import { Grid } from '@material-ui/core';
import DetailsButton from './DetailsButton';
import React, { ReactNode, ReactNodeArray } from 'react';

interface OwnProps {
  children: ReactNode | ReactNodeArray;
  detailsLink?: string;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
}

const GridContainer: React.FC<OwnProps> = ({ children, detailsLink, alignItems = 'flex-start' }) => {
  return (
    <Grid container spacing={2} alignItems={alignItems}>
      <Grid item md={1} xs={12}>
        {detailsLink && <DetailsButton to={detailsLink} />}
      </Grid>
      <Grid item md={3} xs={6}>
        {Array.isArray(children) ? children[0] : children}
      </Grid>
      <Grid item md={7} xs={6}>
        {Array.isArray(children) && children[1]}
      </Grid>
    </Grid>
  );
};

export default React.memo(GridContainer);

import { DisplayTab } from './DisplayTab';
import { DisplayHeader } from './DisplayHeader';

export class DisplayDocument {
  constructor(private readonly tabs: Array<DisplayTab>) {}

  getTabs = () => this.tabs;

  getTabHeaders = (tabName: string): Array<DisplayHeader> => {
    const tabIndex = this.tabs.findIndex((displayTab) => displayTab.tabName === tabName);

    return this.tabs[tabIndex].getHeaders();
  };
}

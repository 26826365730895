import { IntlKeys } from 'lib/localization/keys/__keys';

export enum MyJoindataSerialized {
  JoindataRequestedParticipations = 'JoindataRequestedParticipations',
}

enum MyJoindataUnserialized {
  JoindataRequestedParticipations = 'JoindataRequestedParticipations',
}

enum MyJoindataSerializedHeaders {
  organizationId = 'organizationId',
  organizationName = 'organizationName',
  kvk = 'kvk',
  purposeName = 'purposeName',
  scheme = 'scheme',
  status = 'status',
  requestedAt = 'requestedAt',
  contentStartDate = 'contentStartDate',
  externalParticipationId = 'externalParticipationId',
}

const MyJoindataUnserializedHeaders = {
  organizationId: ['organization', 'id'],
  organizationName: ['organization', 'kvk_company_name'],
  kvk: ['organization', 'kvk'],
  purposeName: ['purpose', 'name'],
  scheme: 'scheme',
  status: 'status',
  requestedAt: 'requested_at',
  contentStartDate: 'content_start_date',
  externalParticipationId: 'external_participation_id',
};

export const JOINDATA_REQUESTED_PARTICIPATIONS = {
  [MyJoindataSerialized.JoindataRequestedParticipations]: {
    serialized: MyJoindataSerialized.JoindataRequestedParticipations,
    unserialized: MyJoindataUnserialized.JoindataRequestedParticipations,
    view: IntlKeys.participationPlural,
    columns: {
      [MyJoindataSerializedHeaders.organizationId]: {
        serialized: MyJoindataSerializedHeaders.organizationId,
        unserialized: MyJoindataUnserializedHeaders.organizationId,
        view: IntlKeys.adminOrganizationsOrganizationId,
      },
      [MyJoindataSerializedHeaders.organizationName]: {
        serialized: MyJoindataSerializedHeaders.organizationName,
        unserialized: MyJoindataUnserializedHeaders.organizationName,
        view: IntlKeys.organizationsName,
      },
      [MyJoindataSerializedHeaders.kvk]: {
        serialized: MyJoindataSerializedHeaders.kvk,
        unserialized: MyJoindataUnserializedHeaders.kvk,
        view: IntlKeys.kvk,
      },
      [MyJoindataSerializedHeaders.purposeName]: {
        serialized: MyJoindataSerializedHeaders.purposeName,
        unserialized: MyJoindataUnserializedHeaders.purposeName,
        view: IntlKeys.joindataPurposeName,
      },
      [MyJoindataSerializedHeaders.scheme]: {
        serialized: MyJoindataSerializedHeaders.scheme,
        unserialized: MyJoindataUnserializedHeaders.scheme,
        view: IntlKeys.scheme,
      },
      [MyJoindataSerializedHeaders.status]: {
        serialized: MyJoindataSerializedHeaders.status,
        unserialized: MyJoindataUnserializedHeaders.status,
        view: IntlKeys.status,
      },
      [MyJoindataSerializedHeaders.requestedAt]: {
        serialized: MyJoindataSerializedHeaders.requestedAt,
        unserialized: MyJoindataUnserializedHeaders.requestedAt,
        view: IntlKeys.requested,
      },
      [MyJoindataSerializedHeaders.contentStartDate]: {
        serialized: MyJoindataSerializedHeaders.contentStartDate,
        unserialized: MyJoindataUnserializedHeaders.contentStartDate,
        view: IntlKeys.joindataContentStartDate,
      },
      [MyJoindataSerializedHeaders.externalParticipationId]: {
        serialized: MyJoindataSerializedHeaders.externalParticipationId,
        unserialized: MyJoindataUnserializedHeaders.externalParticipationId,
        view: IntlKeys.joindataExternalParticipationId,
      },
    },
  },
};

import { PIG_HOUSING_EMISSION_FACTORS } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = PIG_HOUSING_EMISSION_FACTORS.PigHousingEmissionFactors;

export const getPigHousingEmissionFactorsDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.livestockCategory.serialized,
        extractPropName: columns.livestockCategory.view,
      }),
      new DisplayHeader({ headerName: columns.housingSystem.serialized, extractPropName: columns.housingSystem.view }),
      new DisplayHeader({ headerName: columns.yearValue.serialized, extractPropName: columns.yearValue.view }),
      new DisplayHeader({ headerName: columns.emissionValue.serialized, extractPropName: columns.emissionValue.view }),
      new DisplayHeader({ headerName: columns.unit.serialized, extractPropName: columns.unit.view }),
    ]),
  ]);
};

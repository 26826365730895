/**
 * @deprecated use single IntlKeys
 */
export enum FarmHomeKeys {
  farmProfile = 'farm.home.profile',
  stableData = 'farm.home.stableData',
  stableRegistrations = 'farm.home.stableRegistrations',
}

/**
 * @deprecated use single IntlKeys
 */
export enum FarmEventsKeys {
  ubn = 'farm.events.ubn',
  stableName = 'farm.events.stableName',
  type = 'farm.events.type',
  action = 'farm.events.action',
  lastModifiedBy = 'farm.events.lastModifiedBy',
}

/**
 * @deprecated use single IntlKeys
 */
export enum FarmReportKeys {
  supportTitle = 'farm.report.title.support',
  deathsTitle = 'farm.report.title.deaths',
  deliveryTitle = 'farm.report.title.delivery',
  feedConsumptionTitle = 'farm.report.title.feedConsumption',
  stable = 'farm.report.stable',
  department = 'farm.report.department',
  originUbn = 'farm.report.originUbn',
  amount = 'farm.report.amount',
  weight = 'farm.report.weight',
  date = 'farm.report.date',
  geneticMother = 'farm.report.geneticMother',
  geneticFather = 'farm.report.geneticFather',
  vaccination = 'farm.report.vaccination',
  notes = 'farm.report.notes',
  causeOfDeath = 'farm.report.causeOfDeath',
  actualWeightOfFeedType = 'farm.report.actualWeightOfFeedType',
  startFeed = 'farm.report.startFeed',
  growthFeed = 'farm.report.growthFeed',
  finishFeed = 'farm.report.finishFeed',
  departmentNotFound = 'farm.report.departmentNotFound',
}

/**
 * @deprecated use single IntlKeys
 */
export enum FarmStablesKeys {
  selectLocationTitle = 'farm.stables.selectLocationTitle',
  stableNumber = 'farm.stables.stallNumber',

  modifyStableTitle = 'farm.stables.modifyStableTitle',
  stableName = 'farm.stables.stableName',

  animalCategory = 'farm.stables.animalCategory',
  weaningPiglets = 'farm.stables.weaningPiglets',
  sows = 'farm.stables.sows',
  finishers = 'farm.stables.finishers',

  feeding = 'farm.stables.feeding',
  feedType = 'farm.stables.feedType',

  dryFoodLimited = 'farm.stables.dryFoodLimited',
  dryFoodUnlimited = 'farm.stables.dryFoodUnlimited',
  liquidFoodLimited = 'farm.stables.LiquidFoodLimited',
  liquidFoodUnlimited = 'farm.stables.LiquidFoodUnlimited',

  deleteStable = 'farm.stables.deleteStable',
  editDepartments = 'farm.stables.editDepartments',

  departments = 'farm.stables.departments',
  departmentsOptionalNote = 'farm.stables.departmentsOptionalNote',

  numberOfDepartments = 'farm.stables.numberOfDepartments',
  placesPerDepartment = 'farm.stables.placesPerDepartment',
  placesPerDepartmentAverage = 'farm.stables.placesPerDepartmentAverage',
  editDepartmentButtonText = 'farm.stables.editDepartmentButtonText',
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Route } from '../../../Route';
import RouteParser from 'route-parser';

const descrs = [
  'Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n    A alias animi consequuntur deleniti deserunt ea eligendi esse, impedit itaque molestiae nam quae quas reiciendis soluta tenetur. Cumque in officiis perspiciatis.',
  'Accusamus alias architecto atque consequuntur, cupiditate delectus dolorem dolorum, ea eligendi et facere fugiat nobis, nulla quaerat quas sapiente ut voluptatum!\nDolores eligendi error laboriosam maiores necessitatibus praesentium sint voluptas.',
  'Adipisci aspernatur consectetur cumque debitis doloremque dolores ducimus eaque, earum ex facere fuga id incidunt inventore ipsum, laudantium molestiae mollitia necessitatibus odio pariatur quasi quod quos rem reprehenderit sequi temporibus.',
  'Aut consequatur dolor, exercitationem facilis ipsa, ipsum laborum magnam nesciunt nihil nobis numquam perspiciatis quisquam quo sint sit tempore tenetur veniam voluptate voluptatem voluptatum!\n    Aliquid dolore ducimus facere quibusdam veniam.',
  'Ab animi, assumenda autem blanditiis commodi deleniti eos facilis fuga illo impedit in, incidunt ipsam iure maiores minima molestias, nobis obcaecati quas quisquam quos saepe sapiente tempore ut velit veniam.',
  'Accusantium architecto, assumenda cumque delectus eius eligendi fugiat minus modi molestias, quia rerum unde.\nAlias cumque error saepe! Adipisci blanditiis cupiditate dolores esse fuga nesciunt non pariatur perferendis quas vero.',
  'Accusamus dolor earum esse maiores molestias nemo nesciunt quibusdam voluptas. Accusamus aliquam cumque deleniti dignissimos earum enim, et necessitatibus nemo neque optio, porro sapiente sequi, sit suscipit temporibus totam ut?',
  'A beatae earum excepturi, fuga provident sunt tempora vel voluptates! Asperiores, aspernatur at aut blanditiis consequuntur deleniti facilis magnam nemo odit quas quidem quos recusandae rem sint tempore unde voluptas!',
  'A eius facilis nemo quas quos?\n\n    Alias consequuntur dicta dolorem doloremque exercitationem harum illum ipsam laudantium nam nihil, obcaecati odio officia praesentium quo similique sint totam veritatis vitae! Eveniet, similique.',
  'Accusamus alias cupiditate dolor doloremque ducimus eius est ex exercitationem harum illum incidunt laborum magnam maxime natus nemo nobis non nostrum numquam obcaecati, odit possimus praesentium quia quidem velit vero.',
];

export const generateDataPoint = (iy: number, predefined = {}) => {
  return {
    id: iy,
    visible: Math.random() > 3 / 8,
    name: `Datapoint ${iy}`,
    format: 'Format',
    description: descrs[Math.floor(descrs.length * Math.random())],
    ...predefined,
  };
};

export const generateCategory = (ix: number, predefined = {}) => {
  return {
    id: ix,
    name: `Category ${ix + 1}`,
    description: descrs[Math.floor(descrs.length * Math.random())],
    dataPoints: new Array(8).fill(0).map((_, iy) => generateDataPoint(iy)),
    lastModified: new Date().toISOString(),
    modifiedBy: {
      name: 'Username',
    },
    ...predefined,
  };
};

export const dataCatalogue = new Route(
  'get',
  new RouteParser('/mock/data-catalogue/grouped_index'),
  (parsedParams, requestData) => {
    return [
      200,
      {
        resources: new Array(7).fill(0).map((_, ix) => generateCategory(ix)),
      },
    ];
  },
);

export const createCategory = new Route(
  'post',
  new RouteParser('/mock/data_catalogue/create_category'),
  (parsedParams, requestData) => {
    console.log(requestData);
    return [
      200,
      {
        resources: [
          ...new Array(7).fill(0).map((_, ix) => generateCategory(ix)),
          generateCategory(
            7,
            requestData
              ? {
                  // @ts-ignore
                  ...JSON.parse(requestData),
                  dataPoints: [],
                  visible: false,
                }
              : undefined,
          ),
        ],
      },
    ];
  },
);

export const deleteCategory = new Route(
  'delete',
  new RouteParser('/mock/data_catalogue/delete_category/:id'),
  (parsedParams, requestData) => {
    return [
      200,
      {
        resources: [...new Array(7).fill(0).map((_, ix) => generateCategory(ix))].filter(
          ({ id }) => id != parsedParams.id,
        ),
      },
    ];
  },
);

export const editCategory = new Route(
  'put',
  new RouteParser('/mock/data_catalogue/update_category/:id'),
  (parsedParams, requestData) => {
    // @ts-ignore
    const updatedCategory = JSON.parse(requestData);

    return [
      200,
      {
        resources: [
          //
          ...new Array(7).fill(0).map((_, ix) => generateCategory(ix)),
        ].map((category) =>
          category.id == parsedParams.id
            ? {
                ...category,
                ...updatedCategory,
              }
            : category,
        ),
      },
    ];
  },
);

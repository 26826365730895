import { WUR_MFC_EFEM_FIELDS } from 'lib/excel/config/referenceTables/wurMfcEfem/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = WUR_MFC_EFEM_FIELDS.WurMfcEfem;

export const getWurMfcEfemDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.system.serialized,
        extractPropName: columns.system.view,
      }),
      new DisplayHeader({
        headerName: columns.avgStorageDurationManure.serialized,
        extractPropName: columns.avgStorageDurationManure.view,
      }),
      new DisplayHeader({ headerName: columns.mcf.serialized, extractPropName: columns.mcf.view }),
    ]),
  ]);
};

import _ from 'lodash';

import { ResourcesWrapper } from 'lib/http/utils';

import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { isNumeric } from 'lib/helpers/renderHelpers';

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer, ObjectWithProps, PropertyPath } from 'lib/excel/serilizers/Cell';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { SLAUGHTER_DETAILS } from 'lib/tables/config/slaughter/details/config';

const { columns, serialized, unserialized } = SLAUGHTER_DETAILS.SlaughterDetails;

interface SlaughterDetailsI {
  id: number;
  slaughter_message_id: number;
  slaughter_key: string;
  animal_type: string;
  carcass_code: string;
  animal_weight: string;
  organ_code: string;
  muscle_thickness: string;
  fat_thickness: string;
  after_correction_pig_amount: string;
  created_at: string;
  updated_at: string;
  meat: string;
  animal_identification_type: string;
}

const normalizeAnimalWeight = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propOriginalPath: PropertyPath,
) => {
  const value = _.get(excelRow, propOriginalPath, '');

  // to kilogram
  return isNumeric(value) ? Number(value / 10) : '';
};

const normalizeAfterCorrectionAmount = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propOriginalPath: PropertyPath,
) => {
  const value = _.get(excelRow, propOriginalPath);

  // to EUR
  return isNumeric(value) ? Number(value / 10000) : '';
};

const normalizeThickness = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propOriginalPath: PropertyPath,
) => {
  const value = _.get(excelRow, propOriginalPath);

  // to millimeters
  return isNumeric(value) ? Number(value / 10) : '';
};

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(columns.slaughterMessageId.unserialized, columns.slaughterMessageId.serialized),
      new CellSerializer(columns.containerNumber.unserialized, columns.containerNumber.serialized),
      new CellSerializer(columns.slaughterKey.unserialized, columns.slaughterKey.serialized),
      new CellSerializer(columns.animalType.unserialized, columns.animalType.serialized),
      new CellSerializer(columns.animalWeight.unserialized, columns.animalWeight.serialized, normalizeAnimalWeight),
      new CellSerializer(columns.muscleThickness.unserialized, columns.muscleThickness.serialized, normalizeThickness),
      new CellSerializer(columns.fatThickness.unserialized, columns.fatThickness.serialized, normalizeThickness),
      new CellSerializer(columns.meat.unserialized, columns.meat.serialized, normalizeAnimalWeight),
      new CellSerializer(columns.carcassCode.unserialized, columns.carcassCode.serialized),
      new CellSerializer(
        columns.afterCorrectionPigAmount.unserialized,
        columns.afterCorrectionPigAmount.serialized,
        normalizeAfterCorrectionAmount,
      ),
      new CellSerializer(columns.organCode.unserialized, columns.organCode.serialized),
      new CellSerializer(columns.slaughterMessage.unserialized, columns.slaughterMessage.serialized),
      new CellSerializer(columns.animalIdentificationType.unserialized, columns.animalIdentificationType.serialized),

      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
    ]),
  ]);
};

export const serializeSlaughterDetailsInfo = (serverData: ResourcesWrapper<SlaughterDetailsI>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_WEIGHTS } from './config';

export type GroupWeightsPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  fin_event_identifier: string;
  number: string;
  total_weight: number;
  notes: string;
  group_weight_date: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type GroupWeightSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  finEventIdentifier: string;
  number: string;
  totalWeight: number;
  notes: string;
  groupWeightDate: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = GROUP_WEIGHTS.GroupWeights;

const getDefaultSerializer = (data: DocumentData<GroupWeightsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.finEventIdentifier.unserialized, columns.finEventIdentifier.serialized),
      new CellSerializer(columns.number.unserialized, columns.number.serialized),
      new CellSerializer(columns.totalWeight.unserialized, columns.totalWeight.serialized),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.groupWeightDate.unserialized, columns.groupWeightDate.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeGroupWeights = (serverData: ResourcesWrapper<GroupWeightsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

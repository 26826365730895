/**
 * @deprecated use single IntlKeys
 */
export enum UiKeys {
  bulkAction = 'ui.bulkAction',
  showMenu = 'ui.showMenu',
  displayAs = 'ui.displayAs',
  list = 'ui.list',
  cards = 'ui.cards',
  filterItems = 'ui.filterItems',
  all = 'ui.all',
  today = 'ui.today',
  thisWeek = 'ui.thisWeek',
  thisMonth = 'ui.thisMonth',
  dateRange = 'ui.dateRange',
  from = 'ui.from',
  to = 'ui.to',
  resetFilters = 'ui.resetFilters',
  applyFilters = 'ui.applyFilters',
  tableSettings = 'ui.tableSettings',
  applySettings = 'ui.applySettings',
  resetToDefault = 'ui.resetToDefault',
  proceed = 'ui.proceed',
  discard = 'ui.discard',
}

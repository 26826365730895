import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';

const resources = [
  {
    company_scheme: 'nl.kvk',
    company_value: 'iw7eo',
    end_date: '2021-01-22T01:53:57.000Z',
    granted_on: '2021-01-07T09:02:05.000Z',
    refused_on: '2021-01-25T22:33:07+02:00',
    replaced_by_id: 'bdf36ceb-99dd-499c-9e2d-3fb2e92054ad',
    revoked_on: '2021-01-23T02:58:48.000Z',
    start_date: '2021-01-25T17:05:02+02:00',
    status: 0,
    is_valid: false,
    purpose: {
      name: 'ea',
    },
    restrictions: [
      {
        restriction_type: 'dolores',
        description: 'Ad autem animi qui.',
        date_start: '2021-01-25T09:15:06.000Z',
        date_end: '2021-01-25T10:19:59.000Z',
        scheme: 'nl.kvk',
        restriction_value: {
          Gavin: 'Loralee',
          Donovan: 'Elisabeth',
          Teodoro: 'Latrina',
        },
      },
    ],
  },
  {
    company_scheme: 'nl.kvk',
    company_value: 'iw7eo',
    end_date: '2021-01-22T01:53:57.000Z',
    granted_on: '2021-01-07T09:02:05.000Z',
    refused_on: '2021-01-25T22:33:07+02:00',
    replaced_by_id: 'bdf36ceb-99dd-499c-9e2d-3fb2e92054ad',
    revoked_on: '2021-01-23T02:58:48.000Z',
    start_date: '2021-01-25T17:05:02+02:00',
    status: 0,
    is_valid: false,
    purpose: {
      name: 'ea',
    },
    restrictions: [
      {
        restriction_type: 'dolores',
        description: 'Ad autem animi qui.',
        date_start: '2021-01-25T09:15:06.000Z',
        date_end: '2021-01-25T10:19:59.000Z',
        scheme: 'nl.kvk',
        restriction_value: {
          Gavin: 'Loralee',
          Donovan: 'Elisabeth',
          Teodoro: 'Latrina',
        },
      },
    ],
  },
];

export const myJoindata = new Route('get', new RouteParser('/mock/participations'), () => {
  return [200, { resources }];
});

export const adminJoindata = new Route('get', new RouteParser('/mock/admin/participations'), () => {
  return [200, { resources }];
});

export const adminJoindataRequestedParticipations = new Route(
  'get',
  new RouteParser('/mock/admin/participation_store_requests'),
  () => {
    return [
      200,
      {
        resources: [
          {
            scheme: 'nl.kvk1',
            content_start_date: '2021-02-10T14:50:11.000Z',
            requested_at: '2021-02-09T11:26:31.000Z',
            status: 'pending',
            external_participation_id: '36d81e23-f49b-43da-8795-2a7d8202fdd7',
            organization: {
              id: 1,
              kvk: 'pvbofrxunv',
              kvk_company_name: 'Kshlerin-Blick',
              email: '1bethany@yahoo.com',
            },
            purpose: {
              id: 1,
              name: 'et',
            },
          },
          {
            scheme: 'nl.kvk2',
            content_start_date: '2021-02-10T14:50:11.000Z',
            requested_at: '2021-02-09T11:26:31.000Z',
            status: 'failed',
            external_participation_id: '36d81e23-f49b-43da-8795-2a7d8202fdd7',
            organization: {
              id: 1,
              kvk: 'pvbofrxunv',
              kvk_company_name: 'Kshlerin-Blick',
              email: '1bethany@yahoo.com',
            },
            purpose: {
              id: 1,
              name: 'et',
            },
          },
        ],
      },
    ];
  },
);

const joindataLastSyncResource = {
  id: 1,
  task_scope: 'participations',
  task_name: 'sync_records',
  done_at: '2021-02-03T14:31:46.578Z',
  status: 'pending',
};

export type JoindataLastSyncResource = typeof joindataLastSyncResource;

export const joindataLastSync = new Route(
  'get',
  new RouteParser('/mock/admin/task_logs/last_log/participations/sync_records'),
  () => {
    return [200, { resource: joindataLastSyncResource }];
  },
);

export const syncJoindata = new Route(
  'post',
  new RouteParser('/mock/admin/tasks/execute/participations/sync_records'),
  () => {
    return [200, { status: 'ok' }];
  },
);

import { asyncAction, syncAction } from 'lib/actions';
import { DateRange } from 'lib/tables/config/vri/config';

export const fetchUBNsAutocomplete = asyncAction<string | undefined>('vri/fetchUBNsAutocomplete');
export const fetchVriDataAction = asyncAction('vri/fetchVriData');
export const fetchVriStatisticsAction = asyncAction('vri/fetchVriStatistics');
export const fetchVriReportsConstantsAction = asyncAction('vri/fetchVriReportsConstants');
export const deleteReportUpload = asyncAction('vri/deleteReportUpload');
export const deleteReport = asyncAction('vri/deleteReport');

export const setSelectedVriUbn = syncAction<string>('vri/setSelectedUbn');
export const setSelectedVriDateRange = syncAction<DateRange>('vri/setSelectedDateRange');

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_GROUPS } from './config';

export type GroupGroupsPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  group_identifier: string;
  group_name: string;
  date_end: string;
  notes: string;
  barn_identifier: string;
  room_identifier: string;
  pen_identifier: string;
  group_datetime: string;
  first_delivery_growth_exp: string;
  last_delivery_growth_exp: string;
  first_delivery_perc: string;
  last_delivery_perc: string;
  first_delivery_weight: string;
  last_delivery_weight: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type GroupGroupsSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  groupIdentifier: string;
  groupName: string;
  dateEnd: string;
  notes: string;
  barnIdentifier: string;
  roomIdentifier: string;
  penIdentifier: string;
  groupDatetime: string;
  firstDeliveryGrowthExp: string;
  lastDeliveryGrowthExp: string;
  firstDeliveryPerc: string;
  lastDeliveryPerc: string;
  firstDeliveryWeight: string;
  lastDeliveryWeight: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = GROUP_GROUPS.GroupGroups;

const getDefaultSerializer = (data: DocumentData<GroupGroupsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.groupIdentifier.unserialized, columns.groupIdentifier.serialized),
      new CellSerializer(columns.groupName.unserialized, columns.groupName.serialized),
      new CellSerializer(columns.dateEnd.unserialized, columns.dateEnd.serialized),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.barnIdentifier.unserialized, columns.barnIdentifier.serialized),
      new CellSerializer(columns.roomIdentifier.unserialized, columns.roomIdentifier.serialized),
      new CellSerializer(columns.penIdentifier.unserialized, columns.penIdentifier.serialized),
      new CellSerializer(columns.groupDatetime.unserialized, columns.groupDatetime.serialized),
      new CellSerializer(columns.firstDeliveryGrowthExp.unserialized, columns.firstDeliveryGrowthExp.serialized),
      new CellSerializer(columns.lastDeliveryGrowthExp.unserialized, columns.lastDeliveryGrowthExp.serialized),
      new CellSerializer(columns.firstDeliveryPerc.unserialized, columns.firstDeliveryPerc.serialized),
      new CellSerializer(columns.lastDeliveryPerc.unserialized, columns.lastDeliveryPerc.serialized),
      new CellSerializer(columns.firstDeliveryWeight.unserialized, columns.firstDeliveryWeight.serialized),
      new CellSerializer(columns.lastDeliveryWeight.unserialized, columns.lastDeliveryWeight.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeGroupGroups = (serverData: ResourcesWrapper<GroupGroupsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum CompanyReportsSerialized {
  CompanyReports = 'CompanyReports',
  CompanyReportsSubmit = 'CompanyReportsSubmit',
  RavCodes = 'RavCodes',
}

enum CompanyReportsUnserialized {
  CompanyReports = 'CompanyReports',
  CompanyReportsSubmit = 'CompanyReportsSubmit',
  RavCodes = 'RavCodes',
}

export enum CompanyReportsSerializedHeaders {
  id = 'id',
  status = 'status',

  // general
  idType = 'idType',
  partyIdTypeCode = 'partyIdTypeCode',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  householdComposition = 'householdComposition',
  companyType = 'companyType',
  byProductsOrLoose = 'byProductsOrLoose',
  feedFermentation = 'feedFermentation',
  //
  ubn = 'ubn',

  // rav-codes
  farmerRavCodes = 'farmerRavCodes',

  feedSuppliersPiglets = 'feedSuppliersPiglets',
  feedSuppliersSows = 'feedSuppliersSows',
  feedSuppliersFatteningPigs = 'feedSuppliersFatteningPigs',

  //
  isManureBeingRemoved = 'isManureBeingRemoved',
  manureRemovingFrequency = 'manureRemovingFrequency',
  manureDigestion = 'manureDigestion',

  manureFile = 'manureFile',
  manureFileName = 'manureFileName',

  //
  otherManureStorageTypes = 'otherManureStorageTypes',
  durationOfStorageInCurrentType = 'durationOfStorageInCurrentType',
  monthlyManureRemoval = 'monthlyManureRemoval',

  // production-results-1
  avgSowCount = 'avgSowCount',
  avgRearingSowCount = 'avgRearingSowCount',
  avgPigletCount = 'avgPigletCount',
  totalSoldSowsPrice = 'totalSoldSowsPrice',
  totalSoldPigletsPrice = 'totalSoldPigletsPrice',
  avgPigletCountPerYear = 'avgPigletCountPerYear',
  breedingFeedConversion = 'breedingFeedConversion',
  averageNumberSucklingPiglets = 'averageNumberSucklingPiglets',

  // production-results-2
  avgFinishingPigCount = 'avgFinishingPigCount',
  avgPurchasedPigletWeight = 'avgPurchasedPigletWeight',
  fatteningFeedConversion = 'fatteningFeedConversion',
  cullingPercentage = 'cullingPercentage',
  growthPerDay = 'growthPerDay',
  ewConversion = 'ewConversion',

  // energy-use
  nonRenewableGasPurchasedVolume = 'nonRenewableGasPurchasedVolume',
  renewableGasPurchasedVolume = 'renewableGasPurchasedVolume',
  nonRenewableElectricityPurchasedVolume = 'nonRenewableElectricityPurchasedVolume',
  renewableElectricityPurchasedVolume = 'renewableElectricityPurchasedVolume',
  tapWaterUsedVolume = 'tapWaterUsedVolume',
  waterFromOwnSource = 'waterFromOwnSource',
  waterMeterAvailable = 'waterMeterAvailable',
  gasMeterAvailable = 'gasMeterAvailable',
  electricityMeterAvailable = 'electricityMeterAvailable',
  sharedSmartMeter = 'sharedSmartMeter',
  quantityOfElectricityProduced = 'quantityOfElectricityProduced',
  solarPanelsSquare = 'solarPanelsSquare',

  productCount = 'productCount',
  fermentationCount = 'fermentationCount',
  manureCount = 'manureCount',

  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
}

export const CompanyReportsUnserializedHeaders = {
  id: 'id',
  status: 'status',

  // general
  idType: 'id_type',
  partyIdTypeCode: ['user_passport', 'id'],
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  householdComposition: 'household_composition',
  companyType: 'company_type',
  byProductsOrLoose: 'by_products_or_loose',
  feedFermentation: 'feed_fermentation',

  //
  ubn: ['user_passport', 'ubn'],

  // rav-codes
  farmerRavCodes: 'farmer_rav_codes',

  feedSuppliersPiglets: 'feed_suppliers_piglets',
  feedSuppliersSows: 'feed_suppliers_sows',
  feedSuppliersFatteningPigs: 'feed_suppliers_fattening_pigs',

  //
  isManureBeingRemoved: 'is_manure_being_removed',
  manureRemovingFrequency: 'manure_removing_frequency',
  manureDigestion: 'manure_digestion',

  manureFile: 'manure_file',
  manureFileName: 'manure_file_name',

  //
  otherManureStorageTypes: 'other_manure_storage_types',
  durationOfStorageInCurrentType: 'duration_of_storage_in_current_type',
  monthlyManureRemoval: 'monthly_manure_removal',

  // production-results-1
  avgSowCount: 'avg_sow_count',
  avgRearingSowCount: 'avg_rearing_sow_count',
  avgPigletCount: 'avg_piglet_count',
  totalSoldSowsPrice: 'total_sold_sows_price',
  totalSoldPigletsPrice: 'total_sold_piglets_price',
  avgPigletCountPerYear: 'avg_piglet_count_per_year',
  breedingFeedConversion: 'breeding_feed_conversion',
  averageNumberSucklingPiglets: 'average_number_suckling_piglets',

  // production-results-2
  avgFinishingPigCount: 'avg_finishing_pig_count',
  avgPurchasedPigletWeight: 'avg_purchased_piglet_weight',
  fatteningFeedConversion: 'fattening_feed_conversion',
  cullingPercentage: 'culling_percentage',
  growthPerDay: 'growth_per_day',
  ewConversion: 'ew_conversion',

  // energy-use
  nonRenewableGasPurchasedVolume: 'non_renewable_gas_purchased_volume',
  renewableGasPurchasedVolume: 'renewable_gas_purchased_volume',
  nonRenewableElectricityPurchasedVolume: 'non_renewable_electricity_purchased_volume',
  renewableElectricityPurchasedVolume: 'renewable_electricity_purchased_volume',
  tapWaterUsedVolume: 'tap_water_used_volume',
  waterFromOwnSource: 'water_from_own_source',
  sharedSmartMeter: 'shared_smart_meter',
  quantityOfElectricityProduced: 'quantity_of_electricity_produced',
  solarPanelsSquare: 'solar_panels_square',

  waterMeterAvailable: 'water_meter_available',
  gasMeterAvailable: 'gas_meter_available',
  electricityMeterAvailable: 'electricity_meter_available',

  productCount: 'product_count',
  fermentationCount: 'fermentation_count',
  manureCount: 'manure_count',

  updatedAt: 'updated_at',
  updatedBy: ['updated_by', 'full_name'],
} as const;

export enum CompanyReportsSubmitSerializedHeaders {
  id = 'id',
  status = 'status',

  // general
  idType = 'idType',
  partyIdTypeCode = 'partyIdTypeCode',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  householdComposition = 'householdComposition',
  companyType = 'companyType',
  byProductsOrLoose = 'byProductsOrLoose',
  feedFermentation = 'feedFermentation',

  // rav-codes
  farmerRavCodesAttributes = 'farmerRavCodesAttributes',

  feedSuppliersPiglets = 'feedSuppliersPiglets',
  feedSuppliersSows = 'feedSuppliersSows',
  feedSuppliersFatteningPigs = 'feedSuppliersFatteningPigs',

  //
  isManureBeingRemoved = 'isManureBeingRemoved',
  manureRemovingFrequency = 'manureRemovingFrequency',
  manureDigestion = 'manureDigestion',

  manureFile = 'manureFile',
  manureFileName = 'manureFileName',

  //
  otherManureStorageTypes = 'otherManureStorageTypes',
  durationOfStorageInCurrentType = 'durationOfStorageInCurrentType',
  monthlyManureRemoval = 'monthlyManureRemoval',

  // production-results-1
  avgSowCount = 'avgSowCount',
  avgRearingSowCount = 'avgRearingSowCount',
  avgPigletCount = 'avgPigletCount',
  totalSoldSowsPrice = 'totalSoldSowsPrice',
  totalSoldPigletsPrice = 'totalSoldPigletsPrice',
  avgPigletCountPerYear = 'avgPigletCountPerYear',
  breedingFeedConversion = 'breedingFeedConversion',
  averageNumberSucklingPiglets = 'averageNumberSucklingPiglets',

  // production-results-2
  avgFinishingPigCount = 'avgFinishingPigCount',
  avgPurchasedPigletWeight = 'avgPurchasedPigletWeight',
  fatteningFeedConversion = 'fatteningFeedConversion',
  cullingPercentage = 'cullingPercentage',
  growthPerDay = 'growthPerDay',
  ewConversion = 'ewConversion',

  // energy-use
  nonRenewableGasPurchasedVolume = 'nonRenewableGasPurchasedVolume',
  renewableGasPurchasedVolume = 'renewableGasPurchasedVolume',
  nonRenewableElectricityPurchasedVolume = 'nonRenewableElectricityPurchasedVolume',
  renewableElectricityPurchasedVolume = 'renewableElectricityPurchasedVolume',
  tapWaterUsedVolume = 'tapWaterUsedVolume',
  waterFromOwnSource = 'waterFromOwnSource',
  sharedSmartMeter = 'sharedSmartMeter',
  quantityOfElectricityProduced = 'quantityOfElectricityProduced',
  solarPanelsSquare = 'solarPanelsSquare',
  waterMeterAvailable = 'waterMeterAvailable',
  gasMeterAvailable = 'gasMeterAvailable',
  electricityMeterAvailable = 'electricityMeterAvailable',
}

export const CompanyReportsSubmitUnserializedHeaders = {
  id: 'id',
  status: 'status',

  // general
  idType: 'id_type',
  partyIdTypeCode: 'user_passport_id',
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  householdComposition: 'household_composition',
  companyType: 'company_type',
  byProductsOrLoose: 'by_products_or_loose',
  feedFermentation: 'feed_fermentation',

  // rav-codes
  farmerRavCodesAttributes: 'farmer_rav_codes_attributes',

  feedSuppliersPiglets: 'feed_suppliers_piglets',
  feedSuppliersSows: 'feed_suppliers_sows',
  feedSuppliersFatteningPigs: 'feed_suppliers_fattening_pigs',

  //
  isManureBeingRemoved: 'is_manure_being_removed',
  manureRemovingFrequency: 'manure_removing_frequency',
  manureDigestion: 'manure_digestion',

  manureFile: 'manure_file',
  manureFileName: 'manure_file_name',

  //
  otherManureStorageTypes: 'other_manure_storage_types',
  durationOfStorageInCurrentType: 'duration_of_storage_in_current_type',
  monthlyManureRemoval: 'monthly_manure_removal',

  // production-results-1
  avgSowCount: 'avg_sow_count',
  avgRearingSowCount: 'avg_rearing_sow_count',
  avgPigletCount: 'avg_piglet_count',
  totalSoldSowsPrice: 'total_sold_sows_price',
  totalSoldPigletsPrice: 'total_sold_piglets_price',
  avgPigletCountPerYear: 'avg_piglet_count_per_year',
  breedingFeedConversion: 'breeding_feed_conversion',
  averageNumberSucklingPiglets: 'average_number_suckling_piglets',

  // production-results-2
  avgFinishingPigCount: 'avg_finishing_pig_count',
  avgPurchasedPigletWeight: 'avg_purchased_piglet_weight',
  fatteningFeedConversion: 'fattening_feed_conversion',
  cullingPercentage: 'culling_percentage',
  growthPerDay: 'growth_per_day',
  ewConversion: 'ew_conversion',

  // energy-use
  nonRenewableGasPurchasedVolume: 'non_renewable_gas_purchased_volume',
  renewableGasPurchasedVolume: 'renewable_gas_purchased_volume',
  nonRenewableElectricityPurchasedVolume: 'non_renewable_electricity_purchased_volume',
  renewableElectricityPurchasedVolume: 'renewable_electricity_purchased_volume',
  tapWaterUsedVolume: 'tap_water_used_volume',
  waterFromOwnSource: 'water_from_own_source',
  sharedSmartMeter: 'shared_smart_meter',
  quantityOfElectricityProduced: 'quantity_of_electricity_produced',
  solarPanelsSquare: 'solar_panels_square',

  waterMeterAvailable: 'water_meter_available',
  gasMeterAvailable: 'gas_meter_available',
  electricityMeterAvailable: 'electricity_meter_available',
} as const;

export enum RavCodesSerializedHeaders {
  stableNumber = 'stableNumber',
  ravCode = 'ravCode',
  animalCount = 'animalCount',
  storageDurationOfManureUnderTheStable = 'storageDurationOfManureUnderTheStable',
}

export enum RavCodesUnserializedHeaders {
  stableNumber = 'stable_number',
  ravCode = 'rav_code',
  animalCount = 'animal_count',
  storageDurationOfManureUnderTheStable = 'storage_duration_of_manure_under_the_stable',
}

// Different by 2 fields
// Submit:
// farmer_rav_codes_attributes
// farmer_rav_codes
export const COMPANY_REPORTS = {
  [CompanyReportsSerialized.CompanyReports]: {
    serialized: CompanyReportsSerialized.CompanyReports,
    unserialized: CompanyReportsUnserialized.CompanyReports,
    view: IntlKeys.titleReportList,

    columns: {
      [CompanyReportsSerializedHeaders.id]: {
        serialized: CompanyReportsSerializedHeaders.id,
        unserialized: CompanyReportsUnserializedHeaders.id,
        view: IntlKeys.farmerInputSystemId,
      },
      [CompanyReportsSerializedHeaders.status]: {
        serialized: CompanyReportsSerializedHeaders.status,
        unserialized: CompanyReportsUnserializedHeaders.status,
        view: IntlKeys.farmerInputStatus,
      },
      [CompanyReportsSerializedHeaders.idType]: {
        serialized: CompanyReportsSerializedHeaders.idType,
        unserialized: CompanyReportsUnserializedHeaders.idType,
        view: IntlKeys.farmerInputIdType,
      },
      [CompanyReportsSerializedHeaders.byProductsOrLoose]: {
        serialized: CompanyReportsSerializedHeaders.byProductsOrLoose,
        unserialized: CompanyReportsUnserializedHeaders.byProductsOrLoose,
        view: IntlKeys.farmerInputByProductsOrLoose,
      },
      [CompanyReportsSerializedHeaders.feedFermentation]: {
        serialized: CompanyReportsSerializedHeaders.feedFermentation,
        unserialized: CompanyReportsUnserializedHeaders.feedFermentation,
        view: IntlKeys.farmerInputFeedFermentation,
      },
      [CompanyReportsSerializedHeaders.otherManureStorageTypes]: {
        serialized: CompanyReportsSerializedHeaders.otherManureStorageTypes,
        unserialized: CompanyReportsUnserializedHeaders.otherManureStorageTypes,
        view: IntlKeys.farmerInputAdditionalManureStorage,
      },
      [CompanyReportsSerializedHeaders.feedSuppliersPiglets]: {
        serialized: CompanyReportsSerializedHeaders.feedSuppliersPiglets,
        unserialized: CompanyReportsUnserializedHeaders.feedSuppliersPiglets,
        view: IntlKeys.farmerInputFeedSuppliersPiglets,
      },
      [CompanyReportsSerializedHeaders.feedSuppliersSows]: {
        serialized: CompanyReportsSerializedHeaders.feedSuppliersSows,
        unserialized: CompanyReportsUnserializedHeaders.feedSuppliersSows,
        view: IntlKeys.farmerInputFeedSuppliersSows,
      },
      [CompanyReportsSerializedHeaders.feedSuppliersFatteningPigs]: {
        serialized: CompanyReportsSerializedHeaders.feedSuppliersFatteningPigs,
        unserialized: CompanyReportsUnserializedHeaders.feedSuppliersFatteningPigs,
        view: IntlKeys.farmerInputFeedSuppliersFatteningPigs
      },
      [CompanyReportsSerializedHeaders.monthlyManureRemoval]: {
        serialized: CompanyReportsSerializedHeaders.monthlyManureRemoval,
        unserialized: CompanyReportsUnserializedHeaders.monthlyManureRemoval,
        view: IntlKeys.farmerInputAdditionalManureStorage,
      },
      [CompanyReportsSerializedHeaders.isManureBeingRemoved]: {
        serialized: CompanyReportsSerializedHeaders.isManureBeingRemoved,
        unserialized: CompanyReportsUnserializedHeaders.isManureBeingRemoved,
        view: IntlKeys.farmerInputIsManureBeingRemoved,
      },
      [CompanyReportsSerializedHeaders.manureRemovingFrequency]: {
        serialized: CompanyReportsSerializedHeaders.manureRemovingFrequency,
        unserialized: CompanyReportsUnserializedHeaders.manureRemovingFrequency,
        view: IntlKeys.farmerInputManureRemovingFrequency,
      },
      [CompanyReportsSerializedHeaders.manureDigestion]: {
        serialized: CompanyReportsSerializedHeaders.manureDigestion,
        unserialized: CompanyReportsUnserializedHeaders.manureDigestion,
        view: IntlKeys.farmerInputManureDigestion,
      },
      [CompanyReportsSerializedHeaders.manureFile]: {
        serialized: CompanyReportsSerializedHeaders.manureFile,
        unserialized: CompanyReportsUnserializedHeaders.manureFile,
        view: IntlKeys.farmerInputManureDigestion,
      },
      [CompanyReportsSerializedHeaders.manureFileName]: {
        serialized: CompanyReportsSerializedHeaders.manureFileName,
        unserialized: CompanyReportsUnserializedHeaders.manureFileName,
        view: IntlKeys.farmerInputManureDigestion,
      },
      [CompanyReportsSerializedHeaders.durationOfStorageInCurrentType]: {
        serialized: CompanyReportsSerializedHeaders.durationOfStorageInCurrentType,
        unserialized: CompanyReportsUnserializedHeaders.durationOfStorageInCurrentType,
        view: IntlKeys.farmerInputAdditionalManureStorage,
      },
      [CompanyReportsSerializedHeaders.partyIdTypeCode]: {
        serialized: CompanyReportsSerializedHeaders.partyIdTypeCode,
        unserialized: CompanyReportsUnserializedHeaders.partyIdTypeCode,
        view: IntlKeys.farmerInputIdCode,
      },
      [CompanyReportsSerializedHeaders.reportingPeriodDateStart]: {
        serialized: CompanyReportsSerializedHeaders.reportingPeriodDateStart,
        unserialized: CompanyReportsUnserializedHeaders.reportingPeriodDateStart,
        view: IntlKeys.farmerInputPeriodStartDate,
      },
      [CompanyReportsSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: CompanyReportsSerializedHeaders.reportingPeriodDateEnd,
        unserialized: CompanyReportsUnserializedHeaders.reportingPeriodDateEnd,
        view: IntlKeys.farmerInputPeriodEndDate,
      },
      [CompanyReportsSerializedHeaders.householdComposition]: {
        serialized: CompanyReportsSerializedHeaders.householdComposition,
        unserialized: CompanyReportsUnserializedHeaders.householdComposition,
        view: IntlKeys.farmerInputHouseholdComposition,
      },
      [CompanyReportsSerializedHeaders.companyType]: {
        serialized: CompanyReportsSerializedHeaders.companyType,
        unserialized: CompanyReportsUnserializedHeaders.companyType,
        view: IntlKeys.farmerInputTypeOfCompany,
      },
      [CompanyReportsSerializedHeaders.ubn]: {
        serialized: CompanyReportsSerializedHeaders.ubn,
        unserialized: CompanyReportsUnserializedHeaders.ubn,
        view: IntlKeys.farmerInputIdCode,
      },
      [CompanyReportsSerializedHeaders.farmerRavCodes]: {
        serialized: CompanyReportsSerializedHeaders.farmerRavCodes,
        unserialized: CompanyReportsUnserializedHeaders.farmerRavCodes,
        view: IntlKeys.farmerInputRavCode,
      },
      [CompanyReportsSerializedHeaders.manureFileName]: {
        serialized: CompanyReportsSerializedHeaders.manureFileName,
        unserialized: CompanyReportsUnserializedHeaders.manureFileName,
        view: IntlKeys.farmerInputManureFileName,
      },
      [CompanyReportsSerializedHeaders.avgSowCount]: {
        serialized: CompanyReportsSerializedHeaders.avgSowCount,
        unserialized: CompanyReportsUnserializedHeaders.avgSowCount,
        view: IntlKeys.farmerInputAverageNumberOfSowsPerPeriod,
      },
      [CompanyReportsSerializedHeaders.avgRearingSowCount]: {
        serialized: CompanyReportsSerializedHeaders.avgRearingSowCount,
        unserialized: CompanyReportsUnserializedHeaders.avgRearingSowCount,
        view: IntlKeys.farmerInputAverageNumberOfRearingSowsPerPeriod,
      },
      [CompanyReportsSerializedHeaders.avgPigletCount]: {
        serialized: CompanyReportsSerializedHeaders.avgPigletCount,
        unserialized: CompanyReportsUnserializedHeaders.avgPigletCount,
        view: IntlKeys.farmerInputAverageNumberOfPigletsPerPeriod,
      },
      [CompanyReportsSerializedHeaders.totalSoldSowsPrice]: {
        serialized: CompanyReportsSerializedHeaders.totalSoldSowsPrice,
        unserialized: CompanyReportsUnserializedHeaders.totalSoldSowsPrice,
        view: IntlKeys.farmerInputTotalPriceSoldSows,
      },
      [CompanyReportsSerializedHeaders.totalSoldPigletsPrice]: {
        serialized: CompanyReportsSerializedHeaders.totalSoldPigletsPrice,
        unserialized: CompanyReportsUnserializedHeaders.totalSoldPigletsPrice,
        view: IntlKeys.farmerInputTotalPriceSoldPiglets,
      },
      [CompanyReportsSerializedHeaders.avgPigletCountPerYear]: {
        serialized: CompanyReportsSerializedHeaders.avgPigletCountPerYear,
        unserialized: CompanyReportsUnserializedHeaders.avgPigletCountPerYear,
        view: IntlKeys.farmerInputAverageNumberOfPigletsProduced,
      },
      [CompanyReportsSerializedHeaders.breedingFeedConversion]: {
        serialized: CompanyReportsSerializedHeaders.breedingFeedConversion,
        unserialized: CompanyReportsUnserializedHeaders.breedingFeedConversion,
        view: IntlKeys.farmerInputFeedConversionBreeder,
      },
      [CompanyReportsSerializedHeaders.averageNumberSucklingPiglets]: {
        serialized: CompanyReportsSerializedHeaders.averageNumberSucklingPiglets,
        unserialized: CompanyReportsUnserializedHeaders.averageNumberSucklingPiglets,
        view: IntlKeys.farmerInputAverageNumberSucklingPiglets,
      },
      [CompanyReportsSerializedHeaders.avgFinishingPigCount]: {
        serialized: CompanyReportsSerializedHeaders.avgFinishingPigCount,
        unserialized: CompanyReportsUnserializedHeaders.avgFinishingPigCount,
        view: IntlKeys.farmerInputAverageNumberOfFinishingPigsPerPeriod,
      },
      [CompanyReportsSerializedHeaders.avgPurchasedPigletWeight]: {
        serialized: CompanyReportsSerializedHeaders.avgPurchasedPigletWeight,
        unserialized: CompanyReportsUnserializedHeaders.avgPurchasedPigletWeight,
        view: IntlKeys.farmerInputAverageWeightPigletsPurchased,
      },
      [CompanyReportsSerializedHeaders.fatteningFeedConversion]: {
        serialized: CompanyReportsSerializedHeaders.fatteningFeedConversion,
        unserialized: CompanyReportsUnserializedHeaders.fatteningFeedConversion,
        view: IntlKeys.farmerInputFeedConversionFattener,
      },
      [CompanyReportsSerializedHeaders.cullingPercentage]: {
        serialized: CompanyReportsSerializedHeaders.cullingPercentage,
        unserialized: CompanyReportsUnserializedHeaders.cullingPercentage,
        view: IntlKeys.farmerInputCullingPercentage,
      },
      [CompanyReportsSerializedHeaders.growthPerDay]: {
        serialized: CompanyReportsSerializedHeaders.growthPerDay,
        unserialized: CompanyReportsUnserializedHeaders.growthPerDay,
        view: IntlKeys.farmerInputGrowthPerDay,
      },
      [CompanyReportsSerializedHeaders.ewConversion]: {
        serialized: CompanyReportsSerializedHeaders.ewConversion,
        unserialized: CompanyReportsUnserializedHeaders.ewConversion,
        view: IntlKeys.farmerInputEwConversion,
      },
      [CompanyReportsSerializedHeaders.nonRenewableGasPurchasedVolume]: {
        serialized: CompanyReportsSerializedHeaders.nonRenewableGasPurchasedVolume,
        unserialized: CompanyReportsUnserializedHeaders.nonRenewableGasPurchasedVolume,
        view: IntlKeys.farmerInputQuantityOfNonRenewableGasPurchased,
      },
      [CompanyReportsSerializedHeaders.renewableGasPurchasedVolume]: {
        serialized: CompanyReportsSerializedHeaders.renewableGasPurchasedVolume,
        unserialized: CompanyReportsUnserializedHeaders.renewableGasPurchasedVolume,
        view: IntlKeys.farmerInputQuantityOfRenewableGasPurchased,
      },
      [CompanyReportsSerializedHeaders.nonRenewableElectricityPurchasedVolume]: {
        serialized: CompanyReportsSerializedHeaders.nonRenewableElectricityPurchasedVolume,
        unserialized: CompanyReportsUnserializedHeaders.nonRenewableElectricityPurchasedVolume,
        view: IntlKeys.farmerInputQuantityOfNonRenewableElectricityPurchased,
      },
      [CompanyReportsSerializedHeaders.renewableElectricityPurchasedVolume]: {
        serialized: CompanyReportsSerializedHeaders.renewableElectricityPurchasedVolume,
        unserialized: CompanyReportsUnserializedHeaders.renewableElectricityPurchasedVolume,
        view: IntlKeys.farmerInputQuantityOfRenewableElectricityPurchased,
      },
      [CompanyReportsSerializedHeaders.tapWaterUsedVolume]: {
        serialized: CompanyReportsSerializedHeaders.tapWaterUsedVolume,
        unserialized: CompanyReportsUnserializedHeaders.tapWaterUsedVolume,
        view: IntlKeys.farmerInputQuantityOfTapWaterUsed,
      },
      [CompanyReportsSerializedHeaders.waterFromOwnSource]: {
        serialized: CompanyReportsSerializedHeaders.waterFromOwnSource,
        unserialized: CompanyReportsUnserializedHeaders.waterFromOwnSource,
        view: IntlKeys.farmerInputWaterFromOwnSource,
      },
      [CompanyReportsSerializedHeaders.sharedSmartMeter]: {
        serialized: CompanyReportsSerializedHeaders.sharedSmartMeter,
        unserialized: CompanyReportsUnserializedHeaders.sharedSmartMeter,
        view: IntlKeys.farmerInputSharedSmartMeter,
      },
      [CompanyReportsSerializedHeaders.quantityOfElectricityProduced]: {
        serialized: CompanyReportsSerializedHeaders.quantityOfElectricityProduced,
        unserialized: CompanyReportsUnserializedHeaders.quantityOfElectricityProduced,
        view: IntlKeys.farmerInputQuantityOfElectricityProduced,
      },
      [CompanyReportsSerializedHeaders.solarPanelsSquare]: {
        serialized: CompanyReportsSerializedHeaders.solarPanelsSquare,
        unserialized: CompanyReportsUnserializedHeaders.solarPanelsSquare,
        view: IntlKeys.farmerInputSolarPanelsSquare,
      },
      [CompanyReportsSerializedHeaders.waterMeterAvailable]: {
        serialized: CompanyReportsSerializedHeaders.waterMeterAvailable,
        unserialized: CompanyReportsUnserializedHeaders.waterMeterAvailable,
        view: IntlKeys.farmerInputSmartWaterMeterAvailable,
      },
      [CompanyReportsSerializedHeaders.gasMeterAvailable]: {
        serialized: CompanyReportsSerializedHeaders.gasMeterAvailable,
        unserialized: CompanyReportsUnserializedHeaders.gasMeterAvailable,
        view: IntlKeys.farmerInputSmartGasMeterAvailable,
      },
      [CompanyReportsSerializedHeaders.electricityMeterAvailable]: {
        serialized: CompanyReportsSerializedHeaders.electricityMeterAvailable,
        unserialized: CompanyReportsUnserializedHeaders.electricityMeterAvailable,
        view: IntlKeys.farmerInputSmartElectiricyMeterAvailable,
      },
      [CompanyReportsSerializedHeaders.productCount]: {
        serialized: CompanyReportsSerializedHeaders.productCount,
        unserialized: CompanyReportsUnserializedHeaders.productCount,
        view: IntlKeys.farmerInputReportsByProducts,
      },
      [CompanyReportsSerializedHeaders.fermentationCount]: {
        serialized: CompanyReportsSerializedHeaders.fermentationCount,
        unserialized: CompanyReportsUnserializedHeaders.fermentationCount,
        view: IntlKeys.farmerInputReportsFermentation,
      },
      [CompanyReportsSerializedHeaders.manureCount]: {
        serialized: CompanyReportsSerializedHeaders.manureCount,
        unserialized: CompanyReportsUnserializedHeaders.manureCount,
        view: IntlKeys.farmerInputReportsManure,
      },
      [CompanyReportsSerializedHeaders.updatedAt]: {
        serialized: CompanyReportsSerializedHeaders.updatedAt,
        unserialized: CompanyReportsUnserializedHeaders.updatedAt,
        view: IntlKeys.farmerInputUpdatedOn,
      },
      [CompanyReportsSerializedHeaders.updatedBy]: {
        serialized: CompanyReportsSerializedHeaders.updatedBy,
        unserialized: CompanyReportsUnserializedHeaders.updatedBy,
        view: IntlKeys.farmerInputUpdatedBy,
      },
    },
  },
};
export const COMPANY_REPORTS_SUBMIT = {
  [CompanyReportsSerialized.CompanyReportsSubmit]: {
    serialized: CompanyReportsSerialized.CompanyReportsSubmit,
    unserialized: CompanyReportsUnserialized.CompanyReportsSubmit,

    columns: {
      [CompanyReportsSubmitSerializedHeaders.id]: {
        serialized: CompanyReportsSubmitSerializedHeaders.id,
        unserialized: CompanyReportsSubmitUnserializedHeaders.id,
      },
      [CompanyReportsSubmitSerializedHeaders.status]: {
        serialized: CompanyReportsSubmitSerializedHeaders.status,
        unserialized: CompanyReportsSubmitUnserializedHeaders.status,
      },
      [CompanyReportsSubmitSerializedHeaders.idType]: {
        serialized: CompanyReportsSubmitSerializedHeaders.idType,
        unserialized: CompanyReportsSubmitUnserializedHeaders.idType,
      },
      [CompanyReportsSubmitSerializedHeaders.byProductsOrLoose]: {
        serialized: CompanyReportsSubmitSerializedHeaders.byProductsOrLoose,
        unserialized: CompanyReportsSubmitUnserializedHeaders.byProductsOrLoose,
        view: IntlKeys.farmerInputByProductsOrLoose,
      },
      [CompanyReportsSubmitSerializedHeaders.feedFermentation]: {
        serialized: CompanyReportsSubmitSerializedHeaders.feedFermentation,
        unserialized: CompanyReportsSubmitUnserializedHeaders.feedFermentation,
        view: IntlKeys.farmerInputFeedFermentation,
      },
      [CompanyReportsSerializedHeaders.otherManureStorageTypes]: {
        serialized: CompanyReportsSerializedHeaders.otherManureStorageTypes,
        unserialized: CompanyReportsUnserializedHeaders.otherManureStorageTypes,
        view: IntlKeys.farmerInputAdditionalManureStorage,
      },
      [CompanyReportsSerializedHeaders.feedSuppliersPiglets]: {
        serialized: CompanyReportsSerializedHeaders.feedSuppliersPiglets,
        unserialized: CompanyReportsUnserializedHeaders.feedSuppliersPiglets,
        view: IntlKeys.farmerInputFeedSuppliersPiglets,
      },
      [CompanyReportsSerializedHeaders.feedSuppliersSows]: {
        serialized: CompanyReportsSerializedHeaders.feedSuppliersSows,
        unserialized: CompanyReportsUnserializedHeaders.feedSuppliersSows,
        view: IntlKeys.farmerInputFeedSuppliersSows,
      },
      [CompanyReportsSerializedHeaders.feedSuppliersFatteningPigs]: {
        serialized: CompanyReportsSerializedHeaders.feedSuppliersFatteningPigs,
        unserialized: CompanyReportsUnserializedHeaders.feedSuppliersFatteningPigs,
        view: IntlKeys.farmerInputFeedSuppliersFatteningPigs,
      },
      [CompanyReportsSerializedHeaders.monthlyManureRemoval]: {
        serialized: CompanyReportsSerializedHeaders.monthlyManureRemoval,
        unserialized: CompanyReportsUnserializedHeaders.monthlyManureRemoval,
        view: IntlKeys.farmerInputAdditionalManureStorage,
      },
      [CompanyReportsSerializedHeaders.isManureBeingRemoved]: {
        serialized: CompanyReportsSerializedHeaders.isManureBeingRemoved,
        unserialized: CompanyReportsUnserializedHeaders.isManureBeingRemoved,
        view: IntlKeys.farmerInputIsManureBeingRemoved,
      },
      [CompanyReportsSerializedHeaders.manureRemovingFrequency]: {
        serialized: CompanyReportsSerializedHeaders.manureRemovingFrequency,
        unserialized: CompanyReportsUnserializedHeaders.manureRemovingFrequency,
        view: IntlKeys.farmerInputManureRemovingFrequency,
      },
      [CompanyReportsSerializedHeaders.manureDigestion]: {
        serialized: CompanyReportsSerializedHeaders.manureDigestion,
        unserialized: CompanyReportsUnserializedHeaders.manureDigestion,
        view: IntlKeys.farmerInputManureDigestion,
      },
      [CompanyReportsSerializedHeaders.manureFile]: {
        serialized: CompanyReportsSerializedHeaders.manureFile,
        unserialized: CompanyReportsUnserializedHeaders.manureFile,
      },
      [CompanyReportsSerializedHeaders.manureFileName]: {
        serialized: CompanyReportsSerializedHeaders.manureFileName,
        unserialized: CompanyReportsUnserializedHeaders.manureFileName,
      },
      [CompanyReportsSerializedHeaders.durationOfStorageInCurrentType]: {
        serialized: CompanyReportsSerializedHeaders.durationOfStorageInCurrentType,
        unserialized: CompanyReportsUnserializedHeaders.durationOfStorageInCurrentType,
        view: IntlKeys.farmerInputDurationOfStorage,
      },
      [CompanyReportsSubmitSerializedHeaders.partyIdTypeCode]: {
        serialized: CompanyReportsSubmitSerializedHeaders.partyIdTypeCode,
        unserialized: CompanyReportsSubmitUnserializedHeaders.partyIdTypeCode,
      },
      [CompanyReportsSubmitSerializedHeaders.reportingPeriodDateStart]: {
        serialized: CompanyReportsSubmitSerializedHeaders.reportingPeriodDateStart,
        unserialized: CompanyReportsSubmitUnserializedHeaders.reportingPeriodDateStart,
      },
      [CompanyReportsSubmitSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: CompanyReportsSubmitSerializedHeaders.reportingPeriodDateEnd,
        unserialized: CompanyReportsSubmitUnserializedHeaders.reportingPeriodDateEnd,
      },
      [CompanyReportsSubmitSerializedHeaders.householdComposition]: {
        serialized: CompanyReportsSubmitSerializedHeaders.householdComposition,
        unserialized: CompanyReportsSubmitUnserializedHeaders.householdComposition,
      },
      [CompanyReportsSubmitSerializedHeaders.companyType]: {
        serialized: CompanyReportsSubmitSerializedHeaders.companyType,
        unserialized: CompanyReportsSubmitUnserializedHeaders.companyType,
      },
      [CompanyReportsSubmitSerializedHeaders.farmerRavCodesAttributes]: {
        serialized: CompanyReportsSubmitSerializedHeaders.farmerRavCodesAttributes,
        unserialized: CompanyReportsSubmitUnserializedHeaders.farmerRavCodesAttributes,
      },
      [CompanyReportsSubmitSerializedHeaders.avgSowCount]: {
        serialized: CompanyReportsSubmitSerializedHeaders.avgSowCount,
        unserialized: CompanyReportsSubmitUnserializedHeaders.avgSowCount,
      },
      [CompanyReportsSubmitSerializedHeaders.avgRearingSowCount]: {
        serialized: CompanyReportsSubmitSerializedHeaders.avgRearingSowCount,
        unserialized: CompanyReportsSubmitUnserializedHeaders.avgRearingSowCount,
      },
      [CompanyReportsSubmitSerializedHeaders.avgPigletCount]: {
        serialized: CompanyReportsSubmitSerializedHeaders.avgPigletCount,
        unserialized: CompanyReportsSubmitUnserializedHeaders.avgPigletCount,
      },
      [CompanyReportsSubmitSerializedHeaders.totalSoldSowsPrice]: {
        serialized: CompanyReportsSubmitSerializedHeaders.totalSoldSowsPrice,
        unserialized: CompanyReportsSubmitUnserializedHeaders.totalSoldSowsPrice,
      },
      [CompanyReportsSubmitSerializedHeaders.totalSoldPigletsPrice]: {
        serialized: CompanyReportsSubmitSerializedHeaders.totalSoldPigletsPrice,
        unserialized: CompanyReportsSubmitUnserializedHeaders.totalSoldPigletsPrice,
      },
      [CompanyReportsSubmitSerializedHeaders.avgPigletCountPerYear]: {
        serialized: CompanyReportsSubmitSerializedHeaders.avgPigletCountPerYear,
        unserialized: CompanyReportsSubmitUnserializedHeaders.avgPigletCountPerYear,
      },
      [CompanyReportsSubmitSerializedHeaders.breedingFeedConversion]: {
        serialized: CompanyReportsSubmitSerializedHeaders.breedingFeedConversion,
        unserialized: CompanyReportsSubmitUnserializedHeaders.breedingFeedConversion,
      },
      [CompanyReportsSubmitSerializedHeaders.averageNumberSucklingPiglets]: {
        serialized: CompanyReportsSubmitSerializedHeaders.averageNumberSucklingPiglets,
        unserialized: CompanyReportsSubmitUnserializedHeaders.averageNumberSucklingPiglets,
      },
      [CompanyReportsSubmitSerializedHeaders.avgFinishingPigCount]: {
        serialized: CompanyReportsSubmitSerializedHeaders.avgFinishingPigCount,
        unserialized: CompanyReportsSubmitUnserializedHeaders.avgFinishingPigCount,
      },
      [CompanyReportsSubmitSerializedHeaders.avgPurchasedPigletWeight]: {
        serialized: CompanyReportsSubmitSerializedHeaders.avgPurchasedPigletWeight,
        unserialized: CompanyReportsSubmitUnserializedHeaders.avgPurchasedPigletWeight,
      },
      [CompanyReportsSubmitSerializedHeaders.fatteningFeedConversion]: {
        serialized: CompanyReportsSubmitSerializedHeaders.fatteningFeedConversion,
        unserialized: CompanyReportsSubmitUnserializedHeaders.fatteningFeedConversion,
      },
      [CompanyReportsSubmitSerializedHeaders.cullingPercentage]: {
        serialized: CompanyReportsSubmitSerializedHeaders.cullingPercentage,
        unserialized: CompanyReportsSubmitUnserializedHeaders.cullingPercentage,
      },
      [CompanyReportsSubmitSerializedHeaders.growthPerDay]: {
        serialized: CompanyReportsSubmitSerializedHeaders.growthPerDay,
        unserialized: CompanyReportsSubmitUnserializedHeaders.growthPerDay,
      },
      [CompanyReportsSubmitSerializedHeaders.ewConversion]: {
        serialized: CompanyReportsSubmitSerializedHeaders.ewConversion,
        unserialized: CompanyReportsSubmitUnserializedHeaders.ewConversion,
      },
      [CompanyReportsSubmitSerializedHeaders.nonRenewableGasPurchasedVolume]: {
        serialized: CompanyReportsSubmitSerializedHeaders.nonRenewableGasPurchasedVolume,
        unserialized: CompanyReportsSubmitUnserializedHeaders.nonRenewableGasPurchasedVolume,
      },
      [CompanyReportsSubmitSerializedHeaders.renewableGasPurchasedVolume]: {
        serialized: CompanyReportsSubmitSerializedHeaders.renewableGasPurchasedVolume,
        unserialized: CompanyReportsSubmitUnserializedHeaders.renewableGasPurchasedVolume,
      },
      [CompanyReportsSubmitSerializedHeaders.nonRenewableElectricityPurchasedVolume]: {
        serialized: CompanyReportsSubmitSerializedHeaders.nonRenewableElectricityPurchasedVolume,
        unserialized: CompanyReportsSubmitUnserializedHeaders.nonRenewableElectricityPurchasedVolume,
      },
      [CompanyReportsSubmitSerializedHeaders.renewableElectricityPurchasedVolume]: {
        serialized: CompanyReportsSubmitSerializedHeaders.renewableElectricityPurchasedVolume,
        unserialized: CompanyReportsSubmitUnserializedHeaders.renewableElectricityPurchasedVolume,
      },
      [CompanyReportsSubmitSerializedHeaders.tapWaterUsedVolume]: {
        serialized: CompanyReportsSubmitSerializedHeaders.tapWaterUsedVolume,
        unserialized: CompanyReportsSubmitUnserializedHeaders.tapWaterUsedVolume,
      },
      [CompanyReportsSubmitSerializedHeaders.waterFromOwnSource]: {
        serialized: CompanyReportsSubmitSerializedHeaders.waterFromOwnSource,
        unserialized: CompanyReportsSubmitUnserializedHeaders.waterFromOwnSource,
      },
      [CompanyReportsSubmitSerializedHeaders.sharedSmartMeter]: {
        serialized: CompanyReportsSubmitSerializedHeaders.sharedSmartMeter,
        unserialized: CompanyReportsSubmitUnserializedHeaders.sharedSmartMeter,
      },
      [CompanyReportsSubmitSerializedHeaders.quantityOfElectricityProduced]: {
        serialized: CompanyReportsSubmitSerializedHeaders.quantityOfElectricityProduced,
        unserialized: CompanyReportsSubmitUnserializedHeaders.quantityOfElectricityProduced,
      },
      [CompanyReportsSubmitSerializedHeaders.solarPanelsSquare]: {
        serialized: CompanyReportsSubmitSerializedHeaders.solarPanelsSquare,
        unserialized: CompanyReportsSubmitUnserializedHeaders.solarPanelsSquare,
      },
      [CompanyReportsSubmitSerializedHeaders.waterMeterAvailable]: {
        serialized: CompanyReportsSubmitSerializedHeaders.waterMeterAvailable,
        unserialized: CompanyReportsSubmitUnserializedHeaders.waterMeterAvailable,
      },
      [CompanyReportsSubmitSerializedHeaders.gasMeterAvailable]: {
        serialized: CompanyReportsSubmitSerializedHeaders.gasMeterAvailable,
        unserialized: CompanyReportsSubmitUnserializedHeaders.gasMeterAvailable,
      },
      [CompanyReportsSubmitSerializedHeaders.electricityMeterAvailable]: {
        serialized: CompanyReportsSubmitSerializedHeaders.electricityMeterAvailable,
        unserialized: CompanyReportsSubmitUnserializedHeaders.electricityMeterAvailable,
      },
    },
  },
};
export const RAV_CODES = {
  [CompanyReportsSerialized.RavCodes]: {
    serialized: CompanyReportsSerialized.RavCodes,
    unserialized: CompanyReportsUnserialized.RavCodes,

    columns: {
      [RavCodesSerializedHeaders.stableNumber]: {
        serialized: RavCodesSerializedHeaders.stableNumber,
        unserialized: RavCodesUnserializedHeaders.stableNumber,
      },
      [RavCodesSerializedHeaders.ravCode]: {
        serialized: RavCodesSerializedHeaders.ravCode,
        unserialized: RavCodesUnserializedHeaders.ravCode,
      },
      [RavCodesSerializedHeaders.animalCount]: {
        serialized: RavCodesSerializedHeaders.animalCount,
        unserialized: RavCodesUnserializedHeaders.animalCount,
      },
      [RavCodesSerializedHeaders.storageDurationOfManureUnderTheStable]: {
        serialized: RavCodesSerializedHeaders.storageDurationOfManureUnderTheStable,
        unserialized: RavCodesUnserializedHeaders.storageDurationOfManureUnderTheStable,
      },
    },
  },
};

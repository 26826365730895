import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const recognitionsCompany = new Route('get', new RouteParser('/recognitions/grouped_index'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 1135,
          updated_date: '2020-04-07T21:00:00.000Z',
          code: 'GMP',
          reg_number: ' GMP018081',
          name: 'AgruniekRijnvallei Voer B.V.',
          program: 'BLKV01',
          created_at: '2020-06-26T07:53:17.456Z',
          updated_at: '2020-06-26T07:53:17.456Z',
          ubn: '0373555',
          ...generateBlockchainRecord(),
        },
        {
          id: 1131,
          updated_date: '2020-04-07T21:00:00.000Z',
          code: 'GMP',
          reg_number: ' GMP018539',
          name: 'ForFarmers Nederland B.V.',
          program: 'BLKV01',
          created_at: '2020-06-26T07:53:17.442Z',
          updated_at: '2020-06-26T07:53:17.442Z',
          ubn: '0297930',
          ...generateBlockchainRecord(),
        },
        {
          id: 1127,
          updated_date: '2020-04-04T21:00:00.000Z',
          code: 'IKBVA',
          reg_number: '   1576203',
          name: 'Vof Krabben ',
          program: 'BLKV01',
          created_at: '2020-06-26T07:53:17.435Z',
          updated_at: '2020-06-26T07:53:17.435Z',
          ubn: '0294809',
          ...generateBlockchainRecord(),
        },
        {
          id: 1120,
          updated_date: '2020-04-07T21:00:00.000Z',
          code: 'GMP',
          reg_number: ' GMP047899',
          name: 'Duynie B.V.',
          program: 'GFW',
          created_at: '2020-06-26T07:53:17.421Z',
          updated_at: '2020-06-26T07:53:17.421Z',
          ubn: '0287359',
          ...generateBlockchainRecord(),
        },
        {
          id: 1116,
          updated_date: '2020-04-08T21:00:00.000Z',
          code: 'GMP',
          reg_number: ' GMP047885',
          name: 'De Heus Voeders B.V.',
          program: 'GFW',
          created_at: '2020-06-26T07:53:17.413Z',
          updated_at: '2020-06-26T07:53:17.413Z',
          ubn: '0281403',
          ...generateBlockchainRecord(),
        },
        {
          id: 1114,
          updated_date: '2020-04-01T21:00:00.000Z',
          code: 'GMP',
          reg_number: ' GMP018539',
          name: 'ForFarmers Nederland B.V.',
          program: 'GFW',
          created_at: '2020-06-26T07:53:17.409Z',
          updated_at: '2020-06-26T07:53:17.409Z',
          ubn: '0205922',
          ...generateBlockchainRecord(),
        },
        {
          id: 1089,
          updated_date: '2020-04-01T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP048084',
          name: 'Voergroep Zuid B.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:24.077Z',
          updated_at: '2020-06-03T13:06:24.077Z',
          ubn: '6081494',
          ...generateBlockchainRecord(),
        },
        {
          id: 1077,
          updated_date: '2020-04-08T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP018539',
          name: 'ForFarmers Nederland B.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:24.048Z',
          updated_at: '2020-06-03T13:06:24.048Z',
          ubn: '6070216',
          ...generateBlockchainRecord(),
        },
        {
          id: 1075,
          updated_date: '2020-04-08T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP018098',
          name: 'AgruniekRijnvallei Voer B.V.',
          program: 'BLKV01',
          created_at: '2020-06-03T13:06:24.044Z',
          updated_at: '2020-06-03T13:06:24.044Z',
          ubn: '2352237',
          ...generateBlockchainRecord(),
        },
        {
          id: 1071,
          updated_date: '2020-04-02T20:59:56.000Z',
          code: 'IKBVA',
          reg_number: '   1638969',
          name: 'Varkens Yweg 48  BV',
          program: 'CBL01',
          created_at: '2020-06-03T13:06:24.022Z',
          updated_at: '2020-06-03T13:06:24.022Z',
          ubn: '2332824',
          ...generateBlockchainRecord(),
        },
        {
          id: 1065,
          updated_date: '2020-04-05T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP047923',
          name: 'Fransen Gerrits B.V. h.o.d.n. Victoria Mengvoeders',
          program: 'GFW',
          created_at: '2020-06-03T13:06:24.009Z',
          updated_at: '2020-06-03T13:06:24.009Z',
          ubn: '2128313',
          ...generateBlockchainRecord(),
        },
        {
          id: 1062,
          updated_date: '2020-04-05T20:59:56.000Z',
          code: 'IKBVA',
          reg_number: '   1724017',
          name: 'Mts van de Weijer ',
          program: 'CBL01',
          created_at: '2020-06-03T13:06:24.002Z',
          updated_at: '2020-06-03T13:06:24.002Z',
          ubn: '2126360',
          ...generateBlockchainRecord(),
        },
        {
          id: 1046,
          updated_date: '2020-04-01T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP047885',
          name: 'De Heus Voeders B.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.968Z',
          updated_at: '2020-06-03T13:06:23.968Z',
          ubn: '2055967',
          ...generateBlockchainRecord(),
        },
        {
          id: 1044,
          updated_date: '2020-04-01T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP048084',
          name: 'Voergroep Zuid B.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.963Z',
          updated_at: '2020-06-03T13:06:23.963Z',
          ubn: '2055448',
          ...generateBlockchainRecord(),
        },
        {
          id: 1032,
          updated_date: '2020-03-31T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP018092',
          name: 'Agrifirm NWE BV',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.935Z',
          updated_at: '2020-06-03T13:06:23.935Z',
          ubn: '2023212',
          ...generateBlockchainRecord(),
        },
        {
          id: 1030,
          updated_date: '2020-03-31T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP047885',
          name: 'De Heus Voeders B.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.931Z',
          updated_at: '2020-06-03T13:06:23.931Z',
          ubn: '1958474',
          ...generateBlockchainRecord(),
        },
        {
          id: 1028,
          updated_date: '2020-04-05T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP019450',
          name: 'J.A. Theeuwes B.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.926Z',
          updated_at: '2020-06-03T13:06:23.926Z',
          ubn: '1809176',
          ...generateBlockchainRecord(),
        },
        {
          id: 1023,
          updated_date: '2020-03-31T20:59:56.000Z',
          code: 'IKBVA',
          reg_number: '   1733046',
          name: 'Devinka BV / Larestraat ',
          program: 'CBL01',
          created_at: '2020-06-03T13:06:23.915Z',
          updated_at: '2020-06-03T13:06:23.915Z',
          ubn: '1755543',
          ...generateBlockchainRecord(),
        },
        {
          id: 1013,
          updated_date: '2020-04-01T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP048052',
          name: 'Theeuwes-Moonen N.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.891Z',
          updated_at: '2020-06-03T13:06:23.891Z',
          ubn: '1740716',
          ...generateBlockchainRecord(),
        },
        {
          id: 1010,
          updated_date: '2020-04-05T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP018242',
          name: 'ForFarmers Nederland B.V., locatie Oosterhout',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.884Z',
          updated_at: '2020-06-03T13:06:23.884Z',
          ubn: '1723245',
          ...generateBlockchainRecord(),
        },
        {
          id: 1007,
          updated_date: '2020-04-05T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP018469',
          name: 'Agrifirm NWE BV',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.877Z',
          updated_at: '2020-06-03T13:06:23.877Z',
          ubn: '1708459',
          ...generateBlockchainRecord(),
        },
        {
          id: 1004,
          updated_date: '2020-04-06T20:59:56.000Z',
          code: 'IKBVA',
          reg_number: '   1671830',
          name: 'Mts A.L. ten Have - Mellema, MB \u0026 DH ten Have ',
          program: 'BLKV01',
          created_at: '2020-06-03T13:06:23.869Z',
          updated_at: '2020-06-03T13:06:23.869Z',
          ubn: '1671830',
          ...generateBlockchainRecord(),
        },
        {
          id: 997,
          updated_date: '2020-04-02T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP019103',
          name: 'Vitelia Voeders B.V.',
          program: 'BLKV01',
          created_at: '2020-06-03T13:06:23.853Z',
          updated_at: '2020-06-03T13:06:23.853Z',
          ubn: '1638969',
          ...generateBlockchainRecord(),
        },
        {
          id: 995,
          updated_date: '2020-04-05T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP051234',
          name: 'Rined Fourages B.V. (locatie Parlevinkerweg 2b)',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.849Z',
          updated_at: '2020-06-03T13:06:23.849Z',
          ubn: '1576203',
          ...generateBlockchainRecord(),
        },
        {
          id: 991,
          updated_date: '2020-04-02T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP012567',
          name: 'Coop De Valk Wekerom u.a.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.838Z',
          updated_at: '2020-06-03T13:06:23.838Z',
          ubn: '1086920',
          ...generateBlockchainRecord(),
        },
        {
          id: 989,
          updated_date: '2020-04-07T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP047845',
          name: 'Brons Mengvoeders B.V.',
          program: 'BLKV01',
          created_at: '2020-06-03T13:06:23.834Z',
          updated_at: '2020-06-03T13:06:23.834Z',
          ubn: '1009880',
          ...generateBlockchainRecord(),
        },
        {
          id: 985,
          updated_date: '2020-04-02T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP018092',
          name: 'Agrifirm NWE BV',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.825Z',
          updated_at: '2020-06-03T13:06:23.825Z',
          ubn: '0759306',
          ...generateBlockchainRecord(),
        },
        {
          id: 983,
          updated_date: '2020-04-07T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP047855',
          name: 'C.A.V. Den Ham U.A.',
          program: 'BLKV01',
          created_at: '2020-06-03T13:06:23.820Z',
          updated_at: '2020-06-03T13:06:23.820Z',
          ubn: '0693163',
          ...generateBlockchainRecord(),
        },
        {
          id: 980,
          updated_date: '2020-04-06T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP048084',
          name: 'Voergroep Zuid B.V.',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.814Z',
          updated_at: '2020-06-03T13:06:23.814Z',
          ubn: '0531799',
          ...generateBlockchainRecord(),
        },
        {
          id: 978,
          updated_date: '2020-04-01T20:59:56.000Z',
          code: 'GMP',
          reg_number: ' GMP018469',
          name: 'Agrifirm NWE BV',
          program: 'GFW',
          created_at: '2020-06-03T13:06:23.810Z',
          updated_at: '2020-06-03T13:06:23.810Z',
          ubn: '0391812',
          ...generateBlockchainRecord(),
        },
        {
          id: 45,
          updated_date: '2020-04-01T20:59:56.000Z',
          code: 'IKBVA',
          reg_number: '   0721190',
          name: 'Het Condé BV',
          program: 'BIO',
          created_at: '2020-05-28T11:29:45.267Z',
          updated_at: '2020-05-28T11:29:45.267Z',
          ubn: '1659560',
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

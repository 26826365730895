import { IntlKeys } from 'lib/localization/keys/__keys';

enum PenLevelOriginal {
  PenLevel = 'AS_PensLevel',
}

enum PenLevelSerialized {
  PenLevel = 'PenLevel',
}

enum PenLevelUnserialized {
  PenLevel = 'PenLevel',
}

enum PenLevelOriginalHeaders {
  'penLevel' = 'pensLevel',
  'penNameNL' = 'pensName',
  'penNameEN' = 'pensNameEN',
}

enum PenLevelSerializedHeaders {
  'penLevel' = 'penLevel',
  'penNameNL' = 'penNameNL',
  'penNameEN' = 'penNameEN',
}
enum PenLevelUnserializedHeaders {
  'penLevel' = 'pen_level',
  'penNameNL' = 'pen_name_nl',
  'penNameEN' = 'pen_name_en',
}

export const PEN_LEVEL_FIELDS = {
  [PenLevelSerialized.PenLevel]: {
    original: PenLevelOriginal.PenLevel,
    serialized: PenLevelSerialized.PenLevel,
    unserialized: PenLevelUnserialized.PenLevel,
    view: IntlKeys.referenceTablesAsPensLevel,
    viewName: 'AS_PensLevel',

    columns: {
      [PenLevelSerializedHeaders.penLevel]: {
        original: PenLevelOriginalHeaders.penLevel,
        serialized: PenLevelSerializedHeaders.penLevel,
        unserialized: PenLevelUnserializedHeaders.penLevel,
        view: IntlKeys.referenceTablesPensLevel,
      },
      [PenLevelSerializedHeaders.penNameNL]: {
        original: PenLevelOriginalHeaders.penNameNL,
        serialized: PenLevelSerializedHeaders.penNameNL,
        unserialized: PenLevelUnserializedHeaders.penNameNL,
        view: IntlKeys.referenceTablesPensName,
      },
      [PenLevelSerializedHeaders.penNameEN]: {
        original: PenLevelOriginalHeaders.penNameEN,
        serialized: PenLevelSerializedHeaders.penNameEN,
        unserialized: PenLevelUnserializedHeaders.penNameEN,
        view: IntlKeys.referenceTablesPensNameEN,
      },
    },
  },
};

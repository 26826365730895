import { SUPPLIERS_NUMBERS_FIELDS } from 'lib/excel/config/referenceTables/suppliersNumbers/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatUploadDate } from 'lib/excel/serilizers/utils';

const { columns, serialized, view } = SUPPLIERS_NUMBERS_FIELDS.SuppliersNumbers;

export const getSuppliersNumbersView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.codeList.serialized, viewPropName: columns.codeList.view }),
      new CellViewer({
        serializedPropName: columns.supplierNumber.serialized,
        viewPropName: columns.supplierNumber.view,
      }),
      new CellViewer({
        serializedPropName: columns.feedSupplierName.serialized,
        viewPropName: columns.feedSupplierName.view,
      }),
      new CellViewer({
        serializedPropName: columns.entryDate.serialized,
        viewPropName: columns.entryDate.view,
        conversionFunction: formatUploadDate,
      }),
      new CellViewer({
        serializedPropName: columns.dischargeDate.serialized,
        viewPropName: columns.dischargeDate.view,
        conversionFunction: formatUploadDate,
      }),
      new CellViewer({ serializedPropName: columns.remark.serialized, viewPropName: columns.remark.view }),
    ]),
  ]);
};

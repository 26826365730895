import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { get } from 'lodash';

import RowDropdown, { ActionConfig } from '../../components/DataTable/models/components/RowDropdown';
import RowDetailsOverlay from 'components/RowDetailsOverlay';
import RowDisplayConfigure from '../../components/DataTable/models/components/RowDisplayConfigure';
import Checkbox from '../../components/DataTable/models/components/Checkbox';
import { TableTabSettings } from './TableTab';
import { ObjectWithProps } from '../excel/serilizers/Cell';
import { HybridColumnSettings, HybridView } from './HybridView';
import { dateTimeFormat, renderToDateFormat } from '../excel/serilizers/utils';
import { SerializedServiceFields, SERVICE_FIELDS } from '../excel/config/service';
import { customSortAlgorithmFactory } from './utils/customSorting';
import {
  formatPercentsTo1Digits,
  roundTo1DigitsFloating,
  roundTo2Digits,
  roundTo4DigitsFloating,
  roundToInteger,
  suffixFormatter,
} from '../helpers/renderHelpers';
import { ServiceKeys } from 'lib/localization/keys/service';
import HashKeyRelevance from 'components/HashKeyRelevance';

export interface RowMenuI {
  headers: Array<HybridView>;
  data: ObjectWithProps;
}

enum MenuItems {
  details = 'details',
  hashKeyRelevance = 'hashKeyRelevance',
}

export const useDetailsRowMenu = ({ data, headers }: RowMenuI, handleClick?: () => void) =>
  useMemo(
    () =>
      headers.map(({ settings, renderDetails }) => {
        const { render, title, field } = settings;
        if (renderDetails) {
          return {
            label: title,
            text: renderDetails({ ...data, handleClick }),
          };
        } else {
          return {
            //
            label: title,
            text: typeof render === 'function' ? render(data, 'row') : data[(field as unknown) as keyof object],
          };
        }
      }),
    [data, headers],
  );

export const RowMenu: React.FC<RowMenuI> = ({ data, headers }) => {
  const [openMenuItem, setOpenMenuItem] = useState<MenuItems | null>(null);

  const handleClickOpen = (menuItem: MenuItems) => () => {
    setOpenMenuItem(menuItem);
  };

  const handleClose = () => {
    setOpenMenuItem(null);
  };

  const containsBlockchainHashKey = useMemo(
    () => headers.some(({ settings: { title } }) => title === SERVICE_FIELDS.blockchainHashKey.view),
    [headers],
  );

  const rowActions: ActionConfig = useMemo(() => {
    const actions = [{ title: 'Details', onClick: handleClickOpen(MenuItems.details) }];
    if (containsBlockchainHashKey) {
      actions.push({ title: 'Check hash key relevance', onClick: handleClickOpen(MenuItems.hashKeyRelevance) });
    }
    return actions;
  }, [containsBlockchainHashKey]);

  const detailsHeaders = headers.filter(({ hasDetailsView }) => hasDetailsView);

  const detailsPrimeHeader = detailsHeaders.find(({ isDetailsHeader }) => isDetailsHeader)!;
  const { settings } = detailsPrimeHeader;
  const { title, field } = settings;

  const detailRows = useDetailsRowMenu({ data, headers }, handleClickOpen(MenuItems.hashKeyRelevance));

  useEffect(() => {
    /* closes item if it was deleted  */

    // prevent closing after resource fetch
    if (openMenuItem === MenuItems.hashKeyRelevance) {
      return;
    }

    handleClose();
  }, [data]);

  return (
    <>
      <RowDropdown actions={rowActions} />

      {openMenuItem === MenuItems.details && (
        <RowDetailsOverlay
          title={title}
          titleValue={data[field!]}
          isOpen={openMenuItem === MenuItems.details}
          handleClose={handleClose}
          rows={detailRows}
        />
      )}

      {openMenuItem === MenuItems.hashKeyRelevance && (
        <HashKeyRelevance data={data} isOpen={openMenuItem === MenuItems.hashKeyRelevance} handleClose={handleClose} />
      )}
    </>
  );
};

export const checkboxRow = (settings: Partial<HybridColumnSettings> = {}) => {
  return ({
    // title: '',
    field: SERVICE_FIELDS[SerializedServiceFields.CHECKBOX].serialized,
    render: (data: object) => <Checkbox data={data} />,
    searchable: false,
    sorting: false,
    width: 50, // has to be the same as least
    ...settings,
  } as unknown) as HybridColumnSettings;
};

export const rowContextMenu = ({ headers, rowContextMenuProps }: TableTabSettings) => {
  const detailsHeaders = headers.filter(({ hasDetailsView }) => hasDetailsView);

  return ({
    title: <RowDisplayConfigure />,
    field: SERVICE_FIELDS[SerializedServiceFields.TABLE_MENU].serialized,
    render: (data: object) => <RowMenu headers={detailsHeaders} data={data} />,
    isService: true,
    headerStyle: {
      paddingRight: 5,
      paddingLeft: 5,
      textAlign: 'center',
    },
    cellStyle: {
      paddingRight: 5,
      paddingLeft: 5,
      textAlign: 'center',
    },
    width: 50, // has to be the same as last
    sorting: false,
    grouping: false,
    ...rowContextMenuProps,
  } as unknown) as HybridColumnSettings;
};

export const renderTruncatedText = (field: string | string[]) => (data: ObjectWithProps) => {
  const value = get(data, field, '');

  return (
    <Typography variant="body1" noWrap>
      {value}
    </Typography>
  );
};

export const renderDateShort = (field: string | string[], dateFormat: string = dateTimeFormat) => (
  data: ObjectWithProps,
) => {
  const value = get(data, field, '');

  return value ? renderToDateFormat(value, dateFormat) : value;
};

const renderBlockchainHashDetails = (data: ObjectWithProps) => {
  const { handleClick = () => undefined } = data;
  return (
    <>
      <div style={{ overflowX: 'auto', marginBottom: 8 }}>{data[SERVICE_FIELDS.blockchainHashKey.serialized]}</div>

      <Button color="primary" size="small" variant="contained" onClick={handleClick}>
        Check hash key relevance
      </Button>
    </>
  );
};

export const IDHybridView = new HybridView({
  id: SERVICE_FIELDS.id.serialized,
  isCardHeader: true,
  isDetailsHeader: true,
  settings: {
    title: SERVICE_FIELDS.id.view,
    field: SERVICE_FIELDS.id.serialized,
    width: 125,
  },
});

export const customIDHybridView = (title: string) =>
  new HybridView({
    id: SERVICE_FIELDS.id.serialized,
    isCardHeader: true,
    isDetailsHeader: true,
    settings: {
      title,
      field: SERVICE_FIELDS.id.serialized,
      width: 125,
    },
  });

export const BlockChainHashHybridView = new HybridView({
  id: SERVICE_FIELDS.blockchainHashKey.serialized,
  settings: {
    cellStyle: {
      wordBreak: 'break-all',
      minWidth: 250,
      maxWidth: 250,
    },
    headerStyle: {
      minWidth: 250,
      maxWidth: 250,
    },
    title: SERVICE_FIELDS.blockchainHashKey.view,
    field: SERVICE_FIELDS.blockchainHashKey.serialized,
    render: renderTruncatedText(SERVICE_FIELDS.blockchainHashKey.serialized),
  },
  renderDetails: renderBlockchainHashDetails,
});

export const UpdatedAtHybridView = new HybridView({
  id: SERVICE_FIELDS.updatedAt.serialized,
  settings: {
    title: SERVICE_FIELDS.updatedAt.view,
    field: SERVICE_FIELDS.updatedAt.serialized,
    render: renderDateShort(SERVICE_FIELDS.updatedAt.serialized),
    customSort: customSortAlgorithmFactory(SERVICE_FIELDS.updatedAt.serialized, 'date'),
  },
});

export const UploadedAtHybridView = new HybridView({
  id: SERVICE_FIELDS.uploadedAt.serialized,
  settings: {
    title: SERVICE_FIELDS.uploadedAt.view,
    field: SERVICE_FIELDS.uploadedAt.serialized,
    render: renderDateShort(SERVICE_FIELDS.uploadedAt.serialized),
    customSort: customSortAlgorithmFactory(SERVICE_FIELDS.uploadedAt.serialized, 'date'),
  },
});

export const CreatedAtHybridView = new HybridView({
  id: SERVICE_FIELDS.createdAt.serialized,
  settings: {
    title: SERVICE_FIELDS.createdAt.view,
    field: SERVICE_FIELDS.createdAt.serialized,
    render: renderDateShort(SERVICE_FIELDS.createdAt.serialized),
    customSort: customSortAlgorithmFactory(SERVICE_FIELDS.createdAt.serialized, 'date'),
  },
});

export const BlockChainTimestamp = new HybridView({
  id: SERVICE_FIELDS.blockchainTimestamp.serialized,
  settings: {
    title: SERVICE_FIELDS.blockchainTimestamp.view,
    field: SERVICE_FIELDS.blockchainTimestamp.serialized,
    render: renderDateShort(SERVICE_FIELDS.blockchainTimestamp.serialized),
    customSort: customSortAlgorithmFactory(SERVICE_FIELDS.blockchainTimestamp.serialized, 'date'),
  },
});

export const TableCheckBox = new HybridView({
  id: 'checkbox',
  settings: checkboxRow(),
  hasDetailsView: false,
  isService: true,
  serviceLabel: ServiceKeys.checkbox,
  isRemovable: false,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//    Number formats render helpers

const nDigitFormatters = {
  0: roundToInteger,
  1: roundTo1DigitsFloating,
  2: roundTo2Digits,
  4: roundTo4DigitsFloating,
};

export const renderPrice = (field: string | string[], priceUnit = '€') => {
  const formatter = suffixFormatter(roundTo2Digits, '', priceUnit);

  return (data: ObjectWithProps) => {
    const value = get(data, field, '');

    return value ? formatter(value) : value;
  };
};

/**
 * render factory
 *
 * @param field
 * @param n - digits after decimal separator; default = 2
 */
export const renderNumber = (field: string | string[], n: keyof typeof nDigitFormatters = 2) => {
  const formatter = nDigitFormatters[n];

  return (data: ObjectWithProps) => {
    const value = get(data, field, '');

    return value ? formatter(parseFloat(value)) : value;
  };
};

export const renderPercent = (field: string | string[]) => (data: ObjectWithProps) => {
  const value = get(data, field, '');

  return value ? formatPercentsTo1Digits(parseFloat(value)) : value;
};

export const renderNumberWithSuffix = (
  field: string | string[],
  prefix = '',
  suffix = '',
  n: keyof typeof nDigitFormatters = 2,
) => {
  const formatter = suffixFormatter(nDigitFormatters[n], suffix, prefix);

  return (data: ObjectWithProps) => {
    const value = get(data, field, '');

    return formatter(value);
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

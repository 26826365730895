import { TypedObjectKeys } from './Document';

export type ConversionFunction = (
  dataRow: TypedObjectKeys<any>,
  sheetData: Array<TypedObjectKeys<any>>,
  viewPropName: string,
  serializedPropName: string,
) => any;

const conversionFunctionStub = (
  dataRow: TypedObjectKeys<Array<TypedObjectKeys<CellViewer>>>,
  sheetData: Array<TypedObjectKeys<CellViewer>>,
  viewPropName: string,
  serializedPropName: string,
) => {
  return { [viewPropName]: dataRow[serializedPropName] };
};

interface CellViewerSettings {
  readonly serializedPropName: string;
  readonly viewPropName: string;
  readonly conversionFunction?: ConversionFunction;
}

export class CellViewer {
  readonly serializedPropName: string;
  readonly viewPropName: string;
  readonly conversionFunction: ConversionFunction;
  // constructor( // OLD CONSTRUCTOR
  //   private readonly serializedPropName: string,
  //   private readonly viewPropName: string,
  //   private readonly conversionFunction: ConversionFunction = conversionFunctionStub,
  // ) {}
  constructor({ serializedPropName, viewPropName, conversionFunction = conversionFunctionStub }: CellViewerSettings) {
    this.serializedPropName = serializedPropName;
    this.viewPropName = viewPropName;
    this.conversionFunction = conversionFunction;
  }

  getValue = (dataRow: TypedObjectKeys<any>, sheetData: Array<TypedObjectKeys<any>>) => {
    return this.conversionFunction(dataRow, sheetData, this.viewPropName, this.serializedPropName);
  };
}

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DAILY_DOCUMENT_FIELDS } from 'lib/excel/config/daily/structure';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

import { ResourcesWrapper } from 'lib/http/utils';

interface KiwaDaily {
  id: number;
  ubn: string;
  country_code: string;
  business_type: string;
  valid_from: string;
  valid_to: string;
  has_own_transport: boolean;
  remark: string;
  vc: string;
  qs_number: string;
  gg_number: string;
  pigs_tomorrow: boolean;
  blk: boolean;
  comment: string;
  salmonella_details: [
    {
      id: number;
      stat_type: string;
      count: number;
    },
  ];
}

const originalSheetColumns = DAILY_DOCUMENT_FIELDS.Register.columns;

const getCertificatesKiwaDailySerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(DAILY_DOCUMENT_FIELDS.Register.original, DAILY_DOCUMENT_FIELDS.Register.serialized, [
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(originalSheetColumns.regNumber.unserialized, originalSheetColumns.regNumber.serialized),
      new CellSerializer(
        originalSheetColumns.countryCodeLoc.unserialized,
        originalSheetColumns.countryCodeLoc.serialized,
      ),
      new CellSerializer(originalSheetColumns.businessType.unserialized, originalSheetColumns.businessType.serialized),
      new CellSerializer(
        originalSheetColumns.startDate.unserialized,
        originalSheetColumns.startDate.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(originalSheetColumns.ownTransport.unserialized, originalSheetColumns.ownTransport.serialized),
      new CellSerializer(
        originalSheetColumns.validUntil.unserialized,
        originalSheetColumns.validUntil.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(originalSheetColumns.remark.unserialized, originalSheetColumns.remark.serialized),
      new CellSerializer(originalSheetColumns.vc.unserialized, originalSheetColumns.vc.serialized),
      new CellSerializer(originalSheetColumns.qsNumber.unserialized, originalSheetColumns.qsNumber.serialized),
      new CellSerializer(originalSheetColumns.ggNr.unserialized, originalSheetColumns.ggNr.serialized),
      new CellSerializer(originalSheetColumns.pigTomorrow.unserialized, originalSheetColumns.pigTomorrow.serialized),
      new CellSerializer(originalSheetColumns.blk.unserialized, originalSheetColumns.blk.serialized),
      new CellSerializer(
        originalSheetColumns.certificateComment.unserialized,
        originalSheetColumns.certificateComment.serialized,
      ),
      new CellSerializer(
        originalSheetColumns.salmonellaInfo.unserialized,
        originalSheetColumns.salmonellaInfo.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeCertificatesInfo = (serverData: ResourcesWrapper<KiwaDaily>) => {
  const data = {
    [DAILY_DOCUMENT_FIELDS.Register.original]: serverData.data.resources,
  };
  const serializer = getCertificatesKiwaDailySerializer(data);

  return serializer.serialize();
};

import { GAS_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/gasConsumptionRates/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = GAS_CONSUMPTION_RATES_FIELDS.GasConsumptionRates;

export const getGasConsumptionRatesDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.houseType.original),
      new CellValidator(columns.gasConsumption.original),
    ]),
  ]);
};

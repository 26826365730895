import { IntlKeys } from 'lib/localization/keys';

enum CodesMatingTypeSerialized {
  CodesMatingType = 'CodesMatingType',
}

enum CodesMatingTypeUnserialized {
  CodesMatingType = 'CodesMatingType',
}

export enum CodesMatingTypeSerializedHeaders {
  id = 'id',
  matingTypeId = 'matingTypeId',
  note = 'note',
  matingTypeDatetime = 'matingTypeDatetime',
}

export const CodesMatingTypeUnserializedHeaders = {
  id: 'id',
  matingTypeId: 'mating_type_identifier',
  note: 'note',
  matingTypeDatetime: 'mating_type_datetime',
} as const;

export const CODES_MATING_TYPE = {
  [CodesMatingTypeSerialized.CodesMatingType]: {
    serialized: CodesMatingTypeSerialized.CodesMatingType,
    unserialized: CodesMatingTypeUnserialized.CodesMatingType,
    view: IntlKeys.farmManagementDataMatingTypeList,

    columns: {
      [CodesMatingTypeSerializedHeaders.id]: {
        serialized: CodesMatingTypeSerializedHeaders.id,
        unserialized: CodesMatingTypeUnserializedHeaders.id,
      },
      [CodesMatingTypeSerializedHeaders.matingTypeId]: {
        serialized: CodesMatingTypeSerializedHeaders.matingTypeId,
        unserialized: CodesMatingTypeUnserializedHeaders.matingTypeId,
        view: IntlKeys.farmManagementDataMatingTypeId,
      },
      [CodesMatingTypeSerializedHeaders.note]: {
        serialized: CodesMatingTypeSerializedHeaders.note,
        unserialized: CodesMatingTypeUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesMatingTypeSerializedHeaders.matingTypeDatetime]: {
        serialized: CodesMatingTypeSerializedHeaders.matingTypeDatetime,
        unserialized: CodesMatingTypeUnserializedHeaders.matingTypeDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
    },
  },
};

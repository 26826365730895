import { IntlKeys } from 'lib/localization/keys/__keys';
import { MovementEventsKeys } from 'lib/localization/keys/movementEvents';

enum MovementEventsSerialized {
  MovementEvents = 'MovementEvents',
}

enum MovementEventsUnserialized {
  MovementEvents = 'MovementEvents',
}

export enum MovementEventsSerializedHeaders {
  eventId = 'eventId',
  eventType = 'eventType',
  eventDateTime = 'eventDateTime',
  animalCategory = 'animalCategory',
  animalCount = 'animalCount',
  transportId = 'transportId',
  groupDate = 'groupDate',
  kvk = 'kvk',
  ubn = 'ubn',
  destinationEventLocationId = 'destinationEventLocationId',
  destinationScheme = 'destinationScheme',
  destinationEventIdMatch = 'destinationEventIdMatch',
  originEventLocationId = 'originEventLocationId',
  originScheme = 'originScheme',
  originEventIdMatch = 'originEventIdMatch',
  labels = 'labels',
}

export enum MovementEventsUnserializedHeaders {
  eventId = 'event_id',
  eventType = 'event_type',
  eventDateTime = 'event_date_time',
  animalCategory = 'animal_category',
  animalCount = 'animal_count',
  transportId = 'transport_id',
  groupDate = 'group_data',
  kvk = 'kvk',
  ubn = 'ubn',
  destination = 'destination',
  destinationEventLocationId = 'event_location_id',
  destinationScheme = 'scheme',
  destinationEventIdMatch = 'event_id_match',
  origin = 'origin',
  originEventLocationId = 'event_location_id',
  originScheme = 'scheme',
  originEventIdMatch = 'event_id_match',
  labels = 'labels',
}

export enum MovementEventsViewHeaders {
  eventId = 'Event ID',
  eventType = 'Event Type',
  eventDateTime = 'Event Date',
  animalCategory = 'Animal Category',
  animalCount = 'Animal Count',
  transportId = 'Transport ID',
  groupDate = 'Group Data',
  kvk = 'KVK',
  ubn = 'UBN',
  destination = 'Destination',
  origin = 'Origin',
  eventLocationId = 'Event Location ID',
  scheme = 'Scheme',
  eventIdMatch = 'Event ID Match',
}

export const MOVEMENT_EVENTS = {
  [MovementEventsSerialized.MovementEvents]: {
    serialized: MovementEventsSerialized.MovementEvents,
    unserialized: MovementEventsUnserialized.MovementEvents,
    view: IntlKeys.titleMovementEvents,
    columns: {
      [MovementEventsSerializedHeaders.eventId]: {
        serialized: MovementEventsSerializedHeaders.eventId,
        unserialized: MovementEventsUnserializedHeaders.eventId,
        view: MovementEventsKeys.eventId,
      },
      [MovementEventsSerializedHeaders.eventType]: {
        serialized: MovementEventsSerializedHeaders.eventType,
        unserialized: MovementEventsUnserializedHeaders.eventType,
        view: MovementEventsKeys.eventType,
      },
      [MovementEventsSerializedHeaders.eventDateTime]: {
        serialized: MovementEventsSerializedHeaders.eventDateTime,
        unserialized: MovementEventsUnserializedHeaders.eventDateTime,
        view: MovementEventsKeys.eventDateTime,
      },
      [MovementEventsSerializedHeaders.animalCategory]: {
        serialized: MovementEventsSerializedHeaders.animalCategory,
        unserialized: MovementEventsUnserializedHeaders.animalCategory,
        view: MovementEventsKeys.animalCategory,
      },
      [MovementEventsSerializedHeaders.animalCount]: {
        serialized: MovementEventsSerializedHeaders.animalCount,
        unserialized: MovementEventsUnserializedHeaders.animalCount,
        view: MovementEventsKeys.animalCount,
      },
      [MovementEventsSerializedHeaders.transportId]: {
        serialized: MovementEventsSerializedHeaders.transportId,
        unserialized: MovementEventsUnserializedHeaders.transportId,
        view: MovementEventsKeys.transportId,
      },
      [MovementEventsSerializedHeaders.groupDate]: {
        serialized: MovementEventsSerializedHeaders.groupDate,
        unserialized: MovementEventsUnserializedHeaders.groupDate,
        view: MovementEventsKeys.groupDate,
      },
      [MovementEventsSerializedHeaders.kvk]: {
        serialized: MovementEventsSerializedHeaders.kvk,
        unserialized: MovementEventsUnserializedHeaders.kvk,
        view: MovementEventsKeys.kvk,
      },
      [MovementEventsSerializedHeaders.ubn]: {
        serialized: MovementEventsSerializedHeaders.ubn,
        unserialized: MovementEventsUnserializedHeaders.ubn,
        view: MovementEventsKeys.ubn,
      },
      [MovementEventsSerializedHeaders.destinationEventLocationId]: {
        serialized: MovementEventsSerializedHeaders.destinationEventLocationId,
        unserialized: [
          MovementEventsUnserializedHeaders.destination,
          MovementEventsUnserializedHeaders.destinationEventLocationId,
        ],
        view: MovementEventsKeys.destination,
      },
      [MovementEventsSerializedHeaders.destinationScheme]: {
        serialized: MovementEventsSerializedHeaders.destinationScheme,
        unserialized: [
          MovementEventsUnserializedHeaders.destination,
          MovementEventsUnserializedHeaders.destinationScheme,
        ],
        view: MovementEventsKeys.destination,
      },
      [MovementEventsSerializedHeaders.destinationEventIdMatch]: {
        serialized: MovementEventsSerializedHeaders.destinationEventIdMatch,
        unserialized: [
          MovementEventsUnserializedHeaders.destination,
          MovementEventsUnserializedHeaders.destinationEventIdMatch,
        ],
        view: MovementEventsKeys.destination,
      },
      [MovementEventsSerializedHeaders.originEventLocationId]: {
        serialized: MovementEventsSerializedHeaders.originEventLocationId,
        unserialized: [
          MovementEventsUnserializedHeaders.origin,
          MovementEventsUnserializedHeaders.originEventLocationId,
        ],
        view: MovementEventsKeys.origin,
      },
      [MovementEventsSerializedHeaders.originScheme]: {
        serialized: MovementEventsSerializedHeaders.originScheme,
        unserialized: [MovementEventsUnserializedHeaders.origin, MovementEventsUnserializedHeaders.originScheme],
        view: MovementEventsKeys.origin,
      },
      [MovementEventsSerializedHeaders.originEventIdMatch]: {
        serialized: MovementEventsSerializedHeaders.originEventIdMatch,
        unserialized: [MovementEventsUnserializedHeaders.origin, MovementEventsUnserializedHeaders.originEventIdMatch],
        view: MovementEventsKeys.origin,
      },
      [MovementEventsSerializedHeaders.labels]: {
        serialized: MovementEventsSerializedHeaders.labels,
        unserialized: MovementEventsUnserializedHeaders.labels,
        view: IntlKeys.titleLabels,
      },
    },
  },
};

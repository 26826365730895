import { ClientStorage, PREF_LANG } from 'lib/storage/Storage';
import { AuthHeaderNames, AuthHeadersMapping } from 'store/auth/entities';

export const saveAuthHeadersInStorage = (headers: { [K in keyof typeof AuthHeadersMapping]: string }) => {
  AuthHeaderNames.forEach((frontKey) => {
    ClientStorage.setItem(frontKey, headers[frontKey]);
  });
};

export const removeAuthHeadersFromStorage = () => {
  AuthHeaderNames.forEach((frontKey) => {
    ClientStorage.removeItem(frontKey);
  });
};

export const removePrefLangFromStorage = () => {
  ClientStorage.removeItem(PREF_LANG);
};

export function getAuthHeadersFromStorage() {
  const token = ClientStorage.getItem('token');
  const type = ClientStorage.getItem('type');
  const uid = ClientStorage.getItem('uid');
  const client = ClientStorage.getItem('client');
  const expiry = ClientStorage.getItem('expiry');

  return {
    token,
    type,
    uid,
    client,
    expiry,
  };
}

import { WATER_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/waterConsumptionRates/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = WATER_CONSUMPTION_RATES_FIELDS.WaterConsumptionRates;

export const getWaterConsumptionRatesDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.householdPeopleQuantity.original),
      new CellValidator(columns.waterConsumption.original),
    ]),
  ]);
};

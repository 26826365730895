import { IntlKeys } from 'lib/localization/keys/__keys';

enum DeliverySegmentSerialized {
  DeliverySegment = 'DeliverySegment',
}

enum DeliverySegmentUnserialized {
  DeliverySegment = 'DeliverySegment',
}

enum DeliverySegmentSerializedHeaders {
  id = 'id',
  deliveryId = 'deliveryId',
  deliveryMessageNumber = 'deliveryMessageNumber',
  receiptDate = 'receiptDate',
  receiptPrintDate = 'receiptPrintDate',
  customerUbn = 'customerUbn',
  address = 'address',
  city = 'city',
  postalCode = 'postalCode',
  countryCode = 'countryCode',
  orderNumber = 'orderNumber',
  deliveryMode = 'deliveryMode',
  brsNumber = 'brsNumber',
}

enum DeliverySegmentUnserializedHeaders {
  id = 'id',
  deliveryId = 'delivery_id',
  deliveryMessageNumber = 'delivery_message_number',
  receiptDate = 'receipt_date',
  receiptPrintDate = 'receipt_print_date',
  customerUbn = 'customer_ubn',
  address = 'address',
  city = 'city',
  postalCode = 'postal_code',
  countryCode = 'country_code',
  orderNumber = 'order_number',
  deliveryMode = 'delivery_mode',
  brsNumber = 'brs_number',
}

export const DELIVERY_SEGMENT_FIELDS = {
  [DeliverySegmentSerialized.DeliverySegment]: {
    serialized: DeliverySegmentSerialized.DeliverySegment,
    unserialized: DeliverySegmentUnserialized.DeliverySegment,
    view: IntlKeys.titleDeliverySegment,
    columns: {
      [DeliverySegmentSerializedHeaders.id]: {
        serialized: DeliverySegmentSerializedHeaders.id,
        unserialized: DeliverySegmentUnserializedHeaders.id,
        view: IntlKeys.serviceBlockchainMessageId,
      },
      [DeliverySegmentSerializedHeaders.deliveryId]: {
        serialized: DeliverySegmentSerializedHeaders.deliveryId,
        unserialized: DeliverySegmentUnserializedHeaders.deliveryId,
        view: IntlKeys.deliveryUniqueKeyReceipt,
      },
      [DeliverySegmentSerializedHeaders.deliveryMessageNumber]: {
        serialized: DeliverySegmentSerializedHeaders.deliveryMessageNumber,
        unserialized: DeliverySegmentUnserializedHeaders.deliveryMessageNumber,
        view: IntlKeys.deliveryReceiptNumber,
      },
      [DeliverySegmentSerializedHeaders.receiptDate]: {
        serialized: DeliverySegmentSerializedHeaders.receiptDate,
        unserialized: DeliverySegmentUnserializedHeaders.receiptDate,
        view: IntlKeys.deliveryReceiptDate,
      },
      [DeliverySegmentSerializedHeaders.receiptPrintDate]: {
        serialized: DeliverySegmentSerializedHeaders.receiptPrintDate,
        unserialized: DeliverySegmentUnserializedHeaders.receiptPrintDate,
        view: IntlKeys.deliveryReceiptPrintDate,
      },
      [DeliverySegmentSerializedHeaders.customerUbn]: {
        serialized: DeliverySegmentSerializedHeaders.customerUbn,
        unserialized: DeliverySegmentUnserializedHeaders.customerUbn,
        view: IntlKeys.deliveryUbnNumber,
      },
      [DeliverySegmentSerializedHeaders.address]: {
        serialized: DeliverySegmentSerializedHeaders.address,
        unserialized: DeliverySegmentUnserializedHeaders.address,
        view: IntlKeys.deliveryStreetnameDeliveryAddress,
      },
      [DeliverySegmentSerializedHeaders.city]: {
        serialized: DeliverySegmentSerializedHeaders.city,
        unserialized: DeliverySegmentUnserializedHeaders.city,
        view: IntlKeys.deliveryCityNameDeliveryAddress,
      },
      [DeliverySegmentSerializedHeaders.postalCode]: {
        serialized: DeliverySegmentSerializedHeaders.postalCode,
        unserialized: DeliverySegmentUnserializedHeaders.postalCode,
        view: IntlKeys.deliveryPostcodeDeliveryAddress,
      },
      [DeliverySegmentSerializedHeaders.countryCode]: {
        serialized: DeliverySegmentSerializedHeaders.countryCode,
        unserialized: DeliverySegmentUnserializedHeaders.countryCode,
        view: IntlKeys.deliveryCountryDeliveryAddress,
      },
      [DeliverySegmentSerializedHeaders.orderNumber]: {
        serialized: DeliverySegmentSerializedHeaders.orderNumber,
        unserialized: DeliverySegmentUnserializedHeaders.orderNumber,
        view: IntlKeys.deliveryOrderNumber,
      },
      [DeliverySegmentSerializedHeaders.deliveryMode]: {
        serialized: DeliverySegmentSerializedHeaders.deliveryMode,
        unserialized: DeliverySegmentUnserializedHeaders.deliveryMode,
        view: IntlKeys.deliveryDeliveryMethod,
      },
      [DeliverySegmentSerializedHeaders.brsNumber]: {
        serialized: DeliverySegmentSerializedHeaders.brsNumber,
        unserialized: DeliverySegmentUnserializedHeaders.brsNumber,
        view: IntlKeys.deliveryBrsNumber,
      },
    },
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { GROUP_WEIGHTS } from 'store/entities/farmManagementData/groupWeights/config';

const { columns, view, serialized } = GROUP_WEIGHTS.GroupWeights;

export const groupWeightsTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.finEventIdentifier.view,
          field: columns.finEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.number.view,
          field: columns.number.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.totalWeight.view,
          field: columns.totalWeight.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.notes.view,
          field: columns.notes.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupWeightDate.view,
          field: columns.groupWeightDate.serialized,
        },
      }),
    ],
  }),
]);

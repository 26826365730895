import { PermissionsEntry, rolePermissions } from './permissions';
import { Languages } from 'lib/localization';

type UserRole = 'content_admin' | 'user_admin' | 'monitoring_admin' | 'simple_user';

export enum UserRoles {
  simpleUser = 'simpleUser',
  contentAdmin = 'contentAdmin',
  userAdmin = 'userAdmin',
  monitoringAdmin = 'monitoringAdmin',
}

const userRolesMapping = {
  content_admin: UserRoles.contentAdmin,
  user_admin: UserRoles.userAdmin,
  monitoring_admin: UserRoles.monitoringAdmin,
  simple_user: UserRoles.simpleUser,
};

export interface UserI {
  readonly id: number;
  readonly fullName: string;
  readonly email: string;
  readonly prefLang: keyof typeof Languages;
  readonly colorTheme: string;
  readonly userRole: UserRoles;
  readonly permissions: PermissionsEntry;
}

export interface UserResource {
  allow_password_change: boolean;
  company_id: number;
  created_at: string;
  email: string;
  id: number;
  image: string | null;
  full_name: string;
  nickname: string | null;
  user_role: UserRole;
  provider: string;
  uid: string;
  updated_at: string;
  pref_lang: keyof typeof Languages;
  user_profile_setting: {
    pagination_active: boolean;
  };
  profile_settings: {
    color_theme: string;
  };
}

// TODO keymapping according to AuthInfo
export class User implements UserI {
  readonly id: number;
  readonly fullName: string;
  readonly email: string;
  readonly prefLang: keyof typeof Languages;
  readonly paginationActive: boolean;
  readonly colorTheme: string;
  readonly userRole: UserRoles;
  readonly permissions: PermissionsEntry;

  constructor({ id, full_name, email, user_role, pref_lang, user_profile_setting, profile_settings }: UserResource) {
    this.id = id;
    this.fullName = full_name;
    this.email = email;
    this.prefLang = pref_lang;
    this.paginationActive = user_profile_setting?.pagination_active;
    this.colorTheme = profile_settings?.color_theme;

    this.userRole = userRolesMapping[user_role];
    this.permissions = rolePermissions[this.userRole];
  }
}

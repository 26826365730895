import { AxiosResponse } from 'axios';
import { Serializer } from 'store/serializers/Serializer';
import { User, UserResource } from 'store/auth/User';

/* ------ headers example
  access-token: "TOKENTOKENTOKENTOKEN"
  cache-control: "max-age=0, private, must-revalidate"
  client: "CLIENTCLIENTCLIENT"
  content-type: "application/json; charset=utf-8"
  expiry: "1555555555"
  token-type: "Bearer"
  uid: "user@example.com"
*/

export enum AuthHeadersMapping {
  token = 'access-token',
  type = 'token-type',
  uid = 'uid',
  client = 'client',
  expiry = 'expiry',
}

export const AuthHeaderNames: (keyof typeof AuthHeadersMapping)[] = ['token', 'type', 'uid', 'client', 'expiry'];

export interface AuthHeaders {
  token: string;
  type: string;
  uid: string;
  client: string;
  expiry: string;
}

export interface AuthInfoI {
  headers: Partial<AuthHeaders>;
  user: Partial<UserResource>;
}

export class AuthInfo implements AuthInfoI {
  headers = {};
  user = {};

  constructor(response: AxiosResponse) {
    const headers = response.headers;

    this.headers = AuthHeaderNames.reduce((acc, frontKey) => {
      acc[frontKey] = headers[AuthHeadersMapping[frontKey]];

      return acc;
    }, {} as AuthHeaders);

    this.user = Serializer.serialize(User, response.data.resource);
  }
}

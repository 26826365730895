import { IntlKeys, TitlesKeys } from 'lib/localization/keys';

enum AdminUpdatesSerialized {
  AdminUpdates = 'AdminUpdates',
}

enum AdminUpdatesUnserialized {
  AdminUpdates = 'AdminUpdates',
}

export enum AdminUsersSerializedHeaders {
  triggeredBy = 'triggeredBy',
  action = 'action',
  actionType = 'actionType',
  payload = 'payload',
  status = 'status',
  diff = 'diff',
  loggableType = 'loggableType',
}

export const ADMIN_UPDATES = {
  [AdminUpdatesSerialized.AdminUpdates]: {
    serialized: AdminUpdatesSerialized.AdminUpdates,
    unserialized: AdminUpdatesUnserialized.AdminUpdates,
    view: TitlesKeys.farmUpdates,

    columns: {
      [AdminUsersSerializedHeaders.triggeredBy]: {
        serialized: AdminUsersSerializedHeaders.triggeredBy,
        view: IntlKeys.userAdminUpdatesLogCreatedBy,
      },
      [AdminUsersSerializedHeaders.action]: {
        serialized: AdminUsersSerializedHeaders.action,
        view: IntlKeys.userAdminUpdatesLogAction,
      },
      [AdminUsersSerializedHeaders.actionType]: {
        serialized: AdminUsersSerializedHeaders.actionType,
        view: IntlKeys.userAdminUpdatesLogActionType,
      },
      [AdminUsersSerializedHeaders.status]: {
        serialized: AdminUsersSerializedHeaders.status,
        view: IntlKeys.status,
      },
      [AdminUsersSerializedHeaders.diff]: {
        serialized: AdminUsersSerializedHeaders.diff,
        view: IntlKeys.details,
      },
      [AdminUsersSerializedHeaders.payload]: {
        serialized: AdminUsersSerializedHeaders.payload,
        view: IntlKeys.details,
      },
      [AdminUsersSerializedHeaders.loggableType]: {
        serialized: AdminUsersSerializedHeaders.loggableType,
        view: IntlKeys.userAdminUpdatesLogLoggableType,
      },
    },
  },
};

import { get } from 'lodash';

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellAggregationFunction, CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';

import {
  JOINDATA_PARTICIPATIONS,
  JoindataParticipationsSerialized,
} from 'lib/tables/config/joindata/participations/config';

interface MyJoindataEntry {
  company_scheme: string;
  company_value: string;
  end_date: string;
  granted_on: string;
  refused_on: string;
  replaced_by_id: string;
  revoked_on: string;
  start_date: string;
  status: number;
  is_valid: boolean;
  purpose: {
    name: string;
  };
  restrictions: [
    {
      restriction_type: string;
      description: string;
      date_start: string;
      date_end: string;
      scheme: string;
    },
  ];
}

type Restriction = MyJoindataEntry['restrictions'][0];

const getFirstRestrictionValue: (field: keyof Restriction) => CellAggregationFunction<MyJoindataEntry> = (
  field: string,
) => (data, sheetData, excelRow) => {
  return get(excelRow, ['restrictions', '0', field], '');
};

const { columns, serialized, unserialized } = JOINDATA_PARTICIPATIONS[
  JoindataParticipationsSerialized.JoindataParticipations
];

const getSerializer = (data: DocumentData<MyJoindataEntry[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.kvk.unserialized, columns.kvk.serialized),
      new CellSerializer(columns.name.unserialized, columns.name.serialized),
      new CellSerializer(columns.participationGrantedOn.unserialized, columns.participationGrantedOn.serialized),
      new CellSerializer(columns.participationStartDate.unserialized, columns.participationStartDate.serialized),
      new CellSerializer(columns.participationStatus.unserialized, columns.participationStatus.serialized),
      new CellSerializer(columns.participationValid.unserialized, columns.participationValid.serialized),
      new CellSerializer(
        columns.restrictionType.unserialized,
        columns.restrictionType.serialized,
        getFirstRestrictionValue('restriction_type'),
      ),
      new CellSerializer(
        columns.restrictionDescription.unserialized,
        columns.restrictionDescription.serialized,
        getFirstRestrictionValue('description'),
      ),
      new CellSerializer(
        columns.restrictionStart.unserialized,
        columns.restrictionStart.serialized,
        getFirstRestrictionValue('date_start'),
      ),
      //
    ]),
  ]);
};

export const serializeJoindataParticipations = (serverData: ResourcesWrapper<MyJoindataEntry>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

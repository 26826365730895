import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/store';
import { authIsAuthorizedSelector, isMaintenanceSelector } from 'store/auth/reducer';

import { makeStyles } from '@material-ui/core';

import NavigationBar from 'components/Header/Header';
import SideNavMenu from './SideNavMenu';
import MaintenanceModal from './maintetanceModal';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const { isAuthorized, isMaintenance } = useSelector(mapStateToProps);

  return (
    <>
      {isAuthorized && (
        <>
          <NavigationBar isSideBarOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
          <SideNavMenu isOpen={isSideBarOpen} setIsOpen={setIsSideBarOpen} />
        </>
      )}

      <div className={classes.layoutContent}>{children}</div>

      {isMaintenance && <MaintenanceModal />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthorized: authIsAuthorizedSelector(state),
  isMaintenance: isMaintenanceSelector(state),
});

const useStyles = makeStyles(() => ({
  layoutContent: {
    maxWidth: 1280,
    margin: '0 auto',
    height: '100%',
    minHeight: '100vh'
  },
}));

export default MainLayout;

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_ARRIVAL } from './config';

export type GroupArrivalPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  financial_event_identifer: string;
  animal_category_identifier: string;
  group_arrival_number: number;
  total_weight: number;
  origin_identifer: string;
  group_arrival_datetime: string;
  own_farm: string;
  without_male: string;
  without_female: string;
  without_male_castrated: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type GroupArrivalSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  financialEventIdentifier: string;
  animalCategoryIdentifier: string;
  groupArrivalNumber: number;
  totalWeight: number;
  originIdentifier: string;
  groupArrivalDatetime: string;
  ownFarm: string;
  withoutMale: string;
  withoutFemale: string;
  withoutMaleCastrated: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = GROUP_ARRIVAL.GroupArrival;

const getDefaultSerializer = (data: DocumentData<GroupArrivalPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.financialEventIdentifier.unserialized, columns.financialEventIdentifier.serialized),
      new CellSerializer(columns.animalCategoryIdentifier.unserialized, columns.animalCategoryIdentifier.serialized),
      new CellSerializer(columns.groupArrivalNumber.unserialized, columns.groupArrivalNumber.serialized),
      new CellSerializer(columns.totalWeight.unserialized, columns.totalWeight.serialized),
      new CellSerializer(columns.originIdentifier.unserialized, columns.originIdentifier.serialized),
      new CellSerializer(columns.groupArrivalDatetime.unserialized, columns.groupArrivalDatetime.serialized),
      new CellSerializer(columns.ownFarm.unserialized, columns.ownFarm.serialized),
      new CellSerializer(columns.withoutMale.unserialized, columns.withoutMale.serialized),
      new CellSerializer(columns.withoutFemale.unserialized, columns.withoutFemale.serialized),
      new CellSerializer(columns.withoutMaleCastrated.unserialized, columns.withoutMaleCastrated.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeGroupArrival = (serverData: ResourcesWrapper<GroupArrivalPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys';

enum CvbTableSerialized {
  CvbTable = 'CvbTable',
}

enum CvbTableUnserialized {
  CvbTable = 'CvbTable',
}

enum CvbTableOriginal {
  CvbTable = 'CFP_CVB_table',
}

export enum CvbTableSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  productCode = 'productCode',
  productSubCode = 'productSubCode',
  productType = 'productType',
  productNameNL = 'productNameNL',
  classNameNL = 'classNameNL',
  subClassNameNL = 'subClassNameNL',
  productNameEN = 'productNameEN',
  classNameEN = 'classNameEN',
  subClassNameEN = 'subClassNameEN',
  ds = 'ds',
  dsUnit = 'dsUnit',
  ras = 'ras',
  rasUnit = 'rasUnit',
  rein = 'rein',
  reinUnit = 'reinUnit',
  re = 're',
  reUnit = 'reUnit',
  cVcRe = 'cVcRe',
  cVcReUnit = 'cVcReUnit',
  cVcOs = 'cVcOs',
  cVcOsUnit = 'cVcOsUnit',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export enum CvbTableOriginalHeaders {
  id = 'id',
  referenceUploadID = 'reference_upload_id',
  productCode = 'product_code',
  productSubCode = 'product_sub_code',
  productType = 'product_type',
  productNameNL = 'product_name_nl',
  classNameNL = 'class_name_nl',
  subClassNameNL = 'sub_class_name_nl',
  productNameEN = 'product_name_en',
  classNameEN = 'class_name_en',
  subClassNameEN = 'sub_class_name_en',
  ds = 'ds',
  dsUnit = 'ds_unit',
  ras = 'ras',
  rasUnit = 'ras_unit',
  rein = 'rein',
  reinUnit = 'rein_unit',
  re = 're',
  reUnit = 're_unit',
  cVcRe = 'cvc_re',
  cVcReUnit = 'cvc_re_unit',
  cVcOs = 'cvc_os',
  cVcOsUnit = 'cvc_os_unit',
  uploadType = 'upload_type',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'valid_date_from',
  validDateTo = 'valid_date_to',
  notes = 'notes',
  uploadedAt = 'uploaded_at',
  uploadedBy = 'uploaded_by',
}

export const CvbTableUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['carbon_foot_print_upload', 'id'],
  productCode: 'product_code',
  productSubCode: 'product_sub_code',
  productType: 'product_type',
  productNameNL: 'product_name_nl',
  classNameNL: 'class_name_nl',
  subClassNameNL: 'sub_class_name',
  productNameEN: 'product_name_en',
  classNameEN: 'class_name_en',
  subClassNameEN: 'sub_class_name_en',
  ds: 'ds',
  dsUnit: 'ds_unit',
  ras: 'ras',
  rasUnit: 'remote_unit',
  rein: 'rein',
  reinUnit: 'rein_unit',
  re: 're',
  reUnit: 're_unit',
  cVcRe: 'c_vc_re',
  cVcReUnit: 'c_vc_re_unit',
  cVcOs: 'c_vc_os',
  cVcOsUnit: 'c_vc_os_unit',
  uploadType: ['carbon_foot_print_upload', 'upload_type'],
  filename: ['carbon_foot_print_upload', 'file_name'],
  status: ['carbon_foot_print_upload', 'status'],
  validDateFrom: ['carbon_foot_print_upload', 'valid_from'],
  validDateTo: ['carbon_foot_print_upload', 'valid_to'],
  notes: ['carbon_foot_print_upload', 'notes'],
  uploadedAt: ['carbon_foot_print_upload', 'uploaded_at'],
  uploadedBy: ['carbon_foot_print_upload', 'uploaded_by', 'full_name'],
} as const;

export const CVB_TABLE = {
  [CvbTableSerialized.CvbTable]: {
    serialized: CvbTableSerialized.CvbTable,
    unserialized: CvbTableUnserialized.CvbTable,
    original: CvbTableOriginal.CvbTable,
    view: IntlKeys.referenceTablesCvbTable,

    columns: {
      [CvbTableSerializedHeaders.id]: {
        serialized: CvbTableSerializedHeaders.id,
        unserialized: CvbTableUnserializedHeaders.id,
        original: CvbTableOriginalHeaders.id,
      },
      [CvbTableSerializedHeaders.referenceUploadID]: {
        serialized: CvbTableSerializedHeaders.referenceUploadID,
        unserialized: CvbTableUnserializedHeaders.referenceUploadID,
        original: CvbTableOriginalHeaders.referenceUploadID,
      },
      [CvbTableSerializedHeaders.productCode]: {
        serialized: CvbTableSerializedHeaders.productCode,
        unserialized: CvbTableUnserializedHeaders.productCode,
        view: IntlKeys.referenceTablesProductCode,
        original: CvbTableOriginalHeaders.productCode,
      },
      [CvbTableSerializedHeaders.productSubCode]: {
        serialized: CvbTableSerializedHeaders.productSubCode,
        unserialized: CvbTableUnserializedHeaders.productSubCode,
        view: IntlKeys.referenceTablesProductSubCode,
        original: CvbTableOriginalHeaders.productSubCode,
      },
      [CvbTableSerializedHeaders.productType]: {
        serialized: CvbTableSerializedHeaders.productType,
        unserialized: CvbTableUnserializedHeaders.productType,
        view: IntlKeys.referenceTablesProductType,
        original: CvbTableOriginalHeaders.productType,
      },
      [CvbTableSerializedHeaders.productNameNL]: {
        serialized: CvbTableSerializedHeaders.productNameNL,
        unserialized: CvbTableUnserializedHeaders.productNameNL,
        view: IntlKeys.referenceTablesProductName,
        original: CvbTableOriginalHeaders.productNameNL,
      },
      [CvbTableSerializedHeaders.classNameNL]: {
        serialized: CvbTableSerializedHeaders.classNameNL,
        unserialized: CvbTableUnserializedHeaders.classNameNL,
        view: IntlKeys.referenceTablesClassName,
        original: CvbTableOriginalHeaders.classNameNL,
      },
      [CvbTableSerializedHeaders.subClassNameNL]: {
        serialized: CvbTableSerializedHeaders.subClassNameNL,
        unserialized: CvbTableUnserializedHeaders.subClassNameNL,
        view: IntlKeys.referenceTablesSubClassName,
        original: CvbTableOriginalHeaders.subClassNameNL,
      },
      [CvbTableSerializedHeaders.productNameEN]: {
        serialized: CvbTableSerializedHeaders.productNameEN,
        unserialized: CvbTableUnserializedHeaders.productNameEN,
        view: IntlKeys.referenceTablesProductNameEN,
        original: CvbTableOriginalHeaders.productNameEN,
      },
      [CvbTableSerializedHeaders.classNameEN]: {
        serialized: CvbTableSerializedHeaders.classNameEN,
        unserialized: CvbTableUnserializedHeaders.classNameEN,
        view: IntlKeys.referenceTablesClassNameEN,
        original: CvbTableOriginalHeaders.classNameEN,
      },
      [CvbTableSerializedHeaders.subClassNameEN]: {
        serialized: CvbTableSerializedHeaders.subClassNameEN,
        unserialized: CvbTableUnserializedHeaders.subClassNameEN,
        view: IntlKeys.referenceTablesSubClassNameEN,
        original: CvbTableOriginalHeaders.subClassNameEN,
      },
      [CvbTableSerializedHeaders.ds]: {
        serialized: CvbTableSerializedHeaders.ds,
        unserialized: CvbTableUnserializedHeaders.ds,
        view: IntlKeys.referenceTablesDs,
        original: CvbTableOriginalHeaders.ds,
      },
      [CvbTableSerializedHeaders.dsUnit]: {
        serialized: CvbTableSerializedHeaders.dsUnit,
        unserialized: CvbTableUnserializedHeaders.dsUnit,
        view: IntlKeys.referenceTablesDsUnit,
        original: CvbTableOriginalHeaders.dsUnit,
      },
      [CvbTableSerializedHeaders.ras]: {
        serialized: CvbTableSerializedHeaders.ras,
        unserialized: CvbTableUnserializedHeaders.ras,
        view: IntlKeys.referenceTablesRas,
        original: CvbTableOriginalHeaders.ras,
      },
      [CvbTableSerializedHeaders.rasUnit]: {
        serialized: CvbTableSerializedHeaders.rasUnit,
        unserialized: CvbTableUnserializedHeaders.rasUnit,
        view: IntlKeys.referenceTablesRasUnit,
        original: CvbTableOriginalHeaders.rasUnit,
      },
      [CvbTableSerializedHeaders.rein]: {
        serialized: CvbTableSerializedHeaders.rein,
        unserialized: CvbTableUnserializedHeaders.rein,
        view: IntlKeys.referenceTablesRein,
        original: CvbTableOriginalHeaders.rein,
      },
      [CvbTableSerializedHeaders.reinUnit]: {
        serialized: CvbTableSerializedHeaders.reinUnit,
        unserialized: CvbTableUnserializedHeaders.reinUnit,
        view: IntlKeys.referenceTablesReinUnit,
        original: CvbTableOriginalHeaders.reinUnit,
      },
      [CvbTableSerializedHeaders.re]: {
        serialized: CvbTableSerializedHeaders.re,
        unserialized: CvbTableUnserializedHeaders.re,
        view: IntlKeys.referenceTablesRe,
        original: CvbTableOriginalHeaders.re,
      },
      [CvbTableSerializedHeaders.reUnit]: {
        serialized: CvbTableSerializedHeaders.reUnit,
        unserialized: CvbTableUnserializedHeaders.reUnit,
        view: IntlKeys.referenceTablesReUnit,
        original: CvbTableOriginalHeaders.reUnit,
      },
      [CvbTableSerializedHeaders.cVcRe]: {
        serialized: CvbTableSerializedHeaders.cVcRe,
        unserialized: CvbTableUnserializedHeaders.cVcRe,
        view: IntlKeys.referenceTablesCvcRe,
        original: CvbTableOriginalHeaders.cVcRe,
      },
      [CvbTableSerializedHeaders.cVcReUnit]: {
        serialized: CvbTableSerializedHeaders.cVcReUnit,
        unserialized: CvbTableUnserializedHeaders.cVcReUnit,
        view: IntlKeys.referenceTablesCvcReUnit,
        original: CvbTableOriginalHeaders.cVcReUnit,
      },
      [CvbTableSerializedHeaders.cVcOs]: {
        serialized: CvbTableSerializedHeaders.cVcOs,
        unserialized: CvbTableUnserializedHeaders.cVcOs,
        view: IntlKeys.referenceTablesCvcOs,
        original: CvbTableOriginalHeaders.cVcOs,
      },
      [CvbTableSerializedHeaders.cVcOsUnit]: {
        serialized: CvbTableSerializedHeaders.cVcOsUnit,
        unserialized: CvbTableUnserializedHeaders.cVcOsUnit,
        view: IntlKeys.referenceTablesCvcOsUnit,
        original: CvbTableOriginalHeaders.cVcOsUnit,
      },
      [CvbTableSerializedHeaders.status]: {
        serialized: CvbTableSerializedHeaders.status,
        unserialized: CvbTableUnserializedHeaders.status,
        original: CvbTableOriginalHeaders.status,
      },
      [CvbTableSerializedHeaders.uploadType]: {
        serialized: CvbTableSerializedHeaders.uploadType,
        unserialized: CvbTableUnserializedHeaders.uploadType,
        original: CvbTableOriginalHeaders.uploadType,
      },
      [CvbTableSerializedHeaders.notes]: {
        serialized: CvbTableSerializedHeaders.notes,
        unserialized: CvbTableUnserializedHeaders.notes,
        original: CvbTableOriginalHeaders.notes,
      },
      [CvbTableSerializedHeaders.filename]: {
        serialized: CvbTableSerializedHeaders.filename,
        unserialized: CvbTableUnserializedHeaders.filename,
        original: CvbTableOriginalHeaders.filename,
      },
      [CvbTableSerializedHeaders.validDateFrom]: {
        serialized: CvbTableSerializedHeaders.validDateFrom,
        unserialized: CvbTableUnserializedHeaders.validDateFrom,
        original: CvbTableOriginalHeaders.validDateFrom,
      },
      [CvbTableSerializedHeaders.validDateTo]: {
        serialized: CvbTableSerializedHeaders.validDateTo,
        unserialized: CvbTableUnserializedHeaders.validDateTo,
        original: CvbTableOriginalHeaders.validDateTo,
      },
      [CvbTableSerializedHeaders.uploadedAt]: {
        serialized: CvbTableSerializedHeaders.uploadedAt,
        unserialized: CvbTableUnserializedHeaders.uploadedAt,
        original: CvbTableOriginalHeaders.uploadedAt,
      },
      [CvbTableSerializedHeaders.uploadedBy]: {
        serialized: CvbTableSerializedHeaders.uploadedBy,
        unserialized: CvbTableUnserializedHeaders.uploadedBy,
        original: CvbTableOriginalHeaders.uploadedBy,
      },
    },
  },
};

import { DocumentSerializer } from '../../../serilizers/Document';
import { DocumentData, SheetSerializer } from '../../../serilizers/Sheet';
import { CellSerializer } from '../../../serilizers/Cell';
import { WEEKLY_DOCUMENT_FIELDS } from '../structure';
import { propToString } from '../../../serilizers/utils';

export const unserializeWeeklyData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(WEEKLY_DOCUMENT_FIELDS.Company.serialized, WEEKLY_DOCUMENT_FIELDS.Company.unserialized, [
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.ubn.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.ubn.unserialized,
        propToString,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.name.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.name.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.address.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.address.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.postalCode.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.postalCode.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.place.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.place.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.phone.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.phone.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.date.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.date.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.sows.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.sows.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.vlv.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.vlv.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.program.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.program.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.remark.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.remark.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.type.serialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.type.unserialized,
      ),
    ]),

    new SheetSerializer(
      WEEKLY_DOCUMENT_FIELDS.Recognition.serialized,
      WEEKLY_DOCUMENT_FIELDS.Recognition.unserialized,
      [
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.ubn.serialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.ubn.unserialized,
          propToString,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.date.serialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.date.unserialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.program.serialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.program.unserialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.code.serialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.code.unserialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.regnr.serialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.regnr.unserialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.name.serialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.name.unserialized,
        ),
      ],
    ),
    new SheetSerializer(WEEKLY_DOCUMENT_FIELDS.Deviations.serialized, WEEKLY_DOCUMENT_FIELDS.Deviations.unserialized, [
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.ubn.serialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.ubn.unserialized,
        propToString,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.program.serialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.program.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.date.serialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.date.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.questionCode.serialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.questionCode.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.remark.serialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.remark.unserialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.answer.serialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.answer.unserialized,
      ),
    ]),
  ]);
};

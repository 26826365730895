import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
// @ts-ignore
import { ObjectHashCalculator } from 'vbbc-common-utilities';

import { currentRouteSelector } from 'store/reducers/routerReducer';

import { api } from 'lib/http/utils';
import { EnvVars } from 'lib/env/Env';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { Params } from 'lib/ensure';

type Result<T = {}> = T & {
  hash: null | string;
  isLoading: boolean;
  error: null | string;
};

export const useHashKeyRelevance = (blockchainHashKey: string, id: number, blockchainId: string) => {
  const urlParams = useParams();
  const routeEndpoint = useSelector(currentRouteSelector)?.endpoint;

  const [batch, setBatch] = useState<any | null>(null);
  const [batchError, setBatchError] = useState<string | null>(null);
  const [batchIsLoading, setBatchIsLoading] = useState(false);

  const [nodeBatch, setNodeBatch] = useState<any | null>(null);
  const [nodeBatchError, setNodeBatchError] = useState<string | null>(null);
  const [nodeBatchIsLoading, setNodeBatchIsLoading] = useState(false);

  const endpoint = useMemo(() => (typeof routeEndpoint === 'function' ? routeEndpoint(urlParams) : routeEndpoint), [
    routeEndpoint,
    urlParams,
  ]);

  const fetchBatch = useCallback(() => {
    setBatchError(null);
    setBatchIsLoading(true);
    const apiCall = api('get', `${endpoint}/${id}`);
    apiCall({} as Params)
      .then(({ data }) => {
        setBatchIsLoading(false);
        setBatch(data?.resource);
      })
      .catch((e) => {
        console.error(e);
        setBatchIsLoading(false);
        setBatchError('Retrieving data error');
        setBatch(null);
      });
  }, [endpoint, id]);

  const fetchNodeBatch = useCallback(
    (envVar: EnvVars) => {
      setNodeBatchError(null);
      setNodeBatchIsLoading(true);

      const apiCall = api('post', 'ReadMemoListTx');
      apiCall({ baseURL: process.env[envVar], data: { idList: [blockchainId] } } as Params)
        .then(({ data }) => {
          setNodeBatchIsLoading(false);
          setNodeBatch(data);
        })
        .catch((e) => {
          console.error(e);
          setNodeBatchIsLoading(false);
          setNodeBatchError('Retrieving data error');
          setNodeBatch(null);
        });
    },
    [blockchainId],
  );

  const calculated = useMemo(() => {
    const result: Result<{ serialized: null | string }> = {
      hash: null,
      serialized: null,
      error: null,
      isLoading: batchIsLoading,
    };

    if (batchError) {
      result.error = 'Retrieving data error';
      return result;
    }
    if (!batch) {
      return result;
    }

    const blockchainProperties = batch?.active_blockchain_record?.properties;

    if (batch && blockchainProperties) {
      try {
        const hashCalculator = new ObjectHashCalculator(batch, blockchainProperties);
        result.hash = hashCalculator.calculateHash();
        result.serialized = hashCalculator.unEscapeAmp(hashCalculator.serializeEntityData());
      } catch (e) {
        console.error(e);
        result.error = 'Hash calculation error';
      }
    } else {
      result.error = 'Not yet available';
    }
    return result;
  }, [batch, batchError, batchIsLoading]);

  const actual = useMemo<Result>(
    () => ({
      hash: nodeBatch?.find(({ id }: ObjectWithProps) => id === blockchainId)?.hash as string,
      error: nodeBatchError,
      isLoading: nodeBatchIsLoading,
    }),
    [nodeBatchIsLoading, blockchainId, nodeBatch, nodeBatchError],
  );

  const onClick = (envVar: EnvVars) => {
    if (blockchainHashKey !== calculated.hash) {
      fetchBatch();
    }
    fetchNodeBatch(envVar);
  };

  return { calculated, actual, onClick };
};

import { CL650_FIELDS } from 'lib/excel/config/referenceTables/cl650/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = CL650_FIELDS.Cl650;

export const getCl650DisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.code.serialized, extractPropName: columns.code.view }),
      new DisplayHeader({ headerName: columns.description.serialized, extractPropName: columns.description.view }),
      new DisplayHeader({ headerName: columns.note.serialized, extractPropName: columns.note.view }),
    ]),
  ]);
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo2DigitsFloating, roundTo5DigitsFloating } from 'lib/helpers/renderHelpers';

import { GREENHOUSE_GAS_EMISSION } from 'store/entities/referenceTables/greenhouseGasEmission/config';

const { columns, view, serialized } = GREENHOUSE_GAS_EMISSION.GreenhouseGasEmission;

export const greenhouseGasEmissionTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.country.view,
          field: columns.country.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.location.view,
          field: columns.location.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.category.view,
          field: columns.category.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.account.view,
          field: columns.account.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.extraExternalId.view,
          field: columns.extraExternalId.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.name.view,
          field: columns.name.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.emissionFactor.view,
          field: columns.emissionFactor.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.emissionFactor.serialized] && roundTo5DigitsFloating(data[columns.emissionFactor.serialized]),
        },
      }),
      new HybridView({
        settings: {
          title: columns.unit.view,
          field: columns.unit.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.conversionFactor.view,
          field: columns.conversionFactor.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.conversionFactor.serialized] &&
            roundTo2DigitsFloating(data[columns.conversionFactor.serialized]),
        },
      }),
      new HybridView({
        settings: {
          title: columns.formulaCf.view,
          field: columns.formulaCf.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.co2Unit.view,
          field: columns.co2Unit.serialized,
        },
      }),
    ],
  }),
]);

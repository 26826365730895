import { Route } from '../../Route';
import RouteParser from 'route-parser';

export const validateToken = new Route('get', new RouteParser('/auth/validate_token'), (args) => {
  return [
    200,
    {
      resource: {
        id: 3,
        provider: 'email',
        uid: 'vbbc-vionadmin@vbbc-example.com',
        allow_password_change: false,
        name: 'Vion Admin 1',
        nickname: null,
        user_role: 'content_admin',
        image: null,
        email: 'vbbc-vionadmin@vbbc-example.com',
        created_at: '2020-05-28T09:26:32.994Z',
        updated_at: '2020-06-18T14:10:14.758Z',
        company_id: 1,
      },
    },

    {
      'access-token': 'eZUK4lcquo5xmY-ylkOWfQ',
      client: 'tq-ZLJW3Pa0MF5stsfxqQw',
      expiry: '1591345988',
      'token-type': 'Bearer',
    },
  ];
});

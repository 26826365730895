export const generateBlockchainRecord = () => ({
  active_blockchain_record: {
    stored_in_blockchain_at: '2020-07-23T07:06:16.665Z',
    hashed_data:
      '9c6ddf4467512815526624af106605d35985e68b047179bbf765119052383bc574bb3f3692755e44f17be3b1f01e3be9c48f47c99f87f59a65758c328d3d8a5c',
    hash_type: 'sha512',
    properties: {
      has_many: {
        salmonella_details: {
          order_by: {
            stat_type: 'asc',
          },
          properties: ['stat_type', 'count'],
        },
      },
      properties: [
        'ubn',
        'country_code',
        'business_type',
        'valid_from',
        'valid_to',
        'has_own_transport',
        'remark',
        'vc',
        'qs_number',
        'gg_number',
        'pigs_tomorrow',
        'blk',
        'comment',
        'salmonella_details',
      ],
    },
  },
});

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper } from 'lib/http/utils';
import { DELIVERY_SEGMENT_CONTENTS_FIELDS } from '../../../../lib/tables/config/delivery/segmentContents/config';

const { columns, serialized, unserialized } = DELIVERY_SEGMENT_CONTENTS_FIELDS.DeliverySegmentContents;
const {
  id,
  itemLineNumber,
  itemNumber,
  itemDescription,
  itemQuantity,
  itemUnits,
  itemGroupCode,
  productionNumber,
  productionLocation,
  gmpNumber,
  invoiceNumber,
  basicContent,
  orderedItemQuantity,
  nutrientN,
  nutrientP,
  nutrientRe,
  nutrientFos,
  nutrientVem,
  nutrientVevi,
  nutrientEw,
  nutrientDs,
  nutrientNdf,
  nutrientZetmeel,
  nutrientEf00,
  nutrientEf40,
  nutrientEf80,
  nutrientCo2,
  nutrientVCos,
  nutrientVCre,
  animalCode,
  feedCode,
  feedCodeKlw,
  ras,
} = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(id.unserialized, id.serialized),
      new CellSerializer(itemLineNumber.unserialized, itemLineNumber.serialized),
      new CellSerializer(itemNumber.unserialized, itemNumber.serialized),
      new CellSerializer(itemDescription.unserialized, itemDescription.serialized),
      new CellSerializer(itemQuantity.unserialized, itemQuantity.serialized),
      new CellSerializer(itemUnits.unserialized, itemUnits.serialized),
      new CellSerializer(itemGroupCode.unserialized, itemGroupCode.serialized),
      new CellSerializer(productionNumber.unserialized, productionNumber.serialized),
      new CellSerializer(productionLocation.unserialized, productionLocation.serialized),
      new CellSerializer(gmpNumber.unserialized, gmpNumber.serialized),
      new CellSerializer(invoiceNumber.unserialized, invoiceNumber.serialized),
      new CellSerializer(basicContent.unserialized, basicContent.serialized),
      new CellSerializer(orderedItemQuantity.unserialized, orderedItemQuantity.serialized),
      new CellSerializer(nutrientN.unserialized, nutrientN.serialized),
      new CellSerializer(nutrientP.unserialized, nutrientP.serialized),
      new CellSerializer(nutrientRe.unserialized, nutrientRe.serialized),
      new CellSerializer(nutrientFos.unserialized, nutrientFos.serialized),
      new CellSerializer(nutrientVem.unserialized, nutrientVem.serialized),
      new CellSerializer(nutrientVevi.unserialized, nutrientVevi.serialized),
      new CellSerializer(nutrientEw.unserialized, nutrientEw.serialized),
      new CellSerializer(nutrientDs.unserialized, nutrientDs.serialized),
      new CellSerializer(nutrientNdf.unserialized, nutrientNdf.serialized),
      new CellSerializer(nutrientZetmeel.unserialized, nutrientZetmeel.serialized),
      new CellSerializer(nutrientEf00.unserialized, nutrientEf00.serialized),
      new CellSerializer(nutrientEf40.unserialized, nutrientEf40.serialized),
      new CellSerializer(nutrientEf80.unserialized, nutrientEf80.serialized),
      new CellSerializer(nutrientCo2.unserialized, nutrientCo2.serialized),
      new CellSerializer(nutrientVCos.unserialized, nutrientVCos.serialized),
      new CellSerializer(nutrientVCre.unserialized, nutrientVCre.serialized),
      new CellSerializer(animalCode.unserialized, animalCode.serialized),
      new CellSerializer(feedCode.unserialized, feedCode.serialized),
      new CellSerializer(feedCodeKlw.unserialized, feedCodeKlw.serialized),
      new CellSerializer(ras.unserialized, ras.serialized),
      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
    ]),
  ]);
};

export const serializeDeliverySegmentContentsInfo = (serverData: ResourcesWrapper) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

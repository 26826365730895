import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { IntlKeys } from 'lib/localization/keys/__keys';

/*

{
  id: 4987,
  ubn: '2222222',
  valid_from: '2004-02-03T20:59:56.000Z',
  valid_to: '2021-01-31T20:59:56.000Z',
  type: 'IKB',
  active_blockchain_record: {
    stored_in_blockchain_at: '2020-07-23T07:06:16.665Z',
    hashed_data:
      '9c6ddf4467512815526624af106605d35985e68b047179bbf765119052383bc574bb3f3692755e44f17be3b1f01e3be9c48f47c99f87f59a65758c328d3d8a5c',
    hash_type: 'sha512',
  },
};

 */

enum IKBSerialized {
  CertificatesIKB = 'CertificatesIKB',
}

enum IKBUnserialized {
  CertificatesIKB = 'CertificatesIKB',
}

enum IKBView {
  CertificatesIKB = 'Certificates IKB',
}

enum IKBSerializedHeaders {
  ubn = 'ubn',
  validFrom = 'validFrom',
  validTo = 'validTo',
  type = 'type',
}

enum IKBUnserializedHeaders {
  ubn = 'ubn',
  validFrom = 'valid_from',
  validTo = 'valid_to',
  type = 'certificate_type',
}

enum IKBViewHeaders {
  ubn = 'UBN',
  validFrom = 'Valid from',
  validTo = 'Valid to',
  type = 'Type',
}

export const IKB_CERTIFICATES = {
  [IKBSerialized.CertificatesIKB]: {
    serialized: IKBSerialized.CertificatesIKB,
    unserialized: IKBUnserialized.CertificatesIKB,
    view: IntlKeys.titleCertificatesIKB,

    columns: {
      [SERVICE_FIELDS.id.serialized]: {
        serialized: SERVICE_FIELDS.id.serialized,
        unserialized: SERVICE_FIELDS.id.unserialized,
        view: SERVICE_FIELDS.id.view,
      },
      [IKBSerializedHeaders.ubn]: {
        serialized: IKBSerializedHeaders.ubn,
        unserialized: IKBUnserializedHeaders.ubn,
        view: IntlKeys.certificateIKBUbn,
      },
      [IKBSerializedHeaders.validFrom]: {
        serialized: IKBSerializedHeaders.validFrom,
        unserialized: IKBUnserializedHeaders.validFrom,
        view: IntlKeys.certificateIKBValidFrom,
      },
      [IKBSerializedHeaders.validTo]: {
        serialized: IKBSerializedHeaders.validTo,
        unserialized: IKBUnserializedHeaders.validTo,
        view: IntlKeys.certificateIKBValidTo,
      },
      [IKBSerializedHeaders.type]: {
        serialized: IKBSerializedHeaders.type,
        unserialized: IKBUnserializedHeaders.type,
        view: IntlKeys.certificateIKBType,
      },
    },
  },
};

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

import { IKB_CERTIFICATES } from 'lib/tables/config/certificates/ikb/config';

interface CertificatesIKBUnserialized {
  id: number;
  program: string;
  remark: string;
  answer: string;
  code: string;
  updated_date: string;
  created_at: string;
  updated_at: string;
  ubn: string;
}

const getSerializer = (data: DocumentData) => {
  const { id, ubn, validFrom, validTo, type } = IKB_CERTIFICATES.CertificatesIKB.columns;

  return new DocumentSerializer(data, [
    new SheetSerializer(IKB_CERTIFICATES.CertificatesIKB.unserialized, IKB_CERTIFICATES.CertificatesIKB.serialized, [
      new CellSerializer(id.unserialized, id.serialized),
      new CellSerializer(ubn.unserialized, ubn.serialized),
      new CellSerializer(validFrom.unserialized, validFrom.serialized),
      new CellSerializer(validTo.unserialized, validTo.serialized),
      new CellSerializer(type.unserialized, type.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeCertificatesIKB = (serverData: ResourcesWrapper<CertificatesIKBUnserialized>) => {
  const data = {
    [IKB_CERTIFICATES.CertificatesIKB.unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

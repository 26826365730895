import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import TitledRow from '../TitledRow';
import { useIntl } from 'react-intl';

const StyledDialog = styled(Dialog)`
  z-index: 1450 !important;
  padding: calc(64px + 25px) 25px 25px;
`;

const StyledAppBar = styled(AppBar)`
  && {
    background: #e2edc0;
    color: black;
  }
`;

interface OwnProps {
  isOpen?: boolean;
  handleClose: () => void;
  title?: React.ReactNode;
  titleValue?: React.ReactNode;

  rows: { label: React.ReactNode; text: React.ReactNode }[];
}

const RowDetailsOverlay: React.FC<OwnProps> = ({
  //
  isOpen = false,
  handleClose,
  title,
  titleValue = '',
  rows,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <StyledDialog fullScreen open={isOpen} onClose={handleClose}>
        <StyledAppBar color="secondary">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {title && typeof title === 'string' ? formatMessage({ id: title }) : title} {titleValue}
            </Typography>
          </Toolbar>
        </StyledAppBar>

        <List>
          {rows.map(({ label, text }, ix) => {
            // if empty fields will be needed make sure text is not null
            return text ? (
              <TitledRow
                key={ix}
                label={(typeof label === 'string' && label) ? formatMessage({ id: label || '' }) : label || ''}
                text={text}
              />
            ) : null;
          })}
        </List>
      </StyledDialog>
    </>
  );
};

export default React.memo(RowDetailsOverlay);

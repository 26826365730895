import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import { CL550 } from 'store/entities/referenceTables/cl550/config';

const { columns, view, serialized } = CL550.Cl550;

export const cl550TableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.mainGroupCode.view,
          field: columns.mainGroupCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.subGroupCode.view,
          field: columns.subGroupCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.codeDetails.view,
          field: columns.codeDetails.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.mainGroupName.view,
          field: columns.mainGroupName.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.subgroupName.view,
          field: columns.subgroupName.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.detailing.view,
          field: columns.detailing.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.english.view,
          field: columns.english.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.german.view,
          field: columns.german.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.codeCondition.view,
          field: columns.codeCondition.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.edi.view,
          field: columns.edi.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.dgr.view,
          field: columns.dgr.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.diseaseAndTreatment.view,
          field: columns.diseaseAndTreatment.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.failure.view,
          field: columns.failure.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.disposal.view,
          field: columns.disposal.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.selection.view,
          field: columns.selection.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.labResult.view,
          field: columns.labResult.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.section.view,
          field: columns.section.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.slaughterhouseData.view,
          field: columns.slaughterhouseData.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.agility.view,
          field: columns.agility.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.coverage.view,
          field: columns.coverage.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.birth.view,
          field: columns.birth.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.suckingPiglet.view,
          field: columns.suckingPiglet.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.weanedBig.view,
          field: columns.weanedBig.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.rearingSowAndBoar.view,
          field: columns.rearingSowAndBoar.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.goodSow.view,
          field: columns.goodSow.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.gestationSow.view,
          field: columns.gestationSow.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.lactatingSow.view,
          field: columns.lactatingSow.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.beer.view,
          field: columns.beer.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.finisher.view,
          field: columns.finisher.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.comment.view,
          field: columns.comment.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.added.view,
          field: columns.added.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.deleted.view,
          field: columns.deleted.serialized,
        },
      }),
    ],
  }),
]);

import { get } from 'lodash';

import { EnvVars } from 'lib/env/Env';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellAggregationFunction, CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { USER_PASSPORTS } from 'lib/tables/config/userPassports/config';
import {
  ADMIN_ORGANIZATIONS_FIELDS,
  AdminOrganizationsSerializedHeaders,
} from 'lib/tables/config/admin/organizations/config';
import { ADMIN_USERS } from 'lib/tables/config/admin/users/config';

import { AdminUserSerialized, AdminUserUnserialized, serializeAdminUsers } from 'store/entities/admin/users';
import {
  serializeUserPassports,
  UserPassportPayload,
  UserPassportSerialized,
  UserPassportTransformedPayload,
} from 'store/entities/userPassports';

export type AdminOrganizationPayload = {
  id: number;
  email: string;
  kvk: string;
  kvk_company_name: string;
  updated_at: string;
  custom_kvk_name: string;

  logo: {
    url: string | null;
  };
  background_image: {
    url: string | null;
  };

  users: Array<AdminUserUnserialized>;
  user_passports: UserPassportPayload[];
};

export type AdminOrganizationSerialized = {
  [AdminOrganizationsSerializedHeaders.id]: number;
  [AdminOrganizationsSerializedHeaders.email]: string;
  [AdminOrganizationsSerializedHeaders.kvk]: string;
  [AdminOrganizationsSerializedHeaders.kvkCompanyName]: string;
  [AdminOrganizationsSerializedHeaders.customKvkName]: string | null;
  [AdminOrganizationsSerializedHeaders.updatedAt]: string;
  [AdminOrganizationsSerializedHeaders.logoUrl]: string | null;
  [AdminOrganizationsSerializedHeaders.backgroundImageUrl]: string | null;

  [AdminOrganizationsSerializedHeaders.users]: AdminUserSerialized[];
  [AdminOrganizationsSerializedHeaders.userPassports]: UserPassportSerialized[];
};

const transformImageUrl: CellAggregationFunction<AdminOrganizationPayload> = (
  //
  data,
  sheetData,
  excelRow,
  propertyOriginalPath,
) => {
  const imgUrl = get(excelRow, propertyOriginalPath) as AdminOrganizationPayload['logo']['url'];

  return typeof imgUrl === 'string' ? `${process.env[EnvVars.HOST_URL]}${imgUrl}`.replace(/([^:])\/{2}/g, '$1/') : null;
};

const { columns, serialized, unserialized } = ADMIN_ORGANIZATIONS_FIELDS.adminOrganizations;
const {
  email,
  kvk,
  kvkCompanyName,
  customKvkName,
  updatedAt,
  users,
  userPassports,
  logoUrl,
  backgroundImageUrl,
} = columns;

const getSerializer = (data: DocumentData<AdminOrganizationPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(email.unserialized, email.serialized),
      new CellSerializer(kvk.unserialized, kvk.serialized),
      new CellSerializer(kvkCompanyName.unserialized, kvkCompanyName.serialized),
      new CellSerializer(customKvkName.unserialized, customKvkName.serialized),
      new CellSerializer(updatedAt.unserialized, updatedAt.serialized),
      new CellSerializer(logoUrl.unserialized, logoUrl.serialized, transformImageUrl, undefined, null),
      new CellSerializer(
        backgroundImageUrl.unserialized,
        backgroundImageUrl.serialized,
        transformImageUrl,
        undefined,
        null,
      ),
      //
      new CellSerializer(users.unserialized, users.serialized, (data, sheetData, excelRow) => {
        const pseudoResponse = ({ data: { resources: excelRow.users } } as unknown) as ResourcesWrapper<
          AdminUserUnserialized
        >;

        return excelRow.users ? serializeAdminUsers(pseudoResponse)[ADMIN_USERS.AdminUsers.serialized] : [];
      }),
      new CellSerializer(userPassports.unserialized, userPassports.serialized, (data, sheetData, excelRow) => {
        const pseudoResponse = ({ data: { resources: excelRow.user_passports } } as unknown) as ResourcesWrapper<
          UserPassportTransformedPayload
        >;

        return serializeUserPassports(pseudoResponse)[USER_PASSPORTS.UserPassports.serialized];
      }),
    ]),
  ]);
};

export const serializeAdminOrganizations = (serverData: ResourcesWrapper<AdminOrganizationPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

export const serializeAdminOrganization = (serverData: ResourceWrapper<AdminOrganizationPayload>) => {
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[serialized][0];
};

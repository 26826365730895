import { GWP_100_FACTORS_FIELDS } from 'lib/excel/config/referenceTables/gwp100Factors/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo2DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = GWP_100_FACTORS_FIELDS.Gwp100Factors;

export const getGwp100FactorsView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.greenhouseGas.serialized,
        viewPropName: columns.greenhouseGas.view,
      }),
      new CellViewer({
        serializedPropName: columns.gwp100Factor.serialized,
        viewPropName: columns.gwp100Factor.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({
        serializedPropName: columns.gwp100FactorUnit.serialized,
        viewPropName: columns.gwp100FactorUnit.view,
      }),
    ]),
  ]);
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum ContentOfNitricOxideOriginal {
  ContentOfNitricOxide = 'CFP_NEMA_Emission_factors_for_o',
}

enum ContentOfNitricOxideSerialized {
  ContentOfNitricOxide = 'ContentOfNitricOxide',
}

enum ContentOfNitricOxideUnserialized {
  ContentOfNitricOxide = 'ContentOfNitricOxide',
}

enum ContentOfNitricOxideOriginalHeaders {
  'livestockCategory' = 'Livestock category ',
  'manureType' = 'Manure_type',
  'valueN2oAndNo' = 'N2O and NO',
  'unitN2oAndNo' = 'N2O and NO Unit',
  'valueN2' = 'N2',
  'unitN2' = 'N2 Unit',
}

enum ContentOfNitricOxideSerializedHeaders {
  'livestockCategory' = 'livestockCategory',
  'manureType' = 'manureType',
  'valueN2oAndNo' = 'valueN2oAndNo',
  'unitN2oAndNo' = 'unitN2oAndNo',
  'valueN2' = 'valueN2',
  'unitN2' = 'unitN2',
}
enum ContentOfNitricOxideUnserializedHeaders {
  'livestockCategory' = 'livestock_category',
  'manureType' = 'manure_type',
  'valueN2oAndNo' = 'value_n2o_and_no',
  'unitN2oAndNo' = 'unit_n2o_and_no',
  'valueN2' = 'value_n2',
  'unitN2' = 'unit_n2',
}

export const CONTENT_OF_NITRIC_OXIDE_FIELDS = {
  [ContentOfNitricOxideUnserialized.ContentOfNitricOxide]: {
    original: ContentOfNitricOxideOriginal.ContentOfNitricOxide,
    serialized: ContentOfNitricOxideSerialized.ContentOfNitricOxide,
    unserialized: ContentOfNitricOxideUnserialized.ContentOfNitricOxide,
    view: IntlKeys.referenceTablesContentOfNitricOxide,
    viewName: 'CFP_NEMA_Emission_factors_for_other_gaseous_N-losses',

    columns: {
      [ContentOfNitricOxideSerializedHeaders.livestockCategory]: {
        original: ContentOfNitricOxideOriginalHeaders.livestockCategory,
        serialized: ContentOfNitricOxideSerializedHeaders.livestockCategory,
        unserialized: ContentOfNitricOxideUnserializedHeaders.livestockCategory,
        view: IntlKeys.referenceTablesContentOfNitricOxideCategory,
      },
      [ContentOfNitricOxideSerializedHeaders.manureType]: {
        original: ContentOfNitricOxideOriginalHeaders.manureType,
        serialized: ContentOfNitricOxideSerializedHeaders.manureType,
        unserialized: ContentOfNitricOxideUnserializedHeaders.manureType,
        view: IntlKeys.referenceTablesContentOfNitricOxideType,
      },
      [ContentOfNitricOxideSerializedHeaders.valueN2oAndNo]: {
        original: ContentOfNitricOxideOriginalHeaders.valueN2oAndNo,
        serialized: ContentOfNitricOxideSerializedHeaders.valueN2oAndNo,
        unserialized: ContentOfNitricOxideUnserializedHeaders.valueN2oAndNo,
        view: IntlKeys.referenceTablesContentOfNitricOxideValueN2oNo,
      },
      [ContentOfNitricOxideSerializedHeaders.unitN2oAndNo]: {
        original: ContentOfNitricOxideOriginalHeaders.unitN2oAndNo,
        serialized: ContentOfNitricOxideSerializedHeaders.unitN2oAndNo,
        unserialized: ContentOfNitricOxideUnserializedHeaders.unitN2oAndNo,
        view: IntlKeys.referenceTablesContentOfNitricOxideUnitN2oNo,
      },
      [ContentOfNitricOxideSerializedHeaders.valueN2]: {
        original: ContentOfNitricOxideOriginalHeaders.valueN2,
        serialized: ContentOfNitricOxideSerializedHeaders.valueN2,
        unserialized: ContentOfNitricOxideUnserializedHeaders.valueN2,
        view: IntlKeys.referenceTablesContentOfNitricOxideValueN2,
      },
      [ContentOfNitricOxideSerializedHeaders.unitN2]: {
        original: ContentOfNitricOxideOriginalHeaders.unitN2,
        serialized: ContentOfNitricOxideSerializedHeaders.unitN2,
        unserialized: ContentOfNitricOxideUnserializedHeaders.unitN2,
        view: IntlKeys.referenceTablesContentOfNitricOxideUnitN2,
      },
    },
  },
};

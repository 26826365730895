import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { IconButton, MenuItem, makeStyles } from '@material-ui/core';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MaterialMenu from '@material-ui/core/Menu';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export type ActionConfig = {
  title: string;
  url?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}[];

interface OwnProps {
  data?: object;
  actions?: ActionConfig;
  Icon?: React.FC;
}

const _actions: ActionConfig = [{ title: 'Details' }, { title: 'Archive' }, { title: 'Copy Link' }];

const RowDropdown: React.FC<OwnProps> = ({ actions = _actions, Icon = MoreHorizIcon }) => {
  const classes = useStyles();

  const buttonRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return actions.length === 0 ? null : (
    <IconButton size="small" onClick={() => setOpen(!isOpen)} ref={buttonRef}>
      <Icon />

      <MaterialMenu
        open={isOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.menu}
      >
        {actions.map(({ title, url, onClick, isLoading, disabled }) => (
          <MenuItem
            key={title}
            // @ts-ignore
            component={url ? Link : undefined}
            onClick={onClick}
            disabled={disabled}
          >
            {isLoading ? <MuiCircularProgress className={classes.circularProgress} /> : title}
          </MenuItem>
        ))}
      </MaterialMenu>
    </IconButton>
  );
};

const useStyles = makeStyles(() => ({
  menu: {
    background: 'rgba(0, 0, 0, 0.15)',

    '&& .MuiMenu-paper': {
      transform: 'translateY(10px)',
      minWidth: 200,
    },
  },
  circularProgress: {
    width: '24px !important',
    height: '24px !important',
    margin: '0 auto',
  },
}));

export default RowDropdown;

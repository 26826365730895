import { ENTITIES_LIST_FIELDS } from 'lib/excel/config/referenceTables/entitiesList/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = ENTITIES_LIST_FIELDS.EntitiesList;

export const serializeEntitiesListData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.entityId.original, columns.entityId.serialized),
      new CellSerializer(columns.entityName.original, columns.entityName.serialized),
      new CellSerializer(columns.country.original, columns.country.serialized),
      new CellSerializer(columns.businessUnit.original, columns.businessUnit.serialized),
    ]),
  ]);
};

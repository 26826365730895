enum CertificatesGroupedSerialized {
  ValidityGrouped = 'ValidityGrouped',
}

enum CertificatesGroupedUnserialized {
  ValidityGrouped = 'ValidityGrouped',
}

export enum CertificatesGroupedSerializedHeaders {
  id = 'id',
  ubn = 'ubn',
  validFrom = 'validFrom',
  validTo = 'validTo',
  certificateStatus = 'certificateStatus',
  certificateType = 'certificateType',
  userPassports = 'userPassports',
}

export enum CertificatesGroupedUnserializedHeaders {
  id = 'id',
  ubn = 'ubn',
  validFrom = 'valid_from',
  validTo = 'valid_to',
  certificateStatus = 'certificate_status',
  certificateType = 'certificate_type',
  userPassports = 'user_passports',
}

export const CERTIFICATES_GROUPED = {
  [CertificatesGroupedSerialized.ValidityGrouped]: {
    serialized: CertificatesGroupedSerialized.ValidityGrouped,
    unserialized: CertificatesGroupedUnserialized.ValidityGrouped,
    columns: {
      [CertificatesGroupedSerializedHeaders.id]: {
        serialized: CertificatesGroupedSerializedHeaders.id,
        unserialized: CertificatesGroupedUnserializedHeaders.id,
      },
      [CertificatesGroupedSerializedHeaders.ubn]: {
        serialized: CertificatesGroupedSerializedHeaders.ubn,
        unserialized: CertificatesGroupedUnserializedHeaders.ubn,
      },
      [CertificatesGroupedSerializedHeaders.validFrom]: {
        serialized: CertificatesGroupedSerializedHeaders.validFrom,
        unserialized: CertificatesGroupedUnserializedHeaders.validFrom,
      },
      [CertificatesGroupedSerializedHeaders.validTo]: {
        serialized: CertificatesGroupedSerializedHeaders.validTo,
        unserialized: CertificatesGroupedUnserializedHeaders.validTo,
      },
      [CertificatesGroupedSerializedHeaders.certificateType]: {
        serialized: CertificatesGroupedSerializedHeaders.certificateType,
        unserialized: CertificatesGroupedUnserializedHeaders.certificateType,
      },
      [CertificatesGroupedSerializedHeaders.certificateStatus]: {
        serialized: CertificatesGroupedSerializedHeaders.certificateStatus,
        unserialized: CertificatesGroupedUnserializedHeaders.certificateStatus,
      },
      [CertificatesGroupedSerializedHeaders.userPassports]: {
        serialized: CertificatesGroupedSerializedHeaders.userPassports,
        unserialized: CertificatesGroupedUnserializedHeaders.userPassports,
      },
    },
  },
};

import { SUPPLIERS_NUMBERS_FIELDS } from 'lib/excel/config/referenceTables/suppliersNumbers/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = SUPPLIERS_NUMBERS_FIELDS.SuppliersNumbers;

export const getSuppliersNumbersDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.codeList.serialized, extractPropName: columns.codeList.view }),
      new DisplayHeader({
        headerName: columns.supplierNumber.serialized,
        extractPropName: columns.supplierNumber.view,
      }),
      new DisplayHeader({
        headerName: columns.feedSupplierName.serialized,
        extractPropName: columns.feedSupplierName.view,
      }),
      new DisplayHeader({ headerName: columns.entryDate.serialized, extractPropName: columns.entryDate.view }),
      new DisplayHeader({ headerName: columns.dischargeDate.serialized, extractPropName: columns.dischargeDate.view }),
      new DisplayHeader({ headerName: columns.remark.serialized, extractPropName: columns.remark.view }),
    ]),
  ]);
};

import RouteParser from 'route-parser';

import { Route, Responder } from '../Route';
// import { generateBlockchainRecord } from '../utils';

export const animalPassportMockResponder = () => {
  const realAlike = {
    certificates: {
      ikb_certificate: {
        id: 3,
        ubn: 'hxutnuufqi',
        certificate_type: 'IKB_certificate',
        valid_from: '2020-12-06T15:32:12.056Z',
        valid_to: '2020-12-16T15:32:12.056Z',
        created_at: '2020-12-16T15:32:12.056Z',
        updated_at: '2020-12-16T15:32:12.195Z',
        active_blockchain_record: {
          stored_in_blockchain_at: null,
          hashed_data:
            'bf4145058e4d566092cab18a1b91184cd3070376676b6ec71f40b8f555a78352d11a27dd225e864517074d869bfd5be9a80b7f90261f30ed3eacb0f2979d6dba',
          hash_type: 'sha512',
          properties: {
            properties: ['ubn', 'valid_from', 'valid_to', 'certificate_type'],
          },
          blockchain_id: null,
        },
      },
      welfare_certificate: {
        id: 4,
        ubn: 'hxutnuufqi',
        certificate_type: 'welfare_certificate',
        valid_from: '2020-12-06T15:32:12.056Z',
        valid_to: '2020-12-16T15:32:12.056Z',
        created_at: '2020-12-16T15:32:12.056Z',
        updated_at: '2020-12-16T15:32:12.206Z',
        active_blockchain_record: {
          stored_in_blockchain_at: null,
          hashed_data:
            '7efb49b343eb44919fa9079dc8a4d6d28c0ca6870cf0878ef17e1d18403b8f81b1f43e6683cb042e2d45013a33be2095deb29be26fab64ec6e5e74841cde2b7a',
          hash_type: 'sha512',
          properties: {
            properties: ['ubn', 'valid_from', 'valid_to', 'certificate_type'],
          },
          blockchain_id: null,
        },
      },
      daily_certificate: {
        id: 2,
        ubn: 'hxutnuufqi',
        country_code: 'CA',
        business_type: 'Dolorem et porro.',
        valid_from: '2020-12-06T15:32:12.056Z',
        valid_to: '2020-12-16T15:32:12.056Z',
        has_own_transport: true,
        remark: 'Cupiditate rem consequuntur. Voluptatem dolorem consectetur. Est at sapiente.',
        vc: 'rerum',
        qs_number: 'xauck0zwfj',
        gg_number: 'td4jcu77cm',
        pigs_tomorrow: false,
        blk: false,
        comment: 'Omnis velit voluptatum. Aut saepe ut. Inventore aut nihil.',
        salmonella_details: [],
        active_blockchain_record: {
          stored_in_blockchain_at: null,
          hashed_data:
            'b8604578eb891e761405e469ba3e5531277f607ad211f40dc8aa047fc7fbcbc7151124b8e8de0e1f829ddb0050d811bedb28dfde61f35a191987ed6be2367688',
          hash_type: 'sha512',
          properties: {
            has_many: {
              salmonella_details: {
                order_by: {
                  stat_type: 'asc',
                },
                properties: ['stat_type', 'count'],
              },
            },
            properties: [
              'ubn',
              'country_code',
              'business_type',
              'valid_from',
              'valid_to',
              'has_own_transport',
              'remark',
              'vc',
              'qs_number',
              'gg_number',
              'pigs_tomorrow',
              'blk',
              'comment',
              'salmonella_details',
            ],
          },
          blockchain_id: null,
        },
      },
    },
    veterinarian_data: {
      type_2: {
        current_year_record: {
          id: 18,
          source: 'qui',
          export_date: '2020-12-14T15:32:12.552Z',
          ubn: 'hxutnuufqi',
          company_name: 'Wehner-Ledner',
          location: 'Suite 958 707 Conn Pines, Kreigerfurt, NY 23719',
          animal_category: 'type_2',
          ddd_tm: 92879.17,
          created_at: '2020-12-16T15:32:12.554Z',
          updated_at: '2020-12-16T15:32:12.554Z',
          active_blockchain_record: {
            stored_in_blockchain_at: null,
            hashed_data:
              '8f6587d0c3e5e04b76f6de1c8ac26ba3f97dcd9a554fc79d9659fb0d329eaaeb7cb5d55e5ea786bfeb20c775f0f1c47c51e8e858329588ca2048170cc9d28026',
            hash_type: 'sha512',
            properties: {
              properties: ['source', 'export_date', 'ubn', 'company_name', 'location', 'animal_category', 'ddd_tm'],
            },
            blockchain_id: null,
          },
        },
        prev_year_record: {
          id: 20,
          source: 'voluptas',
          export_date: '2019-12-14T15:32:12.572Z',
          ubn: 'hxutnuufqi',
          company_name: "Pollich, O'Connell and Franecki",
          location: "481 Dickinson Gateway, O'Keefeburgh, MA 86288-7863",
          animal_category: 'type_2',
          ddd_tm: 51005.73,
          created_at: '2020-12-16T15:32:12.574Z',
          updated_at: '2020-12-16T15:32:12.574Z',
          active_blockchain_record: {
            stored_in_blockchain_at: null,
            hashed_data:
              '30e306fc3ddcbf909f951e66e937f5180ede02f77395bc1aae0d134490df6b03a4695cc8f7a91e972cd5857a0150f37824c40eaa91d78b750132e1e6e08b8fa5',
            hash_type: 'sha512',
            properties: {
              properties: ['source', 'export_date', 'ubn', 'company_name', 'location', 'animal_category', 'ddd_tm'],
            },
            blockchain_id: null,
          },
        },
      },
      type_1: {
        current_year_record: {
          id: 17,
          source: 'dolor',
          export_date: '2020-12-14T15:32:12.542Z',
          ubn: 'hxutnuufqi',
          company_name: 'Nicolas and Sons',
          location: '31066 Mayert Roads, East Bradview, HI 24540',
          animal_category: 'type_1',
          ddd_tm: 23893.68,
          created_at: '2020-12-16T15:32:12.544Z',
          updated_at: '2020-12-16T15:32:12.544Z',
          active_blockchain_record: {
            stored_in_blockchain_at: null,
            hashed_data:
              '4c3982d6d1a2c149d89faaf2c2976a1c4c15a88778dfaxc39817fa8ce95d37f4d6e15f08c1211781d7395b4539e1210dc8c7514033240bce1406365b58dd',
            hash_type: 'sha512',
            properties: {
              properties: ['source', 'export_date', 'ubn', 'company_name', 'location', 'animal_category', 'ddd_tm'],
            },
            blockchain_id: null,
          },
        },
        prev_year_record: {
          id: 19,
          source: 'animi',
          export_date: '2019-12-14T15:32:12.562Z',
          ubn: 'hxutnuufqi',
          company_name: "O'Hara, Lindgren and Moen",
          location: 'Suite 811 36591 Kutch Oval, New Anhville, NM 10657',
          animal_category: 'type_1',
          ddd_tm: 52526.37,
          created_at: '2020-12-16T15:32:12.564Z',
          updated_at: '2020-12-16T15:32:12.564Z',
          active_blockchain_record: {
            stored_in_blockchain_at: null,
            hashed_data:
              '53bd4dd93ae40c0849ecbb94570d41cd515f6cc36c1a794eefb7191b59336598b76ac8excvasdq2a7133d04123d63d1a289aaeaf5804572053fd65279f572cef6',
            hash_type: 'sha512',
            properties: {
              properties: ['source', 'export_date', 'ubn', 'company_name', 'location', 'animal_category', 'ddd_tm'],
            },
            blockchain_id: null,
          },
        },
      },
    },
  };

  const entry = {
    // voer
    feed_data: {
      item_description_1: 15.0,
      item_description_2: 15.0,
    },
    // Transport
    movement_events: {
      //
      animal_categories: [
        {
          category: '01',
          count: 1057,
        },

        {
          category: '03',
          count: 1057,
        },
      ],
    },
    // Farm Informatie
    farm_data: {
      //
      death_causes: [
        {
          type: 'Cause 1',
          count: 8,
          average_weight: 101.71,
        },
        {
          type: 'Cause 1',
          count: 13,
          average_weight: 97.01,
        },
      ],

      feed_stages: [
        {
          feed_type: 'start',
          animal_weight: 3540,
        },
        {
          feed_type: 'growth',
          animal_weight: 5075.5,
        },
        {
          feed_type: 'finish',
          animal_weight: 1126.0,
        },
      ],
    },
    // genetica
    genetica: {
      //
      male_amount: 78,
      male_type: 'TN Tempo Growth',
      female_amount: 15,
      female_type: 'TOPIGS L-lijn',
    },
    // slacht
    slaughter_data: {
      type_1: {
        animal_count: 6,
        avg_animal_weight: 45.67,
        grouped_by_carcass_code: {
          carcass_0: 6,
        },
        grouped_by_organ_code: {
          organ_code_0: 6,
        },
      },
      type_2: {
        animal_count: 6,
        avg_animal_weight: 54.83,
        grouped_by_carcass_code: {
          carcass_1: 6,
        },
        grouped_by_organ_code: {
          organ_code_1: 6,
        },
      },
    },
    //
    ...realAlike,
  };

  return [
    200,
    {
      resource: {
        food_safety: entry,
        animal_welfare: entry,
        product_integrity: entry,
        durability: entry,
      },
    },
  ];
};

// real + mock
export type AnimalPassportCombinedResponse = Exclude<ReturnType<typeof animalPassportMockResponder>[1], number>;
export type AnimalPassportCombinedResource = AnimalPassportCombinedResponse['resource'];

export const animalPassport = new Route(
  'get',
  new RouteParser('/mock/animal_passport'),
  (animalPassportMockResponder as unknown) as Responder,
);

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';

import { ORG_PASSPORT_OPTIONS } from './config';

export interface OrgPassportOptionsRaw {
  feed_suppliers: string[];
  genetic_suppliers: string[];
  sow_lines: string[];
  sire_lines: string[];
  veterinary_practice: string[];
  fms_module_agrisysts: string[];
  fms_module_agro_coops: string[];
  fms_module_agrovisions: string[];
  feeding_systems: string[];
  climate_systems: string[];
  ki_stations: string[];
  fms_names: string[];
}

export interface OrgPassportOptionsSerializedEntry {
  feedSuppliers: string[];
  geneticSuppliers: string[];
  sowLines: string[];
  sireLines: string[];
  veterinaryPractice: string[];
  fmsModuleAgrisysts: string[];
  fmsModuleAgroCoops: string[];
  fmsModuleAgrovisions: string[];
  feedingSystems: string[];
  climateSystems: string[];
  kiStation: string[];
  fmsNames: string[];
}

const { columns, serialized, unserialized } = ORG_PASSPORT_OPTIONS.OrgPassportOptions;
const {
  feedSuppliers,
  geneticSuppliers,
  sowLines,
  sireLines,
  veterinaryPractice,
  fmsModuleAgrisysts,
  fmsModuleAgroCoops,
  fmsModuleAgrovisions,
  feedingSystems,
  climateSystems,
  kiStation,
  fmsNames,
} = columns;

const getSerializer = (data: DocumentData<OrgPassportOptionsRaw[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      //
      new CellSerializer(feedSuppliers.unserialized, feedSuppliers.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(geneticSuppliers.unserialized, geneticSuppliers.serialized),
      new CellSerializer(sowLines.unserialized, sowLines.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(sireLines.unserialized, sireLines.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(veterinaryPractice.unserialized, veterinaryPractice.serialized),
      new CellSerializer(fmsModuleAgrisysts.unserialized, fmsModuleAgrisysts.serialized),
      new CellSerializer(fmsModuleAgroCoops.unserialized, fmsModuleAgroCoops.serialized),
      new CellSerializer(fmsModuleAgrovisions.unserialized, fmsModuleAgrovisions.serialized),
      new CellSerializer(feedingSystems.unserialized, feedingSystems.serialized),
      new CellSerializer(climateSystems.unserialized, climateSystems.serialized),
      new CellSerializer(kiStation.unserialized, kiStation.serialized),
      new CellSerializer(fmsNames.unserialized, fmsNames.serialized),
    ]),
  ]);
};

export const serializeOrgPassportOptions = (serverData: Record<'data', OrgPassportOptionsRaw>) => {
  //
  const data = {
    [unserialized]: [serverData.data],
  };

  const serializer = getSerializer(data);
  return serializer.serialize()[unserialized][0];
};

import { IntlKeys } from 'lib/localization/keys';

enum WaterConsumptionRatesSerialized {
  WaterConsumptionRates = 'WaterConsumptionRates',
}

enum WaterConsumptionRatesUnserialized {
  WaterConsumptionRates = 'WaterConsumptionRates ',
}

export enum WaterConsumptionRatesSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  householdPeopleQuantity = 'householdPeopleQuantity',
  waterConsumption = 'waterConsumption',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const WaterConsumptionRatesUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['water_consumption_rate_upload', 'id'],
  householdPeopleQuantity: 'household_people_quantity',
  waterConsumption: 'water_consumption',
  uploadType: ['water_consumption_rate_upload', 'upload_type'],
  filename: ['water_consumption_rate_upload', 'file_name'],
  status: ['water_consumption_rate_upload', 'status'],
  validDateFrom: ['water_consumption_rate_upload', 'valid_from'],
  validDateTo: ['water_consumption_rate_upload', 'valid_to'],
  notes: ['water_consumption_rate_upload', 'notes'],
  uploadedAt: ['water_consumption_rate_upload', 'uploaded_at'],
  uploadedBy: ['water_consumption_rate_upload', 'uploaded_by', 'full_name'],
} as const;

export const WATER_CONSUMPTION_RATES = {
  [WaterConsumptionRatesSerialized.WaterConsumptionRates]: {
    serialized: WaterConsumptionRatesSerialized.WaterConsumptionRates,
    unserialized: WaterConsumptionRatesUnserialized.WaterConsumptionRates,
    view: IntlKeys.referenceTablesWaterConsumptionRates,

    columns: {
      [WaterConsumptionRatesSerializedHeaders.id]: {
        serialized: WaterConsumptionRatesSerializedHeaders.id,
        unserialized: WaterConsumptionRatesUnserializedHeaders.id,
      },
      [WaterConsumptionRatesSerializedHeaders.referenceUploadID]: {
        serialized: WaterConsumptionRatesSerializedHeaders.referenceUploadID,
        unserialized: WaterConsumptionRatesUnserializedHeaders.referenceUploadID,
      },
      [WaterConsumptionRatesSerializedHeaders.householdPeopleQuantity]: {
        serialized: WaterConsumptionRatesSerializedHeaders.householdPeopleQuantity,
        unserialized: WaterConsumptionRatesUnserializedHeaders.householdPeopleQuantity,
        view: IntlKeys.referenceTablesWaterConsumptionRatesHousehold,
      },
      [WaterConsumptionRatesSerializedHeaders.waterConsumption]: {
        serialized: WaterConsumptionRatesSerializedHeaders.waterConsumption,
        unserialized: WaterConsumptionRatesUnserializedHeaders.waterConsumption,
        view: IntlKeys.referenceTablesWaterConsumptionRatesConsumption,
      },
      [WaterConsumptionRatesSerializedHeaders.status]: {
        serialized: WaterConsumptionRatesSerializedHeaders.status,
        unserialized: WaterConsumptionRatesUnserializedHeaders.status,
      },
      [WaterConsumptionRatesSerializedHeaders.uploadType]: {
        serialized: WaterConsumptionRatesSerializedHeaders.uploadType,
        unserialized: WaterConsumptionRatesUnserializedHeaders.uploadType,
      },
      [WaterConsumptionRatesSerializedHeaders.notes]: {
        serialized: WaterConsumptionRatesSerializedHeaders.notes,
        unserialized: WaterConsumptionRatesUnserializedHeaders.notes,
      },
      [WaterConsumptionRatesSerializedHeaders.filename]: {
        serialized: WaterConsumptionRatesSerializedHeaders.filename,
        unserialized: WaterConsumptionRatesUnserializedHeaders.filename,
      },
      [WaterConsumptionRatesSerializedHeaders.validDateFrom]: {
        serialized: WaterConsumptionRatesSerializedHeaders.validDateFrom,
        unserialized: WaterConsumptionRatesUnserializedHeaders.validDateFrom,
      },
      [WaterConsumptionRatesSerializedHeaders.validDateTo]: {
        serialized: WaterConsumptionRatesSerializedHeaders.validDateTo,
        unserialized: WaterConsumptionRatesUnserializedHeaders.validDateTo,
      },
      [WaterConsumptionRatesSerializedHeaders.uploadedAt]: {
        serialized: WaterConsumptionRatesSerializedHeaders.uploadedAt,
        unserialized: WaterConsumptionRatesUnserializedHeaders.uploadedAt,
      },
      [WaterConsumptionRatesSerializedHeaders.uploadedBy]: {
        serialized: WaterConsumptionRatesSerializedHeaders.uploadedBy,
        unserialized: WaterConsumptionRatesUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys';

enum CodesAnimalIdentityTypeSerialized {
  CodesAnimalIdentityType = 'CodesAnimalIdentityType',
}

enum CodesAnimalIdentityTypeUnserialized {
  CodesAnimalIdentityType = 'CodesAnimalIdentityType',
}

export enum CodesAnimalIdentityTypeSerializedHeaders {
  id = 'id',
  idForTypeIdentifier = 'idForTypeIdentifier',
  note = 'note',
  uniqueIdentifier = 'uniqueIdentifier',
  transponder = 'transponder',
  animalIdentifierTypeDatetime = 'animalIdentifierTypeDatetime',
}

export const CodesAnimalIdentityTypeUnserializedHeaders = {
  id: 'id',
  idForTypeIdentifier: 'id_for_type_identifier',
  note: 'note',
  uniqueIdentifier: 'unique_identifier',
  transponder: 'transponder',
  animalIdentifierTypeDatetime: 'animal_identifier_type_datetime',
} as const;

export const CODES_ANIMAL_IDENTITY_TYPE = {
  [CodesAnimalIdentityTypeSerialized.CodesAnimalIdentityType]: {
    serialized: CodesAnimalIdentityTypeSerialized.CodesAnimalIdentityType,
    unserialized: CodesAnimalIdentityTypeUnserialized.CodesAnimalIdentityType,
    view: IntlKeys.farmManagementDataAnimalIdentityTypeList,

    columns: {
      [CodesAnimalIdentityTypeSerializedHeaders.id]: {
        serialized: CodesAnimalIdentityTypeSerializedHeaders.id,
        unserialized: CodesAnimalIdentityTypeUnserializedHeaders.id,
      },
      [CodesAnimalIdentityTypeSerializedHeaders.idForTypeIdentifier]: {
        serialized: CodesAnimalIdentityTypeSerializedHeaders.idForTypeIdentifier,
        unserialized: CodesAnimalIdentityTypeUnserializedHeaders.idForTypeIdentifier,
        view: IntlKeys.farmManagementDataIdForTypeIdentifier,
      },
      [CodesAnimalIdentityTypeSerializedHeaders.note]: {
        serialized: CodesAnimalIdentityTypeSerializedHeaders.note,
        unserialized: CodesAnimalIdentityTypeUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesAnimalIdentityTypeSerializedHeaders.uniqueIdentifier]: {
        serialized: CodesAnimalIdentityTypeSerializedHeaders.uniqueIdentifier,
        unserialized: CodesAnimalIdentityTypeUnserializedHeaders.uniqueIdentifier,
        view: IntlKeys.farmManagementDataUniqueIdentifier,
      },
      [CodesAnimalIdentityTypeSerializedHeaders.transponder]: {
        serialized: CodesAnimalIdentityTypeSerializedHeaders.transponder,
        unserialized: CodesAnimalIdentityTypeUnserializedHeaders.transponder,
        view: IntlKeys.farmManagementDataTransponder,
      },
      [CodesAnimalIdentityTypeSerializedHeaders.animalIdentifierTypeDatetime]: {
        serialized: CodesAnimalIdentityTypeSerializedHeaders.animalIdentifierTypeDatetime,
        unserialized: CodesAnimalIdentityTypeUnserializedHeaders.animalIdentifierTypeDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
    },
  },
};

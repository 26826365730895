import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';
import { ResourceWrapper } from 'lib/http/utils';

import { ANIMAL_PASSPORTS } from './config';

type BlockChainRecordRaw = {
  stored_in_blockchain_at: string | null;
  hashed_data: string;
  hash_type: string;
  properties: {
    has_many?: Record<string, object>;
    properties: string[];
  };
  blockchain_id: string | null;
};

type BlockChainRecordSerialized = {
  storedInBlockchainAt: string | null;
  hashedData: string;
  hashType: string;
  properties: {
    hasMany?: Record<string, object>;
    properties: string[];
  };
  blockchainId: string | null;
};

type GenericCertificateRaw = {
  id: number;
  ubn: string;
  certificate_type: string;
  valid_from: string;
  valid_to: string;
  created_at?: string;
  updated_at?: string;

  active_blockchain_record: BlockChainRecordRaw;
};

type GenericCertificateSerialized = {
  id: number;
  ubn: string;
  certificateType: string;
  validFrom: string;
  validTo: string;
  createdAt?: string;
  updatedAt?: string;

  active_blockchain_record: BlockChainRecordSerialized;
};

type DailyCertificateRaw = Omit<GenericCertificateRaw, 'certificate_type'> & {
  country_code: string;
  business_type: string;
  has_own_transport: boolean;
  remark: string;
  vc: string;
  qs_number: string;
  gg_number: string;
  pigs_tomorrow: boolean;
  blk: boolean;
  comment: string;
  salmonella_details: string[];
};

type DailyCertificateSerialized = Omit<GenericCertificateSerialized, 'certificateType'> & {
  countryCode: string;
  businessType: string;
  hasOwnTransport: boolean;
  remark: string;
  vc: string;
  qsNumber: string;
  ggNumber: string;
  pigsTomorrow: boolean;
  blk: boolean;
  comment: string;
  salmonellaDetails: string[];
};

type VeterinarianDataYearRaw = {
  id: number;
  source: string;
  export_date: string;
  ubn: string;
  company_name: string;
  location: string;
  animal_category: string;
  ddd_tm: number;
  created_at: string;
  updated_at: string;
  active_blockchain_record: BlockChainRecordRaw;
};

export type VeterinarianDataYearSerialized = {
  id: number;
  source: string;
  exportDate: string;
  ubn: string;
  companyName: string;
  location: string;
  animalCategory: string;
  dddTm: number;
  createdAt: string;
  updatedAt: string;
  activeBlockchainRecord: BlockChainRecordSerialized;
};

export type SlaughterDataTypeRaw = {
  animal_count: number;
  avg_animal_weight: number;
  grouped_by_carcass_code: Record<string, number>;
  grouped_by_organ_code: Record<string, number>;
};

export type SlaughterDataTypeSerialized = {
  animalCount: number;
  avgAnimalWeight: number;
  groupedByCarcassCode: Record<string, number>;
  groupedByOrganCode: Record<string, number>;
};

export type FarmDataTypeRaw = {
  deaths_reports_statistic: {
    animals_count: number;
    animals_weight: number;
    cause_of_death: string;
  }[];
  feed_consumption_report_statistic: {
    start_feed_weight: number;
    intermediate_feed_weight: number;
    finish_feed_weight: number;
  };
};

export type FarmDataTypeSerialized = {
  deathsReportsStatistic: {
    animalsCount: number;
    animalsWeight: number;
    causeOfDeath: string;
  }[];
  feedConsumptionReportStatistic: Record<string, number>;
};

export type GeneticaTypeRaw = {
  mothers_statistic: Record<string, number>;
  fathers_statistic: Record<string, number>;
};

export type GeneticaTypeSerialized = {
  mothersStatistic: Record<string, number>;
  fathersStatistic: Record<string, number>;
};

export interface AnimalPassportsRaw {
  certificates: {
    ikb_certificate: GenericCertificateRaw | null;
    welfare_certificate: GenericCertificateRaw | null;
    daily_certificate: DailyCertificateRaw | null;
  };
  veterinarian_data: Record<string, Record<'current_year_record' | 'prev_year_record', VeterinarianDataYearRaw>>;
  feed_data: Record<string, number>;
  slaughter_data: Record<string, SlaughterDataTypeRaw>;
  farm_data: FarmDataTypeRaw;
  transport_data: Record<string, number>;
  genetic_data: GeneticaTypeRaw;
}

export interface AnimalPassportsSerializedEntry {
  certificates: {
    ikbCertificate: GenericCertificateSerialized | null;
    welfareCertificate: GenericCertificateSerialized | null;
    dailyCertificate: DailyCertificateSerialized | null;
  };
  veterinarianData: Record<
    string,
    Record<'currentYearRecord' | 'prevYearRecord', VeterinarianDataYearSerialized | null>
  >;
  feed: Record<string, number>;
  movementEvents: Record<string, number>;
  farmData: FarmDataTypeSerialized;
  genetica: GeneticaTypeSerialized;
  slaughter: Record<string, SlaughterDataTypeSerialized>;
}

const { columns, serialized, unserialized } = ANIMAL_PASSPORTS.AnimalPassports;
const { certificates, veterinarianData, feed, movementEvents, farmData, genetica, slaughter } = columns;

const getSerializer = (data: DocumentData<AnimalPassportsRaw[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      //
      new CellSerializer(certificates.unserialized, certificates.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(
        veterinarianData.unserialized,
        veterinarianData.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),
      new CellSerializer(feed.unserialized, feed.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(movementEvents.unserialized, movementEvents.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(farmData.unserialized, farmData.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(genetica.unserialized, genetica.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(slaughter.unserialized, slaughter.serialized, serializeObjectSnakeCaseToCamelCase),
    ]),
  ]);
};

export const serializeAnimalPassports = (serverData: ResourceWrapper<AnimalPassportsRaw>) => {
  //
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getSerializer(data);
  return serializer.serialize()[unserialized][0];
};

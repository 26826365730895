import { IntlKeys, ServiceKeys, TitlesKeys } from 'lib/localization/keys';

enum UploadHistorySerialized {
  History = 'History',
}

enum UploadHistoryUnserialized {
  History = 'History',
}

enum UploadUnserializedHeaders {
  status = 'status',
  link = 'file',
  source = 'source',
  user = 'user',
  isEdited = 'is_edited',
  checksum = 'checksum',
  uploadedAt = 'uploaded_at',
  createdAt = 'created_at',
}

enum UploadSerializedHeaders {
  status = 'status',
  link = 'link',
  source = 'source',
  user = 'user',
  isEdited = 'isEdited',
  checksum = 'checksum',
  uploadedAt = 'uploadedAt',
  createdAt = 'createdAt',
}

export const UPLOAD_HISTORY_FIELDS = {
  [UploadHistorySerialized.History]: {
    serialized: UploadHistorySerialized.History,
    unserialized: UploadHistoryUnserialized.History,
    view: TitlesKeys.history,
    columns: {
      [UploadSerializedHeaders.status]: {
        serialized: UploadSerializedHeaders.status,
        unserialized: UploadUnserializedHeaders.status,
        view: IntlKeys.status,
      },
      [UploadSerializedHeaders.link]: {
        serialized: UploadSerializedHeaders.link,
        unserialized: UploadUnserializedHeaders.link,
        view: ServiceKeys.link,
      },
      [UploadSerializedHeaders.source]: {
        serialized: UploadSerializedHeaders.source,
        unserialized: UploadUnserializedHeaders.source,
        view: ServiceKeys.source,
      },
      [UploadSerializedHeaders.user]: {
        serialized: UploadSerializedHeaders.user,
        unserialized: UploadUnserializedHeaders.user,
        view: ServiceKeys.uploadedBy,
      },
      [UploadSerializedHeaders.uploadedAt]: {
        serialized: UploadSerializedHeaders.uploadedAt,
        unserialized: UploadUnserializedHeaders.uploadedAt,
        view: ServiceKeys.uploadedAt,
      },
      [UploadSerializedHeaders.isEdited]: {
        serialized: UploadSerializedHeaders.isEdited,
        unserialized: UploadUnserializedHeaders.isEdited,
        view: ServiceKeys.isEdited,
      },
      [UploadSerializedHeaders.createdAt]: {
        serialized: UploadSerializedHeaders.createdAt,
        unserialized: UploadUnserializedHeaders.createdAt,
        view: ServiceKeys.createdAt,
      },
      [UploadSerializedHeaders.checksum]: {
        serialized: UploadSerializedHeaders.checksum,
        unserialized: UploadUnserializedHeaders.checksum,
        view: ServiceKeys.checksum,
      },
    },
  },
};

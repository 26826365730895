import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_REMOVALS } from './config';

export type GroupRemovalsPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  fin_event_identifier: string;
  alive: string;
  group_removal_number: number;
  weight_slaughtered: number;
  weight_alive: number;
  first_remreason: string;
  second_remreason: string;
  remtype_identifier: string;
  animal_category_identifier: string;
  group_removal_datetime: string;
  wcf_identifier: string;
  not_classified: string;
  class_type: string;
  nl_meat_perc: string;
  nl_aa: string;
  nl_a: string;
  nl_b: string;
  nl_c: string;
  nl_back_fat: string;
  nl_lo_in_depth: string;
  de_lachs: string;
  de_schinken: string;
  de_schaulter: string;
  de_index_punkte: string;
  de_index_factor: string;
  estimated_date_in: string;
  sex_identifier: string;
  without_male: string;
  without_female: string;
  without_male_castrated: string;
  car_pleuritis: string;
  car_inf_skin: string;
  car_inf_feed: string;
  car_pleuritis_in_feed: string;
  car_inf_skin_and_feed: string;
  car_pleuritis_inf_skin: string;
  car_pleuritis_inf_skinand_feed: string;
  organ_affected_liver: string;
  organ_rejected_liver: string;
  organ_affected_lungs: string;
  organ_affected_lungs_and_liver: string;
  organ_affected_lungs_rejected_liver: string;
  qual_identifier: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type GroupRemovalsSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  finEventIdentifier: string;
  alive: string;
  groupRemovalNumber: number;
  weightSlaughtered: number;
  weightAlive: number;
  firstRemreason: string;
  secondRemreason: string;
  remtypeIdentifier: string;
  animalCategoryIdentifier: string;
  groupRemovalDatetime: string;
  wcfIdentifier: string;
  notClassified: string;
  classType: string;
  nlMeatPerc: string;
  nlAa: string;
  nlA: string;
  nlB: string;
  nlC: string;
  nlBackFat: string;
  nlLoInDepth: string;
  deLachs: string;
  deSchinken: string;
  deSchaulter: string;
  deIndexPunkte: string;
  deIndexFactor: string;
  estimatedDateIn: string;
  sexIdentifier: string;
  withoutMale: string;
  withoutFemale: string;
  withoutMaleCastrated: string;
  carPleuritis: string;
  carInfSkin: string;
  carInfFeed: string;
  carPleuritisInFeed: string;
  carInfSkinAndFeed: string;
  carPleuritisInfSkin: string;
  carPleuritisInfSkinAndFeed: string;
  organAffectedLiver: string;
  organRejectedLiver: string;
  organAffectedLungs: string;
  organAffectedLungsAndLiver: string;
  organAffectedLungsRejectedLiver: string;
  qualIdentifier: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = GROUP_REMOVALS.GroupRemoval;

const getDefaultSerializer = (data: DocumentData<GroupRemovalsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.finEventIdentifier.unserialized, columns.finEventIdentifier.serialized),
      new CellSerializer(columns.alive.unserialized, columns.alive.serialized),
      new CellSerializer(columns.groupRemovalNumber.unserialized, columns.groupRemovalNumber.serialized),
      new CellSerializer(columns.weightSlaughtered.unserialized, columns.weightSlaughtered.serialized),
      new CellSerializer(columns.weightAlive.unserialized, columns.weightAlive.serialized),
      new CellSerializer(columns.firstRemreason.unserialized, columns.firstRemreason.serialized),
      new CellSerializer(columns.secondRemreason.unserialized, columns.secondRemreason.serialized),
      new CellSerializer(columns.remtypeIdentifier.unserialized, columns.remtypeIdentifier.serialized),
      new CellSerializer(columns.animalCategoryIdentifier.unserialized, columns.animalCategoryIdentifier.serialized),
      new CellSerializer(columns.groupRemovalDatetime.unserialized, columns.groupRemovalDatetime.serialized),
      new CellSerializer(columns.wcfIdentifier.unserialized, columns.wcfIdentifier.serialized),
      new CellSerializer(columns.notClassified.unserialized, columns.notClassified.serialized),
      new CellSerializer(columns.classType.unserialized, columns.classType.serialized),
      new CellSerializer(columns.nlMeatPerc.unserialized, columns.nlMeatPerc.serialized),
      new CellSerializer(columns.nlAa.unserialized, columns.nlAa.serialized),
      new CellSerializer(columns.nlA.unserialized, columns.nlA.serialized),
      new CellSerializer(columns.nlB.unserialized, columns.nlB.serialized),
      new CellSerializer(columns.nlC.unserialized, columns.nlC.serialized),
      new CellSerializer(columns.nlBackFat.unserialized, columns.nlBackFat.serialized),
      new CellSerializer(columns.nlLoInDepth.unserialized, columns.nlLoInDepth.serialized),
      new CellSerializer(columns.deLachs.unserialized, columns.deLachs.serialized),
      new CellSerializer(columns.deSchinken.unserialized, columns.deSchinken.serialized),
      new CellSerializer(columns.deSchaulter.unserialized, columns.deSchaulter.serialized),
      new CellSerializer(columns.deIndexPunkte.unserialized, columns.deIndexPunkte.serialized),
      new CellSerializer(columns.deIndexFactor.unserialized, columns.deIndexFactor.serialized),
      new CellSerializer(columns.estimatedDateIn.unserialized, columns.estimatedDateIn.serialized),
      new CellSerializer(columns.sexIdentifier.unserialized, columns.sexIdentifier.serialized),
      new CellSerializer(columns.withoutMale.unserialized, columns.withoutMale.serialized),
      new CellSerializer(columns.withoutFemale.unserialized, columns.withoutFemale.serialized),
      new CellSerializer(columns.withoutMaleCastrated.unserialized, columns.withoutMaleCastrated.serialized),
      new CellSerializer(columns.carPleuritis.unserialized, columns.carPleuritis.serialized),
      new CellSerializer(columns.carInfSkin.unserialized, columns.carInfSkin.serialized),
      new CellSerializer(columns.carInfFeed.unserialized, columns.carInfFeed.serialized),
      new CellSerializer(columns.carPleuritisInFeed.unserialized, columns.carPleuritisInFeed.serialized),
      new CellSerializer(columns.carInfSkinAndFeed.unserialized, columns.carInfSkinAndFeed.serialized),
      new CellSerializer(columns.carPleuritisInfSkin.unserialized, columns.carPleuritisInfSkin.serialized),
      new CellSerializer(
        columns.carPleuritisInfSkinAndFeed.unserialized,
        columns.carPleuritisInfSkinAndFeed.serialized,
      ),
      new CellSerializer(columns.organAffectedLiver.unserialized, columns.organAffectedLiver.serialized),
      new CellSerializer(columns.organRejectedLiver.unserialized, columns.organRejectedLiver.serialized),
      new CellSerializer(columns.organAffectedLungs.unserialized, columns.organAffectedLungs.serialized),
      new CellSerializer(
        columns.organAffectedLungsAndLiver.unserialized,
        columns.organAffectedLungsAndLiver.serialized,
      ),
      new CellSerializer(
        columns.organAffectedLungsRejectedLiver.unserialized,
        columns.organAffectedLungsRejectedLiver.serialized,
      ),
      new CellSerializer(columns.qualIdentifier.unserialized, columns.qualIdentifier.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeGroupRemovals = (serverData: ResourcesWrapper<GroupRemovalsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

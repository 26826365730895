import { ApiClient } from 'lib/http/ApiClient';
import { IntlKeys } from 'lib/localization/keys';

import { groupGroupsTableConfig } from 'lib/tables/config/farmManagementData/groupGroups';
import { groupFinancialEventsTableConfig } from 'lib/tables/config/farmManagementData/groupFinancialEvents';
import { groupWeightsTableConfig } from 'lib/tables/config/farmManagementData/groupWeights';
import { groupRemovalsTableConfig } from 'lib/tables/config/farmManagementData/groupRemovals';
import { groupArrivalTableConfig } from 'lib/tables/config/farmManagementData/groupArrival';
import { groupMovesTableConfig } from 'lib/tables/config/farmManagementData/groupMoves';
import { codesAnimalRemarksTableConfig } from 'lib/tables/config/farmManagementData/codesAnimalRemarks';
import { codesDefectsTableConfig } from 'lib/tables/config/farmManagementData/codesDefects';
import { codesDiagnoseTableConfig } from 'lib/tables/config/farmManagementData/codesDiagnose';
import { codesAnimalScoreTableConfig } from 'lib/tables/config/farmManagementData/codesAnimalScore';
import { codesInseminationTableConfig } from 'lib/tables/config/farmManagementData/codesInsemination';
import { codesTreatmentsTableConfig } from 'lib/tables/config/farmManagementData/codesTreatments';
import { groupPurchasedFoodTableConfig } from 'lib/tables/config/farmManagementData/groupPurchasedFood';
import { groupUsedFoodTableConfig } from 'lib/tables/config/farmManagementData/groupUsedFood';
import { codesMatingTypeTableConfig } from 'lib/tables/config/farmManagementData/codesMatingType';
import { codesWeightTypeTableConfig } from 'lib/tables/config/farmManagementData/codesWeightType';
import { codesRemovalTypeTableConfig } from 'lib/tables/config/farmManagementData/codesRemovalType';
import { codesAnimalIdentityTypeTableConfig } from 'lib/tables/config/farmManagementData/codesAnimalIdentityType';
import { codesFarmOptionTableConfig } from 'lib/tables/config/farmManagementData/codesFarmOption';
import { codesBarnTableConfig } from 'lib/tables/config/farmManagementData/codesBarn';
import { codesRoomTableConfig } from 'lib/tables/config/farmManagementData/codesRoom';
import { codesPenTableConfig } from 'lib/tables/config/farmManagementData/codesPen';
import { codesAccountTableConfig } from 'lib/tables/config/farmManagementData/codesAccount';
import { codesEventTypeTableConfig } from 'lib/tables/config/farmManagementData/codesEventTypes';
import { groupExpenseTableConfig } from 'lib/tables/config/farmManagementData/groupExpense';

import {
  ensureGetAdminFarmGroups,
  ensureGetAdminGroupFinancialEvents,
  ensureGetAdminGroupRemovals,
  ensureGetAdminGroupWeights,
  ensureGetAdminGroupArrival,
  ensureGetAdminGroupMoves,
  ensureGetAdminCodesAnimalRemarks,
  ensureGetAdminCodesDefects,
  ensureGetAdminCodesDiagnose,
  ensureGetAdminCodesAnimalScore,
  ensureGetAdminCodesInsemination,
  ensureGetAdminCodesTreatments,
  ensureGetAdminGroupPurchasedFood,
  ensureGetAdminGroupUsedFood,
  ensureGetAdminCodesMatingType,
  ensureGetAdminCodesWeightType,
  ensureGetAdminCodesRemovalType,
  ensureGetAdminCodesAnimalIdentityType,
  ensureGetAdminCodesFarmOption,
  ensureGetAdminCodesBarn,
  ensureGetAdminCodesRoom,
  ensureGetAdminCodesPen,
  ensureGetAdminCodesAccount,
  ensureGetAdminCodesEventType,
  ensureGetAdminGroupExpense,
} from 'store/sagas/batches';

export enum FarmManagementCategories {
  groupGroups = 'group-groups',
  groupFinancialEvents = 'group-financial-events',
  groupGroupWeight = 'group-group-weight',
  groupGroupRemoval = 'group-group-removal',
  groupGroupsArrival = 'group-groups-arrival',
  groupGroupMoves = 'group-group-moves',
  codesAnimalRemarks = 'codes-animal-remarks',
  codesDefects = 'codes-defects',
  codesDiagnose = 'codes-diagnose',
  codesAnimalScore = 'codes-animal-score',
  codesInsemination = 'codes-insemination',
  codesTreatments = 'codes-treatments',
  groupPurchasedFood = 'group-purchased-food',
  groupUsedFood = 'group-used-food',
  codesMatingType = 'codes-mating-type',
  codesWeightType = 'codes-weight-type',
  codesRemovalType = 'codes-removal-type',
  codesAnimalIdentityType = 'codes-animal-identity-type',
  codesFarmOption = 'codes-farm-option',
  codesBarn = 'codes-barn',
  codesRoom = 'codes-room',
  codesPen = 'codes-pen',
  codesAccount = 'codes-account',
  codesEventType = 'codes-event-type',
  groupExpense = 'group-expense',
}

export const FarmManagementCategoriesActions = {
  [FarmManagementCategories.groupGroups]: ApiClient.adminGetFarmGroups,
  [FarmManagementCategories.groupFinancialEvents]: ApiClient.adminGetGroupFinancialEvents,
  [FarmManagementCategories.groupGroupWeight]: ApiClient.adminGetGroupWeights,
  [FarmManagementCategories.groupGroupRemoval]: ApiClient.adminGetGroupRemovals,
  [FarmManagementCategories.groupGroupsArrival]: ApiClient.adminGetGroupArrival,
  [FarmManagementCategories.groupGroupMoves]: ApiClient.adminGetGroupMoves,
  [FarmManagementCategories.codesAnimalRemarks]: ApiClient.adminGetCodesAnimalRemarks,
  [FarmManagementCategories.codesDefects]: ApiClient.adminGetCodesDefects,
  [FarmManagementCategories.codesDiagnose]: ApiClient.adminGetCodesDiagnose,
  [FarmManagementCategories.codesAnimalScore]: ApiClient.adminGetCodesAnimalScore,
  [FarmManagementCategories.codesInsemination]: ApiClient.adminGetCodesInsemination,
  [FarmManagementCategories.codesTreatments]: ApiClient.adminGetCodesTreatments,
  [FarmManagementCategories.groupPurchasedFood]: ApiClient.adminGetGroupPurchasedFood,
  [FarmManagementCategories.groupUsedFood]: ApiClient.adminGetGroupUsedFood,
  [FarmManagementCategories.codesMatingType]: ApiClient.adminGetCodesMatingType,
  [FarmManagementCategories.codesWeightType]: ApiClient.adminGetCodesWeightType,
  [FarmManagementCategories.codesRemovalType]: ApiClient.adminGetCodesRemovalType,
  [FarmManagementCategories.codesAnimalIdentityType]: ApiClient.adminGetCodesAnimalIdentityType,
  [FarmManagementCategories.codesFarmOption]: ApiClient.adminGetCodesFarmOption,
  [FarmManagementCategories.codesBarn]: ApiClient.adminGetCodesBarn,
  [FarmManagementCategories.codesRoom]: ApiClient.adminGetCodesRoom,
  [FarmManagementCategories.codesPen]: ApiClient.adminGetCodesPen,
  [FarmManagementCategories.codesAccount]: ApiClient.adminGetCodesAccount,
  [FarmManagementCategories.codesEventType]: ApiClient.adminGetCodesEventType,
  [FarmManagementCategories.groupExpense]: ApiClient.adminGetGroupExpense,
};

export const categoriesConfig = [
  {
    titleIntlId: IntlKeys.farmManagementDataCodesAccount,
    category: FarmManagementCategories.codesAccount,
    tableConfig: codesAccountTableConfig,
    ensure: ensureGetAdminCodesAccount,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesAnimalIdentityType,
    category: FarmManagementCategories.codesAnimalIdentityType,
    tableConfig: codesAnimalIdentityTypeTableConfig,
    ensure: ensureGetAdminCodesAnimalIdentityType,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesAnimalRemarks,
    category: FarmManagementCategories.codesAnimalRemarks,
    tableConfig: codesAnimalRemarksTableConfig,
    ensure: ensureGetAdminCodesAnimalRemarks,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesAnimalScore,
    category: FarmManagementCategories.codesAnimalScore,
    tableConfig: codesAnimalScoreTableConfig,
    ensure: ensureGetAdminCodesAnimalScore,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesBarn,
    category: FarmManagementCategories.codesBarn,
    tableConfig: codesBarnTableConfig,
    ensure: ensureGetAdminCodesBarn,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesDefects,
    category: FarmManagementCategories.codesDefects,
    tableConfig: codesDefectsTableConfig,
    ensure: ensureGetAdminCodesDefects,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesDiagnose,
    category: FarmManagementCategories.codesDiagnose,
    tableConfig: codesDiagnoseTableConfig,
    ensure: ensureGetAdminCodesDiagnose,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesEventTypes,
    category: FarmManagementCategories.codesEventType,
    tableConfig: codesEventTypeTableConfig,
    ensure: ensureGetAdminCodesEventType,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesFarmOption,
    category: FarmManagementCategories.codesFarmOption,
    tableConfig: codesFarmOptionTableConfig,
    ensure: ensureGetAdminCodesFarmOption,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesInsemination,
    category: FarmManagementCategories.codesInsemination,
    tableConfig: codesInseminationTableConfig,
    ensure: ensureGetAdminCodesInsemination,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesMatingType,
    category: FarmManagementCategories.codesMatingType,
    tableConfig: codesMatingTypeTableConfig,
    ensure: ensureGetAdminCodesMatingType,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesPen,
    category: FarmManagementCategories.codesPen,
    tableConfig: codesPenTableConfig,
    ensure: ensureGetAdminCodesPen,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesRemovalType,
    category: FarmManagementCategories.codesRemovalType,
    tableConfig: codesRemovalTypeTableConfig,
    ensure: ensureGetAdminCodesRemovalType,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesRoom,
    category: FarmManagementCategories.codesRoom,
    tableConfig: codesRoomTableConfig,
    ensure: ensureGetAdminCodesRoom,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesTreatments,
    category: FarmManagementCategories.codesTreatments,
    tableConfig: codesTreatmentsTableConfig,
    ensure: ensureGetAdminCodesTreatments,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataCodesWeightType,
    category: FarmManagementCategories.codesWeightType,
    tableConfig: codesWeightTypeTableConfig,
    ensure: ensureGetAdminCodesWeightType,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupExpense,
    category: FarmManagementCategories.groupExpense,
    tableConfig: groupExpenseTableConfig,
    ensure: ensureGetAdminGroupExpense,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupFinancialEvents,
    category: FarmManagementCategories.groupFinancialEvents,
    tableConfig: groupFinancialEventsTableConfig,
    ensure: ensureGetAdminGroupFinancialEvents,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupPurchasedFood,
    category: FarmManagementCategories.groupPurchasedFood,
    tableConfig: groupPurchasedFoodTableConfig,
    ensure: ensureGetAdminGroupPurchasedFood,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupUsedFood,
    category: FarmManagementCategories.groupUsedFood,
    tableConfig: groupUsedFoodTableConfig,
    ensure: ensureGetAdminGroupUsedFood,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupGroups,
    category: FarmManagementCategories.groupGroups,
    tableConfig: groupGroupsTableConfig,
    ensure: ensureGetAdminFarmGroups,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupsArrival,
    category: FarmManagementCategories.groupGroupsArrival,
    tableConfig: groupArrivalTableConfig,
    ensure: ensureGetAdminGroupArrival,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupMoves,
    category: FarmManagementCategories.groupGroupMoves,
    tableConfig: groupMovesTableConfig,
    ensure: ensureGetAdminGroupMoves,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupRemovals,
    category: FarmManagementCategories.groupGroupRemoval,
    tableConfig: groupRemovalsTableConfig,
    ensure: ensureGetAdminGroupRemovals,
  },
  {
    titleIntlId: IntlKeys.farmManagementDataGroupWeights,
    category: FarmManagementCategories.groupGroupWeight,
    tableConfig: groupWeightsTableConfig,
    ensure: ensureGetAdminGroupWeights,
  },
];

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { WEEKLY_DOCUMENT_FIELDS } from 'lib/excel/config/weekly/structure';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

import { ResourcesWrapper } from 'lib/http/utils';

interface RecognitionUnserialized {
  id: number;
  updated_date: string;
  code: string;
  reg_number: string;
  name: string;
  program: string;
  created_at: string;
  updated_at: string;
  ubn: string;
}

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(
      WEEKLY_DOCUMENT_FIELDS.Recognition.unserialized,
      WEEKLY_DOCUMENT_FIELDS.Recognition.serialized,
      [
        new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
        new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
        new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.ubn.unserialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.ubn.serialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.date.unserialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.date.serialized,
          dateToFormattedString,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.program.unserialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.program.serialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.code.unserialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.code.serialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.regnr.unserialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.regnr.serialized,
        ),
        new CellSerializer(
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.name.unserialized,
          WEEKLY_DOCUMENT_FIELDS.Recognition.columns.name.serialized,
        ),
        new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
        new CellSerializer(
          SERVICE_FIELDS.blockchainTimestamp.unserialized,
          SERVICE_FIELDS.blockchainTimestamp.serialized,
        ),
          new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
      ],
    ),
  ]);
};

export const serializeRecognitionsInfo = (serverData: ResourcesWrapper<RecognitionUnserialized>) => {
  const data = {
    [WEEKLY_DOCUMENT_FIELDS.Recognition.unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

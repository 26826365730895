import { IntlKeys } from 'lib/localization/keys';

enum CodesBarnSerialized {
  CodesBarn = 'CodesBarn',
}

enum CodesBarnUnserialized {
  CodesBarn = 'CodesBarn',
}

export enum CodesBarnSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  barnIdentifier = 'barnIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  codeBarnDatetime = 'codeBarnDatetime',
  correctionFin = 'correctionFin',
  isEarlyWeanUnit = 'isEarlyWeanUnit',
  surface = 'surface',
  vvoNumber = 'vvoNumber',
  euApprovalNumber = 'euApprovalNumber',
  correctionWeaners = 'correctionWeaners',
  correctionReplGilts = 'correctionReplGilts',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesBarnUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  barnIdentifier: 'barn_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  codeBarnDatetime: 'code_barn_datetime',
  correctionFin: 'correction_fin',
  isEarlyWeanUnit: 'is_early_wean_unit',
  surface: 'surface',
  vvoNumber: 'vvo_number',
  euApprovalNumber: 'eu_approval_number',
  correctionWeaners: 'correction_weaners',
  correctionReplGilts: 'correction_repl_gilts',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_BARN = {
  [CodesBarnSerialized.CodesBarn]: {
    serialized: CodesBarnSerialized.CodesBarn,
    unserialized: CodesBarnUnserialized.CodesBarn,
    view: IntlKeys.farmManagementDataBarnList,

    columns: {
      [CodesBarnSerializedHeaders.id]: {
        serialized: CodesBarnSerializedHeaders.id,
        unserialized: CodesBarnUnserializedHeaders.id,
      },
      [CodesBarnSerializedHeaders.companyIdentifier]: {
        serialized: CodesBarnSerializedHeaders.companyIdentifier,
        unserialized: CodesBarnUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesBarnSerializedHeaders.farmIdentifier]: {
        serialized: CodesBarnSerializedHeaders.farmIdentifier,
        unserialized: CodesBarnUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [CodesBarnSerializedHeaders.barnIdentifier]: {
        serialized: CodesBarnSerializedHeaders.barnIdentifier,
        unserialized: CodesBarnUnserializedHeaders.barnIdentifier,
        view: IntlKeys.farmManagementDataBarnId,
      },
      [CodesBarnSerializedHeaders.abbreviation]: {
        serialized: CodesBarnSerializedHeaders.abbreviation,
        unserialized: CodesBarnUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesBarnSerializedHeaders.description]: {
        serialized: CodesBarnSerializedHeaders.description,
        unserialized: CodesBarnUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesBarnSerializedHeaders.codeBarnDatetime]: {
        serialized: CodesBarnSerializedHeaders.codeBarnDatetime,
        unserialized: CodesBarnUnserializedHeaders.codeBarnDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesBarnSerializedHeaders.correctionFin]: {
        serialized: CodesBarnSerializedHeaders.correctionFin,
        unserialized: CodesBarnUnserializedHeaders.correctionFin,
        view: IntlKeys.farmManagementDataCorrectionFin,
      },
      [CodesBarnSerializedHeaders.isEarlyWeanUnit]: {
        serialized: CodesBarnSerializedHeaders.isEarlyWeanUnit,
        unserialized: CodesBarnUnserializedHeaders.isEarlyWeanUnit,
        view: IntlKeys.farmManagementDataIsEarlyWeanUnit,
      },
      [CodesBarnSerializedHeaders.surface]: {
        serialized: CodesBarnSerializedHeaders.surface,
        unserialized: CodesBarnUnserializedHeaders.surface,
        view: IntlKeys.farmManagementDataSurface,
      },
      [CodesBarnSerializedHeaders.vvoNumber]: {
        serialized: CodesBarnSerializedHeaders.vvoNumber,
        unserialized: CodesBarnUnserializedHeaders.vvoNumber,
        view: IntlKeys.farmManagementDataVvoNumber,
      },
      [CodesBarnSerializedHeaders.euApprovalNumber]: {
        serialized: CodesBarnSerializedHeaders.euApprovalNumber,
        unserialized: CodesBarnUnserializedHeaders.euApprovalNumber,
        view: IntlKeys.farmManagementDataEuApprovalNumber,
      },
      [CodesBarnSerializedHeaders.correctionWeaners]: {
        serialized: CodesBarnSerializedHeaders.correctionWeaners,
        unserialized: CodesBarnUnserializedHeaders.correctionWeaners,
        view: IntlKeys.farmManagementDataCorrectionWeaners,
      },
      [CodesBarnSerializedHeaders.correctionReplGilts]: {
        serialized: CodesBarnSerializedHeaders.correctionReplGilts,
        unserialized: CodesBarnUnserializedHeaders.correctionReplGilts,
        view: IntlKeys.farmManagementDataCorrectionReplGilts,
      },
      [CodesBarnSerializedHeaders.ubnList]: {
        serialized: CodesBarnSerializedHeaders.ubnList,
        unserialized: CodesBarnUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesBarnSerializedHeaders.kvkList]: {
        serialized: CodesBarnSerializedHeaders.kvkList,
        unserialized: CodesBarnUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesBarnSerializedHeaders.registrationNameList]: {
        serialized: CodesBarnSerializedHeaders.registrationNameList,
        unserialized: CodesBarnUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { call, select, takeEvery } from 'redux-saga/effects';

import { ApiClient } from 'lib/http/ApiClient';
import ensure, { Ensure } from 'lib/ensure';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import {
  fetchVriCalculatedStatisticsAction,
  fetchVriCalculatedResourcesAction,
  updateVriCalculatedResourcesAction, visitVriToolAction, visitVriAction,
} from './actions';
import { serializeVriResourcesData, serializeVriResourceData, serializeVriStatistics } from './entities';
import { userHasPermissionSelector } from 'store/auth/reducer';
import { PERMISSIONS } from 'store/auth/permissions';
import { CustomAction } from 'lib/actions';

export const ensureGetCalculatedReports = ensure({
  api: ApiClient.getCalculatedReports,
  action: fetchVriCalculatedResourcesAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureAdminGetCalculatedReports = ensure({
  api: ApiClient.adminGetCalculatedReports,
  action: fetchVriCalculatedResourcesAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureUpdateCalculatedReports = ensure({
  api: ApiClient.updateCalculatedReports,
  action: updateVriCalculatedResourcesAction,
  serializeSuccessPayload: serializeVriResourceData,
});

export const ensureAdminUpdateCalculatedReports = ensure({
  api: ApiClient.adminUpdateCalculatedReports,
  action: updateVriCalculatedResourcesAction,
  serializeSuccessPayload: serializeVriResourceData,
});

export const ensureGetCalculatedReportsStatistics = ensure({
  api: ApiClient.getCalculatedReportsStatistics,
  action: fetchVriCalculatedStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

export const ensureAdminGetCalculatedReportsStatistics = ensure({
  api: ApiClient.adminGetCalculatedReportsStatistics,
  action: fetchVriCalculatedStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

export const ensureVisitVriTool = ensure({
  api: ApiClient.visitVriTool,
  action: visitVriToolAction,
});

export const ensureVisitVri = ensure({
  api: ApiClient.visitVri,
  action: visitVriAction,
});

function* getVriCalculatedData({ payload }: ObjectWithProps) {
  const { ensure, ...otherPayload } = payload;

  yield call(ensure, otherPayload);
}

function* getCalculatedReportsStatistics({ payload }: ObjectWithProps) {
  const { filter } = payload;
  const isAdmin: boolean = (yield select(userHasPermissionSelector))(PERMISSIONS.LEVEL1);

  yield call(isAdmin ? ensureAdminGetCalculatedReportsStatistics : ensureGetCalculatedReportsStatistics, { filter });
}

const callEnsure = (ensure: Ensure) =>
  function* ({ payload }: CustomAction) {
    yield call(ensure, payload);
  };

export default function* () {
  yield takeEvery(fetchVriCalculatedResourcesAction.request, getVriCalculatedData);
  yield takeEvery(updateVriCalculatedResourcesAction.request, getVriCalculatedData);
  yield takeEvery(fetchVriCalculatedStatisticsAction.request, getCalculatedReportsStatistics);
  yield takeEvery(visitVriToolAction.request, callEnsure(ensureVisitVriTool));
  yield takeEvery(visitVriAction.request, callEnsure(ensureVisitVri));
}

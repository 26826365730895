import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { ELECTRICITY_CONSUMPTION_RATES } from 'store/entities/referenceTables/electricityConsumptionRates/config';

const { columns, view, serialized } = ELECTRICITY_CONSUMPTION_RATES.ElectricityConsumptionRates;

export const electricityConsumptionRatesTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.householdPeopleQuantity.view,
          field: columns.householdPeopleQuantity.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.electricityConsumption.view,
          field: columns.electricityConsumption.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.electricityConsumption.serialized] &&
            roundToInteger(data[columns.electricityConsumption.serialized]),
        },
      }),
    ],
  }),
]);

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_FARM_OPTION } from './config';

export type CodesFarmOptionPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  farm_option_identifier: string;
  description: string;
  value: string;
  farm_option_datetime: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type CodesFarmOptionSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  farmOptionId: string;
  description: string;
  value: string;
  farmOptionDatetime: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = CODES_FARM_OPTION.CodesFarmOption;

const getDefaultSerializer = (data: DocumentData<CodesFarmOptionPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.farmOptionId.unserialized, columns.farmOptionId.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.value.unserialized, columns.value.serialized),
      new CellSerializer(columns.farmOptionDatetime.unserialized, columns.farmOptionDatetime.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeCodesFarmOption = (serverData: ResourcesWrapper<CodesFarmOptionPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

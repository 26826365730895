import { CFP_GFLI_FEEDPRINT_TABLE } from 'lib/excel/config/referenceTables/cfpGfliFeedprintTable/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = CFP_GFLI_FEEDPRINT_TABLE.CfpGfliFeedprintTable;

export const serializecfpGfliFeedprintTableData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.feedPrintCode.original, columns.feedPrintCode.serialized),
      new CellSerializer(columns.cvbCode.original, columns.cvbCode.serialized),
      new CellSerializer(columns.source.original, columns.source.serialized),
      new CellSerializer(columns.productNameEn.original, columns.productNameEn.serialized),
      new CellSerializer(columns.productNameNl.original, columns.productNameNl.serialized),
      new CellSerializer(columns.productCodeEu.original, columns.productCodeEu.serialized),
      new CellSerializer(columns.productNameEu.original, columns.productNameEu.serialized),
      new CellSerializer(columns.origin.original, columns.origin.serialized),
      new CellSerializer(columns.climate.original, columns.climate.serialized),
    ]),
  ]);
};

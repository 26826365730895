import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_FINANCIAL_EVENTS } from './config';

export type GroupFinancialEventsPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  fin_event_identifier: string;
  event_type_identifier: string;
  event_date: string;
  contact_identifier: string;
  amount_ex_vat: number;
  amount_vat: number;
  amount_in_vat: number;
  invoice_number: string;
  invoice_date: string;
  financial_notes: string;
  group_datetime: string;
  barn_identifier: string;
  room_identifier: string;
  pen_identifier: string;
  group_identifier: string;
  valve: string;
  input_event_identifier: string;
  import_identifier: string;
  input_warnings: string;
  input_type_stamp: string;
  scanned_identifiers: string;
  user_identifier: string;
  batch_number_production: string;
  batch_number_delivery: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type GroupFinancialEventsSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  finEventIdentifier: string;
  eventTypeIdentifier: string;
  eventDate: string;
  contactIdentifier: string;
  amountExVat: number;
  amountVat: number;
  amountInVat: number;
  invoiceNumber: string;
  invoiceDate: string;
  financialNotes: string;
  groupDatetime: string;
  barnIdentifier: string;
  roomIdentifier: string;
  penIdentifier: string;
  groupIdentifier: string;
  valve: string;
  inputEventIdentifier: string;
  importIdentifier: string;
  inputWarnings: string;
  inputTypeStamp: string;
  scannedIdentifiers: string;
  userIdentifier: string;
  batchNumberProduction: string;
  batchNumberDelivery: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = GROUP_FINANCIAL_EVENTS.GroupFinancialEvents;

const getDefaultSerializer = (data: DocumentData<GroupFinancialEventsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.finEventIdentifier.unserialized, columns.finEventIdentifier.serialized),
      new CellSerializer(columns.eventTypeIdentifier.unserialized, columns.eventTypeIdentifier.serialized),
      new CellSerializer(columns.eventDate.unserialized, columns.eventDate.serialized),
      new CellSerializer(columns.contactIdentifier.unserialized, columns.contactIdentifier.serialized),
      new CellSerializer(columns.amountExVat.unserialized, columns.amountExVat.serialized),
      new CellSerializer(columns.amountVat.unserialized, columns.amountVat.serialized),
      new CellSerializer(columns.amountInVat.unserialized, columns.amountInVat.serialized),
      new CellSerializer(columns.invoiceNumber.unserialized, columns.invoiceNumber.serialized),
      new CellSerializer(columns.invoiceDate.unserialized, columns.invoiceDate.serialized),
      new CellSerializer(columns.financialNotes.unserialized, columns.financialNotes.serialized),
      new CellSerializer(columns.groupDatetime.unserialized, columns.groupDatetime.serialized),
      new CellSerializer(columns.barnIdentifier.unserialized, columns.barnIdentifier.serialized),
      new CellSerializer(columns.roomIdentifier.unserialized, columns.roomIdentifier.serialized),
      new CellSerializer(columns.penIdentifier.unserialized, columns.penIdentifier.serialized),
      new CellSerializer(columns.groupIdentifier.unserialized, columns.groupIdentifier.serialized),
      new CellSerializer(columns.valve.unserialized, columns.valve.serialized),
      new CellSerializer(columns.inputEventIdentifier.unserialized, columns.inputEventIdentifier.serialized),
      new CellSerializer(columns.importIdentifier.unserialized, columns.importIdentifier.serialized),
      new CellSerializer(columns.inputWarnings.unserialized, columns.inputWarnings.serialized),
      new CellSerializer(columns.inputTypeStamp.unserialized, columns.inputTypeStamp.serialized),
      new CellSerializer(columns.scannedIdentifiers.unserialized, columns.scannedIdentifiers.serialized),
      new CellSerializer(columns.userIdentifier.unserialized, columns.userIdentifier.serialized),
      new CellSerializer(columns.batchNumberProduction.unserialized, columns.batchNumberProduction.serialized),
      new CellSerializer(columns.batchNumberDelivery.unserialized, columns.batchNumberDelivery.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeGroupFinancialEvents = (serverData: ResourcesWrapper<GroupFinancialEventsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { currentRouteSelector } from 'store/reducers/routerReducer';
import { generateDocTitle } from './utils';

export const useRouteTitle = (
  overrides: Omit<Parameters<typeof generateDocTitle>[0], 'docTitle' | 'routerProps'>,
) => {
  const currentRoute = useSelector(currentRouteSelector);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  return generateDocTitle({
    docTitle: currentRoute.docTitle,
    routerProps: {
      history,
      location,
      match,
    },
    ...overrides,
  });
};

import { ALLOCATION_MANURE_FIELDS } from 'lib/excel/config/referenceTables/allocationManure/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = ALLOCATION_MANURE_FIELDS.AllocationManure;

export const getAllocationManureDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.animalCategory.serialized,
        extractPropName: columns.animalCategory.view,
      }),
      new DisplayHeader({ headerName: columns.manure.serialized, extractPropName: columns.manure.view }),
      new DisplayHeader({ headerName: columns.gas.serialized, extractPropName: columns.gas.view }),
      new DisplayHeader({ headerName: columns.electricity.serialized, extractPropName: columns.electricity.view }),
      new DisplayHeader({ headerName: columns.water.serialized, extractPropName: columns.water.view }),
    ]),
  ]);
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { GROUP_MOVES } from 'store/entities/farmManagementData/groupMoves/config';

const { columns, view, serialized } = GROUP_MOVES.GroupMoves;

export const groupMovesTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.finEventIdentifier.view,
          field: columns.finEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupFrom.view,
          field: columns.groupFrom.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.barnFrom.view,
          field: columns.barnFrom.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.roomFrom.view,
          field: columns.roomFrom.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.penFrom.view,
          field: columns.penFrom.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.animalCategoryIdFrom.view,
          field: columns.animalCategoryIdFrom.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.number.view,
          field: columns.number.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.weight.view,
          field: columns.weight.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.groupMoveDate.view,
          field: columns.groupMoveDate.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.animalCategoryIdTo.view,
          field: columns.animalCategoryIdTo.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.withoutMale.view,
          field: columns.withoutMale.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.withoutFemale.view,
          field: columns.withoutFemale.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.withoutMaleCastrated.view,
          field: columns.withoutMaleCastrated.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.adminDate.view,
          field: columns.adminDate.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.remReason.view,
          field: columns.remReason.serialized,
        },
      }),
    ],
  }),
]);

import assign from 'lodash/assign';
import { BaseFilter, FilterHelpers, FilterValueType } from 'lib/filters';
import { DateInterval, dateRangeFilter } from 'lib/filters/filterTypes/dateRange';
import { strictValueFilter } from 'lib/filters/filterTypes/strictValue';
import { multiValueFilter, singleValueFilter } from 'lib/filters/filterTypes/multiValue';
import { blockchainPermissionsFilter } from 'lib/filters/filterTypes/blockchainPermissions';
import {
  SlaughterDetailsSerializedHeaders,
  SlaughterDetailsUnserializedHeaders,
} from 'lib/tables/config/slaughter/details/config';
import {
  InvoiceDetailsSerializedHeaders,
  InvoiceDetailsUnserializedHeaders,
} from 'lib/tables/config/invoices/details/config';
import { FILTER_ENTITY_TYPES } from 'store/filters/reducer';
import { IntlKeys } from 'lib/localization/keys';
import {
  errorLogTypeOptions,
  farmManagementCategoryOptions,
  statusOptions,
  uploadCategoryOptions,
  uploadStatusOptions,
} from 'lib/filters/filterOptions';
import { PERMISSIONS } from '../auth/permissions';
import { generateOptions } from "../../components/form/periodDatePicker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import moment from 'moment';

export class SimpleFilters<FilterKeys extends string = string> extends BaseFilter<FilterKeys> {
  config = {} as Record<FilterKeys, FilterHelpers>;
  keyMapping = {} as Record<FilterKeys, string | Record<string, string>>;

  constructor({
    config,
    keyMapping,
  }: {
    config: Record<FilterKeys, FilterHelpers>;
    keyMapping: Record<FilterKeys, string | Record<string, string>>;
  }) {
    super();

    this.config = config;
    this.keyMapping = keyMapping;
  }

  serializeFilters = (filters: Partial<Record<FilterKeys, FilterValueType>>) => {
    const initialValues = this.getInitialValues();
    const initialValuesEntries = Object.entries(initialValues) as [FilterKeys, FilterValueType][];
    const filterEntries = Object.entries(filters) as [FilterKeys, FilterValueType][];

    const applyFiltersKeyMapping = (f: [FilterKeys, FilterValueType][]) =>
      f.reduce<Partial<Record<FilterKeys, FilterValueType>>>((resultFilters, [filterKey, value]) => {
        if (this.config.hasOwnProperty(filterKey)) {
          const filterValues = this.config[filterKey].getValues(value);

          if (value?.constructor === Object) {
            const filterMappingEntries = Object.entries(this.keyMapping[filterKey]) as [FilterKeys, string][];

            /**
             * For cases when need to flatten nested filters with:
             * for example:
             *
             * keyMapping: {
             *   createdAt: {
             *     dateFrom: 'created_at_from',
             *     dateTo: 'created_at_to',
             *   },
             * }
             *
             * this:
             *   {
             *     createdAt: {
             *       dateFrom: 'value',
             *       dateTo: 'value',
             *     },
             *   }
             *
             * into:
             *   {
             *     created_at_from: 'value'
             *     created_at_to: 'value',
             *   }
             *
             */
            const x = filterMappingEntries.reduce<Record<string, string>>((acc, [nestedFilterKey, endpointFilter]) => {
              acc[endpointFilter] = filterValues[nestedFilterKey];

              return acc;
            }, {});
            //
            Object.assign(resultFilters, x);
            //
          } else {
            resultFilters[this.keyMapping[filterKey] as FilterKeys] = filterValues;
          }
        }

        return resultFilters;
      }, {});

    const presentFilters = applyFiltersKeyMapping(filterEntries);
    const presentInitialValues = applyFiltersKeyMapping(initialValuesEntries);

    const filter = assign(presentInitialValues, presentFilters);

    return {
      filter,
    };
  };
}

const filterConfigs: Record<FILTER_ENTITY_TYPES, SimpleFilters> = {
  [FILTER_ENTITY_TYPES.CERTIFICATES_ROOT]: new SimpleFilters({
    config: {
      ubn: singleValueFilter(),
      // kvk: singleValueFilter(),
      orgKvk: singleValueFilter(),
    },
    keyMapping: {
      ubn: 'ubn',
      // kvk: 'kvk',
      orgKvk: 'kvk',
    },
  }),
  [FILTER_ENTITY_TYPES.CERTIFICATES_DAILY]: new SimpleFilters({
    config: {
      createdAt: dateRangeFilter(IntlKeys.created),
      validDate: dateRangeFilter(IntlKeys.valid),
      ubn: strictValueFilter,
    },
    keyMapping: {
      createdAt: {
        dateFrom: 'created_at_from',
        dateTo: 'created_at_to',
      },
      ubn: 'ubn',
      validDate: {
        dateFrom: 'valid_date_from',
        dateTo: 'valid_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_RECOGNITIONS]: new SimpleFilters({
    config: {
      createdAt: dateRangeFilter(IntlKeys.created),
      ubn: strictValueFilter,
      updatedDate: dateRangeFilter(IntlKeys.updated),
    },
    keyMapping: {
      createdAt: {
        dateFrom: 'created_at_from',
        dateTo: 'created_at_to',
      },
      ubn: 'ubn',
      updatedDate: {
        dateFrom: 'updated_date_from',
        dateTo: 'updated_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_FARMS]: new SimpleFilters({
    config: {
      createdAt: dateRangeFilter(IntlKeys.created),
      ubn: strictValueFilter,
      updatedDate: dateRangeFilter(IntlKeys.updated),
    },
    keyMapping: {
      createdAt: {
        dateFrom: 'created_at_from',
        dateTo: 'created_at_to',
      },
      ubn: 'ubn',
      updatedDate: {
        dateFrom: 'updated_date_from',
        dateTo: 'updated_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.CERTIFICATES_WEEKLY_DEVIATIONS]: new SimpleFilters({
    config: {
      createdAt: dateRangeFilter(IntlKeys.created),
      ubn: strictValueFilter,
      updatedDate: dateRangeFilter(IntlKeys.updated),
    },
    keyMapping: {
      createdAt: {
        dateFrom: 'created_at_from',
        dateTo: 'created_at_to',
      },
      ubn: 'ubn',
      updatedDate: {
        dateFrom: 'updated_date_from',
        dateTo: 'updated_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.CERTIFICATES_IKB]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      validDate: dateRangeFilter(IntlKeys.valid),
    },
    keyMapping: {
      ubn: 'ubn',
      validDate: {
        dateFrom: 'valid_date_from',
        dateTo: 'valid_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.CERTIFICATES_WELFARE]: new SimpleFilters({
    config: {
      validDate: dateRangeFilter(IntlKeys.valid),
      ubn: strictValueFilter,
    },
    keyMapping: {
      ubn: 'ubn',
      validDate: {
        dateFrom: 'valid_date_from',
        dateTo: 'valid_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.SLAUGHTER_DETAILS]: new SimpleFilters({
    config: {
      [SlaughterDetailsSerializedHeaders.slaughterMessageId]: strictValueFilter,
    },
    keyMapping: {
      [SlaughterDetailsSerializedHeaders.slaughterMessageId]: SlaughterDetailsUnserializedHeaders.slaughterMessageId,
    },
  }),
  [FILTER_ENTITY_TYPES.SLAUGHTER_PRICE_CORRECTIONS]: new SimpleFilters({
    config: {
      date: dateRangeFilter(IntlKeys.export),
      ubn: strictValueFilter,
    },
    keyMapping: {
      date: {
        dateFrom: 'financial_result_date_time_from',
        dateTo: 'financial_result_date_time_to',
      },
      ubn: 'ubn',
    },
  }),
  [FILTER_ENTITY_TYPES.INVOICE_MESSAGES]: new SimpleFilters({
    config: {
      invoice: dateRangeFilter(IntlKeys.invoice),
      ubn: strictValueFilter,
      permission_filter: blockchainPermissionsFilter,
    },
    keyMapping: {
      invoice: {
        dateFrom: 'invoice_date_from',
        dateTo: 'invoice_date_to',
      },
      ubn: 'ubn',
      permission_filter: 'permission_filter',
    },
  }),
  [FILTER_ENTITY_TYPES.INVOICE_DETAILS]: new SimpleFilters({
    config: {
      [InvoiceDetailsSerializedHeaders.invoiceMessageId]: strictValueFilter,
    },
    keyMapping: {
      [InvoiceDetailsSerializedHeaders.invoiceMessageId]: InvoiceDetailsUnserializedHeaders.invoiceMessageId,
    },
  }),
  [FILTER_ENTITY_TYPES.SLAUGHTER_MESSAGES]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      messageDate: dateRangeFilter(IntlKeys.message),
      permission_filter: blockchainPermissionsFilter,
    },
    keyMapping: {
      ubn: 'ubn',
      messageDate: {
        dateFrom: 'message_date_from',
        dateTo: 'message_date_to',
      },
      permission_filter: 'permission_filter',
    },
  }),
  [FILTER_ENTITY_TYPES.DELIVERY_MESSAGES]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      receiptDate: dateRangeFilter(IntlKeys.receipt),
      permission_filter: blockchainPermissionsFilter,
    },
    keyMapping: {
      ubn: 'ubn',
      receiptDate: {
        dateFrom: 'receipt_date_from',
        dateTo: 'receipt_date_to',
      },
      permission_filter: 'permission_filter',
    },
  }),

  [FILTER_ENTITY_TYPES.MOVEMENT_EVENTS]: new SimpleFilters({
    config: {
      eventDateTime: dateRangeFilter(IntlKeys.event),
      ubn: strictValueFilter,
    },
    keyMapping: {
      ubn: 'ubn',
      eventDateTime: {
        dateFrom: 'event_date_time_from',
        dateTo: 'event_date_time_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.FARM_EVENTS]: new SimpleFilters({
    config: {
      updatedAt: dateRangeFilter(IntlKeys.updated),
      ubn: strictValueFilter,
    },
    keyMapping: {
      ubn: 'ubn',
      updatedAt: {
        dateFrom: 'updated_at_from',
        dateTo: 'updated_at_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.GENETICS]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      deliveryDate: dateRangeFilter(IntlKeys.delivery),
    },
    keyMapping: {
      ubn: 'ubn',
      deliveryDate: {
        dateFrom: 'delivery_date_from',
        dateTo: 'delivery_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.VETERINARIAN_DATA]: new SimpleFilters({
    config: {
      exportDate: dateRangeFilter(IntlKeys.export),
      ubn: strictValueFilter,
    },
    keyMapping: {
      ubn: 'ubn',
      exportDate: {
        dateFrom: 'export_date_from',
        dateTo: 'export_date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.ANIMAL_PASSPORTS]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      date: dateRangeFilter(IntlKeys.duration),
    },
    keyMapping: {
      ubn: 'ubn',
      date: {
        dateFrom: 'from',
        dateTo: 'to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.VRI_CALCULATED]: new SimpleFilters({
    config: {
      vriPassportId: singleValueFilter(),
      date: dateRangeFilter(
        IntlKeys.duration,
        DateInterval.DATE_RANGE,
        {
          dateFrom: moment().utc().startOf('year').add(-1, 'year').toDate(),
          dateTo: moment().utc().endOf('year').add(-1, 'year').toDate()
        }
      ),
      company_ids: singleValueFilter(),
      skip_zero: strictValueFilter,
      concept: singleValueFilter(),
    },
    keyMapping: {
      vriPassportId: 'user_passport_id',
      date: {
        dateFrom: 'date_start',
        dateTo: 'date_end',
      },
      company_ids: 'company_ids',
      skip_zero: 'skip_zero',
      concept: 'concept'
    },
  }),
  [FILTER_ENTITY_TYPES.VRI_CALCULATED_TOOL]: new SimpleFilters({
    config: {
      vriPassportId: singleValueFilter(),
      date: dateRangeFilter(
        IntlKeys.duration,
        DateInterval.DATE_RANGE,
        {
          dateFrom: moment().utc().startOf('year').add(-1, 'year').toDate(),
          dateTo: moment().utc().endOf('year').add(-1, 'year').toDate()
        }
      ),      company_ids: singleValueFilter(),
      skip_zero: strictValueFilter,
    },
    keyMapping: {
      vriPassportId: 'user_passport_id',
      date: {
        dateFrom: 'date_start',
        dateTo: 'date_end',
      },
      company_ids: 'company_ids',
      skip_zero: 'skip_zero',
    },
  }),
  [FILTER_ENTITY_TYPES.VRI_COMPARISON_TOOL]: new SimpleFilters({
    config: {
      vriComparisonPassportId: singleValueFilter(),
      firstReportDate: dateRangeFilter(
        IntlKeys.duration,
        DateInterval.DATE_RANGE,
        {
          dateFrom: moment().utc().startOf('year').add(-1, 'year').toDate(),
          dateTo: moment().utc().endOf('year').add(-1, 'year').toDate()
        }
      ),
      secondReportDate: dateRangeFilter(
        IntlKeys.duration,
        DateInterval.DATE_RANGE,
        {
          dateFrom: moment().utc().startOf('year').add(-1, 'year').toDate(),
          dateTo: moment().utc().endOf('year').add(-1, 'year').toDate()
        }
      )
    },
    keyMapping: {
      vriComparisonPassportId: 'user_passport_id',
      firstReportDate: {
        dateFrom: 'date_start',
        dateTo: 'date_end',
      },
      secondReportDate: {
        dateFrom: 'date_start',
        dateTo: 'date_end',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.TRANSPORTS]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      date: dateRangeFilter(IntlKeys.duration),
    },
    keyMapping: {
      ubn: 'location_id_value',
      date: {
        dateFrom: 'created_at_start',
        dateTo: 'created_at_end',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.FARMER_INPUT]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      companyReportId: strictValueFilter,
      updated: dateRangeFilter(IntlKeys.lastUpdated),
      date: dateRangeFilter(IntlKeys.timeRange),
      status: singleValueFilter(IntlKeys.farmerInputStatus, statusOptions),
      uploadedBy: multiValueFilter(IntlKeys.serviceUploadedBy),
    },
    keyMapping: {
      ubn: 'ubn',
      companyReportId: 'company_report_id',
      updated: {
        dateFrom: 'reporting_updated_start',
        dateTo: 'reporting_updated_end',
      },
      date: {
        dateFrom: 'reporting_date_start',
        dateTo: 'reporting_date_end',
      },
      status: 'status',
      uploadedBy: 'created_by_ids',
    },
  }),
  [FILTER_ENTITY_TYPES.COMPANY_REPORT_FILTER]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      companyReportId: strictValueFilter,
      updated: dateRangeFilter(IntlKeys.lastUpdated),
      date: multiValueFilter(IntlKeys.timeRange, {
        options: generateOptions({
          startYear: '2030',
          type: 'halfYear',
          onlyYearsRange: {
            rangeStartYear: '2019',
            rangeEndYear: '2023'
          }
        }).map(o => ({ label: o.label, value: `${o.value.dateFrom} - ${o.value.dateTo}`})),
        icon: DateRangeIcon
      }),
      status: singleValueFilter(IntlKeys.farmerInputStatus, statusOptions),
      uploadedBy: multiValueFilter(IntlKeys.serviceUploadedBy),
    },
    keyMapping: {
      ubn: 'ubn',
      companyReportId: 'id',
      updated: {
        dateFrom: 'reporting_updated_start',
        dateTo: 'reporting_updated_end',
      },
      date: 'reporting_date',
      status: 'status',
      uploadedBy: 'created_by_ids',
    },
  }),
  [FILTER_ENTITY_TYPES.REFERENCE_TABLES]: new SimpleFilters({
    config: {
      uploadDate: dateRangeFilter(IntlKeys.referenceTablesUploadDate),
      validDate: dateRangeFilter(IntlKeys.referenceTablesValidDate),
      status: singleValueFilter(IntlKeys.referenceTablesStatus, uploadStatusOptions),
      category: singleValueFilter(IntlKeys.referenceTablesCategory, uploadCategoryOptions),
    },
    keyMapping: {
      uploadDate: {
        dateFrom: 'created_at_start',
        dateTo: 'created_at_end',
      },
      validDate: {
        dateFrom: 'valid_from',
        dateTo: 'valid_to',
      },
      status: 'status',
      category: 'upload_type',
    },
  }),
  [FILTER_ENTITY_TYPES.FARM_MANAGEMENT_CATEGORIES]: new SimpleFilters({
    config: {
      companyID: multiValueFilter(IntlKeys.farmManagementDataCompanyId, { options: [] }),
      timestamp: dateRangeFilter(IntlKeys.farmManagementDataTimestamp),
    },
    keyMapping: {
      companyID: 'company_identifiers',
      timestamp: {
        dateFrom: 'created_at_start',
        dateTo: 'created_at_end',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.FARM_MANAGEMENT_DATA]: new SimpleFilters({
    config: {
      createdAt: dateRangeFilter(IntlKeys.farmManagementDataCreatedAt),
      category: singleValueFilter(IntlKeys.farmManagementDataCategory, farmManagementCategoryOptions),
      // companies: multiValueFilter(IntlKeys.tableBodyEmptyDataSourceMessage),
    },
    keyMapping: {
      createdAt: {
        dateFrom: 'created_at_start',
        dateTo: 'created_at_end',
      },
      category: 'type',
      // companies: 'company_identifiers',
    },
  }),
  [FILTER_ENTITY_TYPES.CARBON_FOOTPRINT]: new SimpleFilters({
    config: {
      co2Ubn: singleValueFilter(IntlKeys.ubn),
      validDate:  multiValueFilter(IntlKeys.validDate, {
        options: generateOptions({
          startYear: '2030',
          type: 'halfYear',
          onlyYearsRange: {
            rangeStartYear: '2019',
            rangeEndYear: '2023'
          }
        }).map(o => {
          return { label: o.label, value: `${o.value.dateFrom} - ${o.value.dateTo}`};
        }),
        icon: DateRangeIcon
      }),
      uploadDate: dateRangeFilter(IntlKeys.uploadDate),
      status: singleValueFilter(IntlKeys.status, uploadStatusOptions),
      co2UploadedBy: singleValueFilter(IntlKeys.uploadedBy, undefined, [PERMISSIONS.LEVEL1]),
    },
    keyMapping: {
      co2Ubn: 'ubn',
      validDate: 'valid_date',
      uploadDate: {
        dateFrom: 'created_at_start',
        dateTo: 'created_at_end',
      },
      status: 'status',
      co2UploadedBy: 'uploaded_by_id',
    },
  }),
  [FILTER_ENTITY_TYPES.MONITORING_DASHBOARD]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      vionRelNo: strictValueFilter,
      uploadDate: dateRangeFilter(IntlKeys.uploadDate),
      labels: multiValueFilter(IntlKeys.titleLabels, { customTags: true }),
      concepts: multiValueFilter(IntlKeys.concepts),
    },
    keyMapping: {
      ubn: 'ubn',
      vionRelNo: 'relation_number',
      uploadDate: {
        dateFrom: 'created_at_start',
        dateTo: 'created_at_end',
      },
      labels: 'label_ids',
      concepts: 'concepts',
    },
  }),
  [FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_STATISTIC]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      uploadDate: dateRangeFilter(IntlKeys.requestDate),
    },
    keyMapping: {
      ubn: 'ubn',
      uploadDate: {
        dateFrom: 'requested_at_start',
        dateTo: 'requested_at_end',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.MONITORING_DASHBOARD_DATA]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      uploadDate: dateRangeFilter(IntlKeys.date),
    },
    keyMapping: {
      ubn: 'ubn',
      uploadDate: {
        dateFrom: 'date_from',
        dateTo: 'date_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.RAW_MATERIALS_ORIGIN]: new SimpleFilters({
    config: {
      ubn: strictValueFilter,
      uploadDate: dateRangeFilter(IntlKeys.uploadDate),
      timeRange: dateRangeFilter(IntlKeys.timeRange),
    },
    keyMapping: {
      ubn: 'ubn',
      uploadDate: {
        dateFrom: 'created_at_start',
        dateTo: 'created_at_end',
      },
      timeRange: {
        dateFrom: 'beginning_time',
        dateTo: 'end_time',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.HFM_OUTPUT]: new SimpleFilters({
    config: {
      validDate: dateRangeFilter(IntlKeys.validDate),
    },
    keyMapping: {
      validDate: {
        dateFrom: 'uploaded_at_from',
        dateTo: 'uploaded_at_to',
      },
    },
  }),
  [FILTER_ENTITY_TYPES.ERRORS_LOG]: new SimpleFilters({
    config: {
      type: singleValueFilter(IntlKeys.errorsLogErrorLogType, errorLogTypeOptions),
      logDatetime: dateRangeFilter(IntlKeys.errorsLogLogDatetime),
    },
    keyMapping: {
      type: 'error_log_type',
      logDatetime: {
        dateFrom: 'log_datetime_start',
        dateTo: 'log_datetime_end',
      },
    },
  }),
};

export default filterConfigs;

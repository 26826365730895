import React, { useCallback, useMemo } from 'react';
import { Toolbar, SwipeableDrawer, SwipeableDrawerProps } from '@material-ui/core';

import styled from 'styled-components';

interface OwnProps extends Omit<SwipeableDrawerProps, 'onClose' | 'onOpen' | 'open'> {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  children?: React.ReactNode;
}

export const StyledMaterialDrawer = styled(SwipeableDrawer)`
  & [class*='MuiDrawer-paper'] {
    min-width: 300px;
    width: 360px;
  }
`;

export const SideBarContext = React.createContext({
  closeMenu: () => {
    /* */
  },
});

const SideBar: React.FC<OwnProps> = ({
  //
  anchor = 'left',
  isOpen,
  setIsOpen,
  children,
  ...drawerProps
}) => {
  const toggleDrawer = (value = !isOpen) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(value);
  };

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const contextValue = useMemo(
    () => ({
      closeMenu,
    }),
    [closeMenu],
  );

  return (
    <StyledMaterialDrawer
      anchor={anchor}
      open={isOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      {...drawerProps}
    >
      <Toolbar />
      <SideBarContext.Provider value={contextValue}>{children}</SideBarContext.Provider>
    </StyledMaterialDrawer>
  );
};

export type { OwnProps as SideBarProps };
export default React.memo(SideBar);

import { CONTENT_OF_NITRIC_OXIDE_FIELDS } from 'lib/excel/config/referenceTables/contentOfNitricOxide/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = CONTENT_OF_NITRIC_OXIDE_FIELDS.ContentOfNitricOxide;

export const getcontentOfNitricOxideDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.livestockCategory.original),
      new CellValidator(columns.manureType.original),
      new CellValidator(columns.valueN2oAndNo.original),
      new CellValidator(columns.unitN2oAndNo.original),
      new CellValidator(columns.valueN2.original),
      new CellValidator(columns.unitN2.original),
    ]),
  ]);
};

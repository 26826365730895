export enum COLORS {
  blue = '#123274',
  purple = '#602583',
  pink = '#F50057',
  green = '#4caf50',
  orange = '#ff9800',
  red = '#f44336',
  dimGray = '#666666',
  whiteSmoke = '#f2f2f2',
  lime = '#99C31C',
  spindle = '#bfc7de',
  shadyLady = '#969696',
}

export const colorsThemes = {
  blue: {
    primary: {
      main: COLORS.blue,
    },
    secondary: {
      main: COLORS.pink,
    },
    success: {
      main: COLORS.green,
    },
    warning: {
      main: COLORS.orange,
    },
    error: {
      main: COLORS.red,
    },
  },
  purple: {
    primary: {
      main: COLORS.purple,
    },
    secondary: {
      main: COLORS.pink,
    },
    success: {
      main: COLORS.green,
    },
    warning: {
      main: COLORS.orange,
    },
    error: {
      main: COLORS.red,
    },
  },
};

import { YES_NO_FIELDS } from 'lib/excel/config/referenceTables/yesNo/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundToInteger } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = YES_NO_FIELDS.YesNo;

export const getYesNoView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.yesNoCode.serialized,
        viewPropName: columns.yesNoCode.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseInt, roundToInteger),
      }),
      new CellViewer({ serializedPropName: columns.yesNoName.serialized, viewPropName: columns.yesNoName.view }),
    ]),
  ]);
};

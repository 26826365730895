import moment from 'moment';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

const numericSort = (a: number, b: number) => (a || 0) - (b || 0);

const dateSort = (a: any, b: any) => {
  const date1 = moment(a);
  const date2 = moment(b);

  const ms1 = isNaN(date1.valueOf()) ? 0 : date1.valueOf();
  const ms2 = isNaN(date2.valueOf()) ? 0 : date2.valueOf();

  return ms1 - ms2;
};

const unknownSort = (a: any, b: any) => {
  return `${a}`.localeCompare(b);
};

export const algorithms = {
  numeric: numericSort,
  date: dateSort,
  unknown: unknownSort,
};

export const customSortAlgorithmFactory = (fieldName: string, algorithmType: keyof typeof algorithms) => {
  return (row1: ObjectWithProps, row2: ObjectWithProps) => {
    const value1 = row1[fieldName];
    const value2 = row2[fieldName];

    return algorithms[algorithmType](value1, value2);
  };
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum PensTypeSowBarnOriginal {
  PensTypeSowBarn = 'AS_PensTypeSowBarn',
}

enum PensTypeSowBarnSerialized {
  PensTypeSowBarn = 'PensTypeSowBarn',
}

enum PensTypeSowBarnUnserialized {
  PensTypeSowBarn = 'PensTypeSowBarn',
}

enum PensTypeSowBarnOriginalHeaders {
  'pensTypeSowBarnCode' = 'pensTypeSowBarnCode',
  'pensTypeSowBarnName' = 'pensTypeSowBarnName',
}

enum PensTypeSowBarnSerializedHeaders {
  'pensTypeSowBarnCode' = 'pensTypeSowBarnCode',
  'pensTypeSowBarnName' = 'pensTypeSowBarnName',
}

enum PensTypeSowBarnUnserializedHeaders {
  'pensTypeSowBarnCode' = 'pen_type_sow_barn',
  'pensTypeSowBarnName' = 'pen_barn_name',
}

export const PENS_TYPE_SOW_BARN_FIELDS = {
  [PensTypeSowBarnSerialized.PensTypeSowBarn]: {
    original: PensTypeSowBarnOriginal.PensTypeSowBarn,
    serialized: PensTypeSowBarnSerialized.PensTypeSowBarn,
    unserialized: PensTypeSowBarnUnserialized.PensTypeSowBarn,
    view: IntlKeys.referenceTablesAsPensTypeSowBarn,
    viewName: 'AS_PensTypeSowBarn',

    columns: {
      [PensTypeSowBarnSerializedHeaders.pensTypeSowBarnCode]: {
        original: PensTypeSowBarnOriginalHeaders.pensTypeSowBarnCode,
        serialized: PensTypeSowBarnSerializedHeaders.pensTypeSowBarnCode,
        unserialized: PensTypeSowBarnUnserializedHeaders.pensTypeSowBarnCode,
        view: IntlKeys.referenceTablesPensSowBarnCode,
      },
      [PensTypeSowBarnSerializedHeaders.pensTypeSowBarnName]: {
        original: PensTypeSowBarnOriginalHeaders.pensTypeSowBarnName,
        serialized: PensTypeSowBarnSerializedHeaders.pensTypeSowBarnName,
        unserialized: PensTypeSowBarnUnserializedHeaders.pensTypeSowBarnName,
        view: IntlKeys.referenceTablesPensSowBarnName,
      },
    },
  },
};

import { YES_NO_FIELDS } from 'lib/excel/config/referenceTables/yesNo/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = YES_NO_FIELDS.YesNo;

export const getYesNoDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.yesNoCode.serialized, extractPropName: columns.yesNoCode.view }),
      new DisplayHeader({ headerName: columns.yesNoName.serialized, extractPropName: columns.yesNoName.view }),
    ]),
  ]);
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum SuppliersNumbersOriginal {
  SuppliersNumbers = 'cl420',
}

enum SuppliersNumbersSerialized {
  SuppliersNumbers = 'SuppliersNumbers',
}

enum SuppliersNumbersUnserialized {
  SuppliersNumbers = 'SuppliersNumbers',
}

enum SuppliersNumbersOriginalHeaders {
  'codeList' = 'Code List',
  'supplierNumber' = 'Supplier Code',
  'feedSupplierName' = 'Supplier Name',
  'entryDate' = 'Date From',
  'dischargeDate' = 'Date To',
  'remark' = 'Remarks',
}

enum SuppliersNumbersSerializedHeaders {
  'codeList' = 'codeList',
  'supplierNumber' = 'supplierNumber',
  'feedSupplierName' = 'feedSupplierName',
  'entryDate' = 'entryDate',
  'dischargeDate' = 'dischargeDate',
  'remark' = 'remark',
}
enum SuppliersNumbersUnserializedHeaders {
  'codeList' = 'code_list',
  'supplierNumber' = 'supplier_code',
  'feedSupplierName' = 'supplier_name',
  'entryDate' = 'date_from',
  'dischargeDate' = 'date_to',
  'remark' = 'remark',
}

export const SUPPLIERS_NUMBERS_FIELDS = {
  [SuppliersNumbersUnserialized.SuppliersNumbers]: {
    original: SuppliersNumbersOriginal.SuppliersNumbers,
    serialized: SuppliersNumbersSerialized.SuppliersNumbers,
    unserialized: SuppliersNumbersUnserialized.SuppliersNumbers,
    view: IntlKeys.referenceTablesSuppliersNumbers,
    viewName: 'cl420',

    columns: {
      [SuppliersNumbersSerializedHeaders.codeList]: {
        original: SuppliersNumbersOriginalHeaders.codeList,
        serialized: SuppliersNumbersSerializedHeaders.codeList,
        unserialized: SuppliersNumbersUnserializedHeaders.codeList,
        view: IntlKeys.referenceTablesSuppliersNumbersCodeList,
      },
      [SuppliersNumbersSerializedHeaders.supplierNumber]: {
        original: SuppliersNumbersOriginalHeaders.supplierNumber,
        serialized: SuppliersNumbersSerializedHeaders.supplierNumber,
        unserialized: SuppliersNumbersUnserializedHeaders.supplierNumber,
        view: IntlKeys.referenceTablesSuppliersNumbersSupplierNumber,
      },
      [SuppliersNumbersSerializedHeaders.feedSupplierName]: {
        original: SuppliersNumbersOriginalHeaders.feedSupplierName,
        serialized: SuppliersNumbersSerializedHeaders.feedSupplierName,
        unserialized: SuppliersNumbersUnserializedHeaders.feedSupplierName,
        view: IntlKeys.referenceTablesSuppliersNumbersFeedSupplierName,
      },
      [SuppliersNumbersSerializedHeaders.entryDate]: {
        original: SuppliersNumbersOriginalHeaders.entryDate,
        serialized: SuppliersNumbersSerializedHeaders.entryDate,
        unserialized: SuppliersNumbersUnserializedHeaders.entryDate,
        view: IntlKeys.referenceTablesSuppliersNumbersEntryDate,
      },
      [SuppliersNumbersSerializedHeaders.dischargeDate]: {
        original: SuppliersNumbersOriginalHeaders.dischargeDate,
        serialized: SuppliersNumbersSerializedHeaders.dischargeDate,
        unserialized: SuppliersNumbersUnserializedHeaders.dischargeDate,
        view: IntlKeys.referenceTablesSuppliersNumbersDischargeDate,
      },
      [SuppliersNumbersSerializedHeaders.remark]: {
        original: SuppliersNumbersOriginalHeaders.remark,
        serialized: SuppliersNumbersSerializedHeaders.remark,
        unserialized: SuppliersNumbersUnserializedHeaders.remark,
        view: IntlKeys.referenceTablesSuppliersNumbersRemark,
      },
    },
  },
};

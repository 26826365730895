export enum DocumentTypes {
  'certificatesDaily' = 'certificatesDaily',
  'certificatesWeekly' = 'certificatesWeekly',
  'certificatesHistory' = 'certificatesHistory',
  'VRIDatabase' = 'VRIDatabase',
  'referenceTables' = 'referenceTables',
  'carbonFootprint' = 'carbonFootprint',
  'hfmOutput' = 'hfmOutput',
}

export const backendKeyMap = {
  [DocumentTypes.certificatesDaily]: 'daily',
  [DocumentTypes.certificatesWeekly]: 'weekly',
  [DocumentTypes.certificatesHistory]: 'history',
  [DocumentTypes.VRIDatabase]: 'reports',
  [DocumentTypes.referenceTables]: 'referenceTables',
  [DocumentTypes.carbonFootprint]: 'carbonFootprint',
  [DocumentTypes.hfmOutput]: 'hfmOutput',
};

export type UploadEntities = keyof typeof DocumentTypes;

export interface FileInfo {
  checksum: string;
  created_at: string;
  file: {
    url: string;
  };
  id: number;
  is_edited: boolean;
  source: string;
  status: string;
  status_message: string | null;
  upload_token: string;
  upload_type: string;
  uploaded_at: string | null; // null?
  user: {
    email: string;
    name: string;
  };
}

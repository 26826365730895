import { IntlKeys } from 'lib/localization/keys';

enum UpdatesLogSerialized {
  UpdatesLog = 'UpdatesLog',
}

enum UpdatesLogUnserialized {
  UpdatesLog = 'UpdatesLog',
}

export enum UpdatesLogSerializedHeaders {
  id = 'id',
  loggableId = 'loggableId', // used for define deleted report
  reportId = 'reportId',
  reportType = 'reportType',
  idType = 'idType',
  idCode = 'idCode',
  action = 'action',
  actionDate = 'actionDate',
  triggeredBy = 'triggeredBy',
}

export const UpdatesLogUnserializedHeaders = {
  id: 'id',
  loggableId: 'loggable_id', // used for define deleted report
  reportId: ['event_data', 'loggable_id'],
  reportType: ['event_data', 'loggable_type'],
  idType: ['event_data', 'id_type'],
  idCode: ['event_data', 'id_value'],
  action: 'event_type',
  actionDate: 'done_at',
  triggeredBy: ['done_by', 'full_name'],
} as const;

export const UPDATES_LOG = {
  [UpdatesLogSerialized.UpdatesLog]: {
    serialized: UpdatesLogSerialized.UpdatesLog,
    unserialized: UpdatesLogUnserialized.UpdatesLog,
    view: IntlKeys.titleFarmUpdates,

    columns: {
      [UpdatesLogSerializedHeaders.reportId]: {
        serialized: UpdatesLogSerializedHeaders.reportId,
        unserialized: UpdatesLogUnserializedHeaders.reportId,
        view: IntlKeys.farmerInputSystemId,
      },
      [UpdatesLogSerializedHeaders.reportType]: {
        serialized: UpdatesLogSerializedHeaders.reportType,
        unserialized: UpdatesLogUnserializedHeaders.reportType,
        view: IntlKeys.farmerInputReportType,
      },
      [UpdatesLogSerializedHeaders.idType]: {
        serialized: UpdatesLogSerializedHeaders.idType,
        unserialized: UpdatesLogUnserializedHeaders.idType,
        view: IntlKeys.farmerInputIdType,
      },
      [UpdatesLogSerializedHeaders.idCode]: {
        serialized: UpdatesLogSerializedHeaders.idCode,
        unserialized: UpdatesLogUnserializedHeaders.idCode,
        view: IntlKeys.farmerInputIdCode,
      },
      [UpdatesLogSerializedHeaders.action]: {
        serialized: UpdatesLogSerializedHeaders.action,
        unserialized: UpdatesLogUnserializedHeaders.action,
        view: IntlKeys.farmerInputAction,
      },
      [UpdatesLogSerializedHeaders.actionDate]: {
        serialized: UpdatesLogSerializedHeaders.actionDate,
        unserialized: UpdatesLogUnserializedHeaders.actionDate,
        view: IntlKeys.farmerInputActionDate,
      },
      [UpdatesLogSerializedHeaders.triggeredBy]: {
        serialized: UpdatesLogSerializedHeaders.triggeredBy,
        unserialized: UpdatesLogUnserializedHeaders.triggeredBy,
        view: IntlKeys.farmerInputTriggeredBy,
      },
      [UpdatesLogSerializedHeaders.loggableId]: {
        serialized: UpdatesLogSerializedHeaders.loggableId,
        unserialized: UpdatesLogUnserializedHeaders.loggableId,
      },
    },
  },
};

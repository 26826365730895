import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { IntlKeys } from 'lib/localization/keys';

import { setMaintenanceAction } from 'store/auth/reducer';

import Modal from 'components/modal';

import { makeStyles } from '@material-ui/core/styles';
import { MaterialTheme } from 'theme';
import backSvg from './background.svg';

interface OwnProps {}
const MaintenanceModal: React.FC<OwnProps> = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  //
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(setMaintenanceAction(false));
  }, [dispatch]);

  return (
    <Modal
      isOpen={true}
      closeModal={closeModal}
      classes={{ wrapper: classes.modalWrapper, content: classes.modalContent }}
    >
      <div className={classes.texts}>
        <h3>{formatMessage({ id: IntlKeys.serverMaintenance })}</h3>
        <p className={classes.descr}>{formatMessage({ id: IntlKeys.maintenanceDescr })}</p>
        <p className={classes.tryAgain}>{formatMessage({ id: IntlKeys.maintenanceTryAgain })}</p>
      </div>

      <div className={classes.bgContainer} />
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  //
  modalWrapper: {
    overflow: 'hidden',
    margin: 5,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  texts: {
    margin: '35px 0',
    padding: '0 20px',
  },

  descr: {
    color: 'rgba(0,0,0,0.6)',
  },

  tryAgain: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#5D8C00',
  },

  bgContainer: {
    height: 200,
    background: `url(${backSvg})`,
    backgroundPositionX: 'center',
  },
}));

export default React.memo(MaintenanceModal);

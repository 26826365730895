import React from 'react';
import { useIntl } from 'react-intl';

import { IntlKeys } from 'lib/localization/keys';

import { SlaughterDataTypeSerialized } from 'store/entities/animalPassports';

import DataContainer, { DataType } from '../components/DataContainer';
import GridContainer from '../components/GridContainer';
import { useAnimalTypeMapper, useCarcassCodeMapper, useOrganCodeMapper } from './mappingUtils';

interface OwnProps {
  data: Record<string, SlaughterDataTypeSerialized>;
}

const Slaughter: React.FC<OwnProps> = ({ data }) => {
  const { formatMessage } = useIntl();

  const animalTypeMapper = useAnimalTypeMapper();
  const carcassCodeTypeMapper = useCarcassCodeMapper();
  const organCodeTypeMapper = useOrganCodeMapper();

  return (
    <div>
      {Object.entries(data).map(
        ([animalType, { animalCount, avgAnimalWeight, groupedByCarcassCode, groupedByOrganCode }]) => (
          <GridContainer key={animalType}>
            <DataContainer
              label={formatMessage({ id: IntlKeys.animalPassportSlaughterAnimalType })}
              data={animalTypeMapper(animalType)}
            />

            <div>
              <DataContainer
                label={formatMessage({ id: IntlKeys.animalPassportSlaughterAnimalCount })}
                data={animalCount}
                dataType={DataType.Number}
              />

              <DataContainer
                label={formatMessage({ id: IntlKeys.animalPassportAverageWeightKg })}
                data={avgAnimalWeight}
                dataType={DataType.Number}
              />

              {Object.entries(groupedByCarcassCode).map(([carcassCode, totalCount]) => (
                <DataContainer
                  key={carcassCode}
                  label={formatMessage(
                    {
                      id: IntlKeys.animalPassportSlaughterCarcassCodeTotal,
                    },
                    { carcassCode: carcassCodeTypeMapper(carcassCode) },
                  )}
                  data={totalCount}
                  dataType={DataType.Number}
                />
              ))}

              {Object.entries(groupedByOrganCode).map(([organCode, totalCount]) => (
                <DataContainer
                  key={organCode}
                  label={formatMessage(
                    {
                      id: IntlKeys.animalPassportSlaughterOrganCodeTotal,
                    },
                    { organCode: organCodeTypeMapper(organCode) },
                  )}
                  data={totalCount}
                  dataType={DataType.Number}
                />
              ))}
            </div>
          </GridContainer>
        ),
      )}
    </div>
  );
};

export default React.memo(Slaughter);

import { PEN_LEVEL_FIELDS } from '../structure';
import { DisplayDocument } from '../../../../display/DisplayDocument';
import { DisplayTab } from '../../../../display/DisplayTab';
import { DisplayHeader } from '../../../../display/DisplayHeader';

const { columns, view } = PEN_LEVEL_FIELDS.PenLevel;

export const getPenLevelDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.penLevel.serialized, extractPropName: columns.penLevel.view }),
      new DisplayHeader({ headerName: columns.penNameNL.serialized, extractPropName: columns.penNameNL.view }),
      new DisplayHeader({ headerName: columns.penNameEN.serialized, extractPropName: columns.penNameEN.view }),
    ]),
  ]);
};

import React from 'react';
import { RouteProps, RouteComponentProps } from 'react-router';

import routes from './routes';
import { PermissionsEntry } from 'store/auth/permissions';
import { FILTER_ENTITY_TYPES } from 'store/filters/reducer';

export interface RouteType extends RouteProps {
  path: string;
  key: string;
  breadcrumbsLabel?: string;
  docTitle?: string | ((props: RouteComponentProps) => string);
  withBreadcrumbs?: boolean;
  component: React.ComponentType;
  routes?: RouteType[];
  restrictions?: PermissionsEntry;
  filterType?: FILTER_ENTITY_TYPES;
  isDefault?: boolean;
  endpoint?: string | ((params: Record<string, string>) => string);
  predefinedParams?: object | ((params: Record<string, string>) => object);
}

export interface ChildRouteType extends RouteType {
  parent?: RouteType;
  routes?: ChildRouteType[];
}

export * from './routes';
export * from './utils';
export * from './injectCurrentRouteTracking';

export default routes;

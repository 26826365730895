import { IntlKeys } from 'lib/localization/keys';

enum CodesRemovalTypeSerialized {
  CodesRemovalType = 'CodesRemovalType',
}

enum CodesRemovalTypeUnserialized {
  CodesRemovalType = 'CodesRemovalType',
}

export enum CodesRemovalTypeSerializedHeaders {
  id = 'id',
  removalTypeId = 'removalTypeId',
  note = 'note',
  removalTypeDatetime = 'removalTypeDatetime',
}

export const CodesRemovalTypeUnserializedHeaders = {
  id: 'id',
  removalTypeId: 'removal_type_identifier',
  note: 'note',
  removalTypeDatetime: 'removal_type_datetime',
} as const;

export const CODES_REMOVAL_TYPE = {
  [CodesRemovalTypeSerialized.CodesRemovalType]: {
    serialized: CodesRemovalTypeSerialized.CodesRemovalType,
    unserialized: CodesRemovalTypeUnserialized.CodesRemovalType,
    view: IntlKeys.farmManagementDataRemovalList,

    columns: {
      [CodesRemovalTypeSerializedHeaders.id]: {
        serialized: CodesRemovalTypeSerializedHeaders.id,
        unserialized: CodesRemovalTypeUnserializedHeaders.id,
      },
      [CodesRemovalTypeSerializedHeaders.removalTypeId]: {
        serialized: CodesRemovalTypeSerializedHeaders.removalTypeId,
        unserialized: CodesRemovalTypeUnserializedHeaders.removalTypeId,
        view: IntlKeys.farmManagementDataRemovalTypeId,
      },
      [CodesRemovalTypeSerializedHeaders.note]: {
        serialized: CodesRemovalTypeSerializedHeaders.note,
        unserialized: CodesRemovalTypeUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesRemovalTypeSerializedHeaders.removalTypeDatetime]: {
        serialized: CodesRemovalTypeSerializedHeaders.removalTypeDatetime,
        unserialized: CodesRemovalTypeUnserializedHeaders.removalTypeDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
    },
  },
};

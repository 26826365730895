import { CL649_FIELDS } from 'lib/excel/config/referenceTables/cl649/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundToInteger } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = CL649_FIELDS.Cl649;

export const getCl649View = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.code.serialized,
        viewPropName: columns.code.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseInt, roundToInteger),
      }),
      new CellViewer({ serializedPropName: columns.description.serialized, viewPropName: columns.description.view }),
      new CellViewer({ serializedPropName: columns.remarks.serialized, viewPropName: columns.remarks.view }),
    ]),
  ]);
};

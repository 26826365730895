import { GAS_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/gasConsumptionRates/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = GAS_CONSUMPTION_RATES_FIELDS.GasConsumptionRates;

export const serializeGasConsumptionRatesData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.houseType.original, columns.houseType.serialized),
      new CellSerializer(columns.gasConsumption.original, columns.gasConsumption.serialized),
    ]),
  ]);
};

import { TypedObjectKeys } from '../view/Document';
import { ViewDocumentData } from './DisplayTab';

type ValidationFunction = (
  dataRow: TypedObjectKeys<Array<TypedObjectKeys<any>>>,
  sheetData: Array<TypedObjectKeys<any>>,
  rowData: TypedObjectKeys<any>,
  extractPropName: string,
) => any;

const validationFunctionStub = (
  dataRow: TypedObjectKeys<Array<TypedObjectKeys<any>>>,
  sheetData: Array<TypedObjectKeys<any>>,
  rowData: TypedObjectKeys<any>,
  extractPropName: string,
) => {
  return rowData[extractPropName].value;
};

type ConversionFunction = (
  dataRow: TypedObjectKeys<Array<TypedObjectKeys<any>>>,
  sheetData: Array<TypedObjectKeys<any>>,
  rowData: TypedObjectKeys<any>,
  extractPropName: string,
) => any;

const conversionFunctionStub = (
  dataRow: TypedObjectKeys<Array<TypedObjectKeys<any>>>,
  sheetData: Array<TypedObjectKeys<any>>,
  rowData: TypedObjectKeys<any>,
  extractPropName: string,
) => {
  return rowData[extractPropName].value;
};

export interface TableProps {
  disablePadding: boolean;
  numeric: boolean;
}

const defaultTableProps = {
  disablePadding: true,
  numeric: false,
};

interface DisplayHeaderSettings {
  readonly headerName: string;
  readonly extractPropName: string;
  readonly validationFunction?: ValidationFunction;
  readonly conversionFunction?: ConversionFunction;
  readonly tableProps?: TableProps;
}

export class DisplayHeader implements DisplayHeaderSettings {
  readonly headerName: string;
  readonly extractPropName: string;
  readonly validationFunction: ValidationFunction;
  readonly conversionFunction: ConversionFunction;
  readonly tableProps: TableProps;
  //   constructor OLD CONSTRUCTOR
  //     readonly headerName: string,
  //     readonly extractPropName: string,
  //     readonly validationFunction: ValidationFunction = validationFunctionStub,
  //     readonly conversionFunction: ConversionFunction = conversionFunctionStub,
  //     readonly tableProps: TableProps = defaultTableProps,
  //   ) {}
  constructor({
    conversionFunction = conversionFunctionStub,
    extractPropName,
    headerName,
    tableProps = defaultTableProps,
    validationFunction = validationFunctionStub,
  }: DisplayHeaderSettings) {
    this.headerName = headerName;
    this.extractPropName = extractPropName;
    this.validationFunction = validationFunction;
    this.conversionFunction = conversionFunction;
    this.tableProps = tableProps;
  }

  public getValue = (data: ViewDocumentData, tabData: Array<TypedObjectKeys<any>>, dataRow: TypedObjectKeys<any>) => {
    return this.conversionFunction(data, tabData, dataRow, this.extractPropName);
  };
}

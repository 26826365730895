import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import rowContextMenu from './rowContextMenu';

import { CODES_ACCOUNT } from 'store/entities/farmManagementData/codesAccount/config';

const { columns, view, serialized } = CODES_ACCOUNT.CodesAccount;

export const codesAccountTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.accountIdentifier.view,
          field: columns.accountIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.code.view,
          field: columns.code.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.abbreviation.view,
          field: columns.abbreviation.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.description.view,
          field: columns.description.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.contactIdentifier.view,
          field: columns.contactIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentSows.view,
          field: columns.percentSows.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentWeaners.view,
          field: columns.percentWeaners.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentFinishers.view,
          field: columns.percentFinishers.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentReplGilts.view,
          field: columns.percentReplGilts.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.typeIdentifier.view,
          field: columns.typeIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.active.view,
          field: columns.active.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.accountDatetime.view,
          field: columns.accountDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.evType.view,
          field: columns.evType.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.vatIdentifier.view,
          field: columns.vatIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.barn.view,
          field: columns.barn.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicine.view,
          field: columns.medicine.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.inoculation.view,
          field: columns.inoculation.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.hormone.view,
          field: columns.hormone.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineEanCode.view,
          field: columns.medicineEanCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineCountryCode.view,
          field: columns.medicineCountryCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineOfficialName.view,
          field: columns.medicineOfficialName.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineDateEnd.view,
          field: columns.medicineDateEnd.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineUnit.view,
          field: columns.medicineUnit.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ev.view,
          field: columns.ev.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineWhPeriod.view,
          field: columns.medicineWhPeriod.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineDosage.view,
          field: columns.medicineDosage.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineDosageFactor.view,
          field: columns.medicineDosageFactor.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.supplierIdentifier.view,
          field: columns.supplierIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.medicineEmbCode.view,
          field: columns.medicineEmbCode.serialized,
        },
      }),
    ],
  }),
]);

import { CVB_TABLE_FIELDS } from '../structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = CVB_TABLE_FIELDS.CvbTable;

export const serializeCvbTableData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.productCode.original, columns.productCode.serialized),
      new CellSerializer(columns.productSubCode.original, columns.productSubCode.serialized),
      new CellSerializer(columns.productType.original, columns.productType.serialized),
      new CellSerializer(columns.productNameNL.original, columns.productNameNL.serialized),
      new CellSerializer(columns.classNameNL.original, columns.classNameNL.serialized),
      new CellSerializer(columns.subClassNameNL.original, columns.subClassNameNL.serialized),
      new CellSerializer(columns.productNameEN.original, columns.productNameEN.serialized),
      new CellSerializer(columns.classNameEN.original, columns.classNameEN.serialized),
      new CellSerializer(columns.subClassNameEN.original, columns.subClassNameEN.serialized),
      new CellSerializer(columns.ds.original, columns.ds.serialized),
      new CellSerializer(columns.dsUnit.original, columns.dsUnit.serialized),
      new CellSerializer(columns.ras.original, columns.ras.serialized),
      new CellSerializer(columns.rasUnit.original, columns.rasUnit.serialized),
      new CellSerializer(columns.rein.original, columns.rein.serialized),
      new CellSerializer(columns.reinUnit.original, columns.reinUnit.serialized),
      new CellSerializer(columns.re.original, columns.re.serialized),
      new CellSerializer(columns.reUnit.original, columns.reUnit.serialized),
      new CellSerializer(columns.cVcRe.original, columns.cVcRe.serialized),
      new CellSerializer(columns.cVcReUnit.original, columns.cVcReUnit.serialized),
      new CellSerializer(columns.cVcOs.original, columns.cVcOs.serialized),
      new CellSerializer(columns.cVcOsUnit.original, columns.cVcOsUnit.serialized),
    ]),
  ]);
};

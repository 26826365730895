import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { EntitiesListPayload, EntitiesListSerialized } from '../referenceTables/entitiesList';
import {
  GreenhouseGasEmissionPayload,
  GreenhouseGasEmissionSerialized,
} from '../referenceTables/greenhouseGasEmission';

import { HFM_OUTPUT, HFM_OUTPUT_SUBMIT } from './config';

export type HfmOutputPayload = {
  id: number;
  year: string;
  period: string;
  quarter: string;
  year_to_date: string;
  entity_external_id: string;
  account_hfm: string;
  extra_external_id: string;
  consumption: number;
  co2_equivalent: number | null;
  file_name: string;
  uploaded_at: string;
  uploaded_by: {
    full_name: string;
  };
  entities_list: Pick<EntitiesListPayload, 'entity_id' | 'entity_name' | 'country' | 'business_unit'> | null;
  greenhouse_gas_emission: Pick<
    GreenhouseGasEmissionPayload,
    'country' | 'category' | 'account' | 'name' | 'emission_factor' | 'unit' | 'conversion_factor' | 'formula_cf'
  > | null;
};

export type HfmOutputSerialized = {
  id: number;
  year: string;
  period: string;
  quarter: string;
  yearToDate: string;
  entityExternalId: string;
  accountHfm: string;
  extraExternalId: string;
  consumption: number;
  co2Equivalent: number | null;
  fileName: string;
  uploadedAt: string;
  uploadedBy: string;
  entitiesList: Pick<EntitiesListSerialized, 'entityId' | 'entityName' | 'country' | 'businessUnit'> | null;
  greenhouseGasEmission: Pick<
    GreenhouseGasEmissionSerialized,
    'country' | 'category' | 'account' | 'name' | 'emissionFactor' | 'unit' | 'conversionFactor' | 'formulaCf'
  > | null;
};

export type HfmOutputSubmitPayload = Pick<
  HfmOutputPayload,
  'year' | 'period' | 'year_to_date' | 'entity_external_id' | 'account_hfm' | 'extra_external_id' | 'consumption'
>;
export type HfmOutputSubmitSerialized = Pick<
  HfmOutputSerialized,
  'year' | 'period' | 'yearToDate' | 'entityExternalId' | 'accountHfm' | 'extraExternalId' | 'consumption'
>;

const { columns: defaultColumns } = HFM_OUTPUT.HFMOutput;
const { columns: submitColumns } = HFM_OUTPUT_SUBMIT.HFMOutputSubmit;

const getDefaultSerializer = (data: DocumentData<HfmOutputPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(HFM_OUTPUT.HFMOutput.unserialized, HFM_OUTPUT.HFMOutput.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.year.unserialized, defaultColumns.year.serialized),
      new CellSerializer(defaultColumns.period.unserialized, defaultColumns.period.serialized),
      new CellSerializer(defaultColumns.quarter.unserialized, defaultColumns.quarter.serialized),
      new CellSerializer(defaultColumns.yearToDate.unserialized, defaultColumns.yearToDate.serialized),
      new CellSerializer(defaultColumns.entityExternalId.unserialized, defaultColumns.entityExternalId.serialized),
      new CellSerializer(defaultColumns.accountHfm.unserialized, defaultColumns.accountHfm.serialized),
      new CellSerializer(defaultColumns.extraExternalId.unserialized, defaultColumns.extraExternalId.serialized),
      new CellSerializer(defaultColumns.consumption.unserialized, defaultColumns.consumption.serialized),
      new CellSerializer(defaultColumns.co2Equivalent.unserialized, defaultColumns.co2Equivalent.serialized),
      new CellSerializer(defaultColumns.entities.unserialized, defaultColumns.entities.serialized),
      new CellSerializer(defaultColumns.entitiesCountry.unserialized, defaultColumns.entitiesCountry.serialized),
      new CellSerializer(
        defaultColumns.entitiesBusinessUnit.unserialized,
        defaultColumns.entitiesBusinessUnit.serialized,
      ),
      new CellSerializer(
        defaultColumns.greenhouseConversionFactor.unserialized,
        defaultColumns.greenhouseConversionFactor.serialized,
      ),
      new CellSerializer(
        defaultColumns.greenhouseEmissionFactor.unserialized,
        defaultColumns.greenhouseEmissionFactor.serialized,
      ),
      new CellSerializer(defaultColumns.greenhouseCategory.unserialized, defaultColumns.greenhouseCategory.serialized),
      new CellSerializer(defaultColumns.greenhouseName.unserialized, defaultColumns.greenhouseName.serialized),
      new CellSerializer(defaultColumns.fileName.unserialized, defaultColumns.fileName.serialized),
      new CellSerializer(defaultColumns.uploadedAt.unserialized, defaultColumns.uploadedAt.serialized),
      new CellSerializer(defaultColumns.uploadedBy.unserialized, defaultColumns.uploadedBy.serialized),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<HfmOutputSubmitSerialized[]>) => {
  return new DocumentSerializer<HfmOutputSubmitPayload, HfmOutputSubmitSerialized>(data, [
    new SheetSerializer(HFM_OUTPUT_SUBMIT.HFMOutputSubmit.serialized, HFM_OUTPUT_SUBMIT.HFMOutputSubmit.unserialized, [
      new CellSerializer(submitColumns.year.unserialized, submitColumns.year.serialized),
      new CellSerializer(submitColumns.period.unserialized, submitColumns.period.serialized),
      new CellSerializer(submitColumns.yearToDate.unserialized, submitColumns.yearToDate.serialized),
      new CellSerializer(submitColumns.entityExternalId.unserialized, submitColumns.entityExternalId.serialized),
      new CellSerializer(submitColumns.accountHfm.unserialized, submitColumns.accountHfm.serialized),
      new CellSerializer(submitColumns.extraExternalId.unserialized, submitColumns.extraExternalId.serialized),
      new CellSerializer(submitColumns.consumption.unserialized, submitColumns.consumption.serialized),
    ]),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<HfmOutputSerialized[]>) => {
  return new DocumentSerializer<HfmOutputSerialized, HfmOutputSubmitSerialized>(data, [
    new SheetSerializer(HFM_OUTPUT_SUBMIT.HFMOutputSubmit.serialized, HFM_OUTPUT_SUBMIT.HFMOutputSubmit.unserialized, [
      new CellSerializer(defaultColumns.year.serialized, submitColumns.year.serialized),
      new CellSerializer(defaultColumns.period.serialized, submitColumns.period.serialized),
      new CellSerializer(defaultColumns.yearToDate.serialized, submitColumns.yearToDate.serialized),
      new CellSerializer(defaultColumns.entityExternalId.serialized, submitColumns.entityExternalId.serialized),
      new CellSerializer(defaultColumns.accountHfm.serialized, submitColumns.accountHfm.serialized),
      new CellSerializer(defaultColumns.extraExternalId.serialized, submitColumns.extraExternalId.serialized),
      new CellSerializer(defaultColumns.consumption.serialized, submitColumns.consumption.serialized),
    ]),
  ]);
};

export const serializeHfmOutputs = (serverData: ResourcesWrapper<HfmOutputPayload>) => {
  const { unserialized } = HFM_OUTPUT.HFMOutput;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const serializeHfmOutput = (serverData: ResourceWrapper<HfmOutputPayload>) => {
  const { serialized, unserialized } = HFM_OUTPUT.HFMOutput;
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

export const unserializeHfmOutputForSubmit = (resource: HfmOutputSubmitSerialized): HfmOutputSubmitPayload => {
  const { serialized, unserialized } = HFM_OUTPUT_SUBMIT.HFMOutputSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeHfmOutputStoSS = (resource: HfmOutputSerialized) => {
  const { serialized, unserialized } = HFM_OUTPUT_SUBMIT.HFMOutputSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper } from 'lib/http/utils';
import { DELIVERY_MESSAGES_FIELDS } from 'lib/tables/config/delivery/messages/config';

const { columns, serialized, unserialized } = DELIVERY_MESSAGES_FIELDS.deliveryMessages;
const {
  id,
  supplierVatNumber,
  supplierName,
  supplierHouseNumber,
  associationAssignedCode,
  clientAddress,
  clientCity,
  clientCountryCode,
  clientHouseNumber,
  clientName,
  clientPostalCode,
  clientUbn,
  messageVersion,
  messageOrderNumber,
  senderId,
  sendMessageDate,
  supplierAddress,
  supplierCountryCode,
  supplierEmail,
  supplierPhone,
  supplierPostalCode,
  supplierUbn,
  supplierUrl,
  messageId,
  supplierCity,
} = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(id.unserialized, id.serialized),
      new CellSerializer(messageId.unserialized, messageId.serialized),
      new CellSerializer(supplierVatNumber.unserialized, supplierVatNumber.serialized),
      new CellSerializer(supplierName.unserialized, supplierName.serialized),
      new CellSerializer(supplierHouseNumber.unserialized, supplierHouseNumber.serialized),
      new CellSerializer(associationAssignedCode.unserialized, associationAssignedCode.serialized),
      new CellSerializer(clientAddress.unserialized, clientAddress.serialized),
      new CellSerializer(clientCountryCode.unserialized, clientCountryCode.serialized),
      new CellSerializer(clientCity.unserialized, clientCity.serialized),
      new CellSerializer(clientHouseNumber.unserialized, clientHouseNumber.serialized),
      new CellSerializer(clientName.unserialized, clientName.serialized),
      new CellSerializer(clientPostalCode.unserialized, clientPostalCode.serialized),
      new CellSerializer(clientUbn.unserialized, clientUbn.serialized),
      new CellSerializer(messageVersion.unserialized, messageVersion.serialized),
      new CellSerializer(messageOrderNumber.unserialized, messageOrderNumber.serialized),
      new CellSerializer(senderId.unserialized, senderId.serialized),
      new CellSerializer(sendMessageDate.unserialized, sendMessageDate.serialized),
      new CellSerializer(supplierAddress.unserialized, supplierAddress.serialized),
      new CellSerializer(supplierCity.unserialized, supplierCity.serialized),
      new CellSerializer(supplierCountryCode.unserialized, supplierCountryCode.serialized),
      new CellSerializer(supplierEmail.unserialized, supplierEmail.serialized),
      new CellSerializer(supplierPhone.unserialized, supplierPhone.serialized),
      new CellSerializer(supplierPostalCode.unserialized, supplierPostalCode.serialized),
      new CellSerializer(supplierUbn.unserialized, supplierUbn.serialized),
      new CellSerializer(supplierUrl.unserialized, supplierUrl.serialized),

      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeDeliveryMessageInfo = (serverData: ResourcesWrapper) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { ELECTRICITY_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/electricityConsumptionRates/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundToInteger } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = ELECTRICITY_CONSUMPTION_RATES_FIELDS.ElectricityConsumptionRates;

export const getElectricityConsumptionRatesView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.householdPeopleQuantity.serialized,
        viewPropName: columns.householdPeopleQuantity.view,
      }),
      new CellViewer({
        serializedPropName: columns.electricityConsumption.serialized,
        viewPropName: columns.electricityConsumption.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseInt, roundToInteger),
      }),
    ]),
  ]);
};

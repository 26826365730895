import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const movementEvents = new Route('get', new RouteParser('/mock/movement_events'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 4,
          event_id: '9wrgni6w40',
          event_type: 'AFV',
          event_date_time: '2020-01-21T21:52:06.000Z',
          animal_category: '4',
          animal_count: 348,
          transport_id: 'g2v2vzfua8',
          group_data: 'shqfefo',
          kvk: 'xfp2m53',
          ubn: 'x5g3lv9',
          created_at: '2020-08-26T08:10:04.346Z',
          updated_at: '2020-08-26T08:10:04.346Z',
          destination: {
            event_location_id: 'vuhqc9fy3o',
            scheme: 'c9mcch5',
            event_id_match: 'olqdjnq84c',
          },
          ...generateBlockchainRecord(),
        },
        {
          id: 3,
          event_id: 'au21klzzq1',
          event_type: 'AAN',
          event_date_time: '2019-12-02T20:23:19.000Z',
          animal_category: '3',
          animal_count: 815,
          transport_id: 'fol9pcpknf',
          group_data: 'gz0zdc1',
          kvk: 'rpk34op',
          ubn: 'stnd70j',
          created_at: '2020-08-26T08:10:04.330Z',
          updated_at: '2020-08-26T08:10:04.330Z',
          origin: {
            event_location_id: 'j66ibio1vf',
            scheme: 'rub6tj6',
            event_id_match: 'k04ad7sn53',
          },
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

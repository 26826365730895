import { Route } from '../../Route';
import RouteParser from 'route-parser';

import { generateBlockchainRecord } from '../../utils';

export const veterinarianData = new Route('get', new RouteParser('/mock/veterinarian_data'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 6,
          source: 'debitis',
          export_date: '2019-12-13T00:00:00.000Z',
          ubn: '263197',
          company_name: 'Kessler Group',
          location: 'Suite 625 9809 Dave Circle, Lake Shaynafort, VA 65932',
          animal_category: 'harum',
          ddd_tm: 36859.05,
          created_at: '2020-09-02T13:20:19.090Z',
          updated_at: '2020-09-02T13:20:19.090Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 5,
          source: 'quasi',
          export_date: '2020-01-01T00:00:00.000Z',
          ubn: '868749',
          company_name: 'Monahan-Huels',
          location: 'Suite 839 13134 Schamberger Port, Joneston, WA 01828',
          animal_category: 'minus',
          ddd_tm: 20322.54,
          created_at: '2020-09-02T13:20:19.074Z',
          updated_at: '2020-09-02T13:20:19.074Z',
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { WEEKLY_DOCUMENT_FIELDS } from 'lib/excel/config/weekly/structure';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

import { ResourcesWrapper } from 'lib/http/utils';

interface DeviationUnserialized {
  id: number;
  program: string;
  remark: string;
  answer: string;
  code: string;
  updated_date: string;
  created_at: string;
  updated_at: string;
  ubn: string;
}

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(WEEKLY_DOCUMENT_FIELDS.Deviations.unserialized, WEEKLY_DOCUMENT_FIELDS.Deviations.serialized, [
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.ubn.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.ubn.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.program.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.program.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.date.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.date.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.questionCode.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.questionCode.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.remark.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.remark.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.answer.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Deviations.columns.answer.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeDeviationsInfo = (serverData: ResourcesWrapper<DeviationUnserialized>) => {
  const data = {
    [WEEKLY_DOCUMENT_FIELDS.Deviations.unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys';

enum CodesEventTypesSerialized {
  CodesEventTypes = 'CodesEventTypes',
}

enum CodesEventTypesUnserialized {
  CodesEventTypes = 'CodesEventTypes',
}

export enum CodesEventTypesSerializedHeaders {
  id = 'id',
  eventTypeIdentifier = 'eventTypeIdentifier',
  note = 'note',
  shortcut = 'shortcut',
  eventTypeDatetime = 'eventTypeDatetime',
}

export const CodesEventTypesUnserializedHeaders = {
  id: 'id',
  eventTypeIdentifier: 'event_type_identifier',
  note: 'note',
  shortcut: 'shortcut',
  eventTypeDatetime: 'event_type_datetime',
} as const;

export const CODES_EVENT_TYPES = {
  [CodesEventTypesSerialized.CodesEventTypes]: {
    serialized: CodesEventTypesSerialized.CodesEventTypes,
    unserialized: CodesEventTypesUnserialized.CodesEventTypes,
    view: IntlKeys.farmManagementDataEventTypesList,

    columns: {
      [CodesEventTypesSerializedHeaders.id]: {
        serialized: CodesEventTypesSerializedHeaders.id,
        unserialized: CodesEventTypesUnserializedHeaders.id,
      },
      [CodesEventTypesSerializedHeaders.eventTypeIdentifier]: {
        serialized: CodesEventTypesSerializedHeaders.eventTypeIdentifier,
        unserialized: CodesEventTypesUnserializedHeaders.eventTypeIdentifier,
        view: IntlKeys.farmManagementDataEventTypeId,
      },
      [CodesEventTypesSerializedHeaders.note]: {
        serialized: CodesEventTypesSerializedHeaders.note,
        unserialized: CodesEventTypesUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesEventTypesSerializedHeaders.shortcut]: {
        serialized: CodesEventTypesSerializedHeaders.shortcut,
        unserialized: CodesEventTypesUnserializedHeaders.shortcut,
        view: IntlKeys.farmManagementDataShortcut,
      },
      [CodesEventTypesSerializedHeaders.eventTypeDatetime]: {
        serialized: CodesEventTypesSerializedHeaders.eventTypeDatetime,
        unserialized: CodesEventTypesUnserializedHeaders.eventTypeDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys';

enum CodesFarmOptionSerialized {
  CodesFarmOption = 'CodesFarmOption',
}

enum CodesFarmOptionUnserialized {
  CodesFarmOption = 'CodesFarmOption',
}

export enum CodesFarmOptionSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  farmOptionId = 'farmOptionId',
  description = 'description',
  value = 'value',
  farmOptionDatetime = 'farmOptionDatetime',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesFarmOptionUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  farmOptionId: 'farm_option_identifier',
  description: 'description',
  value: 'value',
  farmOptionDatetime: 'farm_option_datetime',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_FARM_OPTION = {
  [CodesFarmOptionSerialized.CodesFarmOption]: {
    serialized: CodesFarmOptionSerialized.CodesFarmOption,
    unserialized: CodesFarmOptionUnserialized.CodesFarmOption,
    view: IntlKeys.farmManagementDataFarmOptionList,

    columns: {
      [CodesFarmOptionSerializedHeaders.id]: {
        serialized: CodesFarmOptionSerializedHeaders.id,
        unserialized: CodesFarmOptionUnserializedHeaders.id,
      },
      [CodesFarmOptionSerializedHeaders.companyIdentifier]: {
        serialized: CodesFarmOptionSerializedHeaders.companyIdentifier,
        unserialized: CodesFarmOptionUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesFarmOptionSerializedHeaders.farmIdentifier]: {
        serialized: CodesFarmOptionSerializedHeaders.farmIdentifier,
        unserialized: CodesFarmOptionUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [CodesFarmOptionSerializedHeaders.farmOptionId]: {
        serialized: CodesFarmOptionSerializedHeaders.farmOptionId,
        unserialized: CodesFarmOptionUnserializedHeaders.farmOptionId,
        view: IntlKeys.farmManagementDataFarmOptionId,
      },
      [CodesFarmOptionSerializedHeaders.description]: {
        serialized: CodesFarmOptionSerializedHeaders.description,
        unserialized: CodesFarmOptionUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesFarmOptionSerializedHeaders.value]: {
        serialized: CodesFarmOptionSerializedHeaders.value,
        unserialized: CodesFarmOptionUnserializedHeaders.value,
        view: IntlKeys.farmManagementDataValue,
      },
      [CodesFarmOptionSerializedHeaders.farmOptionDatetime]: {
        serialized: CodesFarmOptionSerializedHeaders.farmOptionDatetime,
        unserialized: CodesFarmOptionUnserializedHeaders.farmOptionDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesFarmOptionSerializedHeaders.ubnList]: {
        serialized: CodesFarmOptionSerializedHeaders.ubnList,
        unserialized: CodesFarmOptionUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesFarmOptionSerializedHeaders.kvkList]: {
        serialized: CodesFarmOptionSerializedHeaders.kvkList,
        unserialized: CodesFarmOptionUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesFarmOptionSerializedHeaders.registrationNameList]: {
        serialized: CodesFarmOptionSerializedHeaders.registrationNameList,
        unserialized: CodesFarmOptionUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

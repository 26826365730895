import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';
import { serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';

import { UserPassportPayload, UserPassportSerialized } from 'store/entities/userPassports';
import { getUserPassportsCellSerializer } from '../userPassportsCellSerializer';
import { CERTIFICATES_GROUPED } from './config';

export interface ValidityGroupedRaw {
  id: number;
  ubn: string;
  valid_from: string;
  valid_to: string;
  certificate_type: string;
  certificate_status: string;
  user_passports?: Array<Pick<UserPassportPayload, 'id' | 'registration_name' | 'kvk' | 'ubn'>>;
}

export interface ValidityGroupedSerializedEntry {
  id: number;
  ubn: string;
  validFrom: string;
  validTo: string;
  certificateType: string;
  certificateStatus: string;
  userPassports?: Array<Pick<UserPassportSerialized, 'id' | 'registrationName' | 'kvk' | 'ubn'>>;
}

const { columns, serialized, unserialized } = CERTIFICATES_GROUPED.ValidityGrouped;

const getSerializer = (data: DocumentData<ValidityGroupedRaw[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      //
      new CellSerializer(columns.id.unserialized, columns.id.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(columns.ubn.unserialized, columns.ubn.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(
        columns.validFrom.unserialized,
        columns.validFrom.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),
      new CellSerializer(columns.validTo.unserialized, columns.validTo.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(
        columns.certificateType.unserialized,
        columns.certificateType.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),
      new CellSerializer(
        columns.certificateStatus.unserialized,
        columns.certificateStatus.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),
      getUserPassportsCellSerializer(columns.userPassports.unserialized, columns.userPassports.serialized),
    ]),
  ]);
};

export const serializeValidityGrouped = (serverData: ResourcesWrapper<ValidityGroupedRaw>) => {
  //
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);
  return serializer.serialize();
};

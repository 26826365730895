import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper } from 'lib/http/utils';
import { SLAUGHTER_MESSAGES } from 'lib/tables/config/slaughter/messages/config';

interface SlaughterMessagePayload {
  id: 1;
  message_id: string;
  farmer_id: string;
  message_date: string;
  currency: string;
  slaughterhouse_id: string;
  slaughter_date: string;
  created_at: string;
  slaughters: Array<any>;
  ubn_list: Array<any>;
  message_sequence_number: string | number;
  animals_supplied_count: string | number;
}

export interface SlaughterMessageSerialized {
  id: 1;
  messageId: string;
  supplierRelationNumber: string; // farmer_id
  messageDate: string;
  currency: string;
  slaughterhouseId: string;
  slaughterDate: string;
  createdAt: string;
  slaughters: Array<any>;
  supplierId: Array<any>; // ubn_list
  messageSequenceNumber: string | number;
  animalsSuppliedCount: string | number;
}

const { columns, serialized, unserialized } = SLAUGHTER_MESSAGES.SlaughterMessages;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.messageId.unserialized, columns.messageId.serialized),
      new CellSerializer(columns.slaughterPlaceId.unserialized, columns.slaughterPlaceId.serialized),
      new CellSerializer(columns.supplierRelationNumber.unserialized, columns.supplierRelationNumber.serialized),
      new CellSerializer(columns.labels.unserialized, columns.labels.serialized),
      new CellSerializer(columns.messageDate.unserialized, columns.messageDate.serialized, dateToFormattedString),
      new CellSerializer(columns.currency.unserialized, columns.currency.serialized),
      new CellSerializer(columns.slaughterHouseId.unserialized, columns.slaughterHouseId.serialized),
      new CellSerializer(columns.slaughterDate.unserialized, columns.slaughterDate.serialized, dateToFormattedString),
      new CellSerializer(columns.supplierId.unserialized, columns.supplierId.serialized),
      new CellSerializer(columns.messageSequenceNumber.unserialized, columns.messageSequenceNumber.serialized),
      new CellSerializer(columns.animalsSuppliedCount.unserialized, columns.animalsSuppliedCount.serialized),

      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeSlaughterMessagesInfo = (serverData: ResourcesWrapper<SlaughterMessagePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { WUR_MFC_EFEM_FIELDS } from 'lib/excel/config/referenceTables/wurMfcEfem/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = WUR_MFC_EFEM_FIELDS.WurMfcEfem;

export const serializeWurMfcEfemData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.system.original, columns.system.serialized),
      new CellSerializer(columns.avgStorageDurationManure.original, columns.avgStorageDurationManure.serialized),
      new CellSerializer(columns.mcf.original, columns.mcf.serialized),
    ]),
  ]);
};

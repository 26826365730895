import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { GROUP_ARRIVAL } from 'store/entities/farmManagementData/groupArrival/config';

const { columns, view, serialized } = GROUP_ARRIVAL.GroupArrival;

export const groupArrivalTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.financialEventIdentifier.view,
          field: columns.financialEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.animalCategoryIdentifier.view,
          field: columns.animalCategoryIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupArrivalNumber.view,
          field: columns.groupArrivalNumber.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.totalWeight.view,
          field: columns.totalWeight.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.originIdentifier.view,
          field: columns.originIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupArrivalDatetime.view,
          field: columns.groupArrivalDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ownFarm.view,
          field: columns.ownFarm.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.withoutMale.view,
          field: columns.withoutMale.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.withoutFemale.view,
          field: columns.withoutFemale.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.withoutMaleCastrated.view,
          field: columns.withoutMaleCastrated.serialized,
        },
      }),
    ],
  }),
]);

import { DocumentData } from '../serilizers/Sheet';
import { SheetViewer } from './Sheet';

export type TypedObjectKeys<T> = Record<string, T>;
export type ViewDocumentData = TypedObjectKeys<Array<TypedObjectKeys<any>>>;

export class DocumentViewer {
  constructor(
    private readonly data: DocumentData,
    private readonly sheetViewers: Array<SheetViewer>,
    private documentData: ViewDocumentData = {},
  ) {
    this.setSheetValues();
    this.setDocumentData();
  }

  public getViewData = () => {
    return this.documentData;
  };

  private setSheetValues = () => {
    this.sheetViewers.forEach((sheetViewer) => {
      const documentData = this.data[sheetViewer.serializedSheetName];

      sheetViewer.setData(documentData);
    });
  };

  private setDocumentData = () => {
    this.documentData = this.sheetViewers.reduce((cumulative, sheetViewer) => {
      return {
        ...cumulative,
        ...{ [sheetViewer.viewSheetName]: sheetViewer.getData() },
      };
    }, {});
  };
}

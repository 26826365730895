import { IntlKeys } from 'lib/localization/keys';

enum WurMcfEfemSerialized {
  WurMcfEfem = 'WurMcfEfem',
}

enum WurMcfEfemUnserialized {
  WurMcfEfem = 'WurMcfEfem',
}

export enum WurMcfEfemSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  system = 'system',
  avgStorageDurationManure = 'avgStorageDurationManure',
  mcf = 'mcf',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const WurMcfEfemUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['wur_mcf_efem_upload', 'id'],
  system: 'system',
  avgStorageDurationManure: 'avg_storage_duration_manure',
  mcf: 'mcf',
  uploadType: ['wur_mcf_efem_upload', 'upload_type'],
  filename: ['wur_mcf_efem_upload', 'file_name'],
  status: ['wur_mcf_efem_upload', 'status'],
  validDateFrom: ['wur_mcf_efem_upload', 'valid_from'],
  validDateTo: ['wur_mcf_efem_upload', 'valid_to'],
  notes: ['wur_mcf_efem_upload', 'notes'],
  uploadedAt: ['wur_mcf_efem_upload', 'uploaded_at'],
  uploadedBy: ['wur_mcf_efem_upload', 'uploaded_by', 'full_name'],
} as const;

export const WUR_MCF_EFEM = {
  [WurMcfEfemSerialized.WurMcfEfem]: {
    serialized: WurMcfEfemSerialized.WurMcfEfem,
    unserialized: WurMcfEfemUnserialized.WurMcfEfem,
    view: IntlKeys.referenceTablesWurMfcEfem,

    columns: {
      [WurMcfEfemSerializedHeaders.id]: {
        serialized: WurMcfEfemSerializedHeaders.id,
        unserialized: WurMcfEfemUnserializedHeaders.id,
      },
      [WurMcfEfemSerializedHeaders.referenceUploadID]: {
        serialized: WurMcfEfemSerializedHeaders.referenceUploadID,
        unserialized: WurMcfEfemUnserializedHeaders.referenceUploadID,
      },
      [WurMcfEfemSerializedHeaders.system]: {
        serialized: WurMcfEfemSerializedHeaders.system,
        unserialized: WurMcfEfemUnserializedHeaders.system,
        view: IntlKeys.referenceTablesWurMfcEfemSystem,
      },
      [WurMcfEfemSerializedHeaders.avgStorageDurationManure]: {
        serialized: WurMcfEfemSerializedHeaders.avgStorageDurationManure,
        unserialized: WurMcfEfemUnserializedHeaders.avgStorageDurationManure,
        view: IntlKeys.referenceTablesWurMfcEfemDurationDung,
      },
      [WurMcfEfemSerializedHeaders.mcf]: {
        serialized: WurMcfEfemSerializedHeaders.mcf,
        unserialized: WurMcfEfemUnserializedHeaders.mcf,
        view: IntlKeys.referenceTablesWurMfcEfemMfc,
      },
      [WurMcfEfemSerializedHeaders.status]: {
        serialized: WurMcfEfemSerializedHeaders.status,
        unserialized: WurMcfEfemUnserializedHeaders.status,
      },
      [WurMcfEfemSerializedHeaders.uploadType]: {
        serialized: WurMcfEfemSerializedHeaders.uploadType,
        unserialized: WurMcfEfemUnserializedHeaders.uploadType,
      },
      [WurMcfEfemSerializedHeaders.notes]: {
        serialized: WurMcfEfemSerializedHeaders.notes,
        unserialized: WurMcfEfemUnserializedHeaders.notes,
      },
      [WurMcfEfemSerializedHeaders.filename]: {
        serialized: WurMcfEfemSerializedHeaders.filename,
        unserialized: WurMcfEfemUnserializedHeaders.filename,
      },
      [WurMcfEfemSerializedHeaders.validDateFrom]: {
        serialized: WurMcfEfemSerializedHeaders.validDateFrom,
        unserialized: WurMcfEfemUnserializedHeaders.validDateFrom,
      },
      [WurMcfEfemSerializedHeaders.validDateTo]: {
        serialized: WurMcfEfemSerializedHeaders.validDateTo,
        unserialized: WurMcfEfemUnserializedHeaders.validDateTo,
      },
      [WurMcfEfemSerializedHeaders.uploadedAt]: {
        serialized: WurMcfEfemSerializedHeaders.uploadedAt,
        unserialized: WurMcfEfemUnserializedHeaders.uploadedAt,
      },
      [WurMcfEfemSerializedHeaders.uploadedBy]: {
        serialized: WurMcfEfemSerializedHeaders.uploadedBy,
        unserialized: WurMcfEfemUnserializedHeaders.uploadedBy,
      },
    },
  },
};

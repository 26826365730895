import { IntlKeys } from 'lib/localization/keys';

enum RavReferenceListSerialized {
  RavReferenceList = 'RavReferenceList',
}

enum RavReferenceListUnserialized {
  RavReferenceList = 'RavReferenceList',
}

export enum RavReferenceListSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  animalCategory = 'animalCategory',
  description = 'description',
  efEm = 'efEm',
  housingType = 'housingType',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const RavReferenceListUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['rav_reference_list_upload', 'id'],
  animalCategory: 'animal_category',
  description: 'description',
  efEm: 'ef_em',
  housingType: 'housing_type',
  uploadType: ['rav_reference_list_upload', 'upload_type'],
  filename: ['rav_reference_list_upload', 'file_name'],
  status: ['rav_reference_list_upload', 'status'],
  validDateFrom: ['rav_reference_list_upload', 'valid_from'],
  validDateTo: ['rav_reference_list_upload', 'valid_to'],
  notes: ['rav_reference_list_upload', 'notes'],
  uploadedAt: ['rav_reference_list_upload', 'uploaded_at'],
  uploadedBy: ['rav_reference_list_upload', 'uploaded_by', 'full_name'],
} as const;

export const RAV_REFERENCE_LIST = {
  [RavReferenceListSerialized.RavReferenceList]: {
    serialized: RavReferenceListSerialized.RavReferenceList,
    unserialized: RavReferenceListUnserialized.RavReferenceList,
    view: IntlKeys.referenceTablesRavReferenceList,

    columns: {
      [RavReferenceListSerializedHeaders.id]: {
        serialized: RavReferenceListSerializedHeaders.id,
        unserialized: RavReferenceListUnserializedHeaders.id,
      },
      [RavReferenceListSerializedHeaders.referenceUploadID]: {
        serialized: RavReferenceListSerializedHeaders.referenceUploadID,
        unserialized: RavReferenceListUnserializedHeaders.referenceUploadID,
      },
      [RavReferenceListSerializedHeaders.animalCategory]: {
        serialized: RavReferenceListSerializedHeaders.animalCategory,
        unserialized: RavReferenceListUnserializedHeaders.animalCategory,
        view: IntlKeys.referenceTablesRavReferenceListAnimalCategory,
      },
      [RavReferenceListSerializedHeaders.description]: {
        serialized: RavReferenceListSerializedHeaders.description,
        unserialized: RavReferenceListUnserializedHeaders.description,
        view: IntlKeys.referenceTablesRavReferenceListDescription,
      },
      [RavReferenceListSerializedHeaders.efEm]: {
        serialized: RavReferenceListSerializedHeaders.efEm,
        unserialized: RavReferenceListUnserializedHeaders.efEm,
        view: IntlKeys.referenceTablesRavReferenceListEfem,
      },
      [RavReferenceListSerializedHeaders.housingType]: {
        serialized: RavReferenceListSerializedHeaders.housingType,
        unserialized: RavReferenceListUnserializedHeaders.housingType,
        view: IntlKeys.referenceTablesRavReferenceListHousingType,
      },
      [RavReferenceListSerializedHeaders.status]: {
        serialized: RavReferenceListSerializedHeaders.status,
        unserialized: RavReferenceListUnserializedHeaders.status,
      },
      [RavReferenceListSerializedHeaders.uploadType]: {
        serialized: RavReferenceListSerializedHeaders.uploadType,
        unserialized: RavReferenceListUnserializedHeaders.uploadType,
      },
      [RavReferenceListSerializedHeaders.notes]: {
        serialized: RavReferenceListSerializedHeaders.notes,
        unserialized: RavReferenceListUnserializedHeaders.notes,
      },
      [RavReferenceListSerializedHeaders.filename]: {
        serialized: RavReferenceListSerializedHeaders.filename,
        unserialized: RavReferenceListUnserializedHeaders.filename,
      },
      [RavReferenceListSerializedHeaders.validDateFrom]: {
        serialized: RavReferenceListSerializedHeaders.validDateFrom,
        unserialized: RavReferenceListUnserializedHeaders.validDateFrom,
      },
      [RavReferenceListSerializedHeaders.validDateTo]: {
        serialized: RavReferenceListSerializedHeaders.validDateTo,
        unserialized: RavReferenceListUnserializedHeaders.validDateTo,
      },
      [RavReferenceListSerializedHeaders.uploadedAt]: {
        serialized: RavReferenceListSerializedHeaders.uploadedAt,
        unserialized: RavReferenceListUnserializedHeaders.uploadedAt,
      },
      [RavReferenceListSerializedHeaders.uploadedBy]: {
        serialized: RavReferenceListSerializedHeaders.uploadedBy,
        unserialized: RavReferenceListUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import React, { useCallback, useState } from 'react';
import { Collapse } from '@material-ui/core';

import { PERMISSIONS } from 'store/auth/permissions';

import MenuItem from './MenuItem';

import { SvgIconComponent } from '@material-ui/icons';

interface OwnProps {
  title: string;
  icon: SvgIconComponent;
  url?: string;
  isOpen?: boolean;
  nestingLevel?: number;
  withArrow?: boolean;
  subItems?: OwnProps[];
  restrictions?: { [key in PERMISSIONS]?: boolean };
  checkItemVisibility?: (
    restrictions?: { [key in PERMISSIONS]?: boolean },
    hideable?: boolean,
    title?: string,
  ) => boolean;
  onMenuItemSelect?: () => void;
  onClick?: (e: React.MouseEvent) => void;
  hideable?: boolean;
}
const checkVisibilityStub = () => true;

const MenuItemGroup: React.FC<OwnProps> = ({
  //
  title,
  icon,
  url,
  subItems,
  nestingLevel = 0,
  onMenuItemSelect,
  restrictions,
  checkItemVisibility = checkVisibilityStub,
  hideable = false,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return checkItemVisibility(restrictions, hideable, title) ? (
    <>
      <MenuItem
        title={title}
        icon={icon}
        url={url}
        toggleOpen={toggleOpen}
        nestingLevel={nestingLevel}
        isOpen={isOpen}
        withArrow={Boolean(subItems?.length)}
        onMenuItemSelect={onMenuItemSelect}
        {...rest}
      />

      {subItems?.length && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {subItems.map(({ title, icon, url, restrictions, subItems, hideable }) => (
            <MenuItemGroup
              key={title}
              title={title}
              icon={icon}
              url={url}
              subItems={subItems}
              nestingLevel={nestingLevel + 1}
              onMenuItemSelect={onMenuItemSelect}
              restrictions={restrictions}
              checkItemVisibility={checkItemVisibility}
              hideable={hideable}
            />
          ))}
        </Collapse>
      )}
    </>
  ) : null;
};

export default React.memo(MenuItemGroup);

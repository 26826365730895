import { DisplayHeader } from './DisplayHeader';
import { TypedObjectKeys } from '../view/Document';

export type ViewDocumentData = TypedObjectKeys<Array<TypedObjectKeys<any>>>;

export class DisplayTab {
  constructor(readonly tabName: string, readonly headers: Array<DisplayHeader>) {}

  public getHeaders = () => {
    return this.headers;
  };
}

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { CL607 } from 'store/entities/referenceTables/cl607/config';

const { columns, view, serialized } = CL607.Cl607;

export const cl607TableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.code.view,
          field: columns.code.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.code.serialized] && roundToInteger(data[columns.code.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.description.view,
          field: columns.description.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.remarks.view,
          field: columns.remarks.serialized,
        },
      }),
    ],
  }),
]);

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { CODES_BARN } from 'store/entities/farmManagementData/codesBarn/config';

const { columns, view, serialized } = CODES_BARN.CodesBarn;

export const codesBarnTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.barnIdentifier.view,
          field: columns.barnIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.abbreviation.view,
          field: columns.abbreviation.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.description.view,
          field: columns.description.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.codeBarnDatetime.view,
          field: columns.codeBarnDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.correctionFin.view,
          field: columns.correctionFin.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.isEarlyWeanUnit.view,
          field: columns.isEarlyWeanUnit.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.surface.view,
          field: columns.surface.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.vvoNumber.view,
          field: columns.vvoNumber.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.euApprovalNumber.view,
          field: columns.euApprovalNumber.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.correctionWeaners.view,
          field: columns.correctionWeaners.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.correctionReplGilts.view,
          field: columns.correctionReplGilts.serialized,
        },
      }),
    ],
  }),
]);

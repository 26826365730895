import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import { ENTITIES_LIST } from 'store/entities/referenceTables/entitiesList/config';

const { columns, view, serialized } = ENTITIES_LIST.EntitiesList;

export const entitiesListTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.entityId.view,
          field: columns.entityId.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.entityName.view,
          field: columns.entityName.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.country.view,
          field: columns.country.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.businessUnit.view,
          field: columns.businessUnit.serialized,
        },
      }),
    ],
  }),
]);

import {
  formatPercentsTo1Digits,
  roundTo1DigitsFloating,
  roundTo2Digits,
  roundTo2DigitsFloating,
  roundToInteger,
  suffixFormatter,
} from 'lib/helpers/renderHelpers';

export type VriCalculatedReportUnserialized = {
  id: number;
  user_passport_id: number;
  ubn: string;
  vion_relation_number: string;
  name: string;
  average_meat_correction: number;
  average_muscle_correction: number;
  average_beacon_correction: number;
  average_weight_correction: number;
  average_sex_allowance: number;
  average_concept_surcharge: number;
  average_meat: number;
  average_weight: number;
  average_fat_thickness: number;
  average_muscle_thickness: number;
  total_pigs_count: number;
  approved_carcass_count: number;
  calculated_report_id: number;
  carcass_code_1: number;
  carcass_code_2: number;
  carcass_code_3: number;
  carcass_code_4: number;
  carcass_code_5: number;
  carcass_code_6: number;
  carcass_code_7: number;
  approved_organ_count: number;
  organ_code_1: number;
  organ_code_2: number;
  organ_code_3: number;
  organ_code_4: number;
  organ_code_5: number;
  organ_code_6: number;
  healthy_count: number;
  average_classification_correction: number;
  balance_with_respect_to_base: number;
  pigs_in_draft: number;
  healthy: number;
  long_afg_count: number;
  long_afg: number;
  lever_afg_count: number;
  lever_afg: number;
  approved_organ: number;
  liver_impaired_count: number;
  liver_impaired: number;
  inflamed_leg_count: number;
  inflamed_leg: number;
  inflamed_skin_count: number;
  inflamed_skin: number;
  pleurisy_count: number;
  pleurisy: number;
  approved_carcass: number;
  not_judjeable_count: number;
  not_judjeable: number;
  euro_per_pig: number;
  vri_health: number;
  vri_classification: number;
  total: number;
  vri_serial_number: number;
  epg_serial_number: number;
  feed_intake_per_day: number;
  average_nutrient_ew: number;
  removed_pigs_count: number;
  average_epg_weight: number;
  average_birth_weight: number;
  average_round_length_days: number;
  loss: number;
  ew_conversion: number;
  growth: number;
  epg: number;
  vri_epg: number;
};

export type VriCalculatedReportSerialized = {
  id: number;
  userPassportId: number;
  ubn: string;
  vionRelationNumber: string;
  name: string;
  averageMeatCorrection: number;
  averageMuscleCorrection: number;
  averageBeaconCorrection: number;
  averageWeightCorrection: number;
  averageSexAllowance: number;
  averageConceptSurcharge: number;
  averageMeat: number;
  averageWeight: number;
  averageFatThickness: number;
  averageMuscleThickness: number;
  totalPigsCount: number;
  approvedCarcassCount: number;
  calculatedReportId: number;
  carcassCode1: number;
  carcassCode2: number;
  carcassCode3: number;
  carcassCode4: number;
  carcassCode5: number;
  carcassCode6: number;
  carcassCode7: number;
  approvedOrganCount: number;
  organCode1: number;
  organCode2: number;
  organCode3: number;
  organCode4: number;
  organCode5: number;
  organCode6: number;
  healthyCount: number;
  averageClassificationCorrection: number;
  balanceWithRespectToBase: number;
  pigsInDraft: number;
  healthy: number;
  longAfgCount: number;
  longAfg: number;
  leverAfgCount: number;
  leverAfg: number;
  approvedOrgan: number;
  liverImpairedCount: number;
  liverImpaired: number;
  inflamedLegCount: number;
  inflamedLeg: number;
  inflamedSkinCount: number;
  inflamedSkin: number;
  pleurisyCount: number;
  pleurisy: number;
  approvedCarcass: number;
  notJudjeableCount: number;
  notJudjeable: number;
  euroPerPig: number;
  vriHealth: number;
  vriClassification: number;
  total: number;
  vriSerialNumber: number;
  epgSerialNumber: number;
  feedIntakePerDay: number;
  averageNutrientEw: number;
  removedPigsCount: number;
  averageEpgWeight: number;
  averageBirthWeight: number;
  averageRoundLengthDays: number;
  loss: number;
  ewConversion: number;
  growth: number;
  epg: number;
  vriEpg: number;
};

type VriCalculatedTotals = {
  health_balance: {
    total_average: number;
    total_top_20: number;
  };
  balance_base: {
    total_average: number;
    total_top_20: number;
  };
  epg: {
    total_average: number;
    total_top_20: number;
  };
};

type VriAggregatedReportDataUnserialized = {
  total_min: number;
  total_max: number;
  euro_per_pig_min: number;
  euro_per_pig_max: number;
  balance_with_respect_to_base_min: number;
  balance_with_respect_to_base_max: number;
  // epg_min: number;
  // epg_max: number;
  totals: VriCalculatedTotals;
};

type VriAggregatedReportDataSerialized = {
  totalMin: number;
  totalMax: number;
  euroPerPigMin: number;
  euroPerPigMax: number;
  balanceWithRespectToBaseMin: number;
  balanceWithRespectToBaseMax: number;
  // epgMin: number;
  // epgMax: number;
  totals: VriCalculatedTotals;
};

export type VriCalculatedResourcesItemUnserialized = {
  id: number;
  status: string;
  aggregated_report_data: VriAggregatedReportDataUnserialized;
  calculated_report_items: VriCalculatedReportUnserialized[];
};

export type VriCalculatedResourcesItemSerialized = {
  resourceData: {
    id: number;
    status: string;
  };
  aggregatedReportData: VriAggregatedReportDataSerialized;
  calculatedReportItems: VriCalculatedReportSerialized[];
};

export type DateRange = {
  reportDateFrom: string;
  reportDateTo: string;
};

type VriCalculatedStatisticEntityUnserialized = {
  your_score: number;
  average_top_20_score: number;
  difference: number;
};

type VriCalculatedStatisticEntitySerialized = {
  yourScore: number;
  averageTop20Score: number;
  difference: number;
};

export type VriCalculatedStatisticsResourceUnserialized = {
  vri_total: VriCalculatedStatisticEntityUnserialized;
  lap_speed: VriCalculatedStatisticEntityUnserialized;
  delivered_animals: VriCalculatedStatisticEntityUnserialized;
  delivered: VriCalculatedStatisticEntityUnserialized;
  vri_epg: VriCalculatedStatisticEntityUnserialized;
  epg_euro_per_pig: VriCalculatedStatisticEntityUnserialized;
  growth: VriCalculatedStatisticEntityUnserialized;
  feed_intake_per_day: VriCalculatedStatisticEntityUnserialized;
  ew_conversion: VriCalculatedStatisticEntityUnserialized;
  laying_big_weight: VriCalculatedStatisticEntityUnserialized;
  loss: VriCalculatedStatisticEntityUnserialized;
  vri_balance_with_respect_to_base: VriCalculatedStatisticEntityUnserialized;
  balance_with_respect_to_base: VriCalculatedStatisticEntityUnserialized;
  average_weight: VriCalculatedStatisticEntityUnserialized;
  average_weight_correction: VriCalculatedStatisticEntityUnserialized;
  average_fat_thickness: VriCalculatedStatisticEntityUnserialized;
  average_beacon_correction: VriCalculatedStatisticEntityUnserialized;
  average_muscle_thickness: VriCalculatedStatisticEntityUnserialized;
  average_muscle_correction: VriCalculatedStatisticEntityUnserialized;
  average_meat: VriCalculatedStatisticEntityUnserialized;
  average_meat_correction: VriCalculatedStatisticEntityUnserialized;
  average_classification_correction: VriCalculatedStatisticEntityUnserialized;
  average_sex_allowance: VriCalculatedStatisticEntityUnserialized;
  average_concept_surcharge: VriCalculatedStatisticEntityUnserialized;
  pigs_in_draft: VriCalculatedStatisticEntityUnserialized;
  vri_health: VriCalculatedStatisticEntityUnserialized;
  euro_per_pig: VriCalculatedStatisticEntityUnserialized;
  approved_carcass: VriCalculatedStatisticEntityUnserialized;
  pleurisy: VriCalculatedStatisticEntityUnserialized;
  inflamed_skin: VriCalculatedStatisticEntityUnserialized;
  inflamed_leg: VriCalculatedStatisticEntityUnserialized;
  approved_organ: VriCalculatedStatisticEntityUnserialized;
  liver_afg: VriCalculatedStatisticEntityUnserialized;
  lung_afg: VriCalculatedStatisticEntityUnserialized;
  healthy: VriCalculatedStatisticEntityUnserialized;
  average_nutrient_ew: VriCalculatedStatisticEntityUnserialized;
  removed_pigs_count: VriCalculatedStatisticEntityUnserialized;
  average_epg_weight: VriCalculatedStatisticEntityUnserialized;
  average_birth_weight: VriCalculatedStatisticEntityUnserialized;
  average_round_length_days: VriCalculatedStatisticEntityUnserialized;
  epg: VriCalculatedStatisticEntityUnserialized;

  business_total: {
    vri_total: number;
    epg_euro_per_pig: number;
    balance_with_respect_to_base: number;
    euro_per_pig: number;
  };
};

export type VriCalculatedStatisticsResourceSerialized = {
  vriTotal: VriCalculatedStatisticEntitySerialized;
  lapSpeed: VriCalculatedStatisticEntitySerialized;
  deliveredAnimals: VriCalculatedStatisticEntitySerialized;
  delivered: VriCalculatedStatisticEntitySerialized;
  vriEpg: VriCalculatedStatisticEntitySerialized;
  epgEuroPerPig: VriCalculatedStatisticEntitySerialized;
  growth: VriCalculatedStatisticEntitySerialized;
  feedIntakePerDay: VriCalculatedStatisticEntitySerialized;
  ewConversion: VriCalculatedStatisticEntitySerialized;
  layingBigWeight: VriCalculatedStatisticEntitySerialized;
  loss: VriCalculatedStatisticEntitySerialized;
  vriBalanceWithRespectToBase: VriCalculatedStatisticEntitySerialized;
  balanceWithRespectToBase: VriCalculatedStatisticEntitySerialized;
  averageWeight: VriCalculatedStatisticEntitySerialized;
  averageWeightCorrection: VriCalculatedStatisticEntitySerialized;
  averageFatThickness: VriCalculatedStatisticEntitySerialized;
  averageBeaconCorrection: VriCalculatedStatisticEntitySerialized;
  averageMuscleThickness: VriCalculatedStatisticEntitySerialized;
  averageMuscleCorrection: VriCalculatedStatisticEntitySerialized;
  averageMeat: VriCalculatedStatisticEntitySerialized;
  averageMeatCorrection: VriCalculatedStatisticEntitySerialized;
  averageClassificationCorrection: VriCalculatedStatisticEntitySerialized;
  averageSexAllowance: VriCalculatedStatisticEntitySerialized;
  averageConceptSurcharge: VriCalculatedStatisticEntitySerialized;
  pigsInDraft: VriCalculatedStatisticEntitySerialized;
  vriHealth: VriCalculatedStatisticEntitySerialized;
  euroPerPig: VriCalculatedStatisticEntitySerialized;
  approvedCarcass: VriCalculatedStatisticEntitySerialized;
  pleurisy: VriCalculatedStatisticEntitySerialized;
  inflamedSkin: VriCalculatedStatisticEntitySerialized;
  inflamedLeg: VriCalculatedStatisticEntitySerialized;
  approvedOrgan: VriCalculatedStatisticEntitySerialized;
  liverAfg: VriCalculatedStatisticEntitySerialized;
  lungAfg: VriCalculatedStatisticEntitySerialized;
  healthy: VriCalculatedStatisticEntitySerialized;
  averageNutrientEw: VriCalculatedStatisticEntitySerialized;
  removedPigsCount: VriCalculatedStatisticEntitySerialized;
  averageEpgWeight: VriCalculatedStatisticEntitySerialized;
  averageBirthWeight: VriCalculatedStatisticEntitySerialized;
  averageRoundLengthDays: VriCalculatedStatisticEntitySerialized;
  epg: VriCalculatedStatisticEntitySerialized;

  businessTotal: {
    vriTotal: number;
    epgEuroPerPig: number;
    balanceWithRespectToBase: number;
    euroPerPig: number;
  };
};

export type VriCalculatedConstantsUnserialized = {
  growth: number;
  feed_intake_per_day: number;
  ew_conversion: number;
  laying_big_weight: number;
  loss: number;
  gender_weight: number;
  bacon_weight: number;
  muscle_thickness: number;
  meat_percent: number;
  epg: number;
  average_weight: number;
  average_weight_correction: number;
  average_fat_thickness: number;
  average_beacon_correction: number;
  average_muscle_thickness: number;
  average_muscle_correction: number;
  average_meat: number;
  average_meat_correction: number;
  average_classification_correction: number;
  average_sex_allowance: number;
  average_concept_surcharge: number;
  pigs_in_draft: number;
  balance_with_respect_to_base: number;
  approved_carcass: number;
  pleurisy: number;
  inflamed_skin: number;
  inflamed_leg: number;
  approved_organ: number;
  liver_afg: number;
  lung_afg: number;
  healthy: number;
  euro_per_pig: number;
  vri_total: number;
  vri_epg: number;
  vri_balance: number;
  vri_health: number;
  average_nutrient_ew: number;
  removed_pigs_count: number;
  average_epg_weight: number;
  average_birth_weight: number;
  average_round_length_days: number;
};

export type VriCalculatedConstantsSerialized = {
  growth: number;
  feedIntakePerDay: number;
  ewConversion: number;
  layingBigWeight: number;
  loss: number;
  genderWeight: number;
  baconWeight: number;
  muscleThickness: number;
  meatPercent: number;
  epg: number;
  averageWeight: number;
  averageWeightCorrection: number;
  averageFatThickness: number;
  averageBeaconCorrection: number;
  averageMuscleThickness: number;
  averageMuscleCorrection: number;
  averageMeat: number;
  averageMeatCorrection: number;
  averageClassificationCorrection: number;
  averageSexAllowance: number;
  averageConceptSurcharge: number;
  pigsInDraft: number;
  balanceWithRespectToBase: number;
  approvedCarcass: number;
  pleurisy: number;
  inflamedSkin: number;
  inflamedLeg: number;
  approvedOrgan: number;
  liverAfg: number;
  lungAfg: number;
  healthy: number;
  euroPerPig: number;
  vriTotal: number;
  vriEpg: number;
  vriBalance: number;
  vriHealth: number;
  averageNutrientEw: number;
  removedPigsCount: number;
  averageEpgWeight: number;
  averageBirthWeight: number;
  averageRoundLengthDays: number;
};

export type VriCalculatedReportsConstantsUnserialized = {
  top_20: VriCalculatedConstantsUnserialized;
  avg: VriCalculatedConstantsUnserialized;
};

export type VriCalculatedReportsConstantsSerialized = {
  top20: VriCalculatedConstantsSerialized;
  avg: VriCalculatedConstantsSerialized;
};

export enum VriCalculatedSerialized {
  vri = 'vri',
}

export enum VriCalculatedUnserialized {
  vri = 'vri',
}

export enum VriCalculatedSerializedHeaders {
  userPassportId = 'userPassportId',
  ubn = 'ubn',
  vionRelationNumber = 'vionRelationNumber',
  name = 'name',
  averageMeatCorrection = 'averageMeatCorrection',
  averageMuscleCorrection = 'averageMuscleCorrection',
  averageBeaconCorrection = 'averageBeaconCorrection',
  averageWeightCorrection = 'averageWeightCorrection',
  averageSexAllowance = 'averageSexAllowance',
  averageConceptSurcharge = 'averageConceptSurcharge',
  averageMeat = 'averageMeat',
  averageWeight = 'averageWeight',
  averageFatThickness = 'averageFatThickness',
  averageMuscleThickness = 'averageMuscleThickness',
  totalPigsCount = 'totalPigsCount',
  approvedCarcassCount = 'approvedCarcassCount',
  calculatedReportId = 'calculatedReportId',
  carcassCode1 = 'carcassCode1',
  carcassCode2 = 'carcassCode2',
  carcassCode3 = 'carcassCode3',
  carcassCode4 = 'carcassCode4',
  carcassCode5 = 'carcassCode5',
  carcassCode6 = 'carcassCode6',
  carcassCode7 = 'carcassCode7',
  approvedOrganCount = 'approvedOrganCount',
  organCode1 = 'organCode1',
  organCode2 = 'organCode2',
  organCode3 = 'organCode3',
  organCode4 = 'organCode4',
  organCode5 = 'organCode5',
  organCode6 = 'organCode6',
  healthyCount = 'healthyCount',
  averageClassificationCorrection = 'averageClassificationCorrection',
  balanceWithRespectToBase = 'balanceWithRespectToBase',
  pigsInDraft = 'pigsInDraft',
  healthy = 'healthy',
  longAfgCount = 'longAfgCount',
  longAfg = 'longAfg',
  leverAfgCount = 'leverAfgCount',
  leverAfg = 'leverAfg',
  approvedOrgan = 'approvedOrgan',
  liverImpairedCount = 'liverImpairedCount',
  liverImpaired = 'liverImpaired',
  inflamedLegCount = 'inflamedLegCount',
  inflamedLeg = 'inflamedLeg',
  inflamedSkinCount = 'inflamedSkinCount',
  inflamedSkin = 'inflamedSkin',
  pleurisyCount = 'pleurisyCount',
  pleurisy = 'pleurisy',
  approvedCarcass = 'approvedCarcass',
  notJudjeableCount = 'notJudjeableCount',
  notJudjeable = 'notJudjeable',
  euroPerPig = 'euroPerPig',
  vriHealth = 'vriHealth',
  vriClassification = 'vriClassification',
  total = 'total',
  vriSerialNumber = 'vriSerialNumber',
  epgSerialNumber = 'epgSerialNumber',
  feedIntakePerDay = 'feedIntakePerDay',
  averageNutrientEw = 'averageNutrientEw',
  removedPigsCount = 'removedPigsCount',
  averageEpgWeight = 'averageEpgWeight',
  averageBirthWeight = 'averageBirthWeight',
  averageRoundLengthDays = 'averageRoundLengthDays',
  loss = 'loss',
  ewConversion = 'ewConversion',
  growth = 'growth',
  epg = 'epg',
  vriEpg = 'vriEpg',
  // resource
  totalMin = 'totalMin',
  totalMax = 'totalMax',
  epgMin = 'epgMin',
  epgMax = 'epgMax',
  euroPerPigMin = 'euroPerPigMin',
  euroPerPigMax = 'euroPerPigMax',
  balanceWithRespectToBaseMin = 'balanceWithRespectToBaseMin',
  balanceWithRespectToBaseMax = 'balanceWithRespectToBaseMax',
  totals = 'totals',
  // statistics
  vriTotal = 'vriTotal',
  lapSpeed = 'lapSpeed',
  deliveredAnimals = 'deliveredAnimals',
  delivered = 'delivered',
  vriBalanceWithRespectToBase = 'vriBalanceWithRespectToBase',
  businessTotal = 'businessTotal',
  yourScore = 'yourScore',
  averageTop20Score = 'averageTop20Score',
  difference = 'difference',
  epgEuroPerPig = 'epgEuroPerPig',
  // constants
  genderWeight = 'genderWeight',
  baconWeight = 'baconWeight',
  meatPercent = 'meatPercent',
  vriBalance = 'vriBalance',
  top20 = 'top20',
  avg = 'avg',
  id = 'id',
  status = 'status',
}

export enum VriCalculatedUnserializedHeaders {
  userPassportId = 'user_passport_id',
  ubn = 'ubn',
  vionRelationNumber = 'vion_relation_number',
  name = 'name',
  averageMeatCorrection = 'average_meat_correction',
  averageMuscleCorrection = 'average_muscle_correction',
  averageBeaconCorrection = 'average_beacon_correction',
  averageWeightCorrection = 'average_weight_correction',
  averageSexAllowance = 'average_sex_allowance',
  averageConceptSurcharge = 'average_concept_surcharge',
  averageMeat = 'average_meat',
  averageWeight = 'average_weight',
  averageFatThickness = 'average_fat_thickness',
  averageMuscleThickness = 'average_muscle_thickness',
  totalPigsCount = 'total_pigs_count',
  approvedCarcassCount = 'approved_carcass_count',
  calculatedReportId = 'calculated_report_id',
  carcassCode1 = 'carcass_code_1',
  carcassCode2 = 'carcass_code_2',
  carcassCode3 = 'carcass_code_3',
  carcassCode4 = 'carcass_code_4',
  carcassCode5 = 'carcass_code_5',
  carcassCode6 = 'carcass_code_6',
  carcassCode7 = 'carcass_code_7',
  approvedOrganCount = 'approved_organ_count',
  organCode1 = 'organ_code_1',
  organCode2 = 'organ_code_2',
  organCode3 = 'organ_code_3',
  organCode4 = 'organ_code_4',
  organCode5 = 'organ_code_5',
  organCode6 = 'organ_code_6',
  healthyCount = 'healthy_count',
  averageClassificationCorrection = 'average_classification_correction',
  balanceWithRespectToBase = 'balance_with_respect_to_base',
  pigsInDraft = 'pigs_in_draft',
  healthy = 'healthy',
  longAfgCount = 'long_afg_count',
  longAfg = 'long_afg',
  leverAfgCount = 'lever_afg_count',
  leverAfg = 'lever_afg',
  approvedOrgan = 'approved_organ',
  liverImpairedCount = 'liver_impaired_count',
  liverImpaired = 'liver_impaired',
  inflamedLegCount = 'inflamed_leg_count',
  inflamedLeg = 'inflamed_leg',
  inflamedSkinCount = 'inflamed_skin_count',
  inflamedSkin = 'inflamed_skin',
  pleurisyCount = 'pleurisy_count',
  pleurisy = 'pleurisy',
  approvedCarcass = 'approved_carcass',
  notJudjeableCount = 'not_judjeable_count',
  notJudjeable = 'not_judjeable',
  euroPerPig = 'euro_per_pig',
  vriHealth = 'vri_health',
  vriClassification = 'vri_classification',
  total = 'total',
  vriSerialNumber = 'vri_serial_number',
  epgSerialNumber = 'epg_serial_number',
  feedIntakePerDay = 'feed_intake_per_day',
  averageNutrientEw = 'average_nutrient_ew',
  removedPigsCount = 'removed_pigs_count',
  averageEpgWeight = 'average_epg_weight',
  averageBirthWeight = 'average_birth_weight',
  averageRoundLengthDays = 'average_round_length_days',
  loss = 'loss',
  ewConversion = 'ew_conversion',
  growth = 'growth',
  epg = 'epg',
  vriEpg = 'vri_epg',
  // resource
  totalMin = 'total_min',
  totalMax = 'total_max',
  epgMin = 'epg_min',
  epgMax = 'epg_max',
  euroPerPigMin = 'euro_per_pig_min',
  euroPerPigMax = 'euro_per_pig_max',
  balanceWithRespectToBaseMin = 'balance_with_respect_to_base_min',
  balanceWithRespectToBaseMax = 'balance_with_respect_to_base_max',
  totals = 'totals',
  // statistics
  vriTotal = 'vri_total',
  lapSpeed = 'lap_speed',
  deliveredAnimals = 'delivered_animals',
  delivered = 'delivered',
  vriBalanceWithRespectToBase = 'vri_balance_with_respect_to_base',
  businessTotal = 'business_total',
  yourScore = 'your_score',
  averageTop20Score = 'average_top_20_score',
  difference = 'difference',
  epgEuroPerPig = 'epg_euro_per_pig',
  // constants
  genderWeight = 'gender_weight',
  baconWeight = 'bacon_weight',
  meatPercent = 'meat_percent',
  vriBalance = 'vri_balance',
  top20 = 'top_20',
  avg = 'avg',
  id = 'id',
  status = 'status',
}

export const VriCalculatedFormatters: Record<string, (value: number) => string> = {
  vriSerialNumber: roundToInteger,
  total: roundToInteger,
  vriEpg: roundTo2Digits,
  epgSerialNumber: roundToInteger,
  growth: roundTo2DigitsFloating,
  feedIntakePerDay: roundTo2DigitsFloating,
  ewConversion: roundTo2DigitsFloating,
  layingBigWeight: roundTo1DigitsFloating,
  loss: roundTo2DigitsFloating,
  epg: roundTo2DigitsFloating,
  vriClassification: roundToInteger,
  epgOrderNumber: roundToInteger,
  balanceWithRespectToBase: roundTo2Digits,
  averageWeight: roundTo2Digits,
  averageWeightCorrection: roundTo2Digits,
  averageFatThickness: roundTo2Digits,
  averageBeaconCorrection: roundTo2Digits,
  averageMuscleThickness: roundTo2Digits,
  averageMuscleCorrection: roundTo2Digits,
  averageMeat: roundTo2Digits,
  averageMeatCorrection: roundTo2Digits,
  averageClassificationCorrection: roundTo2Digits,
  averageSexAllowance: roundTo2Digits,
  averageConceptSurcharge: roundTo2Digits,
  pigsInDraft: formatPercentsTo1Digits,
  vriHealth: roundTo2Digits,
  epgNumber: roundToInteger,
  euroPerPig: suffixFormatter(roundTo2Digits, '', '€'),
  approvedCarcass: formatPercentsTo1Digits,
  pleurisy: formatPercentsTo1Digits,
  inflamedSkin: formatPercentsTo1Digits,
  inflamedLeg: formatPercentsTo1Digits,
  approvedOrgan: formatPercentsTo1Digits,
  leverAfg: formatPercentsTo1Digits,
  liverAfg: formatPercentsTo1Digits,
  longAfg: formatPercentsTo1Digits,
  lungAfg: formatPercentsTo1Digits,
  healthy: formatPercentsTo1Digits,
  averageNutrientEw: roundTo2DigitsFloating,
  removedPigsCount: roundTo2DigitsFloating,
  averageEpgWeight: roundTo2DigitsFloating,
  averageBirthWeight: roundTo2DigitsFloating,
  averageRoundLengthDays: roundTo2DigitsFloating,
  // statistics
  vriTotal: roundTo2Digits,
  lapSpeed: roundTo2Digits,
  deliveredAnimals: roundTo2Digits,
  delivered: roundTo2Digits,
};

// FUNCTIONS FOR VRI TABLES
export const customVriCalculatedRenderByFieldName = (field: keyof typeof VriCalculatedSerializedHeaders) => (
  data: Record<string, any>,
) => {
  const value = data[VriCalculatedSerializedHeaders[field]];
  return VriCalculatedFormatters[field] ? VriCalculatedFormatters[field](value) : value;
};

export const customVriCalculatedFilterAndSearchByFieldName = (field: keyof typeof VriCalculatedSerializedHeaders) => (
  searchedValue: string,
  data: Record<string, any>,
) => {
  const cellValue = customVriCalculatedRenderByFieldName(field)(data);
  return String(cellValue).indexOf(searchedValue) != -1;
};

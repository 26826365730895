import { IntlKeys } from 'lib/localization/keys/__keys';

enum GreenhouseGasEmissionOriginal {
  GreenhouseGasEmission = 'Greenhouse gas emissions',
}

enum GreenhouseGasEmissionSerialized {
  GreenhouseGasEmission = 'GreenhouseGasEmission',
}

enum GreenhouseGasEmissionUnserialized {
  GreenhouseGasEmission = 'GreenhouseGasEmission',
}

enum GreenhouseGasEmissionOriginalHeaders {
  'country' = 'Country',
  'location' = 'Location',
  'category' = 'Category',
  'account' = 'Account',
  'extraExternalId' = 'Custom1',
  'name' = 'Name',
  'inputUnit' = 'Input unit',
  'conversionFactor' = 'Conversion Factor',
  'conversionFormula' = 'Conversion Formula',
  'emissionFactors' = 'Emission factors (kg CO2-eq. of kg CH4 per eenheid)',
  'co2Unit' = 'Unit (kg CO2-eq)',
}

enum GreenhouseGasEmissionSerializedHeaders {
  'country' = 'country',
  'location' = 'location',
  'category' = 'category',
  'account' = 'account',
  'extraExternalId' = 'extraExternalId',
  'name' = 'name',
  'inputUnit' = 'inputUnit',
  'conversionFactor' = 'conversionFactor',
  'conversionFormula' = 'conversionFormula',
  'emissionFactors' = 'emissionFactors',
  'co2Unit' = 'co2Unit',
}
enum GreenhouseGasEmissionUnserializedHeaders {
  'country' = 'country',
  'location' = 'location',
  'category' = 'category',
  'account' = 'account',
  'extraExternalId' = 'extra_external_id',
  'name' = 'name',
  'inputUnit' = 'unit',
  'conversionFactor' = 'conversion_factor',
  'conversionFormula' = 'conversion_formula',
  'emissionFactors' = 'emission_factors',
  'co2Unit' = 'co2_unit',
}

export const GREENHOUSE_GAS_EMISSION = {
  [GreenhouseGasEmissionUnserialized.GreenhouseGasEmission]: {
    original: GreenhouseGasEmissionOriginal.GreenhouseGasEmission,
    serialized: GreenhouseGasEmissionSerialized.GreenhouseGasEmission,
    unserialized: GreenhouseGasEmissionUnserialized.GreenhouseGasEmission,
    view: IntlKeys.referenceTablesGreenhouseGasEmission, //
    viewName: 'Greenhouse gas emissions',

    columns: {
      [GreenhouseGasEmissionSerializedHeaders.country]: {
        original: GreenhouseGasEmissionOriginalHeaders.country,
        serialized: GreenhouseGasEmissionSerializedHeaders.country,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.country,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionCountry,
      },
      [GreenhouseGasEmissionSerializedHeaders.location]: {
        original: GreenhouseGasEmissionOriginalHeaders.location,
        serialized: GreenhouseGasEmissionSerializedHeaders.location,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.location,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionLocation,
      },
      [GreenhouseGasEmissionSerializedHeaders.category]: {
        original: GreenhouseGasEmissionOriginalHeaders.category,
        serialized: GreenhouseGasEmissionSerializedHeaders.category,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.category,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionCategory,
      },
      [GreenhouseGasEmissionSerializedHeaders.account]: {
        original: GreenhouseGasEmissionOriginalHeaders.account,
        serialized: GreenhouseGasEmissionSerializedHeaders.account,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.account,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionAccount,
      },
      [GreenhouseGasEmissionSerializedHeaders.extraExternalId]: {
        original: GreenhouseGasEmissionOriginalHeaders.extraExternalId,
        serialized: GreenhouseGasEmissionSerializedHeaders.extraExternalId,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.extraExternalId,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionExtraExternalId,
      },
      [GreenhouseGasEmissionSerializedHeaders.name]: {
        original: GreenhouseGasEmissionOriginalHeaders.name,
        serialized: GreenhouseGasEmissionSerializedHeaders.name,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.name,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionName,
      },
      [GreenhouseGasEmissionSerializedHeaders.inputUnit]: {
        original: GreenhouseGasEmissionOriginalHeaders.inputUnit,
        serialized: GreenhouseGasEmissionSerializedHeaders.inputUnit,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.inputUnit,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionEmissionFactor,
      },
      [GreenhouseGasEmissionSerializedHeaders.conversionFactor]: {
        original: GreenhouseGasEmissionOriginalHeaders.conversionFactor,
        serialized: GreenhouseGasEmissionSerializedHeaders.conversionFactor,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.conversionFactor,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionConversionFactor,
      },
      [GreenhouseGasEmissionSerializedHeaders.conversionFormula]: {
        original: GreenhouseGasEmissionOriginalHeaders.conversionFormula,
        serialized: GreenhouseGasEmissionSerializedHeaders.conversionFormula,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.conversionFormula,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionUnit,
      },
      [GreenhouseGasEmissionSerializedHeaders.emissionFactors]: {
        original: GreenhouseGasEmissionOriginalHeaders.emissionFactors,
        serialized: GreenhouseGasEmissionSerializedHeaders.emissionFactors,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.emissionFactors,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionEmissionFactors,
      },
      [GreenhouseGasEmissionSerializedHeaders.co2Unit]: {
        original: GreenhouseGasEmissionOriginalHeaders.co2Unit,
        serialized: GreenhouseGasEmissionSerializedHeaders.co2Unit,
        unserialized: GreenhouseGasEmissionUnserializedHeaders.co2Unit,
        view: IntlKeys.referenceTablesGreenhouseGasEmissionCo2Unit,
      },
    },
  },
};

import { createGenerateClassName } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import './fonts.css';

import { COLORS, colorsThemes } from './colors';

const PRIMARY_COLOR = '#123274';
const SECONDARY_COLOR = '#F50057';

export const classNamesGenerator = createGenerateClassName({});

const colors = {
  primary: PRIMARY_COLOR,
  vionMain: {
    900: '#05225e',
    800: PRIMARY_COLOR,
    700: '#1a3c80',
    600: '#24458c',
    500: '#2b4d94',
    400: '#4d66a2',
    300: '#6d81b2',
    200: '#96a4c8',
    100: '#bfc7de',
    50: '#e6e9f1',
  },
  secondary: SECONDARY_COLOR,
  white: '#FFF',
  background: {
    grey: '#f2f2f2',
    lime: '#99C31C',
  },
  border: {
    greyLight: '#E0E0E0',
    grey: '#d1d1d1',
    greyDark: '#9E9E9E',
  },
  text: {
    greyLight: '#969696',
    grey: '#666666',
    greenDark: '#374807',
    error: '#f44336',
  },
};

const dimensions = {
  pagePadding: 10,
  borderRadius: 8,
};

const breakpoints = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};
const media = (direction: 'up' | 'down') =>
  (Object.keys(breakpoints) as (keyof typeof breakpoints)[]).reduce((acc, label) => {
    acc[label] = `@media (${direction === 'down' ? 'max' : 'min'}-width: ${breakpoints[label]}px)`;
    return acc;
  }, {} as { [K in keyof typeof breakpoints]: string });
const theme = {
  breakpoints,
  media: {
    up: media('up'),
    down: media('down'),
  },
  palette: {
    divider: 'red',
  },
  dimensions,
  colors,
};

export type MaterialTheme = Theme & { colors: typeof colors; dimensions: typeof dimensions };

export const getTheme = (colorThemeName?: keyof typeof colorsThemes) => {
  const colorTheme = colorThemeName ? colorsThemes[colorThemeName] : colorsThemes.blue;

  return {
    palette: colorTheme,
    colors: COLORS,
  };
};

export const GlobalStyles = createGlobalStyle<{ theme: typeof theme }>`
  ${normalize}
  
  #root {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background: ${colors.background.grey};
  }
  
   body * {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }

  h1 {
    font-size: 2.5em;
    margin: 0 0 30px 0;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 1em;
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  input[type='number'] {
    -moz-appearance: textfield;
  }
  
  button {
    background: none;
  }

  body {
    margin: 0;
  }

  code {}
`;

export default theme;

export type CustomTheme = typeof theme;

enum HeadersOriginal {
  Database = 'database',
}
enum HeadersSerialized {
  Database = 'database',
}
enum HeadersUnserialized {
  Database = 'database',
}
enum HeadersView {
  Database = 'Database',
}

enum DatabaseOriginalHeaders {
  'vriTotalNumber' = 'VRI TOTAAL VOLGNUMMER',
  'ubn' = 'UBN',
  'name' = 'Naam',
  'vriTotal' = 'VRI TOTAAL',
  'vriEgp' = 'VRI EPG',
  'orderNumberEPG' = `Volgnummer`,
  'growth' = 'GROEI (gr/dag)',
  'feedIntakePerDay' = `VOEROPNAME (kg/dag)`,
  'ewConversion' = `EW-CONVERSIE`,
  'weightSupport' = `OPLEG GEWICHT BIG (kg)`,
  'failurePercent' = 'UITVAL (%)',
  'epg' = 'EPG (€)',
  'vriClassification' = 'VRI Classificatie',
  // 'epgSequenceNumber' = `epg Volg-nummer`,
  'basicBalance' = 'Saldo t.o.v. Basis (ct/kg)',
  'byWeight' = `Gem geslachtgewicht (kg)`,
  'avgWeightCorrection' = 'Gem gewicht correctie (ct/kg)',
  'saveSpekdikte' = 'Gem. spekdikte (mm)',
  'baconCorrection' = 'Gem spek correctie (ct/kg)',
  'baconWidth' = 'Gem spierdikte (mm)',
  'muscleCorrection' = 'Gem spier correctie (ct/kg)',
  'preciousMeat' = 'Gem vlees (%)',
  'preciousMeatCorrection' = 'Gem vlees% correctie (ct/kg)',
  'gemClassificationCorrection' = `Gem classificatie correctie (ct/kg)`,
  'gemSexeSurChange' = 'Gem sexe toeslag (ct/kg)',
  'gemConceptSurchange' = 'Gem concept toeslag (ct/kg)',
  'percentOfPigsInDraft' = 'Varkens in concept (%)',
  'vriHealth' = 'VRI gezondheid',
  // 'epgSequenceNumber_' = 'epg volgnummer',
  'euroPerPiglet' = 'Gezondheidssaldo (€ per varken)',
  'approved' = 'Karkas Goedgekeurd',
  'pleuritis' = 'Pleuritis',
  'firesSkin' = 'Ontst. huid',
  'firesLeg' = 'Ontst. poot',
  'approvedOrgan' = 'Orgaan Goed gekeurd', /// PROBLEMS HERE
  'leverAfg' = 'Lever afg.',
  'longAfg' = 'Long afg.',
  'healthy' = 'Geen Karkas en Orgaan afwijkingen (%)',
  'endManufacturer' = 'EINDBEER',
  'feedingSystem' = 'VOER SYSTEEM',
}

enum DatabaseViewHeaders {
  'vriTotalNumber' = 'VRI TOTAAL VOLGNUMMER',
  'ubn' = 'UBN',
  'name' = 'Naam',
  'vriTotal' = 'VRI TOTAAL',
  'vriEgp' = 'VRI EPG',
  'orderNumberEPG' = `Volgnummer`,
  'growth' = 'GROEI (gr/dag)',
  'feedIntakePerDay' = `VOEROPNAME (kg/dag)`,
  'ewConversion' = `EW-CONVERSIE`,
  'weightSupport' = `OPLEG GEWICHT BIG (kg)`,
  'failurePercent' = 'UITVAL (%)',
  'epg' = 'EPG (€)',
  'vriClassification' = 'VRI Classificatie',
  // 'epgSequenceNumber' = `epg Volg-nummer`,
  'basicBalance' = 'Saldo t.o.v. Basis (ct/kg)',
  'byWeight' = `Gem geslachtgewicht (kg)`,
  'avgWeightCorrection' = 'Gem gewicht correctie (ct/kg)',
  'saveSpekdikte' = 'Gem. spekdikte (mm)',
  'baconCorrection' = 'Gem spek correctie (ct/kg)',
  'baconWidth' = 'Gem spierdikte (mm)',
  'muscleCorrection' = 'Gem spier correctie (ct/kg)',
  'preciousMeat' = 'Gem vlees (%)',
  'preciousMeatCorrection' = 'Gem vlees% correctie (ct/kg)',
  'gemClassificationCorrection' = `Gem classificatie correctie (ct/kg)`,
  'gemSexeSurChange' = 'Gem sexe toeslag (ct/kg)',
  'gemConceptSurchange' = 'Gem concept toeslag (ct/kg)',
  'percentOfPigsInDraft' = 'Varkens in concept (%)',
  'vriHealth' = 'VRI gezondheid',
  // 'epgSequenceNumber_' = 'epg volgnummer',
  'euroPerPiglet' = 'Gezondheidssaldo (€ per varken)',
  'approved' = 'Karkas Goedgekeurd',
  'pleuritis' = 'Pleuritis',
  'firesSkin' = 'Ontst. huid',
  'firesLeg' = 'Ontst. poot',
  'approvedOrgan' = 'Orgaan Goed gekeurd', /// PROBLEMS HERE
  'leverAfg' = 'Lever afg.',
  'longAfg' = 'Long afg.',
  'healthy' = 'Geen Karkas en Orgaan afwijkingen (%)',
  'endManufacturer' = 'EINDBEER',
  'feedingSystem' = 'VOER SYSTEEM',
}

enum DatabaseSerializerHeaders {
  'vriTotalNumber' = 'vriTotalNumber',
  'ubn' = 'ubn',
  'name' = 'name',
  'vriTotal' = 'vriTotal',
  'vriEgp' = 'vriEgp',
  'orderNumberEPG' = 'orderNumberEPG',
  'growth' = 'growth',
  'feedIntakePerDay' = 'feedIntakePerDay',
  'ewConversion' = 'ewConversion',
  'weightSupport' = 'weightSupport',
  'failurePercent' = 'failurePercent',
  'epg' = 'epg',
  'vriClassification' = 'vriClassification',
  'basicBalance' = 'basicBalance',
  'byWeight' = 'byWeight',
  'avgWeightCorrection' = 'avgWeightCorrection',
  'saveSpekdikte' = 'saveSpekdikte',
  'baconCorrection' = 'baconCorrection',
  'baconWidth' = 'baconWidth',
  'muscleCorrection' = 'muscleCorrection',
  'preciousMeat' = 'preciousMeat',
  'preciousMeatCorrection' = 'preciousMeatCorrection',
  'gemClassificationCorrection' = 'gemClassificationCorrection',
  'gemSexeSurChange' = 'gemSexeSurChange',
  'gemConceptSurchange' = 'gemConceptSurchange',
  'percentOfPigsInDraft' = 'percentOfPigsInDraft',
  'vriHealth' = 'vriHealth',
  'euroPerPiglet' = 'euroPerPiglet',
  'approved' = 'approved',
  'pleuritis' = 'pleuritis',
  'firesSkin' = 'firesSkin',
  'firesLeg' = 'firesLeg',
  'approvedOrgan' = 'approvedOrgan', /// PROBLEMS HERE
  'leverAfg' = 'leverAfg',
  'longAfg' = 'longAfg',
  'healthy' = 'healthy',
  'endManufacturer' = 'endManufacturer',
  'feedingSystem' = 'feedingSystem',
}
enum DatabaseUnserializedHeaders {
  'vriTotalNumber' = 'vri_serial_number',
  'ubn' = 'ubn',
  'name' = 'name',
  'vriTotal' = 'total',
  'vriEgp' = 'vri_epg',
  'orderNumberEPG' = 'epg_serial_number',
  'growth' = 'growth',
  'feedIntakePerDay' = 'feed_intake_per_day',
  'ewConversion' = 'ew_conversion',
  'weightSupport' = 'laying_big_weight',
  'failurePercent' = 'loss',
  'epg' = 'epg',
  'vriClassification' = 'vri_classification',
  'basicBalance' = 'balance_with_respect_to_base',
  'byWeight' = 'average_weight',
  'avgWeightCorrection' = 'average_weight_correction',
  'saveSpekdikte' = 'average_fat_thickness',
  'baconCorrection' = 'average_beacon_correction',
  'baconWidth' = 'average_muscle_thickness',
  'muscleCorrection' = 'average_muscle_correction',
  'preciousMeat' = 'average_meat',
  'preciousMeatCorrection' = 'average_meat_correction',
  'gemClassificationCorrection' = 'average_classification_correction',
  'gemSexeSurChange' = 'average_sex_allowance',
  'gemConceptSurchange' = 'average_concept_surcharge',
  'percentOfPigsInDraft' = 'pigs_in_draft',
  'vriHealth' = 'vri_health',
  'euroPerPiglet' = 'euro_per_pig',
  'approved' = 'approved_carcass',
  'pleuritis' = 'pleurisy',
  'firesSkin' = 'inflamed_skin',
  'firesLeg' = 'inflamed_leg',
  'approvedOrgan' = 'approved_organ', /// PROBLEMS HERE
  'leverAfg' = 'lever_afg',
  'longAfg' = 'long_afg',
  'healthy' = 'healthy',
  'endManufacturer' = 'end_manufacturer',
  'feedingSystem' = 'feeding_system',
}

export const VRI_DOCUMENT_FIELDS = {
  [HeadersSerialized.Database]: {
    original: HeadersOriginal.Database,
    serialized: HeadersSerialized.Database,
    unserialized: HeadersUnserialized.Database,
    view: HeadersView.Database,
    viewName: 'Database',
    columns: {
      [DatabaseSerializerHeaders.vriTotalNumber]: {
        original: DatabaseOriginalHeaders.vriTotalNumber,
        serialized: DatabaseSerializerHeaders.vriTotalNumber,
        unserialized: DatabaseUnserializedHeaders.vriTotalNumber,
        view: DatabaseViewHeaders.vriTotalNumber,
      },
      [DatabaseSerializerHeaders.ubn]: {
        original: DatabaseOriginalHeaders.ubn,
        serialized: DatabaseSerializerHeaders.ubn,
        unserialized: DatabaseUnserializedHeaders.ubn,
        view: DatabaseViewHeaders.ubn,
      },
      [DatabaseSerializerHeaders.name]: {
        original: DatabaseOriginalHeaders.name,
        serialized: DatabaseSerializerHeaders.name,
        unserialized: DatabaseUnserializedHeaders.name,
        view: DatabaseViewHeaders.name,
      },
      [DatabaseSerializerHeaders.vriTotal]: {
        original: DatabaseOriginalHeaders.vriTotal,
        serialized: DatabaseSerializerHeaders.vriTotal,
        unserialized: DatabaseUnserializedHeaders.vriTotal,
        view: DatabaseViewHeaders.vriTotal,
      },
      [DatabaseSerializerHeaders.vriEgp]: {
        original: DatabaseOriginalHeaders.vriEgp,
        serialized: DatabaseSerializerHeaders.vriEgp,
        unserialized: DatabaseUnserializedHeaders.vriEgp,
        view: DatabaseViewHeaders.vriEgp,
      },
      [DatabaseSerializerHeaders.orderNumberEPG]: {
        original: DatabaseOriginalHeaders.orderNumberEPG,
        serialized: DatabaseSerializerHeaders.orderNumberEPG,
        unserialized: DatabaseUnserializedHeaders.orderNumberEPG,
        view: DatabaseViewHeaders.orderNumberEPG,
      },
      [DatabaseSerializerHeaders.growth]: {
        original: DatabaseOriginalHeaders.growth,
        serialized: DatabaseSerializerHeaders.growth,
        unserialized: DatabaseUnserializedHeaders.growth,
        view: DatabaseViewHeaders.growth,
      },
      [DatabaseSerializerHeaders.feedIntakePerDay]: {
        original: DatabaseOriginalHeaders.feedIntakePerDay,
        serialized: DatabaseSerializerHeaders.feedIntakePerDay,
        unserialized: DatabaseUnserializedHeaders.feedIntakePerDay,
        view: DatabaseViewHeaders.feedIntakePerDay,
      },
      [DatabaseSerializerHeaders.ewConversion]: {
        original: DatabaseOriginalHeaders.ewConversion,
        serialized: DatabaseSerializerHeaders.ewConversion,
        unserialized: DatabaseUnserializedHeaders.ewConversion,
        view: DatabaseViewHeaders.ewConversion,
      },
      [DatabaseSerializerHeaders.weightSupport]: {
        original: DatabaseOriginalHeaders.weightSupport,
        serialized: DatabaseSerializerHeaders.weightSupport,
        unserialized: DatabaseUnserializedHeaders.weightSupport,
        view: DatabaseViewHeaders.weightSupport,
      },
      [DatabaseSerializerHeaders.failurePercent]: {
        original: DatabaseOriginalHeaders.failurePercent,
        serialized: DatabaseSerializerHeaders.failurePercent,
        unserialized: DatabaseUnserializedHeaders.failurePercent,
        view: DatabaseViewHeaders.failurePercent,
      },
      [DatabaseSerializerHeaders.epg]: {
        original: DatabaseOriginalHeaders.epg,
        serialized: DatabaseSerializerHeaders.epg,
        unserialized: DatabaseUnserializedHeaders.epg,
        view: DatabaseViewHeaders.epg,
      },
      [DatabaseSerializerHeaders.vriClassification]: {
        original: DatabaseOriginalHeaders.vriClassification,
        serialized: DatabaseSerializerHeaders.vriClassification,
        unserialized: DatabaseUnserializedHeaders.vriClassification,
        view: DatabaseViewHeaders.vriClassification,
      },
      // [DatabaseSerializerHeaders.epgSequenceNumber]: {
      //   original: DatabaseOriginalHeaders.epgSequenceNumber,
      //   serialized: DatabaseSerializerHeaders.epgSequenceNumber,
      //   unserialized: DatabaseUnserializedHeaders.epgSequenceNumber,
      //   view: DatabaseViewHeaders.epgSequenceNumber,
      // },
      [DatabaseSerializerHeaders.basicBalance]: {
        original: DatabaseOriginalHeaders.basicBalance,
        serialized: DatabaseSerializerHeaders.basicBalance,
        unserialized: DatabaseUnserializedHeaders.basicBalance,
        view: DatabaseViewHeaders.basicBalance,
      },
      [DatabaseSerializerHeaders.byWeight]: {
        original: DatabaseOriginalHeaders.byWeight,
        serialized: DatabaseSerializerHeaders.byWeight,
        unserialized: DatabaseUnserializedHeaders.byWeight,
        view: DatabaseViewHeaders.byWeight,
      },
      [DatabaseSerializerHeaders.avgWeightCorrection]: {
        original: DatabaseOriginalHeaders.avgWeightCorrection,
        serialized: DatabaseSerializerHeaders.avgWeightCorrection,
        unserialized: DatabaseUnserializedHeaders.avgWeightCorrection,
        view: DatabaseViewHeaders.avgWeightCorrection,
      },
      [DatabaseSerializerHeaders.saveSpekdikte]: {
        original: DatabaseOriginalHeaders.saveSpekdikte,
        serialized: DatabaseSerializerHeaders.saveSpekdikte,
        unserialized: DatabaseUnserializedHeaders.saveSpekdikte,
        view: DatabaseViewHeaders.saveSpekdikte,
      },
      [DatabaseSerializerHeaders.baconCorrection]: {
        original: DatabaseOriginalHeaders.baconCorrection,
        serialized: DatabaseSerializerHeaders.baconCorrection,
        unserialized: DatabaseUnserializedHeaders.baconCorrection,
        view: DatabaseViewHeaders.baconCorrection,
      },
      [DatabaseSerializerHeaders.baconWidth]: {
        original: DatabaseOriginalHeaders.baconWidth,
        serialized: DatabaseSerializerHeaders.baconWidth,
        unserialized: DatabaseUnserializedHeaders.baconWidth,
        view: DatabaseViewHeaders.baconWidth,
      },
      [DatabaseSerializerHeaders.muscleCorrection]: {
        original: DatabaseOriginalHeaders.muscleCorrection,
        serialized: DatabaseSerializerHeaders.muscleCorrection,
        unserialized: DatabaseUnserializedHeaders.muscleCorrection,
        view: DatabaseViewHeaders.muscleCorrection,
      },
      [DatabaseSerializerHeaders.preciousMeat]: {
        original: DatabaseOriginalHeaders.preciousMeat,
        serialized: DatabaseSerializerHeaders.preciousMeat,
        unserialized: DatabaseUnserializedHeaders.preciousMeat,
        view: DatabaseViewHeaders.preciousMeat,
      },
      [DatabaseSerializerHeaders.preciousMeatCorrection]: {
        original: DatabaseOriginalHeaders.preciousMeatCorrection,
        serialized: DatabaseSerializerHeaders.preciousMeatCorrection,
        unserialized: DatabaseUnserializedHeaders.preciousMeatCorrection,
        view: DatabaseViewHeaders.preciousMeatCorrection,
      },
      [DatabaseSerializerHeaders.gemClassificationCorrection]: {
        original: DatabaseOriginalHeaders.gemClassificationCorrection,
        serialized: DatabaseSerializerHeaders.gemClassificationCorrection,
        unserialized: DatabaseUnserializedHeaders.gemClassificationCorrection,
        view: DatabaseViewHeaders.gemClassificationCorrection,
      },
      [DatabaseSerializerHeaders.gemSexeSurChange]: {
        original: DatabaseOriginalHeaders.gemSexeSurChange,
        serialized: DatabaseSerializerHeaders.gemSexeSurChange,
        unserialized: DatabaseUnserializedHeaders.gemSexeSurChange,
        view: DatabaseViewHeaders.gemSexeSurChange,
      },
      [DatabaseSerializerHeaders.gemConceptSurchange]: {
        original: DatabaseOriginalHeaders.gemConceptSurchange,
        serialized: DatabaseSerializerHeaders.gemConceptSurchange,
        unserialized: DatabaseUnserializedHeaders.gemConceptSurchange,
        view: DatabaseViewHeaders.gemConceptSurchange,
      },
      [DatabaseSerializerHeaders.percentOfPigsInDraft]: {
        original: DatabaseOriginalHeaders.percentOfPigsInDraft,
        serialized: DatabaseSerializerHeaders.percentOfPigsInDraft,
        unserialized: DatabaseUnserializedHeaders.percentOfPigsInDraft,
        view: DatabaseViewHeaders.percentOfPigsInDraft,
      },
      [DatabaseSerializerHeaders.vriHealth]: {
        original: DatabaseOriginalHeaders.vriHealth,
        serialized: DatabaseSerializerHeaders.vriHealth,
        unserialized: DatabaseUnserializedHeaders.vriHealth,
        view: DatabaseViewHeaders.vriHealth,
      },
      // [DatabaseSerializerHeaders.epgSequenceNumber_]: {
      //   original: DatabaseOriginalHeaders.epgSequenceNumber_,
      //   serialized: DatabaseSerializerHeaders.epgSequenceNumber_,
      //   unserialized: DatabaseUnserializedHeaders.epgSequenceNumber_,
      //   view: DatabaseViewHeaders.epgSequenceNumber_,
      // },
      [DatabaseSerializerHeaders.euroPerPiglet]: {
        original: DatabaseOriginalHeaders.euroPerPiglet,
        serialized: DatabaseSerializerHeaders.euroPerPiglet,
        unserialized: DatabaseUnserializedHeaders.euroPerPiglet,
        view: DatabaseViewHeaders.euroPerPiglet,
      },
      [DatabaseSerializerHeaders.approved]: {
        original: DatabaseOriginalHeaders.approved,
        serialized: DatabaseSerializerHeaders.approved,
        unserialized: DatabaseUnserializedHeaders.approved,
        view: DatabaseViewHeaders.approved,
      },
      [DatabaseSerializerHeaders.pleuritis]: {
        original: DatabaseOriginalHeaders.pleuritis,
        serialized: DatabaseSerializerHeaders.pleuritis,
        unserialized: DatabaseUnserializedHeaders.pleuritis,
        view: DatabaseViewHeaders.pleuritis,
      },
      [DatabaseSerializerHeaders.firesSkin]: {
        original: DatabaseOriginalHeaders.firesSkin,
        serialized: DatabaseSerializerHeaders.firesSkin,
        unserialized: DatabaseUnserializedHeaders.firesSkin,
        view: DatabaseViewHeaders.firesSkin,
      },
      [DatabaseSerializerHeaders.firesLeg]: {
        original: DatabaseOriginalHeaders.firesLeg,
        serialized: DatabaseSerializerHeaders.firesLeg,
        unserialized: DatabaseUnserializedHeaders.firesLeg,
        view: DatabaseViewHeaders.firesLeg,
      },
      [DatabaseSerializerHeaders.approvedOrgan]: {
        original: DatabaseOriginalHeaders.approvedOrgan,
        serialized: DatabaseSerializerHeaders.approvedOrgan,
        unserialized: DatabaseUnserializedHeaders.approvedOrgan,
        view: DatabaseViewHeaders.approvedOrgan,
      },
      [DatabaseSerializerHeaders.leverAfg]: {
        original: DatabaseOriginalHeaders.leverAfg,
        serialized: DatabaseSerializerHeaders.leverAfg,
        unserialized: DatabaseUnserializedHeaders.leverAfg,
        view: DatabaseViewHeaders.leverAfg,
      },
      [DatabaseSerializerHeaders.longAfg]: {
        original: DatabaseOriginalHeaders.longAfg,
        serialized: DatabaseSerializerHeaders.longAfg,
        unserialized: DatabaseUnserializedHeaders.longAfg,
        view: DatabaseViewHeaders.longAfg,
      },
      [DatabaseSerializerHeaders.healthy]: {
        original: DatabaseOriginalHeaders.healthy,
        serialized: DatabaseSerializerHeaders.healthy,
        unserialized: DatabaseUnserializedHeaders.healthy,
        view: DatabaseViewHeaders.healthy,
      },
      [DatabaseSerializerHeaders.endManufacturer]: {
        original: DatabaseOriginalHeaders.endManufacturer,
        serialized: DatabaseSerializerHeaders.endManufacturer,
        unserialized: DatabaseUnserializedHeaders.endManufacturer,
        view: DatabaseViewHeaders.endManufacturer,
      },
      [DatabaseSerializerHeaders.feedingSystem]: {
        original: DatabaseOriginalHeaders.feedingSystem,
        serialized: DatabaseSerializerHeaders.feedingSystem,
        unserialized: DatabaseUnserializedHeaders.feedingSystem,
        view: DatabaseViewHeaders.feedingSystem,
      },
    },
  },
};

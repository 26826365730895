import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const certificatesIKB = new Route('get', new RouteParser('/certificates_ikb/grouped_index'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 4987,
          ubn: '2222222',
          valid_from: '2004-02-03T20:59:56.000Z',
          valid_to: '2021-01-31T20:59:56.000Z',
          type: 'ikb',
          ...generateBlockchainRecord(),
        },
        {
          id: 4986,
          ubn: '1111111',
          valid_from: '2004-02-03T20:59:56.000Z',
          valid_to: '2021-01-31T20:59:56.000Z',
          type: 'ikb',
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

import { IntlKeys } from 'lib/localization/keys/__keys';

enum EntitiesListOriginal {
  EntitiesList = 'Entities list',
}

enum EntitiesListSerialized {
  EntitiesList = 'EntitiesList',
}

enum EntitiesListUnserialized {
  EntitiesList = 'EntitiesList',
}

enum EntitiesListOriginalHeaders {
  'entityId' = 'Entity ID',
  'entityName' = 'Entity name',
  'country' = 'Country',
  'businessUnit' = 'Business Unit',
}

enum EntitiesListSerializedHeaders {
  'entityId' = 'entityId',
  'entityName' = 'entityName',
  'country' = 'country',
  'businessUnit' = 'businessUnit',
}
enum EntitiesListUnserializedHeaders {
  'entityId' = 'entity_id',
  'entityName' = 'entity_name',
  'country' = 'country',
  'businessUnit' = 'business_unit',
}

export const ENTITIES_LIST_FIELDS = {
  [EntitiesListUnserialized.EntitiesList]: {
    original: EntitiesListOriginal.EntitiesList,
    serialized: EntitiesListSerialized.EntitiesList,
    unserialized: EntitiesListUnserialized.EntitiesList,
    view: IntlKeys.referenceTablesEntitiesList, //
    viewName: 'Entities list',

    columns: {
      [EntitiesListSerializedHeaders.entityId]: {
        original: EntitiesListOriginalHeaders.entityId,
        serialized: EntitiesListSerializedHeaders.entityId,
        unserialized: EntitiesListUnserializedHeaders.entityId,
        view: IntlKeys.referenceTablesEntitiesListEntityId,
      },
      [EntitiesListSerializedHeaders.entityName]: {
        original: EntitiesListOriginalHeaders.entityName,
        serialized: EntitiesListSerializedHeaders.entityName,
        unserialized: EntitiesListUnserializedHeaders.entityName,
        view: IntlKeys.referenceTablesEntitiesListEntityName,
      },
      [EntitiesListSerializedHeaders.country]: {
        original: EntitiesListOriginalHeaders.country,
        serialized: EntitiesListSerializedHeaders.country,
        unserialized: EntitiesListUnserializedHeaders.country,
        view: IntlKeys.referenceTablesEntitiesListCountry,
      },
      [EntitiesListSerializedHeaders.businessUnit]: {
        original: EntitiesListOriginalHeaders.businessUnit,
        serialized: EntitiesListSerializedHeaders.businessUnit,
        unserialized: EntitiesListUnserializedHeaders.businessUnit,
        view: IntlKeys.referenceTablesEntitiesListBusinessUnit,
      },
    },
  },
};

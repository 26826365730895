import { GREENHOUSE_GAS_EMISSION } from 'lib/excel/config/referenceTables/greenhouseGasEmission/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo5DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = GREENHOUSE_GAS_EMISSION.GreenhouseGasEmission;

export const getGreenhouseGasEmissionView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.country.serialized, viewPropName: columns.country.view }),
      new CellViewer({ serializedPropName: columns.location.serialized, viewPropName: columns.location.view }),
      new CellViewer({ serializedPropName: columns.category.serialized, viewPropName: columns.category.view }),
      new CellViewer({ serializedPropName: columns.account.serialized, viewPropName: columns.account.view }),
      new CellViewer({
        serializedPropName: columns.extraExternalId.serialized,
        viewPropName: columns.extraExternalId.view,
      }),
      new CellViewer({ serializedPropName: columns.name.serialized, viewPropName: columns.name.view }),
      new CellViewer({ serializedPropName: columns.inputUnit.serialized, viewPropName: columns.inputUnit.view }),
      new CellViewer({
        serializedPropName: columns.conversionFactor.serialized,
        viewPropName: columns.conversionFactor.view,
      }),
      new CellViewer({
        serializedPropName: columns.conversionFormula.serialized,
        viewPropName: columns.conversionFormula.view,
      }),
      new CellViewer({
        serializedPropName: columns.emissionFactors.serialized,
        viewPropName: columns.emissionFactors.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo5DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.co2Unit.serialized, viewPropName: columns.co2Unit.view }),
    ]),
  ]);
};

import RouteParser from 'route-parser';

import { Route, Responder } from '../Route';

export const dashboardTooltipMockResponder = () => {
  return [
    200,
    {
      resources: [
        {
          id: 1,
          is_allowed: true,
          notes: 'Notes text',
          allowed_from: '2021-01-02T12:47:48.111Z',
          allowed_to: '2021-01-08T12:47:48.111Z',
          permission_rule: {
            id: 1,
            title: 'Kiwa Daily',
            name: 'certificates',
          },
        },
        {
          id: 1,
          is_allowed: true,
          notes: 'Notes text',
          allowed_from: '2021-01-02T12:47:48.111Z',
          allowed_to: '2021-01-08T12:47:48.111Z',
          permission_rule: {
            id: 1,
            title: 'Kiwa Daily',
            name: 'certificates',
          },
        },
      ],
    },
  ];
};

// real + mock
export type DashboardTooltipCombinedResponse = Exclude<ReturnType<typeof dashboardTooltipMockResponder>[1], number>;
export type DashboardTooltipCombinedResource = DashboardTooltipCombinedResponse['resources'];

export const dashboardTooltip = new Route(
  'get',
  new RouteParser('/mock/organization_permission_rules'),
  (dashboardTooltipMockResponder as unknown) as Responder,
);

import React from 'react';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'lib/localization/keys';

export const useLocalizationHook = () => {
  const { formatMessage } = useIntl();

  const applyFormatting = React.useCallback((id: IntlKeys) => formatMessage({ id }), [formatMessage]);

  return React.useMemo(
    () => ({
      body: {
        emptyDataSourceMessage: applyFormatting(IntlKeys.tableBodyEmptyDataSourceMessage),
        addTooltip: applyFormatting(IntlKeys.tableBodyAddTooltip),
        deleteTooltip: applyFormatting(IntlKeys.tableBodyDeleteTooltip),
        editTooltip: applyFormatting(IntlKeys.tableBodyEditTooltip),
        filterRow: {
          filterTooltip: applyFormatting(IntlKeys.tableBodyFilterRowFilterTooltip),
        },
        editRow: {
          deleteText: applyFormatting(IntlKeys.tableBodyEditRowDeleteText),
          cancelTooltip: applyFormatting(IntlKeys.tableBodyEditRowCancelTooltip),
          saveTooltip: applyFormatting(IntlKeys.tableBodyEditRowSaveTooltip),
        },
      },
      grouping: {
        placeholder: applyFormatting(IntlKeys.tableGroupingPlaceholder),
        groupedBy: applyFormatting(IntlKeys.tableGroupingGroupedBy),
      },
      header: {
        actions: applyFormatting(IntlKeys.tableHeaderActions),
      },
      pagination: {
        labelDisplayedRows: applyFormatting(IntlKeys.tablePaginationLabelDisplayedRows),
        labelRowsSelect: applyFormatting(IntlKeys.tablePaginationLabelRowsSelect),
        labelRowsPerPage: applyFormatting(IntlKeys.tablePaginationLabelRowsPerPage),
        firstAriaLabel: applyFormatting(IntlKeys.tablePaginationFirstAriaLabel),
        firstTooltip: applyFormatting(IntlKeys.tablePaginationFirstTooltip),
        previousAriaLabel: applyFormatting(IntlKeys.tablePaginationPreviousAriaLabel),
        previousTooltip: applyFormatting(IntlKeys.tablePaginationPreviousTooltip),
        nextAriaLabel: applyFormatting(IntlKeys.tablePaginationNextAriaLabel),
        nextTooltip: applyFormatting(IntlKeys.tablePaginationNextTooltip),
        lastAriaLabel: applyFormatting(IntlKeys.tablePaginationLastAriaLabel),
        lastTooltip: applyFormatting(IntlKeys.tablePaginationLastTooltip),
      },
      toolbar: {
        addRemoveColumns: applyFormatting(IntlKeys.tableToolbarAddRemoveColumns),
        nRowsSelected: applyFormatting(IntlKeys.tableToolbarNRowsSelected),
        showColumnsTitle: applyFormatting(IntlKeys.tableToolbarShowColumnsTitle),
        showColumnsAriaLabel: applyFormatting(IntlKeys.tableToolbarShowColumnsAriaLabel),
        exportTitle: applyFormatting(IntlKeys.tableToolbarExportTitle),
        exportAriaLabel: applyFormatting(IntlKeys.tableToolbarExportAriaLabel),
        exportName: applyFormatting(IntlKeys.tableToolbarExportName),
        searchTooltip: applyFormatting(IntlKeys.tableToolbarSearchTooltip),
        searchPlaceholder: applyFormatting(IntlKeys.tableToolbarSearchPlaceholder),
      },
    }),
    [applyFormatting],
  );
};

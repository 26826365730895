import { IntlKeys } from 'lib/localization/keys';

enum GasConsumptionRatesSerialized {
  GasConsumptionRates = 'GasConsumptionRates',
}

enum GasConsumptionRatesUnserialized {
  GasConsumptionRates = 'GasConsumptionRates',
}

export enum GasConsumptionRatesSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  houseType = 'houseType',
  gasConsumption = 'gasConsumption',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const GasConsumptionRatesUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['gas_consumption_rate_upload', 'id'],
  houseType: 'house_type',
  gasConsumption: 'gas_consumption',
  uploadType: ['gas_consumption_rate_upload', 'upload_type'],
  filename: ['gas_consumption_rate_upload', 'file_name'],
  status: ['gas_consumption_rate_upload', 'status'],
  validDateFrom: ['gas_consumption_rate_upload', 'valid_from'],
  validDateTo: ['gas_consumption_rate_upload', 'valid_to'],
  notes: ['gas_consumption_rate_upload', 'notes'],
  uploadedAt: ['gas_consumption_rate_upload', 'uploaded_at'],
  uploadedBy: ['gas_consumption_rate_upload', 'uploaded_by', 'full_name'],
} as const;

export const GAS_CONSUMPTION_RATES = {
  [GasConsumptionRatesSerialized.GasConsumptionRates]: {
    serialized: GasConsumptionRatesSerialized.GasConsumptionRates,
    unserialized: GasConsumptionRatesUnserialized.GasConsumptionRates,
    view: IntlKeys.referenceTablesGasConsumptionRates,

    columns: {
      [GasConsumptionRatesSerializedHeaders.id]: {
        serialized: GasConsumptionRatesSerializedHeaders.id,
        unserialized: GasConsumptionRatesUnserializedHeaders.id,
      },
      [GasConsumptionRatesSerializedHeaders.referenceUploadID]: {
        serialized: GasConsumptionRatesSerializedHeaders.referenceUploadID,
        unserialized: GasConsumptionRatesUnserializedHeaders.referenceUploadID,
      },
      [GasConsumptionRatesSerializedHeaders.houseType]: {
        serialized: GasConsumptionRatesSerializedHeaders.houseType,
        unserialized: GasConsumptionRatesUnserializedHeaders.houseType,
        view: IntlKeys.referenceTablesGasConsumptionRatesHouseType,
      },
      [GasConsumptionRatesSerializedHeaders.gasConsumption]: {
        serialized: GasConsumptionRatesSerializedHeaders.gasConsumption,
        unserialized: GasConsumptionRatesUnserializedHeaders.gasConsumption,
        view: IntlKeys.referenceTablesGasConsumptionRatesGasConsumption,
      },
      [GasConsumptionRatesSerializedHeaders.status]: {
        serialized: GasConsumptionRatesSerializedHeaders.status,
        unserialized: GasConsumptionRatesUnserializedHeaders.status,
      },
      [GasConsumptionRatesSerializedHeaders.uploadType]: {
        serialized: GasConsumptionRatesSerializedHeaders.uploadType,
        unserialized: GasConsumptionRatesUnserializedHeaders.uploadType,
      },
      [GasConsumptionRatesSerializedHeaders.notes]: {
        serialized: GasConsumptionRatesSerializedHeaders.notes,
        unserialized: GasConsumptionRatesUnserializedHeaders.notes,
      },
      [GasConsumptionRatesSerializedHeaders.filename]: {
        serialized: GasConsumptionRatesSerializedHeaders.filename,
        unserialized: GasConsumptionRatesUnserializedHeaders.filename,
      },
      [GasConsumptionRatesSerializedHeaders.validDateFrom]: {
        serialized: GasConsumptionRatesSerializedHeaders.validDateFrom,
        unserialized: GasConsumptionRatesUnserializedHeaders.validDateFrom,
      },
      [GasConsumptionRatesSerializedHeaders.validDateTo]: {
        serialized: GasConsumptionRatesSerializedHeaders.validDateTo,
        unserialized: GasConsumptionRatesUnserializedHeaders.validDateTo,
      },
      [GasConsumptionRatesSerializedHeaders.uploadedAt]: {
        serialized: GasConsumptionRatesSerializedHeaders.uploadedAt,
        unserialized: GasConsumptionRatesUnserializedHeaders.uploadedAt,
      },
      [GasConsumptionRatesSerializedHeaders.uploadedBy]: {
        serialized: GasConsumptionRatesSerializedHeaders.uploadedBy,
        unserialized: GasConsumptionRatesUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import { GREENHOUSE_GAS_EMISSION } from 'lib/excel/config/referenceTables/greenhouseGasEmission/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = GREENHOUSE_GAS_EMISSION.GreenhouseGasEmission;

export const getGreenhouseGasEmissionNoDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.country.serialized, extractPropName: columns.country.view }),
      new DisplayHeader({ headerName: columns.location.serialized, extractPropName: columns.location.view }),
      new DisplayHeader({ headerName: columns.category.serialized, extractPropName: columns.category.view }),
      new DisplayHeader({ headerName: columns.account.serialized, extractPropName: columns.account.view }),
      new DisplayHeader({
        headerName: columns.extraExternalId.serialized,
        extractPropName: columns.extraExternalId.view,
      }),
      new DisplayHeader({ headerName: columns.name.serialized, extractPropName: columns.name.view }),
      new DisplayHeader({
        headerName: columns.inputUnit.serialized,
        extractPropName: columns.inputUnit.view,
      }),
      new DisplayHeader({
        headerName: columns.conversionFactor.serialized,
        extractPropName: columns.conversionFactor.view,
      }),
      new DisplayHeader({
        headerName: columns.conversionFormula.serialized,
        extractPropName: columns.conversionFormula.view,
      }),
      new DisplayHeader({
        headerName: columns.emissionFactors.serialized,
        extractPropName: columns.emissionFactors.view,
      }),
      new DisplayHeader({
        headerName: columns.co2Unit.serialized,
        extractPropName: columns.co2Unit.view,
      }),
    ]),
  ]);
};

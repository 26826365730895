import { IntlKeys } from 'lib/localization/keys/__keys';

enum VeterinarianDataSerialized {
  VeterinarianData = 'VeterinarianData',
}

enum VeterinarianDataUnserialized {
  VeterinarianData = 'VeterinarianData',
}

export enum VeterinarianDataSerializedHeaders {
  exportDate = 'exportDate',
  ubn = 'ubn',
  companyName = 'companyName',
  streetAddress = 'streetAddress',
  placeAddress = 'placeAddress',
  period = 'period',
  animalCategory = 'animalCategory',
  dddTm = 'dddTm',
  deliveryDate = 'deliveryDate',
  registrationDate = 'registrationDate',
  veterinarianName = 'veterinarianName',
  ean = 'ean',
  registrationNumberNl = 'registrationNumberNl',
  choice = 'choice',
  productName = 'productName',
  packagesCount = 'packagesCount',
  capacity = 'capacity',
  total = 'total',
  dosingFactor = 'dosingFactor',
  avgAnimalsNumber = 'avgAnimalsNumber',
  weightPerAnimal = 'weightPerAnimal',
  dddDaily = 'dddDaily',
}

export enum VeterinarianDataUnserializedHeaders {
  exportDate = 'export_date',
  ubn = 'ubn',
  companyName = 'company_name',
  streetAddress = 'street_address',
  placeAddress = 'location',
  period = 'period',
  animalCategory = 'animal_category',
  dddTm = 'ddd_tm',
  deliveryDate = 'delivery_date',
  registrationDate = 'registration_date',
  veterinarianName = 'veterinarian_name',
  ean = 'ean',
  registrationNumberNl = 'registration_number_nl',
  choice = 'choice',
  productName = 'product_name',
  packagesCount = 'packages_count',
  capacity = 'capacity',
  total = 'total',
  dosingFactor = 'dosing_factor',
  avgAnimalsNumber = 'avg_animals_number',
  weightPerAnimal = 'weight_per_animal',
  dddDaily = 'ddd_daily',
}

export const VETERINARIAN_DATA = {
  [VeterinarianDataSerialized.VeterinarianData]: {
    serialized: VeterinarianDataSerialized.VeterinarianData,
    unserialized: VeterinarianDataUnserialized.VeterinarianData,
    view: IntlKeys.titleVeterinarianData,
    columns: {
      [VeterinarianDataSerializedHeaders.exportDate]: {
        serialized: VeterinarianDataSerializedHeaders.exportDate,
        unserialized: VeterinarianDataUnserializedHeaders.exportDate,
        view: IntlKeys.veterinaryExportDate,
      },
      [VeterinarianDataSerializedHeaders.ubn]: {
        serialized: VeterinarianDataSerializedHeaders.ubn,
        unserialized: VeterinarianDataUnserializedHeaders.ubn,
        view: IntlKeys.veterinaryUbn,
      },
      [VeterinarianDataSerializedHeaders.companyName]: {
        serialized: VeterinarianDataSerializedHeaders.companyName,
        unserialized: VeterinarianDataUnserializedHeaders.companyName,
        view: IntlKeys.veterinaryCompanyName,
      },
      [VeterinarianDataSerializedHeaders.streetAddress]: {
        serialized: VeterinarianDataSerializedHeaders.streetAddress,
        unserialized: VeterinarianDataUnserializedHeaders.streetAddress,
        view: IntlKeys.veterinaryStreetAddress,
      },
      [VeterinarianDataSerializedHeaders.placeAddress]: {
        serialized: VeterinarianDataSerializedHeaders.placeAddress,
        unserialized: VeterinarianDataUnserializedHeaders.placeAddress,
        view: IntlKeys.veterinaryPlaceAddress,
      },
      [VeterinarianDataSerializedHeaders.period]: {
        serialized: VeterinarianDataSerializedHeaders.period,
        unserialized: VeterinarianDataUnserializedHeaders.period,
        view: IntlKeys.veterinaryPeriod,
      },
      [VeterinarianDataSerializedHeaders.animalCategory]: {
        serialized: VeterinarianDataSerializedHeaders.animalCategory,
        unserialized: VeterinarianDataUnserializedHeaders.animalCategory,
        view: IntlKeys.veterinaryAnimalCategory,
      },
      [VeterinarianDataSerializedHeaders.dddTm]: {
        serialized: VeterinarianDataSerializedHeaders.dddTm,
        unserialized: VeterinarianDataUnserializedHeaders.dddTm,
        view: IntlKeys.veterinaryDddTm,
      },
      [VeterinarianDataSerializedHeaders.deliveryDate]: {
        serialized: VeterinarianDataSerializedHeaders.deliveryDate,
        unserialized: VeterinarianDataUnserializedHeaders.deliveryDate,
        view: IntlKeys.veterinaryDeliveryDate,
      },
      [VeterinarianDataSerializedHeaders.registrationDate]: {
        serialized: VeterinarianDataSerializedHeaders.registrationDate,
        unserialized: VeterinarianDataUnserializedHeaders.registrationDate,
        view: IntlKeys.veterinaryRegistrationDate,
      },
      [VeterinarianDataSerializedHeaders.veterinarianName]: {
        serialized: VeterinarianDataSerializedHeaders.veterinarianName,
        unserialized: VeterinarianDataUnserializedHeaders.veterinarianName,
        view: IntlKeys.veterinaryVeterinarianName,
      },
      [VeterinarianDataSerializedHeaders.ean]: {
        serialized: VeterinarianDataSerializedHeaders.ean,
        unserialized: VeterinarianDataUnserializedHeaders.ean,
        view: IntlKeys.veterinaryEan,
      },
      [VeterinarianDataSerializedHeaders.registrationNumberNl]: {
        serialized: VeterinarianDataSerializedHeaders.registrationNumberNl,
        unserialized: VeterinarianDataUnserializedHeaders.registrationNumberNl,
        view: IntlKeys.veterinaryRegistrationNumberNl,
      },
      [VeterinarianDataSerializedHeaders.choice]: {
        serialized: VeterinarianDataSerializedHeaders.choice,
        unserialized: VeterinarianDataUnserializedHeaders.choice,
        view: IntlKeys.veterinaryChoice,
      },
      [VeterinarianDataSerializedHeaders.productName]: {
        serialized: VeterinarianDataSerializedHeaders.productName,
        unserialized: VeterinarianDataUnserializedHeaders.productName,
        view: IntlKeys.veterinaryProductName,
      },
      [VeterinarianDataSerializedHeaders.packagesCount]: {
        serialized: VeterinarianDataSerializedHeaders.packagesCount,
        unserialized: VeterinarianDataUnserializedHeaders.packagesCount,
        view: IntlKeys.veterinaryPackagesCount,
      },
      [VeterinarianDataSerializedHeaders.capacity]: {
        serialized: VeterinarianDataSerializedHeaders.capacity,
        unserialized: VeterinarianDataUnserializedHeaders.capacity,
        view: IntlKeys.veterinaryCapacity,
      },
      [VeterinarianDataSerializedHeaders.total]: {
        serialized: VeterinarianDataSerializedHeaders.total,
        unserialized: VeterinarianDataUnserializedHeaders.total,
        view: IntlKeys.veterinaryTotal,
      },
      [VeterinarianDataSerializedHeaders.dosingFactor]: {
        serialized: VeterinarianDataSerializedHeaders.dosingFactor,
        unserialized: VeterinarianDataUnserializedHeaders.dosingFactor,
        view: IntlKeys.veterinaryDosingFactor,
      },
      [VeterinarianDataSerializedHeaders.avgAnimalsNumber]: {
        serialized: VeterinarianDataSerializedHeaders.avgAnimalsNumber,
        unserialized: VeterinarianDataUnserializedHeaders.avgAnimalsNumber,
        view: IntlKeys.veterinaryAvgAnimalsNumber,
      },
      [VeterinarianDataSerializedHeaders.weightPerAnimal]: {
        serialized: VeterinarianDataSerializedHeaders.weightPerAnimal,
        unserialized: VeterinarianDataUnserializedHeaders.weightPerAnimal,
        view: IntlKeys.veterinaryWeightPerAnimal,
      },
      [VeterinarianDataSerializedHeaders.dddDaily]: {
        serialized: VeterinarianDataSerializedHeaders.dddDaily,
        unserialized: VeterinarianDataUnserializedHeaders.dddDaily,
        view: IntlKeys.veterinaryDddDaily,
      },
    },
  },
};

import TableView from './table';
import CardView from './card';
import { DataTableProps } from '../index';

export interface ViewProps extends Omit<DataTableProps, 'data' | 'view' | 'className'> {
  title?: string;
  data: object[];
}

const views = {
  table: TableView,
  card: CardView,
  map: TableView,
};

export default views;

import { Route } from '../../Route';
import RouteParser from 'route-parser';

export const fileUpload = new Route('post', new RouteParser('/file_uploads'), (...args: Array<any>) => {
  const [, fd] = args;

  const responseAlreadyUploaded = {
    errors: 'File already uploaded!',
    old_file_upload: {
      errors: 'File already uploaded!',
      old_file_upload: {
        id: 41,
        file: {
          url: '/uploads/companies/1/user_channels/3/file_upload/41/file/valid_daily.xlsx',
        },
        checksum: '8e6dc81d6f683911ba1ad045f776bfa3',
        is_edited: false,
        upload_token: 'k2QmWY68YEeLCY-m9OYG31scshuaPNl2Vg',
        upload_type: 'daily',
        uploaded_at: null,
        source: 'manual',
        status: 'pending',
        created_at: '2020-06-26T08:30:17.500Z',
        user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
      },
    },
  };

  switch (fd.get('resource[file]').name) {
    case 'already_uploaded_weekly.xlsx':
    case 'already_uploaded_vri.xlsx':
    case 'already_uploaded_daily.xlsx': {
      return [400, responseAlreadyUploaded];
    }

    default: {
      return [200, responseAlreadyUploaded];
    }
  }
});

import React from 'react';
import moment from 'moment';

import { roundTo2Digits } from 'lib/helpers/renderHelpers';

import { Typography, Divider, Box, makeStyles, useTheme, Theme } from '@material-ui/core';

export enum DataType {
  String = 'string',
  Number = 'number',
  Date = 'date',
}

interface OwnProps {
  label: string;
  data?: string | number;
  dataType?: DataType;
}

const formatData = {
  [DataType.String]: (data: string | number) => data,
  [DataType.Number]: (data: string | number) => roundTo2Digits(+data),
  [DataType.Date]: (data: string | number) => moment(data).format('DD MMM YYYY'),
};

const DataContainer: React.FC<OwnProps> = ({ label, data = '-', dataType = DataType.String }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <Typography className={classes.caption} variant="overline">
        {label}
      </Typography>

      <Box className={classes.dataBox} overflow="auto">
        {formatData[dataType](data)}
      </Box>
      <Divider />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    lineHeight: 0,
    letterSpacing: 1.5,
    fontSize: 10,
    color: theme.colors.dimGray,
  },
  container: {
    paddingBottom: 16,
  },
  dataBox: {
    lineHeight: 2,
  },
}));

export default React.memo(DataContainer);

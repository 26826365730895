import { IntlKeys } from 'lib/localization/keys/__keys';

export enum TransportLoadingsSerialized {
  Loadings = 'Loadings',
  Unloadings = 'Unloadings',
}

enum TransportLoadingsUnserialized {
  Loadings = 'Loadings',
  Unloadings = 'Unloadings',
}

enum TransportLoadingsSerializedHeaders {
  transportId = 'transportId',
  plannedArrivalTime = 'plannedArrivalTime',
  actualArrivalTime = 'actualArrivalTime',
  startLoadingTime = 'startLoadingTime',
  endLoadingTime = 'endLoadingTime',
  plannedDepartureTime = 'plannedDepartureTime',
  actualDepartureTime = 'actualDepartureTime',
  numberOfAnimals = 'numberOfAnimals',
  locationIdTypeCode = 'locationIdTypeCode',
  locationIdValue = 'locationIdValue',
  locationName = 'locationName',
}

enum TransportLoadingsUnserializedHeaders {
  transportId = 'transport_id',
  plannedArrivalTime = 'planned_arrival_time',
  actualArrivalTime = 'actual_arrival_time',
  startLoadingTime = 'start_loading_time',
  endLoadingTime = 'end_loading_time',
  plannedDepartureTime = 'planned_departure_time',
  actualDepartureTime = 'actual_departure_time',
  numberOfAnimals = 'number_of_animals',
  locationIdTypeCode = 'location_id_type_code',
  locationIdValue = 'location_id_value',
  locationName = 'location_name',
}

const columns = {
  [TransportLoadingsSerializedHeaders.transportId]: {
    serialized: TransportLoadingsSerializedHeaders.transportId,
    unserialized: TransportLoadingsUnserializedHeaders.transportId,
    view: IntlKeys.movementEventsTransportId,
  },
  [TransportLoadingsSerializedHeaders.plannedArrivalTime]: {
    serialized: TransportLoadingsSerializedHeaders.plannedArrivalTime,
    unserialized: TransportLoadingsUnserializedHeaders.plannedArrivalTime,
    view: IntlKeys.transportsPlannedArrivalTime,
  },
  [TransportLoadingsSerializedHeaders.actualArrivalTime]: {
    serialized: TransportLoadingsSerializedHeaders.actualArrivalTime,
    unserialized: TransportLoadingsUnserializedHeaders.actualArrivalTime,
    view: IntlKeys.transportsActualArrivalTime,
  },
  [TransportLoadingsSerializedHeaders.startLoadingTime]: {
    serialized: TransportLoadingsSerializedHeaders.startLoadingTime,
    unserialized: TransportLoadingsUnserializedHeaders.startLoadingTime,
    view: IntlKeys.transportsStartLoadingTime,
  },
  [TransportLoadingsSerializedHeaders.endLoadingTime]: {
    serialized: TransportLoadingsSerializedHeaders.endLoadingTime,
    unserialized: TransportLoadingsUnserializedHeaders.endLoadingTime,
    view: IntlKeys.transportsEndLoadingTime,
  },
  [TransportLoadingsSerializedHeaders.plannedDepartureTime]: {
    serialized: TransportLoadingsSerializedHeaders.plannedDepartureTime,
    unserialized: TransportLoadingsUnserializedHeaders.plannedDepartureTime,
    view: IntlKeys.transportsPlannedDepartureTime,
  },
  [TransportLoadingsSerializedHeaders.actualDepartureTime]: {
    serialized: TransportLoadingsSerializedHeaders.actualDepartureTime,
    unserialized: TransportLoadingsUnserializedHeaders.actualDepartureTime,
    view: IntlKeys.transportsActualDepartureTime,
  },
  [TransportLoadingsSerializedHeaders.numberOfAnimals]: {
    serialized: TransportLoadingsSerializedHeaders.numberOfAnimals,
    unserialized: TransportLoadingsUnserializedHeaders.numberOfAnimals,
    view: IntlKeys.transportsReferenceId,
  },
  [TransportLoadingsSerializedHeaders.locationIdTypeCode]: {
    serialized: TransportLoadingsSerializedHeaders.locationIdTypeCode,
    unserialized: TransportLoadingsUnserializedHeaders.locationIdTypeCode,
    view: IntlKeys.transportsLocationIdTypeCode,
  },
  [TransportLoadingsSerializedHeaders.locationIdValue]: {
    serialized: TransportLoadingsSerializedHeaders.locationIdValue,
    unserialized: TransportLoadingsUnserializedHeaders.locationIdValue,
    view: IntlKeys.transportsLocationIdValue,
  },
  [TransportLoadingsSerializedHeaders.locationName]: {
    serialized: TransportLoadingsSerializedHeaders.locationName,
    unserialized: TransportLoadingsUnserializedHeaders.locationName,
    view: IntlKeys.transportsLocationName,
  },
};

export const TRANSPORT_LOADINGS = {
  [TransportLoadingsSerialized.Loadings]: {
    serialized: TransportLoadingsSerialized.Loadings,
    unserialized: TransportLoadingsUnserialized.Loadings,
    view: IntlKeys.transportLoadings,
    columns,
  },
};

export const TRANSPORT_UNLOADINGS = {
  [TransportLoadingsSerialized.Unloadings]: {
    serialized: TransportLoadingsSerialized.Unloadings,
    unserialized: TransportLoadingsUnserialized.Unloadings,
    view: IntlKeys.transportUnloadings,
    columns,
  },
};

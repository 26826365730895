import { Serializer } from '../index';
import { DocumentData, SheetSerializer } from './Sheet';
import { ObjectWithProps } from './Cell';

export class DocumentSerializer<T = ObjectWithProps, R = ObjectWithProps> implements Serializer {
  constructor(
    //
    private readonly data: DocumentData<(T | R)[]>,
    private readonly sheets: Array<SheetSerializer<T | R, R | T>>,
  ) {}

  private _get = <S extends 'serialize' | 'unserialize'>(method: S) => {
    type TFrom = S extends 'serialize' ? T : R;
    type TTo = S extends 'serialize' ? R : T;

    return () => {
      const data = this.data as DocumentData<TFrom[]>;
      const sheets = (this.sheets as unknown) as Array<SheetSerializer<TFrom, TTo>>;

      return sheets.reduce((cumulative, sheet) => {
        const serializer = sheet[method];

        return {
          ...cumulative,
          [sheet.sheetSerializerName]: serializer(data),
        };
      }, {} as DocumentData<TTo[]>);
    };
  };

  serialize = this._get('serialize');
  unserialize = this._get('unserialize');
}

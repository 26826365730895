import { DocumentSerializer } from '../../../serilizers/Document';
import { DocumentData, SheetSerializer } from '../../../serilizers/Sheet';
import { CellSerializer } from '../../../serilizers/Cell';
import { VRI_DOCUMENT_FIELDS } from '../structure';
import { propToString } from '../../../serilizers/utils';

const { columns, serialized, unserialized } = VRI_DOCUMENT_FIELDS.database;

const {
  vriTotalNumber,
  ubn,
  name,
  vriTotal,
  vriEgp,
  orderNumberEPG,
  growth,
  feedIntakePerDay,
  ewConversion,
  weightSupport,
  failurePercent,
  epg,
  vriClassification,
  // epgSequenceNumber,
  basicBalance,
  byWeight,
  avgWeightCorrection,
  saveSpekdikte,
  baconCorrection,
  baconWidth,
  muscleCorrection,
  preciousMeat,
  preciousMeatCorrection,
  gemClassificationCorrection,
  gemSexeSurChange,
  gemConceptSurchange,
  percentOfPigsInDraft,
  vriHealth,
  // epgSequenceNumber_,
  euroPerPiglet,
  approved,
  pleuritis,
  firesSkin,
  firesLeg,
  leverAfg,
  longAfg,
  healthy,
  approvedOrgan,
  endManufacturer,
  feedingSystem,
} = columns;

export const unserializeVRIData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(serialized, unserialized, [
      new CellSerializer(vriTotalNumber.serialized, vriTotalNumber.unserialized, propToString),
      new CellSerializer(ubn.serialized, ubn.unserialized, propToString),
      new CellSerializer(name.serialized, name.unserialized, propToString),
      new CellSerializer(vriTotal.serialized, vriTotal.unserialized),
      new CellSerializer(vriEgp.serialized, vriEgp.unserialized),
      new CellSerializer(orderNumberEPG.serialized, orderNumberEPG.unserialized, propToString),
      new CellSerializer(growth.serialized, growth.unserialized),
      new CellSerializer(feedIntakePerDay.serialized, feedIntakePerDay.unserialized),
      new CellSerializer(ewConversion.serialized, ewConversion.unserialized),
      new CellSerializer(weightSupport.serialized, weightSupport.unserialized),
      new CellSerializer(failurePercent.serialized, failurePercent.unserialized),
      new CellSerializer(epg.serialized, epg.unserialized),
      new CellSerializer(vriClassification.serialized, vriClassification.unserialized),
      // new CellSerializer(epgSequenceNumber.serialized, epgSequenceNumber.unserialized),
      new CellSerializer(basicBalance.serialized, basicBalance.unserialized),
      new CellSerializer(byWeight.serialized, byWeight.unserialized),
      new CellSerializer(avgWeightCorrection.serialized, avgWeightCorrection.unserialized),
      new CellSerializer(saveSpekdikte.serialized, saveSpekdikte.unserialized),
      new CellSerializer(baconCorrection.serialized, baconCorrection.unserialized),
      new CellSerializer(baconWidth.serialized, baconWidth.unserialized),
      new CellSerializer(muscleCorrection.serialized, muscleCorrection.unserialized),
      new CellSerializer(preciousMeat.serialized, preciousMeat.unserialized),
      new CellSerializer(preciousMeatCorrection.serialized, preciousMeatCorrection.unserialized),
      new CellSerializer(gemClassificationCorrection.serialized, gemClassificationCorrection.unserialized),
      new CellSerializer(gemSexeSurChange.serialized, gemSexeSurChange.unserialized),
      new CellSerializer(gemConceptSurchange.serialized, gemConceptSurchange.unserialized),
      new CellSerializer(percentOfPigsInDraft.serialized, percentOfPigsInDraft.unserialized),
      new CellSerializer(vriHealth.serialized, vriHealth.unserialized),
      // new CellSerializer(epgSequenceNumber_.serialized, epgSequenceNumber_.unserialized),
      new CellSerializer(euroPerPiglet.serialized, euroPerPiglet.unserialized),
      new CellSerializer(approved.serialized, approved.unserialized),
      new CellSerializer(pleuritis.serialized, pleuritis.unserialized),
      new CellSerializer(firesSkin.serialized, firesSkin.unserialized),
      new CellSerializer(firesLeg.serialized, firesLeg.unserialized),
      new CellSerializer(approvedOrgan.serialized, approvedOrgan.unserialized),
      new CellSerializer(leverAfg.serialized, leverAfg.unserialized),
      new CellSerializer(longAfg.serialized, longAfg.unserialized),
      new CellSerializer(healthy.serialized, healthy.unserialized),
      new CellSerializer(endManufacturer.serialized, endManufacturer.unserialized),
      new CellSerializer(feedingSystem.serialized, feedingSystem.unserialized),
    ]),
  ]);
};

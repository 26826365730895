import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';

import { asyncAction, syncAction } from 'lib/actions';
import { cloneDeep } from 'lodash';
import { deleteReport } from '../vri/actions';
import { Ensure } from 'lib/ensure';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { DocumentData } from 'lib/excel/serilizers/Sheet';

const initialState = {
  //
  metaResources: {
    filter: {},
    total: 0,
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  metaResource: {
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  selected: [],
  resources: {} as DocumentData,
  resource: {},
};

export interface FetchResources extends ObjectWithProps {
  ensure: Ensure;
}

export const fetchBatchesAction = asyncAction<FetchResources>('batches/fetchBatches');
export const fetchBatchAction = asyncAction<FetchResources>('batches/fetchBatch');
export const clearResourceAction = syncAction('batches/clearResource');

export const batchReducer = createReducer(initialState, {
  //
  [fetchBatchesAction.request]: (draft, { payload }) => {
    draft.metaResources.isError = false;
    draft.metaResources.isLoading = true;
    draft.metaResources.isLoaded = false;
  },

  [fetchBatchesAction.success]: (draft, { payload, meta }) => {
    draft.metaResources.isLoading = false;
    draft.metaResources.isLoaded = true;
    draft.metaResources.total = meta.total_count;
    draft.resources = { ...draft.resources, ...payload };
  },

  [fetchBatchesAction.failure]: (draft, { payload }) => {
    draft.metaResources.isError = true;
    draft.metaResources.isLoading = false;
    draft.metaResources.isLoaded = false;
    draft.metaResources.total = initialState.metaResources.total;
    draft.resources = initialState.resources;
  },

  [fetchBatchAction.request]: (draft, { payload }) => {
    draft.metaResource.isError = false;
    draft.metaResource.isLoading = true;
    draft.metaResource.isLoaded = false;
  },

  [fetchBatchAction.success]: (draft, { payload, meta }) => {
    draft.metaResource.isLoading = false;
    draft.metaResource.isLoaded = true;
    draft.resource = payload;
  },

  [fetchBatchAction.failure]: (draft, { payload }) => {
    draft.metaResource.isError = true;
    draft.metaResource.isLoading = false;
    draft.metaResource.isLoaded = false;
    draft.resource = initialState.resource;
  },

  [clearResourceAction.type]: (draft) => {
    draft.metaResource = initialState.metaResource;
    draft.resource = initialState.resource;
  },

  [deleteReport.request]: (draft, { payload }) => {
    draft.metaResources.isLoading = true;
  },
  [deleteReport.success]: (draft, params) => {
    const deleteItemId = params?.requestPayload?.params?.id;

    draft.resources.database = draft.resources.database.filter(({ id }) => id !== deleteItemId);
    draft.metaResources.isLoading = false;
  },
  [deleteReport.failure]: (draft, { payload }) => {
    draft.metaResources.isLoading = false;
  },
});

const batchSelector = (state: RootState) => state.batch;
export const batchResourcesSelector = createSelector(batchSelector, (batch) => {
  return cloneDeep(batch.resources);
});

export const batchResourceSelector = createSelector(batchSelector, (batch) => batch.resource);

export const batchMetaSelector = createSelector(batchSelector, (batch) => batch.metaResources);
export const batchMetaResourceSelector = createSelector(batchSelector, (batch) => batch.metaResource);

export default batchReducer;

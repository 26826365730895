import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_ANIMAL_IDENTITY_TYPE } from './config';

export type CodesAnimalIdentityTypePayload = {
  id: number;
  id_for_type_identifier: string;
  note: string;
  unique_identifier: string;
  transponder: string;
  animal_identifier_type_datetime: string;
};

export type CodesAnimalIdentityTypeSerialized = {
  id: number;
  idForTypeIdentifier: string;
  note: string;
  uniqueIdentifier: string;
  transponder: string;
  animalIdentifierTypeDatetime: string;
};

const { columns, serialized, unserialized } = CODES_ANIMAL_IDENTITY_TYPE.CodesAnimalIdentityType;

const getDefaultSerializer = (data: DocumentData<CodesAnimalIdentityTypePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.idForTypeIdentifier.unserialized, columns.idForTypeIdentifier.serialized),
      new CellSerializer(columns.note.unserialized, columns.note.serialized),
      new CellSerializer(columns.uniqueIdentifier.unserialized, columns.uniqueIdentifier.serialized),
      new CellSerializer(columns.transponder.unserialized, columns.transponder.serialized),
      new CellSerializer(
        columns.animalIdentifierTypeDatetime.unserialized,
        columns.animalIdentifierTypeDatetime.serialized,
      ),
    ]),
  ]);
};

export const serializeCodesAnimalIdentityType = (serverData: ResourcesWrapper<CodesAnimalIdentityTypePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Languages } from 'lib/localization';

import { userLocaleSelector } from 'store/reducers/userSettingsReducer';
import { setLocale } from 'store/reducers/userSettingsReducer';

import { Select, MenuItem, FormControl, makeStyles } from '@material-ui/core';

import { COLORS } from 'theme/colors';

type OwnProps = {
  color?: 'primary' | 'secondary';
};

const LanguageSwitcher: FC<OwnProps> = ({ color }) => {
  const classes = useStyles({ color });
  const dispatch = useDispatch();
  const locale = useSelector(userLocaleSelector);

  const languages = React.useMemo(
    () => Object.entries(Languages).map((entry) => ({ code: entry[0], label: entry[1] })),
    [],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => dispatch(setLocale(e.target.value as keyof typeof Languages)),
    [dispatch],
  );

  return (
    <FormControl variant="standard">
      <Select
        id="language-simple-select-filled"
        value={locale}
        onChange={handleChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          style: { zIndex: 1500 },
        }}
        color={color}
        disableUnderline
        className={classes.select}
        data-testid="language-select-dropdown"
      >
        {languages.map(({ code, label }) => (
          <MenuItem key={code} value={code} data-testid={`language-selection-item-${code}`}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles(() => ({
  select: ({ color }: OwnProps) => ({
    color: `${color === 'primary' ? COLORS.blue : color === 'secondary' ? COLORS.pink : 'white'} !important`,

    '& > svg': {
      fill: color === 'primary' ? COLORS.blue : color === 'secondary' ? COLORS.pink : 'white',
    },
  }),
}));

export default LanguageSwitcher;

import { AxiosPromise, AxiosResponse } from 'axios';
import { merge } from 'lodash';

import { Params } from '../ensure';
import { Axios } from './HttpClient';
import { Method } from './ApiClient';

export interface ApiCall<P = any> {
  (params: any): AxiosPromise<PromisePayload<P>>;
  method: Method;
  url: ((args: Params) => string) | string;
}

export type PromisePayload<P> = {
  payload: P;
  meta?: Record<string, unknown>;
  requestPayload: Record<string, unknown>;
  headers: Record<string, unknown>;
};

// dynamic url helper
export const api = <T extends Params>(
  method: Method,
  url: ((args: T) => string) | string,
  predefinedParams?: object,
) => {
  // dispatch params in action payload - ensure renames it to urlParams
  const apiCall = ({ urlParams, baseURL, ...args }: T) => {
    return Axios({
      baseURL,
      method,
      url: typeof url === 'function' ? url(urlParams) : url,
      ...merge({}, predefinedParams, args),
    });
  };

  // need to decide into which axios config field put payload in ensure helper
  apiCall.method = method;
  apiCall.url = url;

  return apiCall;
};

export const createResponseInterceptor = (
  onSuccess = (response: AxiosResponse) => response,
  onError = (error: any) => Promise.reject(error),
) => {
  return Axios.interceptors.response.use(onSuccess, onError);
};

export type ResourceWrapper<T = any> = AxiosResponse<{
  resource: T;
}>;

export type ResourcesWrapper<T = any> = AxiosResponse<{
  resources: T[];
}>;

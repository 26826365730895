import { IntlKeys } from 'lib/localization/keys/__keys';

enum Cl607Original {
  Cl607 = 'cl607',
}

enum Cl607Serialized {
  Cl607 = 'Cl607',
}

enum Cl607Unserialized {
  Cl607 = 'Cl607',
}

enum Cl607OriginalHeaders {
  'code' = 'code',
  'description' = 'description',
  'remarks' = 'notes',
}

enum Cl607SerializedHeaders {
  'code' = 'code',
  'description' = 'description',
  'remarks' = 'remarks',
}
enum Cl607UnserializedHeaders {
  'code' = 'code',
  'description' = 'description',
  'remarks' = 'remarks',
}

export const CL607_FIELDS = {
  [Cl607Unserialized.Cl607]: {
    original: Cl607Original.Cl607,
    serialized: Cl607Serialized.Cl607,
    unserialized: Cl607Unserialized.Cl607,
    view: IntlKeys.referenceTablesCl607,
    viewName: 'cl607',

    columns: {
      [Cl607SerializedHeaders.code]: {
        original: Cl607OriginalHeaders.code,
        serialized: Cl607SerializedHeaders.code,
        unserialized: Cl607UnserializedHeaders.code,
        view: IntlKeys.referenceTablesCl607Code,
      },
      [Cl607SerializedHeaders.description]: {
        original: Cl607OriginalHeaders.description,
        serialized: Cl607SerializedHeaders.description,
        unserialized: Cl607UnserializedHeaders.description,
        view: IntlKeys.referenceTablesCl607Description,
      },
      [Cl607SerializedHeaders.remarks]: {
        original: Cl607OriginalHeaders.remarks,
        serialized: Cl607SerializedHeaders.remarks,
        unserialized: Cl607UnserializedHeaders.remarks,
        view: IntlKeys.referenceTablesCl607Remarks,
      },
    },
  },
};

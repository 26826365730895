import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CL649 } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type Cl649Payload = {
  id: number;
  code: number | null;
  description: string | null;
  notes: string | null;
  charge_or_premium_code_type_upload: ManageUploadPayload;
};

export type Cl649Serialized = {
  id: number;
  referenceUploadID: number;
  code: number;
  description: string;
  remarks: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = CL649.Cl649;

const getDefaultSerializer = (data: DocumentData<Cl649Payload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.code.unserialized, columns.code.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.remarks.unserialized, columns.remarks.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeCl649 = (serverData: ResourcesWrapper<Cl649Payload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';

export const userPassports = new Route('get', new RouteParser('/mock/user_passports'), () => {
  return [
    200,
    {
      resources: [
        {
          id: 2,
          registration_name: 'Kub LLC',
          kvk: 'fmrzlfymkp',
          concept: 'GF Balance',
          ubn: 'ozlrqhutde',
          gln: 'yqnhipmmor',
          btw: 'ajatmweyus',
          vion_relation_number: 'edniikvfig',
          certificates: 'dolore',
          certificates_other: 'voluptates',
          genetic_suppliers: ['aut', 'quia'],
          genetic_supplier_other: 'numquam',
          ki_stations: ['consequatur', 'dolor'],
          ki_station_other: 'consequatur',
          sow_line: 'inventore',
          sow_line_other: 'itaque',
          breeding_program: 'velit',
          sire_lines: ['optio', 'voluptatibus'],
          sire_line_other: 'ea',
          veterinary_practice: 'qui',
          veterinary_practice_other: 'rem',
          feed_supplier_other: 'beatae',
          fms_names: ['quis', 'reiciendis'],
          fms_module_agrisysts: ['rerum', 'voluptatem'],
          fms_module_agro_coops: ['dolorum', 'nemo'],
          fms_module_agrovisions: ['totam', 'est'],
          feeding_systems: ['quos', 'quaerat'],
          feeding_system_other: 'ut',
          climate_systems: ['consequatur', 'et'],
          climate_system_other: 'atque',
          street: 'Bruen Square',
          postcode: '64577',
          place: 'New Mexico',
          phone: '(666) 414-4598',
          certification_authority: 'Dolorem et.',
          feed_suppliers: 'Ut doloremque.',
          created_at: '2021-02-03T05:56:14.445Z',
          updated_at: '2021-02-03T05:56:14.445Z',
          passport_owners: [
            {
              id: 2,
              user_type: 'farmer',
              email: '2jeenia@yahoo.com',
              full_name: 'Miss Hollis Lubowitz',
              user_type_descriptions: ['tempora', 'et', 'placeat'],
            },
            {
              id: 3,
              user_type: 'farmer',
              email: '3timothy@hotmail.com',
              full_name: 'Fredrick Miller',
              user_type_descriptions: ['est', 'dolores', 'qui'],
            },
          ],
          organization: {
            id: 1,
            kvk: 'fmrzlfymkp',
            kvk_company_name: 'Kub LLC',
            email: '1garfield@yahoo.com',
          },
          organization_permission_profile: null,
        },
        {
          id: 1,
          registration_name: 'Kub LLC',
          kvk: 'fmrzlfymkp',
          concept: 'GF Balance',
          ubn: 'wtszvnfvee',
          gln: 'otgtjozrzp',
          btw: 'flshdgknjs',
          vion_relation_number: 'emsnowhuwt',
          certificates: 'laboriosam',
          certificates_other: 'quos',
          genetic_suppliers: ['incidunt', 'molestias'],
          genetic_supplier_other: 'voluptate',
          ki_stations: ['aperiam', 'amet'],
          ki_station_other: 'est',
          sow_line: 'repellendus',
          sow_line_other: 'explicabo',
          breeding_program: 'ipsum',
          sire_lines: ['quis', 'odit'],
          sire_line_other: 'aut',
          veterinary_practice: 'fuga',
          veterinary_practice_other: 'illum',
          feed_supplier_other: 'ut',
          fms_names: ['sequi', 'commodi'],
          fms_module_agrisysts: ['ullam', 'vel'],
          fms_module_agro_coops: ['reprehenderit', 'quo'],
          fms_module_agrovisions: ['id', 'harum'],
          feeding_systems: ['qui', 'facere'],
          feeding_system_other: 'aperiam',
          climate_systems: ['possimus', 'et'],
          climate_system_other: 'ea',
          street: 'Cody Valleys',
          postcode: '00000',
          place: 'West Virginia',
          phone: '409.341.0454',
          certification_authority: 'Dicta quis.',
          feed_suppliers: 'Sunt qui.',
          created_at: '2021-02-03T05:56:14.430Z',
          updated_at: '2021-02-03T05:56:14.430Z',
          passport_owners: [
            {
              id: 2,
              user_type: 'farmer',
              email: '2jeenia@yahoo.com',
              full_name: 'Miss Hollis Lubowitz',
              user_type_descriptions: ['tempora', 'et', 'placeat'],
            },
            {
              id: 3,
              user_type: 'farmer',
              email: '3timothy@hotmail.com',
              full_name: 'Fredrick Miller',
              user_type_descriptions: ['est', 'dolores', 'qui'],
            },
          ],
          organization: {
            id: 1,
            kvk: 'fmrzlfymkp',
            kvk_company_name: 'Kub LLC',
            email: '1garfield@yahoo.com',
          },
          organization_permission_profile: null,
        },
      ],
    },
  ];
});

export const adminUserPassports = new Route('get', new RouteParser('/mock/admin/user_passports'), () => {
  return [
    200,
    {
      resources: [
        {
          id: 5,
          registration_name: 'Spinka-Weissnat',
          kvk: 'pwcjokccca',
          concept: 'GFS/AH',
          ubn: 'ixgxysijyl',
          gln: 'fvldmyrutk',
          btw: 'metybutesq',
          vion_relation_number: 'ckbefhxpyz',
          certificates: 'quaerat',
          certificates_other: 'corporis',
          genetic_suppliers: ['fuga', 'delectus'],
          genetic_supplier_other: 'explicabo',
          ki_stations: ['et', 'facilis'],
          ki_station_other: 'rem',
          sow_line: 'qui',
          sow_line_other: 'fugiat',
          breeding_program: 'voluptatem',
          sire_lines: ['officiis', 'inventore'],
          sire_line_other: 'deserunt',
          veterinary_practice: 'eaque',
          veterinary_practice_other: 'nihil',
          feed_supplier_other: 'eligendi',
          fms_names: ['tenetur', 'enim'],
          fms_module_agrisysts: ['qui', 'fugit'],
          fms_module_agro_coops: ['molestiae', 'saepe'],
          fms_module_agrovisions: ['fuga', 'explicabo'],
          feeding_systems: ['eligendi', 'rerum'],
          feeding_system_other: 'eius',
          climate_systems: ['id', 'debitis'],
          climate_system_other: 'illo',
          street: 'Jacobs Radial',
          postcode: '66935',
          place: 'Montana',
          phone: '(965) 676-1951',
          certification_authority: 'Nisi enim.',
          feed_suppliers: 'Incidunt expedita.',
          created_at: '2021-02-03T05:53:30.995Z',
          updated_at: '2021-02-03T05:53:30.995Z',
          permission_profile_template_id: null,
          organization_permission_profile: null,
          passport_owners: [],
          organization: {
            id: 5,
            kvk: 'pwcjokccca',
            kvk_company_name: 'Spinka-Weissnat',
            email: '5burt@gmail.com',
          },
          updated_by: {
            email: '5cleotilde@hotmail.com',
            full_name: 'Lucien Cronin',
          },
        },
        {
          id: 4,
          registration_name: 'Schuppe Inc',
          kvk: 'rlxuqrlaav',
          concept: 'GFS/AH',
          ubn: 'uhinmxsqix',
          gln: 'gwhforugmf',
          btw: 'jughjydhtx',
          vion_relation_number: 'livvxqsohv',
          certificates: 'voluptas',
          certificates_other: 'sit',
          genetic_suppliers: ['maxime', 'saepe'],
          genetic_supplier_other: 'soluta',
          ki_stations: ['corrupti', 'ipsam'],
          ki_station_other: 'qui',
          sow_line: 'consequatur',
          sow_line_other: 'eligendi',
          breeding_program: 'eum',
          sire_lines: ['illum', 'a'],
          sire_line_other: 'inventore',
          veterinary_practice: 'est',
          veterinary_practice_other: 'maiores',
          feed_supplier_other: 'vitae',
          fms_names: ['mollitia', 'natus'],
          fms_module_agrisysts: ['placeat', 'fuga'],
          fms_module_agro_coops: ['nisi', 'dolorem'],
          fms_module_agrovisions: ['qui', 'eveniet'],
          feeding_systems: ['sequi', 'iste'],
          feeding_system_other: 'accusantium',
          climate_systems: ['qui', 'culpa'],
          climate_system_other: 'culpa',
          street: 'Hessel Via',
          postcode: '44830-2708',
          place: 'Connecticut',
          phone: '744-624-2867',
          certification_authority: 'A placeat.',
          feed_suppliers: 'Suscipit odio.',
          created_at: '2021-02-03T05:53:30.984Z',
          updated_at: '2021-02-03T05:53:30.984Z',
          permission_profile_template_id: null,
          organization_permission_profile: null,
          passport_owners: [],
          organization: {
            id: 4,
            kvk: 'rlxuqrlaav',
            kvk_company_name: 'Schuppe Inc',
            email: '4jerrod.kiehn@gmail.com',
          },
          updated_by: {
            email: '4demetrius@gmail.com',
            full_name: 'Oliver Muller',
          },
        },
        {
          id: 3,
          registration_name: "O'Reilly-Green",
          kvk: 'npglxvdktu',
          concept: 'GFS/AH',
          ubn: 'mgpnxrrdkp',
          gln: 'kvmleeuyht',
          btw: 'zayvxouyyy',
          vion_relation_number: 'zdmfqxhbfh',
          certificates: 'dolores',
          certificates_other: 'ut',
          genetic_suppliers: ['repellat', 'quasi'],
          genetic_supplier_other: 'vel',
          ki_stations: ['totam', 'laborum'],
          ki_station_other: 'excepturi',
          sow_line: 'laboriosam',
          sow_line_other: 'laborum',
          breeding_program: 'provident',
          sire_lines: ['ex', 'sunt'],
          sire_line_other: 'consequuntur',
          veterinary_practice: 'quod',
          veterinary_practice_other: 'at',
          feed_supplier_other: 'officia',
          fms_names: ['nisi', 'et'],
          fms_module_agrisysts: ['et', 'delectus'],
          fms_module_agro_coops: ['nihil', 'et'],
          fms_module_agrovisions: ['id', 'et'],
          feeding_systems: ['et', 'numquam'],
          feeding_system_other: 'alias',
          climate_systems: ['libero', 'molestiae'],
          climate_system_other: 'amet',
          street: 'Isadora Springs',
          postcode: '63749',
          place: 'Idaho',
          phone: '(759) 556-3178',
          certification_authority: 'Repellat accusantium.',
          feed_suppliers: 'Delectus est.',
          created_at: '2021-02-03T05:53:30.972Z',
          updated_at: '2021-02-03T05:53:30.972Z',
          permission_profile_template_id: null,
          organization_permission_profile: null,
          passport_owners: [],
          organization: {
            id: 3,
            kvk: 'npglxvdktu',
            kvk_company_name: "O'Reilly-Green",
            email: '3claribel.toy@yahoo.com',
          },
          updated_by: {
            email: '3carina_brekke@gmail.com',
            full_name: 'Golda Carroll',
          },
        },
        {
          id: 2,
          registration_name: 'Rogahn, Bauch and Kautzer',
          kvk: 'qmyfhyoezg',
          concept: 'GFS/AH',
          ubn: 'dpqwyzjmkc',
          gln: 'csyggddppg',
          btw: 'ucouzoafpc',
          vion_relation_number: 'rmrmsujpkd',
          certificates: 'eius',
          certificates_other: 'consectetur',
          genetic_suppliers: ['expedita', 'consequatur'],
          genetic_supplier_other: 'quis',
          ki_stations: ['ullam', 'vitae'],
          ki_station_other: 'et',
          sow_line: 'eos',
          sow_line_other: 'autem',
          breeding_program: 'vel',
          sire_lines: ['non', 'enim'],
          sire_line_other: 'eveniet',
          veterinary_practice: 'tempore',
          veterinary_practice_other: 'optio',
          feed_supplier_other: 'tempora',
          fms_names: ['maiores', 'esse'],
          fms_module_agrisysts: ['vel', 'tempora'],
          fms_module_agro_coops: ['molestias', 'voluptatem'],
          fms_module_agrovisions: ['vitae', 'autem'],
          feeding_systems: ['est', 'repellendus'],
          feeding_system_other: 'molestias',
          climate_systems: ['quaerat', 'minus'],
          climate_system_other: 'temporibus',
          street: 'Anibal Land',
          postcode: '83708-5216',
          place: 'Minnesota',
          phone: '176.008.4324',
          certification_authority: 'Ipsum quo.',
          feed_suppliers: 'Dolorem distinctio.',
          created_at: '2021-02-03T05:53:30.957Z',
          updated_at: '2021-02-03T05:53:30.957Z',
          permission_profile_template_id: null,
          organization_permission_profile: null,
          passport_owners: [],
          organization: {
            id: 2,
            kvk: 'qmyfhyoezg',
            kvk_company_name: 'Rogahn, Bauch and Kautzer',
            email: '2michaele@hotmail.com',
          },
          updated_by: {
            email: '2cara@gmail.com',
            full_name: 'Ok Schmeler',
          },
        },
        {
          id: 1,
          registration_name: 'Corkery, Hermann and Borer',
          kvk: 'trkspcivoq',
          concept: 'GFS',
          ubn: 'ykkdzmxmyy',
          gln: 'usvmtwkeub',
          btw: 'zpnfovbnrk',
          vion_relation_number: 'ovnspwqzvw',
          certificates: 'libero',
          certificates_other: 'est',
          genetic_suppliers: ['qui', 'rerum'],
          genetic_supplier_other: 'minus',
          ki_stations: ['voluptas', 'delectus'],
          ki_station_other: 'sint',
          sow_line: 'corrupti',
          sow_line_other: 'perspiciatis',
          breeding_program: 'dolorum',
          sire_lines: ['voluptatem', 'esse'],
          sire_line_other: 'accusamus',
          veterinary_practice: 'fugit',
          veterinary_practice_other: 'voluptatem',
          feed_supplier_other: 'quam',
          fms_names: ['repellat', 'eum'],
          fms_module_agrisysts: ['ratione', 'repellendus'],
          fms_module_agro_coops: ['vero', 'quaerat'],
          fms_module_agrovisions: ['est', 'qui'],
          feeding_systems: ['dolorum', 'culpa'],
          feeding_system_other: 'minima',
          climate_systems: ['velit', 'ipsum'],
          climate_system_other: 'sunt',
          street: 'Mohamed Corner',
          postcode: '04375',
          place: 'New Jersey',
          phone: '1-206-754-6410',
          certification_authority: 'Optio repellat.',
          feed_suppliers: 'Quia doloremque.',
          created_at: '2021-02-03T05:53:30.943Z',
          updated_at: '2021-02-03T05:53:30.943Z',
          permission_profile_template_id: null,
          organization_permission_profile: null,
          passport_owners: [],
          organization: {
            id: 1,
            kvk: 'trkspcivoq',
            kvk_company_name: 'Corkery, Hermann and Borer',
            email: '1sarah_harris@yahoo.com',
          },
          updated_by: {
            email: '1prince_stokes@hotmail.com',
            full_name: 'Tari Mohr',
          },
        },
      ],
    },
  ];
});

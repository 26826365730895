import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';

import { DataContext } from '../../index';
import { ViewProps } from '../index';

import DataCard from './DataCard';
import { useResponsiveXS } from './utils';

const CardView: React.FC<ViewProps> = ({
  //
  // title,
  data,
  // isLoading,
}) => {
  const [xs] = useResponsiveXS();
  const { currentTab } = useContext(DataContext);
  const { CardComponent } = currentTab;

  const Card = CardComponent ? CardComponent : DataCard;

  return (
    <Grid container spacing={2}>
      {data.map((item, ix) => (
        <Card key={ix} data={item} xs={xs} />
      ))}
    </Grid>
  );
};

export default React.memo(CardView);

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { PENS_LEVEL } from 'store/entities/referenceTables/pensLevel/config';

const { columns, view, serialized } = PENS_LEVEL.PensLevel;

export const pensLevelTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.penLevel.view,
          field: columns.penLevel.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.penLevel.serialized] && roundToInteger(data[columns.penLevel.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.penNameNL.view,
          field: columns.penNameNL.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.penNameEN.view,
          field: columns.penNameEN.serialized,
        },
      }),
    ],
  }),
]);

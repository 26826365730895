import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper } from 'lib/http/utils';

import { FTP_UPDATES } from './config';

export type FtpUpdatesPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  upload_type: string;
  file_name: string;
  upload_source: string;
  created_at: string;
  updated_at: string | null;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type FtpUpdatesSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  uploadType: string;
  fileName: string;
  uploadSource: string;
  createdAt: string;
  updatedAt: string | null;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns } = FTP_UPDATES.FtpUpdates;

const getDefaultSerializer = (data: DocumentData<FtpUpdatesPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(FTP_UPDATES.FtpUpdates.unserialized, FTP_UPDATES.FtpUpdates.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.fileName.unserialized, columns.fileName.serialized),
      new CellSerializer(columns.uploadSource.unserialized, columns.uploadSource.serialized),
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeFtpUpdates = (serverData: ResourcesWrapper<FtpUpdatesPayload>) => {
  const { unserialized } = FTP_UPDATES.FtpUpdates;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

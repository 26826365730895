import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';
import { RootState } from '../store';

import { asyncAction, syncAction } from 'lib/actions';
import { UserPassportSerialized } from 'store/entities/userPassports';
import {
  StableResourceSerialized,
  StableSerialized,
  FarmEventSerialized,
  FarmProductStoredValuesPayload,
  FarmFermentationStoredValuesPayload,
} from 'store/entities/farm';
import { CvbTableSerialized } from 'store/entities/referenceTables/cvbTable';

const initialState = {
  organizations: {
    resources: [] as UserPassportSerialized[],
    isLoading: false,
    selectedUbn: null as null | string,
    selectedDepartment: null as null | string,
  },
  stables: {
    resource: {} as StableResourceSerialized,
    isLoading: false,
  },
  events: {
    resources: [] as FarmEventSerialized[],
    isLoading: false,
  },
  reports: {
    resource: {},
    isLoading: false,
    isLoaded: false,
  },
  // storedValues: {
  //   productsValues: {} as FarmProductStoredValuesPayload,
  //   fermentationValues: {} as FarmFermentationStoredValuesPayload,
  //   isLoading: false,
  //   isLoaded: false,
  // },
  productsStoredValues: {
    isLoading: false,
    isLoaded: false,
    resources: {} as FarmProductStoredValuesPayload,
  },
  fermentationStoredValues: {
    isLoading: false,
    isLoaded: false,
    resources: {} as FarmFermentationStoredValuesPayload,
  },
  byProductCarbonValues: {
    isLoading: false,
    isLoaded: false,
    resource: {} as CvbTableSerialized,
    isRequested: false,
    isFound: false,
  },
};

// stables
export const fetchOrganizationPassport = asyncAction('farm/fetchOrganizationPassport');
export const fetchPassportStatuses = asyncAction('farm/fetchPassportStatuses');
export const farmGetStables = asyncAction('farm/farmGetStables');
export const farmUpdateStables = asyncAction('farm/farmUpdateStables');
export const farmDeleteStable = asyncAction('farm/farmDeleteStable');
// reports
export const farmCreateDeathReport = asyncAction('farm/farmCreateDeathReport');
export const farmCreateDeliveryReport = asyncAction('farm/farmCreateDeliveryReport');
export const farmCreateSupportReport = asyncAction('farm/farmCreateSupportReport');
export const farmCreateFeedConsumptionReport = asyncAction('farm/farmCreateFeedConsumptionReport');

export const farmGetDeathReport = asyncAction('farm/farmGetDeathReport');
export const farmGetDeliveryReport = asyncAction('farm/farmGetDeliveryReport');
export const farmGetSupportReport = asyncAction('farm/farmGetSupportReport');
export const farmGetFeedConsumptionReport = asyncAction('farm/farmGetFeedConsumptionReport');

export const farmUpdateDeathReport = asyncAction('farm/farmUpdateDeathReport');
export const farmUpdateDeliveryReport = asyncAction('farm/farmUpdateDeliveryReport');
export const farmUpdateSupportReport = asyncAction('farm/farmUpdateSupportReport');
export const farmUpdateFeedConsumptionReport = asyncAction('farm/farmUpdateFeedConsumptionReport');

export const farmDeleteDeathReport = asyncAction('farm/farmDeleteDeathReport');
export const farmDeleteDeliveryReport = asyncAction('farm/farmDeleteDeliveryReport');
export const farmDeleteSupportReport = asyncAction('farm/farmDeleteSupportReport');
export const farmDeleteFeedConsumptionReport = asyncAction('farm/farmDeleteFeedConsumptionReport');

export const fetchProductReportsStoredValues = asyncAction('farm/farmGetProductReportsStoredValues');
export const fetchFermentationReportsStoredValues = asyncAction('farm/fetchFermentationReportsStoredValues');

export const fetchByProductsCarbonValues = asyncAction('farm/fetchByProductsCarbonValues');
export const clearByProductsCarbonValues = syncAction('farm/clearByProductsCarbonValues');
export const clearByProductsCarbonValuesIsRequested = syncAction('farm/clearByProductsCarbonValuesIsRequested');

// updated log
export const farmGetEvents = asyncAction('farm/farmGetEvents');
// sync actions
export const farmSetSelectedUbn = syncAction<string | null>('farm/farmSetSelectedUbn');
export const farmSetSelectedDepartment = syncAction<string | null>('farm/farmSetSelectedDepartment');

export const farmReducer = createReducer(initialState, {
  //
  [fetchOrganizationPassport.request]: (draft) => {
    draft.organizations.resources = initialState.organizations.resources;
    draft.organizations.isLoading = true;
  },

  [fetchOrganizationPassport.success]: (draft, { payload }) => {
    draft.organizations.resources = payload;
    draft.organizations.isLoading = false;
    if (payload[0]?.ubn) {
      draft.organizations.selectedUbn = payload[0].ubn;
    }
  },

  [fetchOrganizationPassport.failure]: (draft) => {
    draft.organizations.resources = initialState.organizations.resources;
    draft.organizations.isLoading = false;
  },

  //

  [farmGetStables.request]: (draft) => {
    draft.stables.resource = initialState.stables.resource;
    draft.stables.isLoading = true;
    draft.organizations.selectedDepartment = initialState.organizations.selectedDepartment;
  },

  [farmGetStables.success]: (draft, { payload }) => {
    draft.stables.resource = payload;
    draft.stables.isLoading = false;
    if (payload?.stables?.length) {
      draft.organizations.selectedDepartment = payload.stables[0].id;
    }
  },

  [farmGetStables.failure]: (draft) => {
    draft.stables.resource = initialState.stables.resource;
    draft.stables.isLoading = false;
  },

  //

  [farmGetEvents.request]: (draft) => {
    draft.events.resources = initialState.events.resources;
    draft.events.isLoading = true;
  },

  [farmGetEvents.success]: (draft, { payload }) => {
    draft.events.resources = payload;
    draft.events.isLoading = false;
  },

  [farmGetEvents.failure]: (draft) => {
    draft.events.resources = initialState.events.resources;
    draft.events.isLoading = false;
  },
  //

  [farmGetDeathReport.request]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = true;
    draft.reports.isLoaded = false;
  },

  [farmGetDeathReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },

  [farmGetDeathReport.failure]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },

  //

  [farmGetDeliveryReport.request]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = true;
    draft.reports.isLoaded = false;
  },

  [farmGetDeliveryReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },

  [farmGetDeliveryReport.failure]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },

  //

  [farmGetSupportReport.request]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = true;
    draft.reports.isLoaded = false;
  },

  [farmGetSupportReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },

  [farmGetSupportReport.failure]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },

  //

  [farmGetFeedConsumptionReport.request]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = true;
    draft.reports.isLoaded = false;
  },

  [farmGetFeedConsumptionReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },

  [farmGetFeedConsumptionReport.failure]: (draft) => {
    draft.reports.resource = initialState.reports.resource;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },
  //

  [farmUpdateDeathReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },
  //

  [farmUpdateDeliveryReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },
  //

  [farmUpdateSupportReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },
  //

  [farmUpdateFeedConsumptionReport.success]: (draft, { payload }) => {
    draft.reports.resource = payload;
    draft.reports.isLoading = false;
    draft.reports.isLoaded = true;
  },
  //

  [farmUpdateStables.success]: (draft, { payload }) => {
    draft.stables.resource = payload;
    draft.stables.isLoading = false;
  },

  [fetchProductReportsStoredValues.request]: (draft) => {
    draft.productsStoredValues.resources = initialState.productsStoredValues.resources;
    draft.productsStoredValues.isLoading = true;
    draft.productsStoredValues.isLoaded = false;
  },

  [fetchProductReportsStoredValues.success]: (draft, { payload }) => {
    const { product_code, product_name, product_origin, product_origin_detailed } = payload;
    draft.productsStoredValues.resources = {
      productCode: product_code,
      productName: product_name,
      productOrigin: product_origin,
      productOriginDetailed: product_origin_detailed,
    };
    draft.productsStoredValues.isLoading = false;
    draft.productsStoredValues.isLoaded = true;
  },

  [fetchProductReportsStoredValues.failure]: (draft) => {
    draft.productsStoredValues.resources = initialState.productsStoredValues.resources;
    draft.productsStoredValues.isLoading = false;
    draft.productsStoredValues.isLoaded = true;
  },

  [fetchFermentationReportsStoredValues.request]: (draft) => {
    draft.fermentationStoredValues.resources = initialState.fermentationStoredValues.resources;
    draft.fermentationStoredValues.isLoading = true;
    draft.fermentationStoredValues.isLoaded = false;
  },

  [fetchFermentationReportsStoredValues.success]: (draft, { payload }) => {
    const { product_code, product_name, supplier_name } = payload;
    draft.fermentationStoredValues.resources = {
      productCode: product_code,
      productName: product_name,
      supplierName: supplier_name,
    };
    draft.fermentationStoredValues.isLoading = false;
    draft.fermentationStoredValues.isLoaded = true;
  },

  [fetchFermentationReportsStoredValues.failure]: (draft) => {
    draft.fermentationStoredValues.resources = initialState.fermentationStoredValues.resources;
    draft.fermentationStoredValues.isLoading = false;
    draft.fermentationStoredValues.isLoaded = true;
  },

  [fetchByProductsCarbonValues.request]: (draft) => {
    draft.byProductCarbonValues.resource = initialState.byProductCarbonValues.resource;
    draft.byProductCarbonValues.isLoading = true;
    draft.byProductCarbonValues.isLoaded = false;
    draft.byProductCarbonValues.isRequested = true;
    draft.byProductCarbonValues.isFound = false;
  },

  [fetchByProductsCarbonValues.success]: (draft, { payload }) => {
    draft.byProductCarbonValues.resource = payload;
    draft.byProductCarbonValues.isLoading = false;
    draft.byProductCarbonValues.isLoaded = true;
    draft.byProductCarbonValues.isFound = true;
  },

  [fetchByProductsCarbonValues.failure]: (draft) => {
    draft.byProductCarbonValues.resource = initialState.byProductCarbonValues.resource;
    draft.byProductCarbonValues.isLoading = false;
    draft.byProductCarbonValues.isLoaded = true;
    draft.byProductCarbonValues.isFound = false;
  },

  [clearByProductsCarbonValues.type]: (draft) => {
    draft.byProductCarbonValues.isLoading = false;
    draft.byProductCarbonValues.isLoaded = true;
    draft.byProductCarbonValues.resource = initialState.byProductCarbonValues.resource;
    draft.byProductCarbonValues.isFound = false;
  },

  [clearByProductsCarbonValuesIsRequested.type]: (draft) => {
    draft.byProductCarbonValues.isLoading = false;
    draft.byProductCarbonValues.isLoaded = true;
    draft.byProductCarbonValues.resource = initialState.byProductCarbonValues.resource;
    draft.byProductCarbonValues.isFound = false;
    draft.byProductCarbonValues.isRequested = false;
  },

  //
  //
  //

  [farmSetSelectedUbn.type]: (draft, { payload }) => {
    draft.organizations.selectedUbn = payload;
  },
  [farmSetSelectedDepartment.type]: (draft, { payload }) => {
    draft.organizations.selectedDepartment = payload;
  },
});

const farmSelector = (state: RootState) => state.farm;
export const farmOrganizationsSelector = createSelector(farmSelector, (farm) => farm.organizations);
export const farmOrganizationsResourceSelector = createSelector(
  farmOrganizationsSelector,
  (organizations) => organizations.resources,
);
export const farmOrganizationsIsLoadingSelector = createSelector(
  farmOrganizationsSelector,
  (organizations) => organizations.isLoading,
);
export const farmOrganizationsSelectedUbnSelector = createSelector(
  farmOrganizationsSelector,
  (organizations) => organizations.selectedUbn,
);
export const farmOrganizationsSelectedDepartmentSelector = createSelector(
  farmOrganizationsSelector,
  (organizations) => organizations.selectedDepartment,
);
export const farmStablesSelector = createSelector(farmSelector, (farm) => farm.stables);
export const farmStablesResourceSelector = createSelector(farmStablesSelector, (stables) => stables.resource);
export const farmStablesArraySelector = createSelector(
  farmStablesResourceSelector,
  (resource) => resource?.stables || ([] as StableSerialized[]),
);
export const farmEventsSelector = createSelector(farmSelector, (farm) => farm.events);
export const farmEventsResourcesSelector = createSelector(farmEventsSelector, (events) => cloneDeep(events.resources));
export const farmEventsIsLoadingSelector = createSelector(farmEventsSelector, (events) => events.isLoading);

export const farmReportSelector = createSelector(farmSelector, (farm) => farm.reports);
export const farmReportResourceSelector = createSelector(farmReportSelector, (report) => report.resource);
export const farmReportIsLoadingSelector = createSelector(farmReportSelector, (report) => report.isLoading);
export const farmReportIsLoadedSelector = createSelector(farmReportSelector, (report) => report.isLoaded);
export const farmProductStoredValues = createSelector(farmSelector, (farm) => farm.productsStoredValues);
export const farmFermentationStoredValues = createSelector(farmSelector, (farm) => farm.fermentationStoredValues);
export const farmByProductsCarbonValues = createSelector(farmSelector, (farm) => farm.byProductCarbonValues);

export default farmReducer;

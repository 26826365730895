import { IntlKeys } from 'lib/localization/keys';

enum GroupRemovalSerialized {
  GroupRemoval = 'GroupRemoval',
}

enum GroupRemovalUnserialized {
  GroupRemoval = 'GroupRemoval',
}

export enum GroupRemovalSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  finEventIdentifier = 'finEventIdentifier',
  alive = 'alive',
  groupRemovalNumber = 'groupRemovalNumber',
  weightSlaughtered = 'weightSlaughtered',
  weightAlive = 'weightAlive',
  firstRemreason = 'firstRemreason',
  secondRemreason = 'secondRemreason',
  remtypeIdentifier = 'remtypeIdentifier',
  animalCategoryIdentifier = 'animalCategoryIdentifier',
  groupRemovalDatetime = 'groupRemovalDatetime',
  wcfIdentifier = 'wcfIdentifier',
  notClassified = 'notClassified',
  classType = 'classType',
  nlMeatPerc = 'nlMeatPerc',
  nlAa = 'nlAa',
  nlA = 'nlA',
  nlB = 'nlB',
  nlC = 'nlC',
  nlBackFat = 'nlBackFat',
  nlLoInDepth = 'nlLoInDepth',
  deLachs = 'deLachs',
  deSchinken = 'deSchinken',
  deSchaulter = 'deSchaulter',
  deIndexPunkte = 'deIndexPunkte',
  deIndexFactor = 'deIndexFactor',
  estimatedDateIn = 'estimatedDateIn',
  sexIdentifier = 'sexIdentifier',
  withoutMale = 'withoutMale',
  withoutFemale = 'withoutFemale',
  withoutMaleCastrated = 'withoutMaleCastrated',
  carPleuritis = 'carPleuritis',
  carInfSkin = 'carInfSkin',
  carInfFeed = 'carInfFeed',
  carPleuritisInFeed = 'carPleuritisInFeed',
  carInfSkinAndFeed = 'carInfSkinAndFeed',
  carPleuritisInfSkin = 'carPleuritisInfSkin',
  carPleuritisInfSkinAndFeed = 'carPleuritisInfSkinAndFeed',
  organAffectedLiver = 'organAffectedLiver',
  organRejectedLiver = 'organRejectedLiver',
  organAffectedLungs = 'organAffectedLungs',
  organAffectedLungsAndLiver = 'organAffectedLungsAndLiver',
  organAffectedLungsRejectedLiver = 'organAffectedLungsRejectedLiver',
  qualIdentifier = 'qualIdentifier',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupRemovalUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  finEventIdentifier: 'fin_event_identifier',
  alive: 'alive',
  groupRemovalNumber: 'group_removal_number',
  weightSlaughtered: 'weight_slaughtered',
  weightAlive: 'weight_alive',
  firstRemreason: 'first_remreason',
  secondRemreason: 'second_remreason',
  remtypeIdentifier: 'remtype_identifier',
  animalCategoryIdentifier: 'animal_category_identifier',
  groupRemovalDatetime: 'group_removal_datetime',
  wcfIdentifier: 'wcf_identifier',
  notClassified: 'not_classified',
  classType: 'class_type',
  nlMeatPerc: 'nl_meat_perc',
  nlAa: 'nl_aa',
  nlA: 'nl_a',
  nlB: 'nl_b',
  nlC: 'nl_c',
  nlBackFat: 'nl_back_fat',
  nlLoInDepth: 'nl_lo_in_depth',
  deLachs: 'de_lachs',
  deSchinken: 'de_schinken',
  deSchaulter: 'de_schaulter',
  deIndexPunkte: 'de_index_punkte',
  deIndexFactor: 'de_index_factor',
  estimatedDateIn: 'estimated_date_in',
  sexIdentifier: 'sex_identifier',
  withoutMale: 'without_male',
  withoutFemale: 'without_female',
  withoutMaleCastrated: 'without_male_castrated',
  carPleuritis: 'car_pleuritis',
  carInfSkin: 'car_inf_skin',
  carInfFeed: 'car_inf_feed',
  carPleuritisInFeed: 'car_pleuritis_in_feed',
  carInfSkinAndFeed: 'car_inf_skin_and_feed',
  carPleuritisInfSkin: 'car_pleuritis_inf_skin',
  carPleuritisInfSkinAndFeed: 'car_pleuritis_inf_skinand_feed',
  organAffectedLiver: 'organ_affected_liver',
  organRejectedLiver: 'organ_rejected_liver',
  organAffectedLungs: 'organ_affected_lungs',
  organAffectedLungsAndLiver: 'organ_affected_lungs_and_liver',
  organAffectedLungsRejectedLiver: 'organ_affected_lungs_rejected_liver',
  qualIdentifier: 'qual_identifier',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_REMOVALS = {
  [GroupRemovalSerialized.GroupRemoval]: {
    serialized: GroupRemovalSerialized.GroupRemoval,
    unserialized: GroupRemovalUnserialized.GroupRemoval,
    view: IntlKeys.farmManagementDataRemovalList,

    columns: {
      [GroupRemovalSerializedHeaders.id]: {
        serialized: GroupRemovalSerializedHeaders.id,
        unserialized: GroupRemovalUnserializedHeaders.id,
      },
      [GroupRemovalSerializedHeaders.companyIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.companyIdentifier,
        unserialized: GroupRemovalUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupRemovalSerializedHeaders.farmIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.farmIdentifier,
        unserialized: GroupRemovalUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupRemovalSerializedHeaders.finEventIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.finEventIdentifier,
        unserialized: GroupRemovalUnserializedHeaders.finEventIdentifier,
        view: IntlKeys.farmManagementDataFinEventId,
      },
      [GroupRemovalSerializedHeaders.alive]: {
        serialized: GroupRemovalSerializedHeaders.alive,
        unserialized: GroupRemovalUnserializedHeaders.alive,
        view: IntlKeys.farmManagementDataAlive,
      },
      [GroupRemovalSerializedHeaders.groupRemovalNumber]: {
        serialized: GroupRemovalSerializedHeaders.groupRemovalNumber,
        unserialized: GroupRemovalUnserializedHeaders.groupRemovalNumber,
        view: IntlKeys.farmManagementDataNumber,
      },
      [GroupRemovalSerializedHeaders.weightSlaughtered]: {
        serialized: GroupRemovalSerializedHeaders.weightSlaughtered,
        unserialized: GroupRemovalUnserializedHeaders.weightSlaughtered,
        view: IntlKeys.farmManagementDataWeightSlaughtered,
      },
      [GroupRemovalSerializedHeaders.weightAlive]: {
        serialized: GroupRemovalSerializedHeaders.weightAlive,
        unserialized: GroupRemovalUnserializedHeaders.weightAlive,
        view: IntlKeys.farmManagementDataWeightAlive,
      },
      [GroupRemovalSerializedHeaders.firstRemreason]: {
        serialized: GroupRemovalSerializedHeaders.firstRemreason,
        unserialized: GroupRemovalUnserializedHeaders.firstRemreason,
        view: IntlKeys.farmManagementDataFirstReamson,
      },
      [GroupRemovalSerializedHeaders.secondRemreason]: {
        serialized: GroupRemovalSerializedHeaders.secondRemreason,
        unserialized: GroupRemovalUnserializedHeaders.secondRemreason,
        view: IntlKeys.farmManagementDataSecondReamson,
      },
      [GroupRemovalSerializedHeaders.remtypeIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.remtypeIdentifier,
        unserialized: GroupRemovalUnserializedHeaders.remtypeIdentifier,
        view: IntlKeys.farmManagementDataRemtypeId,
      },
      [GroupRemovalSerializedHeaders.animalCategoryIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.animalCategoryIdentifier,
        unserialized: GroupRemovalUnserializedHeaders.animalCategoryIdentifier,
        view: IntlKeys.farmManagementDataAnimalCategoryId,
      },
      [GroupRemovalSerializedHeaders.groupRemovalDatetime]: {
        serialized: GroupRemovalSerializedHeaders.groupRemovalDatetime,
        unserialized: GroupRemovalUnserializedHeaders.groupRemovalDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupRemovalSerializedHeaders.wcfIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.wcfIdentifier,
        unserialized: GroupRemovalUnserializedHeaders.wcfIdentifier,
        view: IntlKeys.farmManagementDataWcfId,
      },
      [GroupRemovalSerializedHeaders.notClassified]: {
        serialized: GroupRemovalSerializedHeaders.notClassified,
        unserialized: GroupRemovalUnserializedHeaders.notClassified,
        view: IntlKeys.farmManagementDataNotClassified,
      },
      [GroupRemovalSerializedHeaders.classType]: {
        serialized: GroupRemovalSerializedHeaders.classType,
        unserialized: GroupRemovalUnserializedHeaders.classType,
        view: IntlKeys.farmManagementDataClassType,
      },
      [GroupRemovalSerializedHeaders.nlMeatPerc]: {
        serialized: GroupRemovalSerializedHeaders.nlMeatPerc,
        unserialized: GroupRemovalUnserializedHeaders.nlMeatPerc,
        view: IntlKeys.farmManagementDataNlMeatPerc,
      },
      [GroupRemovalSerializedHeaders.nlAa]: {
        serialized: GroupRemovalSerializedHeaders.nlAa,
        unserialized: GroupRemovalUnserializedHeaders.nlAa,
        view: IntlKeys.farmManagementDataNlAa,
      },
      [GroupRemovalSerializedHeaders.nlA]: {
        serialized: GroupRemovalSerializedHeaders.nlA,
        unserialized: GroupRemovalUnserializedHeaders.nlA,
        view: IntlKeys.farmManagementDataNlA,
      },
      [GroupRemovalSerializedHeaders.nlB]: {
        serialized: GroupRemovalSerializedHeaders.nlB,
        unserialized: GroupRemovalUnserializedHeaders.nlB,
        view: IntlKeys.farmManagementDataNlB,
      },
      [GroupRemovalSerializedHeaders.nlC]: {
        serialized: GroupRemovalSerializedHeaders.nlC,
        unserialized: GroupRemovalUnserializedHeaders.nlC,
        view: IntlKeys.farmManagementDataNlC,
      },
      [GroupRemovalSerializedHeaders.nlBackFat]: {
        serialized: GroupRemovalSerializedHeaders.nlBackFat,
        unserialized: GroupRemovalUnserializedHeaders.nlBackFat,
        view: IntlKeys.farmManagementDataNlBackFat,
      },
      [GroupRemovalSerializedHeaders.nlLoInDepth]: {
        serialized: GroupRemovalSerializedHeaders.nlLoInDepth,
        unserialized: GroupRemovalUnserializedHeaders.nlLoInDepth,
        view: IntlKeys.farmManagementDataNlLoInDepth,
      },
      [GroupRemovalSerializedHeaders.deLachs]: {
        serialized: GroupRemovalSerializedHeaders.deLachs,
        unserialized: GroupRemovalUnserializedHeaders.deLachs,
        view: IntlKeys.farmManagementDataDeLachs,
      },
      [GroupRemovalSerializedHeaders.deSchinken]: {
        serialized: GroupRemovalSerializedHeaders.deSchinken,
        unserialized: GroupRemovalUnserializedHeaders.deSchinken,
        view: IntlKeys.farmManagementDataDeSchinken,
      },
      [GroupRemovalSerializedHeaders.nlBackFat]: {
        serialized: GroupRemovalSerializedHeaders.nlBackFat,
        unserialized: GroupRemovalUnserializedHeaders.nlBackFat,
        view: IntlKeys.farmManagementDataNlBackFat,
      },
      [GroupRemovalSerializedHeaders.deSchaulter]: {
        serialized: GroupRemovalSerializedHeaders.deSchaulter,
        unserialized: GroupRemovalUnserializedHeaders.deSchaulter,
        view: IntlKeys.farmManagementDataDeSchaulter,
      },
      [GroupRemovalSerializedHeaders.deIndexPunkte]: {
        serialized: GroupRemovalSerializedHeaders.deIndexPunkte,
        unserialized: GroupRemovalUnserializedHeaders.deIndexPunkte,
        view: IntlKeys.farmManagementDataDeIndexPunkte,
      },
      [GroupRemovalSerializedHeaders.deIndexFactor]: {
        serialized: GroupRemovalSerializedHeaders.deIndexFactor,
        unserialized: GroupRemovalUnserializedHeaders.deIndexFactor,
        view: IntlKeys.farmManagementDataDeIndexFactor,
      },
      [GroupRemovalSerializedHeaders.estimatedDateIn]: {
        serialized: GroupRemovalSerializedHeaders.estimatedDateIn,
        unserialized: GroupRemovalUnserializedHeaders.estimatedDateIn,
        view: IntlKeys.farmManagementDataEstimatedDateIn,
      },
      [GroupRemovalSerializedHeaders.sexIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.sexIdentifier,
        unserialized: GroupRemovalUnserializedHeaders.sexIdentifier,
        view: IntlKeys.farmManagementDataSexId,
      },
      [GroupRemovalSerializedHeaders.withoutMale]: {
        serialized: GroupRemovalSerializedHeaders.withoutMale,
        unserialized: GroupRemovalUnserializedHeaders.withoutMale,
        view: IntlKeys.farmManagementDataWithoutMale,
      },
      [GroupRemovalSerializedHeaders.withoutFemale]: {
        serialized: GroupRemovalSerializedHeaders.withoutFemale,
        unserialized: GroupRemovalUnserializedHeaders.withoutFemale,
        view: IntlKeys.farmManagementDataWithoutFemale,
      },
      [GroupRemovalSerializedHeaders.withoutMaleCastrated]: {
        serialized: GroupRemovalSerializedHeaders.withoutMaleCastrated,
        unserialized: GroupRemovalUnserializedHeaders.withoutMaleCastrated,
        view: IntlKeys.farmManagementDataWithoutMaleCastrated,
      },
      [GroupRemovalSerializedHeaders.carPleuritis]: {
        serialized: GroupRemovalSerializedHeaders.carPleuritis,
        unserialized: GroupRemovalUnserializedHeaders.carPleuritis,
        view: IntlKeys.farmManagementDataCarPleuritis,
      },
      [GroupRemovalSerializedHeaders.carInfSkin]: {
        serialized: GroupRemovalSerializedHeaders.carInfSkin,
        unserialized: GroupRemovalUnserializedHeaders.carInfSkin,
        view: IntlKeys.farmManagementDataCarInfSkin,
      },
      [GroupRemovalSerializedHeaders.carInfFeed]: {
        serialized: GroupRemovalSerializedHeaders.carInfFeed,
        unserialized: GroupRemovalUnserializedHeaders.carInfFeed,
        view: IntlKeys.farmManagementDataCarInfFeed,
      },
      [GroupRemovalSerializedHeaders.carPleuritisInFeed]: {
        serialized: GroupRemovalSerializedHeaders.carPleuritisInFeed,
        unserialized: GroupRemovalUnserializedHeaders.carPleuritisInFeed,
        view: IntlKeys.farmManagementDataCarPleuritisInfFeed,
      },
      [GroupRemovalSerializedHeaders.carInfSkinAndFeed]: {
        serialized: GroupRemovalSerializedHeaders.carInfSkinAndFeed,
        unserialized: GroupRemovalUnserializedHeaders.carInfSkinAndFeed,
        view: IntlKeys.farmManagementDataCarInfSkinAndFeed,
      },
      [GroupRemovalSerializedHeaders.carPleuritisInfSkin]: {
        serialized: GroupRemovalSerializedHeaders.withoutMale,
        unserialized: GroupRemovalUnserializedHeaders.withoutMale,
        view: IntlKeys.farmManagementDataCarPleuritisInfSkin,
      },
      [GroupRemovalSerializedHeaders.carPleuritisInfSkinAndFeed]: {
        serialized: GroupRemovalSerializedHeaders.carPleuritisInfSkinAndFeed,
        unserialized: GroupRemovalUnserializedHeaders.carPleuritisInfSkinAndFeed,
        view: IntlKeys.farmManagementDataCarPleuritisInfSkinAndFeed,
      },
      [GroupRemovalSerializedHeaders.organAffectedLiver]: {
        serialized: GroupRemovalSerializedHeaders.organAffectedLiver,
        unserialized: GroupRemovalUnserializedHeaders.organAffectedLiver,
        view: IntlKeys.farmManagementDataOrganAffLiver,
      },
      [GroupRemovalSerializedHeaders.organRejectedLiver]: {
        serialized: GroupRemovalSerializedHeaders.organRejectedLiver,
        unserialized: GroupRemovalUnserializedHeaders.organRejectedLiver,
        view: IntlKeys.farmManagementDataOrganRejLiver,
      },
      [GroupRemovalSerializedHeaders.organAffectedLungs]: {
        serialized: GroupRemovalSerializedHeaders.organAffectedLungs,
        unserialized: GroupRemovalUnserializedHeaders.organAffectedLungs,
        view: IntlKeys.farmManagementDataOrganAffLungs,
      },
      [GroupRemovalSerializedHeaders.organAffectedLungsAndLiver]: {
        serialized: GroupRemovalSerializedHeaders.organAffectedLungsAndLiver,
        unserialized: GroupRemovalUnserializedHeaders.organAffectedLungsAndLiver,
        view: IntlKeys.farmManagementDataOrganAffLungsAndLiver,
      },
      [GroupRemovalSerializedHeaders.organAffectedLungsRejectedLiver]: {
        serialized: GroupRemovalSerializedHeaders.organAffectedLungsRejectedLiver,
        unserialized: GroupRemovalUnserializedHeaders.organAffectedLungsRejectedLiver,
        view: IntlKeys.farmManagementDataOrganAffLungsRejLiver,
      },
      [GroupRemovalSerializedHeaders.qualIdentifier]: {
        serialized: GroupRemovalSerializedHeaders.organAffectedLungsRejectedLiver,
        unserialized: GroupRemovalUnserializedHeaders.organAffectedLungsRejectedLiver,
        view: IntlKeys.farmManagementDataQualId,
      },
      [GroupRemovalSerializedHeaders.ubnList]: {
        serialized: GroupRemovalSerializedHeaders.ubnList,
        unserialized: GroupRemovalUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupRemovalSerializedHeaders.kvkList]: {
        serialized: GroupRemovalSerializedHeaders.kvkList,
        unserialized: GroupRemovalUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupRemovalSerializedHeaders.registrationNameList]: {
        serialized: GroupRemovalSerializedHeaders.registrationNameList,
        unserialized: GroupRemovalUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

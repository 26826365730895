import { CL550_FIELDS } from 'lib/excel/config/referenceTables/cl550/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';

const { columns, serialized, view } = CL550_FIELDS.Cl550;

export const getCl550View = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.mainGroupCode.serialized,
        viewPropName: columns.mainGroupCode.view,
      }),
      new CellViewer({ serializedPropName: columns.subGroupCode.serialized, viewPropName: columns.subGroupCode.view }),
      new CellViewer({ serializedPropName: columns.codeDetails.serialized, viewPropName: columns.codeDetails.view }),
      new CellViewer({
        serializedPropName: columns.mainGroupName.serialized,
        viewPropName: columns.mainGroupName.view,
      }),
      new CellViewer({ serializedPropName: columns.subgroupName.serialized, viewPropName: columns.subgroupName.view }),
      new CellViewer({ serializedPropName: columns.detailing.serialized, viewPropName: columns.detailing.view }),
      new CellViewer({ serializedPropName: columns.english.serialized, viewPropName: columns.english.view }),
      new CellViewer({ serializedPropName: columns.german.serialized, viewPropName: columns.german.view }),
      new CellViewer({
        serializedPropName: columns.codeCondition.serialized,
        viewPropName: columns.codeCondition.view,
      }),
      new CellViewer({ serializedPropName: columns.edi.serialized, viewPropName: columns.edi.view }),
      new CellViewer({ serializedPropName: columns.dgr.serialized, viewPropName: columns.dgr.view }),
      new CellViewer({
        serializedPropName: columns.diseaseAndTreatment.serialized,
        viewPropName: columns.diseaseAndTreatment.view,
      }),
      new CellViewer({ serializedPropName: columns.failure.serialized, viewPropName: columns.failure.view }),
      new CellViewer({ serializedPropName: columns.disposal.serialized, viewPropName: columns.disposal.view }),
      new CellViewer({ serializedPropName: columns.labResult.serialized, viewPropName: columns.labResult.view }),
      new CellViewer({ serializedPropName: columns.section.serialized, viewPropName: columns.section.view }),
      new CellViewer({
        serializedPropName: columns.slaughterhouseData.serialized,
        viewPropName: columns.slaughterhouseData.view,
      }),
      new CellViewer({ serializedPropName: columns.agility.serialized, viewPropName: columns.agility.view }),
      new CellViewer({ serializedPropName: columns.coverage.serialized, viewPropName: columns.coverage.view }),
      new CellViewer({ serializedPropName: columns.birth.serialized, viewPropName: columns.birth.view }),
      new CellViewer({
        serializedPropName: columns.suckingPiglet.serialized,
        viewPropName: columns.suckingPiglet.view,
      }),
      new CellViewer({ serializedPropName: columns.weanedBig.serialized, viewPropName: columns.weanedBig.view }),
      new CellViewer({
        serializedPropName: columns.rearingSowAndBoar.serialized,
        viewPropName: columns.rearingSowAndBoar.view,
      }),
      new CellViewer({ serializedPropName: columns.goodSow.serialized, viewPropName: columns.goodSow.view }),
      new CellViewer({ serializedPropName: columns.gestationSow.serialized, viewPropName: columns.gestationSow.view }),
      new CellViewer({ serializedPropName: columns.lactatingSow.serialized, viewPropName: columns.lactatingSow.view }),
      new CellViewer({ serializedPropName: columns.beer.serialized, viewPropName: columns.beer.view }),
      new CellViewer({ serializedPropName: columns.finisher.serialized, viewPropName: columns.finisher.view }),
      new CellViewer({ serializedPropName: columns.comment.serialized, viewPropName: columns.comment.view }),
      new CellViewer({ serializedPropName: columns.added.serialized, viewPropName: columns.added.view }),
      new CellViewer({ serializedPropName: columns.deleted.serialized, viewPropName: columns.deleted.view }),
    ]),
  ]);
};

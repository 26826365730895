import { PEN_LEVEL_FIELDS } from '../structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = PEN_LEVEL_FIELDS.PenLevel;

export const getPenLevelDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.penLevel.original),
      new CellValidator(columns.penNameNL.original),
      new CellValidator(columns.penNameEN.original),
    ]),
  ]);
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { CODES_ANIMAL_SCORE } from 'store/entities/farmManagementData/codesAnimalScore/config';

const { columns, view, serialized } = CODES_ANIMAL_SCORE.CodesAnimalScore;

export const codesAnimalScoreTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.animalScoreIdentifier.view,
          field: columns.animalScoreIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.abbreviation.view,
          field: columns.abbreviation.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.description.view,
          field: columns.description.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.note.view,
          field: columns.note.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.animalScoreDatetime.view,
          field: columns.animalScoreDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.codeGroup.view,
          field: columns.codeGroup.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.scoreEnd.view,
          field: columns.scoreEnd.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.sows.view,
          field: columns.sows.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.boars.view,
          field: columns.boars.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.weaners.view,
          field: columns.weaners.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.sucklers.view,
          field: columns.sucklers.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.replstock.view,
          field: columns.replstock.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.finishers.view,
          field: columns.finishers.serialized,
        },
      }),
    ],
  }),
]);

import React from 'react';
import { List, ListProps } from '@material-ui/core';

import { PERMISSIONS } from 'store/auth/permissions';

import MenuItemGroup from './components/MenuItemGroup';

import styled from 'styled-components';
import { SvgIconComponent } from '@material-ui/icons';

interface ItemBase {
  title: string;
  icon: SvgIconComponent;
  onClick?: (e: React.MouseEvent) => void;
  restrictions?: { [key in PERMISSIONS]?: boolean };
  hideable?: boolean;
}

interface ItemLink extends ItemBase {
  url: string;
}

interface ItemGroup extends ItemBase {
  subItems: (ItemLink | ItemGroup)[];
}

export type NestedMenuItem = ItemBase | ItemLink | ItemGroup;

const StyledList = styled(List)`
  && {
    margin-right: 20px;
  }
`;

interface OwnProps extends ListProps {
  items: NestedMenuItem[];
  checkItemVisibility: (restrictions?: { [key in PERMISSIONS]?: boolean }) => boolean;
  onMenuItemSelect?: () => void;
}

const NestedMenu: React.FC<OwnProps> = ({
  //
  items,
  checkItemVisibility,
  onMenuItemSelect,
  ...listProps
}) => {
  return (
    <StyledList {...listProps}>
      {
        //@ts-ignore
        items.map(({ title, icon, url, restrictions, subItems, hideable, ...rest }) => (
          <MenuItemGroup
            //
            key={title}
            title={title}
            icon={icon}
            url={url}
            subItems={subItems}
            //
            restrictions={restrictions}
            checkItemVisibility={checkItemVisibility}
            onMenuItemSelect={onMenuItemSelect}
            hideable={hideable}
            {...rest}
          />
        ))
      }
    </StyledList>
  );
};

export default React.memo(NestedMenu);

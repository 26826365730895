import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CL550 } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type Cl550Payload = {
  id: number;
  main_group_code: string;
  subgroup_code: string;
  code_details: string;
  main_group_name: string;
  subgroup_name: string | null;
  detailing: string | null;
  english: string;
  german: string | null;
  code_condition: string;
  edi: string | null;
  dgr: string | null;
  disease_and_treatment: string | boolean;
  failure: string | boolean;
  disposal: string | boolean;
  selection: string | boolean;
  lab_result: string | boolean;
  section: string | boolean;
  slaughterhouse_data: string | boolean;
  agility: string | boolean;
  coverage: string | boolean;
  birth: string | boolean;
  sucking_piglet: string | boolean;
  weaned_big: string | boolean;
  rearing_sow_and_boar: string | boolean;
  good_sow: string | boolean;
  gestation_sow: string | boolean;
  lactating_sow: string | boolean;
  beer: string | boolean;
  finisher: string | boolean;
  comment: string | null;
  added: string | null;
  deleted: string | null;
  pig_disease_code_upload: ManageUploadPayload;
};

export type Cl550Serialized = {
  id: number;
  referenceUploadID: number;
  mainGroupCode: string;
  subGroupCode: string;
  codeDetails: string;
  mainGroupName: string;
  subgroupName: string | null;
  detailing: string | null;
  english: string;
  german: string | null;
  codeCondition: string;
  edi: string | null;
  dgr: string | null;
  diseaseAndTreatment: string | boolean;
  failure: string | boolean;
  disposal: string | boolean;
  selection: string | boolean;
  labResult: string | boolean;
  section: string | boolean;
  slaughterhouseData: string | boolean;
  agility: string | boolean;
  coverage: string | boolean;
  birth: string | boolean;
  suckingPiglet: string | boolean;
  weanedBig: string | boolean;
  rearingSowAndBoar: string | boolean;
  goodSow: string | boolean;
  gestationSow: string | boolean;
  lactatingSow: string | boolean;
  beer: string | boolean;
  finisher: string | boolean;
  comment: string | null;
  added: string | null;
  deleted: string | null;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = CL550.Cl550;

const getDefaultSerializer = (data: DocumentData<Cl550Payload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.mainGroupCode.unserialized, columns.mainGroupCode.serialized),
      new CellSerializer(columns.subGroupCode.unserialized, columns.subGroupCode.serialized),
      new CellSerializer(columns.codeDetails.unserialized, columns.codeDetails.serialized),
      new CellSerializer(columns.mainGroupName.unserialized, columns.mainGroupName.serialized),
      new CellSerializer(columns.subgroupName.unserialized, columns.subgroupName.serialized),
      new CellSerializer(columns.detailing.unserialized, columns.detailing.serialized),
      new CellSerializer(columns.english.unserialized, columns.english.serialized),
      new CellSerializer(columns.german.unserialized, columns.german.serialized),
      new CellSerializer(columns.codeCondition.unserialized, columns.codeCondition.serialized),
      new CellSerializer(columns.edi.unserialized, columns.edi.serialized),
      new CellSerializer(columns.dgr.unserialized, columns.dgr.serialized),
      new CellSerializer(columns.diseaseAndTreatment.unserialized, columns.diseaseAndTreatment.serialized),
      new CellSerializer(columns.failure.unserialized, columns.failure.serialized),
      new CellSerializer(columns.disposal.unserialized, columns.disposal.serialized),
      new CellSerializer(columns.selection.unserialized, columns.selection.serialized),
      new CellSerializer(columns.labResult.unserialized, columns.labResult.serialized),
      new CellSerializer(columns.section.unserialized, columns.section.serialized),
      new CellSerializer(columns.slaughterhouseData.unserialized, columns.slaughterhouseData.serialized),
      new CellSerializer(columns.agility.unserialized, columns.agility.serialized),
      new CellSerializer(columns.coverage.unserialized, columns.coverage.serialized),
      new CellSerializer(columns.birth.unserialized, columns.birth.serialized),
      new CellSerializer(columns.suckingPiglet.unserialized, columns.suckingPiglet.serialized),
      new CellSerializer(columns.weanedBig.unserialized, columns.weanedBig.serialized),
      new CellSerializer(columns.rearingSowAndBoar.unserialized, columns.rearingSowAndBoar.serialized),
      new CellSerializer(columns.goodSow.unserialized, columns.goodSow.serialized),
      new CellSerializer(columns.gestationSow.unserialized, columns.gestationSow.serialized),
      new CellSerializer(columns.lactatingSow.unserialized, columns.lactatingSow.serialized),
      new CellSerializer(columns.beer.unserialized, columns.beer.serialized),
      new CellSerializer(columns.finisher.unserialized, columns.finisher.serialized),
      new CellSerializer(columns.comment.unserialized, columns.comment.serialized),
      new CellSerializer(columns.added.unserialized, columns.added.serialized),
      new CellSerializer(columns.deleted.unserialized, columns.deleted.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeCl550 = (serverData: ResourcesWrapper<Cl550Payload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { ThemeProvider } from 'styled-components/macro';
import { StylesProvider } from '@material-ui/core/styles';
import { SnackbarExtendedProvider } from 'lib/toast';

import App from './App';

import theme, { classNamesGenerator, GlobalStyles } from 'theme';
import { store, history } from 'store/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const renderHMRApp = (Component: React.FC) => {
  /* eslint-disable-next-line */
  return ReactDOM.render(
    // HMR works like this
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <StylesProvider generateClassName={classNamesGenerator} injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarExtendedProvider>
              <Component />
              <GlobalStyles />
            </SnackbarExtendedProvider>
          </ThemeProvider>
        </StylesProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

renderHMRApp(App);

// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require('./App').default;
//     renderHMRApp(NextApp);
//   });
// }

serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

import React, { useContext, useMemo } from 'react';
import { Tabs, Tab, TablePagination, TablePaginationProps } from '@material-ui/core';

import { DataContext } from '../../../index';

import styled from 'styled-components';

interface OwnProps {}

const CustomPagination: React.FC<OwnProps & TablePaginationProps> = ({ ...paginationProps }) => {
  const { tableConfig, currentTab, setCurrentTabKey, setPage } = useContext(DataContext);

  const withTabs = tableConfig.tabs.length > 1;
  const tabs = useMemo(() => tableConfig.tabs, [tableConfig]);

  return (
    <>
      {withTabs && (
        <StyledTabs
          value={tabs.findIndex((tab) => tab.label === currentTab.label)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabs.map((tab) => (
            <StyledTab
              key={tab.dataKey}
              label={tab.label}
              onClick={() => {
                setPage(0);
                setCurrentTabKey(tab.dataKey);
              }}
            />
          ))}
        </StyledTabs>
      )}
      <StyledTablePagination {...paginationProps} />
    </>
  );
};

export const StyledTabs = styled((props) => <Tabs component="td" {...props} />)`
  && {
    min-height: auto;
  }
`;

export const StyledTab = styled((props) => (
  <Tab
    {...props}
    classes={{
      selected: 'selected',
    }}
  />
))`
  && {
    background: ${({ theme }) => theme.colors.background.grey};
    padding: 0;
    font-size: 0.75rem;

    min-width: 72px;
    min-height: 26px;

    margin: 0 1px 0 1px;

    &:after {
      content: '';
      display: block;
      height: 85%;
      width: 0;
      border-right: 1px solid ${({ theme }) => theme.colors.border.greyDark};
    }
  }

  &.selected {
    background: white;
  }
`;

export const StyledTablePagination = styled(TablePagination)``;

export default React.memo(CustomPagination);

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';

import {
  JOINDATA_REQUESTED_PARTICIPATIONS,
  MyJoindataSerialized,
} from 'lib/tables/config/joindata/requested-participations/config';

interface MyJoindataEntry {
  scheme: string;
  content_start_date: string;
  requested_at: string;
  status: string;
  external_participation_id: string;
  organization: {
    id: number;
    kvk: string;
    kvk_company_name: string;
    email: string;
  };
  purpose: {
    id: number;
    name: string;
  };
}

const { columns, serialized, unserialized } = JOINDATA_REQUESTED_PARTICIPATIONS[
  MyJoindataSerialized.JoindataRequestedParticipations
];

const getSerializer = (data: DocumentData<MyJoindataEntry[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.organizationId.unserialized, columns.organizationId.serialized),
      new CellSerializer(columns.organizationName.unserialized, columns.organizationName.serialized),
      new CellSerializer(columns.kvk.unserialized, columns.kvk.serialized),
      new CellSerializer(columns.purposeName.unserialized, columns.purposeName.serialized),
      new CellSerializer(columns.scheme.unserialized, columns.scheme.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.requestedAt.unserialized, columns.requestedAt.serialized),
      new CellSerializer(columns.contentStartDate.unserialized, columns.contentStartDate.serialized),
      new CellSerializer(columns.externalParticipationId.unserialized, columns.externalParticipationId.serialized),
    ]),
  ]);
};

export const serializeJoindataRequestedParticipations = (serverData: ResourcesWrapper<MyJoindataEntry>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

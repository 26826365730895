import { InvoicesKeys } from 'lib/localization/keys/invoices';
import { IntlKeys } from 'lib/localization/keys/__keys';

//{
//   "id": 3,
//   "message_id": "39814561",
//   "currency_code": "New Taiwan Dollar",
//   "overview_type": "tempore",
//   "invoice_number": "8542123243",
//   "invoice_date": "2019-10-28",
//   "unique_message_number": "514784280",
//   "supplier_name": "Voluptatem modi qui.",
//   "supplier_postal_address_street": "Skiles Glens",
//   "supplier_postal_address_house_number": "960",
//   "supplier_postal_address_place": "North Neal",
//   "supplier_postal_address_postcode": "94555",
//   "supplier_ean_address_code": "82544-1167",
//   "supplier_bank_account_number": "8212404209",
//   "supplier_iban": "NL66BAIW6596498958",
//   "supplier_international_vat_number": "NL59WLFG3664361472",
//   "customer_name": "Numquam rerum provident.",
//   "customer_street_address": "Jewel Glen",
//   "customer_postal_address_place": "Rossanaburgh",
//   "customer_relation_number": "87028",
//   "deliveries": [
//     {
//       "id": 1,
//       "invoice": {
//         "id": 3,
//         "message_id": "39814561"
//       },
//       "line_number": "76",
//       "delivery_date": "2020-04-03",
//       "delivery_note_number": "45125011",
//       "delivery_note_line_number": "72",
//       "product_quantity": "89076",
//       "product_article_code": "275946378179830043",
//       "product_description": "Commodi soluta sunt laudantium.",
//       "product_article_group_code": "65",
//       "product_article_group_code_description": "Eum ea incidunt corporis."
//     }
//   ]
// }
enum InvoicesSerialized {
  InvoiceMessages = 'Invoices',
}

enum InvoicesUnserialized {
  InvoiceMessages = 'Invoices',
}

// enum InvoiceView {
//   InvoiceMessages = 'Invoices',
// }

enum InvoicesSerializedHeaders {
  messageId = 'messageId',
  currencyCode = 'currencyCode',
  overviewType = 'overviewType',
  invoiceNumber = 'invoiceNumber',
  invoiceDate = 'invoiceDate',
  uniqueMessageNumber = 'uniqueMessageNumber',
  supplierName = 'supplierName',
  supplierStreet = 'supplierStreet',
  supplierHouseNumber = 'supplierHouseNumber',
  supplierPlace = 'supplierPlace',
  supplierPostCode = 'supplierPostCode',
  supplierAddressCode = 'supplierAddressCode',
  supplierBankAccount = 'supplierBankAccount',
  supplierEANAddressCode = 'supplierEANAddressCode',
  supplierIban = 'supplierIban',
  supplierVatNumber = 'supplierVatNumber',
  customerName = 'customerName',
  customerStreetAddress = 'customerStreetAddress',
  customerPostalAddressPlace = 'customerPostalAddressPlace',
  customerRelationNumber = 'customerRelationNumber',
  deliveries = 'deliveries',
}

enum InvoicesUnserializedHeaders {
  messageId = 'message_id',
  currencyCode = 'currency_code',
  overviewType = 'overview_type',
  invoiceNumber = 'invoice_number',
  invoiceDate = 'invoice_date',
  uniqueMessageNumber = 'unique_message_number',
  supplierName = 'supplier_name',
  supplierStreet = 'supplier_postal_address_street',
  supplierHouseNumber = 'supplier_postal_address_house_number',
  supplierPlace = 'supplier_postal_address_place',
  supplierPostCode = 'supplier_postal_address_postcode',
  supplierEANAddressCode = 'supplier_ean_address_code',
  supplierBankAccount = 'supplier_bank_account_number',
  supplierIban = 'supplier_iban',
  supplierVatNumber = 'supplier_international_vat_number',
  customerName = 'customer_name',
  customerStreetAddress = 'customer_street_address',
  customerPostalAddressPlace = 'customer_postal_address_place',
  customerRelationNumber = 'customer_relation_number',
  deliveries = 'deliveries',
}

// enum InvoiceViewHeaders {
//   messageId = 'Message ID',
//   currencyCode = 'Currency Code',
//   overviewType = 'Overview Type',
//   invoiceNumber = 'Invoice Number',
//   invoiceDate = 'Invoice Date',
//   uniqueMessageNumber = 'Unique Message Number',
//   supplierName = 'Supplier Name',
//   supplierStreet = 'Supplier Postal Address Street',
//   supplierHouseNumber = 'Supplier House Number',
//   supplierPlace = 'Supplier Postal Address Place',
//   supplierPostCode = 'Supplier Postal Address Postcode',
//   supplierEANAddressCode = 'Supplier EAN Address Code',
//   supplierBankAccount = 'Supplier Bank Acoount Number',
//   supplierIban = 'Supplier IBAN',
//   supplierVatNumber = 'Supplier International VAT Number',
//   customerName = 'Customer Name',
//   customerStreetAddress = 'Customer Street Address',
//   customerPostalAddressPlace = 'Customer Postal Address Place',
//   customerRelationNumber = 'Customer Relation Number',
//   deliveries = 'Deliveries',
// }

export const INVOICE_MESSAGES = {
  [InvoicesSerialized.InvoiceMessages]: {
    serialized: InvoicesSerialized.InvoiceMessages,
    unserialized: InvoicesUnserialized.InvoiceMessages,
    view: IntlKeys.titleInvoices,
    columns: {
      [InvoicesSerializedHeaders.messageId]: {
        serialized: InvoicesSerializedHeaders.messageId,
        unserialized: InvoicesUnserializedHeaders.messageId,
        view: IntlKeys.messageId,
      },
      [InvoicesSerializedHeaders.currencyCode]: {
        serialized: InvoicesSerializedHeaders.currencyCode,
        unserialized: InvoicesUnserializedHeaders.currencyCode,
        view: InvoicesKeys.currencyCode,
      },
      [InvoicesSerializedHeaders.overviewType]: {
        serialized: InvoicesSerializedHeaders.overviewType,
        unserialized: InvoicesUnserializedHeaders.overviewType,
        view: InvoicesKeys.overviewType,
      },
      [InvoicesSerializedHeaders.invoiceNumber]: {
        serialized: InvoicesSerializedHeaders.invoiceNumber,
        unserialized: InvoicesUnserializedHeaders.invoiceNumber,
        view: InvoicesKeys.invoiceNumber,
      },
      [InvoicesSerializedHeaders.invoiceDate]: {
        serialized: InvoicesSerializedHeaders.invoiceDate,
        unserialized: InvoicesUnserializedHeaders.invoiceDate,
        view: InvoicesKeys.invoiceDate,
      },
      [InvoicesSerializedHeaders.uniqueMessageNumber]: {
        serialized: InvoicesSerializedHeaders.uniqueMessageNumber,
        unserialized: InvoicesUnserializedHeaders.uniqueMessageNumber,
        view: InvoicesKeys.uniqueMessageNumber,
      },
      [InvoicesSerializedHeaders.supplierName]: {
        serialized: InvoicesSerializedHeaders.supplierName,
        unserialized: InvoicesUnserializedHeaders.supplierName,
        view: InvoicesKeys.supplierName,
      },
      [InvoicesSerializedHeaders.supplierStreet]: {
        serialized: InvoicesSerializedHeaders.supplierStreet,
        unserialized: InvoicesUnserializedHeaders.supplierStreet,
        view: InvoicesKeys.supplierStreet,
      },
      [InvoicesSerializedHeaders.supplierHouseNumber]: {
        serialized: InvoicesSerializedHeaders.supplierHouseNumber,
        unserialized: InvoicesUnserializedHeaders.supplierHouseNumber,
        view: InvoicesKeys.supplierHouseNumber,
      },
      [InvoicesSerializedHeaders.supplierPlace]: {
        serialized: InvoicesSerializedHeaders.supplierPlace,
        unserialized: InvoicesUnserializedHeaders.supplierPlace,
        view: InvoicesKeys.supplierPlace,
      },
      [InvoicesSerializedHeaders.supplierPostCode]: {
        serialized: InvoicesSerializedHeaders.supplierPostCode,
        unserialized: InvoicesUnserializedHeaders.supplierPostCode,
        view: InvoicesKeys.supplierPostCode,
      },
      [InvoicesSerializedHeaders.supplierEANAddressCode]: {
        serialized: InvoicesSerializedHeaders.supplierEANAddressCode,
        unserialized: InvoicesUnserializedHeaders.supplierEANAddressCode,
        view: InvoicesKeys.supplierEANAddressCode,
      },
      [InvoicesSerializedHeaders.supplierBankAccount]: {
        serialized: InvoicesSerializedHeaders.supplierBankAccount,
        unserialized: InvoicesUnserializedHeaders.supplierBankAccount,
        view: InvoicesKeys.supplierBankAccount,
      },
      [InvoicesSerializedHeaders.supplierIban]: {
        serialized: InvoicesSerializedHeaders.supplierIban,
        unserialized: InvoicesUnserializedHeaders.supplierIban,
        view: InvoicesKeys.supplierIban,
      },
      [InvoicesSerializedHeaders.supplierVatNumber]: {
        serialized: InvoicesSerializedHeaders.supplierVatNumber,
        unserialized: InvoicesUnserializedHeaders.supplierVatNumber,
        view: InvoicesKeys.supplierVatNumber,
      },
      [InvoicesSerializedHeaders.customerName]: {
        serialized: InvoicesSerializedHeaders.customerName,
        unserialized: InvoicesUnserializedHeaders.customerName,
        view: InvoicesKeys.customerName,
      },
      [InvoicesSerializedHeaders.customerStreetAddress]: {
        serialized: InvoicesSerializedHeaders.customerStreetAddress,
        unserialized: InvoicesUnserializedHeaders.customerStreetAddress,
        view: InvoicesKeys.customerStreetAddress,
      },
      [InvoicesSerializedHeaders.customerPostalAddressPlace]: {
        serialized: InvoicesSerializedHeaders.customerPostalAddressPlace,
        unserialized: InvoicesUnserializedHeaders.customerPostalAddressPlace,
        view: InvoicesKeys.customerPostalAddressPlace,
      },
      [InvoicesSerializedHeaders.customerRelationNumber]: {
        serialized: InvoicesSerializedHeaders.customerRelationNumber,
        unserialized: InvoicesUnserializedHeaders.customerRelationNumber,
        view: InvoicesKeys.customerRelationNumber,
      },
      [InvoicesSerializedHeaders.deliveries]: {
        serialized: InvoicesSerializedHeaders.deliveries,
        unserialized: InvoicesUnserializedHeaders.deliveries,
        view: InvoicesKeys.deliveries,
      },
    },
  },
};

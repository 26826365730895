import { IntlKeys } from 'lib/localization/keys';

enum CfpGfliFeedprintTableSerialized {
  CfpGfliFeedprintTable = 'CfpGfliFeedprintTable',
}

enum CfpGfliFeedprintTableUnserialized {
  CfpGfliFeedprintTable = 'CfpGfliFeedprintTable',
}

export enum CfpGfliFeedprintTableSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  feedPrintCode = 'feedPrintCode',
  cvbCode = 'cvbCode',
  source = 'source',
  productNameEn = 'productNameEn',
  productNameNl = 'productNameNl',
  productCodeEu = 'productCodeEu',
  productNameEu = 'productNameEu',
  origin = 'origin',
  climate = 'climate',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const CfpGfliFeedprintTableUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['feed_print_upload', 'id'],
  feedPrintCode: 'feed_print_code',
  cvbCode: 'cvb_code',
  source: 'source',
  productNameEn: 'product_name_en',
  productNameNl: 'product_name_nl',
  productCodeEu: 'product_code_eu',
  productNameEu: 'product_name_eu',
  origin: 'origin',
  climate: 'climate',
  uploadType: ['feed_print_upload', 'upload_type'],
  filename: ['feed_print_upload', 'file_name'],
  status: ['feed_print_upload', 'status'],
  validDateFrom: ['feed_print_upload', 'valid_from'],
  validDateTo: ['feed_print_upload', 'valid_to'],
  notes: ['feed_print_upload', 'notes'],
  uploadedAt: ['feed_print_upload', 'uploaded_at'],
  uploadedBy: ['feed_print_upload', 'uploaded_by', 'full_name'],
} as const;

export const CFP_GFLI_FEEDPRINT_TABLE = {
  [CfpGfliFeedprintTableSerialized.CfpGfliFeedprintTable]: {
    serialized: CfpGfliFeedprintTableSerialized.CfpGfliFeedprintTable,
    unserialized: CfpGfliFeedprintTableUnserialized.CfpGfliFeedprintTable,
    view: IntlKeys.referenceTablesCfpGfliFeedprintTable,

    columns: {
      [CfpGfliFeedprintTableSerializedHeaders.id]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.id,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.id,
      },
      [CfpGfliFeedprintTableSerializedHeaders.referenceUploadID]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.referenceUploadID,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.referenceUploadID,
      },
      [CfpGfliFeedprintTableSerializedHeaders.feedPrintCode]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.feedPrintCode,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.feedPrintCode,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableFeedPrintCode,
      },
      [CfpGfliFeedprintTableSerializedHeaders.cvbCode]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.cvbCode,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.cvbCode,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableCvbCode,
      },
      [CfpGfliFeedprintTableSerializedHeaders.source]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.source,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.source,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableSource,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productNameEn]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.productNameEn,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productNameEn,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEn,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productNameNl]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.productNameNl,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productNameNl,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameNl,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productCodeEu]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.productCodeEu,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productCodeEu,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductCodeEu,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productNameEu]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.productNameEu,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productNameEu,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEu,
      },
      [CfpGfliFeedprintTableSerializedHeaders.origin]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.origin,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.origin,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableOrigin,
      },
      [CfpGfliFeedprintTableSerializedHeaders.climate]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.climate,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.climate,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableClimate,
      },
      [CfpGfliFeedprintTableSerializedHeaders.status]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.status,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.status,
      },
      [CfpGfliFeedprintTableSerializedHeaders.uploadType]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.uploadType,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.uploadType,
      },
      [CfpGfliFeedprintTableSerializedHeaders.notes]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.notes,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.notes,
      },
      [CfpGfliFeedprintTableSerializedHeaders.filename]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.filename,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.filename,
      },
      [CfpGfliFeedprintTableSerializedHeaders.validDateFrom]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.validDateFrom,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.validDateFrom,
      },
      [CfpGfliFeedprintTableSerializedHeaders.validDateTo]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.validDateTo,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.validDateTo,
      },
      [CfpGfliFeedprintTableSerializedHeaders.uploadedAt]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.uploadedAt,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.uploadedAt,
      },
      [CfpGfliFeedprintTableSerializedHeaders.uploadedBy]: {
        serialized: CfpGfliFeedprintTableSerializedHeaders.uploadedBy,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum AnimalPassportsSerialized {
  AnimalPassports = 'AnimalPassports',
}

enum AnimalPassportsUnserialized {
  AnimalPassports = 'AnimalPassports',
}

export enum AnimalPassportsSerializedHeaders {
  certificates = 'certificates',
  veterinarianData = 'veterinarianData',
  feed = 'feed',
  movementEvents = 'movementEvents',
  farmData = 'farmData',
  genetica = 'genetica',
  slaughter = 'slaughter',
}

export enum AnimalPassportsUnserializedHeaders {
  certificates = 'certificates',
  veterinarianData = 'veterinarian_data',
  feed = 'feed_data',
  movementEvents = 'transport_data',
  farmData = 'farm_data',
  genetica = 'genetic_data',
  slaughter = 'slaughter_data',
}

export const ANIMAL_PASSPORTS = {
  [AnimalPassportsSerialized.AnimalPassports]: {
    serialized: AnimalPassportsSerialized.AnimalPassports,
    unserialized: AnimalPassportsUnserialized.AnimalPassports,
    view: IntlKeys.titleGenetics,
    columns: {
      [AnimalPassportsSerializedHeaders.certificates]: {
        serialized: AnimalPassportsSerializedHeaders.certificates,
        unserialized: AnimalPassportsUnserializedHeaders.certificates,
        view: IntlKeys.certificates,
      },
      [AnimalPassportsSerializedHeaders.veterinarianData]: {
        serialized: AnimalPassportsSerializedHeaders.veterinarianData,
        unserialized: AnimalPassportsUnserializedHeaders.veterinarianData,
        view: IntlKeys.titleVeterinarianData,
      },
      [AnimalPassportsSerializedHeaders.feed]: {
        serialized: AnimalPassportsSerializedHeaders.feed,
        unserialized: AnimalPassportsUnserializedHeaders.feed,
        view: IntlKeys.farmFeeding,
      },
      [AnimalPassportsSerializedHeaders.movementEvents]: {
        serialized: AnimalPassportsSerializedHeaders.movementEvents,
        unserialized: AnimalPassportsUnserializedHeaders.movementEvents,
        view: IntlKeys.titleMovementEvents,
      },
      [AnimalPassportsSerializedHeaders.farmData]: {
        serialized: AnimalPassportsSerializedHeaders.farmData,
        unserialized: AnimalPassportsUnserializedHeaders.farmData,
        view: IntlKeys.titleFarmReports,
      },
      [AnimalPassportsSerializedHeaders.genetica]: {
        serialized: AnimalPassportsSerializedHeaders.genetica,
        unserialized: AnimalPassportsUnserializedHeaders.genetica,
        view: IntlKeys.titleGenetics,
      },
      [AnimalPassportsSerializedHeaders.slaughter]: {
        serialized: AnimalPassportsSerializedHeaders.slaughter,
        unserialized: AnimalPassportsUnserializedHeaders.slaughter,
        view: IntlKeys.titleSlaughter,
      },
    },
  },
};

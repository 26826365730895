import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_EVENT_TYPES } from './config';

export type CodesEventTypesPayload = {
  id: number;
  event_type_identifier: string;
  note: string | null;
  shortcut: string;
  event_type_datetime: string;
};

export type CodesEventTypesSerialized = {
  id: number;
  eventTypeIdentifier: string;
  note: string | null;
  shortcut: string;
  eventTypeDatetime: string;
};

const { columns, serialized, unserialized } = CODES_EVENT_TYPES.CodesEventTypes;

const getDefaultSerializer = (data: DocumentData<CodesEventTypesPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.eventTypeIdentifier.unserialized, columns.eventTypeIdentifier.serialized),
      new CellSerializer(columns.note.unserialized, columns.note.serialized),
      new CellSerializer(columns.shortcut.unserialized, columns.shortcut.serialized),
      new CellSerializer(columns.eventTypeDatetime.unserialized, columns.eventTypeDatetime.serialized),
    ]),
  ]);
};

export const serializeCodesEventTypes = (serverData: ResourcesWrapper<CodesEventTypesPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys';

enum CodesAnimalScoreSerialized {
  CodesAnimalScore = 'CodesAnimalScore',
}

enum CodesAnimalScoreUnserialized {
  CodesAnimalScore = 'CodesAnimalScore',
}

export enum CodesAnimalScoreSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  animalScoreIdentifier = 'animalScoreIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  note = 'note',
  animalScoreDatetime = 'animalScoreDatetime',
  codeGroup = 'codeGroup',
  scoreEnd = 'scoreEnd',
  sows = 'sows',
  boars = 'boars',
  weaners = 'weaners',
  sucklers = 'sucklers',
  replstock = 'replstock',
  finishers = 'finishers',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesAnimalScoreUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  animalScoreIdentifier: 'animal_score_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  note: 'note',
  animalScoreDatetime: 'animal_score_datetime',
  codeGroup: 'code_group',
  scoreEnd: 'score_end',
  sows: 'sows',
  boars: 'boars',
  weaners: 'weaners',
  sucklers: 'sucklers',
  replstock: 'replstock',
  finishers: 'finishers',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_ANIMAL_SCORE = {
  [CodesAnimalScoreSerialized.CodesAnimalScore]: {
    serialized: CodesAnimalScoreSerialized.CodesAnimalScore,
    unserialized: CodesAnimalScoreUnserialized.CodesAnimalScore,
    view: IntlKeys.farmManagementDataAnimalScoreList,

    columns: {
      [CodesAnimalScoreSerializedHeaders.id]: {
        serialized: CodesAnimalScoreSerializedHeaders.id,
        unserialized: CodesAnimalScoreUnserializedHeaders.id,
      },
      [CodesAnimalScoreSerializedHeaders.companyIdentifier]: {
        serialized: CodesAnimalScoreSerializedHeaders.companyIdentifier,
        unserialized: CodesAnimalScoreUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesAnimalScoreSerializedHeaders.animalScoreIdentifier]: {
        serialized: CodesAnimalScoreSerializedHeaders.animalScoreIdentifier,
        unserialized: CodesAnimalScoreUnserializedHeaders.animalScoreIdentifier,
        view: IntlKeys.farmManagementDataAnimalScoreId,
      },
      [CodesAnimalScoreSerializedHeaders.abbreviation]: {
        serialized: CodesAnimalScoreSerializedHeaders.abbreviation,
        unserialized: CodesAnimalScoreUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesAnimalScoreSerializedHeaders.description]: {
        serialized: CodesAnimalScoreSerializedHeaders.description,
        unserialized: CodesAnimalScoreUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesAnimalScoreSerializedHeaders.note]: {
        serialized: CodesAnimalScoreSerializedHeaders.note,
        unserialized: CodesAnimalScoreUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesAnimalScoreSerializedHeaders.animalScoreDatetime]: {
        serialized: CodesAnimalScoreSerializedHeaders.animalScoreDatetime,
        unserialized: CodesAnimalScoreUnserializedHeaders.animalScoreDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesAnimalScoreSerializedHeaders.codeGroup]: {
        serialized: CodesAnimalScoreSerializedHeaders.codeGroup,
        unserialized: CodesAnimalScoreUnserializedHeaders.codeGroup,
        view: IntlKeys.farmManagementDataCodeGroup,
      },
      [CodesAnimalScoreSerializedHeaders.scoreEnd]: {
        serialized: CodesAnimalScoreSerializedHeaders.scoreEnd,
        unserialized: CodesAnimalScoreUnserializedHeaders.scoreEnd,
        view: IntlKeys.farmManagementDataScoreEnd,
      },
      [CodesAnimalScoreSerializedHeaders.sows]: {
        serialized: CodesAnimalScoreSerializedHeaders.sows,
        unserialized: CodesAnimalScoreUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataSows,
      },
      [CodesAnimalScoreSerializedHeaders.boars]: {
        serialized: CodesAnimalScoreSerializedHeaders.boars,
        unserialized: CodesAnimalScoreUnserializedHeaders.boars,
        view: IntlKeys.farmManagementDataBoars,
      },
      [CodesAnimalScoreSerializedHeaders.weaners]: {
        serialized: CodesAnimalScoreSerializedHeaders.weaners,
        unserialized: CodesAnimalScoreUnserializedHeaders.weaners,
        view: IntlKeys.farmManagementDataWeaners,
      },
      [CodesAnimalScoreSerializedHeaders.sucklers]: {
        serialized: CodesAnimalScoreSerializedHeaders.sucklers,
        unserialized: CodesAnimalScoreUnserializedHeaders.sucklers,
        view: IntlKeys.farmManagementDataSucklers,
      },
      [CodesAnimalScoreSerializedHeaders.replstock]: {
        serialized: CodesAnimalScoreSerializedHeaders.replstock,
        unserialized: CodesAnimalScoreUnserializedHeaders.replstock,
        view: IntlKeys.farmManagementDataReplstock,
      },
      [CodesAnimalScoreSerializedHeaders.finishers]: {
        serialized: CodesAnimalScoreSerializedHeaders.finishers,
        unserialized: CodesAnimalScoreUnserializedHeaders.finishers,
        view: IntlKeys.farmManagementDataFinishers,
      },
      [CodesAnimalScoreSerializedHeaders.ubnList]: {
        serialized: CodesAnimalScoreSerializedHeaders.ubnList,
        unserialized: CodesAnimalScoreUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesAnimalScoreSerializedHeaders.kvkList]: {
        serialized: CodesAnimalScoreSerializedHeaders.kvkList,
        unserialized: CodesAnimalScoreUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesAnimalScoreSerializedHeaders.registrationNameList]: {
        serialized: CodesAnimalScoreSerializedHeaders.registrationNameList,
        unserialized: CodesAnimalScoreUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys';

enum EntitiesListSerialized {
  EntitiesList = 'EntitiesList',
}

enum EntitiesListUnserialized {
  EntitiesList = 'EntitiesList',
}

export enum EntitiesListSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  entityId = 'entityId',
  entityName = 'entityName',
  country = 'country',
  businessUnit = 'businessUnit',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const EntitiesListUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['entities_list_upload', 'id'],
  entityId: 'entity_id',
  entityName: 'entity_name',
  country: 'country',
  location: 'location',
  businessUnit: 'business_unit',
  uploadType: ['entities_list_upload', 'upload_type'],
  filename: ['entities_list_upload', 'file_name'],
  status: ['entities_list_upload', 'status'],
  validDateFrom: ['entities_list_upload', 'valid_from'],
  validDateTo: ['entities_list_upload', 'valid_to'],
  notes: ['entities_list_upload', 'notes'],
  uploadedAt: ['entities_list_upload', 'uploaded_at'],
  uploadedBy: ['entities_list_upload', 'uploaded_by', 'full_name'],
} as const;

export const ENTITIES_LIST = {
  [EntitiesListSerialized.EntitiesList]: {
    serialized: EntitiesListSerialized.EntitiesList,
    unserialized: EntitiesListUnserialized.EntitiesList,
    view: IntlKeys.referenceTablesEntitiesList,

    columns: {
      [EntitiesListSerializedHeaders.id]: {
        serialized: EntitiesListSerializedHeaders.id,
        unserialized: EntitiesListUnserializedHeaders.id,
      },
      [EntitiesListSerializedHeaders.referenceUploadID]: {
        serialized: EntitiesListSerializedHeaders.referenceUploadID,
        unserialized: EntitiesListUnserializedHeaders.referenceUploadID,
      },
      [EntitiesListSerializedHeaders.entityId]: {
        serialized: EntitiesListSerializedHeaders.entityId,
        unserialized: EntitiesListUnserializedHeaders.entityId,
        view: IntlKeys.referenceTablesEntitiesListEntityId,
      },
      [EntitiesListSerializedHeaders.entityName]: {
        serialized: EntitiesListSerializedHeaders.entityName,
        unserialized: EntitiesListUnserializedHeaders.entityName,
        view: IntlKeys.referenceTablesEntitiesListEntityName,
      },
      [EntitiesListSerializedHeaders.country]: {
        serialized: EntitiesListSerializedHeaders.country,
        unserialized: EntitiesListUnserializedHeaders.country,
        view: IntlKeys.referenceTablesEntitiesListCountry,
      },
      [EntitiesListSerializedHeaders.businessUnit]: {
        serialized: EntitiesListSerializedHeaders.businessUnit,
        unserialized: EntitiesListUnserializedHeaders.businessUnit,
        view: IntlKeys.referenceTablesEntitiesListBusinessUnit,
      },
      [EntitiesListSerializedHeaders.status]: {
        serialized: EntitiesListSerializedHeaders.status,
        unserialized: EntitiesListUnserializedHeaders.status,
      },
      [EntitiesListSerializedHeaders.uploadType]: {
        serialized: EntitiesListSerializedHeaders.uploadType,
        unserialized: EntitiesListUnserializedHeaders.uploadType,
      },
      [EntitiesListSerializedHeaders.notes]: {
        serialized: EntitiesListSerializedHeaders.notes,
        unserialized: EntitiesListUnserializedHeaders.notes,
      },
      [EntitiesListSerializedHeaders.filename]: {
        serialized: EntitiesListSerializedHeaders.filename,
        unserialized: EntitiesListUnserializedHeaders.filename,
      },
      [EntitiesListSerializedHeaders.validDateFrom]: {
        serialized: EntitiesListSerializedHeaders.validDateFrom,
        unserialized: EntitiesListUnserializedHeaders.validDateFrom,
      },
      [EntitiesListSerializedHeaders.validDateTo]: {
        serialized: EntitiesListSerializedHeaders.validDateTo,
        unserialized: EntitiesListUnserializedHeaders.validDateTo,
      },
      [EntitiesListSerializedHeaders.uploadedAt]: {
        serialized: EntitiesListSerializedHeaders.uploadedAt,
        unserialized: EntitiesListUnserializedHeaders.uploadedAt,
      },
      [EntitiesListSerializedHeaders.uploadedBy]: {
        serialized: EntitiesListSerializedHeaders.uploadedBy,
        unserialized: EntitiesListUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const slaughterPriceCorrections = new Route('get', new RouteParser('/mock/slaughter_price_corrections'), () => {
  return [
    200,
    {
      resources: [
        {
          id: 3,
          financial_result_id: '033532',
          financial_result_date_time: '2021-01-08T00:00:00.000Z',
          slaughter_house_type_code: 'y7j9ac',
          slaughter_house_value: '6b9yba',
          trader_type_code: '5r9xsu',
          trader_value: '3333',
          animal_type_code: 'bu60fl',
          animal_value: 'yoy2mi',
          number_of_animals: '32',
          currency_code: 'SAR',
          created_at: '2021-01-14T14:41:47.359Z',
          updated_at: '2021-01-14T14:41:47.359Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 2,
          financial_result_id: '098921',
          financial_result_date_time: '2021-01-09T00:00:00.000Z',
          slaughter_house_type_code: '756eed',
          slaughter_house_value: 'mumn5m',
          trader_type_code: 'wqle8y',
          trader_value: '2222',
          animal_type_code: 'ds2y9a',
          animal_value: 'bvdcc7',
          number_of_animals: '47',
          currency_code: 'PYG',
          created_at: '2021-01-14T14:41:47.343Z',
          updated_at: '2021-01-14T14:41:47.343Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 1,
          financial_result_id: '094697',
          financial_result_date_time: '2020-12-21T00:00:00.000Z',
          slaughter_house_type_code: 'zg8zz8',
          slaughter_house_value: '4pbhdq',
          trader_type_code: 'jznmzd',
          trader_value: '1111',
          animal_type_code: 'jxmiaf',
          animal_value: 'f1de4s',
          number_of_animals: '158',
          currency_code: 'BBD',
          created_at: '2021-01-14T14:41:47.288Z',
          updated_at: '2021-01-14T14:41:47.288Z',
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

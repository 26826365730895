import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_PURCHASED_FOOD } from './config';

export type GroupDefectsPayload = {
  id: number;
  companyIdentifier: string;
  farm_identifier: string;
  financial_event_identifier: string;
  sows: string;
  finishers: string;
  gilts: string;
  weaners: string;
  total_weight: string;
  ev: string;
  dm: string;
  purchased_food_datetime: string;
  acct_identifier: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type GroupDefectsSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  finEventIdentifier: string;
  sows: string;
  finishers: string;
  gilts: string;
  weaners: string;
  totalWeight: string;
  eV: string;
  dM: string;
  purchasedFoodDatetime: string;
  acctIdentifier: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = GROUP_PURCHASED_FOOD.GroupPurchasedFood;

const getDefaultSerializer = (data: DocumentData<GroupDefectsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.finEventIdentifier.unserialized, columns.finEventIdentifier.serialized),
      new CellSerializer(columns.sows.unserialized, columns.sows.serialized),
      new CellSerializer(columns.finishers.unserialized, columns.finishers.serialized),
      new CellSerializer(columns.gilts.unserialized, columns.gilts.serialized),
      new CellSerializer(columns.weaners.unserialized, columns.weaners.serialized),
      new CellSerializer(columns.totalWeight.unserialized, columns.totalWeight.serialized),
      new CellSerializer(columns.eV.unserialized, columns.eV.serialized),
      new CellSerializer(columns.dM.unserialized, columns.dM.serialized),
      new CellSerializer(columns.purchasedFoodDatetime.unserialized, columns.purchasedFoodDatetime.serialized),
      new CellSerializer(columns.acctIdentifier.unserialized, columns.acctIdentifier.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeGroupPurchasedFood = (serverData: ResourcesWrapper<GroupDefectsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

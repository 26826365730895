import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo3Digits } from 'lib/helpers/renderHelpers';

import { CFP_CO2_EMISSION_FACTOR } from 'store/entities/referenceTables/cfpCo2EmissionFactor/config';

const { columns, view, serialized } = CFP_CO2_EMISSION_FACTOR.CfpCo2EmissionFactors;

export const cfpCo2EmissionFactorTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.emissionSource.view,
          field: columns.emissionSource.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.co2Volume.view,
          field: columns.co2Volume.serialized,
          render: (data: ObjectWithProps) => roundTo3Digits(data[columns.co2Volume.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.reference.view,
          field: columns.reference.serialized,
        },
      }),
    ],
  }),
]);

import { CONTENT_OF_NITRIC_OXIDE_FIELDS } from 'lib/excel/config/referenceTables/contentOfNitricOxide/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = CONTENT_OF_NITRIC_OXIDE_FIELDS.ContentOfNitricOxide;

export const getContentOfNitricOxideDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.livestockCategory.serialized,
        extractPropName: columns.livestockCategory.view,
      }),
      new DisplayHeader({ headerName: columns.manureType.serialized, extractPropName: columns.manureType.view }),
      new DisplayHeader({ headerName: columns.valueN2oAndNo.serialized, extractPropName: columns.valueN2oAndNo.view }),
      new DisplayHeader({ headerName: columns.unitN2oAndNo.serialized, extractPropName: columns.unitN2oAndNo.view }),
      new DisplayHeader({ headerName: columns.valueN2.serialized, extractPropName: columns.valueN2.view }),
      new DisplayHeader({ headerName: columns.unitN2.serialized, extractPropName: columns.unitN2.view }),
    ]),
  ]);
};

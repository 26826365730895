import { RAV_REFERENCE_LIST_FIELDS } from 'lib/excel/config/referenceTables/ravReferenceList/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = RAV_REFERENCE_LIST_FIELDS.RavReferenceList;

export const getRavReferenceListDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.animalCategory.serialized,
        extractPropName: columns.animalCategory.view,
      }),
      new DisplayHeader({ headerName: columns.description.serialized, extractPropName: columns.description.view }),
      new DisplayHeader({ headerName: columns.efEm.serialized, extractPropName: columns.efEm.view }),
      new DisplayHeader({ headerName: columns.housingType.serialized, extractPropName: columns.housingType.view }),
    ]),
  ]);
};

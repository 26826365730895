import { IntlKeys } from 'lib/localization/keys';

enum CodesDiagnoseSerialized {
  CodesDiagnose = 'CodesDiagnose',
}

enum CodesDiagnoseUnserialized {
  CodesDiagnose = 'CodesDiagnose',
}

export enum CodesDiagnoseSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  diagnoseIdentifier = 'diagnoseIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  note = 'note',
  diagnoseDatetime = 'diagnoseDatetime',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesDiagnoseUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  diagnoseIdentifier: 'diagnose_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  note: 'note',
  diagnoseDatetime: 'diagnose_datetime',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_DIAGNOSE = {
  [CodesDiagnoseSerialized.CodesDiagnose]: {
    serialized: CodesDiagnoseSerialized.CodesDiagnose,
    unserialized: CodesDiagnoseUnserialized.CodesDiagnose,
    view: IntlKeys.farmManagementDataDiagnosesList,

    columns: {
      [CodesDiagnoseSerializedHeaders.id]: {
        serialized: CodesDiagnoseSerializedHeaders.id,
        unserialized: CodesDiagnoseUnserializedHeaders.id,
      },
      [CodesDiagnoseSerializedHeaders.companyIdentifier]: {
        serialized: CodesDiagnoseSerializedHeaders.companyIdentifier,
        unserialized: CodesDiagnoseUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesDiagnoseSerializedHeaders.diagnoseIdentifier]: {
        serialized: CodesDiagnoseSerializedHeaders.diagnoseIdentifier,
        unserialized: CodesDiagnoseUnserializedHeaders.diagnoseIdentifier,
        view: IntlKeys.farmManagementDataDiagnoseId,
      },
      [CodesDiagnoseSerializedHeaders.abbreviation]: {
        serialized: CodesDiagnoseSerializedHeaders.abbreviation,
        unserialized: CodesDiagnoseUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesDiagnoseSerializedHeaders.description]: {
        serialized: CodesDiagnoseSerializedHeaders.description,
        unserialized: CodesDiagnoseUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesDiagnoseSerializedHeaders.note]: {
        serialized: CodesDiagnoseSerializedHeaders.note,
        unserialized: CodesDiagnoseUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesDiagnoseSerializedHeaders.diagnoseDatetime]: {
        serialized: CodesDiagnoseSerializedHeaders.diagnoseDatetime,
        unserialized: CodesDiagnoseUnserializedHeaders.diagnoseDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesDiagnoseSerializedHeaders.ubnList]: {
        serialized: CodesDiagnoseSerializedHeaders.ubnList,
        unserialized: CodesDiagnoseUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesDiagnoseSerializedHeaders.kvkList]: {
        serialized: CodesDiagnoseSerializedHeaders.kvkList,
        unserialized: CodesDiagnoseUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesDiagnoseSerializedHeaders.registrationNameList]: {
        serialized: CodesDiagnoseSerializedHeaders.registrationNameList,
        unserialized: CodesDiagnoseUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import React from 'react';

import { makeStyles } from '@material-ui/core';

import FilterChips from 'components/DataTable/FilterChips';

import views from './views';
import { DataTableProps } from './index';

interface OwnProps {
  data: object[];
}

const ViewManager: React.FC<Omit<DataTableProps, 'data'> & OwnProps> = ({
  view = 'table',
  isLoading = false,
  className,
  showFilterChips = true,
  ...props
}) => {
  const classes = useStyles();

  const View = views[view];

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {showFilterChips && <FilterChips />}

      <View isLoading={isLoading} {...props} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '25px 0',

    '& [class*="orizontalScrollContainer"]': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
  },
}));

export default ViewManager;

import React from 'react';
import { AxiosError } from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar, ProviderContext, SharedProps, SnackbarProvider } from 'notistack';

import {
  BackRecordsErrorsMultiple,
  BackRecordsErrorsSingle,
  stringifyMultipleItemsErrors,
  stringifySingleItemErrors,
} from 'lib/http/errors';

//////////////////////////////////////////////////
let snackbarContextGlobal: ProviderContext;
//////////////////////////////////////////////////

export const SnackbarExtendedProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const snackBarClasses = useToastClasses();

  return (
    <SnackbarProvider classes={snackBarClasses} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
      {children}
      <SnackbarUtilsConfigurator />
    </SnackbarProvider>
  );
};

export const SnackbarUtilsConfigurator: React.FC = () => {
  snackbarContextGlobal = useSnackbar();
  return null;
};

export const useToastClasses = makeStyles(() => ({
  root: {
    whiteSpace: 'pre-line',
  },
}));

type SimpleError = { error?: string; errors?: string };
type PossibleErrorResponses = AxiosError<BackRecordsErrorsSingle | BackRecordsErrorsMultiple | SimpleError | undefined>;

// custom type guards
const isSimpleError = (axiosError: PossibleErrorResponses): axiosError is AxiosError<SimpleError> => {
  const errorData = axiosError.response?.data as SimpleError;
  return typeof errorData?.error === 'string' || typeof errorData?.errors === 'string';
};
const isSingleRecordError = (axiosError: PossibleErrorResponses): axiosError is AxiosError<BackRecordsErrorsSingle> => {
  return (axiosError.response?.data as BackRecordsErrorsSingle)?.errors instanceof Array;
};
const isMultiRecordError = (
  axiosError: PossibleErrorResponses,
): axiosError is AxiosError<BackRecordsErrorsMultiple> => {
  return (axiosError.response?.data as BackRecordsErrorsMultiple)?.record_errors instanceof Array;
};

export default {
  handleMessages(
    error: string | AxiosError<BackRecordsErrorsSingle | BackRecordsErrorsMultiple | SimpleError>,
    variant: SharedProps['variant'],
    options?: SharedProps,
  ) {
    let displayedMessage;

    try {
      if (typeof error === 'string') {
        displayedMessage = error;
        //
      } else if (isSimpleError(error)) {
        const errorData = error.response?.data as SimpleError;
        displayedMessage = errorData.error || errorData.errors;
        //
      } else if (isSingleRecordError(error)) {
        displayedMessage = stringifySingleItemErrors(error).join('\n');
        //
      } else if (isMultiRecordError(error)) {
        displayedMessage = stringifyMultipleItemsErrors(error).join('\n');
        //
      }
    } catch (e) {}

    if (typeof displayedMessage === 'string' && displayedMessage.indexOf('AxiosError:') === -1) {
      this.toast(displayedMessage, variant, options);
    }
  },
  success(msg: string, options?: SharedProps) {
    this.handleMessages(msg, 'success', options);
  },
  warning(msg: string, options?: SharedProps) {
    this.handleMessages(msg, 'warning', options);
  },
  info(msg: string, options?: SharedProps) {
    this.handleMessages(msg, 'info', options);
  },
  error(msg: string, options?: SharedProps) {
    this.handleMessages(msg, 'error', options);
  },
  toast(msg: string, variant: SharedProps['variant'], options: SharedProps = {}) {
    snackbarContextGlobal.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 5000,
      ...options,
    });
  },
};

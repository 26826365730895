import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo1DigitsFloating } from 'lib/helpers/renderHelpers';

import { CONTENT_OF_NITRIC_OXIDE } from 'store/entities/referenceTables/contentOfNitricOxide/config';

const { columns, view, serialized } = CONTENT_OF_NITRIC_OXIDE.ContentOfNitricOxide;

export const contentOfNitricOxideTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.livestockCategory.view,
          field: columns.livestockCategory.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.manureType.view,
          field: columns.manureType.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.valueN2oAndNo.view,
          field: columns.valueN2oAndNo.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.valueN2oAndNo.serialized] && roundTo1DigitsFloating(data[columns.valueN2oAndNo.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.unitN2oAndNo.view,
          field: columns.unitN2oAndNo.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.valueN2.view,
          field: columns.valueN2.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.valueN2.serialized] && roundTo1DigitsFloating(data[columns.valueN2.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.unitN2.view,
          field: columns.unitN2.serialized,
        },
      }),
    ],
  }),
]);

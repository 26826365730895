import React from 'react';
import { Checkbox as MaterialCheckbox, CheckboxProps, makeStyles } from '@material-ui/core';
// import { useDispatch } from 'react-redux';

interface OwnProps extends CheckboxProps {
  data: object;
}

const Checkbox: React.FC<OwnProps> = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  // TODO MAKES APP TO FALL
  // const handleChange = useCallback(() => dispatch(toggleRowAtMap(data)), [dispatch]);

  return <MaterialCheckbox color="primary" className={classes.checkbox} />;
};

const useStyles = makeStyles(() => ({
  checkbox: {
    '&&': {
      padding: 0,
    },
  },
}));

export default Checkbox;

import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Checkbox, Menu, MenuItem, Button, ButtonGroup, IconButton } from '@material-ui/core';

import { TableColumnDescriptor } from 'lib/tables/TableColumnsGenerator';
import { UiKeys } from 'lib/localization/keys/ui';

import {
  saveTableConfig,
  resetToDefault,
  toggleTableHeader,
  unsavedTableSettingsSelector,
} from 'store/reducers/userSettingsReducer';
import { ToggleTableHeaderDTO } from 'store/dto/ToggleTableHeaderDTO';

import { DataContext } from 'components/DataTable';

import MaterialViewColumnIcon from '@material-ui/icons/ViewColumn';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface OwnProps {}

const RowDetails: React.FC<OwnProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const tableConfigs = useSelector(unsavedTableSettingsSelector);
  const { currentTab, tableConfig } = useContext(DataContext);

  const [anchor, setAnchor] = useState<null | Element>(null);

  const currentTableConfig = tableConfigs && tableConfigs[tableConfig.id]?.[currentTab.id];

  const handleMenuOpen = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => setAnchor(event.currentTarget),
    [],
  );

  const handleMenuClose = useCallback(() => {
    setAnchor(null);
  }, []);

  return (
    <div>
      <IconButton onClick={handleMenuOpen} size="small">
        <MaterialViewColumnIcon />
      </IconButton>

      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem key="list title" className={classes.menuItem} disabled>
          {formatMessage({ id: UiKeys.tableSettings })}
        </MenuItem>

        {currentTab.getTableViewHeaders().filter(header => header.isRemovable).map((header) => {
          const { settings, isService, isRemovable, serviceLabel } = header;
          const rowTitle: string = (isService ? serviceLabel : settings.title) as string;

          return (
            <li key={rowTitle}>
              <MenuItem
                className={classes.formControlLabel}
                component="label"
                htmlFor={`column-toggle-${settings.field}`}
                disabled={!isRemovable}
              >
                <Checkbox
                  color="primary"
                  checked={!isRemovable || configContainsItemId(header.id, currentTableConfig)}
                  id={`column-toggle-${settings.field}`}
                  onChange={() => {
                    dispatch(
                      toggleTableHeader(
                        new ToggleTableHeaderDTO({
                          headerSettings: {
                            id: header.id,
                          },
                          tableId: tableConfig.id,
                          tabId: currentTab.id,
                        }),
                      ),
                    );
                  }}
                />
                <span>{currentTab.useLocalization ? (rowTitle ? formatMessage({ id: rowTitle }) : '') : rowTitle}</span>
              </MenuItem>
            </li>
          );
        })}

        <ButtonGroup key="text" className={classes.buttonContainer} orientation="vertical" size="large" color="primary">
          <Button onClick={() => dispatch(saveTableConfig())}>{formatMessage({ id: UiKeys.applySettings })}</Button>
          <Button onClick={() => dispatch(resetToDefault(currentTab.id))}>
            {formatMessage({ id: UiKeys.resetToDefault })}
          </Button>
        </ButtonGroup>
      </Menu>
    </div>
  );
};

const configContainsItemId = (headerId: string, currentTableSettings: Array<TableColumnDescriptor>) => {
  return Boolean(currentTableSettings?.find(({ id }) => id === headerId));
};

const useStyles = makeStyles((theme: Theme) => ({
  formControlLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  menuItem: {
    opacity: 1,
    fontWeight: 600,
    fontSize: 12,
  },

  buttonContainer: {
    width: '100%',
    opacity: 1,
    fontWeight: 600,
    fontSize: 12,
    padding: '6px 8px',
  },
}));

export default React.memo(RowDetails);

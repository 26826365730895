import { combineReducers } from '@reduxjs/toolkit';

import authReducer, { signOutAction } from './auth/reducer';
import adminReducer from './admin/reducer';
import routerReducer from './reducers/routerReducer';
import batchReducer from './reducers/batchReducer';
import uiReducer from './reducers/uiReducer';
import filtersReducer from './filters/reducer';
import mapReducer from './reducers/mapReducer';
import uploadReducer from './reducers/uploadReducer';
import dataCatalogueReducer from './dataCatalogue/reducer';
import userSettingsReducer from './reducers/userSettingsReducer';
import farmReducer from './reducers/farmReducer';
import vriReducer from './vri/reducer';
import vriCalculatedReducer from './vriCalculated/reducer';
import vriComparisonToolReducer from './vriComparisonTool/reducer';

export const createRootReducer = () => {
  const rootReducer = combineReducers({
    router: routerReducer,
    auth: authReducer,
    admin: adminReducer,
    batch: batchReducer,
    ui: uiReducer,
    filters: filtersReducer,
    map: mapReducer,
    upload: uploadReducer,
    dataCatalogue: dataCatalogueReducer,
    vri: vriReducer,
    vriCalculated: vriCalculatedReducer,
    vriComparisonTool: vriComparisonToolReducer,
    userSettings: userSettingsReducer,
    farm: farmReducer,
  });

  const resettableReducer: typeof rootReducer = (state, action) => {
    if (action.type === signOutAction.success) {
      state = undefined;
    }

    return rootReducer(state, action);
  };

  return resettableReducer;
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { PENS_LEVEL } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type PensLevelPayload = {
  id: number;
  pen_level: number | null;
  pen_level_en: string | null;
  pen_level_nl: string | null;
  pen_level_upload: ManageUploadPayload;
};

export type PensLevelSerialized = {
  id: number;
  referenceUploadID: number;
  penLevel: string;
  penNameNL: string;
  penNameEN: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = PENS_LEVEL.PensLevel;

const getDefaultSerializer = (data: DocumentData<PensLevelPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.penLevel.unserialized, columns.penLevel.serialized),
      new CellSerializer(columns.penNameNL.unserialized, columns.penNameNL.serialized),
      new CellSerializer(columns.penNameEN.unserialized, columns.penNameEN.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializePenLevels = (serverData: ResourcesWrapper<PensLevelPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo2DigitsFloating } from 'lib/helpers/renderHelpers';

import { RAV_CODES } from 'store/entities/referenceTables/ravCodes/config';

const { columns, view, serialized } = RAV_CODES.RavCodes;

export const ravCodesTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.animalCategory.view,
          field: columns.animalCategory.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ravCode.view,
          field: columns.ravCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.animalDescriptionNl.view,
          field: columns.animalDescriptionNl.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.animalDescriptionEn.view,
          field: columns.animalDescriptionEn.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.animalEnclosureCategory.view,
          field: columns.animalEnclosureCategory.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ammonia.view,
          field: columns.ammonia.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.ammonia.serialized] && roundTo2DigitsFloating(data[columns.ammonia.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.ammoniaUnit.view,
          field: columns.ammoniaUnit.serialized,
        },
      }),
    ],
  }),
]);

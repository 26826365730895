import { takeEvery, call } from 'redux-saga/effects';

import ensure from 'lib/ensure';
import { ApiClient } from 'lib/http/ApiClient';
import { CustomAction } from 'lib/actions';

import {
  fetchDataCatalogueAction,
  createDataObjectAction,
  deleteDataObjectAction,
  updateDataObjectAction,
} from './actions';
import {
  CatalogueDataObject,
  serializeDataObjectBackToFront,
  serializeDataObjectFrontToBack,
} from './entities';
import { AxiosResponse } from 'axios';

const ensureGetDataCatalogue = ensure({
  api: ApiClient.getDataCatalogueCategories,
  action: fetchDataCatalogueAction,
  serializeSuccessPayload: (response: AxiosResponse, requestPayload: object) => {
    const categories = response.data.resources;

    return categories.map(serializeDataObjectBackToFront);
  },
});

const ensureCreateCategory = ensure({
  api: ApiClient.createDataCatalogueCategory,
  action: createDataObjectAction,
  transformRequestData: ({ name, description }) => ({
    resource: {
      name,
      description,
    },
  }),
  serializeSuccessPayload: (response: AxiosResponse, requestPayload: object) => {
    const newCategory = response.data.resource;

    return serializeDataObjectBackToFront(newCategory);
  },
});

const ensureDeleteCategory = ensure({
  api: ApiClient.deleteDataCatalogueCategory,
  action: deleteDataObjectAction,
});

const ensureUpdateCategory = ensure({
  api: ApiClient.updateDataCatalogueCategory,
  action: updateDataObjectAction,
  transformRequestData: (category) => ({
    resource: serializeDataObjectFrontToBack(category),
  }),
  serializeSuccessPayload: (response: AxiosResponse, requestPayload: object) => {
    const newCategory = response.data.resource;

    return serializeDataObjectBackToFront(newCategory);
  },
});

function* getDataCatalogue() {
  yield call(ensureGetDataCatalogue);
}

function* createCategory({ payload }: CustomAction) {
  yield call(ensureCreateCategory, payload);
}

function* deleteCategory({ payload }: CustomAction) {
  yield call(ensureDeleteCategory, { params: payload });
}

function* updateCategory({
  //
  payload: { id, ...payload },
}: CustomAction<CatalogueDataObject>) {
  yield call(ensureUpdateCategory, { params: { id }, ...payload });
}

export default function* () {
  //
  yield takeEvery(fetchDataCatalogueAction.request, getDataCatalogue);
  yield takeEvery(createDataObjectAction.request, createCategory);
  yield takeEvery(deleteDataObjectAction.request, deleteCategory);
  yield takeEvery(updateDataObjectAction.request, updateCategory);
}

import { en } from './dictionary/en';
import { nl } from './dictionary/nl';

export enum Languages {
  en = 'EN',
  nl = 'NL',
}

export default {
  en,
  nl,
} as Record<keyof typeof Languages, Record<string, string>>;

/**
 * @deprecated use single IntlKeys
 */
export enum ServiceKeys {
  id = 'service.id',
  tableMenu = 'service.tableMenu',
  createdAt = 'service.createdAt',
  uploadedAt = 'service.uploadedAt',
  updatedAt = 'service.updatedAt',
  uploadedBy = 'service.uploadedBy',
  isEdited = 'service.isEdited',
  link = 'service.link',
  source = 'service.source',
  checksum = 'service.checksum',
  activeBlockchainRecord = 'service.activeBlockchainRecord',
  blockchainHashKey = 'service.blockchainHashKey',
  blockchainTimestamp = 'service.blockchainTimestamp',
  blockchainId = 'service.blockchainId',
  blockchainMessageId = 'service.blockchainMessageId',
  checkbox = 'service.checkbox',
}

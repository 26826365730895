import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { CODES_DIAGNOSE } from 'store/entities/farmManagementData/codesDiagnose/config';

const { columns, view, serialized } = CODES_DIAGNOSE.CodesDiagnose;

export const codesDiagnoseTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.diagnoseIdentifier.view,
          field: columns.diagnoseIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.abbreviation.view,
          field: columns.abbreviation.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.description.view,
          field: columns.description.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.note.view,
          field: columns.note.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.diagnoseDatetime.view,
          field: columns.diagnoseDatetime.serialized,
        },
      }),
    ],
  }),
]);

import { IntlKeys } from 'lib/localization/keys';

enum CodesWeightTypeSerialized {
  CodesWeightType = 'CodesWeightType',
}

enum CodesWeightTypeUnserialized {
  CodesWeightType = 'CodesWeightType',
}

export enum CodesWeightTypeSerializedHeaders {
  id = 'id',
  weightTypeId = 'weightTypeId',
  note = 'note',
  weightTypeDatetime = 'weightTypeDatetime',
}

export const CodesWeightTypeUnserializedHeaders = {
  id: 'id',
  weightTypeId: 'weight_type_identifier',
  note: 'note',
  weightTypeDatetime: 'weight_type_datetime',
} as const;

export const CODES_WEIGHT_TYPE = {
  [CodesWeightTypeSerialized.CodesWeightType]: {
    serialized: CodesWeightTypeSerialized.CodesWeightType,
    unserialized: CodesWeightTypeUnserialized.CodesWeightType,
    view: IntlKeys.farmManagementDataWeightTypeList,

    columns: {
      [CodesWeightTypeSerializedHeaders.id]: {
        serialized: CodesWeightTypeSerializedHeaders.id,
        unserialized: CodesWeightTypeUnserializedHeaders.id,
      },
      [CodesWeightTypeSerializedHeaders.weightTypeId]: {
        serialized: CodesWeightTypeSerializedHeaders.weightTypeId,
        unserialized: CodesWeightTypeUnserializedHeaders.weightTypeId,
        view: IntlKeys.farmManagementDataWeightTypeId,
      },
      [CodesWeightTypeSerializedHeaders.note]: {
        serialized: CodesWeightTypeSerializedHeaders.note,
        unserialized: CodesWeightTypeUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesWeightTypeSerializedHeaders.weightTypeDatetime]: {
        serialized: CodesWeightTypeSerializedHeaders.weightTypeDatetime,
        unserialized: CodesWeightTypeUnserializedHeaders.weightTypeDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
    },
  },
};

import { WUR_MFC_EFEM_FIELDS } from 'lib/excel/config/referenceTables/wurMfcEfem/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = WUR_MFC_EFEM_FIELDS.WurMfcEfem;

export const getWurMfcEfemDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.system.original),
      new CellValidator(columns.avgStorageDurationManure.original),
      new CellValidator(columns.mcf.original),
    ]),
  ]);
};

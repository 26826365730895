import { IntlKeys } from 'lib/localization/keys/__keys';

enum ManureReportsSerialized {
  ManureReports = 'ManureReports',
  ManureReportsSubmit = 'ManureReportsSubmit',
}

enum ManureReportsUnserialized {
  ManureReports = 'ManureReports',
  ManureReportsSubmit = 'ManureReportsSubmit',
}

export enum ManureReportsSerializedHeaders {
  id = 'id',
  status = 'status',
  ubn = 'ubn',
  idType = 'idType',
  companyReport = 'companyReport',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  processedManure = 'processedManure',
  sodiumHydroxide = 'sodiumHydroxide',
  glycerineUsed = 'glycerineUsed',
  digestateProduction = 'digestateProduction',
  biogasProduction = 'biogasProduction',
  electricityProduction = 'electricityProduction',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
}

export const ManureReportsUnserializedHeaders = {
  id: 'id',
  status: 'status',
  ubn: ['company_report', 'user_passport', 'ubn'],
  idType: ['company_report', 'id_type'],
  companyReport: ['company_report', 'id'],
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  processedManure: 'processed_manure',
  sodiumHydroxide: 'sodium_hydroxide',
  glycerineUsed: 'glycerine_used',
  digestateProduction: 'digestate_production',
  biogasProduction: 'biogas_production',
  electricityProduction: 'electricity_production',
  updatedAt: 'updated_at',
  updatedBy: ['updated_by', 'full_name'],
} as const;

export enum ManureReportsSubmitSerializedHeaders {
  id = 'id',
  status = 'status',
  companyReport = 'companyReport',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  processedManure = 'processedManure',
  sodiumHydroxide = 'sodiumHydroxide',
  glycerineUsed = 'glycerineUsed',
  digestateProduction = 'digestateProduction',
  biogasProduction = 'biogasProduction',
  electricityProduction = 'electricityProduction',
}

export const ManureReportsSubmitUnserializedHeaders = {
  id: 'id',
  status: 'status',
  companyReport: 'company_report_id',
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  processedManure: 'processed_manure',
  sodiumHydroxide: 'sodium_hydroxide',
  glycerineUsed: 'glycerine_used',
  digestateProduction: 'digestate_production',
  biogasProduction: 'biogas_production',
  electricityProduction: 'electricity_production',
} as const;

export const MANURE_REPORTS = {
  [ManureReportsSerialized.ManureReports]: {
    serialized: ManureReportsSerialized.ManureReports,
    unserialized: ManureReportsUnserialized.ManureReports,
    view: IntlKeys.titleReportList,

    columns: {
      [ManureReportsSerializedHeaders.id]: {
        serialized: ManureReportsSerializedHeaders.id,
        unserialized: ManureReportsUnserializedHeaders.id,
        view: IntlKeys.farmerInputSystemId,
      },
      [ManureReportsSerializedHeaders.status]: {
        serialized: ManureReportsSerializedHeaders.status,
        unserialized: ManureReportsUnserializedHeaders.status,
        view: IntlKeys.farmerInputStatus,
      },
      [ManureReportsSerializedHeaders.ubn]: {
        serialized: ManureReportsSerializedHeaders.ubn,
        unserialized: ManureReportsUnserializedHeaders.ubn,
        view: IntlKeys.farmerInputIdCode,
      },
      [ManureReportsSerializedHeaders.idType]: {
        serialized: ManureReportsSerializedHeaders.idType,
        unserialized: ManureReportsUnserializedHeaders.idType,
        view: IntlKeys.farmerInputIdType,
      },
      [ManureReportsSerializedHeaders.companyReport]: {
        serialized: ManureReportsSerializedHeaders.companyReport,
        unserialized: ManureReportsUnserializedHeaders.companyReport,
        view: IntlKeys.farmerInputMainReportId,
      },
      [ManureReportsSerializedHeaders.reportingPeriodDateStart]: {
        serialized: ManureReportsSerializedHeaders.reportingPeriodDateStart,
        unserialized: ManureReportsUnserializedHeaders.reportingPeriodDateStart,
        view: IntlKeys.farmerInputPeriodStartDate,
      },
      [ManureReportsSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: ManureReportsSerializedHeaders.reportingPeriodDateEnd,
        unserialized: ManureReportsUnserializedHeaders.reportingPeriodDateEnd,
        view: IntlKeys.farmerInputPeriodEndDate,
      },
      [ManureReportsSerializedHeaders.processedManure]: {
        serialized: ManureReportsSerializedHeaders.processedManure,
        unserialized: ManureReportsUnserializedHeaders.processedManure,
        view: IntlKeys.farmerInputProcessedManure,
      },
      [ManureReportsSerializedHeaders.sodiumHydroxide]: {
        serialized: ManureReportsSerializedHeaders.sodiumHydroxide,
        unserialized: ManureReportsUnserializedHeaders.sodiumHydroxide,
        view: IntlKeys.farmerInputSodiumHydroxide,
      },
      [ManureReportsSerializedHeaders.glycerineUsed]: {
        serialized: ManureReportsSerializedHeaders.glycerineUsed,
        unserialized: ManureReportsUnserializedHeaders.glycerineUsed,
        view: IntlKeys.farmerInputGlycerineUsed,
      },
      [ManureReportsSerializedHeaders.digestateProduction]: {
        serialized: ManureReportsSerializedHeaders.digestateProduction,
        unserialized: ManureReportsUnserializedHeaders.digestateProduction,
        view: IntlKeys.farmerInputDigestateProduction,
      },
      [ManureReportsSerializedHeaders.biogasProduction]: {
        serialized: ManureReportsSerializedHeaders.biogasProduction,
        unserialized: ManureReportsUnserializedHeaders.biogasProduction,
        view: IntlKeys.farmerInputBiogasProduction,
      },
      [ManureReportsSerializedHeaders.electricityProduction]: {
        serialized: ManureReportsSerializedHeaders.electricityProduction,
        unserialized: ManureReportsUnserializedHeaders.electricityProduction,
        view: IntlKeys.farmerInputElectricityProduction,
      },
      [ManureReportsSerializedHeaders.updatedAt]: {
        serialized: ManureReportsSerializedHeaders.updatedAt,
        unserialized: ManureReportsUnserializedHeaders.updatedAt,
        view: IntlKeys.farmerInputUpdatedOn,
      },
      [ManureReportsSerializedHeaders.updatedBy]: {
        serialized: ManureReportsSerializedHeaders.updatedBy,
        unserialized: ManureReportsUnserializedHeaders.updatedBy,
        view: IntlKeys.farmerInputUpdatedBy,
      },
    },
  },
};

export const MANURE_REPORTS_SUBMIT = {
  [ManureReportsSerialized.ManureReportsSubmit]: {
    serialized: ManureReportsSerialized.ManureReportsSubmit,
    unserialized: ManureReportsUnserialized.ManureReportsSubmit,

    columns: {
      [ManureReportsSubmitSerializedHeaders.id]: {
        serialized: ManureReportsSubmitSerializedHeaders.id,
        unserialized: ManureReportsSubmitUnserializedHeaders.id,
      },
      [ManureReportsSubmitSerializedHeaders.status]: {
        serialized: ManureReportsSubmitSerializedHeaders.status,
        unserialized: ManureReportsSubmitUnserializedHeaders.status,
      },
      [ManureReportsSubmitSerializedHeaders.companyReport]: {
        serialized: ManureReportsSubmitSerializedHeaders.companyReport,
        unserialized: ManureReportsSubmitUnserializedHeaders.companyReport,
      },
      [ManureReportsSubmitSerializedHeaders.reportingPeriodDateStart]: {
        serialized: ManureReportsSubmitSerializedHeaders.reportingPeriodDateStart,
        unserialized: ManureReportsSubmitUnserializedHeaders.reportingPeriodDateStart,
      },
      [ManureReportsSubmitSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: ManureReportsSubmitSerializedHeaders.reportingPeriodDateEnd,
        unserialized: ManureReportsSubmitUnserializedHeaders.reportingPeriodDateEnd,
      },
      [ManureReportsSubmitSerializedHeaders.processedManure]: {
        serialized: ManureReportsSubmitSerializedHeaders.processedManure,
        unserialized: ManureReportsSubmitUnserializedHeaders.processedManure,
      },
      [ManureReportsSubmitSerializedHeaders.sodiumHydroxide]: {
        serialized: ManureReportsSubmitSerializedHeaders.sodiumHydroxide,
        unserialized: ManureReportsSubmitUnserializedHeaders.sodiumHydroxide,
      },
      [ManureReportsSubmitSerializedHeaders.glycerineUsed]: {
        serialized: ManureReportsSubmitSerializedHeaders.glycerineUsed,
        unserialized: ManureReportsSubmitUnserializedHeaders.glycerineUsed,
      },
      [ManureReportsSubmitSerializedHeaders.digestateProduction]: {
        serialized: ManureReportsSubmitSerializedHeaders.digestateProduction,
        unserialized: ManureReportsSubmitUnserializedHeaders.digestateProduction,
      },
      [ManureReportsSubmitSerializedHeaders.biogasProduction]: {
        serialized: ManureReportsSubmitSerializedHeaders.biogasProduction,
        unserialized: ManureReportsSubmitUnserializedHeaders.biogasProduction,
      },
      [ManureReportsSubmitSerializedHeaders.electricityProduction]: {
        serialized: ManureReportsSubmitSerializedHeaders.electricityProduction,
        unserialized: ManureReportsSubmitUnserializedHeaders.electricityProduction,
      },
    },
  },
};

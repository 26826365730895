import { IPCC_EMISSION_FACTORS_FIELDS } from '../structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = IPCC_EMISSION_FACTORS_FIELDS.IpccEmissionFactors;

export const getIpccEmissionFactorsDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.factor.original),
      new CellValidator(columns.description.original),
      new CellValidator(columns.defaultValue.original),
      new CellValidator(columns.uncertaintyRange.original),
    ]),
  ]);
};

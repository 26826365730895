import React from 'react';

import { TableTabSettings } from 'lib/tables/TableTab';
import { HybridColumnSettings } from 'lib/tables/HybridView';
import { SerializedServiceFields, SERVICE_FIELDS } from 'lib/excel/config/service';

import RowDisplayConfigure from 'components/DataTable/models/components/RowDisplayConfigure';

const RowContextMenu = ({ rowContextMenuProps }: TableTabSettings) => {
  return ({
    title: <RowDisplayConfigure />,
    field: SERVICE_FIELDS[SerializedServiceFields.TABLE_MENU].serialized,
    render: null,
    isService: true,
    isRemovable: false,
    headerStyle: {
      paddingRight: 5,
      paddingLeft: 5,
      textAlign: 'center',
    },
    cellStyle: {
      paddingRight: 5,
      paddingLeft: 5,
      textAlign: 'center',
    },
    width: 50, // has to be the same as last
    sorting: false,
    grouping: false,
    ...rowContextMenuProps,
  } as unknown) as HybridColumnSettings;
};

export default RowContextMenu;

import { PERMISSIONS } from 'store/auth/permissions';

import { OptionType } from 'components/form/FormAutocomplete/FormAutocompleteComponent';

import LabelIcon from '@material-ui/icons/Label';

import { FILTER_TYPES } from './index';
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon/SvgIcon";

type SingleFilterValue = null | string | number;
type MultiFilterValue = Array<string | number>;

type MultiFilterConfig = {
  options?: OptionType[];
  customTags?: boolean;
  icon?: OverridableComponent<SvgIconTypeMap>
};

export const singleValueFilter = (title?: string, options?: OptionType[], permissions?: PERMISSIONS[]) => ({
  title,
  options,
  permissions,
  type: FILTER_TYPES.SINGLE_VALUE,
  getInitialValues: () => null as SingleFilterValue,
  getQuery: (filterValue: SingleFilterValue) => filterValue || {},
  getValues: (filterValue: SingleFilterValue) => filterValue || null,
  getLabel: (filterValue: SingleFilterValue) => filterValue || '',
  icon: LabelIcon,
});

export const multiValueFilter = (title: string, config?: MultiFilterConfig) => ({
  title,
  options: config?.options,
  customTags: config?.customTags,
  multiple: true,
  type: FILTER_TYPES.MULTI_VALUE,
  getInitialValues: () => [] as MultiFilterValue,
  getQuery: (filterValue: MultiFilterValue) => filterValue || {},
  getValues: (filterValue: MultiFilterValue) => filterValue || [],
  getLabel: (filterValue: MultiFilterValue) => (filterValue?.length ? filterValue.join(', ') : ''),
  icon: config?.icon || LabelIcon,
});

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_ACCOUNT } from './config';

export type CodesAccountPayload = {
  id: number;
  company_identifier: string;
  account_identifier: string;
  code: string;
  abbreviation: string;
  description: string;
  contact_identifier: string;
  percent_sows: string;
  percent_weaners: string;
  percent_finishers: string;
  percent_repl_gilts: string;
  type_identifier: string;
  active: string;
  account_datetime: string;
  ev_type: string;
  vat_identifier: string;
  barn: string;
  medicine: string;
  inoculation: string;
  hormone: string;
  medicine_ean_code: string;
  medicine_country_code: string;
  medicine_official_name: string;
  medicine_date_end: string;
  medicine_unit: string;
  ev: string;
  medicine_wh_period: string;
  medicine_dosage: string;
  medicine_dosage_factor: string;
  supplier_identifier: string;
  medicine_emb_code: string;
};

export type CodesAccountSerialized = {
  id: number;
  companyIdentifier: string;
  accountIdentifier: string;
  code: string;
  abbreviation: string;
  description: string;
  contactIdentifier: string;
  percentSows: string;
  percentWeaners: string;
  percentFinishers: string;
  percentReplGilts: string;
  typeIdentifier: string;
  active: string;
  accountDatetime: string;
  evType: string;
  vatIdentifier: string;
  barn: string;
  medicine: string;
  inoculation: string;
  hormone: string;
  medicineEanCode: string;
  medicineCountryCode: string;
  medicineOfficialName: string;
  medicineDateEnd: string;
  medicineUnit: string;
  ev: string;
  medicineWhPeriod: string;
  medicineDosage: string;
  medicineDosageFactor: string;
  supplierIdentifier: string;
  medicineEmbCode: string;
};

const { columns, serialized, unserialized } = CODES_ACCOUNT.CodesAccount;

const getDefaultSerializer = (data: DocumentData<CodesAccountPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.accountIdentifier.unserialized, columns.accountIdentifier.serialized),
      new CellSerializer(columns.code.unserialized, columns.code.serialized),
      new CellSerializer(columns.abbreviation.unserialized, columns.abbreviation.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.contactIdentifier.unserialized, columns.contactIdentifier.serialized),
      new CellSerializer(columns.percentSows.unserialized, columns.percentSows.serialized),
      new CellSerializer(columns.percentWeaners.unserialized, columns.percentWeaners.serialized),
      new CellSerializer(columns.percentWeaners.unserialized, columns.percentWeaners.serialized),
      new CellSerializer(columns.percentFinishers.unserialized, columns.percentFinishers.serialized),
      new CellSerializer(columns.percentReplGilts.unserialized, columns.percentReplGilts.serialized),
      new CellSerializer(columns.typeIdentifier.unserialized, columns.typeIdentifier.serialized),
      new CellSerializer(columns.active.unserialized, columns.active.serialized),
      new CellSerializer(columns.accountDatetime.unserialized, columns.accountDatetime.serialized),
      new CellSerializer(columns.evType.unserialized, columns.evType.serialized),
      new CellSerializer(columns.vatIdentifier.unserialized, columns.vatIdentifier.serialized),
      new CellSerializer(columns.barn.unserialized, columns.barn.serialized),
      new CellSerializer(columns.medicine.unserialized, columns.medicine.serialized),
      new CellSerializer(columns.inoculation.unserialized, columns.inoculation.serialized),
      new CellSerializer(columns.hormone.unserialized, columns.hormone.serialized),
      new CellSerializer(columns.medicineEanCode.unserialized, columns.medicineEanCode.serialized),
      new CellSerializer(columns.medicineCountryCode.unserialized, columns.medicineCountryCode.serialized),
      new CellSerializer(columns.medicineOfficialName.unserialized, columns.medicineOfficialName.serialized),
      new CellSerializer(columns.medicineDateEnd.unserialized, columns.medicineDateEnd.serialized),
      new CellSerializer(columns.medicineUnit.unserialized, columns.medicineUnit.serialized),
      new CellSerializer(columns.ev.unserialized, columns.ev.serialized),
      new CellSerializer(columns.medicineWhPeriod.unserialized, columns.medicineWhPeriod.serialized),
      new CellSerializer(columns.medicineDosage.unserialized, columns.medicineDosage.serialized),
      new CellSerializer(columns.medicineDosageFactor.unserialized, columns.medicineDosageFactor.serialized),
      new CellSerializer(columns.supplierIdentifier.unserialized, columns.supplierIdentifier.serialized),
      new CellSerializer(columns.medicineEmbCode.unserialized, columns.medicineEmbCode.serialized),
    ]),
  ]);
};

export const serializeCodesAccount = (serverData: ResourcesWrapper<CodesAccountPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

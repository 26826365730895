import React from 'react';

// @ts-ignore
import { defaultProps } from 'material-table/dist/default-props';

type GroupData = {
  data: any[];
  groups?: GroupData[];
};

interface OwnProps {
  //
  [T: string]: any;
}

const CustomGroupRow: React.FC<OwnProps> = ({ groupData, ...props }) => {
  const group = props.groups[props.level];
  const renderedValue = group.render ? getData(groupData) : groupData.value;

  return (
    <defaultProps.components.GroupRow
      //
      {...props}
      groupData={{ ...groupData, value: renderedValue }}
    />
  );
};

const getData = function getData(groupData: GroupData): any {
  //
  return groupData.groups && groupData.groups.length > 0 ? getData(groupData.groups![0]) : groupData.data[0];
};

export default React.memo(CustomGroupRow);

import { pensLevelTableConfig } from 'lib/tables/config/referenceTables/pensLevel';
import { pensTypeSowBarnTableConfig } from 'lib/tables/config/referenceTables/pensTypeSowBarn';
import { yesNoTableConfig } from 'lib/tables/config/referenceTables/yesNo';
import { cfpCo2EmissionFactorTableConfig } from 'lib/tables/config/referenceTables/cfpCo2EmissionFactor';
import { cvbTableConfig } from 'lib/tables/config/referenceTables/cvbTable';
import { ipccEmissionFactorsTableConfig } from 'lib/tables/config/referenceTables/ipccEmissionFactors';
import { cfpGfliFeedprintTableConfig } from 'lib/tables/config/referenceTables/cfpGfliFeedprint';
import { contentOfNitricOxideTableConfig } from 'lib/tables/config/referenceTables/contentOfNitricOxide';
import { pigHousingEmissionFactorsConfig } from 'lib/tables/config/referenceTables/pigHousingEmissionFactors';
import { electricityConsumptionRatesTableConfig } from 'lib/tables/config/referenceTables/electricityConsumptionRates';
import { gasConsumptionRatesTableConfig } from 'lib/tables/config/referenceTables/gasConsumptionRates';
import { waterConsumptionRatesTableConfig } from 'lib/tables/config/referenceTables/waterConsumptionRates';
import { ravCodesTableConfig } from 'lib/tables/config/referenceTables/ravCodes';
import { gwp100FactorsTableConfig } from 'lib/tables/config/referenceTables/gwp100Factors';
import { wurMcfEfemTableConfig } from 'lib/tables/config/referenceTables/wurMcfEfem';
import { cl550TableConfig } from 'lib/tables/config/referenceTables/cl550';
import { cl649TableConfig } from 'lib/tables/config/referenceTables/cl649';
import { cl650TableConfig } from 'lib/tables/config/referenceTables/cl650';
import { cl607TableConfig } from 'lib/tables/config/referenceTables/cl607';
import { suppliersNumbersTableConfig } from 'lib/tables/config/referenceTables/suppliersNumbers';
import { countryCodesTableConfig } from 'lib/tables/config/referenceTables/countryCodes';
import { ravReferenceListTableConfig } from 'lib/tables/config/referenceTables/ravReferenceList';
import { allocationManureTableConfig } from 'lib/tables/config/referenceTables/allocationManure';
import { greenhouseGasEmissionTableConfig } from 'lib/tables/config/referenceTables/greenhouseGasEmission';
import { entitiesListTableConfig } from 'lib/tables/config/referenceTables/entitiesList';

import {
  ensureGetAdminCfpCo2EmissionFactors,
  ensureGetAdminPenLevels,
  ensureGetAdminPensTypeSowBarn,
  ensureGetAdminYesNo,
  ensureGetAdminCvbTable,
  ensureGetAdminIpccEmissionFactors,
  ensureGetAdminCfpGfliFeedprintTable,
  ensureGetAdminContentOfNitricOxide,
  ensureGetAdminPigHousingEmissionFactors,
  ensureGetAdminElectricityConsumptionRates,
  ensureGetAdminGasConsumptionRates,
  ensureGetAdminWaterConsumptionRates,
  ensureGetAdminRavCodes,
  ensureGetAdminGwp100Factors,
  ensureGetAdminWurMcfEfem,
  ensureGetAdminCl550,
  ensureGetAdminCl649,
  ensureGetAdminCl650,
  ensureGetAdminCl607,
  ensureGetAdminSuppliersNumbers,
  ensureGetAdminCountryCodes,
  ensureGetAdminRavReferenceList,
  ensureGetAdminAllocationManure,
  ensureGetAdminGreenhouseGasEmission,
  ensureGetAdminEntitiesList,
} from 'store/sagas/batches';

export enum REFERENCE_TABLES_TYPES {
  pensLevel = 'pen_level_upload',
  pensTypeSowBarn = 'pen_type_sow_barn_upload',
  yesNo = 'answer_code_upload',
  cfpCo2EmissionFactor = 'co2_emission_factor_upload',
  cvbTable = 'carbon_foot_print_upload',
  ipccEmissionFactors = 'ipcc_emission_factor_upload',
  cfpGfliFeedprintTable = 'feed_print_upload',
  contentOfNitricOxide = 'content_of_nitric_oxide_upload',
  pigHousingEmissionFactors = 'pig_housing_emission_factor_upload',
  electricityConsumptionRates = 'electricity_consumption_rate_upload',
  gasConsumptionRates = 'gas_consumption_rate_upload',
  waterConsumptionRates = 'water_consumption_rate_upload',
  ravCodes = 'rav_code_upload',
  gwp100Factors = 'gwp100_factor_upload',
  wurMcfEfem = 'wur_mcf_efem_upload',
  cl550 = 'pig_disease_code_upload',
  cl649 = 'charge_or_premium_code_type_upload',
  cl650 = 'group_charge_or_premium_code_upload',
  cl607 = 'animal_id_code_type_upload',
  suppliersNumbers = 'supplier_number_upload',
  countryCodes = 'country_code_upload',
  ravReferenceList = 'rav_reference_list_upload',
  allocationManure = 'allocation_manure_upload',
  greenhouseGasEmission = 'greenhouse_gas_emission_upload',
  entitiesList = 'entities_list_upload',
}

export const REFERENCE_TABLES_UTILS = {
  [REFERENCE_TABLES_TYPES.pensLevel]: {
    tableConfig: pensLevelTableConfig,
    ensure: ensureGetAdminPenLevels,
  },
  [REFERENCE_TABLES_TYPES.pensTypeSowBarn]: {
    tableConfig: pensTypeSowBarnTableConfig,
    ensure: ensureGetAdminPensTypeSowBarn,
  },
  [REFERENCE_TABLES_TYPES.yesNo]: {
    tableConfig: yesNoTableConfig,
    ensure: ensureGetAdminYesNo,
  },
  [REFERENCE_TABLES_TYPES.cfpCo2EmissionFactor]: {
    tableConfig: cfpCo2EmissionFactorTableConfig,
    ensure: ensureGetAdminCfpCo2EmissionFactors,
  },
  [REFERENCE_TABLES_TYPES.cvbTable]: {
    tableConfig: cvbTableConfig,
    ensure: ensureGetAdminCvbTable,
  },
  [REFERENCE_TABLES_TYPES.ipccEmissionFactors]: {
    tableConfig: ipccEmissionFactorsTableConfig,
    ensure: ensureGetAdminIpccEmissionFactors,
  },
  [REFERENCE_TABLES_TYPES.cfpGfliFeedprintTable]: {
    tableConfig: cfpGfliFeedprintTableConfig,
    ensure: ensureGetAdminCfpGfliFeedprintTable,
  },
  [REFERENCE_TABLES_TYPES.contentOfNitricOxide]: {
    tableConfig: contentOfNitricOxideTableConfig,
    ensure: ensureGetAdminContentOfNitricOxide,
  },
  [REFERENCE_TABLES_TYPES.pigHousingEmissionFactors]: {
    tableConfig: pigHousingEmissionFactorsConfig,
    ensure: ensureGetAdminPigHousingEmissionFactors,
  },
  [REFERENCE_TABLES_TYPES.electricityConsumptionRates]: {
    tableConfig: electricityConsumptionRatesTableConfig,
    ensure: ensureGetAdminElectricityConsumptionRates,
  },
  [REFERENCE_TABLES_TYPES.gasConsumptionRates]: {
    tableConfig: gasConsumptionRatesTableConfig,
    ensure: ensureGetAdminGasConsumptionRates,
  },
  [REFERENCE_TABLES_TYPES.waterConsumptionRates]: {
    tableConfig: waterConsumptionRatesTableConfig,
    ensure: ensureGetAdminWaterConsumptionRates,
  },
  [REFERENCE_TABLES_TYPES.ravCodes]: {
    tableConfig: ravCodesTableConfig,
    ensure: ensureGetAdminRavCodes,
  },
  [REFERENCE_TABLES_TYPES.gwp100Factors]: {
    tableConfig: gwp100FactorsTableConfig,
    ensure: ensureGetAdminGwp100Factors,
  },
  [REFERENCE_TABLES_TYPES.wurMcfEfem]: {
    tableConfig: wurMcfEfemTableConfig,
    ensure: ensureGetAdminWurMcfEfem,
  },
  [REFERENCE_TABLES_TYPES.cl550]: {
    tableConfig: cl550TableConfig,
    ensure: ensureGetAdminCl550,
  },
  [REFERENCE_TABLES_TYPES.cl649]: {
    tableConfig: cl649TableConfig,
    ensure: ensureGetAdminCl649,
  },
  [REFERENCE_TABLES_TYPES.cl650]: {
    tableConfig: cl650TableConfig,
    ensure: ensureGetAdminCl650,
  },
  [REFERENCE_TABLES_TYPES.cl607]: {
    tableConfig: cl607TableConfig,
    ensure: ensureGetAdminCl607,
  },
  [REFERENCE_TABLES_TYPES.suppliersNumbers]: {
    tableConfig: suppliersNumbersTableConfig,
    ensure: ensureGetAdminSuppliersNumbers,
  },
  [REFERENCE_TABLES_TYPES.countryCodes]: {
    tableConfig: countryCodesTableConfig,
    ensure: ensureGetAdminCountryCodes,
  },
  [REFERENCE_TABLES_TYPES.ravReferenceList]: {
    tableConfig: ravReferenceListTableConfig,
    ensure: ensureGetAdminRavReferenceList,
  },
  [REFERENCE_TABLES_TYPES.allocationManure]: {
    tableConfig: allocationManureTableConfig,
    ensure: ensureGetAdminAllocationManure,
  },
  [REFERENCE_TABLES_TYPES.greenhouseGasEmission]: {
    tableConfig: greenhouseGasEmissionTableConfig,
    ensure: ensureGetAdminGreenhouseGasEmission,
  },
  [REFERENCE_TABLES_TYPES.entitiesList]: {
    tableConfig: entitiesListTableConfig,
    ensure: ensureGetAdminEntitiesList,
  },
};

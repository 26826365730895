import { IntlKeys } from 'lib/localization/keys';

enum GroupMovesSerialized {
  GroupMoves = 'GroupMoves',
}

enum GroupMovesUnserialized {
  GroupMoves = 'GroupMoves',
}

export enum GroupMovesSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  finEventIdentifier = 'finEventIdentifier',
  groupFrom = 'groupFrom',
  barnFrom = 'barnFrom',
  roomFrom = 'roomFrom',
  penFrom = 'penFrom',
  animalCategoryIdFrom = 'animalCategoryIdFrom',
  number = 'number',
  weight = 'weight',
  groupMoveDate = 'groupMoveDate',
  animalCategoryIdTo = 'animalCategoryIdTo',
  withoutMale = 'withoutMale',
  withoutFemale = 'withoutFemale',
  withoutMaleCastrated = 'withoutMaleCastrated',
  adminDate = 'adminDate',
  remReason = 'remReason',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupMovesUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  finEventIdentifier: 'financial_event_identifier',
  groupFrom: 'group_from',
  barnFrom: 'barn_from',
  roomFrom: 'room_from',
  penFrom: 'pen_from',
  animalCategoryIdFrom: 'animal_category_id_from',
  number: 'number',
  weight: 'weight',
  groupMoveDate: 'group_move_date',
  animalCategoryIdTo: 'animal_category_id_to',
  withoutMale: 'without_male',
  withoutFemale: 'without_female',
  withoutMaleCastrated: 'without_male_castrated',
  adminDate: 'admin_date',
  remReason: 'rem_reason',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_MOVES = {
  [GroupMovesSerialized.GroupMoves]: {
    serialized: GroupMovesSerialized.GroupMoves,
    unserialized: GroupMovesUnserialized.GroupMoves,
    view: IntlKeys.farmManagementDataWeightsList,

    columns: {
      [GroupMovesSerializedHeaders.id]: {
        serialized: GroupMovesSerializedHeaders.id,
        unserialized: GroupMovesUnserializedHeaders.id,
      },
      [GroupMovesSerializedHeaders.companyIdentifier]: {
        serialized: GroupMovesSerializedHeaders.companyIdentifier,
        unserialized: GroupMovesUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupMovesSerializedHeaders.farmIdentifier]: {
        serialized: GroupMovesSerializedHeaders.farmIdentifier,
        unserialized: GroupMovesUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupMovesSerializedHeaders.finEventIdentifier]: {
        serialized: GroupMovesSerializedHeaders.finEventIdentifier,
        unserialized: GroupMovesUnserializedHeaders.finEventIdentifier,
        view: IntlKeys.farmManagementDataFinEventId,
      },
      [GroupMovesSerializedHeaders.groupFrom]: {
        serialized: GroupMovesSerializedHeaders.groupFrom,
        unserialized: GroupMovesUnserializedHeaders.groupFrom,
        view: IntlKeys.farmManagementDataGroupFrom,
      },
      [GroupMovesSerializedHeaders.barnFrom]: {
        serialized: GroupMovesSerializedHeaders.barnFrom,
        unserialized: GroupMovesUnserializedHeaders.barnFrom,
        view: IntlKeys.farmManagementDataBarnFrom,
      },
      [GroupMovesSerializedHeaders.roomFrom]: {
        serialized: GroupMovesSerializedHeaders.roomFrom,
        unserialized: GroupMovesUnserializedHeaders.roomFrom,
        view: IntlKeys.farmManagementDataRoomFrom,
      },
      [GroupMovesSerializedHeaders.penFrom]: {
        serialized: GroupMovesSerializedHeaders.penFrom,
        unserialized: GroupMovesUnserializedHeaders.penFrom,
        view: IntlKeys.farmManagementDataPenFrom,
      },
      [GroupMovesSerializedHeaders.animalCategoryIdFrom]: {
        serialized: GroupMovesSerializedHeaders.animalCategoryIdFrom,
        unserialized: GroupMovesUnserializedHeaders.animalCategoryIdFrom,
        view: IntlKeys.farmManagementDataAnimalCatIdFrom,
      },
      [GroupMovesSerializedHeaders.number]: {
        serialized: GroupMovesSerializedHeaders.number,
        unserialized: GroupMovesUnserializedHeaders.number,
        view: IntlKeys.farmManagementDataNumber,
      },
      [GroupMovesSerializedHeaders.weight]: {
        serialized: GroupMovesSerializedHeaders.weight,
        unserialized: GroupMovesUnserializedHeaders.weight,
        view: IntlKeys.farmManagementDataWeight,
      },
      [GroupMovesSerializedHeaders.groupMoveDate]: {
        serialized: GroupMovesSerializedHeaders.groupMoveDate,
        unserialized: GroupMovesUnserializedHeaders.groupMoveDate,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupMovesSerializedHeaders.animalCategoryIdTo]: {
        serialized: GroupMovesSerializedHeaders.animalCategoryIdTo,
        unserialized: GroupMovesUnserializedHeaders.animalCategoryIdTo,
        view: IntlKeys.farmManagementDataAnimalCatIdTo,
      },
      [GroupMovesSerializedHeaders.withoutMale]: {
        serialized: GroupMovesSerializedHeaders.withoutMale,
        unserialized: GroupMovesUnserializedHeaders.withoutMale,
        view: IntlKeys.farmManagementDataWithoutMale,
      },
      [GroupMovesSerializedHeaders.withoutFemale]: {
        serialized: GroupMovesSerializedHeaders.withoutFemale,
        unserialized: GroupMovesUnserializedHeaders.withoutFemale,
        view: IntlKeys.farmManagementDataWithoutFemale,
      },
      [GroupMovesSerializedHeaders.withoutMaleCastrated]: {
        serialized: GroupMovesSerializedHeaders.withoutMaleCastrated,
        unserialized: GroupMovesUnserializedHeaders.withoutMaleCastrated,
        view: IntlKeys.farmManagementDataWithoutMaleCastrated,
      },
      [GroupMovesSerializedHeaders.adminDate]: {
        serialized: GroupMovesSerializedHeaders.adminDate,
        unserialized: GroupMovesUnserializedHeaders.adminDate,
        view: IntlKeys.farmManagementDataAdminDate,
      },
      [GroupMovesSerializedHeaders.remReason]: {
        serialized: GroupMovesSerializedHeaders.remReason,
        unserialized: GroupMovesUnserializedHeaders.remReason,
        view: IntlKeys.farmManagementDataRemReason,
      },
      [GroupMovesSerializedHeaders.ubnList]: {
        serialized: GroupMovesSerializedHeaders.ubnList,
        unserialized: GroupMovesUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupMovesSerializedHeaders.kvkList]: {
        serialized: GroupMovesSerializedHeaders.kvkList,
        unserialized: GroupMovesUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupMovesSerializedHeaders.registrationNameList]: {
        serialized: GroupMovesSerializedHeaders.registrationNameList,
        unserialized: GroupMovesUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

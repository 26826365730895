import { IntlKeys } from 'lib/localization/keys';
import { DocumentData, SheetSerializer } from "../../../../excel/serilizers/Sheet";
import { DocumentSerializer } from "../../../../excel/serilizers/Document";
import { CellSerializer } from "../../../../excel/serilizers/Cell";
import { ResourcesWrapper } from "../../../../http/utils";

enum DataOriginal {
  Data = 'data',
}

enum DataSerialized {
  Data = 'Data',
}

enum DataUnserialized {
  Data = 'Data',
}

export enum DataOriginalHeaders {
  kvk = 'kvk',
  kvkName = 'kvk_name',
  ubn = 'ubn',
  organizationId = 'organization_id',
  concepts = 'concepts',
  clientRelationNumber = 'client_relation_number',
  certificates = 'certificates',
  slaughters = 'slaughters',
  slaughterPriceCorrections = 'slaughter_price_corrections',
  genetics = 'genetics',
  deliveryMessages = 'delivery_messages',
  veterinarianData = 'veterinarian_data',
  movementEvents = 'movement_events',
  transports = 'transports',
  rawMaterialOrigins = 'raw_material_origins'
}

export enum DataSerializedHeaders {
  kvk = 'kvk',
  kvkName = 'kvkName',
  ubn = 'ubn',
  organizationId = 'organizationId',
  concepts = 'concepts',
  clientRelationNumber = 'clientRelationNumber',
  certificates = 'certificates',
  slaughters = 'slaughters',
  slaughterPriceCorrections = 'slaughterPriceCorrections',
  genetics = 'genetics',
  deliveryMessages = 'deliveryMessages',
  veterinarianData = 'veterinarianData',
  movementEvents = 'movementEvents',
  transports = 'transports',
  rawMaterialOrigins = 'rawMaterialOrigins'
}

export enum DataUnserializedHeaders {
  kvk = 'kvk',
  kvkName = 'kvk_name',
  ubn = 'ubn',
  organizationId = 'organization_id',
  concepts = 'concepts',
  clientRelationNumber = 'client_relation_number',
  certificates = 'certificates',
  slaughters = 'slaughters',
  slaughterPriceCorrections = 'slaughter_price_corrections',
  genetics = 'genetics',
  deliveryMessages = 'delivery_messages',
  veterinarianData = 'veterinarian_data',
  movementEvents = 'movement_events',
  transports = 'transports',
  rawMaterialOrigins = 'raw_material_origins'
}
export type DataStatisticsPayload = {
  ubn: string;
  kvk: string;
  kvk_name: string;
  organization_id: number;
  concepts: string;
  client_relation_number: string;
  certificates: number;
  slaughters: number;
  slaughter_price_corrections: number;
  genetics: number;
  delivery_messages: number;
  veterinarian_data: number;
  movement_events: number;
  transports: number;
  raw_material_origins: number;
};

export const DATA = {
  [DataSerialized.Data]: {
    original: DataOriginal.Data,
    serialized: DataSerialized.Data,
    unserialized: DataUnserialized.Data,
    view: IntlKeys.titleData,
    columns: {
      [DataSerializedHeaders.kvk]: {
        original: DataOriginalHeaders.kvk,
        serialized: DataSerializedHeaders.kvk,
        unserialized: DataUnserializedHeaders.kvk,
        view: IntlKeys.kvk,
      },
      [DataSerializedHeaders.kvkName]: {
        original: DataOriginalHeaders.kvkName,
        serialized: DataSerializedHeaders.kvkName,
        unserialized: DataUnserializedHeaders.kvkName,
        view: IntlKeys.customKvkName,
      },
      [DataSerializedHeaders.ubn]: {
        original: DataOriginalHeaders.ubn,
        serialized: DataSerializedHeaders.ubn,
        unserialized: DataUnserializedHeaders.ubn,
        view: IntlKeys.ubn,
      },
      [DataSerializedHeaders.organizationId]: {
        original: DataOriginalHeaders.organizationId,
        serialized: DataSerializedHeaders.organizationId,
        unserialized: DataUnserializedHeaders.organizationId,
        view: IntlKeys.organization,
      },
      [DataSerializedHeaders.concepts]: {
        original: DataOriginalHeaders.concepts,
        serialized: DataSerializedHeaders.concepts,
        unserialized: DataUnserializedHeaders.concepts,
        view: IntlKeys.concepts,
      },
      [DataSerializedHeaders.clientRelationNumber]: {
        original: DataOriginalHeaders.clientRelationNumber,
        serialized: DataSerializedHeaders.clientRelationNumber,
        unserialized: DataUnserializedHeaders.clientRelationNumber,
        view: IntlKeys.organizationsVionRelationNumber,
      },
      [DataSerializedHeaders.certificates]: {
        original: DataOriginalHeaders.certificates,
        serialized: DataSerializedHeaders.certificates,
        unserialized: DataUnserializedHeaders.certificates,
        view: IntlKeys.certificates,
      },
      [DataSerializedHeaders.slaughters]: {
        original: DataOriginalHeaders.slaughters,
        serialized: DataSerializedHeaders.slaughters,
        unserialized: DataUnserializedHeaders.slaughters,
        view: IntlKeys.slaughterSlaughters,
      },
      [DataSerializedHeaders.slaughterPriceCorrections]: {
        original: DataOriginalHeaders.slaughterPriceCorrections,
        serialized: DataSerializedHeaders.slaughterPriceCorrections,
        unserialized: DataUnserializedHeaders.slaughterPriceCorrections,
        view: IntlKeys.slaughterPriceCorrections,
      },
      [DataSerializedHeaders.genetics]: {
        original: DataOriginalHeaders.genetics,
        serialized: DataSerializedHeaders.genetics,
        unserialized: DataUnserializedHeaders.genetics,
        view: IntlKeys.titleGenetics,
      },
      [DataSerializedHeaders.deliveryMessages]: {
        original: DataOriginalHeaders.deliveryMessages,
        serialized: DataSerializedHeaders.deliveryMessages,
        unserialized: DataUnserializedHeaders.deliveryMessages,
        view: IntlKeys.titleDeliveryMessages,
      },
      [DataSerializedHeaders.veterinarianData]: {
        original: DataOriginalHeaders.veterinarianData,
        serialized: DataSerializedHeaders.veterinarianData,
        unserialized: DataUnserializedHeaders.veterinarianData,
        view: IntlKeys.titleVeterinarianData,
      },
      [DataSerializedHeaders.movementEvents]: {
        original: DataOriginalHeaders.movementEvents,
        serialized: DataSerializedHeaders.movementEvents,
        unserialized: DataUnserializedHeaders.movementEvents,
        view: IntlKeys.titleMovementEvents,
      },
      [DataSerializedHeaders.transports]: {
        original: DataOriginalHeaders.transports,
        serialized: DataSerializedHeaders.transports,
        unserialized: DataUnserializedHeaders.transports,
        view: IntlKeys.transports,
      },
      [DataSerializedHeaders.rawMaterialOrigins]: {
        original: DataOriginalHeaders.rawMaterialOrigins,
        serialized: DataSerializedHeaders.rawMaterialOrigins,
        unserialized: DataUnserializedHeaders.rawMaterialOrigins,
        view: IntlKeys.titleRawMaterialsOrigin,
      },
    },
  },
};

const { columns, serialized, unserialized } = DATA.Data;

const getDefaultSerializer = (data: DocumentData<DataStatisticsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.ubn.unserialized, columns.ubn.serialized),
      new CellSerializer(columns.kvk.unserialized, columns.kvk.serialized),
      new CellSerializer(columns.kvkName.unserialized, columns.kvkName.serialized),
      new CellSerializer(columns.organizationId.unserialized, columns.organizationId.serialized),
      new CellSerializer(columns.concepts.unserialized, columns.concepts.serialized),
      new CellSerializer(columns.clientRelationNumber.unserialized, columns.clientRelationNumber.serialized),
      new CellSerializer(columns.certificates.unserialized, columns.certificates.serialized),
      new CellSerializer(columns.slaughters.unserialized, columns.slaughters.serialized),
      new CellSerializer(columns.slaughterPriceCorrections.unserialized, columns.slaughterPriceCorrections.serialized),
      new CellSerializer(columns.genetics.unserialized, columns.genetics.serialized),
      new CellSerializer(columns.deliveryMessages.unserialized, columns.deliveryMessages.serialized),
      new CellSerializer(columns.veterinarianData.unserialized, columns.veterinarianData.serialized),
      new CellSerializer(columns.movementEvents.unserialized, columns.movementEvents.serialized),
      new CellSerializer(columns.transports.unserialized, columns.transports.serialized),
      new CellSerializer(columns.rawMaterialOrigins.unserialized, columns.rawMaterialOrigins.serialized),
    ]),
  ]);
};

export const serializeDataStatistics = (serverData: ResourcesWrapper<DataStatisticsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { ALLOCATION_MANURE_FIELDS } from 'lib/excel/config/referenceTables/allocationManure/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = ALLOCATION_MANURE_FIELDS.AllocationManure;

export const serializeAllocationManureData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.animalCategory.original, columns.animalCategory.serialized),
      new CellSerializer(columns.manure.original, columns.manure.serialized),
      new CellSerializer(columns.gas.original, columns.gas.serialized),
      new CellSerializer(columns.electricity.original, columns.electricity.serialized),
      new CellSerializer(columns.water.original, columns.water.serialized),
    ]),
  ]);
};

import { GWP_100_FACTORS_FIELDS } from 'lib/excel/config/referenceTables/gwp100Factors/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = GWP_100_FACTORS_FIELDS.Gwp100Factors;

export const getGwp100FactorsDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.greenhouseGas.serialized,
        extractPropName: columns.greenhouseGas.view,
      }),
      new DisplayHeader({
        headerName: columns.gwp100Factor.serialized,
        extractPropName: columns.gwp100Factor.view,
      }),
      new DisplayHeader({
        headerName: columns.gwp100FactorUnit.serialized,
        extractPropName: columns.gwp100FactorUnit.view,
      }),
    ]),
  ]);
};

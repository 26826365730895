import { DocumentSerializer } from '../../../lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from '../../../lib/excel/serilizers/Sheet';
import { CellSerializer, ObjectWithProps, PropertyPath } from '../../../lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from '../../../lib/excel/config/service';
import { UPLOAD_HISTORY_FIELDS } from '../../../lib/tables/config/uploadHistory/config';
import _ from 'lodash';
import { dateTimeString } from '../../../lib/excel/serilizers/utils';
import { ResourcesWrapper } from '../../../lib/http/utils';

export interface HistoryEntry {
  id: number;
  file: {
    url: string;
  };
  checksum: string;
  is_edited: false;
  upload_token: string;
  upload_type: string;
  uploaded_at: string;
  source: string;
  status: string;
  created_at: string;
  user: {
    email: string;
    name: string;
  };
}

const columns = UPLOAD_HISTORY_FIELDS.History.columns;

const { source, link, user, status, checksum, isEdited } = columns;

export const getFileUrl = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propOriginalPath: PropertyPath,
) => {
  const fileInfo = _.get(excelRow, propOriginalPath);

  return fileInfo['url'];
};
export const getUsername = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propOriginalPath: PropertyPath,
) => {
  const userInfo = _.get(excelRow, propOriginalPath);

  return userInfo['name'];
};

const getHistorySerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(UPLOAD_HISTORY_FIELDS.History.unserialized, UPLOAD_HISTORY_FIELDS.History.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(SERVICE_FIELDS.uploadedAt.unserialized, SERVICE_FIELDS.uploadedAt.serialized, dateTimeString),
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized, dateTimeString),
      new CellSerializer(status.unserialized, status.serialized),
      new CellSerializer(link.unserialized, link.serialized, getFileUrl),
      new CellSerializer(user.unserialized, user.serialized, getUsername),
      new CellSerializer(source.unserialized, source.serialized),
      new CellSerializer(checksum.unserialized, checksum.serialized),
      new CellSerializer(isEdited.unserialized, isEdited.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
    ]),
  ]);
};

export const serializeHistoryInfo = (serverData: ResourcesWrapper<HistoryEntry>) => {
  const data = {
    [UPLOAD_HISTORY_FIELDS.History.serialized]: serverData.data.resources,
  };
  const serializer = getHistorySerializer(data);

  return serializer.serialize();
};

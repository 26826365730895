import { YES_NO_FIELDS } from 'lib/excel/config/referenceTables/yesNo/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = YES_NO_FIELDS.YesNo;

export const serializeYesNoData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.yesNoCode.original, columns.yesNoCode.serialized),
      new CellSerializer(columns.yesNoName.original, columns.yesNoName.serialized),
    ]),
  ]);
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GREENHOUSE_GAS_EMISSION } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type GreenhouseGasEmissionPayload = {
  id: number;
  country: string | null;
  location: string | null;
  category: string | null;
  account: string | null;
  extra_external_id: string | null;
  name: string | null;
  emission_factor: number | null;
  unit: string | null;
  conversion_factor: number | null;
  formula_cf: string | null;
  co2_unit: string | null;
  greenhouse_gas_emission_upload: ManageUploadPayload;
};

export type GreenhouseGasEmissionSerialized = {
  id: number;
  referenceUploadID: number;
  country: string;
  location: string;
  category: string;
  account: string;
  extraExternalId: string;
  name: string;
  emissionFactor: number;
  unit: string;
  conversionFactor: number;
  formulaCf: string;
  co2_unit: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = GREENHOUSE_GAS_EMISSION.GreenhouseGasEmission;

const getDefaultSerializer = (data: DocumentData<GreenhouseGasEmissionPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.country.unserialized, columns.country.serialized),
      new CellSerializer(columns.location.unserialized, columns.location.serialized),
      new CellSerializer(columns.category.unserialized, columns.category.serialized),
      new CellSerializer(columns.account.unserialized, columns.account.serialized),
      new CellSerializer(columns.extraExternalId.unserialized, columns.extraExternalId.serialized),
      new CellSerializer(columns.name.unserialized, columns.name.serialized),
      new CellSerializer(columns.emissionFactor.unserialized, columns.emissionFactor.serialized),
      new CellSerializer(columns.unit.unserialized, columns.unit.serialized),
      new CellSerializer(columns.conversionFactor.unserialized, columns.conversionFactor.serialized),
      new CellSerializer(columns.formulaCf.unserialized, columns.formulaCf.serialized),
      new CellSerializer(columns.co2Unit.unserialized, columns.co2Unit.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeGreenhouseGasEmission = (serverData: ResourcesWrapper<GreenhouseGasEmissionPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Divider, ListItem, ListSubheader } from '@material-ui/core';

import { PERMISSIONS } from 'store/auth/permissions';

import NestedMenu, { NestedMenuItem } from 'components/NestedMenu';
import { SideBarContext } from 'components/SideBar';

import { useStyles } from './index';

interface OwnProps {
  //
  name?: string;
  items: NestedMenuItem[];
  checkItemVisibility: (
    restrictions?: Partial<Record<PERMISSIONS, boolean>>,
    hideable?: boolean,
    title?: string,
  ) => boolean;
}

const NavSection: React.FC<OwnProps> = ({ name, items, checkItemVisibility }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const { closeMenu } = useContext(SideBarContext);

  return (
    <ul className={classes.ul}>
      {name && <ListSubheader>{formatMessage({ id: name })}</ListSubheader>}
      <ListItem>
        <NestedMenu
          //
          items={items}
          checkItemVisibility={checkItemVisibility}
          onMenuItemSelect={closeMenu}
        />
      </ListItem>

      <Divider className={classes.divider} variant="inset" component="div" />
    </ul>
  );
};

export default React.memo(NavSection);

import { IntlKeys } from 'lib/localization/keys/__keys';
import React from 'react';
import { useIntl } from 'react-intl';
import { FarmDataTypeSerialized } from 'store/entities/animalPassports';
import DataContainer, { DataType } from '../components/DataContainer';
import GridContainer from '../components/GridContainer';
interface OwnProps {
  data: FarmDataTypeSerialized;
}

const Farm: React.FC<OwnProps> = ({ data: { deathsReportsStatistic, feedConsumptionReportStatistic } }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {deathsReportsStatistic && deathsReportsStatistic.map(({ causeOfDeath, animalsCount, animalsWeight }) => (
        <GridContainer key={causeOfDeath}>
          <DataContainer label={formatMessage({ id: IntlKeys.animalPassportFarmDeathCause })} data={causeOfDeath} />

          <div>
            <DataContainer
              label={formatMessage({ id: IntlKeys.animalPassportFarmAnimalCount })}
              data={animalsCount}
              dataType={DataType.Number}
            />
            <DataContainer
              label={formatMessage({ id: IntlKeys.animalPassportAverageWeightKg })}
              data={animalsWeight}
              dataType={DataType.Number}
            />
          </div>
        </GridContainer>
      ))}
      {feedConsumptionReportStatistic && Object.entries(feedConsumptionReportStatistic).map(([feedType, animalWeight]) => (
        <GridContainer key={feedType}>
          <DataContainer label={formatMessage({ id: IntlKeys.animalPassportFarmAverageFeedType })} data={feedType} />

          <div>
            <DataContainer
              label={formatMessage({ id: IntlKeys.animalPassportFarmAverageAnimalWeight })}
              data={animalWeight}
              dataType={DataType.Number}
            />
          </div>
        </GridContainer>
      ))}
    </div>
  );
};

export default React.memo(Farm);

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CFP_CO2_EMISSION_FACTOR } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type CfpCo2EmissionFactorPayload = {
  id: number;
  emission_source: string | null;
  co2_volume: number | null;
  reference: string | null;
  co2_emission_factor_upload: ManageUploadPayload;
};

export type CfpCo2EmissionFactorSerialized = {
  id: number;
  referenceUploadID: number;
  emissionSource: string;
  co2Volume: number;
  reference: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = CFP_CO2_EMISSION_FACTOR.CfpCo2EmissionFactors;

const getDefaultSerializer = (data: DocumentData<CfpCo2EmissionFactorPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.emissionSource.unserialized, columns.emissionSource.serialized),
      new CellSerializer(columns.co2Volume.unserialized, columns.co2Volume.serialized),
      new CellSerializer(columns.reference.unserialized, columns.reference.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeCfpCo2EmissionFactor = (serverData: ResourcesWrapper<CfpCo2EmissionFactorPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum RawMaterialsOriginSerialized {
  RawMaterialsOrigin = 'RawMaterialsOrigin',
}

enum RawMaterialsOriginUnserialized {
  RawMaterialsOrigin = 'RawMaterialsOrigin',
}

export enum RawMaterialsOriginSerializedHeaders {
  id = 'id',
  messageId = 'messageId',
  referenceUploadID = 'referenceUploadID',
  supplierNumber = 'supplierNumber',
  supplierName = 'supplierName',
  customerNumber = 'customerNumber',
  customerName = 'customerName',
  ubn = 'ubn',
  beginningTime = 'beginningTime',
  endTime = 'endTime',
  regionOfOriginCode = 'regionOfOriginCode',
  source = 'source',
  fileName = 'fileName',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  uploadSource = 'uploadSource',
  //
  feedIngredientReports = 'feedIngredientReports',
  animalCategoryCode = 'animalCategoryCode',
  subAnimalCategoryCode = 'subAnimalCategoryCode',
  articleNumber = 'articleNumber',
  articleTypeNumberCode = 'articleTypeNumberCode',
  articleDescription = 'articleDescription',
  netQuantityDeliveredProduct = 'netQuantityDeliveredProduct',
  quantityUnit = 'quantityUnit',
  nutrientDs = 'nutrientDs',
  nutrientRe = 'nutrientRe',
  breed = 'breed',
  nutrientVcre = 'nutrientVcre',
  nutrientVcos = 'nutrientVcos',
}

export const RawMaterialsOriginUnserializedHeaders = {
  id: 'id',
  messageId: 'message_id',
  referenceUploadID: ['raw_material_origin_upload', 'id'],
  supplierNumber: 'supplier_number',
  supplierName: 'supplier_name',
  customerNumber: 'customer_number',
  customerName: 'customer_name',
  ubn: 'ubn',
  beginningTime: 'beginning_time',
  endTime: 'end_time',
  regionOfOriginCode: 'region_of_origin_code',
  source: 'source',
  fileName: ['raw_material_origin_upload', 'file_name'],
  createdAt: 'created_at',
  updatedAt: ['raw_material_origin_upload', 'updated_at'],
  uploadSource: ['raw_material_origin_upload', 'upload_source'],
  //
  feedIngredientReports: 'feed_ingredient_reports',
  animalCategoryCode: 'animal_category_code',
  subAnimalCategoryCode: 'sub_animal_category_code',
  articleNumber: 'article_number',
  articleTypeNumberCode: 'article_type_number_code',
  articleDescription: 'article_description',
  netQuantityDeliveredProduct: 'net_quantity_delivered_product',
  quantityUnit: 'quantity_unit',
  nutrientDs: 'nutrient_ds',
  nutrientRe: 'nutrient_re',
  breed: 'breed',
  nutrientVcre: 'nutrient_vcre',
  nutrientVcos: 'nutrient_vcos',
} as const;

export const RAW_MATERIALS_ORIGIN = {
  [RawMaterialsOriginSerialized.RawMaterialsOrigin]: {
    serialized: RawMaterialsOriginSerialized.RawMaterialsOrigin,
    unserialized: RawMaterialsOriginUnserialized.RawMaterialsOrigin,
    view: IntlKeys.titleRawMaterialsOrigin,
    columns: {
      [RawMaterialsOriginSerializedHeaders.messageId]: {
        serialized: RawMaterialsOriginSerializedHeaders.messageId,
        unserialized: RawMaterialsOriginUnserializedHeaders.messageId,
        view: IntlKeys.rawMaterialsOriginMessageId,
      },
      [RawMaterialsOriginSerializedHeaders.source]: {
        serialized: RawMaterialsOriginSerializedHeaders.source,
        unserialized: RawMaterialsOriginUnserializedHeaders.source,
        view: IntlKeys.rawMaterialsOriginSource,
      },
      [RawMaterialsOriginSerializedHeaders.supplierNumber]: {
        serialized: RawMaterialsOriginSerializedHeaders.supplierNumber,
        unserialized: RawMaterialsOriginUnserializedHeaders.supplierNumber,
        view: IntlKeys.rawMaterialsOriginSupplierNumber,
      },
      [RawMaterialsOriginSerializedHeaders.supplierName]: {
        serialized: RawMaterialsOriginSerializedHeaders.supplierName,
        unserialized: RawMaterialsOriginUnserializedHeaders.supplierName,
        view: IntlKeys.rawMaterialsOriginSupplierName,
      },
      [RawMaterialsOriginSerializedHeaders.customerNumber]: {
        serialized: RawMaterialsOriginSerializedHeaders.customerNumber,
        unserialized: RawMaterialsOriginUnserializedHeaders.customerNumber,
        view: IntlKeys.rawMaterialsOriginCustomerNumber,
      },
      [RawMaterialsOriginSerializedHeaders.customerName]: {
        serialized: RawMaterialsOriginSerializedHeaders.customerName,
        unserialized: RawMaterialsOriginUnserializedHeaders.customerName,
        view: IntlKeys.rawMaterialsOriginCustomerName,
      },
      [RawMaterialsOriginSerializedHeaders.ubn]: {
        serialized: RawMaterialsOriginSerializedHeaders.ubn,
        unserialized: RawMaterialsOriginUnserializedHeaders.ubn,
        view: IntlKeys.rawMaterialsOriginUbn,
      },
      [RawMaterialsOriginSerializedHeaders.beginningTime]: {
        serialized: RawMaterialsOriginSerializedHeaders.beginningTime,
        unserialized: RawMaterialsOriginUnserializedHeaders.beginningTime,
        view: IntlKeys.rawMaterialsOriginBeginningTime,
      },
      [RawMaterialsOriginSerializedHeaders.endTime]: {
        serialized: RawMaterialsOriginSerializedHeaders.endTime,
        unserialized: RawMaterialsOriginUnserializedHeaders.endTime,
        view: IntlKeys.rawMaterialsOriginEndTime,
      },
      [RawMaterialsOriginSerializedHeaders.animalCategoryCode]: {
        serialized: RawMaterialsOriginSerializedHeaders.animalCategoryCode,
        unserialized: RawMaterialsOriginUnserializedHeaders.animalCategoryCode,
        view: IntlKeys.rawMaterialsOriginAnimalCategoryCode,
      },
      [RawMaterialsOriginSerializedHeaders.subAnimalCategoryCode]: {
        serialized: RawMaterialsOriginSerializedHeaders.subAnimalCategoryCode,
        unserialized: RawMaterialsOriginUnserializedHeaders.subAnimalCategoryCode,
        view: IntlKeys.rawMaterialsOriginSubAnimalCategoryCode,
      },
      [RawMaterialsOriginSerializedHeaders.articleNumber]: {
        serialized: RawMaterialsOriginSerializedHeaders.articleNumber,
        unserialized: RawMaterialsOriginUnserializedHeaders.articleNumber,
        view: IntlKeys.rawMaterialsOriginArticleNumber,
      },
      [RawMaterialsOriginSerializedHeaders.articleTypeNumberCode]: {
        serialized: RawMaterialsOriginSerializedHeaders.articleTypeNumberCode,
        unserialized: RawMaterialsOriginUnserializedHeaders.articleTypeNumberCode,
        view: IntlKeys.rawMaterialsOriginArticleTypeNumberCode,
      },
      [RawMaterialsOriginSerializedHeaders.articleDescription]: {
        serialized: RawMaterialsOriginSerializedHeaders.articleDescription,
        unserialized: RawMaterialsOriginUnserializedHeaders.articleDescription,
        view: IntlKeys.rawMaterialsOriginArticleDescription,
      },
      [RawMaterialsOriginSerializedHeaders.netQuantityDeliveredProduct]: {
        serialized: RawMaterialsOriginSerializedHeaders.netQuantityDeliveredProduct,
        unserialized: RawMaterialsOriginUnserializedHeaders.netQuantityDeliveredProduct,
        view: IntlKeys.rawMaterialsOriginNetQuantityDeliveredProduct,
      },
      [RawMaterialsOriginSerializedHeaders.quantityUnit]: {
        serialized: RawMaterialsOriginSerializedHeaders.quantityUnit,
        unserialized: RawMaterialsOriginUnserializedHeaders.quantityUnit,
        view: IntlKeys.rawMaterialsOriginQuantityUnit,
      },
      [RawMaterialsOriginSerializedHeaders.nutrientDs]: {
        serialized: RawMaterialsOriginSerializedHeaders.nutrientDs,
        unserialized: RawMaterialsOriginUnserializedHeaders.nutrientDs,
        view: IntlKeys.rawMaterialsOriginNutrientDs,
      },
      [RawMaterialsOriginSerializedHeaders.nutrientRe]: {
        serialized: RawMaterialsOriginSerializedHeaders.nutrientRe,
        unserialized: RawMaterialsOriginUnserializedHeaders.nutrientRe,
        view: IntlKeys.rawMaterialsOriginNutrientRe,
      },
      [RawMaterialsOriginSerializedHeaders.breed]: {
        serialized: RawMaterialsOriginSerializedHeaders.breed,
        unserialized: RawMaterialsOriginUnserializedHeaders.breed,
        view: IntlKeys.rawMaterialsOriginBreed,
      },
      [RawMaterialsOriginSerializedHeaders.nutrientVcre]: {
        serialized: RawMaterialsOriginSerializedHeaders.nutrientVcre,
        unserialized: RawMaterialsOriginUnserializedHeaders.nutrientVcre,
        view: IntlKeys.rawMaterialsOriginNutrientVcre,
      },
      [RawMaterialsOriginSerializedHeaders.nutrientVcos]: {
        serialized: RawMaterialsOriginSerializedHeaders.nutrientVcos,
        unserialized: RawMaterialsOriginUnserializedHeaders.nutrientVcos,
        view: IntlKeys.rawMaterialsOriginNutrientVcos,
      },
      [RawMaterialsOriginSerializedHeaders.regionOfOriginCode]: {
        serialized: RawMaterialsOriginSerializedHeaders.regionOfOriginCode,
        unserialized: RawMaterialsOriginUnserializedHeaders.regionOfOriginCode,
        view: IntlKeys.rawMaterialsOriginRegionOfOriginCode,
      },
      [RawMaterialsOriginSerializedHeaders.feedIngredientReports]: {
        serialized: RawMaterialsOriginSerializedHeaders.feedIngredientReports,
        unserialized: RawMaterialsOriginUnserializedHeaders.feedIngredientReports,
      },
      [RawMaterialsOriginSerializedHeaders.createdAt]: {
        serialized: RawMaterialsOriginSerializedHeaders.createdAt,
        unserialized: RawMaterialsOriginUnserializedHeaders.createdAt,
        view: IntlKeys.rawMaterialsOriginCreatedAt,
      },
    },
  },
};

import React from 'react';
import { useIntl } from 'react-intl';
import DataContainer, { DataType } from '../components/DataContainer';
import GridContainer from '../components/GridContainer';
import { IntlKeys } from 'lib/localization/keys/__keys';

interface OwnProps {
  data: Record<string, number>;
}

const Feed: React.FC<OwnProps> = ({ data }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {Object.entries(data).map(([itemDescription, value]) => (
        <GridContainer key={itemDescription}>
          <DataContainer label={formatMessage({ id: IntlKeys.animalPassportFeedGmpType })} data={itemDescription} />

          <DataContainer
            label={formatMessage({ id: IntlKeys.animalPassportFeedItemQuantity })}
            data={value}
            dataType={DataType.Number}
          />
        </GridContainer>
      ))}
    </div>
  );
};

export default React.memo(Feed);

/**
 * @deprecated use single IntlKeys
 */
export enum TitlesKeys {
  solutionBySIM = 'title.solutionBySIM',
  signIn = 'title.signIn',
  signUp = 'title.signUp',
  signOut = 'title.signOut',
  password = 'title.password',
  dashboard = 'title.dashboard',
  map = 'title.map',
  certificates = 'title.certificates',
  certificatesDaily = 'title.certificatesDaily',
  certificatesWeekly = 'title.certificatesWeekly',
  certificatesDeviations = 'title.certificatesDeviations',
  certificatesFarms = 'title.certificatesFarms',
  certificatesRecognitions = 'title.certificatesRecognitions',
  certificatesIKB = 'title.ikbCertificates',
  certificatesWelfare = 'title.welfareCertificates',
  certificatesKiwa = 'title.kiwaCertificates',
  invoices = 'title.invoices',
  invoiceDetails = 'title.invoiceDetails',
  slaughter = 'title.slaughter',
  genetics = 'title.genetics',
  deliveryMessages = 'title.deliveryMessages',
  deliverySegment = 'title.deliverySegment',
  deliverySegmentContents = 'title.deliverySegmentContents',
  organizationPassports = 'title.organizationPassports',
  movementEvents = 'title.movementEvents',
  veterinarianData = 'title.veterinarianData',
  uploadDocument = 'title.uploadDocument',
  feedProvenanceValidityTool = 'title.feedProvenanceValidityTool',
  dataCatalogue = 'title.dataCatalogue',
  vri = 'title.vri',
  vriEpg = 'title.vriEpg',
  vriHealthBalance = 'title.vriHealthBalance',
  vriBalanceBase = 'title.vriBalanceBase',
  vriReportingTool = 'title.vriReportingTool',
  vriDatabase = 'title.vriDatabase',
  vriDatabaseReports = 'title.vriDatabaseReports',
  myFarm = 'title.myFarm',
  myCompany = 'title.myCompany',
  farmStables = 'title.farmStables',
  farmReports = 'title.farmReports',
  farmUpdates = 'title.farmUpdates',
  history = 'title.history',
  admin = 'title.admin',
  allOrganizations = 'title.allOrganizations',
  allUsers = 'title.allUsers',
}

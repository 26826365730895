import { CL650_FIELDS } from 'lib/excel/config/referenceTables/cl650/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';

const { columns, serialized, view } = CL650_FIELDS.Cl650;

export const getCl650View = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.code.serialized, viewPropName: columns.code.view }),
      new CellViewer({ serializedPropName: columns.description.serialized, viewPropName: columns.description.view }),
      new CellViewer({ serializedPropName: columns.note.serialized, viewPropName: columns.note.view }),
    ]),
  ]);
};

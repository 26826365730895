import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'material-table';
import { Card, CardHeader, CardContent, List, Checkbox, Divider, Grid } from '@material-ui/core';

import TitledRow from 'components/TitledRow';

import { DataContext } from '../../index';
import { XSVariants } from './utils';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export interface OwnProps {
  data: object;
  xs: XSVariants;
}
const DataCard: React.FC<OwnProps> = ({ data, xs }) => {
  const { currentTab } = useContext(DataContext);
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const RowMenuComponent = currentTab.getRowMenu();

  const { head, rows } = useMemo(() => {
    const cardsConfigs = currentTab.getCardsSettings();
    const cardHeader = currentTab.getCardHeaderSettings();

    const generateRow = (rowConfig: Column<object>) => ({
      label: typeof rowConfig.title === 'string' ? formatMessage({ id: rowConfig.title }) : rowConfig.title,
      value: rowConfig.render //
        ? rowConfig.render(data, 'row')
        : data[(rowConfig.field as unknown) as keyof typeof data],
    });

    return {
      head: generateRow(cardHeader),
      rows: cardsConfigs.map((rowConfig) => generateRow(rowConfig)),
    };
  }, [currentTab, data, formatMessage]);

  return (
    <Grid
      //
      xs={xs}
      item
    >
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Checkbox
              // defaultChecked
              className={classes.checkbox}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          action={RowMenuComponent && <RowMenuComponent headers={currentTab.getDetailsConfig()} data={data} />}
          title={head.label}
          subheader={head.value}
        />
        <Divider />

        <CardContent className={classes.cardContent}>
          <List className={classes.root}>
            {rows.map(({ label, value }, ix, arr) => (
              <TitledRow key={ix} label={label} text={value} withDivider={ix < arr.length - 1} />
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // maxWidth: 345,
    },
    checkbox: {
      margin: -9,
    },
    cardContent: {
      padding: 0,

      '&:last-child': {
        paddingBottom: 0,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }),
);

export default React.memo(DataCard);

import { Route } from '../../Route';
import RouteParser from 'route-parser';

export const singIn = new Route('post', new RouteParser('/auth/sign_in'), () => [
  200,
  {
    resource: {
      id: 3,
      email: 'vbbc-vionadmin@vbbc-example.com',
      provider: 'email',
      uid: 'vbbc-vionadmin@vbbc-example.com',
      allow_password_change: false,
      name: 'VION Admin',
      nickname: null,
      user_role: 'content_admin',
      image: null,
      created_at: '2020-05-21T12:27:16.354Z',
      updated_at: '2020-05-22T08:07:02.235Z',
      company_id: 1,
    },
  },

  {
    'access-token': 'eZUK4lcquo5xmY-ylkOWfQ',
    client: 'tq-ZLJW3Pa0MF5stsfxqQw',
    expiry: '1591345988',
    'token-type': 'Bearer',
  },
]);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Components } from 'material-table';

import { DataViews } from 'store/entities/UI';
import { TableTab } from 'lib/tables/TableTab';
import { TableConfig } from 'lib/tables/TableConfig';
import { DocumentData } from 'lib/excel/serilizers/Sheet';

import ViewManager from './ViewManager';

export interface DataTableProps {
  tableConfig: TableConfig;
  data: DocumentData;
  totalCount?: number;
  onStateChange?: (state: { page: number; pageSize: number }) => void;
  isCustomPagination?: boolean;
  isLoading: boolean;
  className?: string;
  title?: string;
  view?: DataViews;
  containerProps?: object;
  components?: Components;
  showFilterChips?: boolean;
  onSelectionChange?: (data, rowData?) => void;
}

export type DataContextType = {
  tableConfig: TableConfig;
  currentTab: TableTab;
  setCurrentTabKey: (tabKey: string) => void;
  page: number;
  totalCount?: number;
  setPage: (newPage: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  pageSizeVariant: Array<number>;
  pageSize: number;
};
export const DataContext = React.createContext<DataContextType>(({} as unknown) as DataContextType);

const DataTable: React.FC<DataTableProps> = (props) => {
  const { tableConfig, onStateChange, totalCount, data } = props;

  const [currentTabKey, setCurrentTabKey] = useState<string>(tableConfig.tabs[0]?.dataKey);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);

  const setItemsPerPage = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const tabData: object[] = data[currentTabKey] || [];

  const contextValue = useMemo(() => {
    const pageSizeVariant = [5, 10, 20];

    return {
      tableConfig,
      currentTab: tableConfig.tabs.find(({ dataKey }) => dataKey === currentTabKey)!,
      pageSize,
      pageSizeVariant,
      setItemsPerPage,
      setCurrentTabKey,
      page,
      totalCount,
      setPage,
    };
  }, [tableConfig, currentTabKey, page, pageSize, totalCount, setCurrentTabKey, setItemsPerPage]);

  useEffect(() => {
    if (onStateChange) {
      onStateChange({ page, pageSize });
    }
  }, [page, pageSize, onStateChange]);

  useEffect(() => {
    //
    setCurrentTabKey(tableConfig.tabs[0]?.dataKey);
  }, [tableConfig]);

  return (
    <DataContext.Provider value={contextValue}>
      <ViewManager {...props} data={tabData} key={contextValue.currentTab.headers.length} />
    </DataContext.Provider>
  );
};

export default React.memo(DataTable);

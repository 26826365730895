import { createSelector, createReducer } from '@reduxjs/toolkit';

import { asyncAction, syncAction } from 'lib/actions';
import { Languages } from 'lib/localization';
import { ChangeHeadersOrder, TableColumnsGenerator, TableSettings } from 'lib/tables/TableColumnsGenerator';

import { RootState } from 'store/store';
import { DataViews } from 'store/entities/UI';
import { ToggleTableHeaderDTO } from 'store/dto/ToggleTableHeaderDTO';
import { getUserAction, signInAction } from 'store/auth/reducer';

const initialState = {
  tableSettings: TableColumnsGenerator.emptySettings(),
  unsavedTableSettings: TableColumnsGenerator.emptySettings(),
  //
  dataView: 'table' as DataViews,
  locale: 'nl' as keyof typeof Languages,
  paginationActive: false,
};

export const toggleTableHeader = syncAction<ToggleTableHeaderDTO>('userSettings/toggleTableHeader');
export const changeHeadersOrder = syncAction<ChangeHeadersOrder>('userSettings/changeHeadersOrder');
export const saveTableConfig = asyncAction('userSettings/saveTableConfig');
export const getTableConfig = asyncAction('userSettings/getTableConfig');
export const patchTableConfig = syncAction<TableSettings>('userSettings/patchTableConfig');
export const resetToDefault = asyncAction<string>('userSettings/resetToDefault');
export const changeDataViewAction = syncAction<DataViews>('userSettings/changeDataView');
export const setLocale = asyncAction<keyof typeof Languages>('userSettings/changeLocale');
export const setPaginationActiveAction = asyncAction<boolean>('userSettings/setPaginationActive');
export const getUserByTokenAction = asyncAction('auth/GET_USER_BY_TOKEN');

export const userSettingsReducer = createReducer(initialState, {
  [toggleTableHeader.type]: (draft, { payload }) => {
    const headerToggleDTO: ToggleTableHeaderDTO = payload as ToggleTableHeaderDTO;

    draft.unsavedTableSettings = TableColumnsGenerator.toggleHeader(draft.unsavedTableSettings, headerToggleDTO);
  },

  [changeHeadersOrder.type]: (draft, { payload }) => {
    const changeHeaderOrderPayload = payload as ChangeHeadersOrder;

    const newConfig = TableColumnsGenerator.changeHeadersOrder(draft.tableSettings, changeHeaderOrderPayload);

    draft.tableSettings = newConfig;
    draft.unsavedTableSettings = newConfig;
  },

  [getUserByTokenAction.success]: (draft, { payload }) => {
    if (payload.pref_lang) {
      draft.locale = payload.pref_lang;
    }
  },

  [saveTableConfig.request]: (draft) => {
    // Optimistic update
    draft.tableSettings = draft.unsavedTableSettings;
  },

  [getTableConfig.success]: (draft, { payload }) => {
    if (!payload?.status) {
      const tableSettings = payload as TableSettings;

      draft.tableSettings = tableSettings;
      draft.unsavedTableSettings = tableSettings;
    }
  },

  [patchTableConfig.type]: (draft, { payload }) => {
    const missingTableSettings = payload as TableSettings;

    draft.tableSettings = { ...draft.tableSettings, ...missingTableSettings };
    draft.unsavedTableSettings = { ...draft.unsavedTableSettings, ...missingTableSettings };
  },

  [changeDataViewAction.type]: (draft, { payload }) => {
    draft.dataView = payload;
  },

  [getUserAction.success]: (draft, { payload }) => {
    draft.locale = payload?.user?.prefLang || initialState.locale;
    draft.paginationActive = payload?.user?.paginationActive || initialState.paginationActive;
  },

  [signInAction.success]: (draft, { payload }) => {
    draft.locale = payload?.user?.prefLang || initialState.locale;
    draft.paginationActive = payload?.user?.paginationActive || initialState.paginationActive;
  },

  [setLocale.request]: (draft, { payload }) => {
    draft.locale = payload;
  },

  [setPaginationActiveAction.request]: (draft, { payload }) => {
    draft.paginationActive = payload;
  },
});

const us = (state: RootState) => state.userSettings;
//
export const tableSettingsSelector = createSelector(us, (us) => us.tableSettings);
export const unsavedTableSettingsSelector = createSelector(us, (us) => us.unsavedTableSettings);
export const userSettingsDataViewSelector = createSelector(us, (us) => us.dataView);
export const userLocaleSelector = createSelector(us, (us) => us.locale);
export const paginationActiveSelector = createSelector(us, (us) => us.paginationActive);

export default userSettingsReducer;

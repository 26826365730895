/**
 * @deprecated use single IntlKeys
 */
export enum VriKeys {
  selectYourLocation = 'vri.selectYourLocation',
  vriRanking = 'vri.vriRanking',
  goToVriReportingTool = 'vri.goToVriReportingTool',
  vriTotalRanking = 'vri.vriTotalRanking',
  serialNumber = 'vri.serialNumber',
  yourScoreLabel = 'vri.yourScoreLabel',
  centsPerKilogram = 'vri.centsPerKilogram',
  perPig = 'vri.perPig',
  vriEpgTitle = 'vri.vriEpgTitle',
  vriBalanceBaseTitle = 'vri.vriBalanceBaseTitle',
  vriHealthBalanceTitle = 'vri.vriHealthBalanceTitle',
  chartUbn = 'vri.chartUbn',
  chartName = 'vri.chartName',
  chartTotal = 'vri.chartTotal',
  chartEpg = 'vri.chartEpg',
  chartClassification = 'vri.chartClassification',
  chartHealth = 'vri.chartHealth',
  top20Label = 'vri.top20Label',
  avgLabel = 'vri.avgLabel',
  averageLabel = 'vri.averageLabel',
  pigsCapacity = 'vri.pigsCapacity',
  differenceLabel = 'vri.differenceLabel',
  totalCompany = 'vri.totalCompany',
  database = 'vri.database',
  databaseDetails = 'vri.databaseDetails',
  uploadedFile = 'vri.uploadedFile',
  balanceBaseAverageWeightCorrection = 'vri.balanceBase.averageWeightCorrection',
  balanceBaseAverageFatCorrection = 'vri.balanceBase.balanceBaseAverageFatCorrection',
  balanceBaseAverageMuscleCorrection = 'vri.balanceBase.balanceBaseAverageMuscleCorrection',
  balanceBaseAverageMeatCorrection = 'vri.balanceBase.balanceBaseAverageMeatCorrection',
  //

  id = 'vri.value.id',
  rating = 'vri.value.rating',
  vriSerialNumber = 'vri.value.vriSerialNumber',
  ubn = 'vri.value.ubn',
  name = 'vri.value.name',
  total = 'vri.value.total',
  vriEpg = 'vri.value.vriEpg',
  epgSerialNumber = 'vri.value.epgSerialNumber',
  growth = 'vri.value.growth',
  feedIntakePerDay = 'vri.value.feedIntakePerDay',
  averageBirthWeight = 'vri.value.averageBirthWeight',
  ewConversion = 'vri.value.ewConversion',
  layingBigWeight = 'vri.value.layingBigWeight',
  loss = 'vri.value.loss',
  epg = 'vri.value.epg',
  vriClassification = 'vri.value.vriClassification',
  epgOrderNumber = 'vri.value.epgOrderNumber',
  balanceWithRespectToBase = 'vri.value.balanceWithRespectToBase',
  averageWeight = 'vri.value.averageWeight',
  averageWeightCorrection = 'vri.value.averageWeightCorrection',
  averageFatThickness = 'vri.value.averageFatThickness',
  averageBeaconCorrection = 'vri.value.averageBeaconCorrection',
  averageMuscleThickness = 'vri.value.averageMuscleThickness',
  averageMuscleCorrection = 'vri.value.averageMuscleCorrection',
  averageMeat = 'vri.value.averageMeat',
  averageMeatCorrection = 'vri.value.averageMeatCorrection',
  averageClassificationCorrection = 'vri.value.averageClassificationCorrection',
  averageSexAllowance = 'vri.value.averageSexAllowance',
  averageConceptSurcharge = 'vri.value.averageConceptSurcharge',
  averageEpgWeight = 'vri.value.averageEpgWeight',
  pigsInDraft = 'vri.value.pigsInDraft',
  vriHealth = 'vri.value.vriHealth',
  epgNumber = 'vri.value.epgNumber',
  euroPerPig = 'vri.value.euroPerPig',
  approvedCarcass = 'vri.value.approvedCarcass',
  pleurisy = 'vri.value.pleurisy',
  inflamedSkin = 'vri.value.inflamedSkin',
  inflamedLeg = 'vri.value.inflamedLeg',
  approvedOrgan = 'vri.value.approvedOrgan',
  leverAfg = 'vri.value.leverAfg',
  liverAfg = 'vri.value.liverAfg',
  longAfg = 'vri.value.longAfg',
  lungAfg = 'vri.value.lungAfg',
  healthy = 'vri.value.healthy',
  createdAt = 'vri.value.createdAt',
  updatedAt = 'vri.value.updatedAt',
  fileUpload = 'vri.value.fileUpload',
  reportUpload = 'vri.value.reportUpload',
  // fileUpload
  file = 'vri.value.file',
  url = 'vri.value.url',
  checksum = 'vri.value.checksum',
  isEdited = 'vri.value.isEdited',
  uploadToken = 'vri.value.uploadToken',
  uploadType = 'vri.value.uploadType',
  source = 'vri.value.source',
  status = 'vri.value.status',
  statusMessage = 'vri.value.statusMessage',
  user = 'vri.value.user',
  email = 'vri.value.email',
  // reportUpload
  reportDateFrom = 'vri.value.reportDateFrom',
  reportDateTo = 'vri.value.reportDateTo',
  // resource
  totalMin = 'vri.value.totalMin',
  totalMax = 'vri.value.totalMax',
  epgMin = 'vri.value.epgMin',
  epgMax = 'vri.value.epgMax',
  euroPerPigMin = 'vri.value.euroPerPigMin',
  euroPerPigMax = 'vri.value.euroPerPigMax',
  balanceWithRespectToBaseMin = 'vri.value.balanceWithRespectToBaseMin',
  balanceWithRespectToBaseMax = 'vri.value.balanceWithRespectToBaseMax',
  constants = 'vri.value.constants',
  // statistics
  vriTotal = 'vri.value.vriTotal',
  lapSpeed = 'vri.value.lapSpeed',
  deliveredAnimals = 'vri.value.deliveredAnimals',
  delivered = 'vri.value.delivered',
  vriBalanceWithRespectToBase = 'vri.value.vriBalanceWithRespectToBase',
  businessTotal = 'vri.value.businessTotal',
  yourScore = 'vri.value.yourScore',
  averageTop20Score = 'vri.value.averageTop20Score',
  difference = 'vri.value.difference',
  epgEuroPerPig = 'vri.value.epgEuroPerPig',
  // constants
  genderWeight = 'vri.value.genderWeight',
  baconWeight = 'vri.value.baconWeight',
  meatPercent = 'vri.value.meatPercent',
  vriBalance = 'vri.value.vriBalance',
  top20 = 'vri.value.top20',
  avg = 'vri.value.avg',
  endManufacturer = 'vri.value.endManufacturer',
  feedingSystem = 'vri.value.feedingSystem',
}

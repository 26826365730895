import { IntlKeys } from 'lib/localization/keys';

enum GroupWeightsSerialized {
  GroupWeights = 'GroupWeights',
}

enum GroupWeightsUnserialized {
  GroupWeights = 'GroupWeights',
}

export enum GroupWeightsSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  finEventIdentifier = 'finEventIdentifier',
  number = 'number',
  totalWeight = 'totalWeight',
  notes = 'notes',
  groupWeightDate = 'groupWeightDate',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupWeightsUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  finEventIdentifier: 'fin_event_identifier',
  number: 'number',
  totalWeight: 'total_weight',
  notes: 'notes',
  groupWeightDate: 'group_weight_date',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_WEIGHTS = {
  [GroupWeightsSerialized.GroupWeights]: {
    serialized: GroupWeightsSerialized.GroupWeights,
    unserialized: GroupWeightsUnserialized.GroupWeights,
    view: IntlKeys.farmManagementDataWeightsList,

    columns: {
      [GroupWeightsSerializedHeaders.id]: {
        serialized: GroupWeightsSerializedHeaders.id,
        unserialized: GroupWeightsUnserializedHeaders.id,
      },
      [GroupWeightsSerializedHeaders.companyIdentifier]: {
        serialized: GroupWeightsSerializedHeaders.companyIdentifier,
        unserialized: GroupWeightsUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupWeightsSerializedHeaders.farmIdentifier]: {
        serialized: GroupWeightsSerializedHeaders.farmIdentifier,
        unserialized: GroupWeightsUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupWeightsSerializedHeaders.finEventIdentifier]: {
        serialized: GroupWeightsSerializedHeaders.finEventIdentifier,
        unserialized: GroupWeightsUnserializedHeaders.finEventIdentifier,
        view: IntlKeys.farmManagementDataFinEventId,
      },
      [GroupWeightsSerializedHeaders.number]: {
        serialized: GroupWeightsSerializedHeaders.number,
        unserialized: GroupWeightsUnserializedHeaders.number,
        view: IntlKeys.farmManagementDataNumber,
      },
      [GroupWeightsSerializedHeaders.totalWeight]: {
        serialized: GroupWeightsSerializedHeaders.totalWeight,
        unserialized: GroupWeightsUnserializedHeaders.totalWeight,
        view: IntlKeys.farmManagementDataTotalWeight,
      },
      [GroupWeightsSerializedHeaders.notes]: {
        serialized: GroupWeightsSerializedHeaders.notes,
        unserialized: GroupWeightsUnserializedHeaders.notes,
        view: IntlKeys.farmManagementDataNotes,
      },
      [GroupWeightsSerializedHeaders.groupWeightDate]: {
        serialized: GroupWeightsSerializedHeaders.groupWeightDate,
        unserialized: GroupWeightsUnserializedHeaders.groupWeightDate,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupWeightsSerializedHeaders.ubnList]: {
        serialized: GroupWeightsSerializedHeaders.ubnList,
        unserialized: GroupWeightsUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupWeightsSerializedHeaders.kvkList]: {
        serialized: GroupWeightsSerializedHeaders.kvkList,
        unserialized: GroupWeightsUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupWeightsSerializedHeaders.registrationNameList]: {
        serialized: GroupWeightsSerializedHeaders.registrationNameList,
        unserialized: GroupWeightsUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys';

enum CodesPenSerialized {
  CodesPen = 'CodesPen',
}

enum CodesPenUnserialized {
  CodesPen = 'CodesPen',
}

export enum CodesPenSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  barnIdentifier = 'barnIdentifier',
  roomIdentifier = 'roomIdentifier',
  penIdentifier = 'penIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  firstValue = 'firstValue',
  secondValue = 'secondValue',
  codePenDatetime = 'codePenDatetime',
  transponder = 'transponder',
  numberPlaces = 'numberPlaces',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesPenUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  barnIdentifier: 'barn_identifier',
  roomIdentifier: 'room_identifier',
  penIdentifier: 'pen_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  firstValue: 'first_value',
  secondValue: 'second_value',
  codePenDatetime: 'code_pen_datetime',
  transponder: 'transponder',
  numberPlaces: 'number_places',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_PEN = {
  [CodesPenSerialized.CodesPen]: {
    serialized: CodesPenSerialized.CodesPen,
    unserialized: CodesPenUnserialized.CodesPen,
    view: IntlKeys.farmManagementDataPenList,

    columns: {
      [CodesPenSerializedHeaders.id]: {
        serialized: CodesPenSerializedHeaders.id,
        unserialized: CodesPenUnserializedHeaders.id,
      },
      [CodesPenSerializedHeaders.companyIdentifier]: {
        serialized: CodesPenSerializedHeaders.companyIdentifier,
        unserialized: CodesPenUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesPenSerializedHeaders.farmIdentifier]: {
        serialized: CodesPenSerializedHeaders.farmIdentifier,
        unserialized: CodesPenUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [CodesPenSerializedHeaders.barnIdentifier]: {
        serialized: CodesPenSerializedHeaders.barnIdentifier,
        unserialized: CodesPenUnserializedHeaders.barnIdentifier,
        view: IntlKeys.farmManagementDataBarnId,
      },
      [CodesPenSerializedHeaders.roomIdentifier]: {
        serialized: CodesPenSerializedHeaders.roomIdentifier,
        unserialized: CodesPenUnserializedHeaders.roomIdentifier,
        view: IntlKeys.farmManagementDataRoomId,
      },
      [CodesPenSerializedHeaders.penIdentifier]: {
        serialized: CodesPenSerializedHeaders.penIdentifier,
        unserialized: CodesPenUnserializedHeaders.penIdentifier,
        view: IntlKeys.farmManagementDataPenId,
      },
      [CodesPenSerializedHeaders.abbreviation]: {
        serialized: CodesPenSerializedHeaders.abbreviation,
        unserialized: CodesPenUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesPenSerializedHeaders.description]: {
        serialized: CodesPenSerializedHeaders.description,
        unserialized: CodesPenUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesPenSerializedHeaders.firstValue]: {
        serialized: CodesPenSerializedHeaders.firstValue,
        unserialized: CodesPenUnserializedHeaders.firstValue,
        view: IntlKeys.farmManagementDataFirstValue,
      },
      [CodesPenSerializedHeaders.secondValue]: {
        serialized: CodesPenSerializedHeaders.secondValue,
        unserialized: CodesPenUnserializedHeaders.secondValue,
        view: IntlKeys.farmManagementDataSecondValue,
      },
      [CodesPenSerializedHeaders.codePenDatetime]: {
        serialized: CodesPenSerializedHeaders.codePenDatetime,
        unserialized: CodesPenUnserializedHeaders.codePenDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesPenSerializedHeaders.transponder]: {
        serialized: CodesPenSerializedHeaders.transponder,
        unserialized: CodesPenUnserializedHeaders.transponder,
        view: IntlKeys.farmManagementDataTransponder,
      },
      [CodesPenSerializedHeaders.numberPlaces]: {
        serialized: CodesPenSerializedHeaders.numberPlaces,
        unserialized: CodesPenUnserializedHeaders.numberPlaces,
        view: IntlKeys.farmManagementDataNumberPlaces,
      },
      [CodesPenSerializedHeaders.ubnList]: {
        serialized: CodesPenSerializedHeaders.ubnList,
        unserialized: CodesPenUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesPenSerializedHeaders.kvkList]: {
        serialized: CodesPenSerializedHeaders.kvkList,
        unserialized: CodesPenUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesPenSerializedHeaders.registrationNameList]: {
        serialized: CodesPenSerializedHeaders.registrationNameList,
        unserialized: CodesPenUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface OwnProps {
  to: string;
}

const DetailsButton: React.FC<OwnProps> = ({ to }) => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link}>
      <Button variant="text" color="primary">
        Details
      </Button>
    </Link>
  );
};

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

export default DetailsButton;

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { PENS_TYPE_SOW_BARN } from 'store/entities/referenceTables/pensTypeSowBarn/config';

const { columns, view, serialized } = PENS_TYPE_SOW_BARN.PensTypeSowBarn;

export const pensTypeSowBarnTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.penTypeSowBarnCode.view,
          field: columns.penTypeSowBarnCode.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.penTypeSowBarnCode.serialized] && roundToInteger(data[columns.penTypeSowBarnCode.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.penTypeSowBarnName.view,
          field: columns.penTypeSowBarnName.serialized,
        },
      }),
    ],
  }),
]);

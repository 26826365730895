import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_BARN } from './config';

export type CodesBarnPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  barn_identifier: string;
  abbreviation: string;
  description: string;
  code_barn_datetime: string;
  correction_fin: string;
  is_early_wean_unit: string;
  vvo_number: string;
  eu_approval_number: string;
  correction_weaners: string;
  correction_repl_gilts: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type CodesBarnSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  barnIdentifier: string;
  abbreviation: string;
  description: string;
  codeBarnDatetime: string;
  correctionFin: string;
  isEarlyWeanUnit: string;
  surface: string;
  vvoNumber: string;
  euApprovalNumber: string;
  correctionWeaners: string;
  correctionReplGilts: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = CODES_BARN.CodesBarn;

const getDefaultSerializer = (data: DocumentData<CodesBarnPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.barnIdentifier.unserialized, columns.barnIdentifier.serialized),
      new CellSerializer(columns.abbreviation.unserialized, columns.abbreviation.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.codeBarnDatetime.unserialized, columns.codeBarnDatetime.serialized),
      new CellSerializer(columns.correctionFin.unserialized, columns.correctionFin.serialized),
      new CellSerializer(columns.isEarlyWeanUnit.unserialized, columns.isEarlyWeanUnit.serialized),
      new CellSerializer(columns.surface.unserialized, columns.surface.serialized),
      new CellSerializer(columns.vvoNumber.unserialized, columns.vvoNumber.serialized),
      new CellSerializer(columns.euApprovalNumber.unserialized, columns.euApprovalNumber.serialized),
      new CellSerializer(columns.correctionWeaners.unserialized, columns.correctionWeaners.serialized),
      new CellSerializer(columns.correctionReplGilts.unserialized, columns.correctionReplGilts.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeCodesBarn = (serverData: ResourcesWrapper<CodesBarnPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

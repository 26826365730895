import { CL650_FIELDS } from 'lib/excel/config/referenceTables/cl650/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = CL650_FIELDS.Cl650;

export const getCl650DocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.code.original),
      new CellValidator(columns.description.original),
      new CellValidator(columns.note.original),
    ]),
  ]);
};

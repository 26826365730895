import { ELECTRICITY_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/electricityConsumptionRates/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = ELECTRICITY_CONSUMPTION_RATES_FIELDS.ElectricityConsumptionRates;

export const getElectricityConsumptionRatesDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.householdPeopleQuantity.original),
      new CellValidator(columns.electricityConsumption.original),
    ]),
  ]);
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { propToString, valueToBoolean } from 'lib/excel/serilizers/utils';

import { DAILY_DOCUMENT_FIELDS } from '../structure';

const originalSheetColumns = DAILY_DOCUMENT_FIELDS.Register.columns;

export const unserializeDailyData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(DAILY_DOCUMENT_FIELDS.Register.serialized, DAILY_DOCUMENT_FIELDS.Register.unserialized, [
      new CellSerializer(
        originalSheetColumns.regNumber.serialized,
        originalSheetColumns.regNumber.unserialized,
        propToString,
      ),
      new CellSerializer(
        originalSheetColumns.countryCodeLoc.serialized,
        originalSheetColumns.countryCodeLoc.unserialized,
      ),
      new CellSerializer(originalSheetColumns.businessType.serialized, originalSheetColumns.businessType.unserialized),
      new CellSerializer(originalSheetColumns.startDate.serialized, originalSheetColumns.startDate.unserialized),
      new CellSerializer(
        originalSheetColumns.ownTransport.serialized,
        originalSheetColumns.ownTransport.unserialized,
        valueToBoolean,
      ),
      new CellSerializer(originalSheetColumns.validUntil.serialized, originalSheetColumns.validUntil.unserialized),
      new CellSerializer(originalSheetColumns.remark.serialized, originalSheetColumns.remark.unserialized),
      new CellSerializer(originalSheetColumns.vc.serialized, originalSheetColumns.vc.unserialized),
      new CellSerializer(originalSheetColumns.qsNumber.serialized, originalSheetColumns.qsNumber.unserialized),
      new CellSerializer(originalSheetColumns.ggNr.serialized, originalSheetColumns.ggNr.unserialized),
      new CellSerializer(
        originalSheetColumns.pigTomorrow.serialized,
        originalSheetColumns.pigTomorrow.unserialized,
        valueToBoolean,
      ),
      new CellSerializer(originalSheetColumns.blk.serialized, originalSheetColumns.blk.unserialized, valueToBoolean),
      new CellSerializer(
        originalSheetColumns.certificateComment.serialized,
        originalSheetColumns.certificateComment.unserialized,
      ),
      new CellSerializer(
        originalSheetColumns.salmonellaInfo.serialized,
        originalSheetColumns.salmonellaInfo.unserialized,
      ),
    ]),
  ]);
};

import { ENTITIES_LIST_FIELDS } from 'lib/excel/config/referenceTables/entitiesList/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = ENTITIES_LIST_FIELDS.EntitiesList;

export const getEntitiesListDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.entityId.original),
      new CellValidator(columns.entityName.original),
      new CellValidator(columns.country.original),
      new CellValidator(columns.businessUnit.original),
    ]),
  ]);
};

import { IntlKeys } from 'lib/localization/keys';

enum Cl607Serialized {
  Cl607 = 'Cl607',
}

enum Cl607Unserialized {
  Cl607 = 'Cl607',
}

export enum Cl607SerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  code = 'code',
  description = 'description',
  remarks = 'remarks',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const Cl607UnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['animal_id_code_type_upload', 'id'],
  code: 'code',
  description: 'description',
  remarks: 'notes',
  uploadType: ['animal_id_code_type_upload', 'upload_type'],
  filename: ['animal_id_code_type_upload', 'file_name'],
  status: ['animal_id_code_type_upload', 'status'],
  validDateFrom: ['animal_id_code_type_upload', 'valid_from'],
  validDateTo: ['animal_id_code_type_upload', 'valid_to'],
  notes: ['animal_id_code_type_upload', 'notes'],
  uploadedAt: ['animal_id_code_type_upload', 'uploaded_at'],
  uploadedBy: ['animal_id_code_type_upload', 'uploaded_by', 'full_name'],
} as const;

export const CL607 = {
  [Cl607Serialized.Cl607]: {
    serialized: Cl607Serialized.Cl607,
    unserialized: Cl607Unserialized.Cl607,
    view: IntlKeys.referenceTablesCl607,

    columns: {
      [Cl607SerializedHeaders.id]: {
        serialized: Cl607SerializedHeaders.id,
        unserialized: Cl607UnserializedHeaders.id,
      },
      [Cl607SerializedHeaders.referenceUploadID]: {
        serialized: Cl607SerializedHeaders.referenceUploadID,
        unserialized: Cl607UnserializedHeaders.referenceUploadID,
      },
      [Cl607SerializedHeaders.code]: {
        serialized: Cl607SerializedHeaders.code,
        unserialized: Cl607UnserializedHeaders.code,
        view: IntlKeys.referenceTablesCl607Code,
      },
      [Cl607SerializedHeaders.description]: {
        serialized: Cl607SerializedHeaders.description,
        unserialized: Cl607UnserializedHeaders.description,
        view: IntlKeys.referenceTablesCl607Description,
      },
      [Cl607SerializedHeaders.remarks]: {
        serialized: Cl607SerializedHeaders.remarks,
        unserialized: Cl607UnserializedHeaders.remarks,
        view: IntlKeys.referenceTablesCl607Remarks,
      },
      [Cl607SerializedHeaders.status]: {
        serialized: Cl607SerializedHeaders.status,
        unserialized: Cl607UnserializedHeaders.status,
      },
      [Cl607SerializedHeaders.uploadType]: {
        serialized: Cl607SerializedHeaders.uploadType,
        unserialized: Cl607UnserializedHeaders.uploadType,
      },
      [Cl607SerializedHeaders.notes]: {
        serialized: Cl607SerializedHeaders.notes,
        unserialized: Cl607UnserializedHeaders.notes,
      },
      [Cl607SerializedHeaders.filename]: {
        serialized: Cl607SerializedHeaders.filename,
        unserialized: Cl607UnserializedHeaders.filename,
      },
      [Cl607SerializedHeaders.validDateFrom]: {
        serialized: Cl607SerializedHeaders.validDateFrom,
        unserialized: Cl607UnserializedHeaders.validDateFrom,
      },
      [Cl607SerializedHeaders.validDateTo]: {
        serialized: Cl607SerializedHeaders.validDateTo,
        unserialized: Cl607UnserializedHeaders.validDateTo,
      },
      [Cl607SerializedHeaders.uploadedAt]: {
        serialized: Cl607SerializedHeaders.uploadedAt,
        unserialized: Cl607UnserializedHeaders.uploadedAt,
      },
      [Cl607SerializedHeaders.uploadedBy]: {
        serialized: Cl607SerializedHeaders.uploadedBy,
        unserialized: Cl607UnserializedHeaders.uploadedBy,
      },
    },
  },
};

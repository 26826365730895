import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import {
  ADMIN_USERS,
  AdminUsersUnserializedHeaders,
  AdminUsersSerializedHeaders,
} from 'lib/tables/config/admin/users/config';
import { serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';
import { UserPassportSerialized, UserPassportPayload } from 'store/entities/userPassports';

export type UserType = 'farmer' | 'feed_producer' | 'veterinarian' | 'transporter';
type UserStatus = 'active' | 'suspended';
type UserLang = 'en' | 'nl';

export interface AdminUserUnserialized {
  id: number;
  full_name: null | string;
  pref_lang: UserLang;
  uid: string;
  user_role: string;
  user_type: null | UserType;
  is_contact_person: boolean;
  updated_at: string;
  status: UserStatus;
  user_passports: UserPassportPayload[];
  permission_profile_template: string;
  concepts: string[];
  companies: string[];
  profile_settings: {
    color_theme: string;
  };
}

export interface AdminUserSerialized {
  id: number;
  email?: string;
  fullName: null | string;
  prefLang: UserLang;
  uid: string;
  userRole: string;
  concepts: string[];
  companies: string[];
  userType: null | UserType;
  isContactPerson: boolean;
  updatedAt: string;
  status: UserStatus;
  userPassports: UserPassportSerialized[];
  permissionProfileTemplate: string;
  colorTheme: string;
}

const getSerializer = (data: DocumentData<AdminUserUnserialized[]>) => {
  const {
    fullName,
    prefLang,
    uid,
    userRole,
    userType,
    isContactPerson,
    concepts,
    companies,
    lastSignInAt,
    signInCount,
    permissionProfileTemplate,
    colorTheme,
  } = ADMIN_USERS.AdminUsers.columns;

  return new DocumentSerializer(data, [
    new SheetSerializer(ADMIN_USERS.AdminUsers.unserialized, ADMIN_USERS.AdminUsers.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(fullName.unserialized, fullName.serialized),
      new CellSerializer(prefLang.unserialized, prefLang.serialized),
      new CellSerializer(uid.unserialized, uid.serialized),
      new CellSerializer(userRole.unserialized, userRole.serialized),
      new CellSerializer(userType.unserialized, userType.serialized),
      new CellSerializer(permissionProfileTemplate.unserialized, permissionProfileTemplate.serialized),
      new CellSerializer(isContactPerson.unserialized, isContactPerson.serialized),
      new CellSerializer(concepts.unserialized, concepts.serialized),
      new CellSerializer(companies.unserialized, companies.serialized),
      new CellSerializer(lastSignInAt.unserialized, lastSignInAt.serialized),
      new CellSerializer(signInCount.unserialized, signInCount.serialized),
      new CellSerializer(colorTheme.unserialized, colorTheme.serialized),
      new CellSerializer(AdminUsersUnserializedHeaders.status, AdminUsersSerializedHeaders.status),
      new CellSerializer(AdminUsersUnserializedHeaders.email, AdminUsersSerializedHeaders.email),
      new CellSerializer(
        AdminUsersUnserializedHeaders.userPassports,
        AdminUsersSerializedHeaders.userPassports,
        serializeObjectSnakeCaseToCamelCase,
      ),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
    ]),
  ]);
};

export const serializeAdminUsers = (response: ResourcesWrapper<AdminUserUnserialized>) => {
  const data = {
    [ADMIN_USERS.AdminUsers.unserialized]: response.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

export const serializeAdminUser = (response: ResourceWrapper<AdminUserUnserialized>) => {
  const data = {
    [ADMIN_USERS.AdminUsers.unserialized]: [response.data.resource],
  };

  const serializer = getSerializer(data);

  return serializer.serialize()[ADMIN_USERS.AdminUsers.serialized][0];
};

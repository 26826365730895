import { IntlKeys } from 'lib/localization/keys/__keys';

enum SlaughterChargeOrPremiumsSerialized {
  SlaughterChargeOrPremiums = 'SlaughterChargeOrPremiums',
}

enum SlaughterChargeOrPremiumsUnserialized {
  SlaughterChargeOrPremiums = 'SlaughterChargeOrPremiums',
}

enum SlaughterChargeOrPremiumsSerializedHeaders {
  codeListId = 'codeListId',
  code = 'code',
  baseCode = 'baseCode',
  amount = 'amount',
  vatPercentage = 'vatPercentage',
  vatAmount = 'vatAmount',
}

enum SlaughterChargeOrPremiumsUnserializedHeaders {
  codeListId = 'code_list_id',
  code = 'code',
  baseCode = 'base_code',
  amount = 'amount',
  vatPercentage = 'vat_percentage',
  vatAmount = 'vat_amount',
}

export const SLAUGHTER_CHARGE_OR_PREMIUMS = {
  [SlaughterChargeOrPremiumsSerialized.SlaughterChargeOrPremiums]: {
    serialized: SlaughterChargeOrPremiumsSerialized.SlaughterChargeOrPremiums,
    unserialized: SlaughterChargeOrPremiumsUnserialized.SlaughterChargeOrPremiums,
    view: IntlKeys.slaughterChargeOrPremiums,
    columns: {
      [SlaughterChargeOrPremiumsSerializedHeaders.codeListId]: {
        serialized: SlaughterChargeOrPremiumsSerializedHeaders.codeListId,
        unserialized: SlaughterChargeOrPremiumsUnserializedHeaders.codeListId,
        view: IntlKeys.slaughterCOPCodeListId,
      },
      [SlaughterChargeOrPremiumsSerializedHeaders.code]: {
        serialized: SlaughterChargeOrPremiumsSerializedHeaders.code,
        unserialized: SlaughterChargeOrPremiumsUnserializedHeaders.code,
        view: IntlKeys.slaughterCOPCode,
      },
      [SlaughterChargeOrPremiumsSerializedHeaders.baseCode]: {
        serialized: SlaughterChargeOrPremiumsSerializedHeaders.baseCode,
        unserialized: SlaughterChargeOrPremiumsUnserializedHeaders.baseCode,
        view: IntlKeys.slaughterCOPBaseCode,
      },
      [SlaughterChargeOrPremiumsSerializedHeaders.amount]: {
        serialized: SlaughterChargeOrPremiumsSerializedHeaders.amount,
        unserialized: SlaughterChargeOrPremiumsUnserializedHeaders.amount,
        view: IntlKeys.slaughterCOPAmount,
      },
      [SlaughterChargeOrPremiumsSerializedHeaders.vatPercentage]: {
        serialized: SlaughterChargeOrPremiumsSerializedHeaders.vatPercentage,
        unserialized: SlaughterChargeOrPremiumsUnserializedHeaders.vatPercentage,
        view: IntlKeys.slaughterCOPVatPercentage,
      },
      [SlaughterChargeOrPremiumsSerializedHeaders.vatAmount]: {
        serialized: SlaughterChargeOrPremiumsSerializedHeaders.vatAmount,
        unserialized: SlaughterChargeOrPremiumsUnserializedHeaders.vatAmount,
        view: IntlKeys.slaughterCOPVatAmount,
      },
    },
  },
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { CVB_TABLE } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type CvbTablePayload = {
  id: number;
  product_code: string | null;
  product_sub_code: string | null;
  product_type: string | null;
  product_name_nl: string | null;
  class_name_nl: string | null;
  sub_class_name: string | null;
  product_name_en: string | null;
  class_name_en: string | null;
  sub_class_name_en: string | null;
  ds: number | null;
  ds_unit: string | null;
  ras: number | null;
  remote_unit: string | null;
  rein: number | null;
  rein_unit: string | null;
  re: number | null;
  re_unit: string | null;
  c_vc_re: number | null;
  c_vc_re_unit: string | null;
  c_vc_os: number | null;
  c_vc_os_unit: string | null;
  carbon_foot_print_upload: ManageUploadPayload;
};

export type CvbTableSerialized = {
  id: number;
  referenceUploadID: number;
  productCode: string | null;
  productSubCode: string | null;
  productType: string | null;
  productNameNL: string | null;
  classNameNL: string | null;
  subClassNameNL: string | null;
  productNameEN: string | null;
  classNameEN: string | null;
  subClassNameEN: string | null;
  ds: number | null;
  dsUnit: string | null;
  ras: number | null;
  rasUnit: string | null;
  rein: number | null;
  reinUnit: string | null;
  re: number | null;
  reUnit: string | null;
  cVcRe: number | null;
  cVcReUnit: string | null;
  cVcOs: number | null;
  cVcOsUnit: string | null;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = CVB_TABLE.CvbTable;

const getDefaultSerializer = (data: DocumentData<CvbTablePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.productCode.unserialized, columns.productCode.serialized),
      new CellSerializer(columns.productSubCode.unserialized, columns.productSubCode.serialized),
      new CellSerializer(columns.productType.unserialized, columns.productType.serialized),
      new CellSerializer(columns.productNameNL.unserialized, columns.productNameNL.serialized),
      new CellSerializer(columns.classNameNL.unserialized, columns.classNameNL.serialized),
      new CellSerializer(columns.subClassNameNL.unserialized, columns.subClassNameNL.serialized),
      new CellSerializer(columns.productNameEN.unserialized, columns.productNameEN.serialized),
      new CellSerializer(columns.classNameEN.unserialized, columns.classNameEN.serialized),
      new CellSerializer(columns.subClassNameEN.unserialized, columns.subClassNameEN.serialized),
      new CellSerializer(columns.ds.unserialized, columns.ds.serialized),
      new CellSerializer(columns.dsUnit.unserialized, columns.dsUnit.serialized),
      new CellSerializer(columns.ras.unserialized, columns.ras.serialized),
      new CellSerializer(columns.rasUnit.unserialized, columns.rasUnit.serialized),
      new CellSerializer(columns.rein.unserialized, columns.rein.serialized),
      new CellSerializer(columns.reinUnit.unserialized, columns.reinUnit.serialized),
      new CellSerializer(columns.re.unserialized, columns.re.serialized),
      new CellSerializer(columns.reUnit.unserialized, columns.reUnit.serialized),
      new CellSerializer(columns.cVcRe.unserialized, columns.cVcRe.serialized),
      new CellSerializer(columns.cVcReUnit.unserialized, columns.cVcReUnit.serialized),
      new CellSerializer(columns.cVcOs.unserialized, columns.cVcOs.serialized),
      new CellSerializer(columns.cVcOsUnit.unserialized, columns.cVcOsUnit.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeCvbTable = (serverData: ResourcesWrapper<CvbTablePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const serializeCvbTableRecord = (serverData: ResourceWrapper<CvbTablePayload>) => {
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

import { PIG_HOUSING_EMISSION_FACTORS } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = PIG_HOUSING_EMISSION_FACTORS.PigHousingEmissionFactors;

export const serializePigHousingEmissionFactorsData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.livestockCategory.original, columns.livestockCategory.serialized),
      new CellSerializer(columns.housingSystem.original, columns.housingSystem.serialized),
      new CellSerializer(columns.yearValue.original, columns.yearValue.serialized),
      new CellSerializer(columns.emissionValue.original, columns.emissionValue.serialized),
      new CellSerializer(columns.unit.original, columns.unit.serialized),
    ]),
  ]);
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum RavCodesOriginal {
  RavCodes = 'CFP_RAV_codes',
}

enum RavCodesSerialized {
  RavCodes = 'RavCodes',
}

enum RavCodesUnserialized {
  RavCodes = 'RavCodes',
}

enum RavCodesOriginalHeaders {
  'animalCategory' = 'Diercategorie',
  'ravCode' = 'RAVCode',
  'animalDescriptionNl' = 'Dieromschrijving',
  'animalDescriptionEn' = 'AnimalDescription',
  'animalEnclosureCategory' = 'DierverblijfCategorie ',
  'ammonia' = 'Ammoniak ',
  'ammoniaUnit' = 'AmmoniakUnit',
}

enum RavCodesSerializedHeaders {
  'animalCategory' = 'animalCategory',
  'ravCode' = 'ravCode',
  'animalDescriptionNl' = 'animalDescriptionNl',
  'animalDescriptionEn' = 'animalDescriptionEn',
  'animalEnclosureCategory' = 'animalEnclosureCategory',
  'ammonia' = 'ammonia',
  'ammoniaUnit' = 'ammoniaUnit',
}
enum RavCodesUnserializedHeaders {
  'animalCategory' = 'animal_category',
  'ravCode' = 'rav_code',
  'animalDescriptionNl' = 'animal_description_nl',
  'animalDescriptionEn' = 'animal_description_en',
  'animalEnclosureCategory' = 'animal_enclosure_category',
  'ammonia' = 'ammonia',
  'ammoniaUnit' = 'ammonia_unit',
}

export const RAV_CODES_FIELDS = {
  [RavCodesUnserialized.RavCodes]: {
    original: RavCodesOriginal.RavCodes,
    serialized: RavCodesSerialized.RavCodes,
    unserialized: RavCodesUnserialized.RavCodes,
    view: IntlKeys.referenceTablesRavCodes,
    viewName: 'CFP_RAV_codes',

    columns: {
      [RavCodesSerializedHeaders.animalCategory]: {
        original: RavCodesOriginalHeaders.animalCategory,
        serialized: RavCodesSerializedHeaders.animalCategory,
        unserialized: RavCodesUnserializedHeaders.animalCategory,
        view: IntlKeys.referenceTablesRavCodesAnimalCategory,
      },
      [RavCodesSerializedHeaders.ravCode]: {
        original: RavCodesOriginalHeaders.ravCode,
        serialized: RavCodesSerializedHeaders.ravCode,
        unserialized: RavCodesUnserializedHeaders.ravCode,
        view: IntlKeys.referenceTablesRavCodesRavCode,
      },
      [RavCodesSerializedHeaders.animalDescriptionNl]: {
        original: RavCodesOriginalHeaders.animalDescriptionNl,
        serialized: RavCodesSerializedHeaders.animalDescriptionNl,
        unserialized: RavCodesUnserializedHeaders.animalDescriptionNl,
        view: IntlKeys.referenceTablesRavCodesAnimalDescriptionNl,
      },
      [RavCodesSerializedHeaders.animalDescriptionEn]: {
        original: RavCodesOriginalHeaders.animalDescriptionEn,
        serialized: RavCodesSerializedHeaders.animalDescriptionEn,
        unserialized: RavCodesUnserializedHeaders.animalDescriptionEn,
        view: IntlKeys.referenceTablesRavCodesAnimalDescriptionEn,
      },
      [RavCodesSerializedHeaders.animalEnclosureCategory]: {
        original: RavCodesOriginalHeaders.animalEnclosureCategory,
        serialized: RavCodesSerializedHeaders.animalEnclosureCategory,
        unserialized: RavCodesUnserializedHeaders.animalEnclosureCategory,
        view: IntlKeys.referenceTablesRavCodesAnimalEnclosureCategory,
      },
      [RavCodesSerializedHeaders.ammonia]: {
        original: RavCodesOriginalHeaders.ammonia,
        serialized: RavCodesSerializedHeaders.ammonia,
        unserialized: RavCodesUnserializedHeaders.ammonia,
        view: IntlKeys.referenceTablesRavCodesAmmonia,
      },
      [RavCodesSerializedHeaders.ammoniaUnit]: {
        original: RavCodesOriginalHeaders.ammoniaUnit,
        serialized: RavCodesSerializedHeaders.ammoniaUnit,
        unserialized: RavCodesUnserializedHeaders.ammoniaUnit,
        view: IntlKeys.referenceTablesRavCodesAmmoniaUnit,
      },
    },
  },
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_EXPENSE } from './config';

export type GroupExpensePayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  fin_event_identifier: string;
  period_from: string | null;
  period_to: string | null;
  percent_sows: string;
  percent_finishers: string;
  percent_repl_gilts: string;
  percent_weaners: string;
  expense_datetime: string;
  account_identifier: string;
  number: string;
};

export type GroupExpenseSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  finEventIdentifier: string;
  periodFrom: string;
  periodTo: string | null;
  percentSows: string | null;
  percentFinishers: string | null;
  percentReplGilts: string;
  percentWeaners: string;
  expenseDatetime: string;
  accountIdentifier: string;
  number: string;
};

const { columns, serialized, unserialized } = GROUP_EXPENSE.GroupExpense;

const getDefaultSerializer = (data: DocumentData<GroupExpensePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.finEventIdentifier.unserialized, columns.finEventIdentifier.serialized),
      new CellSerializer(columns.periodFrom.unserialized, columns.periodFrom.serialized),
      new CellSerializer(columns.periodTo.unserialized, columns.periodTo.serialized),
      new CellSerializer(columns.percentSows.unserialized, columns.percentSows.serialized),
      new CellSerializer(columns.percentFinishers.unserialized, columns.percentFinishers.serialized),
      new CellSerializer(columns.percentReplGilts.unserialized, columns.percentReplGilts.serialized),
      new CellSerializer(columns.percentWeaners.unserialized, columns.percentWeaners.serialized),
      new CellSerializer(columns.expenseDatetime.unserialized, columns.expenseDatetime.serialized),
      new CellSerializer(columns.accountIdentifier.unserialized, columns.accountIdentifier.serialized),
      new CellSerializer(columns.number.unserialized, columns.number.serialized),
    ]),
  ]);
};

export const serializeGroupExpense = (serverData: ResourcesWrapper<GroupExpensePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const deviationsCompany = new Route('get', new RouteParser('/deviations/grouped_index'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 20754,
          program: 'CBL01',
          remark: 'Reductie beschreven in BGP',
          answer: 'Ja',
          code: 'BLKAG08a',
          updated_date: '2020-04-07T21:00:00.000Z',
          created_at: '2020-06-26T07:53:17.538Z',
          updated_at: '2020-06-26T07:53:17.538Z',
          ubn: '0297930',
          ...generateBlockchainRecord(),
        },
        {
          id: 20745,
          program: 'GFW',
          remark: 'Via telefoon',
          answer: 'Ja',
          code: 'GFW08d',
          updated_date: '2020-04-01T21:00:00.000Z',
          created_at: '2020-06-26T07:53:17.495Z',
          updated_at: '2020-06-26T07:53:17.495Z',
          ubn: '0205922',
          ...generateBlockchainRecord(),
        },
        {
          id: 20720,
          program: 'GFW',
          remark: '4800',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-01T20:59:56.000Z',
          created_at: '2020-06-03T13:06:36.990Z',
          updated_at: '2020-06-03T13:06:36.990Z',
          ubn: '6081494',
          ...generateBlockchainRecord(),
        },
        {
          id: 20692,
          program: 'GFW',
          remark: '3800',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:36.853Z',
          updated_at: '2020-06-03T13:06:36.853Z',
          ubn: '6070216',
          ...generateBlockchainRecord(),
        },
        {
          id: 20675,
          program: 'JAPAN',
          remark: 'uitslag dd. 12.3.20 Roba',
          answer: 'A',
          code: 'VV0303I',
          updated_date: '2020-04-07T20:59:56.000Z',
          created_at: '2020-06-03T13:06:36.766Z',
          updated_at: '2020-06-03T13:06:36.766Z',
          ubn: '6037871',
          ...generateBlockchainRecord(),
        },
        {
          id: 20648,
          program: 'GFW',
          remark: '1867',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:36.630Z',
          updated_at: '2020-06-03T13:06:36.630Z',
          ubn: '2352237',
          ...generateBlockchainRecord(),
        },
        {
          id: 20515,
          program: 'CBL01',
          remark:
            'Er is permanent goed afleidingsmateriaal aanwezig: ketting + kunststof buisje  en stroruif per groep. ',
          answer: 'A',
          code: 'VH0504',
          updated_date: '2020-04-02T20:59:56.000Z',
          created_at: '2020-06-03T13:06:35.947Z',
          updated_at: '2020-06-03T13:06:35.947Z',
          ubn: '2332824',
          ...generateBlockchainRecord(),
        },
        {
          id: 20397,
          program: 'JAPAN',
          remark: 'De dieren hebben onbeperkt vers water tot hun beschikking.',
          answer: 'A',
          code: 'VV0508',
          updated_date: '2020-04-02T20:59:56.000Z',
          created_at: '2020-06-03T13:06:35.314Z',
          updated_at: '2020-06-03T13:06:35.314Z',
          ubn: '2310516',
          ...generateBlockchainRecord(),
        },
        {
          id: 20357,
          program: 'CBL01',
          remark: 'jerrycand met lang stro. ketting+blok hout',
          answer: 'A',
          code: 'VH0504',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:35.100Z',
          updated_at: '2020-06-03T13:06:35.100Z',
          ubn: '2262396',
          ...generateBlockchainRecord(),
        },
        {
          id: 20227,
          program: 'GFW',
          remark: '0',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:34.422Z',
          updated_at: '2020-06-03T13:06:34.422Z',
          ubn: '2128313',
          ...generateBlockchainRecord(),
        },
        {
          id: 20199,
          program: 'GFW',
          remark: '2430',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:34.287Z',
          updated_at: '2020-06-03T13:06:34.287Z',
          ubn: '2126360',
          ...generateBlockchainRecord(),
        },
        {
          id: 20054,
          program: 'JAPAN',
          remark: 'Nippels in de verblijven',
          answer: 'A',
          code: 'VV0508',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:33.521Z',
          updated_at: '2020-06-03T13:06:33.521Z',
          ubn: '2066372',
          ...generateBlockchainRecord(),
        },
        {
          id: 20024,
          program: 'GFW',
          remark: '710',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-01T20:59:56.000Z',
          created_at: '2020-06-03T13:06:33.381Z',
          updated_at: '2020-06-03T13:06:33.381Z',
          ubn: '2055967',
          ...generateBlockchainRecord(),
        },
        {
          id: 20007,
          program: 'GFW',
          remark: '4500',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-01T20:59:56.000Z',
          created_at: '2020-06-03T13:06:33.296Z',
          updated_at: '2020-06-03T13:06:33.296Z',
          ubn: '2055448',
          ...generateBlockchainRecord(),
        },
        {
          id: 19976,
          program: 'GFW',
          remark: '1793',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-03-31T20:59:56.000Z',
          created_at: '2020-06-03T13:06:33.152Z',
          updated_at: '2020-06-03T13:06:33.152Z',
          ubn: '2023212',
          ...generateBlockchainRecord(),
        },
        {
          id: 19957,
          program: 'GFW',
          remark: '0',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-03-31T20:59:56.000Z',
          created_at: '2020-06-03T13:06:33.064Z',
          updated_at: '2020-06-03T13:06:33.064Z',
          ubn: '1958474',
          ...generateBlockchainRecord(),
        },
        {
          id: 19936,
          program: 'GFW',
          remark: '4078',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:32.965Z',
          updated_at: '2020-06-03T13:06:32.965Z',
          ubn: '1809176',
          ...generateBlockchainRecord(),
        },
        {
          id: 19913,
          program: 'GFW',
          remark: '900',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-03-31T20:59:56.000Z',
          created_at: '2020-06-03T13:06:32.860Z',
          updated_at: '2020-06-03T13:06:32.860Z',
          ubn: '1755543',
          ...generateBlockchainRecord(),
        },
        {
          id: 19776,
          program: 'GFW',
          remark: '2133',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-01T20:59:56.000Z',
          created_at: '2020-06-03T13:06:32.172Z',
          updated_at: '2020-06-03T13:06:32.172Z',
          ubn: '1740716',
          ...generateBlockchainRecord(),
        },
        {
          id: 19749,
          program: 'GFW',
          remark: '1700',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:32.045Z',
          updated_at: '2020-06-03T13:06:32.045Z',
          ubn: '1723245',
          ...generateBlockchainRecord(),
        },
        {
          id: 19718,
          program: 'JAPAN',
          remark: 'Uitslag laatste bronwater onderzoek d.d.: 24-01-2020',
          answer: 'A',
          code: 'VV0303I',
          updated_date: '2020-04-07T20:59:56.000Z',
          created_at: '2020-06-03T13:06:31.893Z',
          updated_at: '2020-06-03T13:06:31.893Z',
          ubn: '1721670',
          ...generateBlockchainRecord(),
        },
        {
          id: 19694,
          program: 'GFW',
          remark: '2596',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:31.780Z',
          updated_at: '2020-06-03T13:06:31.780Z',
          ubn: '1708459',
          ...generateBlockchainRecord(),
        },
        {
          id: 19666,
          program: 'CBL01',
          remark: 'Staarten worden gecoupeerd. Staarten van de opfokgelten worden nu langer gelaten.',
          answer: 'Ja',
          code: 'BLKZ04A.1',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:31.646Z',
          updated_at: '2020-06-03T13:06:31.646Z',
          ubn: '1707821',
          ...generateBlockchainRecord(),
        },
        {
          id: 19663,
          program: 'JAPAN',
          remark: 'De dieren hebben onbeperkt vers water tot hun beschikking.',
          answer: 'A',
          code: 'VV0508',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:31.633Z',
          updated_at: '2020-06-03T13:06:31.633Z',
          ubn: '1707175',
          ...generateBlockchainRecord(),
        },
        {
          id: 19560,
          program: 'GFW',
          remark: '0',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-06T20:59:56.000Z',
          created_at: '2020-06-03T13:06:31.130Z',
          updated_at: '2020-06-03T13:06:31.130Z',
          ubn: '1671830',
          ...generateBlockchainRecord(),
        },
        {
          id: 19410,
          program: 'BIO',
          remark: 'Kadaverplaats heeft verharde ondergrond',
          answer: 'A',
          code: 'VB0505',
          updated_date: '2020-04-01T20:59:56.000Z',
          created_at: '2020-06-03T13:06:30.354Z',
          updated_at: '2020-06-03T13:06:30.354Z',
          ubn: '1659560',
          ...generateBlockchainRecord(),
        },
        {
          id: 19386,
          program: 'CBL01',
          remark:
            'Er is permanent goed afleidingsmateriaal aanwezig: ketting + kunststof buisje en strokokers en stroruiven. ',
          answer: 'A',
          code: 'VH0504',
          updated_date: '2020-04-02T20:59:56.000Z',
          created_at: '2020-06-03T13:06:30.242Z',
          updated_at: '2020-06-03T13:06:30.242Z',
          ubn: '1638969',
          ...generateBlockchainRecord(),
        },
        {
          id: 19232,
          program: 'JAPAN',
          remark: 'Er is sprake van brijvoedering, tevens kunnen de dieren onbeperkt over vers water beschikken.',
          answer: 'A',
          code: 'VV0508',
          updated_date: '2020-03-31T20:59:56.000Z',
          created_at: '2020-06-03T13:06:29.457Z',
          updated_at: '2020-06-03T13:06:29.457Z',
          ubn: '1625006',
          ...generateBlockchainRecord(),
        },
        {
          id: 19183,
          program: 'BLKV01',
          remark: 'Wagens hebben laadlift (verklaring veehouder).',
          answer: 'Ja',
          code: 'BLKT03',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:29.215Z',
          updated_at: '2020-06-03T13:06:29.215Z',
          ubn: '1586729',
          ...generateBlockchainRecord(),
        },
        {
          id: 19121,
          program: 'GFW',
          remark: '0',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:28.913Z',
          updated_at: '2020-06-03T13:06:28.913Z',
          ubn: '1576203',
          ...generateBlockchainRecord(),
        },
        {
          id: 19101,
          program: 'JAPAN',
          remark: 'De dieren hebben onbeperkt vers water tot hun beschikking.',
          answer: 'A',
          code: 'VV0508',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:28.813Z',
          updated_at: '2020-06-03T13:06:28.813Z',
          ubn: '1575534',
          ...generateBlockchainRecord(),
        },
        {
          id: 19064,
          program: 'GFW',
          remark: '2000',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-02T20:59:56.000Z',
          created_at: '2020-06-03T13:06:28.642Z',
          updated_at: '2020-06-03T13:06:28.642Z',
          ubn: '1086920',
          ...generateBlockchainRecord(),
        },
        {
          id: 19044,
          program: 'GFW',
          remark: '1456',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-07T20:59:56.000Z',
          created_at: '2020-06-03T13:06:28.543Z',
          updated_at: '2020-06-03T13:06:28.543Z',
          ubn: '1009880',
          ...generateBlockchainRecord(),
        },
        {
          id: 18928,
          program: 'BIO',
          remark: 'geen aanvoer',
          answer: 'A',
          code: 'VT0401A',
          updated_date: '2020-04-06T20:59:56.000Z',
          created_at: '2020-06-03T13:06:27.987Z',
          updated_at: '2020-06-03T13:06:27.987Z',
          ubn: '0966498',
          ...generateBlockchainRecord(),
        },
        {
          id: 18897,
          program: 'JAPAN',
          remark: 'leidingwater',
          answer: 'n.v.t.',
          code: 'VV0303I',
          updated_date: '2020-04-07T20:59:56.000Z',
          created_at: '2020-06-03T13:06:27.843Z',
          updated_at: '2020-06-03T13:06:27.843Z',
          ubn: '0915944',
          ...generateBlockchainRecord(),
        },
        {
          id: 18875,
          program: 'GFW',
          remark: '95',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-02T20:59:56.000Z',
          created_at: '2020-06-03T13:06:27.638Z',
          updated_at: '2020-06-03T13:06:27.638Z',
          ubn: '0759306',
          ...generateBlockchainRecord(),
        },
        {
          id: 18858,
          program: 'JAPAN',
          remark: 'Afleveringsverklaringen ondertekend door varkenshouder of gemachtigd persoon',
          answer: 'A',
          code: 'VT0302Ia',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:27.558Z',
          updated_at: '2020-06-03T13:06:27.558Z',
          ubn: '0717104',
          ...generateBlockchainRecord(),
        },
        {
          id: 18834,
          program: 'CBL01',
          remark: 'Er is permanent goed afleidingsmateriaal aanwezig: strkokers en ruiven ',
          answer: 'A',
          code: 'VH0504',
          updated_date: '2020-04-07T20:59:56.000Z',
          created_at: '2020-06-03T13:06:27.448Z',
          updated_at: '2020-06-03T13:06:27.448Z',
          ubn: '0693163',
          ...generateBlockchainRecord(),
        },
        {
          id: 18732,
          program: 'JAPAN',
          remark: 'Uitslag laatste bronwater onderzoek d.d.: 11-02-2020',
          answer: 'A',
          code: 'VV0303I',
          updated_date: '2020-03-31T20:59:56.000Z',
          created_at: '2020-06-03T13:06:26.930Z',
          updated_at: '2020-06-03T13:06:26.930Z',
          ubn: '0688697',
          ...generateBlockchainRecord(),
        },
        {
          id: 18707,
          program: 'JAPAN',
          remark: 'Nippels in de verblijven',
          answer: 'A',
          code: 'VV0508',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:26.812Z',
          updated_at: '2020-06-03T13:06:26.812Z',
          ubn: '0596844',
          ...generateBlockchainRecord(),
        },
        {
          id: 18675,
          program: 'GFW',
          remark: '1800',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-06T20:59:56.000Z',
          created_at: '2020-06-03T13:06:26.666Z',
          updated_at: '2020-06-03T13:06:26.666Z',
          ubn: '0531799',
          ...generateBlockchainRecord(),
        },
        {
          id: 18647,
          program: 'GFW',
          remark: '0',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-01T20:59:56.000Z',
          created_at: '2020-06-03T13:06:26.537Z',
          updated_at: '2020-06-03T13:06:26.537Z',
          ubn: '0391812',
          ...generateBlockchainRecord(),
        },
        {
          id: 18619,
          program: 'GFW',
          remark: '1250',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:26.409Z',
          updated_at: '2020-06-03T13:06:26.409Z',
          ubn: '0373555',
          ...generateBlockchainRecord(),
        },
        {
          id: 18372,
          program: 'GFW',
          remark: '0',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-05T20:59:56.000Z',
          created_at: '2020-06-03T13:06:25.226Z',
          updated_at: '2020-06-03T13:06:25.226Z',
          ubn: '0294809',
          ...generateBlockchainRecord(),
        },
        {
          id: 18225,
          program: 'GFW',
          remark: '1648',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:24.509Z',
          updated_at: '2020-06-03T13:06:24.509Z',
          ubn: '0287359',
          ...generateBlockchainRecord(),
        },
        {
          id: 18205,
          program: 'JAPAN',
          remark: 'De dieren hebben onbeperkt vers water tot hun beschikking.',
          answer: 'A',
          code: 'VV0508',
          updated_date: '2020-04-08T20:59:56.000Z',
          created_at: '2020-06-03T13:06:24.420Z',
          updated_at: '2020-06-03T13:06:24.420Z',
          ubn: '0283580',
          ...generateBlockchainRecord(),
        },
        {
          id: 18172,
          program: 'GFW',
          remark: '0',
          answer: 'A',
          code: 'VA0506a',
          updated_date: '2020-04-09T20:59:56.000Z',
          created_at: '2020-06-03T13:06:24.271Z',
          updated_at: '2020-06-03T13:06:24.271Z',
          ubn: '0281403',
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

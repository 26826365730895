import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GROUP_MOVES } from './config';

export type GroupMovesPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  fin_event_identifier: string;
  group_from: string;
  barn_from: string;
  room_from: string;
  pen_from: string;
  animal_category_id_from: string;
  number: string;
  weight: number;
  group_move_date: string;
  animal_category_id_to: string;
  without_male: string;
  without_female: string;
  without_male_castrated: string;
  admin_date: string | null;
  rem_reason: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type GroupMovesSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  finEventIdentifier: string;
  groupFrom: string;
  barnFrom: string;
  roomFrom: string;
  penFrom: string;
  animalCategoryIdFrom: string;
  number: string;
  weight: number;
  groupMoveDate: string;
  animalCategoryIdTo: string;
  withoutMale: string;
  withoutFemale: string;
  withoutMaleCastrated: string;
  adminDate: string | null;
  remReason: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = GROUP_MOVES.GroupMoves;

const getDefaultSerializer = (data: DocumentData<GroupMovesPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.finEventIdentifier.unserialized, columns.finEventIdentifier.serialized),
      new CellSerializer(columns.groupFrom.unserialized, columns.groupFrom.serialized),
      new CellSerializer(columns.barnFrom.unserialized, columns.barnFrom.serialized),
      new CellSerializer(columns.roomFrom.unserialized, columns.roomFrom.serialized),
      new CellSerializer(columns.penFrom.unserialized, columns.penFrom.serialized),
      new CellSerializer(columns.animalCategoryIdFrom.unserialized, columns.animalCategoryIdFrom.serialized),
      new CellSerializer(columns.number.unserialized, columns.number.serialized),
      new CellSerializer(columns.weight.unserialized, columns.weight.serialized),
      new CellSerializer(columns.groupMoveDate.unserialized, columns.groupMoveDate.serialized),
      new CellSerializer(columns.animalCategoryIdTo.unserialized, columns.animalCategoryIdTo.serialized),
      new CellSerializer(columns.withoutMale.unserialized, columns.withoutMale.serialized),
      new CellSerializer(columns.withoutFemale.unserialized, columns.withoutFemale.serialized),
      new CellSerializer(columns.withoutMaleCastrated.unserialized, columns.withoutMaleCastrated.serialized),
      new CellSerializer(columns.adminDate.unserialized, columns.adminDate.serialized),
      new CellSerializer(columns.remReason.unserialized, columns.remReason.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeGroupMoves = (serverData: ResourcesWrapper<GroupMovesPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

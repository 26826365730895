/**
 * @deprecated use single IntlKeys
 */
export enum UploadsKeys {
  uploadFile = 'uploads.uploadFile',
  dragNDrop = 'uploads.dragNDrop',
  dndToUpload = 'uploads.dndToUpload',
  dndOr = 'uploads.dndOr',
  dndSelectFile = 'uploads.dndSelectFile',
  dndSupportedFormats = 'uploads.dndSupportedFormats',
  dndSingleFile = 'uploads.dndSingleFile',

  uploadDaily = 'uploads.uploadDaily',
  uploadWeekly = 'uploads.uploadWeekly',
  uploadVriDatabase = 'uploads.uploadVriDatabase',
  uploadHistory = 'uploads.uploadHistory',

  pleaseStandBy = 'uploads.pleaseStandBy',
  calculating = 'uploads.calculating',
  nSeconds = 'uploads.nSeconds',
  uploadSuccessful = 'uploads.uploadSuccessful',
  alreadyUploaded = 'uploads.alreadyUploaded',
  alreadyUploadedMsgConfirm = 'uploads.alreadyUploadedMsgConfirm',

  fileName = 'uploads.fileName',
  fileSize = 'uploads.fileSize',
  fileType = 'uploads.fileType',
  processingTime = 'uploads.processingTime',

  nSelected = 'uploads.nSelected',
  sortedAscending = 'uploads.sortedAscending',
  sortedDescending = 'uploads.sortedDescending',
  densePadding = 'uploads.densePadding',

  delete = 'uploads.delete',
  filterList = 'uploads.filterList',
}

import { IntlKeys } from 'lib/localization/keys';

enum CodesDefectsSerialized {
  CodesDefects = 'CodesDefects',
}

enum CodesDefectsUnserialized {
  CodesDefects = 'CodesDefects',
}

export enum CodesDefectsSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  defectIdentifier = 'defectIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  note = 'note',
  workList = 'workList',
  code = 'code',
  defectDatetime = 'defectDatetime',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesDefectsUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  defectIdentifier: 'defect_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  note: 'note',
  workList: 'worklist',
  code: 'code',
  defectDatetime: 'defect_datetime',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_DEFECTS = {
  [CodesDefectsSerialized.CodesDefects]: {
    serialized: CodesDefectsSerialized.CodesDefects,
    unserialized: CodesDefectsUnserialized.CodesDefects,
    view: IntlKeys.farmManagementDataDefectsList,

    columns: {
      [CodesDefectsSerializedHeaders.id]: {
        serialized: CodesDefectsSerializedHeaders.id,
        unserialized: CodesDefectsUnserializedHeaders.id,
      },
      [CodesDefectsSerializedHeaders.companyIdentifier]: {
        serialized: CodesDefectsSerializedHeaders.companyIdentifier,
        unserialized: CodesDefectsUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesDefectsSerializedHeaders.defectIdentifier]: {
        serialized: CodesDefectsSerializedHeaders.defectIdentifier,
        unserialized: CodesDefectsUnserializedHeaders.defectIdentifier,
        view: IntlKeys.farmManagementDataDefectId,
      },
      [CodesDefectsSerializedHeaders.abbreviation]: {
        serialized: CodesDefectsSerializedHeaders.abbreviation,
        unserialized: CodesDefectsUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesDefectsSerializedHeaders.description]: {
        serialized: CodesDefectsSerializedHeaders.description,
        unserialized: CodesDefectsUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesDefectsSerializedHeaders.note]: {
        serialized: CodesDefectsSerializedHeaders.note,
        unserialized: CodesDefectsUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesDefectsSerializedHeaders.workList]: {
        serialized: CodesDefectsSerializedHeaders.workList,
        unserialized: CodesDefectsUnserializedHeaders.workList,
        view: IntlKeys.farmManagementDataWorkList,
      },
      [CodesDefectsSerializedHeaders.code]: {
        serialized: CodesDefectsSerializedHeaders.code,
        unserialized: CodesDefectsUnserializedHeaders.code,
        view: IntlKeys.farmManagementDataCode,
      },
      [CodesDefectsSerializedHeaders.defectDatetime]: {
        serialized: CodesDefectsSerializedHeaders.defectDatetime,
        unserialized: CodesDefectsUnserializedHeaders.defectDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesDefectsSerializedHeaders.ubnList]: {
        serialized: CodesDefectsSerializedHeaders.ubnList,
        unserialized: CodesDefectsUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesDefectsSerializedHeaders.kvkList]: {
        serialized: CodesDefectsSerializedHeaders.kvkList,
        unserialized: CodesDefectsUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesDefectsSerializedHeaders.registrationNameList]: {
        serialized: CodesDefectsSerializedHeaders.registrationNameList,
        unserialized: CodesDefectsUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { IntlKeys } from 'lib/localization/keys/__keys';
import { CertificatesWelfareKeys } from 'lib/localization/keys/certificates';

/*

{
  id: 4987,
  ubn: '2222222',
  valid_from: '2004-02-03T20:59:56.000Z',
  valid_to: '2021-01-31T20:59:56.000Z',
  type: 'welfare',
  active_blockchain_record: {
    stored_in_blockchain_at: '2020-07-23T07:06:16.665Z',
    hashed_data:
      '9c6ddf4467512815526624af106605d35985e68b047179bbf765119052383bc574bb3f3692755e44f17be3b1f01e3be9c48f47c99f87f59a65758c328d3d8a5c',
    hash_type: 'sha512',
  },
};

 */

enum WelfareSerialized {
  CertificatesWelfare = 'CertificatesWelfare',
}

enum WelfareUnserialized {
  CertificatesWelfare = 'CertificatesWelfare',
}

enum WelfareView {
  CertificatesWelfare = 'Certificates Welfare',
}

enum WelfareSerializedHeaders {
  ubn = 'ubn',
  validFrom = 'validFrom',
  validTo = 'validTo',
  type = 'type',
}

enum WelfareUnserializedHeaders {
  ubn = 'ubn',
  validFrom = 'valid_from',
  validTo = 'valid_to',
  type = 'certificate_type',
}

enum WelfareViewHeaders {
  ubn = 'UBN',
  validFrom = 'Valid from',
  validTo = 'Valid to',
  type = 'Type',
}

export const WELFARE_CERTIFICATES = {
  [WelfareSerialized.CertificatesWelfare]: {
    serialized: WelfareSerialized.CertificatesWelfare,
    unserialized: WelfareUnserialized.CertificatesWelfare,
    view: IntlKeys.titleCertificatesWelfare,

    columns: {
      [SERVICE_FIELDS.id.serialized]: {
        serialized: SERVICE_FIELDS.id.serialized,
        unserialized: SERVICE_FIELDS.id.unserialized,
        view: SERVICE_FIELDS.id.view,
      },
      [WelfareSerializedHeaders.ubn]: {
        serialized: WelfareSerializedHeaders.ubn,
        unserialized: WelfareUnserializedHeaders.ubn,
        view: CertificatesWelfareKeys.ubn,
      },
      [WelfareSerializedHeaders.validFrom]: {
        serialized: WelfareSerializedHeaders.validFrom,
        unserialized: WelfareUnserializedHeaders.validFrom,
        view: CertificatesWelfareKeys.validFrom,
      },
      [WelfareSerializedHeaders.validTo]: {
        serialized: WelfareSerializedHeaders.validTo,
        unserialized: WelfareUnserializedHeaders.validTo,
        view: CertificatesWelfareKeys.validTo,
      },
      [WelfareSerializedHeaders.type]: {
        serialized: WelfareSerializedHeaders.type,
        unserialized: WelfareUnserializedHeaders.type,
        view: CertificatesWelfareKeys.type,
      },
    },
  },
};

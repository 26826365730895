import findIndex from 'lodash/findIndex';
import { HybridColumnSettings, HybridView } from './HybridView';
import { TableColumnDescriptor, TableSettings } from './TableColumnsGenerator';
import { FormatMessage, TableTab } from './TableTab';

export class ColumnsSettingsResolver {
  public static resolve(
    tableSettings: TableSettings,
    tableId: string,
    tableTab: TableTab,
    formatMessage: FormatMessage,
    useLocalization = false,
  ) {
    const config = this.getConfig(tableSettings, tableId, tableTab.id);

    const columns = config ? this.resolveBySettings(config, tableTab) : this.resolveDefault(tableTab);

    return useLocalization ? this.translateColumns(columns, formatMessage) : columns;
  }

  private static translateColumns(
    columns: HybridColumnSettings[],
    formatMessage: FormatMessage,
  ): HybridColumnSettings[] {
    return columns.map(({ title, ...rest }) => ({
      ...rest,
      title: title && typeof title === 'string' ? formatMessage({ id: title }) : title,
    }));
  }

  private static resolveBySettings(
    currentTableSettings: Array<TableColumnDescriptor>,
    tableTab: TableTab,
  ): Array<HybridColumnSettings> {
    const headersIDsArray = currentTableSettings.map(({ id }) => id);

    const { orderNormal, orderStatic } = tableTab
      .getTableViewHeaders()
      .filter(({ id, isRemovable }) => !isRemovable || headersIDsArray.includes(id))
      .reduce(
        (acc, cur) => {
          if (typeof cur.order === 'number') {
            acc.orderStatic.push(cur);
          } else {
            acc.orderNormal.push(cur);
          }

          return acc;
        },
        { orderNormal: [], orderStatic: [] } as Record<'orderNormal' | 'orderStatic', HybridView[]>,
      );

    const sortedNormalColumns = orderNormal.sort(
      (a, b) => findIndex(currentTableSettings, { id: a.id }) - findIndex(currentTableSettings, { id: b.id }),
    );

    orderStatic.forEach((hybrid) => {
      sortedNormalColumns.splice(hybrid.order!, 0, hybrid);
    });

    return sortedNormalColumns.map(({ settings }) => settings);
  }

  public static resolveDefault(tableTab: TableTab): Array<HybridColumnSettings> {
    return tableTab.defaultColumnConfig
      ? this.resolveBySettings(tableTab.defaultColumnConfig, tableTab)
      : tableTab.getTableViewHeaders().map((header) => header.settings);
  }

  private static getConfig(tableSettings: TableSettings, tableId: string, tabId: string): Array<TableColumnDescriptor> {
    return tableSettings && tableSettings[tableId]?.[tabId];
  }
}

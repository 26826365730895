import axios from 'axios';
import qs from 'qs';
import AxiosMock from 'axios-mock-adapter';

import { AppMode, EnvVars } from 'lib/env/Env';

import { store } from 'store/store';
import { authHeadersSelector } from 'store/auth/reducer';

const Axios = axios.create({
  baseURL: process.env[EnvVars.API_URL],
  paramsSerializer: (params) =>
    qs.stringify(params, {
      skipNulls: true,
      encode: false,
      arrayFormat: 'brackets',
    }),
});

Axios.interceptors.request.use(
  (config) => {
    const { token, type, uid, client, expiry } = authHeadersSelector(store.getState());

    config.headers['access-token'] = token || config.headers['access-token'];
    config.headers['token-type'] = type || config.headers['token-type'];
    config.headers['client'] = client || config.headers['client'];
    config.headers['expiry'] = expiry || config.headers['expiry'];
    config.headers['uid'] = uid || config.headers['uid'];

    return config;
  },
  (err) => Promise.reject(err),
);

const Router = require('../../__mocks__').default; // this will not go to the bundle
const mock = new AxiosMock(Axios, {
  delayResponse: process.env.REACT_APP_MODE === AppMode.UNITTEST ? 0 : 1000,
});

mock.onAny(/\/mock/).reply((config) => {
  // this will pass to your __mocks__ router, you can put here anything you want
  return Router.proceedRequest(config);
});

if (process.env.REACT_APP_MODE === AppMode.UNITTEST) {
  mock.onAny().reply((config) => Router.proceedRequest(config));
} else {
  mock.onAny().passThrough();
}

export { Axios };

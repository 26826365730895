import { PIG_HOUSING_EMISSION_FACTORS } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo1DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = PIG_HOUSING_EMISSION_FACTORS.PigHousingEmissionFactors;

export const getPigHousingEmissionFactorsView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.livestockCategory.serialized,
        viewPropName: columns.livestockCategory.view,
      }),
      new CellViewer({
        serializedPropName: columns.housingSystem.serialized,
        viewPropName: columns.housingSystem.view,
      }),
      new CellViewer({
        serializedPropName: columns.yearValue.serialized,
        viewPropName: columns.yearValue.view,
      }),
      new CellViewer({
        serializedPropName: columns.emissionValue.serialized,
        viewPropName: columns.emissionValue.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo1DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.unit.serialized, viewPropName: columns.unit.view }),
    ]),
  ]);
};

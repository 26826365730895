import { IntlKeys } from 'lib/localization/keys';

enum CodesRoomSerialized {
  CodesRoom = 'CodesRoom',
}

enum CodesRoomUnserialized {
  CodesRoom = 'CodesRoom',
}

export enum CodesRoomSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  barnIdentifier = 'barnIdentifier',
  roomIdentifier = 'roomIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  codeRoomDatetime = 'codeRoomDatetime',
  surface = 'surface',
  roomTypeIdentifier = 'roomTypeIdentifier',
  color = 'color',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesRoomUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  barnIdentifier: 'barn_identifier',
  roomIdentifier: 'room_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  codeRoomDatetime: 'code_room_datetime',
  surface: 'surface',
  roomTypeIdentifier: 'room_type_identifier',
  color: 'color',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_ROOM = {
  [CodesRoomSerialized.CodesRoom]: {
    serialized: CodesRoomSerialized.CodesRoom,
    unserialized: CodesRoomUnserialized.CodesRoom,
    view: IntlKeys.farmManagementDataRoomList,

    columns: {
      [CodesRoomSerializedHeaders.id]: {
        serialized: CodesRoomSerializedHeaders.id,
        unserialized: CodesRoomUnserializedHeaders.id,
      },
      [CodesRoomSerializedHeaders.companyIdentifier]: {
        serialized: CodesRoomSerializedHeaders.companyIdentifier,
        unserialized: CodesRoomUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesRoomSerializedHeaders.farmIdentifier]: {
        serialized: CodesRoomSerializedHeaders.farmIdentifier,
        unserialized: CodesRoomUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [CodesRoomSerializedHeaders.barnIdentifier]: {
        serialized: CodesRoomSerializedHeaders.barnIdentifier,
        unserialized: CodesRoomUnserializedHeaders.barnIdentifier,
        view: IntlKeys.farmManagementDataBarnId,
      },
      [CodesRoomSerializedHeaders.roomIdentifier]: {
        serialized: CodesRoomSerializedHeaders.roomIdentifier,
        unserialized: CodesRoomUnserializedHeaders.roomIdentifier,
        view: IntlKeys.farmManagementDataRoomId,
      },
      [CodesRoomSerializedHeaders.abbreviation]: {
        serialized: CodesRoomSerializedHeaders.abbreviation,
        unserialized: CodesRoomUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesRoomSerializedHeaders.description]: {
        serialized: CodesRoomSerializedHeaders.description,
        unserialized: CodesRoomUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesRoomSerializedHeaders.codeRoomDatetime]: {
        serialized: CodesRoomSerializedHeaders.codeRoomDatetime,
        unserialized: CodesRoomUnserializedHeaders.codeRoomDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesRoomSerializedHeaders.surface]: {
        serialized: CodesRoomSerializedHeaders.surface,
        unserialized: CodesRoomUnserializedHeaders.surface,
        view: IntlKeys.farmManagementDataSurface,
      },
      [CodesRoomSerializedHeaders.roomTypeIdentifier]: {
        serialized: CodesRoomSerializedHeaders.roomTypeIdentifier,
        unserialized: CodesRoomUnserializedHeaders.roomTypeIdentifier,
        view: IntlKeys.farmManagementDataRoomTypeId,
      },
      [CodesRoomSerializedHeaders.color]: {
        serialized: CodesRoomSerializedHeaders.color,
        unserialized: CodesRoomUnserializedHeaders.color,
        view: IntlKeys.farmManagementDataColor,
      },
      [CodesRoomSerializedHeaders.ubnList]: {
        serialized: CodesRoomSerializedHeaders.ubnList,
        unserialized: CodesRoomUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesRoomSerializedHeaders.kvkList]: {
        serialized: CodesRoomSerializedHeaders.kvkList,
        unserialized: CodesRoomUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesRoomSerializedHeaders.registrationNameList]: {
        serialized: CodesRoomSerializedHeaders.registrationNameList,
        unserialized: CodesRoomUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_REMOVAL_TYPE } from './config';

export type CodesRemovalTypePayload = {
  id: number;
  removal_type_identifier: string;
  note: string;
  removal_type_datetime: string;
};

export type CodesRemovalTypeSerialized = {
  id: number;
  removalTypeId: string;
  note: string;
  removalTypeDatetime: string;
};

const { columns, serialized, unserialized } = CODES_REMOVAL_TYPE.CodesRemovalType;

const getDefaultSerializer = (data: DocumentData<CodesRemovalTypePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.removalTypeId.unserialized, columns.removalTypeId.serialized),
      new CellSerializer(columns.note.unserialized, columns.note.serialized),
      new CellSerializer(columns.removalTypeDatetime.unserialized, columns.removalTypeDatetime.serialized),
    ]),
  ]);
};

export const serializeCodesRemovalType = (serverData: ResourcesWrapper<CodesRemovalTypePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

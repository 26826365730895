import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { RAV_REFERENCE_LIST } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type RavReferenceListPayload = {
  id: number;
  animal_category: string | null;
  description: string | null;
  ef_em: number | null;
  housingType: string | null;
  rav_reference_list_upload: ManageUploadPayload;
};

export type RavReferenceListSerialized = {
  id: number;
  referenceUploadID: number;
  animalCategory: string;
  description: string;
  efEm: number;
  housingType: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = RAV_REFERENCE_LIST.RavReferenceList;

const getDefaultSerializer = (data: DocumentData<RavReferenceListPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.animalCategory.unserialized, columns.animalCategory.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.efEm.unserialized, columns.efEm.serialized),
      new CellSerializer(columns.housingType.unserialized, columns.housingType.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeRavReferenceList = (serverData: ResourcesWrapper<RavReferenceListPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { GROUP_FINANCIAL_EVENTS } from 'store/entities/farmManagementData/groupFinancialEvents/config';

const { columns, view, serialized } = GROUP_FINANCIAL_EVENTS.GroupFinancialEvents;

export const groupFinancialEventsTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.finEventIdentifier.view,
          field: columns.finEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.eventTypeIdentifier.view,
          field: columns.eventTypeIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.eventDate.view,
          field: columns.eventDate.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.contactIdentifier.view,
          field: columns.contactIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.amountExVat.view,
          field: columns.amountExVat.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.amountVat.view,
          field: columns.amountVat.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.amountInVat.view,
          field: columns.amountInVat.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.invoiceNumber.view,
          field: columns.invoiceNumber.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.invoiceDate.view,
          field: columns.invoiceDate.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.financialNotes.view,
          field: columns.financialNotes.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupDatetime.view,
          field: columns.groupDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.barnIdentifier.view,
          field: columns.barnIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.roomIdentifier.view,
          field: columns.roomIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.penIdentifier.view,
          field: columns.penIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupIdentifier.view,
          field: columns.groupIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.valve.view,
          field: columns.valve.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.inputEventIdentifier.view,
          field: columns.inputEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.importIdentifier.view,
          field: columns.importIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.inputWarnings.view,
          field: columns.inputWarnings.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.inputTypeStamp.view,
          field: columns.inputTypeStamp.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.scannedIdentifiers.view,
          field: columns.scannedIdentifiers.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.userIdentifier.view,
          field: columns.userIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.batchNumberProduction.view,
          field: columns.batchNumberProduction.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.batchNumberDelivery.view,
          field: columns.batchNumberDelivery.serialized,
        },
      }),
    ],
  }),
]);

import { merge } from 'lodash';

import { CellSerializer, ObjectWithProps } from './Cell';
import { Serializer } from '../index';

export interface DocumentData<T = Array<ObjectWithProps>> {
  [sheetName: string]: T;
}

export class SheetSerializer<T = ObjectWithProps, R = T> implements Serializer {
  constructor(
    readonly sheetOriginalName: string,
    readonly sheetSerializerName: string,
    private readonly cellSerializers: Array<CellSerializer<T, R>>,
  ) {}

  private _get = <S extends 'serialize' | 'unserialize'>(method: S) => {
    return (data: DocumentData<T[]>) => {
      const sheetName = this.sheetOriginalName;
      const sheetData = data[sheetName];

      return data[sheetName].map((rawExcelObject) => {
        // ROW
        return this.cellSerializers.reduce((cumulative, cellSerializer) => {
          const serializer = cellSerializer[method];

          const extractedProperty = serializer(data, sheetData, rawExcelObject);

          return merge(cumulative, extractedProperty);
        }, {} as R);
      });
    };
  };

  serialize = this._get('serialize');
  unserialize = this._get('unserialize');
}

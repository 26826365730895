import { IntlShape, useIntl } from 'react-intl';

import { IntlKeys } from 'lib/localization/keys';
import { useMemo } from 'react';

type SelfReferencedMapping = Record<string, string | string[]>;

const configureMappedValueGetter = (
  mapping: SelfReferencedMapping,
  formatMessage: IntlShape['formatMessage'],
): ((key: string) => string) => {
  return function get(key) {
    if (!mapping[key]) return formatMessage({ id: IntlKeys.dataNotClassified });

    const value = mapping[key];

    if (value instanceof Array) {
      return value
        .reduce<string[]>((acc, current) => {
          acc.push(get(current));

          return acc;
        }, [])
        .map((id) => formatMessage({ id }))
        .join(', ');
    }

    return formatMessage({ id: value });
  };
};

////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////

const carcassCodeIntlMapping: SelfReferencedMapping = {
  //
  '0': IntlKeys.approved,
  '1': IntlKeys.carcassCodePleurisy,
  '2': IntlKeys.carcassCodeInflamedSkin,
  '3': IntlKeys.carcassCodeInflamedLeg,
  '4': ['1', '3'],
  '5': ['2', '3'],
  '6': ['1', '2'],
  '7': ['1', '2', '3'],
};

export const useCarcassCodeMapper = () => {
  const { formatMessage } = useIntl();
  return useMemo(() => configureMappedValueGetter(carcassCodeIntlMapping, formatMessage), [formatMessage]);
};

////////////////////////////////////////////////////////////////////////////////////////////////

const organCodeIntlMapping: SelfReferencedMapping = {
  //
  '0': IntlKeys.approved,
  '1': IntlKeys.organCodeLiverAffected,
  '2': IntlKeys.organCodeLiverDisapproved,
  '3': IntlKeys.organCodeLungDisapproved,
  '4': ['1', '3'],
  '5': ['2', '3'],
  '6': IntlKeys.dataNotToRate,
};

export const useOrganCodeMapper = () => {
  const { formatMessage } = useIntl();
  return useMemo(() => configureMappedValueGetter(organCodeIntlMapping, formatMessage), [formatMessage]);
};

////////////////////////////////////////////////////////////////////////////////////////////////

const animalCategoryIntlMapping: SelfReferencedMapping = {
  //
  '1': 'Sows and breeding gilts',
  '2': 'Breeding boars',
  '3': 'Piglets',
  '4': 'Slaughter pigs',
  '5': 'Slaughter sows and slaughter piglets',
};
export const useAnimalCategoryMapper = () => {
  const { formatMessage } = useIntl();
  return useMemo(() => configureMappedValueGetter(animalCategoryIntlMapping, formatMessage), [formatMessage]);
};

////////////////////////////////////////////////////////////////////////////////////////////////

const animalTypeIntlMapping: SelfReferencedMapping = {
  //
  '01': IntlKeys.animalTypeGiltOrCastratedBoar,
  '02': IntlKeys.animalTypeBoar,
};
export const useAnimalTypeMapper = () => {
  const { formatMessage } = useIntl();
  return useMemo(() => configureMappedValueGetter(animalTypeIntlMapping, formatMessage), [formatMessage]);
};

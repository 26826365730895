import { WATER_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/waterConsumptionRates/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = WATER_CONSUMPTION_RATES_FIELDS.WaterConsumptionRates;

export const getWaterConsumptionRatesDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.householdPeopleQuantity.serialized,
        extractPropName: columns.householdPeopleQuantity.view,
      }),
      new DisplayHeader({
        headerName: columns.waterConsumption.serialized,
        extractPropName: columns.waterConsumption.view,
      }),
    ]),
  ]);
};

import React from 'react';
import styled from 'styled-components';
import { FormControl, FormControlProps } from '@material-ui/core';

export const StyledFormControl = styled((props: FormControlProps<'fieldset'>) => (
  <FormControl component="fieldset" {...props} />
))`
  && {
    position: relative;
    width: 100%;
    min-width: 290px;
    max-width: 100%;
  }
`;

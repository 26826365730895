import { ENTITIES_LIST_FIELDS } from 'lib/excel/config/referenceTables/entitiesList/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = ENTITIES_LIST_FIELDS.EntitiesList;

export const getEntitiesListDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.entityId.serialized, extractPropName: columns.entityId.view }),
      new DisplayHeader({ headerName: columns.entityName.serialized, extractPropName: columns.entityName.view }),
      new DisplayHeader({ headerName: columns.country.serialized, extractPropName: columns.country.view }),
      new DisplayHeader({ headerName: columns.businessUnit.serialized, extractPropName: columns.businessUnit.view }),
    ]),
  ]);
};

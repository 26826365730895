import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo1DigitsFloating } from 'lib/helpers/renderHelpers';

import { PIG_HOUSING_EMISSION_FACTORS } from 'store/entities/referenceTables/pigHousingEmissionFactors/config';

const { columns, view, serialized } = PIG_HOUSING_EMISSION_FACTORS.PigHousingEmissionFactors;

export const pigHousingEmissionFactorsConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.livestockCategory.view,
          field: columns.livestockCategory.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.housingSystem.view,
          field: columns.housingSystem.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.yearValue.view,
          field: columns.yearValue.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.emissionValue.view,
          field: columns.emissionValue.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.emissionValue.serialized] && roundTo1DigitsFloating(data[columns.emissionValue.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.unit.view,
          field: columns.unit.serialized,
        },
      }),
    ],
  }),
]);

import { IntlKeys } from 'lib/localization/keys/__keys';

enum Cl650Original {
  Cl650 = 'cl650',
}

enum Cl650Serialized {
  Cl650 = 'Cl650',
}

enum Cl650Unserialized {
  Cl650 = 'Cl650',
}

enum Cl650OriginalHeaders {
  'code' = 'code',
  'description' = 'description',
  'note' = 'notes',
}

enum Cl650SerializedHeaders {
  'code' = 'code',
  'description' = 'description',
  'note' = 'note',
}
enum Cl650UnserializedHeaders {
  'code' = 'code',
  'description' = 'description',
  'note' = 'notes',
}

export const CL650_FIELDS = {
  [Cl650Unserialized.Cl650]: {
    original: Cl650Original.Cl650,
    serialized: Cl650Serialized.Cl650,
    unserialized: Cl650Unserialized.Cl650,
    view: IntlKeys.referenceTablesCl650,
    viewName: 'cl650',

    columns: {
      [Cl650SerializedHeaders.code]: {
        original: Cl650OriginalHeaders.code,
        serialized: Cl650SerializedHeaders.code,
        unserialized: Cl650UnserializedHeaders.code,
        view: IntlKeys.referenceTablesCl650Code,
      },
      [Cl650SerializedHeaders.description]: {
        original: Cl650OriginalHeaders.description,
        serialized: Cl650SerializedHeaders.description,
        unserialized: Cl650UnserializedHeaders.description,
        view: IntlKeys.referenceTablesCl650Description,
      },
      [Cl650SerializedHeaders.note]: {
        original: Cl650OriginalHeaders.note,
        serialized: Cl650SerializedHeaders.note,
        unserialized: Cl650UnserializedHeaders.note,
        view: IntlKeys.referenceTablesCl650Note,
      },
    },
  },
};

import { ToggleTableHeaderDTO } from 'store/dto/ToggleTableHeaderDTO';

import { HybridView } from './HybridView';
import { TableConfig } from './TableConfig';

export interface TableColumnDescriptor {
  id: string;
}

export interface TabColumnsConfig {
  [tabName: string]: TableColumnDescriptor[];
}

export interface TableSettings {
  [tableName: string]: TabColumnsConfig;
}

export interface ChangeHeadersOrder {
  draggedToStart: boolean;
  source: HybridView;
  destination: HybridView;
  tableId: string;
  tabId: string;
}

export class TableColumnsGenerator {
  private constructor() {}

  private static getConfigById = (tableSettings: TableSettings, tableId: string): TabColumnsConfig => {
    return tableSettings && tableSettings[tableId];
  };

  private static hasConfig = (tableSettings: TableSettings, configName: string) => Boolean(tableSettings && tableSettings[configName]);

  static hasValidConfig = (tableSettings: TableSettings, tableConfig: TableConfig): boolean => {
    if (!TableColumnsGenerator.hasConfig(tableSettings, tableConfig.id)) return false;

    const currentTableConfig = TableColumnsGenerator.getConfigById(tableSettings, tableConfig.id);

    // make sure all tabs exist
    return tableConfig.tabs.reduce<boolean>((acc, { id }) => {
      return acc && currentTableConfig.hasOwnProperty(id);
    }, true);
  };

  public static emptySettings = (): TableSettings => ({});
  public static defaultSettings = (tableConfig: TableConfig): TableSettings => {
    return {
      [tableConfig.id]: tableConfig.tabs.reduce<Record<string, TableColumnDescriptor[]>>((acc, tab) => {
        acc[tab.id] = tab.defaultColumnConfig
          ? tab.defaultColumnConfig
          : tab.getTableViewHeaders().map(({ settings: { field = '' } }) => ({ id: field }));

        return acc;
      }, {}),
    };
  };

  public static toggleHeader = (
    tableSettings: TableSettings,
    { headerSettings, tabId, tableId }: ToggleTableHeaderDTO,
  ): TableSettings => {
    const tableConfig = TableColumnsGenerator.getConfigById(tableSettings, tableId);
    const tabConfig = tableConfig[tabId];

    const foundHeader = tabConfig.find((tabHeaderSettings) => tabHeaderSettings.id === headerSettings.id);

    if (foundHeader) {
      tableConfig[tabId] = tableConfig[tabId].filter((tabHeaderSettings) => tabHeaderSettings.id !== headerSettings.id);
    } else {
      const newTableConfig: any[] = []
      const tableMenu = tableConfig[tabId].find(tc => tc.id === 'table-menu')
      if (tableMenu) {
        newTableConfig.push(tableMenu);
      }
      newTableConfig.push(headerSettings, ...tableConfig[tabId].filter(tc => tc.id !== 'table-menu'));
      tableConfig[tabId] = newTableConfig;
    }

    return { ...tableSettings };
  };

  public static changeHeadersOrder = (
    tableSettings: TableSettings,
    { source, destination, tabId, tableId, draggedToStart }: ChangeHeadersOrder,
  ): TableSettings => {
    const tableConfig = TableColumnsGenerator.getConfigById(tableSettings, tableId);
    const tabConfig = tableConfig[tabId];

    const sourceIndex = tabConfig.findIndex((tabHeaderSettings) => tabHeaderSettings.id === source.id);
    const destinationIndex = tabConfig.findIndex((tabHeaderSettings) => tabHeaderSettings.id === destination.id);

    if (sourceIndex !== -1 && destinationIndex !== -1) {
      const finalDestinationIndex = draggedToStart
        ? 0
        : sourceIndex > destinationIndex
        ? destinationIndex + 1
        : destinationIndex;

      tabConfig.splice(sourceIndex, 1);
      tabConfig.splice(finalDestinationIndex, 0, { id: source.id });
    }

    return { ...tableSettings };
  };
}

import { IntlKeys } from 'lib/localization/keys';

enum GroupGroupsSerialized {
  GroupGroups = 'GroupGroups',
}

enum GroupGroupsUnserialized {
  GroupGroups = 'GroupGroups',
}

export enum GroupGroupsSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  groupIdentifier = 'groupIdentifier',
  groupName = 'groupName',
  dateEnd = 'dateEnd',
  notes = 'notes',
  barnIdentifier = 'barnIdentifier',
  roomIdentifier = 'roomIdentifier',
  penIdentifier = 'penIdentifier',
  groupDatetime = 'groupDatetime',
  firstDeliveryGrowthExp = 'firstDeliveryGrowthExp',
  lastDeliveryGrowthExp = 'lastDeliveryGrowthExp',
  firstDeliveryPerc = 'firstDeliveryPerc',
  lastDeliveryPerc = 'lastDeliveryPerc',
  firstDeliveryWeight = 'firstDeliveryWeight',
  lastDeliveryWeight = 'lastDeliveryWeight',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupGroupsUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  groupIdentifier: 'group_identifier',
  groupName: 'group_name',
  dateEnd: 'date_end',
  notes: 'notes',
  barnIdentifier: 'barn_identifier',
  roomIdentifier: 'room_identifier',
  penIdentifier: 'pen_identifier',
  groupDatetime: 'group_datetime',
  firstDeliveryGrowthExp: 'first_delivery_growth_exp',
  lastDeliveryGrowthExp: 'last_delivery_growth_exp',
  firstDeliveryPerc: 'first_delivery_perc',
  lastDeliveryPerc: 'last_delivery_perc',
  firstDeliveryWeight: 'first_delivery_weight',
  lastDeliveryWeight: 'last_delivery_weight',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_GROUPS = {
  [GroupGroupsSerialized.GroupGroups]: {
    serialized: GroupGroupsSerialized.GroupGroups,
    unserialized: GroupGroupsUnserialized.GroupGroups,
    view: IntlKeys.farmManagementDataGroupsList,

    columns: {
      [GroupGroupsSerializedHeaders.id]: {
        serialized: GroupGroupsSerializedHeaders.id,
        unserialized: GroupGroupsUnserializedHeaders.id,
      },
      [GroupGroupsSerializedHeaders.companyIdentifier]: {
        serialized: GroupGroupsSerializedHeaders.companyIdentifier,
        unserialized: GroupGroupsUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupGroupsSerializedHeaders.farmIdentifier]: {
        serialized: GroupGroupsSerializedHeaders.farmIdentifier,
        unserialized: GroupGroupsUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupGroupsSerializedHeaders.groupIdentifier]: {
        serialized: GroupGroupsSerializedHeaders.groupIdentifier,
        unserialized: GroupGroupsUnserializedHeaders.groupIdentifier,
        view: IntlKeys.farmManagementDataGroupId,
      },
      [GroupGroupsSerializedHeaders.groupName]: {
        serialized: GroupGroupsSerializedHeaders.groupName,
        unserialized: GroupGroupsUnserializedHeaders.groupName,
        view: IntlKeys.farmManagementDataGroupName,
      },
      [GroupGroupsSerializedHeaders.dateEnd]: {
        serialized: GroupGroupsSerializedHeaders.dateEnd,
        unserialized: GroupGroupsUnserializedHeaders.dateEnd,
        view: IntlKeys.farmManagementDataDateEnd,
      },
      [GroupGroupsSerializedHeaders.notes]: {
        serialized: GroupGroupsSerializedHeaders.notes,
        unserialized: GroupGroupsUnserializedHeaders.notes,
        view: IntlKeys.farmManagementDataNotes,
      },
      [GroupGroupsSerializedHeaders.barnIdentifier]: {
        serialized: GroupGroupsSerializedHeaders.barnIdentifier,
        unserialized: GroupGroupsUnserializedHeaders.barnIdentifier,
        view: IntlKeys.farmManagementDataBarnId,
      },
      [GroupGroupsSerializedHeaders.roomIdentifier]: {
        serialized: GroupGroupsSerializedHeaders.roomIdentifier,
        unserialized: GroupGroupsUnserializedHeaders.roomIdentifier,
        view: IntlKeys.farmManagementDataRoomId,
      },
      [GroupGroupsSerializedHeaders.penIdentifier]: {
        serialized: GroupGroupsSerializedHeaders.penIdentifier,
        unserialized: GroupGroupsUnserializedHeaders.penIdentifier,
        view: IntlKeys.farmManagementDataPenId,
      },
      [GroupGroupsSerializedHeaders.groupDatetime]: {
        serialized: GroupGroupsSerializedHeaders.groupDatetime,
        unserialized: GroupGroupsUnserializedHeaders.groupDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupGroupsSerializedHeaders.firstDeliveryGrowthExp]: {
        serialized: GroupGroupsSerializedHeaders.firstDeliveryGrowthExp,
        unserialized: GroupGroupsUnserializedHeaders.firstDeliveryGrowthExp,
        view: IntlKeys.farmManagementDataFirstDeliveryGrowthExp,
      },
      [GroupGroupsSerializedHeaders.lastDeliveryGrowthExp]: {
        serialized: GroupGroupsSerializedHeaders.lastDeliveryGrowthExp,
        unserialized: GroupGroupsUnserializedHeaders.lastDeliveryGrowthExp,
        view: IntlKeys.farmManagementDataLastDeliveryGrowthExp,
      },
      [GroupGroupsSerializedHeaders.firstDeliveryPerc]: {
        serialized: GroupGroupsSerializedHeaders.firstDeliveryPerc,
        unserialized: GroupGroupsUnserializedHeaders.firstDeliveryPerc,
        view: IntlKeys.farmManagementDataFirstDeliveryPer,
      },
      [GroupGroupsSerializedHeaders.lastDeliveryPerc]: {
        serialized: GroupGroupsSerializedHeaders.lastDeliveryPerc,
        unserialized: GroupGroupsUnserializedHeaders.lastDeliveryPerc,
        view: IntlKeys.farmManagementDataLastDeliveryPerc,
      },
      [GroupGroupsSerializedHeaders.firstDeliveryWeight]: {
        serialized: GroupGroupsSerializedHeaders.firstDeliveryWeight,
        unserialized: GroupGroupsUnserializedHeaders.firstDeliveryWeight,
        view: IntlKeys.farmManagementDataFirstDeliveryWeight,
      },
      [GroupGroupsSerializedHeaders.lastDeliveryWeight]: {
        serialized: GroupGroupsSerializedHeaders.lastDeliveryWeight,
        unserialized: GroupGroupsUnserializedHeaders.lastDeliveryWeight,
        view: IntlKeys.farmManagementDataLastDeliveryWeight,
      },
      [GroupGroupsSerializedHeaders.ubnList]: {
        serialized: GroupGroupsSerializedHeaders.ubnList,
        unserialized: GroupGroupsUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupGroupsSerializedHeaders.kvkList]: {
        serialized: GroupGroupsSerializedHeaders.kvkList,
        unserialized: GroupGroupsUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupGroupsSerializedHeaders.registrationNameList]: {
        serialized: GroupGroupsSerializedHeaders.registrationNameList,
        unserialized: GroupGroupsUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper } from 'lib/http/utils';

import { FARM_MANAGEMENT_PASSPORTS, FARM_MANAGEMENT_PASSPORTS_SUBMIT } from './config';

export type FarmManagementPassportPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  notes: string;
  user_passport_id: number;
  updated_at: string;
  updated_by: {
    full_name: string;
  };
};

export type FarmManagementPassportSerialized = {
  id?: number;
  companyId: string;
  farmId: string;
  notes: string;
  userPassportId: number;
  updatedAt?: string;
  updatedBy?: string;
};

export type FarmManagementPassportSubmitPayload = Omit<
  FarmManagementPassportPayload,
  'id' | 'updated_at' | 'updated_by' | 'user_passport_id'
>;
export type FarmManagementPassportSubmitSerialized = Omit<FarmManagementPassportSerialized, 'userPassportId'>;

const { columns: defaultColumns } = FARM_MANAGEMENT_PASSPORTS.FarmManagementPassports;
const { columns: submitColumns } = FARM_MANAGEMENT_PASSPORTS_SUBMIT.FarmManagementPassportsSubmit;

const getDefaultSerializer = (data: DocumentData<FarmManagementPassportPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(
      FARM_MANAGEMENT_PASSPORTS.FarmManagementPassports.unserialized,
      FARM_MANAGEMENT_PASSPORTS.FarmManagementPassports.serialized,
      [
        new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
        new CellSerializer(defaultColumns.companyId.unserialized, defaultColumns.companyId.serialized),
        new CellSerializer(defaultColumns.farmId.unserialized, defaultColumns.farmId.serialized),
        new CellSerializer(defaultColumns.notes.unserialized, defaultColumns.notes.serialized),
        new CellSerializer(defaultColumns.updatedAt.unserialized, defaultColumns.updatedAt.serialized),
        new CellSerializer(defaultColumns.updatedBy.unserialized, defaultColumns.updatedBy.serialized),
      ],
    ),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<FarmManagementPassportSubmitSerialized[]>) => {
  return new DocumentSerializer<FarmManagementPassportSubmitPayload, FarmManagementPassportSubmitSerialized>(data, [
    new SheetSerializer(
      FARM_MANAGEMENT_PASSPORTS_SUBMIT.FarmManagementPassportsSubmit.serialized,
      FARM_MANAGEMENT_PASSPORTS_SUBMIT.FarmManagementPassportsSubmit.unserialized,
      [
        new CellSerializer(submitColumns.companyId.unserialized, submitColumns.companyId.serialized),
        new CellSerializer(submitColumns.farmId.unserialized, submitColumns.farmId.serialized),
        new CellSerializer(
          submitColumns.notes.unserialized,
          submitColumns.notes.serialized,
          undefined,
          undefined,
          null,
        ),
      ],
    ),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<FarmManagementPassportSerialized[]>) => {
  return new DocumentSerializer<FarmManagementPassportSerialized, FarmManagementPassportSubmitSerialized>(data, [
    new SheetSerializer(
      FARM_MANAGEMENT_PASSPORTS_SUBMIT.FarmManagementPassportsSubmit.serialized,
      FARM_MANAGEMENT_PASSPORTS_SUBMIT.FarmManagementPassportsSubmit.unserialized,
      [
        new CellSerializer(defaultColumns.id.serialized, submitColumns.id.serialized),
        new CellSerializer(defaultColumns.companyId.serialized, submitColumns.companyId.serialized),
        new CellSerializer(defaultColumns.farmId.serialized, submitColumns.farmId.serialized),
        new CellSerializer(
          defaultColumns.notes.serialized,
          submitColumns.notes.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.updatedAt.serialized,
          submitColumns.updatedAt.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.updatedBy.serialized,
          submitColumns.updatedBy.serialized,
          undefined,
          undefined,
          undefined,
        ),
      ],
    ),
  ]);
};

export const serializeFarmManagementPassports = (serverData: ResourcesWrapper<FarmManagementPassportPayload>) => {
  const { unserialized } = FARM_MANAGEMENT_PASSPORTS.FarmManagementPassports;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const unserializeFarmManagementPassportForSubmit = (
  resource: FarmManagementPassportSubmitSerialized,
): FarmManagementPassportSubmitPayload => {
  const { serialized, unserialized } = FARM_MANAGEMENT_PASSPORTS_SUBMIT.FarmManagementPassportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeFarmManagementPassportStoSS = (resource: FarmManagementPassportSerialized) => {
  const { serialized, unserialized } = FARM_MANAGEMENT_PASSPORTS_SUBMIT.FarmManagementPassportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

import { CFP_CO2_EMISSION_FACTOR } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = CFP_CO2_EMISSION_FACTOR.CFP_CO2EmissionFactors;

export const getCfpCo2EmissionFactorDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.emissionSource.serialized,
        extractPropName: columns.emissionSource.view,
      }),
      new DisplayHeader({ headerName: columns.co2Volume.serialized, extractPropName: columns.co2Volume.view }),
      new DisplayHeader({ headerName: columns.reference.serialized, extractPropName: columns.reference.view }),
    ]),
  ]);
};

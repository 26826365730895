import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_ANIMAL_SCORE } from './config';

export type CodesAnimalScorePayload = {
  id: number;
  company_identifier: string;
  animal_score_identifier: string;
  abbreviation: string;
  description: string;
  note: string;
  animal_score_datetime: string;
  code_group: string;
  score_end: string;
  sows: string;
  boars: string;
  weaners: string;
  sucklers: string;
  replstock: string;
  finishers: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type CodesAnimalScoreSerialized = {
  id: number;
  companyIdentifier: string;
  animalScoreIdentifier: string;
  abbreviation: string;
  description: string;
  note: string;
  animalScoreDatetime: string;
  codeGroup: string;
  scoreEnd: string;
  sows: string;
  boars: string;
  weaners: string;
  sucklers: string;
  replstock: string;
  finishers: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = CODES_ANIMAL_SCORE.CodesAnimalScore;

const getDefaultSerializer = (data: DocumentData<CodesAnimalScorePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.animalScoreIdentifier.unserialized, columns.animalScoreIdentifier.serialized),
      new CellSerializer(columns.abbreviation.unserialized, columns.abbreviation.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.note.unserialized, columns.note.serialized),
      new CellSerializer(columns.animalScoreDatetime.unserialized, columns.animalScoreDatetime.serialized),
      new CellSerializer(columns.codeGroup.unserialized, columns.codeGroup.serialized),
      new CellSerializer(columns.scoreEnd.unserialized, columns.scoreEnd.serialized),
      new CellSerializer(columns.sows.unserialized, columns.sows.serialized),
      new CellSerializer(columns.boars.unserialized, columns.boars.serialized),
      new CellSerializer(columns.weaners.unserialized, columns.weaners.serialized),
      new CellSerializer(columns.sucklers.unserialized, columns.sucklers.serialized),
      new CellSerializer(columns.replstock.unserialized, columns.replstock.serialized),
      new CellSerializer(columns.finishers.unserialized, columns.finishers.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeCodesAnimalScore = (serverData: ResourcesWrapper<CodesAnimalScorePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

enum FarmManagementPassportsSerialized {
  FarmManagementPassports = 'FarmManagementPassports',
  FarmManagementPassportsSubmit = 'FarmManagementPassportsSubmit',
}

enum FarmManagementPassportsUnserialized {
  FarmManagementPassports = 'FarmManagementPassports',
  FarmManagementPassportsSubmit = 'FarmManagementPassportsSubmit',
}

export enum FarmManagementPassportsSerializedHeaders {
  id = 'id',
  companyId = 'companyId',
  farmId = 'farmId',
  notes = 'notes',
  userPassportId = 'userPassportId',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
}

export const FarmManagementPassportsUnserializedHeaders = {
  id: 'id',
  companyId: 'company_identifier',
  farmId: 'farm_identifier',
  notes: 'notes',
  userPassportId: 'user_passport_id',
  updatedAt: 'updated_at',
  updatedBy: ['updated_by', 'full_name'],
} as const;

export enum FarmManagementPassportsSubmitSerializedHeaders {
  id = 'id',
  companyId = 'companyId',
  farmId = 'farmId',
  notes = 'notes',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
}

export const FarmManagementPassportsSubmitUnserializedHeaders = {
  id: 'id',
  companyId: 'company_identifier',
  farmId: 'farm_identifier',
  notes: 'notes',
  updatedAt: 'updated_at',
  updatedBy: ['updated_by', 'full_name'],
} as const;

export const FARM_MANAGEMENT_PASSPORTS = {
  [FarmManagementPassportsSerialized.FarmManagementPassports]: {
    serialized: FarmManagementPassportsSerialized.FarmManagementPassports,
    unserialized: FarmManagementPassportsUnserialized.FarmManagementPassports,

    columns: {
      [FarmManagementPassportsSerializedHeaders.id]: {
        serialized: FarmManagementPassportsSerializedHeaders.id,
        unserialized: FarmManagementPassportsUnserializedHeaders.id,
      },
      [FarmManagementPassportsSerializedHeaders.companyId]: {
        serialized: FarmManagementPassportsSerializedHeaders.companyId,
        unserialized: FarmManagementPassportsUnserializedHeaders.companyId,
      },
      [FarmManagementPassportsSerializedHeaders.farmId]: {
        serialized: FarmManagementPassportsSerializedHeaders.farmId,
        unserialized: FarmManagementPassportsUnserializedHeaders.farmId,
      },
      [FarmManagementPassportsSerializedHeaders.notes]: {
        serialized: FarmManagementPassportsSerializedHeaders.notes,
        unserialized: FarmManagementPassportsUnserializedHeaders.notes,
      },
      [FarmManagementPassportsSerializedHeaders.userPassportId]: {
        serialized: FarmManagementPassportsSerializedHeaders.userPassportId,
        unserialized: FarmManagementPassportsUnserializedHeaders.userPassportId,
      },
      [FarmManagementPassportsSerializedHeaders.updatedAt]: {
        serialized: FarmManagementPassportsSerializedHeaders.updatedAt,
        unserialized: FarmManagementPassportsUnserializedHeaders.updatedAt,
      },
      [FarmManagementPassportsSerializedHeaders.updatedBy]: {
        serialized: FarmManagementPassportsSerializedHeaders.updatedBy,
        unserialized: FarmManagementPassportsUnserializedHeaders.updatedBy,
      },
    },
  },
};

export const FARM_MANAGEMENT_PASSPORTS_SUBMIT = {
  [FarmManagementPassportsSerialized.FarmManagementPassportsSubmit]: {
    serialized: FarmManagementPassportsSerialized.FarmManagementPassportsSubmit,
    unserialized: FarmManagementPassportsUnserialized.FarmManagementPassportsSubmit,

    columns: {
      [FarmManagementPassportsSubmitSerializedHeaders.id]: {
        serialized: FarmManagementPassportsSubmitSerializedHeaders.id,
        unserialized: FarmManagementPassportsSubmitUnserializedHeaders.id,
      },
      [FarmManagementPassportsSubmitSerializedHeaders.companyId]: {
        serialized: FarmManagementPassportsSubmitSerializedHeaders.companyId,
        unserialized: FarmManagementPassportsSubmitUnserializedHeaders.companyId,
      },
      [FarmManagementPassportsSubmitSerializedHeaders.farmId]: {
        serialized: FarmManagementPassportsSubmitSerializedHeaders.farmId,
        unserialized: FarmManagementPassportsSubmitUnserializedHeaders.farmId,
      },
      [FarmManagementPassportsSubmitSerializedHeaders.notes]: {
        serialized: FarmManagementPassportsSubmitSerializedHeaders.notes,
        unserialized: FarmManagementPassportsSubmitUnserializedHeaders.notes,
      },
      [FarmManagementPassportsSubmitSerializedHeaders.updatedAt]: {
        serialized: FarmManagementPassportsSubmitSerializedHeaders.updatedAt,
        unserialized: FarmManagementPassportsSubmitUnserializedHeaders.updatedAt,
      },
      [FarmManagementPassportsSubmitSerializedHeaders.updatedBy]: {
        serialized: FarmManagementPassportsSubmitSerializedHeaders.updatedBy,
        unserialized: FarmManagementPassportsSubmitUnserializedHeaders.updatedBy,
      },
    },
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { YES_NO } from 'store/entities/referenceTables/yesNo/config';

const { columns, view, serialized } = YES_NO.YesNo;

export const yesNoTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.yesNoCode.view,
          field: columns.yesNoCode.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.yesNoCode.serialized] && roundToInteger(data[columns.yesNoCode.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.yesNoName.view,
          field: columns.yesNoName.serialized,
        },
      }),
    ],
  }),
]);

import React, { useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'clsx';

import { ArrowRight, SvgIconComponent } from '@material-ui/icons';
import { createStyles, ListItem, ListItemIcon, ListItemText, ButtonBase, Theme, makeStyles } from '@material-ui/core';

interface OwnProps {
  title: string;
  icon: SvgIconComponent;
  url?: string;
  isOpen?: boolean;
  nestingLevel?: number;
  withArrow?: boolean;
  subItems?: OwnProps[];
  toggleOpen: () => void;
  onMenuItemSelect?: () => void;
  onClick?: (e: React.MouseEvent) => void;
}

const MenuItem: React.FC<OwnProps> = (props) => {
  const { title, icon: Icon, url, withArrow = false, toggleOpen, onMenuItemSelect, onClick } = props;

  const history = useHistory();
  const classes = useStyles(props);
  const { formatMessage } = useIntl();

  const closeMenuTimer = useRef<ReturnType<typeof setTimeout>>();

  const delayCloseMenu = useCallback(() => {
    clearTimeout(closeMenuTimer.current);
    if (onMenuItemSelect) {
      closeMenuTimer.current = setTimeout(onMenuItemSelect, 400);
    }
  }, [onMenuItemSelect]);

  const preventCloseMenuByTimer = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(closeMenuTimer.current);
  }, []);

  const { arrowHandlers } = useMemo(
    () => ({
      arrowHandlers: {
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          toggleOpen();
        },
      },
    }),
    [toggleOpen],
  );

  const wrapperHandlers = useMemo(() => {
    const onDoubleClick = (e: React.MouseEvent) => {
      preventCloseMenuByTimer(e);
      toggleOpen();
    };

    return url || onClick
      ? {
          onClick: (e: React.MouseEvent) => {
            delayCloseMenu();

            if (url) {
              history.push(url);
            } else {
              onClick && onClick(e);
            }
          },
          onDoubleClick,
        }
      : {
          onDoubleClick,
        };
  }, [url, onClick, delayCloseMenu, preventCloseMenuByTimer, toggleOpen, history]);

  return (
    <ListItem data-testid={title} className={classes.root} button {...wrapperHandlers}>
      {(Icon || withArrow) && (
        <ListItemIcon className={classes.iconContainer}>
          {withArrow && (
            <ButtonBase data-testid={`${title}-open`} className={classes.arrowButton} {...arrowHandlers}>
              <ArrowRight className={cx(classes.arrow, classes.icon)} />
            </ButtonBase>
          )}

          {Icon && <Icon className={classes.icon} />}
        </ListItemIcon>
      )}
      <ListItemText primary={formatMessage({ id: title })} />
    </ListItem>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, OwnProps>({
    root: ({ nestingLevel = 0 }) => ({
      paddingLeft: theme.spacing(3 + 3 * nestingLevel),
      paddingTop: 4,
      paddingBottom: 4,

      borderTopRightRadius: 99,
      borderBottomRightRadius: 99,
      color: theme.colors.dimGray,
    }),

    iconContainer: {
      minWidth: 40,
      position: 'relative',
    },

    icon: {
      color: theme.colors.dimGray,
    },

    arrowButton: {
      position: 'absolute',
      right: '100%',
      borderRadius: theme.shape.borderRadius,

      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },

    arrow: ({ isOpen }) => ({
      transition: '0.25s ease transform',
      transform: `rotate(${isOpen ? '90deg' : 0})`,
    }),
  }),
);

export default React.memo(MenuItem);

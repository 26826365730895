import { GREENHOUSE_GAS_EMISSION } from 'lib/excel/config/referenceTables/greenhouseGasEmission/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = GREENHOUSE_GAS_EMISSION.GreenhouseGasEmission;

export const getGreenhouseGasEmissionDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.country.original),
      new CellValidator(columns.location.original),
      new CellValidator(columns.category.original),
      new CellValidator(columns.account.original),
      new CellValidator(columns.extraExternalId.original),
      new CellValidator(columns.name.original),
      new CellValidator(columns.inputUnit.original),
      new CellValidator(columns.conversionFactor.original),
      new CellValidator(columns.conversionFormula.original),
      new CellValidator(columns.emissionFactors.original),
      new CellValidator(columns.co2Unit.original),
    ]),
  ]);
};

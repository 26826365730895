import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import { SUPPLIERS_NUMBERS } from 'store/entities/referenceTables/suppliersNumbers/config';

const { columns, view, serialized } = SUPPLIERS_NUMBERS.SuppliersNumbers;

export const suppliersNumbersTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.codeList.view,
          field: columns.codeList.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.supplierNumber.view,
          field: columns.supplierNumber.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.feedSupplierName.view,
          field: columns.feedSupplierName.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.entryDate.view,
          field: columns.entryDate.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.dischargeDate.view,
          field: columns.dischargeDate.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.remark.view,
          field: columns.remark.serialized,
        },
      }),
    ],
  }),
]);

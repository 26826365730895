enum ValidationErrorType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export interface ValidationErrorI {
  readonly type: ValidationErrorType;
  readonly message: string;
}

// @ts-ignore /* classname collides */
export class ValidationError implements ValidationErrorI {
  readonly type: ValidationErrorType = ValidationErrorType.ERROR;

  constructor(readonly message: string) {}
}

export class ValidationWarning implements ValidationErrorI {
  readonly type: ValidationErrorType = ValidationErrorType.WARNING;

  constructor(readonly message: string) {}
}

export class ValidationInfo implements ValidationErrorI {
  readonly type: ValidationErrorType = ValidationErrorType.INFO;

  constructor(readonly message: string) {}
}

export interface ErrorsCategories {
  readonly errors: Array<ValidationError>;
  readonly warnings: Array<ValidationWarning>;
  readonly infos: Array<ValidationWarning>;
}

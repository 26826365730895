import { IntlKeys } from 'lib/localization/keys';

enum ElectricityConsumptionRatesSerialized {
  ElectricityConsumptionRates = 'ElectricityConsumptionRates',
}

enum ElectricityConsumptionRatesUnserialized {
  ElectricityConsumptionRates = 'ElectricityConsumptionRates',
}

export enum ElectricityConsumptionRatesSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  householdPeopleQuantity = 'householdPeopleQuantity',
  electricityConsumption = 'electricityConsumption',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const ElectricityConsumptionRatesUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['electricity_consumption_rate_upload', 'id'],
  householdPeopleQuantity: 'household_people_quantity',
  electricityConsumption: 'electricity_consumption',
  uploadType: ['electricity_consumption_rate_upload', 'upload_type'],
  filename: ['electricity_consumption_rate_upload', 'file_name'],
  status: ['electricity_consumption_rate_upload', 'status'],
  validDateFrom: ['electricity_consumption_rate_upload', 'valid_from'],
  validDateTo: ['electricity_consumption_rate_upload', 'valid_to'],
  notes: ['electricity_consumption_rate_upload', 'notes'],
  uploadedAt: ['electricity_consumption_rate_upload', 'uploaded_at'],
  uploadedBy: ['electricity_consumption_rate_upload', 'uploaded_by', 'full_name'],
} as const;

export const ELECTRICITY_CONSUMPTION_RATES = {
  [ElectricityConsumptionRatesSerialized.ElectricityConsumptionRates]: {
    serialized: ElectricityConsumptionRatesSerialized.ElectricityConsumptionRates,
    unserialized: ElectricityConsumptionRatesUnserialized.ElectricityConsumptionRates,
    view: IntlKeys.referenceTablesElectricityConsumptionRates,

    columns: {
      [ElectricityConsumptionRatesSerializedHeaders.id]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.id,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.id,
      },
      [ElectricityConsumptionRatesSerializedHeaders.referenceUploadID]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.referenceUploadID,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.referenceUploadID,
      },
      [ElectricityConsumptionRatesSerializedHeaders.householdPeopleQuantity]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.householdPeopleQuantity,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.householdPeopleQuantity,
        view: IntlKeys.referenceTablesElectricityConsumptionRatesPeopleQuantity,
      },
      [ElectricityConsumptionRatesSerializedHeaders.electricityConsumption]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.electricityConsumption,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.electricityConsumption,
        view: IntlKeys.referenceTablesElectricityConsumptionRatesConsumption,
      },
      [ElectricityConsumptionRatesSerializedHeaders.status]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.status,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.status,
      },
      [ElectricityConsumptionRatesSerializedHeaders.uploadType]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.uploadType,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.uploadType,
      },
      [ElectricityConsumptionRatesSerializedHeaders.notes]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.notes,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.notes,
      },
      [ElectricityConsumptionRatesSerializedHeaders.filename]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.filename,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.filename,
      },
      [ElectricityConsumptionRatesSerializedHeaders.validDateFrom]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.validDateFrom,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.validDateFrom,
      },
      [ElectricityConsumptionRatesSerializedHeaders.validDateTo]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.validDateTo,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.validDateTo,
      },
      [ElectricityConsumptionRatesSerializedHeaders.uploadedAt]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.uploadedAt,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.uploadedAt,
      },
      [ElectricityConsumptionRatesSerializedHeaders.uploadedBy]: {
        serialized: ElectricityConsumptionRatesSerializedHeaders.uploadedBy,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import { PEN_LEVEL_FIELDS } from '../structure';
import { DocumentSerializer } from '../../../../serilizers/Document';
import { DocumentData, SheetSerializer } from '../../../../serilizers/Sheet';
import { CellSerializer } from '../../../../serilizers/Cell';

const { columns, serialized, original } = PEN_LEVEL_FIELDS.PenLevel;

export const serializePenLevelData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.penLevel.original, columns.penLevel.serialized),
      new CellSerializer(columns.penNameNL.original, columns.penNameNL.serialized),
      new CellSerializer(columns.penNameEN.original, columns.penNameEN.serialized),
    ]),
  ]);
};

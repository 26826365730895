enum LabelsSerialized {
  Labels = 'Labels',
  LabelsSubmit = 'LabelsSubmit',
}

enum LabelsUnserialized {
  Labels = 'Labels',
  LabelsSubmit = 'LabelsSubmit',
}

export enum LabelsSerializedHeaders {
  id = 'id',
  color = 'color',
  name = 'name',
  notes = 'notes',
  userPassportIds = 'userPassportIds',
  slaughters = 'slaughters',
  slaughterPriceCorrections = 'slaughterPriceCorrections',
  movementEvents = 'movementEvents',
  transports = 'transports',
}

export const LabelsUnserializedHeaders = {
  id: 'id',
  color: 'color',
  name: 'name',
  notes: 'notes',
  userPassportIds: 'user_passports',
  slaughters: 'slaughters',
  slaughterPriceCorrections: 'slaughter_price_corrections',
  movementEvents: 'movement_events',
  transports: 'transports',
} as const;

export enum LabelsSubmitSerializedHeaders {
  id = 'id',
  color = 'color',
  name = 'name',
  notes = 'notes',
  userPassportIds = 'userPassportIds',
  slaughterIds = 'slaughterIds',
  slaughterPriceCorrectionIds = 'slaughterPriceCorrectionIds',
  movementEventIds = 'movementEventIds',
  transportIds = 'transportIds',
}

export const LabelsSubmitUnserializedHeaders = {
  id: 'id',
  color: 'color',
  name: 'name',
  notes: 'notes',
  userPassportIds: 'user_passport_ids',
  slaughterIds: 'slaughter_ids',
  slaughterPriceCorrectionIds: 'slaughter_price_correction_ids',
  movementEventIds: 'movement_event_ids',
  transportIds: 'transport_ids',
} as const;

export const LABELS = {
  [LabelsSerialized.Labels]: {
    serialized: LabelsSerialized.Labels,
    unserialized: LabelsUnserialized.Labels,

    columns: {
      [LabelsSerializedHeaders.id]: {
        serialized: LabelsSerializedHeaders.id,
        unserialized: LabelsUnserializedHeaders.id,
      },
      [LabelsSerializedHeaders.color]: {
        serialized: LabelsSerializedHeaders.color,
        unserialized: LabelsUnserializedHeaders.color,
      },
      [LabelsSerializedHeaders.name]: {
        serialized: LabelsSerializedHeaders.name,
        unserialized: LabelsUnserializedHeaders.name,
      },
      [LabelsSerializedHeaders.notes]: {
        serialized: LabelsSerializedHeaders.notes,
        unserialized: LabelsUnserializedHeaders.notes,
      },
      [LabelsSerializedHeaders.userPassportIds]: {
        serialized: LabelsSerializedHeaders.userPassportIds,
        unserialized: LabelsUnserializedHeaders.userPassportIds,
      },
      [LabelsSerializedHeaders.slaughters]: {
        serialized: LabelsSerializedHeaders.slaughters,
        unserialized: LabelsUnserializedHeaders.slaughters,
      },
      [LabelsSerializedHeaders.slaughterPriceCorrections]: {
        serialized: LabelsSerializedHeaders.slaughterPriceCorrections,
        unserialized: LabelsUnserializedHeaders.slaughterPriceCorrections,
      },
      [LabelsSerializedHeaders.movementEvents]: {
        serialized: LabelsSerializedHeaders.movementEvents,
        unserialized: LabelsUnserializedHeaders.movementEvents,
      },
      [LabelsSerializedHeaders.transports]: {
        serialized: LabelsSerializedHeaders.transports,
        unserialized: LabelsUnserializedHeaders.transports,
      },
    },
  },
};

export const LABELS_SUBMIT = {
  [LabelsSerialized.LabelsSubmit]: {
    serialized: LabelsSerialized.LabelsSubmit,
    unserialized: LabelsUnserialized.LabelsSubmit,

    columns: {
      [LabelsSubmitSerializedHeaders.id]: {
        serialized: LabelsSubmitSerializedHeaders.id,
        unserialized: LabelsSubmitUnserializedHeaders.id,
      },
      [LabelsSubmitSerializedHeaders.color]: {
        serialized: LabelsSubmitSerializedHeaders.color,
        unserialized: LabelsSubmitUnserializedHeaders.color,
      },
      [LabelsSubmitSerializedHeaders.name]: {
        serialized: LabelsSubmitSerializedHeaders.name,
        unserialized: LabelsSubmitUnserializedHeaders.name,
      },
      [LabelsSubmitSerializedHeaders.notes]: {
        serialized: LabelsSubmitSerializedHeaders.notes,
        unserialized: LabelsSubmitUnserializedHeaders.notes,
      },
      [LabelsSubmitSerializedHeaders.userPassportIds]: {
        serialized: LabelsSubmitSerializedHeaders.userPassportIds,
        unserialized: LabelsSubmitUnserializedHeaders.userPassportIds,
      },
      [LabelsSubmitSerializedHeaders.slaughterIds]: {
        serialized: LabelsSubmitSerializedHeaders.slaughterIds,
        unserialized: LabelsSubmitUnserializedHeaders.slaughterIds,
      },
      [LabelsSubmitSerializedHeaders.slaughterPriceCorrectionIds]: {
        serialized: LabelsSubmitSerializedHeaders.slaughterPriceCorrectionIds,
        unserialized: LabelsSubmitUnserializedHeaders.slaughterPriceCorrectionIds,
      },
      [LabelsSubmitSerializedHeaders.movementEventIds]: {
        serialized: LabelsSubmitSerializedHeaders.movementEventIds,
        unserialized: LabelsSubmitUnserializedHeaders.movementEventIds,
      },
      [LabelsSubmitSerializedHeaders.transportIds]: {
        serialized: LabelsSubmitSerializedHeaders.transportIds,
        unserialized: LabelsSubmitUnserializedHeaders.transportIds,
      },
    },
  },
};

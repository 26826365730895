import { RAV_REFERENCE_LIST_FIELDS } from 'lib/excel/config/referenceTables/ravReferenceList/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = RAV_REFERENCE_LIST_FIELDS.RavReferenceList;

export const getRavReferenceListDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.animalCategory.original),
      new CellValidator(columns.description.original),
      new CellValidator(columns.efEm.original),
      new CellValidator(columns.housingType.original),
    ]),
  ]);
};

import { ResourcesWrapper } from 'lib/http/utils';

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { GENETICS } from 'lib/tables/config/genetics/config';

export interface GeneticPayload {
  id: number;
  ubn_producer: string;
  ubn_receiver: string;
  delivery_date: string;
  male_type: string;
  male_number: number;
  female_type: string;
  female_number: number;
}

const { columns, serialized, unserialized } = GENETICS.Genetics;
const { semenProducerUBN, ubn, deliveryDate, maleType, maleAmount, femaleType, femaleAmount } = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(semenProducerUBN.unserialized, semenProducerUBN.serialized),
      new CellSerializer(ubn.unserialized, ubn.serialized),
      new CellSerializer(deliveryDate.unserialized, deliveryDate.serialized),
      new CellSerializer(maleType.unserialized, maleType.serialized),
      new CellSerializer(maleAmount.unserialized, maleAmount.serialized),
      new CellSerializer(femaleType.unserialized, femaleType.serialized),
      new CellSerializer(femaleAmount.unserialized, femaleAmount.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeGenetic = (serverData: ResourcesWrapper<GeneticPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

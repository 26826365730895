import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { cloneDeep, merge } from 'lodash';
import MaterialTable from 'material-table';
import { Components } from 'material-table';

import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import { DataContext } from 'components/DataTable';
import { ViewProps } from 'components/DataTable/views';

import CustomGroupRow from './components/CustomGroupRow';
import { useLocalizationHook } from './localization';
import { useColumns } from './columns';

import CustomToolbar from './components/CustomToolbar';
import { MTableHeader, MTableBodyRow, MTableCell} from 'material-table';
// import CustomTableHeader from './components/CustomTableHeader';
import CustomPagination from './components/CustomPagination';
import CustomContainer from './components/CustomContainer';
import { stylesOptions } from './styles';
import { makeStyles } from '@material-ui/core';

interface OwnProps {
  containerProps?: object;
  components?: Components;
  onSelectionChange?: (data, rowData?) => void
}

const TableView: React.FC<ViewProps & OwnProps> = ({
  //
  data,
  isLoading,
  isCustomPagination,
  containerProps = {},
  components,
  onSelectionChange
}) => {
  const {
    //
    currentTab,
    page,
    setPage,
    pageSize,
    totalCount,
    pageSizeVariant,
    setItemsPerPage,
  } = useContext(DataContext);

  const { formatMessage } = useIntl();
  const tableRef = useRef<ObjectWithProps>(null);

  const { labelFunction, label, detailPanel, tableComponents } = currentTab;

  const params = useParams();
  const localization = useLocalizationHook();
  const history = useHistory();
  const [dataRendered, setDataRendered] = useState<boolean>(false);

  const { columns, onColumnDragged } = useColumns(tableRef);

  // init table state
  const query = new URLSearchParams(history.location.search);
  if (query.has('orderDirection') && query.get('orderDirection')) {
    const columnIndex = parseInt(query.get('orderBy') || '0')
    columns[columnIndex].defaultSort = query.get('orderDirection') as 'asc' | 'desc';
  }
  if (query.has('groupBy') && query.get('groupBy')) {
    const groupBy = JSON.parse(query.get('groupBy') || '');
    for (const column of groupBy) {
      if (columns[column.columnOrder]) {
        columns[column.columnOrder].defaultGroupOrder = column.groupOrder;
        columns[column.columnOrder].defaultGroupSort = column.groupSort;
      }
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const dataManager = tableRef.current?.dataManager as {
      changeCurrentPage: (page: number) => void;
      changePageSize: (page: number) => void;
      changeGroupExpand: (path) => void;
    }
    if (query.has('pageSize')) {
      dataManager.changePageSize(parseInt(query.get('pageSize') || '0'));
    }
    if (query.has('currentPage')) {
      dataManager.changeCurrentPage(parseInt(query.get('currentPage') || '0'));
    }
    const interval = setInterval(() => {
      if (tableRef?.current?.state.renderData.length > 0 && tableRef?.current?.state.pageSize !== 1000) {
        if (query.has('expandedGroups') && query.get('expandedGroups')) {
          const expandedGroups = JSON.parse(query.get('expandedGroups') || '');
          for (const path of expandedGroups) {
            dataManager.changeGroupExpand(path);
          }
        }
        setDataRendered(true);
        clearInterval(interval);
      }
    }, 200);
  }, []);

  setInterval(() => {
    // save table state exclude nested table (pageSize === 1000)
    if (tableRef?.current?.state && tableRef.current.state.pageSize !== 1000) {
      const recursive = (groups, currentPath, pathes) => {
        for (let i = 0; i < groups.length; i++) {
          const group = groups[i];
          if (group.isExpanded) {
            const path = cloneDeep(currentPath)
            path.push(i)
            pathes.push(path);
            recursive(group.groups, path, pathes);
          }
        }
      }
      const expandedGroups = [];
      recursive(tableRef.current.state.data, [], expandedGroups);

      const state = {
        currentPage: tableRef.current.state.currentPage,
        pageSize: tableRef.current.state.pageSize,
        orderBy: tableRef.current.state.orderBy,
        orderDirection: tableRef.current.state.orderDirection,
        searchText: tableRef.current.state.searchText,
        groupBy: JSON.stringify(tableRef.current.state.columns
          .filter(c => c.tableData.groupOrder !== undefined)
          .map(c => ({
            groupOrder: c.tableData.groupOrder,
            groupSort: c.tableData.groupSort,
            columnOrder: c.tableData.columnOrder
          }))),
        expandedGroups: JSON.stringify(expandedGroups)
      };
      const queryString = Object.entries(state)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&");
      const newLocation = history.location.pathname + '?' + queryString;
      const currentLocation = history.location.pathname + history.location.search;
      if (newLocation !== currentLocation) {
        const activeElement = document.activeElement;
        const getSearchInput = () => document.querySelector('.MuiToolbar-root input.MuiInputBase-input[aria-label="Search"]');
        let searchInput = getSearchInput();
        history.replace(newLocation);
        if (activeElement === searchInput) {
          let searchInput = getSearchInput();
          if (searchInput && (searchInput as any).focus) {
            (searchInput as any).focus();
          }
        }
      }
    }
  }, 200);

  const options = useMemo(
    () => {
      const query = new URLSearchParams(history.location.search);
      let searchText;
      if (query.has('searchText') && query.get('searchText')) {
        searchText = query.get('searchText');
      }
      return merge(
        {
          emptyRowsWhenPaging: false,
          minBodyHeight: 300,
          pageSizeOptions: pageSizeVariant,
          pageSize,
          ...stylesOptions,
          searchText
        },
        currentTab.tableOptions,
      )
    },
    [pageSize, pageSizeVariant, currentTab.tableOptions],
  );
  const classes = useStyles();
  const resultComponent = useMemo(
    () =>
      merge(
        {
          Toolbar: CustomToolbar,
          Header: props => <thead data-testid="table-header" className={classes.testIdWrapper}><MTableHeader {...props} /></thead>,
          Row: props => <MTableBodyRow data-testid={`table-row-${props.index}`} {...props}/>,
          Cell: props=> <MTableCell data-testid="table-cell" {...props}/>,
          Container: (props: Record<string, unknown>) => <CustomContainer {...props} {...containerProps} />,
          Pagination: CustomPagination,
          GroupRow: CustomGroupRow,
        },
        components,
        tableComponents,
      ),
    [components, tableComponents, containerProps],
  );

  // const handleSortingChange = useCallback((x, y) => {
  //   console.log(x, y, columns[x]);
  // }, []);

  const handleChangePage = useCallback(
    (_page) => {
      if (page !== _page) {
        setPage(_page);
      }
    },
    [page, setPage],
  );

  return (
    <>
    {dataRendered}
    <MaterialTable
      tableRef={tableRef}
      title={labelFunction(label, params, formatMessage)}
      //
      isLoading={isLoading}
      columns={columns}
      options={options}
      data={data}
      // Omit params when no backend pagination is used to leverage material-table pagination
      {...(isCustomPagination
        ? {
            page,
            totalCount,
          }
        : {})}
      //
      onChangeRowsPerPage={setItemsPerPage}
      onChangePage={handleChangePage}
      onColumnDragged={onColumnDragged}
      onSelectionChange={onSelectionChange}
      // onOrderChange={handleSortingChange}
      //
      components={resultComponent}
      detailPanel={detailPanel}
      localization={localization}
    />
    </>
  );
};

const useStyles = makeStyles(() => ({
  testIdWrapper:{
    display: "contents",
  }
}));

export default React.memo(TableView);

import React from 'react';
import Logo from 'assets/logo.png';
import { makeStyles, Theme } from '@material-ui/core';

interface SvgProps {
  className?: string;
  height?: number | string;
  width?: number | string;
  fill?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: 60
  }
}));

export const SimLogoSvg: React.FC<SvgProps> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <img
        className={classes.logo}
        src={Logo}
        alt='company logo'
        aria-label='company logo'
        data-testid="company-logo-login-page"
      />
    </div>
  )
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum AllocationManureOriginal {
  AllocationManure = 'Allocation_Manure',
}

enum AllocationManureSerialized {
  AllocationManure = 'AllocationManure',
}

enum AllocationManureUnserialized {
  AllocationManure = 'AllocationManure',
}

enum AllocationManureOriginalHeaders {
  'animalCategory' = 'Animal category',
  'manure' = 'Manure',
  'gas' = 'Gas',
  'electricity' = 'Electricity ',
  'water' = 'Water',
}

enum AllocationManureSerializedHeaders {
  'animalCategory' = 'animalCategory',
  'manure' = 'manure',
  'gas' = 'gas',
  'electricity' = 'electricity',
  'water' = 'water',
}
enum AllocationManureUnserializedHeaders {
  'animalCategory' = 'animal_category',
  'manure' = 'manure',
  'gas' = 'gas',
  'electricity' = 'electricity',
  'water' = 'water',
}

export const ALLOCATION_MANURE_FIELDS = {
  [AllocationManureUnserialized.AllocationManure]: {
    original: AllocationManureOriginal.AllocationManure,
    serialized: AllocationManureSerialized.AllocationManure,
    unserialized: AllocationManureUnserialized.AllocationManure,
    view: IntlKeys.referenceTablesAllocationManure,
    viewName: 'Allocation Manure',

    columns: {
      [AllocationManureSerializedHeaders.animalCategory]: {
        original: AllocationManureOriginalHeaders.animalCategory,
        serialized: AllocationManureSerializedHeaders.animalCategory,
        unserialized: AllocationManureUnserializedHeaders.animalCategory,
        view: IntlKeys.referenceTablesAllocationManureAnimalCategory,
      },
      [AllocationManureSerializedHeaders.manure]: {
        original: AllocationManureOriginalHeaders.manure,
        serialized: AllocationManureSerializedHeaders.manure,
        unserialized: AllocationManureUnserializedHeaders.manure,
        view: IntlKeys.referenceTablesAllocationManureManure,
      },
      [AllocationManureSerializedHeaders.gas]: {
        original: AllocationManureOriginalHeaders.gas,
        serialized: AllocationManureSerializedHeaders.gas,
        unserialized: AllocationManureUnserializedHeaders.gas,
        view: IntlKeys.referenceTablesAllocationManureGas,
      },
      [AllocationManureSerializedHeaders.electricity]: {
        original: AllocationManureOriginalHeaders.electricity,
        serialized: AllocationManureSerializedHeaders.electricity,
        unserialized: AllocationManureUnserializedHeaders.electricity,
        view: IntlKeys.referenceTablesAllocationManureElectricity,
      },
      [AllocationManureSerializedHeaders.water]: {
        original: AllocationManureOriginalHeaders.water,
        serialized: AllocationManureSerializedHeaders.water,
        unserialized: AllocationManureUnserializedHeaders.water,
        view: IntlKeys.referenceTablesAllocationManureWater,
      },
    },
  },
};

import { ResourceWrapper, ResourcesWrapper } from 'lib/http/utils';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer, ObjectWithProps, PropertyPath } from 'lib/excel/serilizers/Cell';
import { get } from 'lodash';
import { FarmsUpdatesLogSerialized } from 'lib/tables/config/farms/updates/config';
import { Enum } from "../../pages/farmerInput/components/StoredValuesAutocomplete";

const FAKE_TITLE = 'FAKE_TITLE';

enum FarmStablesUnserializedHeader {
  id = 'id',
  stableId = 'stable_id',
  sectionSerialNumber = 'section_serial_number',
  sectionCapacity = 'section_capacity',
  createdBy = 'created_by',
  updatedBy = 'updated_by',
  stablesGroupId = 'stables_group_id',
  name = 'name',
  weaningPigsCount = 'weaning_pigs_count',
  sowsCount = 'sows_count',
  fatteningPigsCount = 'fattening_pigs_count',
  sectionCount = 'section_count',
  feedType = 'feed_type',
  feedSystem = 'feed_system',
  stableSections = 'stable_sections',
  stables = 'stables',
  ubn = 'ubn',
  createdAt = 'created_at',
  updatedAt = 'updated_at',
}

enum FarmStablesSerializedHeader {
  id = 'id',
  stableId = 'stableId',
  sectionSerialNumber = 'sectionSerialNumber',
  sectionCapacity = 'sectionCapacity',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
  stablesGroupId = 'stablesGroupId',
  name = 'name',
  weaningPigsCount = 'weaningPigsCount',
  sowsCount = 'sowsCount',
  fatteningPigsCount = 'fatteningPigsCount',
  sectionCount = 'sectionCount',
  feedType = 'feedType',
  feedSystem = 'feedSystem',
  stableSections = 'stableSections',
  stables = 'stables',
  ubn = 'ubn',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

interface CreatedBy {
  name: string;
  email: string;
}

interface StableSectionUnserialized {
  id: number;
  section_capacity: number;
}

interface StableUnserialized {
  id: number;
  stables_group_id: number;
  name: string;
  weaning_pigs_count: number;
  sows_count: number;
  fattening_pigs_count: number;
  section_count: number;
  section_capacity: number;
  feed_type: string;
  feed_system: string;
  stable_sections: StableSectionUnserialized[];
}

interface StableResourceUnserialized {
  id: number;
  ubn: string;
  created_at: string;
  updated_at: string;
  created_by: CreatedBy;
  updated_by: CreatedBy;
  stables: StableUnserialized[];
}

interface StableSectionSerialized {
  id: number;
  sectionCapacity: number;
}

export interface StableSerialized {
  id: number;
  name: string;
  weaningPigsCount: number;
  sowsCount: number;
  fatteningPigsCount: number;
  sectionCount: number;
  sectionCapacity: number;
  feedType: string;
  feedSystem: string;
  stableSections?: StableSectionSerialized[];
}

export interface StableResourceSerialized {
  id: number;
  ubn: string;
  createdAt: string;
  updatedAt: string;
  createdBy: CreatedBy;
  updatedBy: CreatedBy;
  stables: StableSerialized[];
}

export enum FarmEventTypes {
  created = 'resource_created',
  updated = 'resource_updated',
  destroyed = 'resource_destroyed',
}

export enum FarmResourceType {
  stable = 'stable',
  stablesGroup = 'stables_group',
  deathsReport = 'deaths_report',
  deliveryReport = 'delivery_report',
  feedConsumptionReport = 'feed_consumption_report',
  supportReport = 'support_report',
}

export enum FarmEventUnserializedHeader {
  id = 'id',
  resourceUbn = 'resource_ubn',
  resourceNames = 'resource_names',
  resourceAccessId = 'resource_access_id',
  resourceType = 'resource_type',
  eventType = 'event_type',
  createdAt = 'created_at',
  updatedAt = 'updated_at',
  user = 'user',
  email = 'email',
  name = 'name',
}

export enum FarmEventSerializedHeader {
  id = 'id',
  resourceUbn = 'resourceUbn',
  resourceNames = 'resourceNames',
  resourceAccessId = 'resourceAccessId',
  resourceType = 'resourceType',
  eventType = 'eventType',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  user = 'user',
  email = 'email',
  name = 'name',
}

interface FarmEventUser {
  email: string;
  name: string;
}

export interface FarmEventUnserialized {
  id: number;
  resource_ubn: string;
  resource_names: string[];
  resource_access_id: string;
  resource_type: FarmResourceType;
  event_type: FarmEventTypes;
  created_at: string;
  updated_at: string;
  user: FarmEventUser;
}

export interface FarmEventSerialized {
  id: number;
  resourceUbn: string;
  resourceNames: string[];
  resourceAccessId: string;
  resourceType: FarmResourceType;
  eventType: FarmEventTypes;
  createdAt: string;
  updatedAt: string;
  user: FarmEventUser;
}

export interface FarmProductStoredValuesPayload {
  productCode: Enum[];
  productName: string[];
  productOrigin: string[];
  productOriginDetailed: Enum[];
}

export interface FarmFermentationStoredValuesPayload {
  productCode: string[];
  productName: string[];
  supplierName: string[];
}

const farmEventsSerializer = (data: DocumentData<FarmEventUnserialized[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(FarmsUpdatesLogSerialized.UpdatesLog, FarmsUpdatesLogSerialized.UpdatesLog, [
      new CellSerializer(FarmEventUnserializedHeader.id, FarmEventSerializedHeader.id),
      new CellSerializer(FarmEventUnserializedHeader.resourceUbn, FarmEventSerializedHeader.resourceUbn),
      new CellSerializer(FarmEventUnserializedHeader.resourceNames, FarmEventSerializedHeader.resourceNames),
      new CellSerializer(FarmEventUnserializedHeader.resourceAccessId, FarmEventSerializedHeader.resourceAccessId),
      new CellSerializer(FarmEventUnserializedHeader.resourceType, FarmEventSerializedHeader.resourceType),
      new CellSerializer(FarmEventUnserializedHeader.eventType, FarmEventSerializedHeader.eventType),
      new CellSerializer(FarmEventUnserializedHeader.createdAt, FarmEventSerializedHeader.createdAt),
      new CellSerializer(FarmEventUnserializedHeader.updatedAt, FarmEventSerializedHeader.updatedAt),
      new CellSerializer(FarmEventUnserializedHeader.user, FarmEventSerializedHeader.user),
    ]),
  ]);
};

const stableSectionsSerializer = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propertyOriginalName: PropertyPath,
) => {
  const proceedData: StableSectionUnserialized[] = get(excelRow, propertyOriginalName, '');

  return proceedData.map((stableSection) => ({
    [FarmStablesSerializedHeader.id]: stableSection[FarmStablesUnserializedHeader.id],
    [FarmStablesSerializedHeader.sectionCapacity]: stableSection[FarmStablesUnserializedHeader.sectionCapacity],
  }));
};

const getFarmStablesResourceSerializer = (data: DocumentData<StableResourceUnserialized[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(FAKE_TITLE, FAKE_TITLE, [
      new CellSerializer(FarmStablesUnserializedHeader.id, FarmStablesSerializedHeader.id),
      new CellSerializer(FarmStablesUnserializedHeader.ubn, FarmStablesSerializedHeader.ubn),
      new CellSerializer(FarmStablesUnserializedHeader.createdBy, FarmStablesSerializedHeader.createdBy),
      new CellSerializer(FarmStablesUnserializedHeader.updatedBy, FarmStablesSerializedHeader.updatedBy),
      new CellSerializer(FarmStablesUnserializedHeader.createdAt, FarmStablesSerializedHeader.createdAt),
      new CellSerializer(FarmStablesUnserializedHeader.updatedAt, FarmStablesSerializedHeader.updatedAt),
    ]),
  ]);
};
const getFarmStablesSerializer = (data: DocumentData<StableUnserialized[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(FAKE_TITLE, FAKE_TITLE, [
      new CellSerializer(FarmStablesUnserializedHeader.id, FarmStablesSerializedHeader.id),
      new CellSerializer(FarmStablesUnserializedHeader.stablesGroupId, FarmStablesSerializedHeader.stablesGroupId),
      new CellSerializer(FarmStablesUnserializedHeader.name, FarmStablesSerializedHeader.name),
      new CellSerializer(FarmStablesUnserializedHeader.weaningPigsCount, FarmStablesSerializedHeader.weaningPigsCount),
      new CellSerializer(FarmStablesUnserializedHeader.sowsCount, FarmStablesSerializedHeader.sowsCount),
      new CellSerializer(
        FarmStablesUnserializedHeader.fatteningPigsCount,
        FarmStablesSerializedHeader.fatteningPigsCount,
      ),
      new CellSerializer(FarmStablesUnserializedHeader.sectionCount, FarmStablesSerializedHeader.sectionCount),
      new CellSerializer(FarmStablesUnserializedHeader.sectionCapacity, FarmStablesSerializedHeader.sectionCapacity),
      new CellSerializer(FarmStablesUnserializedHeader.feedType, FarmStablesSerializedHeader.feedType),
      new CellSerializer(FarmStablesUnserializedHeader.feedSystem, FarmStablesSerializedHeader.feedSystem),
      new CellSerializer(
        FarmStablesUnserializedHeader.stableSections,
        FarmStablesSerializedHeader.stableSections,
        stableSectionsSerializer,
      ),
    ]),
  ]);
};

export const serializeFarmStables = (response: ResourceWrapper<StableResourceUnserialized>) => {
  const resource = { [FAKE_TITLE]: [response.data.resource] };
  const stables = { [FAKE_TITLE]: response.data.resource?.stables || [] };

  const serializedResource = getFarmStablesResourceSerializer(resource).serialize()[FAKE_TITLE][0];
  const serializedStables = getFarmStablesSerializer(stables).serialize()[FAKE_TITLE];
  return {
    ...serializedResource,
    [FarmStablesSerializedHeader.stables]: serializedStables,
  };
};

export const serializeFarmEvents = (response: ResourcesWrapper<FarmEventUnserialized>) => {
  const resource = { [FarmsUpdatesLogSerialized.UpdatesLog]: response.data.resources };
  return farmEventsSerializer(resource).serialize()[FarmsUpdatesLogSerialized.UpdatesLog];
};

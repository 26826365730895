import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { FILTER_TYPES } from './index';
import { PERMISSIONS } from 'store/auth/permissions';
import { store } from 'store/store';
import { userHasPermissionSelector } from 'store/auth/reducer';

export enum BlockchainPermissions {
  ALL = 'show_all',
  PERMITTED = 'show_permitted',
  NOT_PERMITTED = 'show_not_permitted',
}

export interface BlockchainPermissionsConfig {
  id: number;
  label: string;
  value: BlockchainPermissions;
  // For url query to sync view
  getQuery: <T extends BlockchainPermissions>(filters?: T) => BlockchainPermissions | {};
  // For request
  getValues: <T extends BlockchainPermissions>(filters?: T) => BlockchainPermissions;
  isDefault?: boolean;
}

export const blockchainPermissionsConfigs: Record<BlockchainPermissions, BlockchainPermissionsConfig> = {
  [BlockchainPermissions.ALL]: {
    id: 1,
    label: 'All',
    value: BlockchainPermissions.ALL,
    getQuery: () => BlockchainPermissions.ALL,
    getValues: () => BlockchainPermissions.ALL,
  },
  [BlockchainPermissions.PERMITTED]: {
    id: 2,
    label: 'Permitted',
    value: BlockchainPermissions.PERMITTED,
    getQuery: () => ({}),
    getValues: () => BlockchainPermissions.PERMITTED,
    isDefault: true,
  },
  [BlockchainPermissions.NOT_PERMITTED]: {
    id: 3,
    label: 'Exceptions',
    value: BlockchainPermissions.NOT_PERMITTED,
    getQuery: () => BlockchainPermissions.NOT_PERMITTED,
    getValues: () => BlockchainPermissions.NOT_PERMITTED,
  },
};

export const BLOCKCHAIN_PERMISSIONS_INITIAL_VALUE = BlockchainPermissions.PERMITTED;
const permissions = [PERMISSIONS.BLOCKCHAIN_PERMISSIONS_FILTER];

export const blockchainPermissionsFilter = {
  type: FILTER_TYPES.BLOCKCHAIN_PERMISSIONS,
  getInitialValues: () => BLOCKCHAIN_PERMISSIONS_INITIAL_VALUE,
  getQuery: (blockchainPermissionsFilter: BlockchainPermissions = BLOCKCHAIN_PERMISSIONS_INITIAL_VALUE) => {
    const userHasPermission = userHasPermissionSelector(store.getState());
    if (permissions.every(userHasPermission)) {
      return blockchainPermissionsConfigs[blockchainPermissionsFilter].getQuery(blockchainPermissionsFilter);
    }
    return {};
  },
  getValues: (blockchainPermissionsFilter: BlockchainPermissions = BLOCKCHAIN_PERMISSIONS_INITIAL_VALUE) => {
    return blockchainPermissionsConfigs[blockchainPermissionsFilter].getValues(blockchainPermissionsFilter);
  },
  getLabel: (blockchainPermissionsFilter: BlockchainPermissions = BLOCKCHAIN_PERMISSIONS_INITIAL_VALUE) => {
    return blockchainPermissionsConfigs[blockchainPermissionsFilter].label;
  },
  icon: VerifiedUserOutlinedIcon,
  permissions,
};

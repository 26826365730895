import { IntlKeys } from 'lib/localization/keys';

enum CodesInseminationSerialized {
  CodesInsemination = 'CodesInsemination',
}

enum CodesInseminationUnserialized {
  CodesInsemination = 'CodesInsemination',
}

export enum CodesInseminationSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  inseminationIdentifier = 'inseminationIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  note = 'note',
  workList = 'workList',
  code = 'code',
  inseminationDatetime = 'inseminationDatetime',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesInseminationUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  inseminationIdentifier: 'insemination_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  note: 'note',
  workList: 'worklist',
  code: 'code',
  inseminationDatetime: 'insemination_datetime',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_INSEMINATION = {
  [CodesInseminationSerialized.CodesInsemination]: {
    serialized: CodesInseminationSerialized.CodesInsemination,
    unserialized: CodesInseminationUnserialized.CodesInsemination,
    view: IntlKeys.farmManagementDataInseminationList,

    columns: {
      [CodesInseminationSerializedHeaders.id]: {
        serialized: CodesInseminationSerializedHeaders.id,
        unserialized: CodesInseminationUnserializedHeaders.id,
      },
      [CodesInseminationSerializedHeaders.companyIdentifier]: {
        serialized: CodesInseminationSerializedHeaders.companyIdentifier,
        unserialized: CodesInseminationUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesInseminationSerializedHeaders.inseminationIdentifier]: {
        serialized: CodesInseminationSerializedHeaders.inseminationIdentifier,
        unserialized: CodesInseminationUnserializedHeaders.inseminationIdentifier,
        view: IntlKeys.farmManagementDataInseminationId,
      },
      [CodesInseminationSerializedHeaders.abbreviation]: {
        serialized: CodesInseminationSerializedHeaders.abbreviation,
        unserialized: CodesInseminationUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesInseminationSerializedHeaders.description]: {
        serialized: CodesInseminationSerializedHeaders.description,
        unserialized: CodesInseminationUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesInseminationSerializedHeaders.note]: {
        serialized: CodesInseminationSerializedHeaders.note,
        unserialized: CodesInseminationUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesInseminationSerializedHeaders.workList]: {
        serialized: CodesInseminationSerializedHeaders.workList,
        unserialized: CodesInseminationUnserializedHeaders.workList,
        view: IntlKeys.farmManagementDataWorkList,
      },
      [CodesInseminationSerializedHeaders.code]: {
        serialized: CodesInseminationSerializedHeaders.code,
        unserialized: CodesInseminationUnserializedHeaders.code,
        view: IntlKeys.farmManagementDataCode,
      },
      [CodesInseminationSerializedHeaders.inseminationDatetime]: {
        serialized: CodesInseminationSerializedHeaders.inseminationDatetime,
        unserialized: CodesInseminationUnserializedHeaders.inseminationDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesInseminationSerializedHeaders.ubnList]: {
        serialized: CodesInseminationSerializedHeaders.ubnList,
        unserialized: CodesInseminationUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesInseminationSerializedHeaders.kvkList]: {
        serialized: CodesInseminationSerializedHeaders.kvkList,
        unserialized: CodesInseminationUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesInseminationSerializedHeaders.registrationNameList]: {
        serialized: CodesInseminationSerializedHeaders.registrationNameList,
        unserialized: CodesInseminationUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

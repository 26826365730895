import { FILTER_ENTITY_TYPES } from 'store/filters/reducer';
import { asyncAction, syncAction } from 'lib/actions';
import { FilterValueType } from 'lib/filters';
import { ObjectWithProps } from "../../lib/excel/serilizers/Cell";

export const addCurrentFiltersAction = syncAction('filters/addCurrentFiltersAction');
export const setCurrentFiltersAction = syncAction('filters/setCurrentFilters');
export const resetCurrentFiltersAction = syncAction('filters/resetCurrentFilters');

export const addFiltersAction = syncAction<{
  entity: FILTER_ENTITY_TYPES;
  filters: Partial<Record<string, FilterValueType>>;
}>('filters/addFiltersAction');
export const setFiltersAction = syncAction<{
  entity: FILTER_ENTITY_TYPES;
  filters: Partial<Record<string, FilterValueType>>;
}>('filters/setFilters');
export const setFiltersQueryAction = syncAction<{
  entity: FILTER_ENTITY_TYPES;
  filters: Partial<Record<string, FilterValueType>>;
}>('filters/setFiltersQuery');
export const resetFiltersAction = syncAction<{ entity: FILTER_ENTITY_TYPES }>('filters/resetFilters');

export const setFiltersMenuStateAction = syncAction<boolean>('filters/setFiltersMenuState');

export const setFilterIsLoaded = syncAction<boolean>('filters/setFilterIsLoaded');

export const getFilterOptionsAction = asyncAction<{filterOptionsKey: string, filter?: ObjectWithProps}>('filters/getFilterOptions');

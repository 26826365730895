import { Route } from '../../Route';
import RouteParser from 'route-parser';

import { generateBlockchainRecord } from '../../utils';

export const certificatesWelfare = new Route(
  'get',
  new RouteParser('/certificates_welfare/grouped_index'),
  (...data) => {
    return [
      200,
      {
        resources: [
          {
            id: 4987,
            ubn: '2222222',
            valid_from: '2004-02-03T20:59:56.000Z',
            valid_to: '2021-01-31T20:59:56.000Z',
            type: 'welfare',
            ...generateBlockchainRecord(),
          },
          {
            id: 4986,
            ubn: '1111111',
            valid_from: '2004-02-03T20:59:56.000Z',
            valid_to: '2021-01-31T20:59:56.000Z',
            type: 'welfare',
            ...generateBlockchainRecord(),
          },
        ],
      },
    ];
  },
);

import { RAV_REFERENCE_LIST_FIELDS } from 'lib/excel/config/referenceTables/ravReferenceList/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = RAV_REFERENCE_LIST_FIELDS.RavReferenceList;

export const serializeRavReferenceListData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.animalCategory.original, columns.animalCategory.serialized),
      new CellSerializer(columns.description.original, columns.description.serialized),
      new CellSerializer(columns.efEm.original, columns.efEm.serialized),
      new CellSerializer(columns.housingType.original, columns.housingType.serialized),
    ]),
  ]);
};

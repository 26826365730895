import { call, put, select, takeEvery } from 'redux-saga/effects';
import { omit, isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';
import moment from 'moment';

import 'moment/locale/nl';
import 'moment/locale/en-gb';

import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { ApiClient } from 'lib/http/ApiClient';
import { CustomAction } from 'lib/actions';
import ensure from 'lib/ensure';
import { Languages } from 'lib/localization';

import { userHasPermissionSelector, userIdSelector } from 'store/auth/reducer';
import {
  getTableConfig,
  resetToDefault,
  saveTableConfig,
  setLocale,
  tableSettingsSelector,
  changeHeadersOrder,
  setPaginationActiveAction,
} from 'store/reducers/userSettingsReducer';
import { PERMISSIONS } from 'store/auth/permissions';
import { ClientStorage, PREF_LANG } from 'lib/storage/Storage';

const serializeTableDataResponse = (response: AxiosResponse) => {
  const config = response?.data?.resource?.configuration;

  if (config !== null && typeof config === 'object') {
    return config;
  }
};

export const ensureSaveConfig = ensure({
  api: ApiClient.saveViewConfig,
  action: saveTableConfig,
  transformRequestData: ({ config }: ObjectWithProps) => {
    return {
      resource: {
        configuration: config,
      },
    };
  },
  serializeSuccessPayload: serializeTableDataResponse,
});

export const ensureGetConfig = ensure({
  api: ApiClient.getViewConfig,
  action: getTableConfig,
  serializeSuccessPayload: serializeTableDataResponse,
});

export const ensureDeleteConfig = ensure({
  api: ApiClient.deleteConfig,
  action: getTableConfig,
  serializeSuccessPayload: serializeTableDataResponse,
});

export const ensureUpdatePrefLanguage = ensure({
  api: ApiClient.updateUser,
  action: setLocale,
});

function* updatePrefUserLanguage({ payload }: CustomAction<keyof typeof Languages>) {
  const id = yield select(userIdSelector);

  if (id) {
    const langMap = {
      en: 'en-gb',
      nl: 'nl',
    };

    moment.locale(langMap[payload]);

    yield call(ensureUpdatePrefLanguage, {
      params: { id },
      resource: { pref_lang: payload },
    });

    ClientStorage.setItem(PREF_LANG, payload);
  }
}

function* updateUserProfilePaginationActive({ payload }: CustomAction<boolean>) {
  const isAdmin = (yield select(userHasPermissionSelector))(PERMISSIONS.LEVEL1);

  yield call(
    ensure({
      api: isAdmin ? ApiClient.adminUpdateUserProfileSettings : ApiClient.updateUserProfileSettings,
      action: setPaginationActiveAction,
    }),
    {
      resource: { pagination_active: payload },
    },
  );
}

function* getUserSettings() {
  yield call(ensureGetConfig, {
    params: {
      configName: 'table_config',
    },
  });
}

function* saveUserSettings() {
  const configToSave = yield select(tableSettingsSelector);

  yield call(ensureSaveConfig, {
    params: {
      configName: 'table_config',
    },
    config: configToSave,
  });
}
function* resetTableSettings({ payload }: CustomAction<string>) {
  const tSettings = yield select(tableSettingsSelector);
  const filteredSettings = omit(tSettings, [payload]);

  const ensure = !isEmpty(filteredSettings) ? ensureSaveConfig : ensureDeleteConfig;

  const newConfig = yield call(ensure, {
    params: {
      configName: 'table_config',
    },
    config: filteredSettings,
  });

  yield put({ type: getTableConfig.success, payload: newConfig.payload });
}

export default function* () {
  yield takeEvery(saveTableConfig.request, saveUserSettings);
  yield takeEvery(changeHeadersOrder.type, saveUserSettings);
  yield takeEvery(resetToDefault.request, resetTableSettings);
  yield takeEvery(getTableConfig.request, getUserSettings);
  yield takeEvery(setLocale.request, updatePrefUserLanguage);
  yield takeEvery(setPaginationActiveAction.request, updateUserProfilePaginationActive);
}

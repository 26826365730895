import { CONTENT_OF_NITRIC_OXIDE_FIELDS } from 'lib/excel/config/referenceTables/contentOfNitricOxide/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo1DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = CONTENT_OF_NITRIC_OXIDE_FIELDS.ContentOfNitricOxide;

export const getcontentOfNitricOxideView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.livestockCategory.serialized,
        viewPropName: columns.livestockCategory.view,
      }),
      new CellViewer({ serializedPropName: columns.manureType.serialized, viewPropName: columns.manureType.view }),
      new CellViewer({
        serializedPropName: columns.valueN2oAndNo.serialized,
        viewPropName: columns.valueN2oAndNo.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo1DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.unitN2oAndNo.serialized, viewPropName: columns.unitN2oAndNo.view }),
      new CellViewer({
        serializedPropName: columns.valueN2.serialized,
        viewPropName: columns.valueN2.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo1DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.unitN2.serialized, viewPropName: columns.unitN2.view }),
    ]),
  ]);
};

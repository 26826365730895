import { IntlKeys } from 'lib/localization/keys/__keys';

enum Gwp100FactorsOriginal {
  Gwp100Factors = 'CFP_WUR_GWP100factor',
}

enum Gwp100FactorsSerialized {
  Gwp100Factors = 'Gwp100Factors',
}

enum Gwp100FactorsUnserialized {
  Gwp100Factors = 'Gwp100Factors',
}

enum Gwp100FactorsOriginalHeaders {
  'greenhouseGas' = 'Greenhouse gas',
  'gwp100Factor' = 'GWP100 factor',
  'gwp100FactorUnit' = 'GWP100 factor Unit',
}

enum Gwp100FactorsSerializedHeaders {
  'greenhouseGas' = 'greenhouseGas',
  'gwp100Factor' = 'gwp100Factor',
  'gwp100FactorUnit' = 'gwp100FactorUnit',
}
enum Gwp100FactorsUnserializedHeaders {
  'greenhouseGas' = 'greenhouse_gas',
  'gwp100Factor' = 'gwp100_factor',
  'gwp100FactorUnit' = 'gwp100_factor_unit',
}

export const GWP_100_FACTORS_FIELDS = {
  [Gwp100FactorsUnserialized.Gwp100Factors]: {
    original: Gwp100FactorsOriginal.Gwp100Factors,
    serialized: Gwp100FactorsSerialized.Gwp100Factors,
    unserialized: Gwp100FactorsUnserialized.Gwp100Factors,
    view: IntlKeys.referenceTablesGwp100Factors,
    viewName: 'CFP_WUR_GWP100factor',

    columns: {
      [Gwp100FactorsSerializedHeaders.greenhouseGas]: {
        original: Gwp100FactorsOriginalHeaders.greenhouseGas,
        serialized: Gwp100FactorsSerializedHeaders.greenhouseGas,
        unserialized: Gwp100FactorsUnserializedHeaders.greenhouseGas,
        view: IntlKeys.referenceTablesGwp100FactorsGreenhouseGas,
      },
      [Gwp100FactorsSerializedHeaders.gwp100Factor]: {
        original: Gwp100FactorsOriginalHeaders.gwp100Factor,
        serialized: Gwp100FactorsSerializedHeaders.gwp100Factor,
        unserialized: Gwp100FactorsUnserializedHeaders.gwp100Factor,
        view: IntlKeys.referenceTablesGwp100FactorsGwp,
      },
      [Gwp100FactorsSerializedHeaders.gwp100FactorUnit]: {
        original: Gwp100FactorsOriginalHeaders.gwp100FactorUnit,
        serialized: Gwp100FactorsSerializedHeaders.gwp100FactorUnit,
        unserialized: Gwp100FactorsUnserializedHeaders.gwp100FactorUnit,
        view: IntlKeys.referenceTablesGwp100FactorsGwpUnit,
      },
    },
  },
};

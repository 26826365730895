import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const certificatesCompany = new Route('get', new RouteParser('/farms/grouped_index'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 311,
          ubn: '0297930',
          address: 'Steenkampweg 2',
          postal_code: '7134 PP',
          place: 'VRAGENDER',
          phone: '0544-371875',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 0,
          updated_date: '2020-04-07T21:00:00.000Z',
          vlv: 1752,
          remark: '',
          created_at: '2020-06-26T07:53:17.402Z',
          updated_at: '2020-06-26T07:53:17.402Z',
          company_name: 'Mts R.J.M. Hegeman ',
          ...generateBlockchainRecord(),
        },
        {
          id: 308,
          ubn: '1671830',
          address: 'Ulsderweg 10',
          postal_code: '9686 XX',
          place: 'BEERTA',
          phone: '0597-655360',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 670,
          updated_date: '2020-04-05T21:00:00.000Z',
          vlv: 720,
          remark: '',
          created_at: '2020-06-26T07:53:17.396Z',
          updated_at: '2020-06-26T07:53:17.396Z',
          company_name: 'Mts A.L. ten Have - Mellema, MB \u0026 DH ten Have ',
          ...generateBlockchainRecord(),
        },
        {
          id: 305,
          ubn: '0373555',
          address: 'Brandwijksedijk 10 A',
          postal_code: '2974 LB',
          place: 'BRANDWIJK',
          phone: '0184-641239',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 0,
          updated_date: '2020-04-07T21:00:00.000Z',
          vlv: 1250,
          remark: '',
          created_at: '2020-06-26T07:53:17.389Z',
          updated_at: '2020-06-26T07:53:17.389Z',
          company_name: 'Vof Gebr. Korevaar ',
          ...generateBlockchainRecord(),
        },
        {
          id: 302,
          ubn: '0391812',
          address: 'Rorikshilweg 1',
          postal_code: '4364 SC',
          place: 'GRIJPSKERKE',
          phone: '0620268944',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 173,
          updated_date: '2020-03-31T21:00:00.000Z',
          vlv: 5,
          remark: '',
          created_at: '2020-06-26T07:51:34.576Z',
          updated_at: '2020-06-26T07:51:34.576Z',
          company_name: 'Vof Francke ',
          ...generateBlockchainRecord(),
        },
        {
          id: 301,
          ubn: '1740716',
          address: 'Hogeweg 12',
          postal_code: '4322 TB',
          place: 'SCHARENDIJKE',
          phone: '0111-420750',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 250,
          updated_date: '2020-03-31T21:00:00.000Z',
          vlv: 2153,
          remark: '',
          created_at: '2020-06-26T07:51:34.574Z',
          updated_at: '2020-06-26T07:51:34.574Z',
          company_name: 'J.P.G. de Bakker ',
          ...generateBlockchainRecord(),
        },
        {
          id: 300,
          ubn: '0205922',
          address: 'Ottershagenweg 18',
          postal_code: '7634 RD',
          place: 'TILLIGTE',
          phone: '0541-221324',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 159,
          updated_date: '2020-04-01T21:00:00.000Z',
          vlv: 5,
          remark: '',
          created_at: '2020-06-26T07:51:34.572Z',
          updated_at: '2020-06-26T07:51:34.572Z',
          company_name: 'Vof De Drie Wilgen ',
          ...generateBlockchainRecord(),
        },
        {
          id: 293,
          ubn: '1086920',
          address: 'Valkseweg  177',
          postal_code: '3771 SE',
          place: 'BARNEVELD',
          phone: '0342-423310',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 0,
          updated_date: '2020-04-01T21:00:00.000Z',
          vlv: 2000,
          remark: '',
          created_at: '2020-06-26T07:51:34.555Z',
          updated_at: '2020-06-26T07:51:34.555Z',
          company_name: 'Mts G. van Hoef ',
          ...generateBlockchainRecord(),
        },
        {
          id: 289,
          ubn: '0966498',
          address: 'Westfriesedijk 1',
          postal_code: '1741 NP',
          place: 'SCHAGEN',
          phone: '0224-219845',
          farm_type: 'NC',
          program: 'BIO',
          sows_count: 213,
          updated_date: '2020-04-05T21:00:00.000Z',
          vlv: 1440,
          remark: '',
          created_at: '2020-06-26T07:51:34.546Z',
          updated_at: '2020-06-26T07:51:34.546Z',
          company_name: 'Mts T.A.M. Wennekers ',
          ...generateBlockchainRecord(),
        },
        {
          id: 288,
          ubn: '0294809',
          address: 'Verkavelingsweg 6',
          postal_code: '7136 JH',
          place: 'ZIEUWENT',
          phone: '06-41446491 barbara',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 542,
          updated_date: '2020-04-05T20:59:56.000Z',
          vlv: 30,
          remark: '',
          created_at: '2020-06-03T13:06:23.753Z',
          updated_at: '2020-06-03T13:06:23.753Z',
          company_name: 'Vof Krabben ',
          ...generateBlockchainRecord(),
        },
        {
          id: 285,
          ubn: '2332824',
          address: 'Lijsterweg 1',
          postal_code: '5752 PS',
          place: 'DEURNE',
          phone: '0478-640101',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 0,
          updated_date: '2020-04-02T20:59:56.000Z',
          vlv: 2015,
          remark: '',
          created_at: '2020-06-03T13:06:23.747Z',
          updated_at: '2020-06-03T13:06:23.747Z',
          company_name: 'Mts Schepers ',
          ...generateBlockchainRecord(),
        },
        {
          id: 283,
          ubn: '1638969',
          address: 'IJsselsteynseweg 48',
          postal_code: '5813 BM',
          place: 'YSSELSTEYN LB',
          phone: '0478-640101',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 505,
          updated_date: '2020-04-02T20:59:56.000Z',
          vlv: 80,
          remark: '',
          created_at: '2020-06-03T13:06:23.741Z',
          updated_at: '2020-06-03T13:06:23.741Z',
          company_name: 'Varkens Yweg 48  BV',
          ...generateBlockchainRecord(),
        },
        {
          id: 281,
          ubn: '2352237',
          address: 'Zevenhuisterweg 64',
          postal_code: '9311 VC',
          place: 'NIEUW RODEN',
          phone: '0594-631873',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 196,
          updated_date: '2020-04-08T20:59:56.000Z',
          vlv: 1917,
          remark: '',
          created_at: '2020-06-03T13:06:23.730Z',
          updated_at: '2020-06-03T13:06:23.730Z',
          company_name: 'A. Kroesbergen ',
          ...generateBlockchainRecord(),
        },
        {
          id: 278,
          ubn: '1009880',
          address: 'Krakkedel 36',
          postal_code: '6686 MB',
          place: 'DOORNENBURG',
          phone: '0481 421187',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 0,
          updated_date: '2020-04-07T20:59:56.000Z',
          vlv: 1456,
          remark: '',
          created_at: '2020-06-03T13:06:23.721Z',
          updated_at: '2020-06-03T13:06:23.721Z',
          company_name: 'Mts E.J.J. Buurman ',
          ...generateBlockchainRecord(),
        },
        {
          id: 275,
          ubn: '2126360',
          address: 'Panoven 12',
          postal_code: '6591 MB',
          place: 'GENNEP',
          phone: '0485-517386',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 0,
          updated_date: '2020-04-05T20:59:56.000Z',
          vlv: 2430,
          remark: '',
          created_at: '2020-06-03T13:06:23.715Z',
          updated_at: '2020-06-03T13:06:23.715Z',
          company_name: 'Nabuma BV',
          ...generateBlockchainRecord(),
        },
        {
          id: 272,
          ubn: '1755543',
          address: 'Oude Spoorbaan 2',
          postal_code: '5051 DX',
          place: 'GOIRLE',
          phone: 'evt. 0348-688769',
          farm_type: 'NC',
          program: 'CBL01',
          sows_count: 0,
          updated_date: '2020-03-31T20:59:56.000Z',
          vlv: 900,
          remark:
            '. Afd.:stal 2 afd 8/9/10 opp 12,75 m2 a 13 varkens 9 x 13 = 117 varkens afd 11/1/2/13/14 a 24 hokken a Totaal opp 367m 2 a 360 varkens stal 1 afd 1/2/3 totaal 100 m2 a 96 varkens afd 4/5/6/7 a 354 m2 a 360 varkens TOTAAL op bedrijf 933',
          created_at: '2020-06-03T13:06:23.707Z',
          updated_at: '2020-06-03T13:06:23.707Z',
          company_name: 'Devinka BV / Kleiren ',
          ...generateBlockchainRecord(),
        },
        {
          id: 85,
          ubn: '0759306',
          address: 'Scholtenswijk 2',
          postal_code: '7926 TM',
          place: 'KERKENVELD',
          phone: '0528-361479',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 4,
          updated_date: '2020-04-02T20:59:56.000Z',
          vlv: 95,
          remark: '',
          created_at: '2020-05-28T11:29:45.092Z',
          updated_at: '2020-05-28T11:29:45.092Z',
          company_name: 'H. ter Stege ',
          ...generateBlockchainRecord(),
        },
        {
          id: 84,
          ubn: '1659560',
          address: 'Oostopgaande 55',
          postal_code: '7918 TC',
          place: 'NIEUWLANDE OOSTERHESSELN',
          phone: '0528-352369',
          farm_type: 'NC',
          program: 'BIO',
          sows_count: 0,
          updated_date: '2020-04-01T20:59:56.000Z',
          vlv: 641,
          remark: '',
          created_at: '2020-05-28T11:29:45.090Z',
          updated_at: '2020-05-28T11:29:45.090Z',
          company_name: 'Mts R. Kleine en F. Kleine-Botter ',
          ...generateBlockchainRecord(),
        },
        {
          id: 83,
          ubn: '2023212',
          address: 'Lagestraat 48',
          postal_code: '6606 KC',
          place: 'NIFTRIK',
          phone: '',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 914,
          updated_date: '2020-03-31T20:59:56.000Z',
          vlv: 1793,
          remark: '',
          created_at: '2020-05-28T11:29:45.087Z',
          updated_at: '2020-05-28T11:29:45.087Z',
          company_name: 'Vof van der Linden ',
          ...generateBlockchainRecord(),
        },
        {
          id: 82,
          ubn: '1723245',
          address: 'Wijbosch 2',
          postal_code: '5763 PT',
          place: 'MILHEEZE',
          phone: '0492-342267',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 240,
          updated_date: '2020-04-05T20:59:56.000Z',
          vlv: 1740,
          remark: 'Voor de opp.  van de hokken zie verslag d.d. 09-04-2009  en 05-04-2016',
          created_at: '2020-05-28T11:29:45.085Z',
          updated_at: '2020-05-28T11:29:45.085Z',
          company_name: 'Mts Heesmans ',
          ...generateBlockchainRecord(),
        },
        {
          id: 81,
          ubn: '6081494',
          address: 'Keutereseweg 3',
          postal_code: '5864 DA',
          place: 'MEERLO',
          phone: '06-26542427',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 0,
          updated_date: '2020-04-01T20:59:56.000Z',
          vlv: 4800,
          remark: 'Voor de opp.  van de hokken zie verslag d.d.  24-03-2009.',
          created_at: '2020-05-28T11:29:45.083Z',
          updated_at: '2020-05-28T11:29:45.083Z',
          company_name: 'Vof Varkenshouderij Poels ',
          ...generateBlockchainRecord(),
        },
        {
          id: 80,
          ubn: '2055448',
          address: 'Hogenbos 14',
          postal_code: '5864 CL',
          place: 'MEERLO',
          phone: '0478-691375',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 1360,
          updated_date: '2020-04-01T20:59:56.000Z',
          vlv: 4810,
          remark: 'Voor de opp.  van de hokken zie verslag d.d. 24-03-2009  en 12-04-2016',
          created_at: '2020-05-28T11:29:45.081Z',
          updated_at: '2020-05-28T11:29:45.081Z',
          company_name: 'Vof Varkenshouderij Poels ',
          ...generateBlockchainRecord(),
        },
        {
          id: 79,
          ubn: '1576203',
          address: 'Waalderweg 45',
          postal_code: '7263 RV',
          place: 'MARIENVELDE',
          phone: '06-41446491',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 690,
          updated_date: '2020-04-05T20:59:56.000Z',
          vlv: 380,
          remark: '',
          created_at: '2020-05-28T11:29:45.079Z',
          updated_at: '2020-05-28T11:29:45.079Z',
          company_name: 'Vof Krabben ',
          ...generateBlockchainRecord(),
        },
        {
          id: 75,
          ubn: '2055967',
          address: 'Langpaed 13',
          postal_code: '9248 SX',
          place: 'SIEGERSWOUDE',
          phone: '0512-301333',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 0,
          updated_date: '2020-04-01T20:59:56.000Z',
          vlv: 710,
          remark: '',
          created_at: '2020-05-28T11:29:45.071Z',
          updated_at: '2020-05-28T11:29:45.071Z',
          company_name: 'Devinka BV / Sytsema ',
          ...generateBlockchainRecord(),
        },
        {
          id: 65,
          ubn: '1958474',
          address: 'Noordsweg 1',
          postal_code: '7986 PN',
          place: 'WITTELTE',
          phone: '0521-598297',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 227,
          updated_date: '2020-03-31T20:59:56.000Z',
          vlv: 0,
          remark: '',
          created_at: '2020-05-28T11:29:45.050Z',
          updated_at: '2020-05-28T11:29:45.050Z',
          company_name: 'Mts G.J. Mts GJ Zantinge/H Stroop ',
          ...generateBlockchainRecord(),
        },
        {
          id: 64,
          ubn: '0693163',
          address: 'Vossersteeg 74',
          postal_code: '7722 RL',
          place: 'DALFSEN',
          phone: '0529-434006',
          farm_type: 'NC',
          program: 'BLKV01',
          sows_count: 0,
          updated_date: '2020-04-07T20:59:56.000Z',
          vlv: 2151,
          remark: '',
          created_at: '2020-05-28T11:29:45.048Z',
          updated_at: '2020-05-28T11:29:45.048Z',
          company_name: 'Vof Varkensbedrijf De Ruimte ',
          ...generateBlockchainRecord(),
        },
        {
          id: 63,
          ubn: '6070216',
          address: 'Weitemansweg 55',
          postal_code: '7608 RG',
          place: 'ALMELO',
          phone: '0546-862622',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 0,
          updated_date: '2020-04-08T20:59:56.000Z',
          vlv: 3800,
          remark: '',
          created_at: '2020-05-28T11:29:45.045Z',
          updated_at: '2020-05-28T11:29:45.045Z',
          company_name: 'Mts J.B. Timmerhuis ',
          ...generateBlockchainRecord(),
        },
        {
          id: 62,
          ubn: '0281403',
          address: 'Groenloseweg 13',
          postal_code: '7273 PG',
          place: 'HAARLO',
          phone: '0348-688769',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 761,
          updated_date: '2020-04-09T20:59:56.000Z',
          vlv: 60,
          remark: '',
          created_at: '2020-05-28T11:29:45.043Z',
          updated_at: '2020-05-28T11:29:45.043Z',
          company_name: 'Vof Finch Pork BV / Haarlo ',
          ...generateBlockchainRecord(),
        },
        {
          id: 61,
          ubn: '0287359',
          address: 'Hoonesweg 7',
          postal_code: '7152 AH',
          place: 'EIBERGEN',
          phone: '0545-431670',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 0,
          updated_date: '2020-04-08T20:59:56.000Z',
          vlv: 1648,
          remark: '',
          created_at: '2020-05-28T11:29:45.041Z',
          updated_at: '2020-05-28T11:29:45.041Z',
          company_name: 'Mts H.J. \u0026 H.A. Lammers ',
          ...generateBlockchainRecord(),
        },
        {
          id: 60,
          ubn: '1708459',
          address: 'Vaartweg 196',
          postal_code: '5106 NG',
          place: 'DONGEN',
          phone: '013-5159407',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 347,
          updated_date: '2020-04-05T20:59:56.000Z',
          vlv: 2596,
          remark: '',
          created_at: '2020-05-28T11:29:45.039Z',
          updated_at: '2020-05-28T11:29:45.039Z',
          company_name: 'J.A.C. den Boer ',
          ...generateBlockchainRecord(),
        },
        {
          id: 59,
          ubn: '0531799',
          address: 'Lage Haghorst  9',
          postal_code: '5087 TX',
          place: 'DIESSEN',
          phone: '013-5042719',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 290,
          updated_date: '2020-04-06T20:59:56.000Z',
          vlv: 1800,
          remark: '',
          created_at: '2020-05-28T11:29:45.037Z',
          updated_at: '2020-05-28T11:29:45.037Z',
          company_name: 'Mts H.A. Reijrink en C.M.J. Reijrink-Raijmakers ',
          ...generateBlockchainRecord(),
        },
        {
          id: 58,
          ubn: '1707175',
          address: 'Kleine Voort 7',
          postal_code: '5081 XG',
          place: 'HILVARENBEEK',
          phone: '013-5051507',
          farm_type: 'NC',
          program: 'BIO',
          sows_count: 203,
          updated_date: '2020-04-08T20:59:56.000Z',
          vlv: 956,
          remark: '',
          created_at: '2020-05-28T11:29:45.035Z',
          updated_at: '2020-05-28T11:29:45.035Z',
          company_name: 'N.C.Q. van den Broek ',
          ...generateBlockchainRecord(),
        },
        {
          id: 57,
          ubn: '2128313',
          address: 'Grote Heistraat 13',
          postal_code: '4884 JA',
          place: 'WERNHOUT',
          phone: '076-5972325',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 675,
          updated_date: '2020-04-05T20:59:56.000Z',
          vlv: 0,
          remark: '',
          created_at: '2020-05-28T11:29:45.033Z',
          updated_at: '2020-05-28T11:29:45.033Z',
          company_name: 'Mts Goetstouwers ',
          ...generateBlockchainRecord(),
        },
        {
          id: 56,
          ubn: '1809176',
          address: 'Eerste Molenweg 8',
          postal_code: '4741 RW',
          place: 'HOEVEN',
          phone: '0165-502423',
          farm_type: 'NC',
          program: 'GFW',
          sows_count: 0,
          updated_date: '2020-04-05T20:59:56.000Z',
          vlv: 4078,
          remark: '',
          created_at: '2020-05-28T11:29:45.031Z',
          updated_at: '2020-05-28T11:29:45.031Z',
          company_name: 'Vof Wijnen ',
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { CompanyReportPayload } from '../companyReports';
import { BY_PRODUCTS, BY_PRODUCTS_SUBMIT } from './config';

export type ByProductPayload = {
  id: number;
  status: 'draft' | 'submitted';
  company_report: CompanyReportPayload;
  reporting_period_date_start?: string;
  reporting_period_date_end?: string;
  purchase_date: string;
  product_code?: string;
  product_name?: string;
  product_origin?: string;
  product_quantity?: number;
  quantity_type?: string | null;
  dry_matter?: number;
  re?: number;
  ras?: number;
  vcre?: number;
  vcos?: number;
  is_product_code_manual_entered: boolean;
  updated_at: string;
  updated_by: {
    email: string;
    full_name: string;
  };
};

export type ByProductSerialized = {
  id: number;
  status: 'draft' | 'submitted';
  ubn: string;
  companyReport: number | null;
  reportingPeriodDateStart?: string;
  reportingPeriodDateEnd?: string;
  purchaseDate: string;
  productCode?: string;
  productName?: string;
  productOrigin?: string;
  productQuantity?: number;
  quantityType?: string | null;
  dryMatter?: number;
  re?: number;
  ras?: number;
  vcre?: number;
  vcos?: number;
  isProductCodeManualEntered: boolean;
  pigletsPercent?: number;
  sowsPercent?: number;
  pigsPercent?: number;
  updatedAt: string;
  updatedBy: string;
};

export type ByProductSubmitPayload = Omit<ByProductPayload, 'id' | 'updated_at' | 'updated_by'>;
export type ByProductSubmitSerialized = Omit<ByProductSerialized, 'id' | 'updatedAt' | 'updatedBy' | 'ubn'>;

const { columns: defaultColumns } = BY_PRODUCTS.ByProducts;
const { columns: submitColumns } = BY_PRODUCTS_SUBMIT.ByProductsSubmit;

const getDefaultSerializer = (data: DocumentData<ByProductPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(BY_PRODUCTS.ByProducts.unserialized, BY_PRODUCTS.ByProducts.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.status.unserialized, defaultColumns.status.serialized),
      new CellSerializer(defaultColumns.ubn.unserialized, defaultColumns.ubn.serialized),
      new CellSerializer(defaultColumns.idType.unserialized, defaultColumns.idType.serialized),
      new CellSerializer(defaultColumns.companyReport.unserialized, defaultColumns.companyReport.serialized),
      new CellSerializer(
        defaultColumns.reportingPeriodDateStart.unserialized,
        defaultColumns.reportingPeriodDateStart.serialized,
      ),
      new CellSerializer(
        defaultColumns.reportingPeriodDateEnd.unserialized,
        defaultColumns.reportingPeriodDateEnd.serialized,
      ),
      new CellSerializer(defaultColumns.purchaseDate.unserialized, defaultColumns.purchaseDate.serialized),
      new CellSerializer(defaultColumns.productCode.unserialized, defaultColumns.productCode.serialized),
      new CellSerializer(defaultColumns.productName.unserialized, defaultColumns.productName.serialized),
      new CellSerializer(defaultColumns.productOrigin.unserialized, defaultColumns.productOrigin.serialized),
      new CellSerializer(defaultColumns.productQuantity.unserialized, defaultColumns.productQuantity.serialized),
      new CellSerializer(defaultColumns.quantityType.unserialized, defaultColumns.quantityType.serialized),
      new CellSerializer(defaultColumns.dryMatter.unserialized, defaultColumns.dryMatter.serialized),
      new CellSerializer(defaultColumns.re.unserialized, defaultColumns.re.serialized),
      new CellSerializer(defaultColumns.ras.unserialized, defaultColumns.ras.serialized),
      new CellSerializer(defaultColumns.vcre.unserialized, defaultColumns.vcre.serialized),
      new CellSerializer(defaultColumns.vcos.unserialized, defaultColumns.vcos.serialized),
      new CellSerializer(
        defaultColumns.isProductCodeManualEntered.unserialized,
        defaultColumns.isProductCodeManualEntered.serialized,
      ),
      new CellSerializer(defaultColumns.pigletsPercent.unserialized, defaultColumns.pigletsPercent.serialized),
      new CellSerializer(defaultColumns.sowsPercent.unserialized, defaultColumns.sowsPercent.serialized),
      new CellSerializer(defaultColumns.pigsPercent.unserialized, defaultColumns.pigsPercent.serialized),
      new CellSerializer(defaultColumns.updatedAt.unserialized, defaultColumns.updatedAt.serialized),
      new CellSerializer(defaultColumns.updatedBy.unserialized, defaultColumns.updatedBy.serialized),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<ByProductSubmitSerialized[]>) => {
  return new DocumentSerializer<ByProductSubmitPayload, ByProductSubmitSerialized>(data, [
    new SheetSerializer(
      BY_PRODUCTS_SUBMIT.ByProductsSubmit.serialized,
      BY_PRODUCTS_SUBMIT.ByProductsSubmit.unserialized,
      [
        new CellSerializer(submitColumns.status.unserialized, submitColumns.status.serialized),
        new CellSerializer(submitColumns.companyReport.unserialized, submitColumns.companyReport.serialized),
        new CellSerializer(
          submitColumns.reportingPeriodDateStart.unserialized,
          submitColumns.reportingPeriodDateStart.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.reportingPeriodDateEnd.unserialized,
          submitColumns.reportingPeriodDateEnd.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(submitColumns.purchaseDate.unserialized, submitColumns.purchaseDate.serialized),
        new CellSerializer(
          submitColumns.productCode.unserialized,
          submitColumns.productCode.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.productName.unserialized,
          submitColumns.productName.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.productOrigin.unserialized,
          submitColumns.productOrigin.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.productQuantity.unserialized,
          submitColumns.productQuantity.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.quantityType.unserialized,
          submitColumns.quantityType.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.dryMatter.unserialized,
          submitColumns.dryMatter.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(submitColumns.re.unserialized, submitColumns.re.serialized, undefined, undefined, null),
        new CellSerializer(submitColumns.ras.unserialized, submitColumns.ras.serialized, undefined, undefined, null),
        new CellSerializer(submitColumns.vcre.unserialized, submitColumns.vcre.serialized, undefined, undefined, null),
        new CellSerializer(submitColumns.vcos.unserialized, submitColumns.vcos.serialized, undefined, undefined, null),
        new CellSerializer(
          submitColumns.isProductCodeManualEntered.unserialized,
          submitColumns.isProductCodeManualEntered.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.pigletsPercent.unserialized,
          submitColumns.pigletsPercent.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.sowsPercent.unserialized,
          submitColumns.sowsPercent.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.pigsPercent.unserialized,
          submitColumns.pigsPercent.serialized,
          undefined,
          undefined,
          null,
        ),
      ],
    ),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<ByProductSerialized[]>) => {
  return new DocumentSerializer<ByProductSerialized, ByProductSubmitSerialized>(data, [
    new SheetSerializer(
      BY_PRODUCTS_SUBMIT.ByProductsSubmit.serialized,
      BY_PRODUCTS_SUBMIT.ByProductsSubmit.unserialized,
      [
        new CellSerializer(defaultColumns.status.serialized, submitColumns.status.serialized),
        new CellSerializer(defaultColumns.companyReport.serialized, submitColumns.companyReport.serialized),
        new CellSerializer(
          defaultColumns.reportingPeriodDateStart.serialized,
          submitColumns.reportingPeriodDateStart.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.reportingPeriodDateEnd.serialized,
          submitColumns.reportingPeriodDateEnd.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(defaultColumns.purchaseDate.serialized, submitColumns.purchaseDate.serialized),
        new CellSerializer(
          defaultColumns.productCode.serialized,
          submitColumns.productCode.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.productName.serialized,
          submitColumns.productName.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.productOrigin.serialized,
          submitColumns.productOrigin.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.productQuantity.serialized,
          submitColumns.productQuantity.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.quantityType.serialized,
          submitColumns.quantityType.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.dryMatter.serialized,
          submitColumns.dryMatter.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(defaultColumns.re.serialized, submitColumns.re.serialized, undefined, undefined, undefined),
        new CellSerializer(
          defaultColumns.ras.serialized,
          submitColumns.ras.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.vcre.serialized,
          submitColumns.vcre.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.vcos.serialized,
          submitColumns.vcos.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.isProductCodeManualEntered.serialized,
          submitColumns.isProductCodeManualEntered.serialized,
        ),
        new CellSerializer(
          defaultColumns.pigletsPercent.serialized,
          submitColumns.pigletsPercent.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.sowsPercent.serialized,
          submitColumns.sowsPercent.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.pigsPercent.serialized,
          submitColumns.pigsPercent.serialized,
          undefined,
          undefined,
          undefined,
        ),
      ],
    ),
  ]);
};

export const serializeByProductReports = (serverData: ResourcesWrapper<ByProductPayload>) => {
  const { unserialized } = BY_PRODUCTS.ByProducts;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const serializeByProduct = (serverData: ResourceWrapper<ByProductPayload>) => {
  const { serialized, unserialized } = BY_PRODUCTS.ByProducts;
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

export const unserializeByProductForSubmit = (resource: ByProductSubmitSerialized): ByProductSubmitPayload => {
  const { serialized, unserialized } = BY_PRODUCTS_SUBMIT.ByProductsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeByProductStoSS = (resource: ByProductSerialized) => {
  const { serialized, unserialized } = BY_PRODUCTS_SUBMIT.ByProductsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

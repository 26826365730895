import qs from 'qs';

export const generateFiltersQuery = (search: string, dateFilterName = 'date') => {
  if (!search) {
    return '';
  }

  const { filter } = qs.parse(search.replace('?', '')) as {
    filter: { ubn: string; date: object };
  };

  return (
    '?' +
    qs.stringify(
      {
        filter: {
          ubn: filter.ubn,
          [dateFilterName]: filter.date,
        },
      },
      { encodeValuesOnly: true },
    )
  );
};

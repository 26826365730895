import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper } from 'lib/http/utils';
import { INVOICE_MESSAGES } from 'lib/tables/config/invoices/invoices/config';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

interface InvoiceMessageI {
  id: number;
  message_id: string;
  currency_code: string;
  overview_type: string;
  invoice_number: string;
  invoice_date: string;
  unique_message_number: string;
  supplier_name: string;
  supplier_postal_address_street: string;
  supplier_postal_address_house_number: string;
  supplier_postal_address_place: string;
  supplier_postal_address_postcode: string;
  supplier_ean_address_code: string;
  supplier_bank_account_number: string;
  supplier_iban: string;
  supplier_international_vat_number: string;
  customer_name: string;
  customer_street_address: string;
  customer_postal_address_place: string;
  customer_relation_number: string;
  deliveries: [
    {
      id: number;
      invoice: {
        id: number;
        message_id: string;
      };
      line_number: string;
      delivery_date: string;
      delivery_note_number: string;
      delivery_note_line_number: string;
      product_quantity: string;
      product_article_code: string;
      product_description: string;
      product_article_group_code: string;
      product_article_group_code_description: string;
    },
  ];
}

const { columns, serialized, unserialized } = INVOICE_MESSAGES.Invoices;
const {
  messageId,
  currencyCode,
  customerName,
  customerRelationNumber,
  customerPostalAddressPlace,
  customerStreetAddress,
  invoiceDate,
  invoiceNumber,
  overviewType,
  supplierBankAccount,
  supplierEANAddressCode,
  supplierHouseNumber,
  supplierIban,
  supplierName,
  supplierPlace,
  supplierPostCode,
  supplierStreet,
  supplierVatNumber,
  uniqueMessageNumber,
} = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(
        SERVICE_FIELDS.updatedAt.unserialized,
        SERVICE_FIELDS.updatedAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(messageId.unserialized, messageId.serialized),
      new CellSerializer(currencyCode.unserialized, currencyCode.serialized),
      new CellSerializer(customerName.unserialized, customerName.serialized),
      new CellSerializer(customerRelationNumber.unserialized, customerRelationNumber.serialized),
      new CellSerializer(customerPostalAddressPlace.unserialized, customerPostalAddressPlace.serialized),
      new CellSerializer(customerStreetAddress.unserialized, customerStreetAddress.serialized),
      new CellSerializer(invoiceDate.unserialized, invoiceDate.serialized, dateToFormattedString),
      new CellSerializer(invoiceNumber.unserialized, invoiceNumber.serialized),
      new CellSerializer(overviewType.unserialized, overviewType.serialized),
      new CellSerializer(supplierBankAccount.unserialized, supplierBankAccount.serialized),
      new CellSerializer(supplierEANAddressCode.unserialized, supplierEANAddressCode.serialized),
      new CellSerializer(supplierHouseNumber.unserialized, supplierHouseNumber.serialized),
      new CellSerializer(supplierIban.unserialized, supplierIban.serialized),
      new CellSerializer(supplierName.unserialized, supplierName.serialized),
      new CellSerializer(supplierPlace.unserialized, supplierPlace.serialized),
      new CellSerializer(supplierPostCode.unserialized, supplierPostCode.serialized),
      new CellSerializer(supplierStreet.unserialized, supplierStreet.serialized),
      new CellSerializer(supplierVatNumber.unserialized, supplierVatNumber.serialized),
      new CellSerializer(uniqueMessageNumber.unserialized, uniqueMessageNumber.serialized),

      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeInvoiceMessagesInfo = (serverData: ResourcesWrapper<InvoiceMessageI>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys';

enum ErrorsLogSerialized {
  ErrorsLog = 'ErrorsLog',
}

enum ErrorsLogUnserialized {
  ErrorsLog = 'ErrorsLog',
}

export enum ErrorsLogSerializedHeaders {
  id = 'id',
  logDatetime = 'logDatetime',
  errorLogType = 'errorLogType',
  errorBody = 'errorBody',
  errorCode = 'errorCode',
  createdAt = 'createdAt',
}

export const ErrorsLogUnserializedHeaders = {
  id: 'id',
  logDatetime: 'log_datetime',
  errorLogType: 'error_log_type',
  errorBody: ['error_data', 'error_body'],
  errorCode: ['error_data', 'error_code'],
  createdAt: 'created_at',
} as const;

export const ERRORS_LOG = {
  [ErrorsLogSerialized.ErrorsLog]: {
    serialized: ErrorsLogSerialized.ErrorsLog,
    unserialized: ErrorsLogUnserialized.ErrorsLog,
    view: IntlKeys.titleErrorsLog,

    columns: {
      [ErrorsLogSerializedHeaders.id]: {
        serialized: ErrorsLogSerializedHeaders.id,
        unserialized: ErrorsLogUnserializedHeaders.id,
        view: IntlKeys.id,
      },
      [ErrorsLogSerializedHeaders.logDatetime]: {
        serialized: ErrorsLogSerializedHeaders.logDatetime,
        unserialized: ErrorsLogUnserializedHeaders.logDatetime,
        view: IntlKeys.errorsLogLogDatetime,
      },
      [ErrorsLogSerializedHeaders.errorLogType]: {
        serialized: ErrorsLogSerializedHeaders.errorLogType,
        unserialized: ErrorsLogUnserializedHeaders.errorLogType,
        view: IntlKeys.errorsLogErrorLogType,
      },
      [ErrorsLogSerializedHeaders.errorBody]: {
        serialized: ErrorsLogSerializedHeaders.errorBody,
        unserialized: ErrorsLogUnserializedHeaders.errorBody,
        view: IntlKeys.errorsLogErrorBody,
      },
      [ErrorsLogSerializedHeaders.errorCode]: {
        serialized: ErrorsLogSerializedHeaders.errorCode,
        unserialized: ErrorsLogUnserializedHeaders.errorCode,
        view: IntlKeys.errorsLogErrorCode,
      },
      [ErrorsLogSerializedHeaders.createdAt]: {
        serialized: ErrorsLogSerializedHeaders.createdAt,
        unserialized: ErrorsLogUnserializedHeaders.createdAt,
        view: IntlKeys.serviceCreatedAt,
      },
    },
  },
};

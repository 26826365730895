import {
  CertificatesKiwaWeeklyFarms,
  CertificatesKiwaWeeklyRecognitions,
  CertificatesKiwaWeeklyDeviations,
} from 'lib/localization/keys/certificates';
import { TitlesKeys } from 'lib/localization/keys/titles';

enum HeadersOriginal {
  Recognition = 'Erkenning',
  Company = 'Bedrijf',
  Deviations = 'Afwijkingen',
}
enum HeadersSerialized {
  Recognition = 'Recognition',
  Company = 'Company',
  Deviations = 'Deviations',
}
enum HeadersUnserialized {
  Recognition = 'recognitions',
  Company = 'companies',
  Deviations = 'deviations',
}
// enum HeadersView {
//   Recognition = 'Erkenning',
//   Company = 'Bedrijf',
//   Deviations = 'Afwijkingen',
// }
//
// UBN	Name	Address	Postal Code	Place	Phone	Date	sows	Vlv	Program	remark	Type
// UBN	Naam	Adres	Postcode	Plaats	Telefoon	Datum	Zeugen	Vlv	Programma	Opmerking	Type

enum CompanyHeadersOriginal {
  UBN = 'UBN',
  Name = 'Naam',
  Address = 'Adres',
  PostalCode = 'Postcode',
  Place = 'Plaats',
  Phone = 'Telefoon',
  Date = 'Datum',
  Sows = 'Zeugen',
  Vlv = 'Vlv',
  Program = 'Programma',
  Remark = 'Opmerking',
  Type = 'Type',
}

enum CompanySerializedHeaders {
  UBN = 'ubn',
  Name = 'name',
  Address = 'address',
  PostalCode = 'postalCode',
  Place = 'place',
  Phone = 'phone',
  Date = 'date',
  Sows = 'sows',
  Vlv = 'vlv',
  Program = 'program',
  Remark = 'remark',
  Type = 'type',
}
enum CompanyUnserializedHeaders {
  UBN = 'ubn',
  Name = 'company_name',
  Address = 'address',
  PostalCode = 'postal_code',
  Place = 'place',
  Phone = 'phone',
  Date = 'updated_date',
  Sows = 'sows_count',
  Vlv = 'vlv',
  Program = 'program',
  Remark = 'remark',
  Type = 'farm_type',
}

// enum CompanyViewHeaders {
//   UBN = 'UBN',
//   Name = 'Naam',
//   Address = 'Adres',
//   PostalCode = 'Postcode',
//   Place = 'Plaats',
//   Phone = 'Telefoon',
//   Date = 'Datum',
//   Sows = 'Zeugen',
//   Vlv = 'Vlv',
//   Program = 'Programma',
//   Remark = 'Opmerking',
//   Type = 'Type',
// }

// UBN	Date	Program	Code	Regnr	Name
// UBN	Datum	Programma	Code	Regnr	Naam

enum RecognitionOriginalHeaders {
  UBN = 'UBN',
  Date = 'Datum',
  Program = 'Programma',
  Code = 'Code',
  Regnr = 'Regnr',
  Name = 'Naam',
}
enum RecognitionSerializedHeaders {
  UBN = 'ubn',
  Date = 'date',
  Program = 'program',
  Code = 'code',
  Regnr = 'regnr',
  Name = 'name',
}
enum RecognitionUnserializedHeaders {
  UBN = 'ubn',
  Date = 'updated_date',
  Program = 'program',
  Code = 'code',
  Regnr = 'reg_number',
  Name = 'name',
}
// enum RecognitionViewHeaders {
//   UBN = 'UBN',
//   Date = 'Datum',
//   Program = 'Programma',
//   Code = 'Code',
//   Regnr = 'Regnr',
//   Name = 'Naam',
// }

// UBN	Program	Date	question Code	remark	Answer
// UBN	Programma	Datum	VraagCode	Opmerking	Antwoord

enum DeviationsOriginalHeaders {
  UBN = 'UBN',
  Program = 'Programma',
  Date = 'Datum',
  QuestionCode = 'VraagCode',
  Remark = 'Opmerking',
  Answer = 'Antwoord',
}
enum DeviationsSerializedHeaders {
  UBN = 'ubn',
  Program = 'program',
  Date = 'date',
  QuestionCode = 'questionCode',
  Remark = 'remark',
  Answer = 'answer',
}
enum DeviationsUnserializedHeaders {
  UBN = 'ubn',
  Program = 'program',
  Date = 'updated_date',
  QuestionCode = 'code',
  Remark = 'remark',
  Answer = 'answer',
}
// enum DeviationsViewHeaders {
//   UBN = 'UBN',
//   Program = 'Programma',
//   Date = 'Datum',
//   QuestionCode = 'VraagCode',
//   Remark = 'Opmerking',
//   Answer = 'Antwoord',
// }

export const WEEKLY_DOCUMENT_FIELDS = {
  [HeadersSerialized.Deviations]: {
    original: HeadersOriginal.Deviations,
    serialized: HeadersSerialized.Deviations,
    unserialized: HeadersUnserialized.Deviations,
    view: TitlesKeys.certificatesDeviations,
    viewName: 'Deviations',
    columns: {
      [DeviationsSerializedHeaders.UBN]: {
        original: DeviationsOriginalHeaders.UBN,
        serialized: DeviationsSerializedHeaders.UBN,
        unserialized: DeviationsUnserializedHeaders.UBN,
        view: CertificatesKiwaWeeklyDeviations.UBN,
      },
      [DeviationsSerializedHeaders.Program]: {
        original: DeviationsOriginalHeaders.Program,
        serialized: DeviationsSerializedHeaders.Program,
        unserialized: DeviationsUnserializedHeaders.Program,
        view: CertificatesKiwaWeeklyDeviations.Program,
      },
      [DeviationsSerializedHeaders.Date]: {
        original: DeviationsOriginalHeaders.Date,
        serialized: DeviationsSerializedHeaders.Date,
        unserialized: DeviationsUnserializedHeaders.Date,
        view: CertificatesKiwaWeeklyDeviations.Date,
      },
      [DeviationsSerializedHeaders.QuestionCode]: {
        original: DeviationsOriginalHeaders.QuestionCode,
        serialized: DeviationsSerializedHeaders.QuestionCode,
        unserialized: DeviationsUnserializedHeaders.QuestionCode,
        view: CertificatesKiwaWeeklyDeviations.QuestionCode,
      },
      [DeviationsSerializedHeaders.Remark]: {
        original: DeviationsOriginalHeaders.Remark,
        serialized: DeviationsSerializedHeaders.Remark,
        unserialized: DeviationsUnserializedHeaders.Remark,
        view: CertificatesKiwaWeeklyDeviations.Remark,
      },
      [DeviationsSerializedHeaders.Answer]: {
        original: DeviationsOriginalHeaders.Answer,
        serialized: DeviationsSerializedHeaders.Answer,
        unserialized: DeviationsUnserializedHeaders.Answer,
        view: CertificatesKiwaWeeklyDeviations.Answer,
      },
    },
  },

  [HeadersSerialized.Recognition]: {
    original: HeadersOriginal.Recognition,
    serialized: HeadersSerialized.Recognition,
    unserialized: HeadersUnserialized.Recognition,
    view: TitlesKeys.certificatesRecognitions,
    viewName: 'Recognitions',
    columns: {
      [RecognitionSerializedHeaders.UBN]: {
        original: RecognitionOriginalHeaders.UBN,
        serialized: RecognitionSerializedHeaders.UBN,
        unserialized: RecognitionUnserializedHeaders.UBN,
        view: CertificatesKiwaWeeklyRecognitions.UBN,
      },
      [RecognitionSerializedHeaders.Date]: {
        original: RecognitionOriginalHeaders.Date,
        serialized: RecognitionSerializedHeaders.Date,
        unserialized: RecognitionUnserializedHeaders.Date,
        view: CertificatesKiwaWeeklyRecognitions.Date,
      },
      [RecognitionSerializedHeaders.Program]: {
        original: RecognitionOriginalHeaders.Program,
        serialized: RecognitionSerializedHeaders.Program,
        unserialized: RecognitionUnserializedHeaders.Program,
        view: CertificatesKiwaWeeklyRecognitions.Program,
      },
      [RecognitionSerializedHeaders.Code]: {
        original: RecognitionOriginalHeaders.Code,
        serialized: RecognitionSerializedHeaders.Code,
        unserialized: RecognitionUnserializedHeaders.Code,
        view: CertificatesKiwaWeeklyRecognitions.Code,
      },
      [RecognitionSerializedHeaders.Regnr]: {
        original: RecognitionOriginalHeaders.Regnr,
        serialized: RecognitionSerializedHeaders.Regnr,
        unserialized: RecognitionUnserializedHeaders.Regnr,
        view: CertificatesKiwaWeeklyRecognitions.Regnr,
      },
      [RecognitionSerializedHeaders.Name]: {
        original: RecognitionOriginalHeaders.Name,
        serialized: RecognitionSerializedHeaders.Name,
        unserialized: RecognitionUnserializedHeaders.Name,
        view: CertificatesKiwaWeeklyRecognitions.Name,
      },
    },
  },

  [HeadersSerialized.Company]: {
    original: HeadersOriginal.Company,
    serialized: HeadersSerialized.Company,
    unserialized: HeadersUnserialized.Company,
    view: TitlesKeys.certificatesFarms,
    viewName: 'Farms',
    columns: {
      [CompanySerializedHeaders.UBN]: {
        original: CompanyHeadersOriginal.UBN,
        serialized: CompanySerializedHeaders.UBN,
        unserialized: CompanyUnserializedHeaders.UBN,
        view: CertificatesKiwaWeeklyFarms.UBN,
      },
      [CompanySerializedHeaders.Name]: {
        original: CompanyHeadersOriginal.Name,
        serialized: CompanySerializedHeaders.Name,
        unserialized: CompanyUnserializedHeaders.Name,
        view: CertificatesKiwaWeeklyFarms.Name,
      },
      [CompanySerializedHeaders.Address]: {
        original: CompanyHeadersOriginal.Address,
        serialized: CompanySerializedHeaders.Address,
        unserialized: CompanyUnserializedHeaders.Address,
        view: CertificatesKiwaWeeklyFarms.Address,
      },
      [CompanySerializedHeaders.PostalCode]: {
        original: CompanyHeadersOriginal.PostalCode,
        serialized: CompanySerializedHeaders.PostalCode,
        unserialized: CompanyUnserializedHeaders.PostalCode,
        view: CertificatesKiwaWeeklyFarms.PostalCode,
      },
      [CompanySerializedHeaders.Place]: {
        original: CompanyHeadersOriginal.Place,
        serialized: CompanySerializedHeaders.Place,
        unserialized: CompanyUnserializedHeaders.Place,
        view: CertificatesKiwaWeeklyFarms.Place,
      },
      [CompanySerializedHeaders.Phone]: {
        original: CompanyHeadersOriginal.Phone,
        serialized: CompanySerializedHeaders.Phone,
        unserialized: CompanyUnserializedHeaders.Phone,
        view: CertificatesKiwaWeeklyFarms.Phone,
      },
      [CompanySerializedHeaders.Date]: {
        original: CompanyHeadersOriginal.Date,
        serialized: CompanySerializedHeaders.Date,
        unserialized: CompanyUnserializedHeaders.Date,
        view: CertificatesKiwaWeeklyFarms.Date,
      },
      [CompanySerializedHeaders.Sows]: {
        original: CompanyHeadersOriginal.Sows,
        serialized: CompanySerializedHeaders.Sows,
        unserialized: CompanyUnserializedHeaders.Sows,
        view: CertificatesKiwaWeeklyFarms.Sows,
      },
      [CompanySerializedHeaders.Vlv]: {
        original: CompanyHeadersOriginal.Vlv,
        serialized: CompanySerializedHeaders.Vlv,
        unserialized: CompanyUnserializedHeaders.Vlv,
        view: CertificatesKiwaWeeklyFarms.Vlv,
      },
      [CompanySerializedHeaders.Program]: {
        original: CompanyHeadersOriginal.Program,
        serialized: CompanySerializedHeaders.Program,
        unserialized: CompanyUnserializedHeaders.Program,
        view: CertificatesKiwaWeeklyFarms.Program,
      },
      [CompanySerializedHeaders.Remark]: {
        original: CompanyHeadersOriginal.Remark,
        serialized: CompanySerializedHeaders.Remark,
        unserialized: CompanyUnserializedHeaders.Remark,
        view: CertificatesKiwaWeeklyFarms.Remark,
      },
      [CompanySerializedHeaders.Type]: {
        original: CompanyHeadersOriginal.Type,
        serialized: CompanySerializedHeaders.Type,
        unserialized: CompanyUnserializedHeaders.Type,
        view: CertificatesKiwaWeeklyFarms.Type,
      },
    },
  },
};

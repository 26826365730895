import { createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { isEqual } from 'lodash';

import { syncAction } from 'lib/actions';
import { LOCATION_CHANGE } from 'connected-react-router';

interface MapReducer {
  isShown: boolean;
  rows: Array<object>;
}

const initialState: MapReducer = {
  isShown: false,
  rows: new Array<object>(),
};

export const toggleShowMap = syncAction<boolean>('map/toggleMap');
export const toggleRowAtMap = syncAction<object>('map/toggleRow');

export const mapReducer = createReducer(initialState, {
  [LOCATION_CHANGE]: (state) => {
    state.isShown = false;
    state.rows = new Array<object>();
  },
  [toggleShowMap.type]: (state, { payload }) => {
    state.isShown = payload;
  },
  [toggleRowAtMap.type]: (state, { payload }) => {
    if (state.rows.some((row) => isEqual(row, payload))) {
      state.rows = state.rows.filter((row) => isEqual(row, payload));
    } else {
      state.rows.push(payload);
    }
  },
});

export const isMapShownSelector = (state: RootState) => state.map.isShown;

export const mapRowsSelector = (state: RootState) => state.map.rows;

export default mapReducer;

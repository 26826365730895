import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const certificatesDaily = new Route('get', new RouteParser('/certificates/grouped_index'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 4987,
          ubn: '2222222',
          country_code: 'NL',
          business_type: 'Mugur Test 234',
          valid_from: '2004-02-03T20:59:56.000Z',
          valid_to: '2021-01-31T20:59:56.000Z',
          has_own_transport: false,
          remark: 'Test',
          vc: 'Castreert niet',
          qs_number: '528000000011446',
          gg_number: '4052852117081',
          pigs_tomorrow: false,
          blk: false,
          comment: '',
          salmonella_details: [
            { id: 9974, stat_type: 'Salmonella Stat. T2-2019', count: 1 },
            { id: 9973, stat_type: 'Salmonella Stat. T1-2020', count: 2 },
          ],
          ...generateBlockchainRecord(),
        },
        {
          id: 4986,
          ubn: '1111111',
          country_code: 'NL',
          business_type: 'Mugur Test',
          valid_from: '2004-02-03T20:59:56.000Z',
          valid_to: '2021-01-31T20:59:56.000Z',
          has_own_transport: false,
          remark: 'Test',
          vc: 'Castreert niet',
          qs_number: '528000000011446',
          gg_number: '4052852117081',
          pigs_tomorrow: false,
          blk: false,
          comment: '',
          salmonella_details: [
            { id: 9972, stat_type: 'Salmonella Stat. T2-2019', count: 1 },
            { id: 9971, stat_type: 'Salmonella Stat. T1-2020', count: 2 },
          ],
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

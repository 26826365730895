import { AnyAction } from 'redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AxiosError } from 'axios';

import { ApiClient } from 'lib/http/ApiClient';
import ensure, { Ensure } from 'lib/ensure';
import { getUrl } from 'routes';

import {
  getUserAction,
  resetPasswordAction,
  passwordChangeAction,
  signInAction,
  signOutAction,
  setMaintenanceAction,
} from 'store/auth/reducer';
import { getUserByTokenAction } from 'store/reducers/userSettingsReducer';
import { Serializer } from 'store/serializers/Serializer';
import { AuthInfo, AuthInfoI } from 'store/auth/entities';
import {
  removeAuthHeadersFromStorage,
  removePrefLangFromStorage,
  saveAuthHeadersInStorage
} from 'store/auth/helpers';
import { getTableConfig } from '../reducers/userSettingsReducer';
import { getSideMenuCounters } from 'store/reducers/uiReducer';
import { removeFilterSettingsFromStorage } from 'lib/filters';
import { ClientStorage, PREF_LANG } from 'lib/storage/Storage';
import { CustomAction } from 'lib/actions';

const callEnsure = (ensure: Ensure) =>
  function* ({ payload }: CustomAction) {
    yield call(ensure, payload);
  };

const ensureSignIn = ensure({
  api: ApiClient.signIn,
  action: signInAction,
  serializeSuccessPayload: (response) => {
    const serializedPayload = Serializer.serialize<AuthInfoI>(AuthInfo, response);
    ClientStorage.setItem(PREF_LANG, `${(serializedPayload.user as any).prefLang}`);
    return serializedPayload;
  },
  serializeFailurePayload: (error: AxiosError) => {
    let errorMsg = 'Unknown error.';

    if (error.response?.status === 401) {
      errorMsg = (error.response.data as any).errors[0];
    }

    return { errorMsg, status: error.response?.status };
  },
});

const ensureSignOut = ensure({
  api: ApiClient.signOut,
  action: signOutAction,
});

const ensureGetUser = ensure({
  api: ApiClient.getUser,
  action: getUserAction,
  serializeSuccessPayload: (response) => {
    return Serializer.serialize<AuthInfoI>(AuthInfo, response);
  },
});

const ensureResetPassword = ensure({
  api: ApiClient.resetPassword,
  action: resetPasswordAction,
  serializeFailurePayload: (error: AxiosError) => {
    return { status: error.response?.status };
  },
});

const ensureChangePassword = ensure({
  api: ApiClient.changePassword,
  action: passwordChangeAction,
});

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

function* login({ payload }: AnyAction) {
  try {
    const response = yield call(ensureSignIn, payload);
    const {
      payload: { headers, status },
    } = response;

    yield* catchMaintenance(status);

    if (headers) {
      yield put(getTableConfig());
      yield put(getSideMenuCounters());

      removeFilterSettingsFromStorage();

      saveAuthHeadersInStorage(headers);

      yield put(push(getUrl()));
    }
  } catch (e) {
    //
  }
}

function* logout() {
  try {
    removeAuthHeadersFromStorage();
    removeFilterSettingsFromStorage();
    removePrefLangFromStorage();
    yield call(ensureSignOut);
  } catch (e) {
    //
  }
}

function* requestUser() {
  //
  yield call(ensureGetUser, {
    onFailure: function* () {
      removeAuthHeadersFromStorage();
      yield put({ type: signOutAction.success });
    },
  });
}

function* resetPassword({ payload }: AnyAction) {
  try {
    const { payload: responsePayload } = yield call(ensureResetPassword, payload);

    yield* catchMaintenance(responsePayload.status);
  } catch (e) {
    //
  }
}

function* changePassword({ payload }: AnyAction) {
  try {
    yield call(ensureChangePassword, payload);
    yield put(getTableConfig());
    yield put(getSideMenuCounters());
  } catch (e) {
    //
  }
}
////////////////////////////////////////////////////////////////////////////////////

function* catchMaintenance(status: number) {
  if ([500, 502].includes(status)) {
    yield put(setMaintenanceAction(true));
  }
}

////////////////////////////////////////////////////////////////////////////////////

const ensureGetUserByToken = ensure({
  api: ApiClient.userByToken,
  action: getUserByTokenAction,
});

export default function* () {
  //
  yield takeEvery(signInAction.request, login);
  yield takeEvery(signOutAction.request, logout);
  yield takeEvery(getUserAction.request, requestUser);
  yield takeEvery(resetPasswordAction.request, resetPassword);
  yield takeEvery(passwordChangeAction.request, changePassword);
  yield takeEvery(getUserByTokenAction.request, callEnsure(ensureGetUserByToken));
}

import { CVB_TABLE_FIELDS } from '../structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo2DigitsFloating, roundTo5DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = CVB_TABLE_FIELDS.CvbTable;

export const getCvbTableView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.productCode.serialized, viewPropName: columns.productCode.view }),
      // new CellViewer({
      //   serializedPropName: columns.productSubCode.serialized,
      //   viewPropName: columns.productSubCode.view,
      // }),
      new CellViewer({ serializedPropName: columns.productType.serialized, viewPropName: columns.productType.view }),
      new CellViewer({
        serializedPropName: columns.productNameNL.serialized,
        viewPropName: columns.productNameNL.view,
      }),
      new CellViewer({ serializedPropName: columns.classNameNL.serialized, viewPropName: columns.classNameNL.view }),
      new CellViewer({
        serializedPropName: columns.subClassNameNL.serialized,
        viewPropName: columns.subClassNameNL.view,
      }),
      new CellViewer({
        serializedPropName: columns.productNameEN.serialized,
        viewPropName: columns.productNameEN.view,
      }),
      new CellViewer({ serializedPropName: columns.classNameEN.serialized, viewPropName: columns.classNameEN.view }),
      new CellViewer({
        serializedPropName: columns.subClassNameEN.serialized,
        viewPropName: columns.subClassNameEN.view,
      }),
      new CellViewer({
        serializedPropName: columns.ds.serialized,
        viewPropName: columns.ds.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.dsUnit.serialized, viewPropName: columns.dsUnit.view }),
      new CellViewer({
        serializedPropName: columns.ras.serialized,
        viewPropName: columns.ras.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.rasUnit.serialized, viewPropName: columns.rasUnit.view }),
      new CellViewer({
        serializedPropName: columns.rein.serialized,
        viewPropName: columns.rein.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.reinUnit.serialized, viewPropName: columns.reinUnit.view }),
      new CellViewer({
        serializedPropName: columns.re.serialized,
        viewPropName: columns.re.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.reUnit.serialized, viewPropName: columns.reUnit.view }),
      new CellViewer({
        serializedPropName: columns.cVcRe.serialized,
        viewPropName: columns.cVcRe.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo5DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.cVcReUnit.serialized, viewPropName: columns.cVcReUnit.view }),
      new CellViewer({
        serializedPropName: columns.cVcOs.serialized,
        viewPropName: columns.cVcOs.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo5DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.cVcOsUnit.serialized, viewPropName: columns.cVcOsUnit.view }),
    ]),
  ]);
};

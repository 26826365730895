import { IntlKeys } from 'lib/localization/keys';

enum GroupArrivalSerialized {
  GroupArrival = 'GroupArrival',
}

enum GroupArrivalUnserialized {
  GroupArrival = 'GroupArrival',
}

export enum GroupArrivalSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  financialEventIdentifier = 'financialEventIdentifier',
  animalCategoryIdentifier = 'animalCategoryIdentifier',
  groupArrivalNumber = 'groupArrivalNumber',
  totalWeight = 'totalWeight',
  originIdentifier = 'originIdentifier',
  groupArrivalDatetime = 'groupArrivalDatetime',
  ownFarm = 'ownFarm',
  withoutMale = 'withoutMale',
  withoutFemale = 'withoutFemale',
  withoutMaleCastrated = 'withoutMaleCastrated',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupArrivalUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  financialEventIdentifier: 'financial_event_identifier',
  animalCategoryIdentifier: 'animal_category_identifier',
  groupArrivalNumber: 'group_arrival_number',
  totalWeight: 'total_weight',
  originIdentifier: 'origin_identifier',
  groupArrivalDatetime: 'group_arrival_datetime',
  ownFarm: 'own_farm',
  withoutMale: 'without_male',
  withoutFemale: 'without_female',
  withoutMaleCastrated: 'without_male_castrated',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_ARRIVAL = {
  [GroupArrivalSerialized.GroupArrival]: {
    serialized: GroupArrivalSerialized.GroupArrival,
    unserialized: GroupArrivalUnserialized.GroupArrival,
    view: IntlKeys.farmManagementDataArrivalList,

    columns: {
      [GroupArrivalSerializedHeaders.id]: {
        serialized: GroupArrivalSerializedHeaders.id,
        unserialized: GroupArrivalUnserializedHeaders.id,
      },
      [GroupArrivalSerializedHeaders.companyIdentifier]: {
        serialized: GroupArrivalSerializedHeaders.companyIdentifier,
        unserialized: GroupArrivalUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupArrivalSerializedHeaders.farmIdentifier]: {
        serialized: GroupArrivalSerializedHeaders.farmIdentifier,
        unserialized: GroupArrivalUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupArrivalSerializedHeaders.financialEventIdentifier]: {
        serialized: GroupArrivalSerializedHeaders.financialEventIdentifier,
        unserialized: GroupArrivalUnserializedHeaders.financialEventIdentifier,
        view: IntlKeys.farmManagementDataFinancialEventId,
      },
      [GroupArrivalSerializedHeaders.animalCategoryIdentifier]: {
        serialized: GroupArrivalSerializedHeaders.animalCategoryIdentifier,
        unserialized: GroupArrivalUnserializedHeaders.animalCategoryIdentifier,
        view: IntlKeys.farmManagementDataAnimalCategoryId,
      },
      [GroupArrivalSerializedHeaders.groupArrivalNumber]: {
        serialized: GroupArrivalSerializedHeaders.groupArrivalNumber,
        unserialized: GroupArrivalUnserializedHeaders.groupArrivalNumber,
        view: IntlKeys.farmManagementDataGroupArrivalNumber,
      },
      [GroupArrivalSerializedHeaders.totalWeight]: {
        serialized: GroupArrivalSerializedHeaders.totalWeight,
        unserialized: GroupArrivalUnserializedHeaders.totalWeight,
        view: IntlKeys.farmManagementDataTotalWeight,
      },
      [GroupArrivalSerializedHeaders.originIdentifier]: {
        serialized: GroupArrivalSerializedHeaders.originIdentifier,
        unserialized: GroupArrivalUnserializedHeaders.originIdentifier,
        view: IntlKeys.farmManagementDataOriginId,
      },
      [GroupArrivalSerializedHeaders.groupArrivalDatetime]: {
        serialized: GroupArrivalSerializedHeaders.groupArrivalDatetime,
        unserialized: GroupArrivalUnserializedHeaders.groupArrivalDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupArrivalSerializedHeaders.ownFarm]: {
        serialized: GroupArrivalSerializedHeaders.ownFarm,
        unserialized: GroupArrivalUnserializedHeaders.ownFarm,
        view: IntlKeys.farmManagementDataOwnFarm,
      },
      [GroupArrivalSerializedHeaders.withoutMale]: {
        serialized: GroupArrivalSerializedHeaders.withoutMale,
        unserialized: GroupArrivalUnserializedHeaders.withoutMale,
        view: IntlKeys.farmManagementDataWithoutMale,
      },
      [GroupArrivalSerializedHeaders.withoutFemale]: {
        serialized: GroupArrivalSerializedHeaders.withoutFemale,
        unserialized: GroupArrivalUnserializedHeaders.withoutFemale,
        view: IntlKeys.farmManagementDataWithoutFemale,
      },
      [GroupArrivalSerializedHeaders.withoutMaleCastrated]: {
        serialized: GroupArrivalSerializedHeaders.withoutMaleCastrated,
        unserialized: GroupArrivalUnserializedHeaders.withoutMaleCastrated,
        view: IntlKeys.farmManagementDataWithoutMaleCastrated,
      },
      [GroupArrivalSerializedHeaders.ubnList]: {
        serialized: GroupArrivalSerializedHeaders.ubnList,
        unserialized: GroupArrivalUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupArrivalSerializedHeaders.kvkList]: {
        serialized: GroupArrivalSerializedHeaders.kvkList,
        unserialized: GroupArrivalUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupArrivalSerializedHeaders.registrationNameList]: {
        serialized: GroupArrivalSerializedHeaders.registrationNameList,
        unserialized: GroupArrivalUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import React from 'react';
import { useIntl } from 'react-intl';

import { IntlKeys } from 'lib/localization/keys';

import DataContainer, { DataType } from '../components/DataContainer';
import GridContainer from '../components/GridContainer';

interface OwnProps {
  data: Record<string, number>;
}
const Transport: React.FC<OwnProps> = ({ data }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {Object.entries(data).map(([type, count]) => (
        <GridContainer key={type}>
          <DataContainer label={formatMessage({ id: IntlKeys.animalPassportAnimalCategory })} data={type} />

          <div>
            <DataContainer
              label={formatMessage({ id: IntlKeys.animalPassportTransportNumberOfAnimals })}
              data={count}
              dataType={DataType.Number}
            />
          </div>
        </GridContainer>
      ))}
    </div>
  );
};

export default React.memo(Transport);

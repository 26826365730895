import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';

import {
  USER_PASSPORTS,
  UserPassportsSerializedHeaders,
  UserPassportsUnserializedHeaders,
} from 'lib/tables/config/userPassports/config';

import {
  PermissionProfileResponseEntry,
  PermissionProfileResponseEntrySerialized,
  serializePermissionProfile,
} from 'store/admin/entities';

const { columns: upColumns } = USER_PASSPORTS.UserPassports;

export interface UbnRelatedImpersonalUsersOriginal {
  [UserPassportsUnserializedHeaders.organizationPermissionProfile]: PermissionProfileResponseEntry | null;
}

export interface UbnRelatedImpersonalUsersSerialized {
  [UserPassportsSerializedHeaders.organizationPermissionProfile]: PermissionProfileResponseEntrySerialized;
}

export const serializeUbnRelatedImpersonalUsers = (serverData: ResourcesWrapper<UbnRelatedImpersonalUsersOriginal>) => {
  const tempKey = 'ubnRelatedImpersonalUsers';

  const data = {
    [tempKey]: serverData.data.resources,
  };

  const serializer = new DocumentSerializer(data, [
    new SheetSerializer(tempKey, tempKey, [
      new CellSerializer(
        upColumns[UserPassportsSerializedHeaders.organizationPermissionProfile].unserialized,
        upColumns[UserPassportsSerializedHeaders.organizationPermissionProfile].serialized,
        (data, sheetData, excelRow, propertyOriginalName) => {
          const permissionProfile = (excelRow[propertyOriginalName as keyof typeof excelRow] || {
            id: 0,
            title: '',
            notes: '',
            permission_rules: [],
          }) as PermissionProfileResponseEntry;

          return serializePermissionProfile(permissionProfile);
        },
      ),
    ]),
  ]);

  return serializer.serialize()[tempKey];
};

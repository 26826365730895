import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import rowContextMenu from './rowContextMenu';

import { GROUP_EXPENSE } from 'store/entities/farmManagementData/groupExpense/config';

const { columns, view, serialized } = GROUP_EXPENSE.GroupExpense;

export const groupExpenseTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.finEventIdentifier.view,
          field: columns.finEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.periodFrom.view,
          field: columns.periodFrom.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.periodTo.view,
          field: columns.periodTo.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentSows.view,
          field: columns.percentSows.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentFinishers.view,
          field: columns.percentFinishers.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentReplGilts.view,
          field: columns.percentReplGilts.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.percentWeaners.view,
          field: columns.percentWeaners.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.expenseDatetime.view,
          field: columns.expenseDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.accountIdentifier.view,
          field: columns.accountIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.number.view,
          field: columns.number.serialized,
        },
      }),
    ],
  }),
]);

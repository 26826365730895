import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { GROUP_PURCHASED_FOOD } from 'store/entities/farmManagementData/groupPurchasedFood/config';

const { columns, view, serialized } = GROUP_PURCHASED_FOOD.GroupPurchasedFood;

export const groupPurchasedFoodTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.finEventIdentifier.view,
          field: columns.finEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.sows.view,
          field: columns.sows.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.finishers.view,
          field: columns.finishers.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.gilts.view,
          field: columns.gilts.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.weaners.view,
          field: columns.weaners.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.totalWeight.view,
          field: columns.totalWeight.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.eV.view,
          field: columns.eV.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.dM.view,
          field: columns.dM.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.purchasedFoodDatetime.view,
          field: columns.purchasedFoodDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.acctIdentifier.view,
          field: columns.acctIdentifier.serialized,
        },
      }),
    ],
  }),
]);

import moment from 'moment';

import { algorithms } from './utils/customSorting';

type Value = string | number;
type DataType = undefined | keyof typeof algorithms;

class CustomComparator {
  compare = (val1: Value, val2: Value, predefinedType?: DataType): number => {
    //
    const type: DataType = predefinedType || this.resolveType(val1, val2);

    switch (type) {
      case 'numeric':
        return this.compareNumeric(val1, val2);
      case 'date':
        return this.compareDate(val1, val2);
      case 'unknown':
      default:
        return this.compareUnknown(val1, val2);
    }
  };

  ////////////////////////////////////////////////////////////
  resolveType = (val1: Value, val2: Value): DataType => {
    if (this.isNumeric(val1) && this.isNumeric(val2)) {
      return 'numeric';
    } else if (this.isDate(val1) && this.isDate(val2)) {
      return 'date';
    }

    return 'unknown';
  };
  ////////////////////////////////////////////////////////////

  isNumeric = (val: Value) => {
    return !isNaN(Number(val));
  };

  compareNumeric = (val1: Value, val2: Value) => {
    return algorithms.numeric(Number(val1), Number(val2));
  };

  isDate = (val: Value) => {
    return moment(val).isValid();
  };

  compareDate = (val1: Value, val2: Value) => {
    return algorithms.date(val1, val2);
  };

  compareUnknown = (val1: Value, val2: Value) => {
    return algorithms.unknown(val1, val2);
  };
}

const Comparator = new CustomComparator();

export default Comparator;

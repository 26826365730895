import { ELECTRICITY_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/electricityConsumptionRates/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = ELECTRICITY_CONSUMPTION_RATES_FIELDS.ElectricityConsumptionRates;

export const getElectricityConsumptionRatesDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.householdPeopleQuantity.serialized,
        extractPropName: columns.householdPeopleQuantity.view,
      }),
      new DisplayHeader({
        headerName: columns.electricityConsumption.serialized,
        extractPropName: columns.electricityConsumption.view,
      }),
    ]),
  ]);
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { SLAUGHTER_CHARGE_OR_PREMIUMS } from 'lib/tables/config/slaughter/chargeOrPremiums/config';

interface SlaughterChargeOrPremiums {
  id: number;
  code_list_id: string;
  code: string;
  base_code: string;
  amount: number;
  vat_percentage: number;
  vat_amount: number;
  created_at: string;
  updated_at: string;
}

const { columns, serialized, unserialized } = SLAUGHTER_CHARGE_OR_PREMIUMS.SlaughterChargeOrPremiums;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      //
      new CellSerializer(columns.codeListId.unserialized, columns.codeListId.serialized),
      new CellSerializer(columns.code.unserialized, columns.code.serialized),
      new CellSerializer(columns.baseCode.unserialized, columns.baseCode.serialized),
      new CellSerializer(columns.amount.unserialized, columns.amount.serialized),
      new CellSerializer(columns.vatPercentage.unserialized, columns.vatPercentage.serialized),
      new CellSerializer(columns.vatAmount.unserialized, columns.vatAmount.serialized),
      //
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
    ]),
  ]);
};

export const serializeSlaughterChargeOrPremiums = (serverData: ResourcesWrapper<SlaughterChargeOrPremiums>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum CfpCo2EmissionFactorOriginal {
  CfpCo2EmissionFactor = 'CFP_CO2EmissionFactors',
}

enum CfpCo2EmissionFactorSerialized {
  CfpCo2EmissionFactor = 'CFP_CO2EmissionFactors',
}

enum CfpCo2EmissionFactorUnserialized {
  CfpCo2EmissionFactor = 'CFP_CO2EmissionFactors',
}

enum CfpCo2EmissionFactorOriginalHeaders {
  'emissionSource' = 'energy or water source',
  'co2Volume' = 'kg CO2/eenheid',
  'reference' = 'Reference',
}

enum CfpCo2EmissionFactorSerializedHeaders {
  'emissionSource' = 'emissionSource',
  'co2Volume' = 'co2Volume',
  'reference' = 'reference',
}
enum CfpCo2EmissionFactorUnserializedHeaders {
  'emissionSource' = 'emission_source',
  'co2Volume' = 'co2_volume',
  'reference' = 'reference',
}

export const CFP_CO2_EMISSION_FACTOR = {
  [CfpCo2EmissionFactorUnserialized.CfpCo2EmissionFactor]: {
    original: CfpCo2EmissionFactorOriginal.CfpCo2EmissionFactor,
    serialized: CfpCo2EmissionFactorSerialized.CfpCo2EmissionFactor,
    unserialized: CfpCo2EmissionFactorUnserialized.CfpCo2EmissionFactor,
    view: IntlKeys.referenceTablesCfpCo2EmissionFactors, //
    viewName: 'CFP_CO2EmissionFactors',

    columns: {
      [CfpCo2EmissionFactorSerializedHeaders.emissionSource]: {
        original: CfpCo2EmissionFactorOriginalHeaders.emissionSource,
        serialized: CfpCo2EmissionFactorSerializedHeaders.emissionSource,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.emissionSource,
        view: IntlKeys.referenceTablesCfpCo2EmissionFactorsSource,
      },
      [CfpCo2EmissionFactorSerializedHeaders.co2Volume]: {
        original: CfpCo2EmissionFactorOriginalHeaders.co2Volume,
        serialized: CfpCo2EmissionFactorSerializedHeaders.co2Volume,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.co2Volume,
        view: IntlKeys.referenceTablesCfpCo2EmissionFactorsVolume,
      },
      [CfpCo2EmissionFactorSerializedHeaders.reference]: {
        original: CfpCo2EmissionFactorOriginalHeaders.reference,
        serialized: CfpCo2EmissionFactorSerializedHeaders.reference,
        unserialized: CfpCo2EmissionFactorUnserializedHeaders.reference,
        view: IntlKeys.referenceTablesCfpCo2EmissionFactorsReference,
      },
    },
  },
};

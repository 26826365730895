export interface CatalogueDataObject {
  id: number;
  name: string;
  description: string;
  dataPoints: DataAttribute[];

  createdAt: string;
  createdBy: {
    name: string;
    email: string;
  };
  modifiedAt: string;
  modifiedBy: {
    name: string;
    email: string;
  };
}

export interface DataAttribute {
  id: number | string;
  visible: boolean;
  name: string;
  format: string;
  description: string;
  //
  _isNew?: boolean;
  _isDeleted?: boolean;
}

export interface CatalogueDataObjectBackend {
  id: number;
  name: string;
  description: string;
  entity_descriptions: DataAttributeBackend[];
  created_at: string;
  created_by: {
    name: string;
    email: string;
  };
  modified_at: string;
  modified_by: {
    name: string;
    email: string;
  };
}

export interface CatalogueDataObjectUpdateBackend {
  id: number;
  name: string;
  description: string;
  entity_descriptions_attributes: DataAttributeBackend[];
}

export interface DataAttributeBackend {
  id: string | number;
  name: string;
  data_format: string;
  description: string;
  is_visible: boolean;
}

export const serializeDataAttributeBackToFront = (
  backDataAttribute: DataAttributeBackend,
): DataAttribute => {
  const { data_format, is_visible, ...unified } = backDataAttribute;

  return {
    ...unified,
    format: data_format,
    visible: is_visible,
  };
};

export const serializeDataObjectBackToFront = (
  backDataObject: CatalogueDataObjectBackend,
): CatalogueDataObject => {
  const {
    //
    entity_descriptions,
    created_at,
    created_by,
    modified_at,
    modified_by,
    ...unified
  } = backDataObject;

  return {
    ...unified,
    dataPoints: entity_descriptions.map(serializeDataAttributeBackToFront),
    createdAt: created_at,
    createdBy: created_by,
    modifiedAt: modified_at,
    modifiedBy: modified_by,
  };
};

export const serializeDataAttributeFrontToBack = (
  dataAttribute: DataAttribute,
): DataAttributeBackend => {
  const {
    //
    format,
    visible,
    _isDeleted,
    _isNew,
    ...unified
  } = dataAttribute;

  const additional = [
    // additional flags for backend
    _isDeleted && { _destroy: true },
    _isNew && { id: '' },
  ].reduce((acc, val) => {
    if (val) {
      Object.assign(acc, val);
    }
    return acc;
  }, {});

  return {
    ...unified,
    data_format: format,
    is_visible: visible,
    ...additional,
  };
};

export const serializeDataObjectFrontToBack = (
  dataObject: CatalogueDataObject,
): CatalogueDataObjectUpdateBackend => {
  const { dataPoints, ...unified } = dataObject;

  return {
    ...unified,
    entity_descriptions_attributes: dataPoints.map(serializeDataAttributeFrontToBack),
  };
};

import { WorkSheet } from 'xlsx';

import { Validator } from '../index';
import { SheetValidator } from './Sheet';
import { ValidationError, ValidationErrorI, ValidationWarning, ErrorsCategories } from './errors';

export interface ExcelSheets {
  [sheetName: string]: WorkSheet;
}

export class DocumentValidator implements Validator {
  private errors: Array<ValidationErrorI> = new Array<ValidationErrorI>();
  private isValidated = false;

  constructor(
    private readonly data: ExcelSheets,
    private readonly sheetValidators: Array<SheetValidator>,
  ) {}

  private verifyDocumentExists = () => {
    if (!this.data && typeof this.data !== 'object') {
      this.errors.push(
        new ValidationError(`Provided document is not object, i\`ts ${typeof this.data} instead`),
      );
    }
  };

  private verifyDocumentIsNotEmpty = () => {
    if (Object.keys(this.data).length === 0) {
      this.errors.push(new ValidationError('No tabs found in the document'));
    }
  };

  private verifySheetData = () => {
    this.sheetValidators.forEach((sheetValidator) => {
      const { errors } = sheetValidator.validate(this.data);

      this.errors = [...this.errors, ...errors!];
    });
  };

  public validate = () => {
    this.verifyDocumentExists();
    this.verifyDocumentIsNotEmpty();
    this.verifySheetData();

    this.isValidated = true;

    return this.getValidationResult();
  };

  private getValidationResult = (): ErrorsCategories => {
    const validationResult = {
      errors: new Array<ValidationError>(),
      warnings: new Array<ValidationWarning>(),
      infos: new Array<ValidationWarning>(),
    };

    this.errors.forEach((error) => {
      if (error instanceof ValidationError) {
        validationResult.errors.push(error);
      } else if (error instanceof ValidationWarning) {
        validationResult.warnings.push(error);
      } else {
        validationResult.infos.push(error);
      }
    });
    return validationResult;
  };

  public isValid = () => {
    if (!this.isValidated) {
      this.validate();
    }

    return this.errors.length === 0;
  };
}

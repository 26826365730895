import { IntlKeys } from 'lib/localization/keys';

enum PensTypeSowBarnSerialized {
  PensTypeSowBarn = 'PensTypeSowBarn',
}

enum PensTypeSowBarnUnserialized {
  PensTypeSowBarn = 'PensTypeSowBarn',
}

export enum PensTypeSowBarnSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  penTypeSowBarnCode = 'penTypeSowBarnCode',
  penTypeSowBarnName = 'penTypeSowBarnName',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const PensTypeSowBarnUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['pen_type_sow_barn_upload', 'id'],
  penTypeSowBarnCode: 'pen_type_sow_barn',
  penTypeSowBarnName: 'pen_barn_name',
  uploadType: ['pen_type_sow_barn_upload', 'upload_type'],
  filename: ['pen_type_sow_barn_upload', 'file_name'],
  status: ['pen_type_sow_barn_upload', 'status'],
  validDateFrom: ['pen_type_sow_barn_upload', 'valid_from'],
  validDateTo: ['pen_type_sow_barn_upload', 'valid_to'],
  notes: ['pen_type_sow_barn_upload', 'notes'],
  uploadedAt: ['pen_type_sow_barn_upload', 'uploaded_at'],
  uploadedBy: ['pen_type_sow_barn_upload', 'uploaded_by', 'full_name'],
} as const;

export const PENS_TYPE_SOW_BARN = {
  [PensTypeSowBarnSerialized.PensTypeSowBarn]: {
    serialized: PensTypeSowBarnSerialized.PensTypeSowBarn,
    unserialized: PensTypeSowBarnUnserialized.PensTypeSowBarn,
    view: IntlKeys.referenceTablesAsPensTypeSowBarn,

    columns: {
      [PensTypeSowBarnSerializedHeaders.id]: {
        serialized: PensTypeSowBarnSerializedHeaders.id,
        unserialized: PensTypeSowBarnUnserializedHeaders.id,
      },
      [PensTypeSowBarnSerializedHeaders.referenceUploadID]: {
        serialized: PensTypeSowBarnSerializedHeaders.referenceUploadID,
        unserialized: PensTypeSowBarnUnserializedHeaders.referenceUploadID,
      },
      [PensTypeSowBarnSerializedHeaders.penTypeSowBarnCode]: {
        serialized: PensTypeSowBarnSerializedHeaders.penTypeSowBarnCode,
        unserialized: PensTypeSowBarnUnserializedHeaders.penTypeSowBarnCode,
        view: IntlKeys.referenceTablesPensSowBarnCode,
      },
      [PensTypeSowBarnSerializedHeaders.penTypeSowBarnName]: {
        serialized: PensTypeSowBarnSerializedHeaders.penTypeSowBarnName,
        unserialized: PensTypeSowBarnUnserializedHeaders.penTypeSowBarnName,
        view: IntlKeys.referenceTablesPensSowBarnName,
      },
      [PensTypeSowBarnSerializedHeaders.status]: {
        serialized: PensTypeSowBarnSerializedHeaders.status,
        unserialized: PensTypeSowBarnUnserializedHeaders.status,
      },
      [PensTypeSowBarnSerializedHeaders.uploadType]: {
        serialized: PensTypeSowBarnSerializedHeaders.uploadType,
        unserialized: PensTypeSowBarnUnserializedHeaders.uploadType,
      },
      [PensTypeSowBarnSerializedHeaders.notes]: {
        serialized: PensTypeSowBarnSerializedHeaders.notes,
        unserialized: PensTypeSowBarnUnserializedHeaders.notes,
      },
      [PensTypeSowBarnSerializedHeaders.filename]: {
        serialized: PensTypeSowBarnSerializedHeaders.filename,
        unserialized: PensTypeSowBarnUnserializedHeaders.filename,
      },
      [PensTypeSowBarnSerializedHeaders.validDateFrom]: {
        serialized: PensTypeSowBarnSerializedHeaders.validDateFrom,
        unserialized: PensTypeSowBarnUnserializedHeaders.validDateFrom,
      },
      [PensTypeSowBarnSerializedHeaders.validDateTo]: {
        serialized: PensTypeSowBarnSerializedHeaders.validDateTo,
        unserialized: PensTypeSowBarnUnserializedHeaders.validDateTo,
      },
      [PensTypeSowBarnSerializedHeaders.uploadedAt]: {
        serialized: PensTypeSowBarnSerializedHeaders.uploadedAt,
        unserialized: PensTypeSowBarnUnserializedHeaders.uploadedAt,
      },
      [PensTypeSowBarnSerializedHeaders.uploadedBy]: {
        serialized: PensTypeSowBarnSerializedHeaders.uploadedBy,
        unserialized: PensTypeSowBarnUnserializedHeaders.uploadedBy,
      },
    },
  },
};

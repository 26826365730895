import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { GROUP_REMOVALS } from 'store/entities/farmManagementData/groupRemovals/config';

const { columns, view, serialized } = GROUP_REMOVALS.GroupRemoval;

export const groupRemovalsTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.finEventIdentifier.view,
          field: columns.finEventIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.alive.view,
          field: columns.alive.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupRemovalNumber.view,
          field: columns.groupRemovalNumber.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.weightSlaughtered.view,
          field: columns.weightSlaughtered.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.weightAlive.view,
          field: columns.weightAlive.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.firstRemreason.view,
          field: columns.firstRemreason.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.secondRemreason.view,
          field: columns.secondRemreason.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.remtypeIdentifier.view,
          field: columns.remtypeIdentifier.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.animalCategoryIdentifier.view,
          field: columns.animalCategoryIdentifier.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.groupRemovalDatetime.view,
          field: columns.groupRemovalDatetime.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.wcfIdentifier.view,
          field: columns.wcfIdentifier.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.notClassified.view,
          field: columns.notClassified.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.classType.view,
          field: columns.classType.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.nlMeatPerc.view,
          field: columns.nlMeatPerc.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.nlAa.view,
          field: columns.nlAa.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.nlA.view,
          field: columns.nlA.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.nlB.view,
          field: columns.nlB.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.nlC.view,
          field: columns.nlC.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.nlBackFat.view,
          field: columns.nlBackFat.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.nlLoInDepth.view,
          field: columns.nlLoInDepth.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.deLachs.view,
          field: columns.deLachs.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.deSchinken.view,
          field: columns.deSchinken.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.deSchaulter.view,
          field: columns.deSchaulter.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.deIndexPunkte.view,
          field: columns.deIndexPunkte.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.deIndexFactor.view,
          field: columns.deIndexFactor.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.estimatedDateIn.view,
          field: columns.estimatedDateIn.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.sexIdentifier.view,
          field: columns.sexIdentifier.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.withoutMale.view,
          field: columns.withoutMale.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.withoutFemale.view,
          field: columns.withoutFemale.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.withoutMaleCastrated.view,
          field: columns.withoutMaleCastrated.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.carPleuritis.view,
          field: columns.carPleuritis.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.carInfSkin.view,
          field: columns.carInfSkin.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.carInfFeed.view,
          field: columns.carInfFeed.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.carPleuritisInFeed.view,
          field: columns.carPleuritisInFeed.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.carInfSkinAndFeed.view,
          field: columns.carInfSkinAndFeed.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.carPleuritisInfSkin.view,
          field: columns.carPleuritisInfSkin.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.carPleuritisInfSkinAndFeed.view,
          field: columns.carPleuritisInfSkinAndFeed.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.organAffectedLiver.view,
          field: columns.organAffectedLiver.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.organRejectedLiver.view,
          field: columns.organRejectedLiver.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.organAffectedLungs.view,
          field: columns.organAffectedLungs.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.organAffectedLungsAndLiver.view,
          field: columns.organAffectedLungsAndLiver.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.organAffectedLungsRejectedLiver.view,
          field: columns.organAffectedLungsRejectedLiver.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.qualIdentifier.view,
          field: columns.qualIdentifier.serialized,
        },
      }),
    ],
  }),
]);

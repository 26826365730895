/**
 * @deprecated use single IntlKeys
 */
export enum InvoicesKeys {
  // invoices
  currencyCode = 'invoices.currencyCode',
  overviewType = 'invoices.overviewType',
  invoiceNumber = 'invoices.invoiceNumber',
  invoiceDate = 'invoices.invoiceDate',
  uniqueMessageNumber = 'invoices.uniqueMessageNumber',
  supplierName = 'invoices.supplierName',
  supplierStreet = 'invoices.supplierStreet',
  supplierHouseNumber = 'invoices.supplierHouseNumber',
  supplierPlace = 'invoices.supplierPlace',
  supplierPostCode = 'invoices.supplierPostCode',
  supplierEANAddressCode = 'invoices.supplierEANAddressCode',
  supplierBankAccount = 'invoices.supplierBankAccount',
  supplierIban = 'invoices.supplierIban',
  supplierVatNumber = 'invoices.supplierVatNumber',
  customerName = 'invoices.customerName',
  customerStreetAddress = 'invoices.customerStreetAddress',
  customerPostalAddressPlace = 'invoices.customerPostalAddressPlace',
  customerRelationNumber = 'invoices.customerRelationNumber',
  deliveries = 'invoices.deliveries',
  // invoice details
  invoice = 'invoices.invoice',
  unit = 'invoices.unit',
  invoiceMessageId = 'invoices.invoiceMessageId',
  lineNumber = 'invoices.lineNumber',
  deliveryDate = 'invoices.deliveryDate',
  deliveryNoteNumber = 'invoices.deliveryNoteNumber',
  deliveryNoteLineNumber = 'invoices.deliveryNoteLineNumber',
  productQuantity = 'invoices.productQuantity',
  productArticleCode = 'invoices.productArticleCode',
  productDescription = 'invoices.productDescription',
  productArticleGroupCode = 'invoices.productArticleGroupCode',
  productArticleGroupCodeDescription = 'invoices.productArticleGroupCodeDescription',
  productEnergeticValue = 'invoices.productEnergeticValue',
}

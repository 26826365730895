import { COUNTRY_CODES_FIELDS } from 'lib/excel/config/referenceTables/countryCodes/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';

const { columns, serialized, view } = COUNTRY_CODES_FIELDS.CountryCodes;

export const getCountryCodesView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.iso31661Code.serialized, viewPropName: columns.iso31661Code.view }),
      new CellViewer({
        serializedPropName: columns.subdivisionName.serialized,
        viewPropName: columns.subdivisionName.view,
      }),
      new CellViewer({ serializedPropName: columns.iso31662Code.serialized, viewPropName: columns.iso31662Code.view }),
      new CellViewer({ serializedPropName: columns.countryName.serialized, viewPropName: columns.countryName.view }),
    ]),
  ]);
};

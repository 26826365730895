import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { WATER_CONSUMPTION_RATES } from 'store/entities/referenceTables/waterConsumptionRates/config';

const { columns, view, serialized } = WATER_CONSUMPTION_RATES.WaterConsumptionRates;

export const waterConsumptionRatesTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.householdPeopleQuantity.view,
          field: columns.householdPeopleQuantity.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.waterConsumption.view,
          field: columns.waterConsumption.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.waterConsumption.serialized] && roundToInteger(data[columns.waterConsumption.serialized]),
        },
      }),
    ],
  }),
]);

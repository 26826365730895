/* eslint-disable @typescript-eslint/no-unused-vars */
import RouteParser from 'route-parser';
import { Route } from '../../Route';

export const vriData = new Route('get', new RouteParser('/mock/report_uploads'), (parsedParams, requestData) => {
  return [
    200,
    {
      resources: [
        {
          id: 1,
          report_date_from: '2017-12-01T00:00:00.000Z',
          report_date_to: '2018-01-01T00:00:00.000Z',
          total_min: 8.0,
          total_max: 10.0,
          constants: {
            epg: {
              total_average: 4.05,
              total_top_20: 9.55,
            },
            health_balance: {
              total_average: 0.04,
              total_top_20: 0.29,
            },
            balance_base: {
              total_average: -1.16,
              total_top_20: -1.44,
            },
          },
          reports: [
            {
              id: 1,
              vri_serial_number: '1',
              ubn: 'o8hpx',
              name: 'Velit ullam perspiciatis.',
              total: 10.0,
              vri_epg: 1.0,
              epg_serial_number: '51',
              growth: 8274.0,
              feed_intake_per_day: 0.7700363807810159,
              ew_conversion: 0.7469168239552024,
              laying_big_weight: 14.395315941946818,
              loss: 3.708434592837852,
              epg: 10.205882865208064,
              vri_classification: 4.0,
              epg_order_number: 53.0,
              balance_with_respect_to_base: -2.5473985281986122,
              average_weight: 102.30568086721915,
              average_weight_correction: -6.764332271163514,
              average_fat_thickness: 12.949749533591305,
              average_beacon_correction: -0.6497490723798067,
              average_muscle_thickness: 69.2504247282337,
              average_muscle_correction: -0.8859345739475342,
              average_meat: 61.35386935900003,
              average_meat_correction: -0.2151643569626911,
              average_classification_correction: -7.83876818123579,
              average_sex_allowance: -2.75071463103082,
              average_concept_surcharge: 1.1942630773480944,
              pigs_in_draft: 65.0,
              vri_health: 5.0,
              epg_number: 53.0,
              euro_per_pig: 0.7206989994496356,
              approved_carcass: 94.3987510695168,
              pleurisy: 10.008778659773654,
              inflamed_skin: 0.02040386906622882,
              inflamed_leg: 1.0006942476690739,
              approved_organ: 96.34173643364312,
              lever_afg: 9.230380463486528,
              long_afg: 6.171340336636881,
              healthy: 99.34258623369197,
              created_at: '2020-08-11T06:50:07.935Z',
              updated_at: '2020-08-11T06:50:07.935Z',
              file_upload: {
                id: 1,
                file: {
                  url: '/uploads/test/companies/2/user_channels/1/file_upload/1/file/reports.xlsx',
                },
                checksum: 'c5f3a77ce85b8b81ba8cd2ce6a6c3e93',
                is_edited: false,
                upload_token: 'gk4LEz-pJzfYBnQSnI5v9nzYs5BCgUew-Q',
                upload_type: 'reports',
                uploaded_at: '2020-08-10T06:50:07.750Z',
                source: 'manual',
                status: 'pending',
                status_message: 'Pending loading.',
                created_at: '2020-08-11T06:50:07.757Z',
                user: {
                  email: '1kelvin_adams@hotmail.com',
                  name: 'Mindy Gerlach DVM',
                },
              },
              report_upload: {
                report_date_from: '2017-12-01T00:00:00.000Z',
                report_date_to: '2018-01-01T00:00:00.000Z',
              },
            },
            {
              id: 228,
              vri_serial_number: '1',
              ubn: 'o8hpx',
              name: 'Velit ullam perspiciatis.',
              total: 10.0,
              vri_epg: 1.0,
              epg_serial_number: '51',
              growth: 8274.0,
              feed_intake_per_day: 0.7700363807810159,
              ew_conversion: 0.7469168239552024,
              laying_big_weight: 14.395315941946818,
              loss: 3.708434592837852,
              epg: 10.205882865208064,
              vri_classification: 4.0,
              epg_order_number: 53.0,
              balance_with_respect_to_base: -2.5473985281986122,
              average_weight: 102.30568086721915,
              average_weight_correction: -6.764332271163514,
              average_fat_thickness: 12.949749533591305,
              average_beacon_correction: -0.6497490723798067,
              average_muscle_thickness: 69.2504247282337,
              average_muscle_correction: -0.8859345739475342,
              average_meat: 61.35386935900003,
              average_meat_correction: -0.2151643569626911,
              average_classification_correction: -7.83876818123579,
              average_sex_allowance: -2.75071463103082,
              average_concept_surcharge: 1.1942630773480944,
              pigs_in_draft: 65.0,
              vri_health: 5.0,
              epg_number: 53.0,
              euro_per_pig: 0.7206989994496356,
              approved_carcass: 94.3987510695168,
              pleurisy: 10.008778659773654,
              inflamed_skin: 0.02040386906622882,
              inflamed_leg: 1.0006942476690739,
              approved_organ: 96.34173643364312,
              lever_afg: 9.230380463486528,
              long_afg: 6.171340336636881,
              healthy: 99.34258623369197,
              created_at: '2020-08-11T06:50:07.935Z',
              updated_at: '2020-08-11T06:50:07.935Z',
              file_upload: {
                id: 228,
                file: {
                  url: '/uploads/test/companies/2/user_channels/1/file_upload/1/file/reports.xlsx',
                },
                checksum: 'c5f3a77ce85b8b81ba8cd2ce6a6c3e93',
                is_edited: false,
                upload_token: 'gk4LEz-pJzfYBnQSnI5v9nzYs5BCgUew-Q',
                upload_type: 'reports',
                uploaded_at: '2020-08-10T06:50:07.750Z',
                source: 'manual',
                status: 'pending',
                status_message: 'Pending loading.',
                created_at: '2020-08-11T06:50:07.757Z',
                user: {
                  email: '1kelvin_adams@hotmail.com',
                  name: 'Mindy Gerlach DVM',
                },
              },
              report_upload: {
                report_date_from: '2019-02-14T00:00:00.000Z',
                report_date_to: '2020-01-01T00:00:00.000Z',
              },
            },
          ],
        },
        {
          id: 228,
          report_date_from: '2019-02-14T00:00:00.000Z',
          report_date_to: '2020-01-01T00:00:00.000Z',
          total_min: 8.0,
          total_max: 10.0,
          constants: {
            epg: {
              total_average: 4.05,
              total_top_20: 9.55,
            },
            health_balance: {
              total_average: 0.04,
              total_top_20: 0.29,
            },
            balance_base: {
              total_average: -1.16,
              total_top_20: -1.44,
            },
          },
          reports: [
            {
              id: 1,
              vri_serial_number: '1',
              ubn: 'o8hpx',
              name: 'Velit ullam perspiciatis.',
              total: 10.0,
              vri_epg: 1.0,
              epg_serial_number: '51',
              growth: 8274.0,
              feed_intake_per_day: 0.7700363807810159,
              ew_conversion: 0.7469168239552024,
              laying_big_weight: 14.395315941946818,
              loss: 3.708434592837852,
              epg: 10.205882865208064,
              vri_classification: 4.0,
              epg_order_number: 53.0,
              balance_with_respect_to_base: -2.5473985281986122,
              average_weight: 102.30568086721915,
              average_weight_correction: -6.764332271163514,
              average_fat_thickness: 12.949749533591305,
              average_beacon_correction: -0.6497490723798067,
              average_muscle_thickness: 69.2504247282337,
              average_muscle_correction: -0.8859345739475342,
              average_meat: 61.35386935900003,
              average_meat_correction: -0.2151643569626911,
              average_classification_correction: -7.83876818123579,
              average_sex_allowance: -2.75071463103082,
              average_concept_surcharge: 1.1942630773480944,
              pigs_in_draft: 65.0,
              vri_health: 5.0,
              epg_number: 53.0,
              euro_per_pig: 0.7206989994496356,
              approved_carcass: 94.3987510695168,
              pleurisy: 10.008778659773654,
              inflamed_skin: 0.02040386906622882,
              inflamed_leg: 1.0006942476690739,
              approved_organ: 96.34173643364312,
              lever_afg: 9.230380463486528,
              long_afg: 6.171340336636881,
              healthy: 99.34258623369197,
              created_at: '2020-08-11T06:50:07.935Z',
              updated_at: '2020-08-11T06:50:07.935Z',
              file_upload: {
                id: 1,
                file: {
                  url: '/uploads/test/companies/2/user_channels/1/file_upload/1/file/reports.xlsx',
                },
                checksum: 'c5f3a77ce85b8b81ba8cd2ce6a6c3e93',
                is_edited: false,
                upload_token: 'gk4LEz-pJzfYBnQSnI5v9nzYs5BCgUew-Q',
                upload_type: 'reports',
                uploaded_at: '2020-08-10T06:50:07.750Z',
                source: 'manual',
                status: 'pending',
                status_message: 'Pending loading.',
                created_at: '2020-08-11T06:50:07.757Z',
                user: {
                  email: '1kelvin_adams@hotmail.com',
                  name: 'Mindy Gerlach DVM',
                },
              },
              report_upload: {
                report_date_from: '2017-12-01T00:00:00.000Z',
                report_date_to: '2018-01-01T00:00:00.000Z',
              },
            },
            {
              id: 228,
              vri_serial_number: '1',
              ubn: 'o8hpx',
              name: 'Velit ullam perspiciatis.',
              total: 10.0,
              vri_epg: 1.0,
              epg_serial_number: '51',
              growth: 8274.0,
              feed_intake_per_day: 0.7700363807810159,
              ew_conversion: 0.7469168239552024,
              laying_big_weight: 14.395315941946818,
              loss: 3.708434592837852,
              epg: 10.205882865208064,
              vri_classification: 4.0,
              epg_order_number: 53.0,
              balance_with_respect_to_base: -2.5473985281986122,
              average_weight: 102.30568086721915,
              average_weight_correction: -6.764332271163514,
              average_fat_thickness: 12.949749533591305,
              average_beacon_correction: -0.6497490723798067,
              average_muscle_thickness: 69.2504247282337,
              average_muscle_correction: -0.8859345739475342,
              average_meat: 61.35386935900003,
              average_meat_correction: -0.2151643569626911,
              average_classification_correction: -7.83876818123579,
              average_sex_allowance: -2.75071463103082,
              average_concept_surcharge: 1.1942630773480944,
              pigs_in_draft: 65.0,
              vri_health: 5.0,
              epg_number: 53.0,
              euro_per_pig: 0.7206989994496356,
              approved_carcass: 94.3987510695168,
              pleurisy: 10.008778659773654,
              inflamed_skin: 0.02040386906622882,
              inflamed_leg: 1.0006942476690739,
              approved_organ: 96.34173643364312,
              lever_afg: 9.230380463486528,
              long_afg: 6.171340336636881,
              healthy: 99.34258623369197,
              created_at: '2020-08-11T06:50:07.935Z',
              updated_at: '2020-08-11T06:50:07.935Z',
              file_upload: {
                id: 228,
                file: {
                  url: '/uploads/test/companies/2/user_channels/1/file_upload/1/file/reports.xlsx',
                },
                checksum: 'c5f3a77ce85b8b81ba8cd2ce6a6c3e93',
                is_edited: false,
                upload_token: 'gk4LEz-pJzfYBnQSnI5v9nzYs5BCgUew-Q',
                upload_type: 'reports',
                uploaded_at: '2020-08-10T06:50:07.750Z',
                source: 'manual',
                status: 'pending',
                status_message: 'Pending loading.',
                created_at: '2020-08-11T06:50:07.757Z',
                user: {
                  email: '1kelvin_adams@hotmail.com',
                  name: 'Mindy Gerlach DVM',
                },
              },
              report_upload: {
                report_date_from: '2019-02-14T00:00:00.000Z',
                report_date_to: '2020-01-01T00:00:00.000Z',
              },
            },
          ],
        },
      ],
    },
  ];
});

export const vriDataCalculated = new Route(
  'get',
  new RouteParser('/mock/reports/calculated'),
  (parsedParams, requestData, query) => {
    const testUbn = (query?.filter as Record<string, unknown>).ubn;

    return [
      200,
      {
        resource: {
          // rating: 'Test',
          total_min: 5,
          total_max: 15,
          euro_per_pig_min: 0.3,
          euro_per_pig_max: 1.5,
          balance_with_respect_to_base_min: 0.5,
          balance_with_respect_to_base_max: 2,

          totals: {
            health_balance: {
              total_average: 8.916,
              total_top_20: 8.916,
            },
            balance_base: {
              total_average: 17.86111111111111,
              total_top_20: 17.86111111111111,
            },
          },
          reports: [
            {
              // ID FOR REPORTING TOOLS
              user_passport_id: 10,
              // ubn: 'pzviqppqvs',
              ubn: testUbn,
              vion_relation_number: 'mivoykxlkd',
              name: 'Sawayn-Schamberger',
              average_meat_correction: 3.2222222222222223,
              average_muscle_correction: 3.0,
              average_beacon_correction: 2.7142857142857144,
              average_weight_correction: 2.0,
              average_sex_allowance: 2.857142857142857,
              average_concept_surcharge: 2.4,
              average_meat: 52.8,
              average_weight: 40.1,
              average_fat_thickness: 419.7,
              average_muscle_thickness: 648.7,
              total_pigs_count: 10,
              approved_carcass_count: 2,
              carcass_code_1: 0,
              carcass_code_2: 3,
              carcass_code_3: 1,
              carcass_code_4: 0,
              carcass_code_5: 0,
              carcass_code_6: 2,
              carcass_code_7: 2,
              approved_organ_count: 3,
              organ_code_1: 2,
              organ_code_2: 1,
              organ_code_3: 2,
              organ_code_4: 0,
              organ_code_5: 1,
              organ_code_6: 1,
              healthy_count: 0,
              average_classification_correction: 10.936507936507937,
              balance_with_respect_to_base: 16.193650793650793,
              pigs_in_draft: 3.857142857142857,
              healthy: 0.3,
              long_afg_count: 3,
              long_afg: 30.0,
              lever_afg_count: 2,
              lever_afg: 20.0,
              approved_organ: 30.0,
              liver_impaired_count: 2,
              liver_impaired: 20.0,
              inflamed_leg_count: 3,
              inflamed_leg: 30.0,
              inflamed_skin_count: 7,
              inflamed_skin: 70.0,
              pleurisy_count: 4,
              pleurisy: 40.0,
              approved_carcass: 20.0,
              not_judjeable_count: 1,
              not_judjeable: 10.0,
              euro_per_pig: 7.923000000000001,
              vri_health: 5,
              vri_classification: 5,
              total: 10,
              vri_serial_number: 44,
              epg_serial_number: 44,
            },
          ],
        },
      },
    ];
  },
);

export const vriUbnsAutocomplete = new Route(
  'get',
  new RouteParser('/mock/reports/ubn_list'),
  (parsedParams, requestData) => {
    return [
      200,
      {
        resources: [
          {
            name: 'Beatae accusantium earum.',
            ubn: 'qmmem',
          },
          {
            name: 'Dolor eos accusantium.',
            ubn: 'cey71',
          },
        ],
      },
    ];
  },
);

export const vriStatistics = new Route('get', new RouteParser('/mock/reports/statistics'), () => [
  200,
  {
    resource: {
      vri_total: {
        your_score: 11.0,
        average_top_20_score: 13.0,
        difference: -2.0,
      },
      lap_speed: {
        your_score: 21.35412121150031,
        average_top_20_score: 3.297129425272656,
        difference: 18.056991786227655,
      },
      delivered_animals: {
        your_score: 9609.35454517514,
        average_top_20_score: 1483.7082413726953,
        difference: 8125.646303802445,
      },
      delivered: {
        your_score: 1049317.0064454856,
        average_top_20_score: 143803.8383821357,
        difference: 905513.1680633499,
      },
      vri_epg: {
        your_score: 2.0,
        average_top_20_score: 5.0,
        difference: -3.0,
      },
      epg_euro_per_pig: {
        your_score: 4.833076144286082,
        average_top_20_score: 9.552530575847,
        difference: -4.7194544315609175,
      },
      growth: {
        your_score: 8143.0,
        average_top_20_score: 950.181891475899,
        difference: 7192.818108524101,
      },
      feed_intake_per_day: {
        your_score: 1.9736865012722529,
        average_top_20_score: 2.285965405194,
        difference: -0.3122789039217473,
      },
      ew_conversion: {
        your_score: 2.7873179410543236,
        average_top_20_score: 2.577121008527,
        difference: 0.21019693252732363,
      },
      laying_big_weight: {
        your_score: 9.07778145918365,
        average_top_20_score: 25.503127214092,
        difference: -16.42534575490835,
      },
      loss: {
        your_score: 1.8901179218695008,
        average_top_20_score: 1.742762209572,
        difference: 0.14735571229750088,
      },
      vri_balance_with_respect_to_base: {
        your_score: 4.0,
        average_top_20_score: 4.0,
        difference: 0.0,
      },
      balance_with_respect_to_base: {
        your_score: -2.399040161327873,
        average_top_20_score: -1.15986460274,
        difference: -1.2391755585878732,
      },
      average_weight: {
        your_score: 109.19744937211709,
        average_top_20_score: 96.92191117648,
        difference: 12.275538195637097,
      },
      average_weight_correction: {
        your_score: -1.152205715347165,
        average_top_20_score: -1.51315054234,
        difference: 0.36094482699283503,
      },
      average_fat_thickness: {
        your_score: 14.85993823709526,
        average_top_20_score: 12.699750472473,
        difference: 2.160187764622261,
      },
      average_beacon_correction: {
        your_score: -0.09526471129774627,
        average_top_20_score: -0.561882283162,
        difference: 0.4666175718642537,
      },
      average_muscle_thickness: {
        your_score: 71.65704243162548,
        average_top_20_score: 67.747379141345,
        difference: 3.9096632902804913,
      },
      average_muscle_correction: {
        your_score: -0.7781899545206896,
        average_top_20_score: -0.136693088884,
        difference: -0.6414968656366896,
      },
      average_meat: {
        your_score: 62.07485427364209,
        average_top_20_score: 59.946625416292,
        difference: 2.128228857350088,
      },
      average_meat_correction: {
        your_score: -0.027091676164676004,
        average_top_20_score: -0.114889133303,
        difference: 0.087797457138324,
      },
      average_classification_correction: {
        your_score: -3.1801270652725675,
        average_top_20_score: -2.391452986301,
        difference: -0.7886740789715674,
      },
      average_sex_allowance: {
        your_score: -2.865705469201217,
        average_top_20_score: -0.066426520548,
        difference: -2.799278948653217,
      },
      average_concept_surcharge: {
        your_score: 1.4757195848599443,
        average_top_20_score: 1.231588383562,
        difference: 0.2441312012979442,
      },
      pigs_in_draft: {
        your_score: 88.0,
        average_top_20_score: 0.775437550685,
        difference: 87.224562449315,
      },
      vri_health: {
        your_score: 5.0,
        average_top_20_score: 5.0,
        difference: 0.0,
      },
      euro_per_pig: {
        your_score: 1.6351292501386965,
        average_top_20_score: 0.287037070219,
        difference: 1.3480921799196965,
      },
      approved_carcass: {
        your_score: 78.58217033480399,
        average_top_20_score: 0.957093946677,
        difference: 77.62507638812698,
      },
      pleurisy: {
        your_score: 16.836906299434176,
        average_top_20_score: 0.036798263542,
        difference: 16.800108035892176,
      },
      inflamed_skin: {
        your_score: 0.6029241937692705,
        average_top_20_score: 0.001592219249,
        difference: 0.6013319745202705,
      },
      inflamed_leg: {
        your_score: 0.6032753503110082,
        average_top_20_score: 0.010788408334,
        difference: 0.5924869419770082,
      },
      approved_organ: {
        your_score: 99.11429988949847,
        average_top_20_score: 0.972419574612,
        difference: 98.14188031488646,
      },
      liver_afg: {
        your_score: 8.228920011006357,
        average_top_20_score: 0.00627478604,
        difference: 8.222645224966357,
      },
      lung_afg: {
        your_score: 5.945345135950635,
        average_top_20_score: 0.022417923292,
        difference: 5.922927212658635,
      },
      healthy: {
        your_score: 75.5078142881592,
        average_top_20_score: 0.934455468545,
        difference: 74.5733588196142,
      },
      business_total: {
        vri_total: -6522.6901376763035,
        epg_euro_per_pig: 32269.57387292415,
        balance_with_respect_to_base: -23505.606585494756,
        euro_per_pig: -15286.657425105697,
      },
    },
  },
]);

import { get } from 'lodash';

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer, PropertyPath } from 'lib/excel/serilizers/Cell';

import { USER_PASSPORTS } from 'lib/tables/config/userPassports/config';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

export const getUserPassportsCellSerializer = (unserialized: string, serialized: string) =>
  new CellSerializer(
    unserialized,
    serialized,
    (
      data: DocumentData,
      sheetData: Array<object>,
      excelRow: { [index: string]: any },
      propertyOriginalPath: PropertyPath,
    ) => {
      const { unserialized, serialized, columns } = USER_PASSPORTS.UserPassports;
      const userPassportRaw = get(excelRow, propertyOriginalPath, []);

      const serializer = new DocumentSerializer(
        {
          [unserialized]: userPassportRaw,
        },
        [
          new SheetSerializer(unserialized, serialized, [
            new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
            new CellSerializer(columns.ubn.unserialized, columns.ubn.serialized),
            new CellSerializer(columns.kvk.unserialized, columns.kvk.serialized),
            new CellSerializer(columns.registrationName.unserialized, columns.registrationName.serialized),
          ]),
        ],
      );

      return serializer.serialize()[serialized];
    },
  );

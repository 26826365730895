enum CertificatesGroupedSerialized {
  CertificatesGrouped = 'CertificatesGrouped',
}

enum CertificatesGroupedUnserialized {
  CertificatesGrouped = 'CertificatesGrouped',
}

export enum CertificatesGroupedSerializedHeaders {
  id = 'id',
  ubn = 'ubn',
  validFrom = 'validFrom',
  validTo = 'validTo',
  pigsTomorrow = 'pigsTomorrow',
  blk = 'blk',
  certificateStatus = 'certificateStatus',
  userPassports = 'userPassports',
}

export enum CertificatesGroupedUnserializedHeaders {
  id = 'id',
  ubn = 'ubn',
  validFrom = 'valid_from',
  validTo = 'valid_to',
  pigsTomorrow = 'pigs_tomorrow',
  blk = 'blk',
  certificateStatus = 'certificate_status',
  userPassports = 'user_passports',
}

export const CERTIFICATES_GROUPED = {
  [CertificatesGroupedSerialized.CertificatesGrouped]: {
    serialized: CertificatesGroupedSerialized.CertificatesGrouped,
    unserialized: CertificatesGroupedUnserialized.CertificatesGrouped,
    columns: {
      [CertificatesGroupedSerializedHeaders.id]: {
        serialized: CertificatesGroupedSerializedHeaders.id,
        unserialized: CertificatesGroupedUnserializedHeaders.id,
      },
      [CertificatesGroupedSerializedHeaders.ubn]: {
        serialized: CertificatesGroupedSerializedHeaders.ubn,
        unserialized: CertificatesGroupedUnserializedHeaders.ubn,
      },
      [CertificatesGroupedSerializedHeaders.validFrom]: {
        serialized: CertificatesGroupedSerializedHeaders.validFrom,
        unserialized: CertificatesGroupedUnserializedHeaders.validFrom,
      },
      [CertificatesGroupedSerializedHeaders.validTo]: {
        serialized: CertificatesGroupedSerializedHeaders.validTo,
        unserialized: CertificatesGroupedUnserializedHeaders.validTo,
      },
      [CertificatesGroupedSerializedHeaders.pigsTomorrow]: {
        serialized: CertificatesGroupedSerializedHeaders.pigsTomorrow,
        unserialized: CertificatesGroupedUnserializedHeaders.pigsTomorrow,
      },
      [CertificatesGroupedSerializedHeaders.blk]: {
        serialized: CertificatesGroupedSerializedHeaders.blk,
        unserialized: CertificatesGroupedUnserializedHeaders.blk,
      },
      [CertificatesGroupedSerializedHeaders.certificateStatus]: {
        serialized: CertificatesGroupedSerializedHeaders.certificateStatus,
        unserialized: CertificatesGroupedUnserializedHeaders.certificateStatus,
      },
      [CertificatesGroupedSerializedHeaders.userPassports]: {
        serialized: CertificatesGroupedSerializedHeaders.userPassports,
        unserialized: CertificatesGroupedUnserializedHeaders.userPassports,
      },
    },
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo4DigitsFloating } from 'lib/helpers/renderHelpers';

import { IPCC_EMISSION_FACTORS } from 'store/entities/referenceTables/ipccEmissionFactors/config';

const { columns, view, serialized } = IPCC_EMISSION_FACTORS.IpccEmissionFactors;

export const ipccEmissionFactorsTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.factor.view,
          field: columns.factor.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.description.view,
          field: columns.description.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.defaultValue.view,
          field: columns.defaultValue.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.defaultValue.serialized] && roundTo4DigitsFloating(data[columns.defaultValue.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.uncertaintyRange.view,
          field: columns.uncertaintyRange.serialized,
        },
      }),
    ],
  }),
]);

import { RAV_CODES_FIELDS } from 'lib/excel/config/referenceTables/ravCodes/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = RAV_CODES_FIELDS.RavCodes;

export const getRavCodesDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.animalCategory.serialized,
        extractPropName: columns.animalCategory.view,
      }),
      new DisplayHeader({
        headerName: columns.ravCode.serialized,
        extractPropName: columns.ravCode.view,
      }),
      new DisplayHeader({
        headerName: columns.animalDescriptionNl.serialized,
        extractPropName: columns.animalDescriptionNl.view,
      }),
      new DisplayHeader({
        headerName: columns.animalDescriptionEn.serialized,
        extractPropName: columns.animalDescriptionEn.view,
      }),
      new DisplayHeader({
        headerName: columns.animalEnclosureCategory.serialized,
        extractPropName: columns.animalEnclosureCategory.view,
      }),
      new DisplayHeader({
        headerName: columns.ammonia.serialized,
        extractPropName: columns.ammonia.view,
      }),
      new DisplayHeader({
        headerName: columns.ammoniaUnit.serialized,
        extractPropName: columns.ammoniaUnit.view,
      }),
    ]),
  ]);
};

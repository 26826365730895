import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { Column, Components, MaterialTableProps, Options } from 'material-table';

import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { SerializedServiceFields, SERVICE_FIELDS } from 'lib/excel/config/service';
import { ServiceKeys } from 'lib/localization/keys/service';

import { OwnProps as CardProps } from 'components/DataTable/views/card/DataCard';

import { HybridColumnSettings, HybridView } from './HybridView';
import { TableColumnDescriptor } from './TableColumnsGenerator';
import { RowMenu, RowMenuI } from './utils';

export type rowContextMenuCurryingFunction = (settings: TableTabSettings) => Column<object>;
export type FormatMessage = (descriptor: MessageDescriptor, values?: ObjectWithProps) => string;

interface SettingsBase {
  readonly dataKey: string;
  readonly rowMenu: React.FC<RowMenuI>;
  readonly rowContextMenu?: rowContextMenuCurryingFunction;
  readonly rowContextMenuProps?: Partial<HybridColumnSettings>;
  readonly tableOptions: Options<ObjectWithProps>;
  readonly useLocalization?: boolean;
  readonly detailPanel?: MaterialTableProps<object>['detailPanel'];
  readonly tableComponents?: Components;
  readonly CardComponent?: React.FC<CardProps>;

  headers: Array<HybridView>;
}

export interface TableTabSettings extends SettingsBase {
  readonly defaultColumnConfig?: TableColumnDescriptor[];
  readonly labelFunction: TableLabelFunction;
  readonly label: string;
  readonly id: string;
}

interface TableTabParameters extends SettingsBase {
  readonly defaultColumnConfig?: string[];
  readonly labelFunction?: TableLabelFunction;
  readonly label?: string;
  readonly id?: string;
}

type TableLabelFunction = (label: string, params: ObjectWithProps, formatMessage: FormatMessage) => string;
export const tableLabelStubFunction: TableLabelFunction = (label) => label;
export const tableFormattedLabelStubFunction: TableLabelFunction = (label, params, formatMessage) =>
  formatMessage({ id: label });

export class TableTab implements TableTabSettings {
  readonly labelFunction: TableTabSettings['labelFunction']; // = tableLabelStubFunction; TODO: should be = tableFormattedLabelStubFunction
  readonly label: TableTabSettings['label'] = '';
  readonly dataKey: TableTabSettings['dataKey'] = '';
  readonly rowMenu: TableTabSettings['rowMenu'] = RowMenu;
  readonly defaultColumnConfig: TableTabSettings['defaultColumnConfig'] = undefined;
  readonly rowContextMenu: TableTabSettings['rowContextMenu'];
  readonly tableOptions: TableTabSettings['tableOptions'];
  readonly id: TableTabSettings['id'] = '';
  readonly useLocalization: TableTabSettings['useLocalization'] = true;
  readonly detailPanel: TableTabSettings['detailPanel'];
  readonly tableComponents: TableTabSettings['tableComponents'];
  readonly CardComponent: TableTabSettings['CardComponent'];

  headers: TableTabSettings['headers'] = new Array<HybridView>();

  constructor({
    dataKey,
    headers,
    defaultColumnConfig,
    labelFunction,
    label = '',
    rowMenu,
    rowContextMenu,
    tableOptions,
    useLocalization = true,
    detailPanel,
    tableComponents,
    CardComponent,
  }: TableTabParameters) {
    this.label = label;
    this.id = dataKey;

    if (labelFunction) {
      this.labelFunction = labelFunction;
    } else {
      this.labelFunction = useLocalization ? tableFormattedLabelStubFunction : tableLabelStubFunction;
    }

    this.headers = headers;
    this.defaultColumnConfig = defaultColumnConfig?.map((id) => ({ id }));
    this.dataKey = dataKey;
    this.rowMenu = rowMenu;
    this.rowContextMenu = rowContextMenu;
    this.tableOptions = tableOptions;
    this.useLocalization = Boolean(useLocalization);
    this.detailPanel = detailPanel;
    this.tableComponents = tableComponents;
    this.CardComponent = CardComponent;

    this.fallbackCardsConfig();
    this.fallbackDetailsConfig();

    this.validateCardView();
    this.validateDetailsConfig();
    this.validateTableConfig();

    this.injectContextMenu();
  }

  public getRowMenu = () => this.rowMenu;

  private fallbackCardsConfig = () => {
    const cardsConfig = this.getCardsConfig();

    // HEADER
    const primaryHeader = cardsConfig.find((header) => header.isCardHeader);

    if (!primaryHeader) {
      const fallbackHeaderIx = this.headers.findIndex((header) => Boolean(header.settings.title));

      this.headers = this.headers.map((hw, ix) =>
        ix === fallbackHeaderIx
          ? new HybridView({
              ...this.headers[fallbackHeaderIx],
              isCardHeader: true,
              hasCardView: true,
            })
          : hw,
      );
    }

    // ROWS
    const definedCardHeaders = this.headers.filter((header) => header.hasCardView && !header.isCardHeader);

    if (definedCardHeaders.length === 0) {
      const ROW_COUNT = 2;

      for (let i = 0; i < ROW_COUNT; i++) {
        const fallbackRowIx = this.headers.findIndex(
          ({ isCardHeader, hasCardView, settings }) => !isCardHeader && !hasCardView && Boolean(settings.title),
        );

        this.headers = this.headers.map((hw, ix) =>
          ix === fallbackRowIx
            ? new HybridView({
                ...this.headers[fallbackRowIx],
                hasCardView: true,
              })
            : hw,
        );
      }
    }
  };

  public getCardsConfig = () => {
    return this.headers.filter(({ hasCardView }) => hasCardView);
  };

  public getCardHeaderSettings = () => {
    const cardHeaders = this.getCardsConfig();
    const cardTitle = cardHeaders.find(({ isCardHeader }) => isCardHeader) as HybridView;

    return cardTitle.settings;
  };

  public getCardsSettings = () => {
    const cardHeaders = this.getCardsConfig();
    const cardRows = cardHeaders.filter(({ isCardHeader, hasCardView }) => !isCardHeader && hasCardView);

    return cardRows.map((header) => header.settings);
  };

  public getDetailsConfig = () => {
    return this.headers
      .filter((header) => header.hasDetailsView)
      .sort((header1) => {
        return header1.isDetailsHeader ? 1 : -1;
      });
  };

  public getTableViewHeaders = () => this.headers.filter((header) => header.hasTableView);

  private injectContextMenu = () => {
    if (this.rowContextMenu) {
      this.headers = [
        new HybridView({
          serviceLabel: ServiceKeys.tableMenu,
          isService: true,
          id: SERVICE_FIELDS[SerializedServiceFields.TABLE_MENU].serialized,
          isRemovable: false,
          isDetailsHeader: false,
          isCardHeader: false,
          hasTableView: true,
          hasCardView: false,
          hasDetailsView: false,
          settings: this.rowContextMenu(this),
        }),
        ...this.headers,
      ];
    }
  };

  private validateTableConfig = () => {
    const tableConfig = this.headers.filter((header) => header.hasTableView);

    if (tableConfig.length === 0) {
      throw new Error(`Failed to configurate table view for ${this.label} tab. There are no table headers found`);
    }
  };

  private fallbackDetailsConfig = () => {
    const detailsConfig = this.getDetailsConfig();
    const detailsHeader = detailsConfig.find((header) => header.isDetailsHeader);

    if (!detailsHeader) {
      const fallbackHeaderIx = this.headers.findIndex((header) => Boolean(header.settings.title));

      this.headers = this.headers.map((hw, ix) =>
        ix === fallbackHeaderIx
          ? new HybridView({
              ...this.headers[fallbackHeaderIx],
              isDetailsHeader: true,
            })
          : hw,
      );
    }
  };

  private validateDetailsConfig = () => {
    const detailsConfig = this.getDetailsConfig();
    const detailsHeader = detailsConfig.filter((header) => header.isDetailsHeader);

    if (detailsConfig.length === 0) {
      throw new Error(`Failed to configurate details view for ${this.label} tab. There are no details headers found`);
    }

    if (detailsHeader.length !== 1) {
      throw new Error(
        `Failed to configurate details view for ${this.label} tab. There are should be 1 details header, ${detailsHeader.length} found instead`,
      );
    }
  };

  private validateCardView = () => {
    const cardsConfig = this.getCardsConfig();
    const primaryHeader = cardsConfig.filter((header) => header.isCardHeader);

    if (cardsConfig.length === 0) {
      throw new Error(`Failed to configurate card view for ${this.label} tab. There are no card view headers found`);
    }

    if (primaryHeader.length !== 1) {
      throw new Error(
        `Failed to configurate card view for ${this.label} tab. There should be 1 primary header (card header), ${primaryHeader.length} found instead `,
      );
    }
  };
}

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { CompanyReportPayload } from '../companyReports';
import { FERMENTATION_REPORTS, FERMENTATION_REPORTS_SUBMIT } from './config';

export type FermentationReportPayload = {
  id: number;
  status: 'draft' | 'submitted';
  company_report: CompanyReportPayload;
  reporting_period_date_start?: string;
  reporting_period_date_end?: string;
  purchase_date: string;
  product_code?: string;
  product_name?: string;
  supplier_name?: string;
  delivered_quantity?: number;
  unit?: string | null;
  updated_at: string;
  updated_by: {
    email: string;
    full_name: string;
  };
};

export type FermentationReportSerialized = {
  id: number;
  status: 'draft' | 'submitted';
  ubn: string;
  companyReport: number | null;
  reportingPeriodDateStart?: string;
  reportingPeriodDateEnd?: string;
  purchaseDate: string;
  productCode?: string;
  productName?: string;
  supplierName?: string;
  deliveredQuantity?: number;
  unit?: string | null;
  updatedAt: string;
  updatedBy: string;
};

export type FermentationReportSubmitPayload = Omit<FermentationReportPayload, 'id' | 'updated_at' | 'updated_by'>;
export type FermentationReportSubmitSerialized = Omit<
  FermentationReportSerialized,
  'id' | 'updatedAt' | 'updatedBy' | 'ubn'
>;

const { columns: defaultColumns } = FERMENTATION_REPORTS.FermentationReports;
const { columns: submitColumns } = FERMENTATION_REPORTS_SUBMIT.FermentationReportsSubmit;

const getDefaultSerializer = (data: DocumentData<FermentationReportPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(
      FERMENTATION_REPORTS.FermentationReports.unserialized,
      FERMENTATION_REPORTS.FermentationReports.serialized,
      [
        new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
        new CellSerializer(defaultColumns.status.unserialized, defaultColumns.status.serialized),
        new CellSerializer(defaultColumns.ubn.unserialized, defaultColumns.ubn.serialized),
        new CellSerializer(defaultColumns.idType.unserialized, defaultColumns.idType.serialized),
        new CellSerializer(defaultColumns.companyReport.unserialized, defaultColumns.companyReport.serialized),
        new CellSerializer(
          defaultColumns.reportingPeriodDateStart.unserialized,
          defaultColumns.reportingPeriodDateStart.serialized,
        ),
        new CellSerializer(
          defaultColumns.reportingPeriodDateEnd.unserialized,
          defaultColumns.reportingPeriodDateEnd.serialized,
        ),
        new CellSerializer(defaultColumns.purchaseDate.unserialized, defaultColumns.purchaseDate.serialized),
        new CellSerializer(defaultColumns.productCode.unserialized, defaultColumns.productCode.serialized),
        new CellSerializer(defaultColumns.productName.unserialized, defaultColumns.productName.serialized),
        new CellSerializer(defaultColumns.supplierName.unserialized, defaultColumns.supplierName.serialized),
        new CellSerializer(defaultColumns.deliveredQuantity.unserialized, defaultColumns.deliveredQuantity.serialized),
        new CellSerializer(defaultColumns.unit.unserialized, defaultColumns.unit.serialized),
        new CellSerializer(defaultColumns.updatedAt.unserialized, defaultColumns.updatedAt.serialized),
        new CellSerializer(defaultColumns.updatedBy.unserialized, defaultColumns.updatedBy.serialized),
      ],
    ),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<FermentationReportSubmitSerialized[]>) => {
  return new DocumentSerializer<FermentationReportSubmitPayload, FermentationReportSubmitSerialized>(data, [
    new SheetSerializer(
      FERMENTATION_REPORTS_SUBMIT.FermentationReportsSubmit.serialized,
      FERMENTATION_REPORTS_SUBMIT.FermentationReportsSubmit.unserialized,
      [
        new CellSerializer(submitColumns.status.unserialized, submitColumns.status.serialized),
        new CellSerializer(submitColumns.companyReport.unserialized, submitColumns.companyReport.serialized),
        new CellSerializer(
          submitColumns.reportingPeriodDateStart.unserialized,
          submitColumns.reportingPeriodDateStart.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.reportingPeriodDateEnd.unserialized,
          submitColumns.reportingPeriodDateEnd.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(submitColumns.purchaseDate.unserialized, submitColumns.purchaseDate.serialized),
        new CellSerializer(
          submitColumns.productCode.unserialized,
          submitColumns.productCode.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.productName.unserialized,
          submitColumns.productName.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.supplierName.unserialized,
          submitColumns.supplierName.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.deliveredQuantity.unserialized,
          submitColumns.deliveredQuantity.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(submitColumns.unit.unserialized, submitColumns.unit.serialized, undefined, undefined, null),
      ],
    ),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<FermentationReportSerialized[]>) => {
  return new DocumentSerializer<FermentationReportSerialized, FermentationReportSubmitSerialized>(data, [
    new SheetSerializer(
      FERMENTATION_REPORTS_SUBMIT.FermentationReportsSubmit.serialized,
      FERMENTATION_REPORTS_SUBMIT.FermentationReportsSubmit.unserialized,
      [
        new CellSerializer(defaultColumns.status.serialized, submitColumns.status.serialized),
        new CellSerializer(defaultColumns.companyReport.serialized, submitColumns.companyReport.serialized),
        new CellSerializer(
          defaultColumns.reportingPeriodDateStart.serialized,
          submitColumns.reportingPeriodDateStart.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.reportingPeriodDateEnd.serialized,
          submitColumns.reportingPeriodDateEnd.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(defaultColumns.purchaseDate.serialized, submitColumns.purchaseDate.serialized),
        new CellSerializer(
          defaultColumns.productCode.serialized,
          submitColumns.productCode.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.productName.serialized,
          submitColumns.productName.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.supplierName.serialized,
          submitColumns.supplierName.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.deliveredQuantity.serialized,
          submitColumns.deliveredQuantity.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.unit.serialized,
          submitColumns.unit.serialized,
          undefined,
          undefined,
          undefined,
        ),
      ],
    ),
  ]);
};

export const serializeFermentationReports = (serverData: ResourcesWrapper<FermentationReportPayload>) => {
  const { unserialized } = FERMENTATION_REPORTS.FermentationReports;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const serializeFermentationReport = (serverData: ResourceWrapper<FermentationReportPayload>) => {
  const { serialized, unserialized } = FERMENTATION_REPORTS.FermentationReports;
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

export const unserializeFermentationReportForSubmit = (
  resource: FermentationReportSubmitSerialized,
): FermentationReportSubmitPayload => {
  const { serialized, unserialized } = FERMENTATION_REPORTS_SUBMIT.FermentationReportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeFermentationReportStoSS = (resource: FermentationReportSerialized) => {
  const { serialized, unserialized } = FERMENTATION_REPORTS_SUBMIT.FermentationReportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

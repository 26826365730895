import {
  formatPercentsTo1Digits,
  formatPercentsTo2Digits,
  formatPercentsTo2DigitsFloating,
  roundTo1DigitsFloating,
  roundTo2Digits,
  roundTo2DigitsFloating,
  roundToInteger,
  suffixFormatter,
} from 'lib/helpers/renderHelpers';

export type UbnEntity = {
  name: string;
  ubn: string;
};

export type VriReportUnserialized = {
  id: number;
  vri_serial_number: string;
  user_passport_id: number;
  ubn: string;
  name: string;
  total: number;
  vri_epg: number;
  epg_serial_number: string;
  growth: number;
  feed_intake_per_day: number;
  ew_conversion: number;
  laying_big_weight: number;
  loss: number;
  epg: number;
  vri_classification: number;
  epg_order_number: number;
  balance_with_respect_to_base: number;
  average_weight: number;
  average_weight_correction: number;
  average_fat_thickness: number;
  average_beacon_correction: number;
  average_muscle_thickness: number;
  average_muscle_correction: number;
  average_meat: number;
  average_meat_correction: number;
  average_classification_correction: number;
  average_sex_allowance: number;
  average_concept_surcharge: number;
  pigs_in_draft: number;
  vri_health: number;
  epg_number: number;
  euro_per_pig: number;
  approved_carcass: number;
  pleurisy: number;
  inflamed_skin: number;
  inflamed_leg: number;
  approved_organ: number;
  lever_afg: number;
  long_afg: number;
  healthy: number;
  created_at: string;
  updated_at: string;
  file_upload?: {
    id: number;
    file: {
      url: string;
    };
    checksum: string;
    is_edited: boolean;
    upload_token: string;
    upload_type: string;
    uploaded_at: string;
    source: string;
    status: string;
    status_message: string;
    created_at: string;
    user: {
      email: string;
      name: string;
    };
  };
  report_upload: {
    report_date_from: string;
    report_date_to: string;
  };
};

export type VriReportSerialized = {
  id: number;
  vriSerialNumber: string;
  userPassportId: number;
  ubn: string;
  name: string;
  total: number;
  vriEpg: number;
  epgSerialNumber: string;
  growth: number;
  feedIntakePerDay: number;
  ewConversion: number;
  layingBigWeight: number;
  loss: number;
  epg: number;
  vriClassification: number;
  epgOrderNumber: number;
  balanceWithRespectToBase: number;
  averageWeight: number;
  averageWeightCorrection: number;
  averageFatThickness: number;
  averageBeaconCorrection: number;
  averageMuscleThickness: number;
  averageMuscleCorrection: number;
  averageMeat: number;
  averageMeatCorrection: number;
  averageClassificationCorrection: number;
  averageSexAllowance: number;
  averageConceptSurcharge: number;
  pigsInDraft: number;
  vriHealth: number;
  epgNumber: number;
  euroPerPig: number;
  approvedCarcass: number;
  pleurisy: number;
  inflamedSkin: number;
  inflamedLeg: number;
  approvedOrgan: number;
  leverAfg: number;
  longAfg: number;
  healthy: number;
  createdAt: string;
  updatedAt: string;
  // nested object serializing is not yet supported
  fileUpload?: {
    id: number;
    file: {
      url: string;
    };
    checksum: string;
    is_edited: boolean;
    upload_token: string;
    upload_type: string;
    uploaded_at: string;
    source: string;
    status: string;
    status_message: string;
    created_at: string;
    user: {
      email: string;
      name: string;
    };
  };
  reportUpload: {
    report_date_from: string;
    report_date_to: string;
  };
};

interface Constants {
  epg: {
    total_average: number;
    total_top_20: number;
  };
  health_balance: {
    total_average: number;
    total_top_20: number;
  };
  balance_base: {
    total_average: number;
    total_top_20: number;
  };
}

export type VriResourceItemUnserialized = {
  id: number;
  rating?: null | string;
  report_date_from: string;
  report_date_to: string;
  total_min: number;
  total_max: number;
  epg_min: number;
  epg_max: number;
  euro_per_pig_min: number;
  euro_per_pig_max: number;
  balance_with_respect_to_base_min: number;
  balance_with_respect_to_base_max: number;
  constants: Constants;
  reports: VriReportUnserialized[];
};

export type VriResourceItemSerialized = {
  id: number;
  rating: string;
  reportDateFrom: string;
  reportDateTo: string;
  totalMin: number;
  totalMax: number;
  epgMin: number;
  epgMax: number;
  euroPerPigMin: number;
  euroPerPigMax: number;
  balanceWithRespectToBaseMin: number;
  balanceWithRespectToBaseMax: number;
  constants: Constants;
  reports: VriReportSerialized[];
};

export type DateRange = {
  reportDateFrom: string;
  reportDateTo: string;
  id: number;
};

type VriStatisticEntityUnserialized = {
  your_score: number;
  average_top_20_score: number;
  difference: number;
};

type VriStatisticEntitySerialized = {
  yourScore: number;
  averageTop20Score: number;
  difference: number;
};

export type VriStatisticsResourceUnserialized = {
  vri_total: VriStatisticEntityUnserialized;
  lap_speed: VriStatisticEntityUnserialized;
  delivered_animals: VriStatisticEntityUnserialized;
  delivered: VriStatisticEntityUnserialized;
  vri_epg: VriStatisticEntityUnserialized;
  epg_euro_per_pig: VriStatisticEntityUnserialized;
  growth: VriStatisticEntityUnserialized;
  feed_intake_per_day: VriStatisticEntityUnserialized;
  ew_conversion: VriStatisticEntityUnserialized;
  laying_big_weight: VriStatisticEntityUnserialized;
  loss: VriStatisticEntityUnserialized;
  vri_balance_with_respect_to_base: VriStatisticEntityUnserialized;
  balance_with_respect_to_base: VriStatisticEntityUnserialized;
  average_weight: VriStatisticEntityUnserialized;
  average_weight_correction: VriStatisticEntityUnserialized;
  average_fat_thickness: VriStatisticEntityUnserialized;
  average_beacon_correction: VriStatisticEntityUnserialized;
  average_muscle_thickness: VriStatisticEntityUnserialized;
  average_muscle_correction: VriStatisticEntityUnserialized;
  average_meat: VriStatisticEntityUnserialized;
  average_meat_correction: VriStatisticEntityUnserialized;
  average_classification_correction: VriStatisticEntityUnserialized;
  average_sex_allowance: VriStatisticEntityUnserialized;
  average_concept_surcharge: VriStatisticEntityUnserialized;
  pigs_in_draft: VriStatisticEntityUnserialized;
  vri_health: VriStatisticEntityUnserialized;
  euro_per_pig: VriStatisticEntityUnserialized;
  approved_carcass: VriStatisticEntityUnserialized;
  pleurisy: VriStatisticEntityUnserialized;
  inflamed_skin: VriStatisticEntityUnserialized;
  inflamed_leg: VriStatisticEntityUnserialized;
  approved_organ: VriStatisticEntityUnserialized;
  liver_afg: VriStatisticEntityUnserialized;
  lung_afg: VriStatisticEntityUnserialized;
  healthy: VriStatisticEntityUnserialized;
  business_total: {
    vri_total: number;
    epg_euro_per_pig: number;
    balance_with_respect_to_base: number;
    euro_per_pig: number;
  };
};

export type VriStatisticsResourceSerialized = {
  vriTotal: VriStatisticEntitySerialized;
  lapSpeed: VriStatisticEntitySerialized;
  deliveredAnimals: VriStatisticEntitySerialized;
  delivered: VriStatisticEntitySerialized;
  vriEpg: VriStatisticEntitySerialized;
  epgEuroPerPig: VriStatisticEntitySerialized;
  growth: VriStatisticEntitySerialized;
  feedIntakePerDay: VriStatisticEntitySerialized;
  ewConversion: VriStatisticEntitySerialized;
  layingBigWeight: VriStatisticEntitySerialized;
  loss: VriStatisticEntitySerialized;
  vriBalanceWithRespectToBase: VriStatisticEntitySerialized;
  balanceWithRespectToBase: VriStatisticEntitySerialized;
  averageWeight: VriStatisticEntitySerialized;
  averageWeightCorrection: VriStatisticEntitySerialized;
  averageFatThickness: VriStatisticEntitySerialized;
  averageBeaconCorrection: VriStatisticEntitySerialized;
  averageMuscleThickness: VriStatisticEntitySerialized;
  averageMuscleCorrection: VriStatisticEntitySerialized;
  averageMeat: VriStatisticEntitySerialized;
  averageMeatCorrection: VriStatisticEntitySerialized;
  averageClassificationCorrection: VriStatisticEntitySerialized;
  averageSexAllowance: VriStatisticEntitySerialized;
  averageConceptSurcharge: VriStatisticEntitySerialized;
  pigsInDraft: VriStatisticEntitySerialized;
  vriHealth: VriStatisticEntitySerialized;
  euroPerPig: VriStatisticEntitySerialized;
  approvedCarcass: VriStatisticEntitySerialized;
  pleurisy: VriStatisticEntitySerialized;
  inflamedSkin: VriStatisticEntitySerialized;
  inflamedLeg: VriStatisticEntitySerialized;
  approvedOrgan: VriStatisticEntitySerialized;
  liverAfg: VriStatisticEntitySerialized;
  lungAfg: VriStatisticEntitySerialized;
  healthy: VriStatisticEntitySerialized;
  businessTotal: {
    vriTotal: number;
    epgEuroPerPig: number;
    balanceWithRespectToBase: number;
    euroPerPig: number;
  };
};

export type VriConstantsUnserialized = {
  growth: number;
  feed_intake_per_day: number;
  ew_conversion: number;
  laying_big_weight: number;
  loss: number;
  gender_weight: number;
  bacon_weight: number;
  muscle_thickness: number;
  meat_percent: number;
  epg: number;
  average_weight: number;
  average_weight_correction: number;
  average_fat_thickness: number;
  average_beacon_correction: number;
  average_muscle_thickness: number;
  average_muscle_correction: number;
  average_meat: number;
  average_meat_correction: number;
  average_classification_correction: number;
  average_sex_allowance: number;
  average_concept_surcharge: number;
  pigs_in_draft: number;
  balance_with_respect_to_base: number;
  approved_carcass: number;
  pleurisy: number;
  inflamed_skin: number;
  inflamed_leg: number;
  approved_organ: number;
  liver_afg: number;
  lung_afg: number;
  healthy: number;
  euro_per_pig: number;
  vri_total: number;
  vri_epg: number;
  vri_balance: number;
  vri_health: number;
};

export type VriConstantsSerialized = {
  growth: number;
  feedIntakePerDay: number;
  ewConversion: number;
  layingBigWeight: number;
  loss: number;
  genderWeight: number;
  baconWeight: number;
  muscleThickness: number;
  meatPercent: number;
  epg: number;
  averageWeight: number;
  averageWeightCorrection: number;
  averageFatThickness: number;
  averageBeaconCorrection: number;
  averageMuscleThickness: number;
  averageMuscleCorrection: number;
  averageMeat: number;
  averageMeatCorrection: number;
  averageClassificationCorrection: number;
  averageSexAllowance: number;
  averageConceptSurcharge: number;
  pigsInDraft: number;
  balanceWithRespectToBase: number;
  approvedCarcass: number;
  pleurisy: number;
  inflamedSkin: number;
  inflamedLeg: number;
  approvedOrgan: number;
  liverAfg: number;
  lungAfg: number;
  healthy: number;
  euroPerPig: number;
  vriTotal: number;
  vriEpg: number;
  vriBalance: number;
  vriHealth: number;
};

export type VriReportsConstantsUnserialized = {
  top_20: VriConstantsUnserialized;
  avg: VriConstantsUnserialized;
};

export type VriReportsConstantsSerialized = {
  top20: VriConstantsSerialized;
  avg: VriConstantsSerialized;
};

export enum VriSerialized {
  vri = 'vri',
}

export enum VriUnserialized {
  vri = 'vri',
}

export enum VriView {
  vri = 'VRI',
}

export enum VriSerializedHeaders {
  id = 'id',
  rating = 'rating',
  vriSerialNumber = 'vriSerialNumber',
  ubn = 'ubn',
  name = 'name',
  total = 'total',
  vriEpg = 'vriEpg',
  epgSerialNumber = 'epgSerialNumber',
  growth = 'growth',
  feedIntakePerDay = 'feedIntakePerDay',
  ewConversion = 'ewConversion',
  layingBigWeight = 'layingBigWeight',
  loss = 'loss',
  epg = 'epg',
  vriClassification = 'vriClassification',
  epgOrderNumber = 'epgOrderNumber',
  balanceWithRespectToBase = 'balanceWithRespectToBase',
  averageWeight = 'averageWeight',
  averageWeightCorrection = 'averageWeightCorrection',
  averageFatThickness = 'averageFatThickness',
  averageBeaconCorrection = 'averageBeaconCorrection',
  averageMuscleThickness = 'averageMuscleThickness',
  averageMuscleCorrection = 'averageMuscleCorrection',
  averageMeat = 'averageMeat',
  averageMeatCorrection = 'averageMeatCorrection',
  averageClassificationCorrection = 'averageClassificationCorrection',
  averageSexAllowance = 'averageSexAllowance',
  averageConceptSurcharge = 'averageConceptSurcharge',
  pigsInDraft = 'pigsInDraft',
  vriHealth = 'vriHealth',
  epgNumber = 'epgNumber',
  euroPerPig = 'euroPerPig',
  approvedCarcass = 'approvedCarcass',
  pleurisy = 'pleurisy',
  inflamedSkin = 'inflamedSkin',
  inflamedLeg = 'inflamedLeg',
  approvedOrgan = 'approvedOrgan',
  leverAfg = 'leverAfg',
  liverAfg = 'liverAfg',
  longAfg = 'longAfg',
  lungAfg = 'lungAfg',
  healthy = 'healthy',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  fileUpload = 'fileUpload',
  reportUpload = 'reportUpload',
  // fileUpload
  file = 'file',
  url = 'url',
  checksum = 'checksum',
  isEdited = 'isEdited',
  uploadToken = 'uploadToken',
  uploadType = 'uploadType',
  source = 'source',
  status = 'status',
  statusMessage = 'statusMessage',
  user = 'user',
  email = 'email',
  // reportUpload
  reportDateFrom = 'reportDateFrom',
  reportDateTo = 'reportDateTo',
  // resource
  totalMin = 'totalMin',
  totalMax = 'totalMax',
  epgMin = 'epgMin',
  epgMax = 'epgMax',
  euroPerPigMin = 'euroPerPigMin',
  euroPerPigMax = 'euroPerPigMax',
  balanceWithRespectToBaseMin = 'balanceWithRespectToBaseMin',
  balanceWithRespectToBaseMax = 'balanceWithRespectToBaseMax',
  constants = 'constants',
  // statistics
  vriTotal = 'vriTotal',
  lapSpeed = 'lapSpeed',
  deliveredAnimals = 'deliveredAnimals',
  delivered = 'delivered',
  vriBalanceWithRespectToBase = 'vriBalanceWithRespectToBase',
  businessTotal = 'businessTotal',
  yourScore = 'yourScore',
  averageTop20Score = 'averageTop20Score',
  difference = 'difference',
  epgEuroPerPig = 'epgEuroPerPig',
  // constants
  genderWeight = 'genderWeight',
  baconWeight = 'baconWeight',
  meatPercent = 'meatPercent',
  vriBalance = 'vriBalance',
  top20 = 'top20',
  avg = 'avg',
  endManufacturer = 'endManufacturer',
  feedingSystem = 'feedingSystem',
}

export enum VriUnserializedHeaders {
  id = 'id',
  rating = 'rating',
  vriSerialNumber = 'vri_serial_number',
  ubn = 'ubn',
  name = 'name',
  total = 'total',
  vriEpg = 'vri_epg',
  epgSerialNumber = 'epg_serial_number',
  growth = 'growth',
  feedIntakePerDay = 'feed_intake_per_day',
  ewConversion = 'ew_conversion',
  layingBigWeight = 'laying_big_weight',
  loss = 'loss',
  epg = 'epg',
  vriClassification = 'vri_classification',
  epgOrderNumber = 'epg_order_number',
  balanceWithRespectToBase = 'balance_with_respect_to_base',
  averageWeight = 'average_weight',
  averageWeightCorrection = 'average_weight_correction',
  averageFatThickness = 'average_fat_thickness',
  averageBeaconCorrection = 'average_beacon_correction',
  averageMuscleThickness = 'average_muscle_thickness',
  averageMuscleCorrection = 'average_muscle_correction',
  averageMeat = 'average_meat',
  averageMeatCorrection = 'average_meat_correction',
  averageClassificationCorrection = 'average_classification_correction',
  averageSexAllowance = 'average_sex_allowance',
  averageConceptSurcharge = 'average_concept_surcharge',
  pigsInDraft = 'pigs_in_draft',
  vriHealth = 'vri_health',
  epgNumber = 'epg_number',
  euroPerPig = 'euro_per_pig',
  approvedCarcass = 'approved_carcass',
  pleurisy = 'pleurisy',
  inflamedSkin = 'inflamed_skin',
  inflamedLeg = 'inflamed_leg',
  approvedOrgan = 'approved_organ',
  leverAfg = 'lever_afg',
  liverAfg = 'liver_afg',
  longAfg = 'long_afg',
  lungAfg = 'lung_afg',
  healthy = 'healthy',
  createdAt = 'updated_at',
  updatedAt = 'updated_at',
  fileUpload = 'file_upload',
  reportUpload = 'report_upload',
  // fileUpload
  file = 'file',
  url = 'url',
  checksum = 'checksum',
  isEdited = 'is_edited',
  uploadToken = 'upload_token',
  uploadType = 'upload_type',
  source = 'source',
  status = 'status',
  statusMessage = 'status_message',
  user = 'user',
  email = 'email',
  // reportUpload
  reportDateFrom = 'report_date_from',
  reportDateTo = 'report_date_to',
  // resource
  totalMin = 'total_min',
  totalMax = 'total_max',
  epgMin = 'epg_min',
  epgMax = 'epg_max',
  euroPerPigMin = 'euro_per_pig_min',
  euroPerPigMax = 'euro_per_pig_max',
  balanceWithRespectToBaseMin = 'balance_with_respect_to_base_min',
  balanceWithRespectToBaseMax = 'balance_with_respect_to_base_max',
  constants = 'constants',
  // statistics
  vriTotal = 'vri_total',
  lapSpeed = 'lap_speed',
  deliveredAnimals = 'delivered_animals',
  delivered = 'delivered',
  vriBalanceWithRespectToBase = 'vri_balance_with_respect_to_base',
  businessTotal = 'business_total',
  yourScore = 'your_score',
  averageTop20Score = 'average_top_20_score',
  difference = 'difference',
  epgEuroPerPig = 'epg_euro_per_pig',
  // constants
  genderWeight = 'gender_weight',
  baconWeight = 'bacon_weight',
  meatPercent = 'meat_percent',
  vriBalance = 'vri_balance',
  top20 = 'top_20',
  avg = 'avg',
  'endManufacturer' = 'end_manufacturer',
  'feedingSystem' = 'feeding_system',
}

export enum VriViewHeaders {
  id = 'Id',
  rating = 'Concept',
  vriSerialNumber = 'VRI Serial number',
  ubn = 'UBN',
  name = 'NAAM',
  total = 'VRI TOTAAL',
  vriEpg = 'VRI EPG',
  epgSerialNumber = 'Volgnummer',
  growth = 'GROEI (gr/dag)',
  feedIntakePerDay = 'VOEROPNAME (kg/dag)',
  ewConversion = 'EW-CONVERSIE',
  layingBigWeight = 'OPLEG GEWICHT BIG (kg)',
  loss = 'UITVAL (%)',
  epg = 'EPG (€)',
  vriClassification = 'VRI Classificatie',
  epgOrderNumber = 'epg Volg-nummer',
  balanceWithRespectToBase = 'Saldo t.o.v. Basis (ct/kg)',
  averageWeight = 'Gem geslachtgewicht (kg)',
  averageWeightCorrection = 'Gem gewicht correctie (ct/kg)',
  averageFatThickness = 'Gem. spekdikte (mm)',
  averageBeaconCorrection = 'Gem spek correctie (ct/kg)',
  averageMuscleThickness = 'Gem spierdikte (mm)',
  averageMuscleCorrection = 'Gem spier correctie (ct/kg)',
  averageMeat = 'Gem vlees (%)',
  averageMeatCorrection = 'Gem vlees% correctie (ct/kg)',
  averageClassificationCorrection = 'Gem classificatie correctie (ct/kg)',
  averageSexAllowance = 'Gem sexe toeslag (ct/kg)',
  averageConceptSurcharge = 'Gem concept toeslag (ct/kg)',
  pigsInDraft = 'Varkens in concept (%)',
  vriHealth = 'VRI gezondheid',
  epgNumber = 'epg volgnummer',
  euroPerPig = 'Gezondheidssaldo (€ per varken)',
  approvedCarcass = 'Karkas Goed gekeurd',
  pleurisy = 'Pleuritis',
  inflamedSkin = 'Ontst. huid',
  inflamedLeg = 'Ontst. poot',
  approvedOrgan = 'Orgaan Goed gekeurd',
  leverAfg = 'Lever afg.',
  liverAfg = 'Lever afg.',
  longAfg = 'Long afg.',
  lungAfg = 'Long afg.',
  healthy = 'Geen Karkas en Orgaan afwijkingen (%)',
  // statistics
  vriTotal = 'VRI Totaal',
  lapSpeed = 'Rondesnelheid',
  deliveredAnimals = 'Afgeleverde dieren',
  delivered = 'Afgeleverde kg',
  vriBalanceWithRespectToBase = 'Balance with respect to VRI Base',
  endManufacturer = 'EINDBEER',
  feedingSystem = 'VOER SYSTEEM',
}

export const VriFormatters: Record<string, (value: number) => string> = {
  vriSerialNumber: roundToInteger,
  total: roundToInteger,
  vriEpg: roundTo2Digits,
  epgSerialNumber: roundToInteger,
  growth: roundToInteger,
  feedIntakePerDay: roundTo2DigitsFloating,
  ewConversion: roundTo2DigitsFloating,
  layingBigWeight: roundTo1DigitsFloating,
  loss: roundTo1DigitsFloating,
  epg: roundTo2Digits,
  vriClassification: roundToInteger,
  epgOrderNumber: roundToInteger,
  balanceWithRespectToBase: roundTo2Digits,
  vriBalanceWithRespectToBase: roundTo2Digits,
  averageWeight: roundTo2Digits,
  averageWeightCorrection: roundTo2Digits,
  averageFatThickness: roundTo2Digits,
  averageBeaconCorrection: roundTo2Digits,
  averageMuscleThickness: roundTo2Digits,
  averageMuscleCorrection: roundTo2Digits,
  averageMeat: roundTo2Digits,
  averageMeatCorrection: roundTo2Digits,
  averageClassificationCorrection: roundTo2Digits,
  averageSexAllowance: roundTo2Digits,
  averageConceptSurcharge: roundTo2Digits,
  pigsInDraft: formatPercentsTo2DigitsFloating,
  vriHealth: roundTo2Digits,
  epgNumber: roundToInteger,
  euroPerPig: suffixFormatter(roundTo2Digits, '', '€'),
  approvedCarcass: formatPercentsTo2DigitsFloating,
  pleurisy: formatPercentsTo2DigitsFloating,
  inflamedSkin: formatPercentsTo2DigitsFloating,
  inflamedLeg: formatPercentsTo2DigitsFloating,
  approvedOrgan: formatPercentsTo2DigitsFloating,
  leverAfg: formatPercentsTo1Digits,
  liverAfg: formatPercentsTo2DigitsFloating,
  longAfg: formatPercentsTo1Digits,
  lungAfg: formatPercentsTo2DigitsFloating,
  healthy: formatPercentsTo2DigitsFloating,
  // statistics
  vriTotal: roundTo2Digits,
  lapSpeed: roundTo2Digits,
  deliveredAnimals: roundTo2Digits,
  delivered: roundTo2Digits,
};

// FUNCTIONS FOR VRI TABLES
export const customVriRenderByFieldName = (field: keyof typeof VriSerializedHeaders) => (data: Record<string, any>) => {
  const value = data[VriSerializedHeaders[field]];
  return VriFormatters[field] ? VriFormatters[field](value) : value;
};

export const customVriFilterAndSearchByFieldName = (field: keyof typeof VriSerializedHeaders) => (
  searchedValue: string,
  data: Record<string, any>,
) => {
  const cellValue = customVriRenderByFieldName(field)(data);
  return String(cellValue).indexOf(searchedValue) != -1;
};

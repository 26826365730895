import { Route } from '../../../Route';
import RouteParser from 'route-parser';

export const uploadHistory = new Route('get', new RouteParser('/file_uploads'), () => {
  return [
    200,
    {
      resources: [
        {
          id: 36,
          file: { url: '/uploads/companies/1/user_channels/3/file_upload/36/file/Bedrijven.xlsx' },
          checksum: '1598e21ae90fb2ec7eb683466a9525be',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: '2020-06-23T08:33:20.510Z',
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-23T08:33:05.359Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 35,
          file: { url: '/uploads/companies/1/user_channels/3/file_upload/35/file/Bedrijven.xlsx' },
          checksum: '1598e21ae90fb2ec7eb683466a9525be',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: '2020-06-23T08:32:21.239Z',
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-23T08:32:03.741Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 34,
          file: { url: '/uploads/companies/1/user_channels/3/file_upload/34/file/Bedrijven.xlsx' },
          checksum: 'a0248e3e2db2d36e5c3d6a5fce49ddf5',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-23T08:31:22.067Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 33,
          file: { url: '/uploads/companies/1/user_channels/3/file_upload/33/file/Bedrijven.xlsx' },
          checksum: 'a0248e3e2db2d36e5c3d6a5fce49ddf5',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: '2020-06-23T08:31:04.036Z',
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-23T08:30:44.521Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 32,
          file: {
            url: '/uploads/companies/1/user_channels/3/file_upload/32/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-15T13:23:00.666Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 31,
          file: { url: '/uploads/companies/1/user_channels/3/file_upload/31/file/Bedrijven.xlsx' },
          checksum: 'a0248e3e2db2d36e5c3d6a5fce49ddf5',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: '2020-06-15T13:15:51.348Z',
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-15T13:15:35.798Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 30,
          file: {
            url:
              '/uploads/companies/1/user_channels/3/file_upload/30/file/https___vbbc-tst.westeurope.cloudapp.azure.com_uploads_companies_1_user_channels_3_file_upload_28_file_Kiwa_Daily_Bedrijven.xlsx',
          },
          checksum: '8d695bc60f4d841bd58bb7d5d8cedb9e',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-09T09:52:29.401Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 29,
          file: {
            url:
              '/uploads/companies/1/user_channels/3/file_upload/29/file/https___vbbc-tst.westeurope.cloudapp.azure.com_uploads_companies_1_user_channels_3_file_upload_28_file_Kiwa_Daily_Bedrijven.xlsx',
          },
          checksum: '8d695bc60f4d841bd58bb7d5d8cedb9e',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-08T10:43:14.415Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 28,
          file: {
            url: '/uploads/companies/1/user_channels/3/file_upload/28/file/Kiwa_Daily_Bedrijven.xlsx',
          },
          checksum: '314ca6eb83cdcd8905a321ca868fe0fd',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-05T15:25:04.368Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 27,
          file: {
            url: '/uploads/companies/1/user_channels/1/file_upload/27/file/Weekly_Farming_2020-5-5.xlsx',
          },
          checksum: 'e3993e9559559f7bf4c890e6a8ddf9b1',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-05T14:52:27.690Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 26,
          file: {
            url: '/uploads/companies/1/user_channels/1/file_upload/26/file/2020-5-5_Bedrijven.xlsx',
          },
          checksum: 'f2f3e7ae21b6038230c4f380075e6cac',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-05T14:51:01.492Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 25,
          file: { url: '/uploads/companies/1/user_channels/2/file_upload/25/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: '2020-06-05T11:59:06.732Z',
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-05T11:58:50.775Z',
          user: { email: 'vbbc-admin_2@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 24,
          file: { url: '/uploads/companies/1/user_channels/3/file_upload/24/file/Bedrijven.xlsx' },
          checksum: 'd7f16cbcac65f3cd64ce4e961cc5caa3',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-05T07:10:42.194Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 23,
          file: { url: '/uploads/companies/1/user_channels/3/file_upload/23/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-05T07:10:03.254Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 22,
          file: {
            url: '/uploads/companies/1/user_channels/2/file_upload/22/file/Bedrijven__2_.xlsx',
          },
          checksum: '88584fd3ccb7ea5796c4c7818358bc85',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-04T14:54:11.318Z',
          user: { email: 'vbbc-admin_2@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 21,
          file: {
            url: '/uploads/companies/1/user_channels/2/file_upload/21/file/Bedrijven__1_.xlsx',
          },
          checksum: '47aa15313147a4e4c6814f9f48f28af8',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-04T14:52:45.443Z',
          user: { email: 'vbbc-admin_2@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 20,
          file: {
            url: '/uploads/companies/1/user_channels/3/file_upload/20/file/Kiwa_Daily_Bedrijven.xlsx',
          },
          checksum: '314ca6eb83cdcd8905a321ca868fe0fd',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-04T12:40:11.192Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 19,
          file: {
            url: '/uploads/companies/1/user_channels/3/file_upload/19/file/Kiwa_Daily_Bedrijven.xlsx',
          },
          checksum: '10ee0d8a56bd407468ba8f289bbd5536',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-04T12:29:50.627Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 18,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/18/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-04T07:26:19.882Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 17,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/17/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-04T07:23:26.644Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 16,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/16/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-03T14:44:11.539Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 15,
          file: {
            url: '/uploads/companies/1/user_channels/3/file_upload/15/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T13:06:18.658Z',
          user: { email: 'vbbc-vionadmin@vbbc-example.com', name: 'Vion Admin 1' },
        },
        {
          id: 14,
          file: {
            url: '/uploads/companies/1/user_channels/1/file_upload/14/file/Vion_Farming_20200417_2.xlsx',
          },
          checksum: '6af366a590809520a62526dd8b4b7807',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-03T12:21:37.584Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 13,
          file: {
            url: '/uploads/companies/1/user_channels/2/file_upload/13/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T12:16:43.209Z',
          user: { email: 'vbbc-admin_2@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 12,
          file: { url: '/uploads/companies/1/user_channels/2/file_upload/12/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T12:15:37.104Z',
          user: { email: 'vbbc-admin_2@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 11,
          file: {
            url: '/uploads/companies/1/user_channels/1/file_upload/11/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T11:50:12.034Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 10,
          file: {
            url: '/uploads/companies/1/user_channels/2/file_upload/10/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T11:48:05.381Z',
          user: { email: 'vbbc-admin_2@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 9,
          file: {
            url: '/uploads/companies/1/user_channels/1/file_upload/9/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T11:37:35.129Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 8,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/8/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T11:36:57.601Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 7,
          file: {
            url: '/uploads/companies/1/user_channels/1/file_upload/7/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-03T09:41:44.597Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 6,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/6/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-03T09:31:54.390Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 5,
          file: { url: '/uploads/companies/1/user_channels/2/file_upload/5/file/Bedrijven.xlsx' },
          checksum: 'f4fcfa2566f2bf38368304b4dc6bdc4c',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-02T14:17:31.024Z',
          user: { email: 'vbbc-admin_2@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 4,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/4/file/Bedrijven_4.xlsx' },
          checksum: 'd6150812acbacdbc7e71a9ee67799636',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-02T14:06:35.572Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 3,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/3/file/Bedrijven_3.xlsx' },
          checksum: 'c425663e0dd8bc29c0d383affe7a07e1',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-02T10:53:49.592Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 2,
          file: { url: '/uploads/companies/1/user_channels/1/file_upload/2/file/Bedrijven.xlsx' },
          checksum: '7b7ef6db30d87968a2a6eddae615e722',
          is_edited: false,
          upload_type: 'daily',
          uploaded_at: null,
          source: 'manual',
          status: 'uploaded',
          created_at: '2020-06-02T10:37:38.245Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
        {
          id: 1,
          file: {
            url: '/uploads/companies/1/user_channels/1/file_upload/1/file/Vion_Farming_20200417.xlsx',
          },
          checksum: '5d28173377afbebe338b5cb014e72541',
          is_edited: false,
          upload_type: 'weekly',
          uploaded_at: null,
          source: 'manual',
          status: 'pending',
          created_at: '2020-06-01T09:29:14.975Z',
          user: { email: 'vbbc-admin_1@vbbc-example.com', name: 'John Doe' },
        },
      ],
    },
  ];
});

import { IntlKeys } from 'lib/localization/keys/__keys';

enum Cl550Original {
  Cl550 = 'cl550',
}

enum Cl550Serialized {
  Cl550 = 'Cl550',
}

enum Cl550Unserialized {
  Cl550 = 'Cl550',
}

enum Cl550OriginalHeaders {
  'mainGroupCode' = 'Code Hoofdgroep',
  'subGroupCode' = 'Code Subgroep',
  'codeDetails' = 'Code Detallering',
  'mainGroupName' = 'Hoofdgroep',
  'subgroupName' = 'Subgroep',
  'detailing' = 'Detaillering',
  'english' = 'ENGLISH',
  'german' = 'DEUTSCH',
  'codeCondition' = 'Code Aandoening',
  'edi' = 'EDI',
  'dgr' = 'DGR',
  'diseaseAndTreatment' = 'ziekte/behandeling',
  'failure' = 'uitval',
  'disposal' = 'afvoer',
  'selection' = 'selectie',
  'labResult' = 'lab.uitslag',
  'section' = 'sectie',
  'slaughterhouseData' = 'slachthuisgegevens',
  'agility' = 'berigheid',
  'coverage' = 'dekking',
  'birth' = 'Geboorte',
  'suckingPiglet' = 'zuigende big',
  'weanedBig' = 'gespeende big',
  'rearingSowAndBoar' = 'opfokzeug/beer',
  'goodSow' = 'guste zeug',
  'gestationSow' = 'dragende zeug',
  'lactatingSow' = 'lacterende zeug',
  'beer' = 'beer',
  'finisher' = 'vleesvarken',
  'comment' = 'opmerkingen',
  'added' = 'toegevoegd',
  'deleted' = 'verwijderd',
}

enum Cl550SerializedHeaders {
  'mainGroupCode' = 'mainGroupCode',
  'subGroupCode' = 'subGroupCode',
  'codeDetails' = 'codeDetails',
  'mainGroupName' = 'mainGroupName',
  'subgroupName' = 'subgroupName',
  'detailing' = 'detailing',
  'english' = 'english',
  'german' = 'german',
  'codeCondition' = 'codeCondition',
  'edi' = 'edi',
  'dgr' = 'dgr',
  'diseaseAndTreatment' = 'diseaseAndTreatment',
  'failure' = 'failure',
  'disposal' = 'disposal',
  'selection' = 'selection',
  'labResult' = 'labResult',
  'section' = 'section',
  'slaughterhouseData' = 'slaughterhouseData',
  'agility' = 'agility',
  'coverage' = 'coverage',
  'birth' = 'birth',
  'suckingPiglet' = 'suckingPiglet',
  'weanedBig' = 'weanedBig',
  'rearingSowAndBoar' = 'rearingSowAndBoar',
  'goodSow' = 'goodSow',
  'gestationSow' = 'gestationSow',
  'lactatingSow' = 'lactatingSow',
  'beer' = 'beer',
  'finisher' = 'finisher',
  'comment' = 'comment',
  'added' = 'added',
  'deleted' = 'deleted',
}
enum Cl550UnserializedHeaders {
  'mainGroupCode' = 'main_group_code',
  'subGroupCode' = 'subgroup_code',
  'codeDetails' = 'code_details',
  'mainGroupName' = 'main_group_name',
  'subgroupName' = 'subgroup_name',
  'detailing' = 'detailing',
  'english' = 'english',
  'german' = 'german',
  'codeCondition' = 'code_condition',
  'edi' = 'edi',
  'dgr' = 'dgr',
  'diseaseAndTreatment' = 'disease_and_treatment',
  'failure' = 'failure',
  'disposal' = 'disposal',
  'selection' = 'selection',
  'labResult' = 'lab_result',
  'section' = 'section',
  'slaughterhouseData' = 'slaughterhouse_data',
  'agility' = 'agility',
  'coverage' = 'coverage',
  'birth' = 'birth',
  'suckingPiglet' = 'sucking_piglet',
  'weanedBig' = 'weaned_big',
  'rearingSowAndBoar' = 'rearing_sow_and_boar',
  'goodSow' = 'good_sow',
  'gestationSow' = 'gestation_sow',
  'lactatingSow' = 'lactating_sow',
  'beer' = 'beer',
  'finisher' = 'finisher',
  'comment' = 'comment',
  'added' = 'added',
  'deleted' = 'deleted',
}

export const CL550_FIELDS = {
  [Cl550Unserialized.Cl550]: {
    original: Cl550Original.Cl550,
    serialized: Cl550Serialized.Cl550,
    unserialized: Cl550Unserialized.Cl550,
    view: IntlKeys.referenceTablesCl550,
    viewName: 'cl550',

    columns: {
      [Cl550SerializedHeaders.mainGroupCode]: {
        original: Cl550OriginalHeaders.mainGroupCode,
        serialized: Cl550SerializedHeaders.mainGroupCode,
        unserialized: Cl550UnserializedHeaders.mainGroupCode,
        view: IntlKeys.referenceTablesCl550MainGroupCode,
      },
      [Cl550SerializedHeaders.subGroupCode]: {
        original: Cl550OriginalHeaders.subGroupCode,
        serialized: Cl550SerializedHeaders.subGroupCode,
        unserialized: Cl550UnserializedHeaders.subGroupCode,
        view: IntlKeys.referenceTablesCl550SubGroupCode,
      },
      [Cl550SerializedHeaders.codeDetails]: {
        original: Cl550OriginalHeaders.codeDetails,
        serialized: Cl550SerializedHeaders.codeDetails,
        unserialized: Cl550UnserializedHeaders.codeDetails,
        view: IntlKeys.referenceTablesCl550CodeDetails,
      },
      [Cl550SerializedHeaders.mainGroupName]: {
        original: Cl550OriginalHeaders.mainGroupName,
        serialized: Cl550SerializedHeaders.mainGroupName,
        unserialized: Cl550UnserializedHeaders.mainGroupName,
        view: IntlKeys.referenceTablesCl550MainGroupName,
      },
      [Cl550SerializedHeaders.subgroupName]: {
        original: Cl550OriginalHeaders.subgroupName,
        serialized: Cl550SerializedHeaders.subgroupName,
        unserialized: Cl550UnserializedHeaders.subgroupName,
        view: IntlKeys.referenceTablesCl550SubgroupName,
      },
      [Cl550SerializedHeaders.detailing]: {
        original: Cl550OriginalHeaders.detailing,
        serialized: Cl550SerializedHeaders.detailing,
        unserialized: Cl550UnserializedHeaders.detailing,
        view: IntlKeys.referenceTablesCl550Detailing,
      },
      [Cl550SerializedHeaders.english]: {
        original: Cl550OriginalHeaders.english,
        serialized: Cl550SerializedHeaders.english,
        unserialized: Cl550UnserializedHeaders.english,
        view: IntlKeys.referenceTablesCl550English,
      },
      [Cl550SerializedHeaders.german]: {
        original: Cl550OriginalHeaders.german,
        serialized: Cl550SerializedHeaders.german,
        unserialized: Cl550UnserializedHeaders.german,
        view: IntlKeys.referenceTablesCl550German,
      },
      [Cl550SerializedHeaders.codeCondition]: {
        original: Cl550OriginalHeaders.codeCondition,
        serialized: Cl550SerializedHeaders.codeCondition,
        unserialized: Cl550UnserializedHeaders.codeCondition,
        view: IntlKeys.referenceTablesCl550CodeCondition,
      },
      [Cl550SerializedHeaders.edi]: {
        original: Cl550OriginalHeaders.edi,
        serialized: Cl550SerializedHeaders.edi,
        unserialized: Cl550UnserializedHeaders.edi,
        view: IntlKeys.referenceTablesCl550Edi,
      },
      [Cl550SerializedHeaders.dgr]: {
        original: Cl550OriginalHeaders.dgr,
        serialized: Cl550SerializedHeaders.dgr,
        unserialized: Cl550UnserializedHeaders.dgr,
        view: IntlKeys.referenceTablesCl550Dgr,
      },
      [Cl550SerializedHeaders.diseaseAndTreatment]: {
        original: Cl550OriginalHeaders.diseaseAndTreatment,
        serialized: Cl550SerializedHeaders.diseaseAndTreatment,
        unserialized: Cl550UnserializedHeaders.diseaseAndTreatment,
        view: IntlKeys.referenceTablesCl550DiseaseAndTreatment,
      },
      [Cl550SerializedHeaders.failure]: {
        original: Cl550OriginalHeaders.failure,
        serialized: Cl550SerializedHeaders.failure,
        unserialized: Cl550UnserializedHeaders.failure,
        view: IntlKeys.referenceTablesCl550Failure,
      },
      [Cl550SerializedHeaders.disposal]: {
        original: Cl550OriginalHeaders.disposal,
        serialized: Cl550SerializedHeaders.disposal,
        unserialized: Cl550UnserializedHeaders.disposal,
        view: IntlKeys.referenceTablesCl550Disposal,
      },
      [Cl550SerializedHeaders.selection]: {
        original: Cl550OriginalHeaders.selection,
        serialized: Cl550SerializedHeaders.selection,
        unserialized: Cl550UnserializedHeaders.selection,
        view: IntlKeys.referenceTablesCl550Selection,
      },
      [Cl550SerializedHeaders.labResult]: {
        original: Cl550OriginalHeaders.labResult,
        serialized: Cl550SerializedHeaders.labResult,
        unserialized: Cl550UnserializedHeaders.labResult,
        view: IntlKeys.referenceTablesCl550LabResult,
      },
      [Cl550SerializedHeaders.section]: {
        original: Cl550OriginalHeaders.section,
        serialized: Cl550SerializedHeaders.section,
        unserialized: Cl550UnserializedHeaders.section,
        view: IntlKeys.referenceTablesCl550Section,
      },
      [Cl550SerializedHeaders.slaughterhouseData]: {
        original: Cl550OriginalHeaders.slaughterhouseData,
        serialized: Cl550SerializedHeaders.slaughterhouseData,
        unserialized: Cl550UnserializedHeaders.slaughterhouseData,
        view: IntlKeys.referenceTablesCl550SlaughterhouseData,
      },
      [Cl550SerializedHeaders.agility]: {
        original: Cl550OriginalHeaders.agility,
        serialized: Cl550SerializedHeaders.agility,
        unserialized: Cl550UnserializedHeaders.agility,
        view: IntlKeys.referenceTablesCl550Agility,
      },
      [Cl550SerializedHeaders.coverage]: {
        original: Cl550OriginalHeaders.coverage,
        serialized: Cl550SerializedHeaders.coverage,
        unserialized: Cl550UnserializedHeaders.coverage,
        view: IntlKeys.referenceTablesCl550Coverage,
      },
      [Cl550SerializedHeaders.birth]: {
        original: Cl550OriginalHeaders.birth,
        serialized: Cl550SerializedHeaders.birth,
        unserialized: Cl550UnserializedHeaders.birth,
        view: IntlKeys.referenceTablesCl550Birth,
      },
      [Cl550SerializedHeaders.suckingPiglet]: {
        original: Cl550OriginalHeaders.suckingPiglet,
        serialized: Cl550SerializedHeaders.suckingPiglet,
        unserialized: Cl550UnserializedHeaders.suckingPiglet,
        view: IntlKeys.referenceTablesCl550SuckingPiglet,
      },
      [Cl550SerializedHeaders.weanedBig]: {
        original: Cl550OriginalHeaders.weanedBig,
        serialized: Cl550SerializedHeaders.weanedBig,
        unserialized: Cl550UnserializedHeaders.weanedBig,
        view: IntlKeys.referenceTablesCl550WeanedBig,
      },
      [Cl550SerializedHeaders.rearingSowAndBoar]: {
        original: Cl550OriginalHeaders.rearingSowAndBoar,
        serialized: Cl550SerializedHeaders.rearingSowAndBoar,
        unserialized: Cl550UnserializedHeaders.rearingSowAndBoar,
        view: IntlKeys.referenceTablesCl550RearingSowAndBoar,
      },
      [Cl550SerializedHeaders.goodSow]: {
        original: Cl550OriginalHeaders.goodSow,
        serialized: Cl550SerializedHeaders.goodSow,
        unserialized: Cl550UnserializedHeaders.goodSow,
        view: IntlKeys.referenceTablesCl550GoodSow,
      },
      [Cl550SerializedHeaders.gestationSow]: {
        original: Cl550OriginalHeaders.gestationSow,
        serialized: Cl550SerializedHeaders.gestationSow,
        unserialized: Cl550UnserializedHeaders.gestationSow,
        view: IntlKeys.referenceTablesCl550GestationSow,
      },
      [Cl550SerializedHeaders.lactatingSow]: {
        original: Cl550OriginalHeaders.lactatingSow,
        serialized: Cl550SerializedHeaders.lactatingSow,
        unserialized: Cl550UnserializedHeaders.lactatingSow,
        view: IntlKeys.referenceTablesCl550LactatingSow,
      },
      [Cl550SerializedHeaders.beer]: {
        original: Cl550OriginalHeaders.beer,
        serialized: Cl550SerializedHeaders.beer,
        unserialized: Cl550UnserializedHeaders.beer,
        view: IntlKeys.referenceTablesCl550Beer,
      },
      [Cl550SerializedHeaders.finisher]: {
        original: Cl550OriginalHeaders.finisher,
        serialized: Cl550SerializedHeaders.finisher,
        unserialized: Cl550UnserializedHeaders.finisher,
        view: IntlKeys.referenceTablesCl550Finisher,
      },
      [Cl550SerializedHeaders.comment]: {
        original: Cl550OriginalHeaders.comment,
        serialized: Cl550SerializedHeaders.comment,
        unserialized: Cl550UnserializedHeaders.comment,
        view: IntlKeys.referenceTablesCl550Comment,
      },
      [Cl550SerializedHeaders.added]: {
        original: Cl550OriginalHeaders.added,
        serialized: Cl550SerializedHeaders.added,
        unserialized: Cl550UnserializedHeaders.added,
        view: IntlKeys.referenceTablesCl550Added,
      },
      [Cl550SerializedHeaders.deleted]: {
        original: Cl550OriginalHeaders.deleted,
        serialized: Cl550SerializedHeaders.deleted,
        unserialized: Cl550UnserializedHeaders.deleted,
        view: IntlKeys.referenceTablesCl550Deleted,
      },
    },
  },
};

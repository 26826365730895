import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { GWP_100_FACTORS } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type Gwp100FactorsPayload = {
  id: number;
  greenhouse_gas: string | null;
  gwp100_factor: number | null;
  gwp100_factor_unit: string | null;
  gwp100_factor_upload: ManageUploadPayload;
};

export type Gwp100FactorsSerialized = {
  id: number;
  referenceUploadID: number;
  greenhouseGas: string;
  gwp100Factor: number;
  gwp100FactorUnit: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = GWP_100_FACTORS.Gwp100Factors;

const getDefaultSerializer = (data: DocumentData<Gwp100FactorsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.greenhouseGas.unserialized, columns.greenhouseGas.serialized),
      new CellSerializer(columns.gwp100Factor.unserialized, columns.gwp100Factor.serialized),
      new CellSerializer(columns.gwp100FactorUnit.unserialized, columns.gwp100FactorUnit.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeGwp100Factors = (serverData: ResourcesWrapper<Gwp100FactorsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermissionSelector } from 'store/auth/reducer';
import { uiAvailableMenuItemsSelector } from 'store/reducers/uiReducer';
import { PERMISSIONS } from 'store/auth/permissions';

import { createStyles, Theme, List, Divider, makeStyles } from '@material-ui/core';

import ProfileInfo from './ProfileInfo';
import NavSection from './navSection';
import SideBar, { SideBarProps } from 'components/SideBar';
import SolutionBySIM from 'components/SolutionBySim';

import { constant, menuItems } from './menuItems';

const SideNavComponent = () => {
  const classes = useStyles();

  const checkPermission = useSelector(userHasPermissionSelector);
  const availableMenuItems = useSelector(uiAvailableMenuItemsSelector);

  const checkItemVisibility = useCallback(
    (restrictions?: Partial<Record<PERMISSIONS, boolean>>, hideable?: boolean, title?: string) => {
      if (hideable && (Object.keys(availableMenuItems).length === 0 || availableMenuItems[title!] === 0 || availableMenuItems[title!] === false)) {
        return false;
      }

      return (
        restrictions === undefined ||
        Object.entries(restrictions).every(
          ([permission, isAllowedValue]) => checkPermission(permission as PERMISSIONS) === isAllowedValue,
        )
      );
    },
    [checkPermission, availableMenuItems],
  );

  return (
    <div className={classes.drawerContent}>
      <div className={classes.wrapper}>
        <ProfileInfo />

        <Divider className={classes.divider} variant="inset" component="div" />

        <NavSection items={constant.items} checkItemVisibility={checkItemVisibility} />
      </div>

      <List className={classes.root} subheader={<li />}>
        {menuItems
          .filter(
            ({ items }) =>
              !items.every(({ restrictions, hideable, title }) => !checkItemVisibility(restrictions, hideable, title)),
          )
          .map(
            ({ name, items, key, restrictions, hideable }) =>
              checkItemVisibility(restrictions, hideable, name) && (
                <li key={`section-${name || key}`} className={classes.listSection}>
                  <NavSection name={name} items={items} checkItemVisibility={checkItemVisibility} />
                </li>
              ),
          )}
      </List>

      <div className={classes.wrapper} style={{ marginTop: 'auto' }}>
        <SolutionBySIM />
      </div>
    </div>
  );
};

export const SideNavMenu: React.FC<SideBarProps> = ({ isOpen, setIsOpen }) => (
  <SideBar isOpen={isOpen} setIsOpen={setIsOpen}>
    <SideNavComponent />
  </SideBar>
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: '100%',
    },
    wrapper: {
      display: 'block',
      marginBottom: 8,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
    divider: {
      marginLeft: 0,
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'hidden',
    },
  }),
);

export default React.memo(SideNavMenu);

import { ServiceKeys } from 'lib/localization/keys/service';
import { IntlKeys } from 'lib/localization/keys';

export enum SerializedServiceFields {
  CREATED_AT = 'createdAt',
  UPDATED_BY = 'updatedBy',
  UPDATED_AT = 'updatedAt',
  UPLOADED_AT = 'uploadedAt',
  ID = 'id',
  BLOCKCHAIN_HASH_KEY = 'blockchainHashKey',
  BLOCKCHAIN_TIMESTAMP = 'blockchainTimestamp',
  BLOCKCHAIN_ID = 'blockchainId',
  TABLE_MENU = 'table-menu',
  CHECKBOX = 'checkbox',
}

enum UnserializedServiceFields {
  CREATED_AT = 'created_at',
  UPDATED_BY = 'updated_by',
  UPDATED_AT = 'updated_at',
  UPLOADED_AT = 'uploaded_at',
  ID = 'id',
  ACTIVE_BLOCKCHAIN_RECORD = 'active_blockchain_record',
  BLOCKCHAIN_HASH_KEY = 'hashed_data',
  BLOCKCHAIN_TIMESTAMP = 'stored_in_blockchain_at',
  BLOCKCHAIN_ID = 'blockchain_id',
}

export const SERVICE_FIELDS = {
  [SerializedServiceFields.CREATED_AT]: {
    unserialized: UnserializedServiceFields.CREATED_AT,
    serialized: SerializedServiceFields.CREATED_AT,
    view: IntlKeys.serviceCreatedAt,
  },
  [SerializedServiceFields.UPDATED_AT]: {
    unserialized: UnserializedServiceFields.UPDATED_AT,
    serialized: SerializedServiceFields.UPDATED_AT,
    view: IntlKeys.serviceLastUpdateAt,
  },
  [SerializedServiceFields.UPDATED_BY]: {
    unserialized: UnserializedServiceFields.UPDATED_BY,
    serialized: SerializedServiceFields.UPDATED_BY,
    view: IntlKeys.serviceUpdatedAt,
  },
  [SerializedServiceFields.ID]: {
    unserialized: UnserializedServiceFields.ID,
    serialized: SerializedServiceFields.ID,
    view: ServiceKeys.id,
  },
  [SerializedServiceFields.UPLOADED_AT]: {
    unserialized: UnserializedServiceFields.UPLOADED_AT,
    serialized: SerializedServiceFields.UPLOADED_AT,
    view: IntlKeys.uploadedAt,
  },
  [SerializedServiceFields.BLOCKCHAIN_HASH_KEY]: {
    unserialized: [UnserializedServiceFields.ACTIVE_BLOCKCHAIN_RECORD, UnserializedServiceFields.BLOCKCHAIN_HASH_KEY],
    serialized: SerializedServiceFields.BLOCKCHAIN_HASH_KEY,
    view: IntlKeys.serviceBlockchainHashKey,
  },
  [SerializedServiceFields.BLOCKCHAIN_TIMESTAMP]: {
    unserialized: [UnserializedServiceFields.ACTIVE_BLOCKCHAIN_RECORD, UnserializedServiceFields.BLOCKCHAIN_TIMESTAMP],
    serialized: SerializedServiceFields.BLOCKCHAIN_TIMESTAMP,
    view: IntlKeys.serviceBlockchainTimestamp,
  },
  [SerializedServiceFields.BLOCKCHAIN_ID]: {
    unserialized: [UnserializedServiceFields.ACTIVE_BLOCKCHAIN_RECORD, UnserializedServiceFields.BLOCKCHAIN_ID],
    serialized: SerializedServiceFields.BLOCKCHAIN_ID,
    view: ServiceKeys.blockchainId,
  },
  [SerializedServiceFields.TABLE_MENU]: {
    serialized: SerializedServiceFields.TABLE_MENU,
  },
  [SerializedServiceFields.CHECKBOX]: {
    serialized: SerializedServiceFields.CHECKBOX,
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import rowContextMenu from './rowContextMenu';

import { CODES_ANIMAL_IDENTITY_TYPE } from 'store/entities/farmManagementData/codesAnimalIdentityType/config';

const { columns, view, serialized } = CODES_ANIMAL_IDENTITY_TYPE.CodesAnimalIdentityType;

export const codesAnimalIdentityTypeTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.idForTypeIdentifier.view,
          field: columns.idForTypeIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.note.view,
          field: columns.note.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.uniqueIdentifier.view,
          field: columns.uniqueIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.transponder.view,
          field: columns.transponder.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.animalIdentifierTypeDatetime.view,
          field: columns.animalIdentifierTypeDatetime.serialized,
        },
      }),
    ],
  }),
]);

import { IntlKeys } from 'lib/localization/keys';

enum PensLevelSerialized {
  PensLevel = 'PensLevel',
}

enum PensLevelUnserialized {
  PensLevel = 'PensLevel',
}

export enum PensLevelSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  penLevel = 'penLevel',
  penNameNL = 'penNameNL',
  penNameEN = 'penNameEN',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const PensLevelUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['pen_level_upload', 'id'],
  penLevel: 'pen_level',
  penNameNL: 'pen_name_nl',
  penNameEN: 'pen_name_en',
  uploadType: ['pen_level_upload', 'upload_type'],
  filename: ['pen_level_upload', 'file_name'],
  status: ['pen_level_upload', 'status'],
  validDateFrom: ['pen_level_upload', 'valid_from'],
  validDateTo: ['pen_level_upload', 'valid_to'],
  notes: ['pen_level_upload', 'notes'],
  uploadedAt: ['pen_level_upload', 'uploaded_at'],
  uploadedBy: ['pen_level_upload', 'uploaded_by', 'full_name'],
} as const;

export const PENS_LEVEL = {
  [PensLevelSerialized.PensLevel]: {
    serialized: PensLevelSerialized.PensLevel,
    unserialized: PensLevelUnserialized.PensLevel,
    view: IntlKeys.referenceTablesAsPensLevel,

    columns: {
      [PensLevelSerializedHeaders.id]: {
        serialized: PensLevelSerializedHeaders.id,
        unserialized: PensLevelUnserializedHeaders.id,
      },
      [PensLevelSerializedHeaders.referenceUploadID]: {
        serialized: PensLevelSerializedHeaders.referenceUploadID,
        unserialized: PensLevelUnserializedHeaders.referenceUploadID,
      },
      [PensLevelSerializedHeaders.penLevel]: {
        serialized: PensLevelSerializedHeaders.penLevel,
        unserialized: PensLevelUnserializedHeaders.penLevel,
        view: IntlKeys.referenceTablesPensLevel,
      },
      [PensLevelSerializedHeaders.penNameNL]: {
        serialized: PensLevelSerializedHeaders.penNameNL,
        unserialized: PensLevelUnserializedHeaders.penNameNL,
        view: IntlKeys.referenceTablesPensName,
      },
      [PensLevelSerializedHeaders.penNameEN]: {
        serialized: PensLevelSerializedHeaders.penNameEN,
        unserialized: PensLevelUnserializedHeaders.penNameEN,
        view: IntlKeys.referenceTablesPensNameEN,
      },
      [PensLevelSerializedHeaders.status]: {
        serialized: PensLevelSerializedHeaders.status,
        unserialized: PensLevelUnserializedHeaders.status,
      },
      [PensLevelSerializedHeaders.uploadType]: {
        serialized: PensLevelSerializedHeaders.uploadType,
        unserialized: PensLevelUnserializedHeaders.uploadType,
      },
      [PensLevelSerializedHeaders.notes]: {
        serialized: PensLevelSerializedHeaders.notes,
        unserialized: PensLevelUnserializedHeaders.notes,
      },
      [PensLevelSerializedHeaders.filename]: {
        serialized: PensLevelSerializedHeaders.filename,
        unserialized: PensLevelUnserializedHeaders.filename,
      },
      [PensLevelSerializedHeaders.validDateFrom]: {
        serialized: PensLevelSerializedHeaders.validDateFrom,
        unserialized: PensLevelUnserializedHeaders.validDateFrom,
      },
      [PensLevelSerializedHeaders.validDateTo]: {
        serialized: PensLevelSerializedHeaders.validDateTo,
        unserialized: PensLevelUnserializedHeaders.validDateTo,
      },
      [PensLevelSerializedHeaders.uploadedAt]: {
        serialized: PensLevelSerializedHeaders.uploadedAt,
        unserialized: PensLevelUnserializedHeaders.uploadedAt,
      },
      [PensLevelSerializedHeaders.uploadedBy]: {
        serialized: PensLevelSerializedHeaders.uploadedBy,
        unserialized: PensLevelUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { GAS_CONSUMPTION_RATES } from 'store/entities/referenceTables/gasConsumptionRates/config';

const { columns, view, serialized } = GAS_CONSUMPTION_RATES.GasConsumptionRates;

export const gasConsumptionRatesTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.houseType.view,
          field: columns.houseType.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.gasConsumption.view,
          field: columns.gasConsumption.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.gasConsumption.serialized] && roundToInteger(data[columns.gasConsumption.serialized]),
        },
      }),
    ],
  }),
]);

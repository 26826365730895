import { IntlKeys } from 'lib/localization/keys/__keys';

enum RavReferenceListOriginal {
  RavReferenceList = 'RAV_Referentielijst',
}

enum RavReferenceListSerialized {
  RavReferenceList = 'RavReferenceList',
}

enum RavReferenceListUnserialized {
  RavReferenceList = 'RavReferenceList',
}

enum RavReferenceListOriginalHeaders {
  'animalCategory' = 'Animal category',
  'description' = 'Description',
  'efEm' = 'EF_em',
  'housingType' = 'Housing type',
}

enum RavReferenceListSerializedHeaders {
  'animalCategory' = 'animalCategory',
  'description' = 'description',
  'efEm' = 'efEm',
  'housingType' = 'housingType',
}
enum RavReferenceListUnserializedHeaders {
  'animalCategory' = 'animal_category',
  'description' = 'description',
  'efEm' = 'ef_em"',
  'housingType' = 'housing_type',
}

export const RAV_REFERENCE_LIST_FIELDS = {
  [RavReferenceListUnserialized.RavReferenceList]: {
    original: RavReferenceListOriginal.RavReferenceList,
    serialized: RavReferenceListSerialized.RavReferenceList,
    unserialized: RavReferenceListUnserialized.RavReferenceList,
    view: IntlKeys.referenceTablesRavReferenceList,
    viewName: 'RAV_Referentielijst',

    columns: {
      [RavReferenceListSerializedHeaders.animalCategory]: {
        original: RavReferenceListOriginalHeaders.animalCategory,
        serialized: RavReferenceListSerializedHeaders.animalCategory,
        unserialized: RavReferenceListUnserializedHeaders.animalCategory,
        view: IntlKeys.referenceTablesRavReferenceListAnimalCategory,
      },
      [RavReferenceListSerializedHeaders.description]: {
        original: RavReferenceListOriginalHeaders.description,
        serialized: RavReferenceListSerializedHeaders.description,
        unserialized: RavReferenceListUnserializedHeaders.description,
        view: IntlKeys.referenceTablesRavReferenceListDescription,
      },
      [RavReferenceListSerializedHeaders.efEm]: {
        original: RavReferenceListOriginalHeaders.efEm,
        serialized: RavReferenceListSerializedHeaders.efEm,
        unserialized: RavReferenceListUnserializedHeaders.efEm,
        view: IntlKeys.referenceTablesRavReferenceListEfem,
      },
      [RavReferenceListSerializedHeaders.housingType]: {
        original: RavReferenceListOriginalHeaders.housingType,
        serialized: RavReferenceListSerializedHeaders.housingType,
        unserialized: RavReferenceListUnserializedHeaders.housingType,
        view: IntlKeys.referenceTablesRavReferenceListHousingType,
      },
    },
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import rowContextMenu from './rowContextMenu';

import { CODES_REMOVAL_TYPE } from 'store/entities/farmManagementData/codesRemovalType/config';

const { columns, view, serialized } = CODES_REMOVAL_TYPE.CodesRemovalType;

export const codesRemovalTypeTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.removalTypeId.view,
          field: columns.removalTypeId.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.note.view,
          field: columns.note.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.removalTypeDatetime.view,
          field: columns.removalTypeDatetime.serialized,
        },
      }),
    ],
  }),
]);

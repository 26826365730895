import { Callbacks } from 'lib/ensure';

export class Deferred<T = any> {
  private _resolve: (value: T) => void = () => undefined;
  private _reject: (value: T) => void = () => undefined;

  private _promise: Promise<T> = new Promise<T>((resolve, reject) => {
    this._reject = reject;
    this._resolve = resolve;
  });

  initializeNewPromise() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._reject = reject;
      this._resolve = resolve;
    });
  }

  public get promise(): Promise<T> {
    return this._promise;
  }

  public resolve(value: T) {
    this._resolve(value);
  }

  public reject(value: T) {
    this._reject(value);
  }
}

export interface AnyPayload {
  [extraProps: string]: any;
}

type Payload = AnyPayload;

export type CustomAction<P = Payload> = {
  type: string;
  payload: P;
  actionCreator: AsyncActionCreator<P>;
  promise: Promise<any>;
};

const makeActionDefault = <P = Payload>(payload?: P): AnyPayload => ({ payload });

export const POSTFIX = {
  request: '/REQUEST',
  success: '/SUCCESS',
  failure: '/FAILURE',
};

type DeferredProps = {
  deferred: Deferred;
};

type SyncActionCreatorType<P = any> = (payload?: P) => CustomAction<P>;
type AsyncActionCreatorType<P = any> = (payload?: NonNullable<P> & Callbacks) => CustomAction<P>;
type SyncPostfixType = { type: string };
type AsyncPostfixType = typeof POSTFIX;

export type SyncActionCreator<P = any> = SyncActionCreatorType<P> & SyncPostfixType & DeferredProps;
export type AsyncActionCreator<P = any> = AsyncActionCreatorType<P> & AsyncPostfixType & DeferredProps;

export const asyncAction = <P = Payload>(type: string, makeAction = makeActionDefault): AsyncActionCreator<P> => {
  const deferred = new Deferred();
  const actionCreator = function actionCreator(payload?: P) {
    deferred.initializeNewPromise();

    const action = {
      ...makeAction(payload),
      type: `${type}${POSTFIX.request}`,
    };

    Object.defineProperty(action, 'actionCreator', {
      value: actionCreator,
      enumerable: false,
    });

    Object.defineProperty(action, 'promise', {
      value: deferred.promise,
      enumerable: false,
    });

    return action as CustomAction<P>;
  };

  actionCreator.request = `${type}${POSTFIX.request}`;
  actionCreator.success = `${type}${POSTFIX.success}`;
  actionCreator.failure = `${type}${POSTFIX.failure}`;
  actionCreator.deferred = deferred;

  return actionCreator;
};

export const syncAction = <P = Payload>(type: string, makeAction = makeActionDefault): SyncActionCreator<P> => {
  const deferred = new Deferred();
  const actionCreator = function actionCreator(payload?: P) {
    deferred.initializeNewPromise();

    const action = {
      ...makeAction(payload),
      type,
    };

    Object.defineProperty(action, 'actionCreator', {
      value: actionCreator,
      enumerable: false,
    });

    Object.defineProperty(action, 'promise', {
      value: deferred.promise,
      enumerable: false,
    });

    return action as CustomAction<P>;
  };

  actionCreator.type = type;
  actionCreator.deferred = deferred;

  return actionCreator;
};

import React from 'react';

import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { EnvVars } from 'lib/env/Env';

import {
  Dialog,
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles,
} from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import { useHashKeyRelevance } from 'components/HashKeyRelevance/utils';

interface OwnProps {
  data: ObjectWithProps;
  isOpen: boolean;
  handleClose: () => void;
}

const HashKeyRelevance: React.FC<OwnProps> = ({ data, isOpen, handleClose }) => {
  const classes = useStyles();

  const { blockchainHashKey, id, blockchainId } = data;

  const { actual, calculated, onClick } = useHashKeyRelevance(blockchainHashKey, id, blockchainId);

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(calculated.serialized!);
    } catch (e) {
      console.error('Failed to copy');
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
        <DialogTitle className={classes.dialogTitle}>Blockchain key check</DialogTitle>

        <DialogContentText className={classes.dialogContentText}>
          Compare blockchain partial hashkey and calculate it online for current row
        </DialogContentText>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            <span className={classes.blockchainType} style={{ transform: 'translate(-50%, 12px)' }}>
              Blockchain hash key:
            </span>
          </DialogContentText>

          <div className={classes.blockchainHashKeyWrapper}>
            <DialogContentText className={classes.dialogContentText}>
              <span className={classes.hashKey}>{blockchainHashKey}</span>
            </DialogContentText>
          </div>

          <DialogContentText className={classes.dialogContentText}>
            <span className={classes.blockchainType} style={{ transform: 'translate(-50%, 0px)' }}>
              Calculated hash key:
            </span>
          </DialogContentText>

          {calculated.isLoading ? (
            <>
              <div style={{ height: 60 }} />

              <div className={classes.loaderWrapper} style={{ transform: 'translate(-50%, -33px)' }}>
                <CircularProgress />
              </div>
            </>
          ) : calculated.hash ? (
            <div className={classes.blockchainHashKeyWrapper}>
              <TextField
                type="text"
                label="Data string"
                value={calculated.serialized}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="copy serialized to clipboard"
                        onClick={onCopy}
                        onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                      >
                        <FileCopyRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classes.textField}
              />
              <DialogContentText className={classes.dialogContentText} style={{ paddingTop: '72px !important' }}>
                <span
                  className={classes.hashKey}
                  style={{
                    color: `${calculated.hash === blockchainHashKey ? '#398249' : '#ea3223'}`,
                    backgroundColor: `${
                      calculated.hash === blockchainHashKey ? 'rgba(57, 130, 73, 0.27)' : 'rgb(234, 50, 35, 0.27)'
                    }`,
                  }}
                >
                  {calculated.hash}
                </span>
              </DialogContentText>
            </div>
          ) : (
            <>
              <span className={classes.hashKeyError}>{calculated.error}</span>
            </>
          )}

          <DialogContentText className={classes.dialogContentText}>
            <span
              className={classes.blockchainType}
              style={{ transform: `translate(-50%, ${!calculated.hash ? 12 : 0}px)` }}
            >
              Actual hash key:
            </span>
          </DialogContentText>

          {actual.isLoading ? (
            <>
              <div style={{ height: 72 }} />

              <div className={classes.loaderWrapper} style={{ transform: 'translate(-50%, -40px)' }}>
                <CircularProgress />
              </div>
            </>
          ) : actual.hash ? (
            <div className={classes.blockchainHashKeyWrapper}>
              <DialogContentText className={classes.dialogContentText}>
                <span
                  className={classes.hashKey}
                  style={{
                    color: `${actual.hash === blockchainHashKey ? '#398249' : '#ea3223'}`,
                    backgroundColor: `${
                      actual.hash === blockchainHashKey ? 'rgba(57, 130, 73, 0.27)' : 'rgb(234, 50, 35, 0.27)'
                    }`,
                  }}
                >
                  {actual.hash}
                </span>
              </DialogContentText>
            </div>
          ) : (
            <>
              <span className={classes.hashKeyError}>{actual.error}</span>
            </>
          )}
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          {actual.hash &&
            calculated.hash &&
            (actual.hash === blockchainHashKey && calculated.hash === blockchainHashKey ? (
              <>
                <DoneIcon className={classes.relevantIcon} />
                <span className={classes.hashRelevanceMessage}>Hashes are relevant</span>
              </>
            ) : (
              <>
                <ClearIcon className={classes.notRelevantIcon} />
                <span className={classes.hashRelevanceMessage}>Hashes are not relevant</span>
              </>
            ))}

          {!process.env[EnvVars.HIDE_PUB_API_NODE_1] &&
            <Button
              onClick={() => onClick(EnvVars.PUB_API_NODE_1)}
              color="primary"
              autoFocus
              variant="contained"
              disabled={actual.isLoading || calculated.isLoading}
              className={classes.button}
            >
              Calculate 1
            </Button>
          }

          {!process.env[EnvVars.HIDE_PUB_API_NODE_2] &&
            <Button
              onClick={() => onClick(EnvVars.PUB_API_NODE_2)}
              color="primary"
              autoFocus
              variant="contained"
              disabled={actual.isLoading || calculated.isLoading}
              className={classes.button}
            >
              Calculate 2
            </Button>
          }

          {!process.env[EnvVars.HIDE_PUB_API_NODE_3] &&
            <Button
              onClick={() => onClick(EnvVars.PUB_API_NODE_3)}
              color="primary"
              autoFocus
              variant="contained"
              disabled={actual.isLoading || calculated.isLoading}
              className={classes.button}
            >
              Calculate
            </Button>
          }

          <Button onClick={handleClose} color="primary" variant="text" className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  blockchainHashKeyWrapper: {
  },
  blockchainType: {
    whiteSpace: 'nowrap',
    left: '50%',
  },
  button: {
    minWidth: '200px !important',
    margin: '0 0 10px !important',
  },
  dialogActions: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogContent: {
    minHeight: 'max-content',
  },
  dialogContentText: {
    textAlign: 'center',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  hashKey: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(33, 33, 33, 0.08)',
    width: '100%',
    margin: '0 auto',
    borderRadius: 4,
    padding: '10px 35px',
    marginRight: 24,
  },
  hashKeyError: {
    color: '#ea3223',
    width: '100%',
    textAlign: 'center',
    display: 'block'
  },
  hashRelevanceMessage: {
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 20,
    marginBottom: 20,
  },
  loaderWrapper: {
    position: 'absolute',
    left: '50%',
  },
  notRelevantIcon: {
    height: '30px !important',
    width: '30px !important',
    color: '#ea3223',
  },
  relevantIcon: {
    height: '30px !important',
    width: '30px !important',
    color: '#99c31c',
  },
  textField: {
    width: '100%',
    marginBottom: 20,
  },
}));

export default React.memo(HashKeyRelevance);

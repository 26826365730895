import { IntlKeys } from 'lib/localization/keys';

enum Cl650Serialized {
  Cl650 = 'Cl650',
}

enum Cl650Unserialized {
  Cl650 = 'Cl650',
}

export enum Cl650SerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  code = 'code',
  description = 'description',
  note = 'note',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const Cl650UnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['group_charge_or_premium_code_upload', 'id'],
  code: 'code',
  description: 'description',
  note: 'notes',
  uploadType: ['group_charge_or_premium_code_upload', 'upload_type'],
  filename: ['group_charge_or_premium_code_upload', 'file_name'],
  status: ['group_charge_or_premium_code_upload', 'status'],
  validDateFrom: ['group_charge_or_premium_code_upload', 'valid_from'],
  validDateTo: ['group_charge_or_premium_code_upload', 'valid_to'],
  notes: ['group_charge_or_premium_code_upload', 'notes'],
  uploadedAt: ['group_charge_or_premium_code_upload', 'uploaded_at'],
  uploadedBy: ['group_charge_or_premium_code_upload', 'uploaded_by', 'full_name'],
} as const;

export const CL650 = {
  [Cl650Serialized.Cl650]: {
    serialized: Cl650Serialized.Cl650,
    unserialized: Cl650Unserialized.Cl650,
    view: IntlKeys.referenceTablesCl650,

    columns: {
      [Cl650SerializedHeaders.id]: {
        serialized: Cl650SerializedHeaders.id,
        unserialized: Cl650UnserializedHeaders.id,
      },
      [Cl650SerializedHeaders.referenceUploadID]: {
        serialized: Cl650SerializedHeaders.referenceUploadID,
        unserialized: Cl650UnserializedHeaders.referenceUploadID,
      },
      [Cl650SerializedHeaders.code]: {
        serialized: Cl650SerializedHeaders.code,
        unserialized: Cl650UnserializedHeaders.code,
        view: IntlKeys.referenceTablesCl650Code,
      },
      [Cl650SerializedHeaders.description]: {
        serialized: Cl650SerializedHeaders.description,
        unserialized: Cl650UnserializedHeaders.description,
        view: IntlKeys.referenceTablesCl650Description,
      },
      [Cl650SerializedHeaders.note]: {
        serialized: Cl650SerializedHeaders.note,
        unserialized: Cl650UnserializedHeaders.note,
        view: IntlKeys.referenceTablesCl650Note,
      },
      [Cl650SerializedHeaders.status]: {
        serialized: Cl650SerializedHeaders.status,
        unserialized: Cl650UnserializedHeaders.status,
      },
      [Cl650SerializedHeaders.uploadType]: {
        serialized: Cl650SerializedHeaders.uploadType,
        unserialized: Cl650UnserializedHeaders.uploadType,
      },
      [Cl650SerializedHeaders.notes]: {
        serialized: Cl650SerializedHeaders.notes,
        unserialized: Cl650UnserializedHeaders.notes,
      },
      [Cl650SerializedHeaders.filename]: {
        serialized: Cl650SerializedHeaders.filename,
        unserialized: Cl650UnserializedHeaders.filename,
      },
      [Cl650SerializedHeaders.validDateFrom]: {
        serialized: Cl650SerializedHeaders.validDateFrom,
        unserialized: Cl650UnserializedHeaders.validDateFrom,
      },
      [Cl650SerializedHeaders.validDateTo]: {
        serialized: Cl650SerializedHeaders.validDateTo,
        unserialized: Cl650UnserializedHeaders.validDateTo,
      },
      [Cl650SerializedHeaders.uploadedAt]: {
        serialized: Cl650SerializedHeaders.uploadedAt,
        unserialized: Cl650UnserializedHeaders.uploadedAt,
      },
      [Cl650SerializedHeaders.uploadedBy]: {
        serialized: Cl650SerializedHeaders.uploadedBy,
        unserialized: Cl650UnserializedHeaders.uploadedBy,
      },
    },
  },
};

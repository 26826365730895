import { GAS_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/gasConsumptionRates/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = GAS_CONSUMPTION_RATES_FIELDS.GasConsumptionRates;

export const getGasConsumptionRatesDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.houseType.serialized, extractPropName: columns.houseType.view }),
      new DisplayHeader({
        headerName: columns.gasConsumption.serialized,
        extractPropName: columns.gasConsumption.view,
      }),
    ]),
  ]);
};

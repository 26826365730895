import { put, select } from 'redux-saga/effects';

import { authMetaSelector, getUserAction } from 'store/auth/reducer';
import { getTableConfig } from '../../reducers/userSettingsReducer';
import { getSideMenuCounters } from 'store/reducers/uiReducer';

export default function* () {
  const { isAuthorized } = yield select(authMetaSelector);

  if (isAuthorized) {
    yield put(getUserAction());
    yield put(getTableConfig());
    yield put(getSideMenuCounters());
  }
}

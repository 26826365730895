import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IntlKeys } from 'lib/localization/keys';

import { AnimalPassportsSerializedEntry } from 'store/entities/animalPassports';

import DataContainer, { DataType } from '../components/DataContainer';
import GridContainer from '../components/GridContainer';

interface OwnProps {
  data: AnimalPassportsSerializedEntry['veterinarianData'];
}

const Medication: React.FC<OwnProps> = ({ data }) => {
  const rows = useMemo(() => {
    return Object.values(data).map(({ currentYearRecord, prevYearRecord }) => {
      const {
        //
        animalCategory,
        dddTm: lastDddTm,
        exportDate: lastExportDate,
      } = currentYearRecord || {};

      const {
        //
        dddTm: previousDddTm,
        exportDate: previousExportDate,
      } = prevYearRecord || {};

      return {
        animalCategory,

        isLastDataAvailable: Boolean(currentYearRecord),
        lastDddTm: lastDddTm ? lastDddTm : '-',
        lastExportDate: lastExportDate,

        isPreviousDataAvailable: Boolean(prevYearRecord),
        previousDddTm: previousDddTm ? previousDddTm : '-',
        previousExportDate,
      };
    });
  }, [data]);

  const { formatMessage } = useIntl();

  return (
    <div>
      {rows.map(
        ({
          animalCategory,
          isLastDataAvailable,
          lastDddTm,
          lastExportDate,
          isPreviousDataAvailable,
          previousDddTm,
          previousExportDate,
        }) => (
          <div key={animalCategory}>
            <DataContainer label={formatMessage({ id: IntlKeys.animalPassportAnimalCategory })} data={animalCategory} />
            <GridContainer>
              <div>
                {isLastDataAvailable && (
                  <DataContainer
                    label={formatMessage({ id: IntlKeys.animalPassportMedicationAnimalLastExportDate })}
                    data={lastExportDate}
                    dataType={DataType.Date}
                  />
                )}
                {isPreviousDataAvailable && (
                  <DataContainer
                    label={formatMessage({ id: IntlKeys.animalPassportMedicationAnimalPreviousExportDate })}
                    data={previousExportDate}
                    dataType={DataType.Date}
                  />
                )}
              </div>
              <div>
                {isLastDataAvailable && (
                  <DataContainer
                    label={formatMessage({ id: IntlKeys.animalPassportMedicationAnimalLastDddTm })}
                    data={lastDddTm}
                  />
                )}
                {isPreviousDataAvailable && (
                  <DataContainer
                    label={formatMessage({ id: IntlKeys.animalPassportMedicationAnimalPreviousDddTm })}
                    data={previousDddTm}
                  />
                )}
              </div>
            </GridContainer>
          </div>
        ),
      )}
    </div>
  );
};

export default React.memo(Medication);

import { IntlKeys } from 'lib/localization/keys/__keys';

enum WaterConsumptionRatesOriginal {
  WaterConsumptionRates = 'CFP_NIBUD_waterverbruik',
}

enum WaterConsumptionRatesSerialized {
  WaterConsumptionRates = 'WaterConsumptionRates',
}

enum WaterConsumptionRatesUnserialized {
  WaterConsumptionRates = 'WaterConsumptionRates',
}

enum WaterConsumptionRatesOriginalHeaders {
  'householdPeopleQuantity' = 'nrPeopleHousehold',
  'waterConsumption' = 'waterConsumption',
}

enum WaterConsumptionRatesSerializedHeaders {
  'householdPeopleQuantity' = 'householdPeopleQuantity',
  'waterConsumption' = 'waterConsumption',
}
enum WaterConsumptionRatesUnserializedHeaders {
  'householdPeopleQuantity' = 'household_people_quantity',
  'waterConsumption' = 'water_consumption',
}

export const WATER_CONSUMPTION_RATES_FIELDS = {
  [WaterConsumptionRatesUnserialized.WaterConsumptionRates]: {
    original: WaterConsumptionRatesOriginal.WaterConsumptionRates,
    serialized: WaterConsumptionRatesSerialized.WaterConsumptionRates,
    unserialized: WaterConsumptionRatesUnserialized.WaterConsumptionRates,
    view: IntlKeys.referenceTablesWaterConsumptionRates, //
    viewName: 'CFP_NIBUD_waterverbruik',

    columns: {
      [WaterConsumptionRatesSerializedHeaders.householdPeopleQuantity]: {
        original: WaterConsumptionRatesOriginalHeaders.householdPeopleQuantity,
        serialized: WaterConsumptionRatesSerializedHeaders.householdPeopleQuantity,
        unserialized: WaterConsumptionRatesUnserializedHeaders.householdPeopleQuantity,
        view: IntlKeys.referenceTablesWaterConsumptionRatesHousehold,
      },
      [WaterConsumptionRatesSerializedHeaders.waterConsumption]: {
        original: WaterConsumptionRatesOriginalHeaders.waterConsumption,
        serialized: WaterConsumptionRatesSerializedHeaders.waterConsumption,
        unserialized: WaterConsumptionRatesUnserializedHeaders.waterConsumption,
        view: IntlKeys.referenceTablesWaterConsumptionRatesConsumption,
      },
    },
  },
};

import { RouteComponentProps } from 'react-router';
import routes, { RouteType, ChildRouteType } from './index';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import RouteParser from 'route-parser';
import { flattenDeep } from 'lodash';

export function makeUrl(strings: TemplateStringsArray, ...values: string[]) {
  return (args: Record<string, string | number>) =>
    values.reduceRight((a, v, i) => `${strings[i]}${args[v]}${a}`, strings[strings.length - 1]);
}

export const combinePaths = (parent: string, child: string) =>
  `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;

export const buildPaths = (routes: RouteType[], parentPath = ''): RouteType[] =>
  routes.map((route) => {
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

export const setupParents = (routes: RouteType[], parentRoute?: RouteType): ChildRouteType[] =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

type NestedChildRoutes = (ChildRouteType | NestedChildRoutes)[];

export const flattenRoutes = (routes: ChildRouteType[]): ChildRouteType[] => {
  const _flattenRoutes = (_routes: ChildRouteType[]): NestedChildRoutes => {
    return _routes.map((route) => [route.routes ? _flattenRoutes(route.routes) : [], route]);
  };

  return flattenDeep(_flattenRoutes(routes));
};

export const generateAppRoutes = (routes: RouteType[]) => {
  return flattenRoutes(setupParents(buildPaths(routes)));
};

export const pathTo = (route: ChildRouteType): ChildRouteType[] => {
  if (!route.parent) {
    return [route];
  }

  return [...pathTo(route.parent), route];
};

/**
 *
 * @param key [optional] - key of desired route. if no key provided default route's url will be returned
 */
export const getUrl = (key?: string): string => {
  let desiredRoute;

  if (key) {
    desiredRoute = routes.find((route) => route.key === key);
  } else {
    desiredRoute = routes.find(({ isDefault }) => isDefault);
  }

  if (!desiredRoute) {
    throw new Error(`Could find route for key: '${key}'`);
  }

  return desiredRoute.path;
};

export const getUrlWithParams = (key: string, params: ObjectWithProps): string => {
  const rawUrl = getUrl(key);
  const url = rawUrl.replace(/(\/?:([\w\d]+))\?/, (match, paramPath, paramName) => {
    // in order to RouteParser could correctly generate url if there's optional params
    // omit ? int non required params
    // or omit non required param completely if corresponding param value is absent
    // "/:id?" -> "/:id" - if id is in params
    //            ""     - if id isn't in params

    return paramName && params.hasOwnProperty(paramName) ? paramPath : '';
  });

  const route = new RouteParser(url);
  const pathWithParams = route.reverse(params);

  if (pathWithParams) {
    return pathWithParams;
  } else {
    console.error(`Failed to apply params to route: ${key}, params : ${JSON.stringify(params)}`);
    return '/';
  }
};

export const generateDocTitle = ({
  prefix = 'VION SIM',
  docTitle,
  routerProps,
}: {
  prefix?: string;
  docTitle: RouteType['docTitle'];
  routerProps: RouteComponentProps;
}) => {
  const routeTitle = typeof docTitle === 'function' ? docTitle(routerProps) : docTitle;

  return `${prefix}${prefix && routeTitle ? ' - ' : ''}${routeTitle || ''}`;
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum CountryCodesOriginal {
  CountryCodes = 'ISO 3166 country codes',
}

enum CountryCodesSerialized {
  CountryCodes = 'CountryCodes',
}

enum CountryCodesUnserialized {
  CountryCodes = 'CountryCodes',
}

enum CountryCodesOriginalHeaders {
  'iso31661Code' = 'ISO_3166-1_code',
  'subdivisionName' = 'subdivision_name',
  'iso31662Code' = 'ISO_3166-2_code',
  'countryName' = 'country_name',
}

enum CountryCodesSerializedHeaders {
  'iso31661Code' = 'iso31661Code',
  'subdivisionName' = 'subdivisionName',
  'iso31662Code' = 'iso31662Code',
  'countryName' = 'countryName',
}
enum CountryCodesUnserializedHeaders {
  'iso31661Code' = 'iso_3166_1_code',
  'subdivisionName' = 'subdivision_name',
  'iso31662Code' = 'iso_3166_2_code',
  'countryName' = 'country_name',
}

export const COUNTRY_CODES_FIELDS = {
  [CountryCodesUnserialized.CountryCodes]: {
    original: CountryCodesOriginal.CountryCodes,
    serialized: CountryCodesSerialized.CountryCodes,
    unserialized: CountryCodesUnserialized.CountryCodes,
    view: IntlKeys.referenceTablesCountryCodes,
    viewName: 'ISO 3166 Country Codes',

    columns: {
      [CountryCodesSerializedHeaders.iso31661Code]: {
        original: CountryCodesOriginalHeaders.iso31661Code,
        serialized: CountryCodesSerializedHeaders.iso31661Code,
        unserialized: CountryCodesUnserializedHeaders.iso31661Code,
        view: IntlKeys.referenceTablesCountryCodesIso31661Code,
      },
      [CountryCodesSerializedHeaders.subdivisionName]: {
        original: CountryCodesOriginalHeaders.subdivisionName,
        serialized: CountryCodesSerializedHeaders.subdivisionName,
        unserialized: CountryCodesUnserializedHeaders.subdivisionName,
        view: IntlKeys.referenceTablesCountryCodesSubdivisionName,
      },
      [CountryCodesSerializedHeaders.iso31662Code]: {
        original: CountryCodesOriginalHeaders.iso31662Code,
        serialized: CountryCodesSerializedHeaders.iso31662Code,
        unserialized: CountryCodesUnserializedHeaders.iso31662Code,
        view: IntlKeys.referenceTablesCountryCodesIso31662Code,
      },
      [CountryCodesSerializedHeaders.countryName]: {
        original: CountryCodesOriginalHeaders.countryName,
        serialized: CountryCodesSerializedHeaders.countryName,
        unserialized: CountryCodesUnserializedHeaders.countryName,
        view: IntlKeys.referenceTablesCountryCodesCountryName,
      },
    },
  },
};

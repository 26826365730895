import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { ALLOCATION_MANURE } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type AllocationManurePayload = {
  id: number;
  animal_category: string | null;
  manure: number | null;
  gas: number | null;
  electricity: number | null;
  water: number | null;
  allocation_manure_upload: ManageUploadPayload;
};

export type AllocationManureSerialized = {
  id: number;
  referenceUploadID: number;
  animalCategory: string;
  manure: number;
  gas: number;
  electricity: number;
  water: number;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = ALLOCATION_MANURE.AllocationManure;

const getDefaultSerializer = (data: DocumentData<AllocationManurePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.animalCategory.unserialized, columns.animalCategory.serialized),
      new CellSerializer(columns.manure.unserialized, columns.manure.serialized),
      new CellSerializer(columns.gas.unserialized, columns.gas.serialized),
      new CellSerializer(columns.electricity.unserialized, columns.electricity.serialized),
      new CellSerializer(columns.water.unserialized, columns.water.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeAllocationManure = (serverData: ResourcesWrapper<AllocationManurePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

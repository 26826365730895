import { IntlKeys } from 'lib/localization/keys';

enum Cl649Serialized {
  Cl649 = 'Cl649',
}

enum Cl649Unserialized {
  Cl649 = 'Cl649',
}

export enum Cl649SerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  code = 'code',
  description = 'description',
  remarks = 'remarks',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const Cl649UnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['charge_or_premium_code_type_upload', 'id'],
  code: 'code',
  description: 'description',
  remarks: 'notes',
  uploadType: ['charge_or_premium_code_type_upload', 'upload_type'],
  filename: ['charge_or_premium_code_type_upload', 'file_name'],
  status: ['charge_or_premium_code_type_upload', 'status'],
  validDateFrom: ['charge_or_premium_code_type_upload', 'valid_from'],
  validDateTo: ['charge_or_premium_code_type_upload', 'valid_to'],
  notes: ['charge_or_premium_code_type_upload', 'notes'],
  uploadedAt: ['charge_or_premium_code_type_upload', 'uploaded_at'],
  uploadedBy: ['charge_or_premium_code_type_upload', 'uploaded_by', 'full_name'],
} as const;

export const CL649 = {
  [Cl649Serialized.Cl649]: {
    serialized: Cl649Serialized.Cl649,
    unserialized: Cl649Unserialized.Cl649,
    view: IntlKeys.referenceTablesCl649,

    columns: {
      [Cl649SerializedHeaders.id]: {
        serialized: Cl649SerializedHeaders.id,
        unserialized: Cl649UnserializedHeaders.id,
      },
      [Cl649SerializedHeaders.referenceUploadID]: {
        serialized: Cl649SerializedHeaders.referenceUploadID,
        unserialized: Cl649UnserializedHeaders.referenceUploadID,
      },
      [Cl649SerializedHeaders.code]: {
        serialized: Cl649SerializedHeaders.code,
        unserialized: Cl649UnserializedHeaders.code,
        view: IntlKeys.referenceTablesCl649Code,
      },
      [Cl649SerializedHeaders.description]: {
        serialized: Cl649SerializedHeaders.description,
        unserialized: Cl649UnserializedHeaders.description,
        view: IntlKeys.referenceTablesCl649Description,
      },
      [Cl649SerializedHeaders.remarks]: {
        serialized: Cl649SerializedHeaders.remarks,
        unserialized: Cl649UnserializedHeaders.remarks,
        view: IntlKeys.referenceTablesCl649Remarks,
      },
      [Cl649SerializedHeaders.status]: {
        serialized: Cl649SerializedHeaders.status,
        unserialized: Cl649UnserializedHeaders.status,
      },
      [Cl649SerializedHeaders.uploadType]: {
        serialized: Cl649SerializedHeaders.uploadType,
        unserialized: Cl649UnserializedHeaders.uploadType,
      },
      [Cl649SerializedHeaders.notes]: {
        serialized: Cl649SerializedHeaders.notes,
        unserialized: Cl649UnserializedHeaders.notes,
      },
      [Cl649SerializedHeaders.filename]: {
        serialized: Cl649SerializedHeaders.filename,
        unserialized: Cl649UnserializedHeaders.filename,
      },
      [Cl649SerializedHeaders.validDateFrom]: {
        serialized: Cl649SerializedHeaders.validDateFrom,
        unserialized: Cl649UnserializedHeaders.validDateFrom,
      },
      [Cl649SerializedHeaders.validDateTo]: {
        serialized: Cl649SerializedHeaders.validDateTo,
        unserialized: Cl649UnserializedHeaders.validDateTo,
      },
      [Cl649SerializedHeaders.uploadedAt]: {
        serialized: Cl649SerializedHeaders.uploadedAt,
        unserialized: Cl649UnserializedHeaders.uploadedAt,
      },
      [Cl649SerializedHeaders.uploadedBy]: {
        serialized: Cl649SerializedHeaders.uploadedBy,
        unserialized: Cl649UnserializedHeaders.uploadedBy,
      },
    },
  },
};

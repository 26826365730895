import { PENS_TYPE_SOW_BARN_FIELDS } from '../structure';
import { DocumentSerializer } from '../../../../serilizers/Document';
import { DocumentData, SheetSerializer } from '../../../../serilizers/Sheet';
import { CellSerializer } from '../../../../serilizers/Cell';

const { columns, serialized, original } = PENS_TYPE_SOW_BARN_FIELDS.PensTypeSowBarn;

export const serializePensTypeSowBarnData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.pensTypeSowBarnCode.original, columns.pensTypeSowBarnCode.serialized),
      new CellSerializer(columns.pensTypeSowBarnName.original, columns.pensTypeSowBarnName.serialized),
    ]),
  ]);
};

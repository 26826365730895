import { IntlKeys } from "../../localization/keys";

export const statusOptions = [
  { value: 'submitted', label: { intlId: IntlKeys.statusSubmitted } },
  { value: 'draft', label: { intlId: IntlKeys.statusDraft } },
];

export const uploadStatusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

export const uploadCategoryOptions = [
  { value: 'pen_level_upload', label: 'AS_PensLevel' },
  { value: 'pen_type_sow_barn_upload', label: 'AS_PensTypeSowBarn' },
  { value: 'answer_code_upload', label: 'AS_YesNo' },
  { value: 'co2_emission_factor_upload', label: 'CFP_CO2EmissionFactors' },
  { value: 'carbon_foot_print_upload', label: 'CFP_CVB_table' },
  { value: 'feed_print_upload', label: 'CFP_GFLI_Feedprint_table' },
  { value: 'ipcc_emission_factor_upload', label: 'CFP_IPCC_Emission_factors_indirect_soil' },
  { value: 'content_of_nitric_oxide_upload', label: 'CFP_NEMA_Emission_factors_for_other_gaseous_N-losses' },
  { value: 'pig_housing_emission_factor_upload', label: 'CFP_NEMA_NH3-emission_factors_for_pig_housing' },
  { value: 'electricity_consumption_rate_upload', label: 'CFP_NIBUD_electriciteitsverbruik' },
  { value: 'gas_consumption_rate_upload', label: 'CFP_NIBUD_gasverbruik' },
  { value: 'water_consumption_rate_upload', label: 'CFP_NIBUD_waterverbruik' },
  { value: 'rav_code_upload', label: 'CFP_RAV_codes' },
  { value: 'gwp100_factor_upload', label: 'CFP_WUR_GWP100factor' },
  { value: 'wur_mcf_efem_upload', label: 'CFP_WUR_MCF' },
  { value: 'pig_disease_code_upload', label: 'cl550' },
  { value: 'charge_or_premium_code_type_upload', label: 'cl649' },
  { value: 'group_charge_or_premium_code_upload', label: 'cl650' },
  { value: 'animal_id_code_type_upload', label: 'cl607' },
  { value: 'supplier_number_upload', label: 'cl420' },
  { value: 'country_code_upload', label: 'ISO 3166 country codes' },
  { value: 'rav_reference_list_upload', label: 'RAV_Referentielijst' },
  { value: 'allocation_manure_upload', label: 'Allocation Manure' },
  { value: 'greenhouse_gas_emission_upload', label: 'Greenhouse Gas Emission' },
  { value: 'entities_list_upload', label: 'Entities List' },
];

export const farmManagementCategoryOptions = [
  { value: 'farm_option_upload', label: 'Codes - Farm Options' },
  { value: 'code_barn_upload', label: 'Codes - Barn' },
  { value: 'code_room_upload', label: 'Codes - Room' },
  { value: 'code_pen_upload', label: 'Codes - Pen' },
  { value: 'animal_remark_upload', label: 'Codes - Animal Remarks' },
  { value: 'animal_score_upload', label: 'Codes - Animal Score' },
  { value: 'defect_upload', label: 'Codes - Defects' },
  { value: 'insemination_upload', label: 'Codes - Insemination' },
  { value: 'diagnose_upload', label: 'Codes - Diagnose' },
  { value: 'treatment_upload', label: 'Codes - Treatments' },
  { value: 'treatment_upload', label: 'Codes - Mating Type' },
  { value: 'weight_type_upload', label: 'Codes - Weight Type' },
  { value: 'removal_type_upload', label: 'Codes - Removal Type' },
  { value: 'animal_identifier_type_upload', label: 'Codes - Animal Identity Type' },
  { value: 'code_account_upload', label: 'Codes - Account' },
  { value: 'code_event_type_upload', label: 'Codes - Event Type' },
  { value: 'group_financial_event_upload', label: 'Group - Financial Events' },
  { value: 'farm_group_upload', label: 'Group - Groups' },
  { value: 'group_arrival_upload', label: 'Group - Arrival' },
  { value: 'group_move_upload', label: 'Group - Move' },
  { value: 'group_removal_upload', label: 'Group - Removal' },
  { value: 'group_weight_upload', label: 'Group - Weight' },
  { value: 'purchased_food_upload', label: 'Group - Food' },
  { value: 'used_food_upload', label: 'Group - Food Used' },
  { value: 'group_expense_upload', label: 'Group - Expense' },
];

export const errorLogTypeOptions = [
  { value: 'delivery_message', label: 'Delivery Message' },
  { value: 'invoice_message', label: 'Invoice Message' },
  { value: 'slaughter_message', label: 'Slaughter Message' },
];

import { ResourcesWrapper } from 'lib/http/utils';

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { VETERINARIAN_DATA } from 'lib/tables/config/veterinarianData/config';

type VeterinarianDataRecordPayload = {
  id: number;
  source: string;
  export_date: string;
  ubn: string;
  company_name: string;
  location: string;
  animal_category: string;
  ddd_tm: number;
  created_at: string;
  updated_at: string;
};

const { columns, serialized, unserialized } = VETERINARIAN_DATA.VeterinarianData;
const {
  exportDate,
  ubn,
  companyName,
  streetAddress,
  placeAddress,
  period,
  animalCategory,
  dddTm,
  deliveryDate,
  registrationDate,
  veterinarianName,
  ean,
  registrationNumberNl,
  choice,
  productName,
  packagesCount,
  capacity,
  total,
  dosingFactor,
  avgAnimalsNumber,
  weightPerAnimal,
  dddDaily,
} = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(exportDate.unserialized, exportDate.serialized),
      new CellSerializer(ubn.unserialized, ubn.serialized),
      new CellSerializer(companyName.unserialized, companyName.serialized),
      new CellSerializer(streetAddress.unserialized, streetAddress.serialized),
      new CellSerializer(placeAddress.unserialized, placeAddress.serialized),
      new CellSerializer(period.unserialized, period.serialized),
      new CellSerializer(animalCategory.unserialized, animalCategory.serialized),
      new CellSerializer(dddTm.unserialized, dddTm.serialized),
      new CellSerializer(deliveryDate.unserialized, deliveryDate.serialized),
      new CellSerializer(registrationDate.unserialized, registrationDate.serialized),
      new CellSerializer(veterinarianName.unserialized, veterinarianName.serialized),
      new CellSerializer(ean.unserialized, ean.serialized),
      new CellSerializer(registrationNumberNl.unserialized, registrationNumberNl.serialized),
      new CellSerializer(choice.unserialized, choice.serialized),
      new CellSerializer(productName.unserialized, productName.serialized),
      new CellSerializer(packagesCount.unserialized, packagesCount.serialized),
      new CellSerializer(capacity.unserialized, capacity.serialized),
      new CellSerializer(total.unserialized, total.serialized),
      new CellSerializer(dosingFactor.unserialized, dosingFactor.serialized),
      new CellSerializer(avgAnimalsNumber.unserialized, avgAnimalsNumber.serialized),
      new CellSerializer(weightPerAnimal.unserialized, weightPerAnimal.serialized),
      new CellSerializer(dddDaily.unserialized, dddDaily.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeVeterinarianData = (serverData: ResourcesWrapper<VeterinarianDataRecordPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { asyncAction } from 'lib/actions';
import { CatalogueDataObject } from './entities';

export const fetchDataCatalogueAction = asyncAction('dataCatalogue/fetchDataCatalogue');

export const createDataObjectAction = asyncAction<{ name: string; description: string }>(
  'dataCatalogue/createDataObject',
);

export const deleteDataObjectAction = asyncAction<{ id: number }>('dataCatalogue/deleteDataObject');
export const updateDataObjectAction = asyncAction<
  Pick<CatalogueDataObject, 'id' | 'name' | 'description' | 'dataPoints'>
>('dataCatalogue/updateDataObject');

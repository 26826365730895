import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { ResourcesWrapper } from 'lib/http/utils';
import { DELIVERY_SEGMENT_FIELDS } from '../../../../lib/tables/config/delivery/segment/config';

const { columns, serialized, unserialized } = DELIVERY_SEGMENT_FIELDS.DeliverySegment;
const {
  id,
  postalCode,
  address,
  brsNumber,
  city,
  countryCode,
  customerUbn,
  deliveryId,
  deliveryMode,
  deliveryMessageNumber,
  orderNumber,
  receiptDate,
  receiptPrintDate,
} = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(id.unserialized, id.serialized),
      new CellSerializer(postalCode.unserialized, postalCode.serialized),
      new CellSerializer(address.unserialized, address.serialized),
      new CellSerializer(brsNumber.unserialized, brsNumber.serialized),
      new CellSerializer(city.unserialized, city.serialized),
      new CellSerializer(countryCode.unserialized, countryCode.serialized),
      new CellSerializer(customerUbn.unserialized, customerUbn.serialized),
      new CellSerializer(deliveryId.unserialized, deliveryId.serialized),
      new CellSerializer(deliveryMode.unserialized, deliveryMode.serialized),
      new CellSerializer(deliveryMessageNumber.unserialized, deliveryMessageNumber.serialized),
      new CellSerializer(orderNumber.unserialized, orderNumber.serialized),
      new CellSerializer(receiptDate.unserialized, receiptDate.serialized),
      new CellSerializer(receiptPrintDate.unserialized, receiptPrintDate.serialized),
      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
    ]),
  ]);
};

export const serializeDeliverySegmentInfo = (serverData: ResourcesWrapper) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

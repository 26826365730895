import { CFP_CO2_EMISSION_FACTOR } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { roundTo3DigitsFloating } from 'lib/helpers/renderHelpers';
import { formatViewNumber } from 'lib/excel/serilizers/utils';

const { columns, serialized, view } = CFP_CO2_EMISSION_FACTOR.CFP_CO2EmissionFactors;

export const getCfpCo2EmissionFactorView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.emissionSource.serialized,
        viewPropName: columns.emissionSource.view,
      }),
      new CellViewer({
        serializedPropName: columns.co2Volume.serialized,
        viewPropName: columns.co2Volume.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo3DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.reference.serialized, viewPropName: columns.reference.view }),
    ]),
  ]);
};

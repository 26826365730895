import { IntlKeys } from 'lib/localization/keys/__keys';
import Certificates from './pillars/Certificates';
import Farm from './pillars/Farm';
import Feed from './pillars/Feed';
import Genetics from './pillars/Genetics';
import Medication from './pillars/Medication';
import Slaughter from './pillars/Slaughter';
import Transport from './pillars/Transport';

export enum AnimalPassportEntities {
  FOOD_SAFETY = 'foodSafety',
  ANIMAL_WELFARE = 'animalWelfare',
  PRODUCT_INTEGRITY = 'productIntegrity',
  DURABILITY = 'durability',
}

export enum PillarTypes {
  Certificates = 'certificates',
  Medication = 'veterinarianData',
  Feed = 'feed',
  Transport = 'movementEvents',
  Farm = 'farmData',
  Slaughter = 'slaughter',
  Genetica = 'genetica',
}

export type AnimalPassportEntityConfig = {
  title: string;
  pillars: PillarTypes[];
};

export const PILLARS: {
  [key in PillarTypes]: {
    title: string;
    component: React.FC<any>;
    detailsUrl?: string;
    dateFilterName?: string;
  };
} = {
  [PillarTypes.Certificates]: {
    title: IntlKeys.animalPassportDataTabsCertification,
    component: Certificates,
  },
  [PillarTypes.Medication]: {
    title: IntlKeys.animalPassportDataTabsMedication,
    component: Medication,
    detailsUrl: 'veterinarianData',
    dateFilterName: 'exportDate',
  },
  [PillarTypes.Feed]: {
    title: IntlKeys.animalPassportDataTabsFeed,
    detailsUrl: 'deliveryMessages',
    component: Feed,
    dateFilterName: 'receiptDate',
  },
  [PillarTypes.Transport]: {
    title: IntlKeys.animalPassportDataTabsTransport,
    detailsUrl: 'movementEvents',
    component: Transport,
    dateFilterName: 'eventDateTime',
  },
  [PillarTypes.Farm]: {
    title: IntlKeys.animalPassportDataTabsFarm,
    detailsUrl: 'farmUpdates',
    component: Farm,
    dateFilterName: 'updatedAt',
  },
  [PillarTypes.Slaughter]: {
    title: IntlKeys.animalPassportDataTabsSlaughter,
    detailsUrl: 'slaughter',
    component: Slaughter,
    dateFilterName: 'messageDate',
  },
  [PillarTypes.Genetica]: {
    title: IntlKeys.animalPassportDataTabsGenetics,
    detailsUrl: 'genetic',
    component: Genetics,
    dateFilterName: 'deliveryDate',
  },
};

export const animalPassportConfig: Record<AnimalPassportEntities, AnimalPassportEntityConfig> = {
  [AnimalPassportEntities.FOOD_SAFETY]: {
    title: IntlKeys.animalPassportDataTabsFoodSafety,
    pillars: [
      //
      PillarTypes.Certificates,
      PillarTypes.Medication,
      PillarTypes.Feed,
      PillarTypes.Slaughter,
    ],
  },
  [AnimalPassportEntities.ANIMAL_WELFARE]: {
    title: IntlKeys.animalPassportDataTabsAnimalWelfare,
    pillars: [
      //
      PillarTypes.Certificates,
      PillarTypes.Medication,
      PillarTypes.Farm,
      PillarTypes.Slaughter,
    ],
  },
  [AnimalPassportEntities.PRODUCT_INTEGRITY]: {
    title: IntlKeys.animalPassportDataTabsProductIntegrity,
    pillars: [
      PillarTypes.Certificates,
      PillarTypes.Feed,
      PillarTypes.Transport,
      PillarTypes.Slaughter,
      PillarTypes.Genetica,
    ],
  },
  [AnimalPassportEntities.DURABILITY]: {
    title: IntlKeys.animalPassportDataTabsSustainability,
    pillars: [
      //
      PillarTypes.Certificates,
      PillarTypes.Medication,
      PillarTypes.Feed,
      PillarTypes.Transport,
    ],
  },
};

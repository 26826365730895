import { CL650_FIELDS } from 'lib/excel/config/referenceTables/cl650/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = CL650_FIELDS.Cl650;

export const serializeCl650Data = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.code.original, columns.code.serialized),
      new CellSerializer(columns.description.original, columns.description.serialized),
      new CellSerializer(columns.note.original, columns.note.serialized),
    ]),
  ]);
};

import { GWP_100_FACTORS_FIELDS } from 'lib/excel/config/referenceTables/gwp100Factors/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = GWP_100_FACTORS_FIELDS.Gwp100Factors;

export const serializeGwp100FactorsData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.greenhouseGas.original, columns.greenhouseGas.serialized),
      new CellSerializer(columns.gwp100Factor.original, columns.gwp100Factor.serialized),
      new CellSerializer(columns.gwp100FactorUnit.original, columns.gwp100FactorUnit.serialized),
    ]),
  ]);
};

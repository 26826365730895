import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';

import { RootState } from '../store';

import {
  fetchVriDataAction,
  setSelectedVriUbn,
  setSelectedVriDateRange,
  fetchUBNsAutocomplete,
  fetchVriStatisticsAction,
  fetchVriReportsConstantsAction,
  deleteReportUpload,
} from './actions';
import {
  DateRange,
  VriReportSerialized,
  VriResourceItemSerialized,
  VriStatisticsResourceSerialized,
  VriReportsConstantsSerialized,
  UbnEntity,
} from 'lib/tables/config/vri/config';

const initialState = {
  meta: {
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  resources: [] as VriResourceItemSerialized[],
  selectedDateRange: {} as DateRange,
  ubn: {
    resources: [] as UbnEntity[],
    isLoading: false,
    selectedUbn: null as string | null,
  },
  concept: {
    selectedConcept: null as string | null,
  },
  statistics: {
    resource: {} as VriStatisticsResourceSerialized,
    isLoading: false,
  },
  constants: {
    resource: {} as VriReportsConstantsSerialized,
    isLoading: false,
  },
};

export const vriReducer = createReducer(initialState, {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  ////////////////////////////////////////////////////////////////////////////
  [fetchVriDataAction.request]: (draft, { payload }) => {
    draft.meta.isError = false;
    draft.meta.isLoading = true;
    draft.meta.isLoaded = false;
    draft.selectedDateRange = initialState.selectedDateRange;
    draft.resources = initialState.resources;
    draft.statistics.resource = initialState.statistics.resource;
  },

  [deleteReportUpload.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },
  [deleteReportUpload.success]: (draft, params) => {
    const deleteItemId = params?.requestPayload?.params?.id;

    const filteredItems = draft.resources.filter(({ id }) => id !== deleteItemId);
    draft.resources = [...filteredItems]; // refresh table;

    draft.meta.isLoading = false;
  },
  [deleteReportUpload.failure]: (draft, { payload }) => {
    draft.meta.isLoading = false;
  },

  [fetchVriDataAction.success]: (draft, { payload, meta }) => {
    draft.meta.isLoading = false;
    draft.meta.isLoaded = true;
    draft.resources = payload;
    const lastReport = payload[payload.length - 1];
    if (lastReport) {
      const { reportDateFrom, reportDateTo, id } = lastReport;
      draft.selectedDateRange = { reportDateFrom, reportDateTo, id };
    }
  },

  [fetchVriDataAction.failure]: (draft, { payload }) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = false;
    draft.resources = initialState.resources;
  },
  ////////////////////////////////////////////////////////////////////////////
  [fetchUBNsAutocomplete.request]: (draft, { payload }) => {
    draft.ubn.isLoading = true;
  },

  [fetchUBNsAutocomplete.success]: (draft, { payload, meta }) => {
    draft.ubn.isLoading = false;
    draft.ubn.resources = payload;
    const selectedUbn = (payload as UbnEntity[])[0];
    if (selectedUbn) {
      draft.ubn.selectedUbn = selectedUbn.ubn;
    }
  },

  [fetchUBNsAutocomplete.failure]: (draft, { payload }) => {
    draft.ubn.isLoading = false;
    draft.ubn.resources = initialState.ubn.resources;
    draft.ubn.selectedUbn = initialState.ubn.selectedUbn;
  },

  ////////////////////////////////////////////////////////////////////////////
  [fetchVriStatisticsAction.request]: (draft, { payload }) => {
    draft.statistics.isLoading = true;
  },

  [fetchVriStatisticsAction.success]: (draft, { payload }) => {
    draft.statistics.isLoading = false;
    draft.statistics.resource = payload;
  },

  [fetchVriStatisticsAction.failure]: (draft, { payload }) => {
    draft.statistics.isLoading = false;
    draft.statistics.resource = initialState.statistics.resource;
  },

  ////////////////////////////////////////////////////////////////////////////
  [fetchVriReportsConstantsAction.request]: (draft, { payload }) => {
    draft.constants.isLoading = true;
  },

  [fetchVriReportsConstantsAction.success]: (draft, { payload }) => {
    draft.constants.isLoading = false;
    draft.constants.resource = payload;
  },

  [fetchVriReportsConstantsAction.failure]: (draft, { payload }) => {
    draft.constants.isLoading = false;
    draft.constants.resource = initialState.constants.resource;
  },

  ////////////////////////////////////////////////////////////////////////////
  /* SYNC ACTIONS */
  [setSelectedVriUbn.type]: (draft, { payload }) => {
    draft.ubn.selectedUbn = payload;
    draft.selectedDateRange = initialState.selectedDateRange;
  },
  [setSelectedVriDateRange.type]: (draft, { payload }) => {
    draft.selectedDateRange = payload;
  },
});

const vriSelector = (state: RootState) => state.vri;
export const vriResourcesSelector = createSelector(vriSelector, (vri) => {
  return cloneDeep(vri.resources);
});

export const vriMetaSelector = createSelector(vriSelector, (vri) => vri.meta);

export const vriUbn = createSelector(vriSelector, (vri) => vri.ubn);
export const vriConcept = createSelector(vriSelector, (vri) => vri.concept);
export const vriUbnResourcesList = createSelector(vriUbn, (vri) => vri.resources);
export const vriUbnResources = createSelector(vriUbnResourcesList, (resources) =>
  resources.map((ubnEntity) => ubnEntity.ubn),
);
export const vriUbnIsLoading = createSelector(vriUbn, (vri) => vri.isLoading);
export const vriUbnSelected = createSelector(vriUbn, (vri) => vri.selectedUbn);
export const vriConceptSelected = createSelector(vriConcept, (vri) => vri.selectedConcept);

export const vriSelectedDateRangeSelector = createSelector(vriSelector, (vri) => vri.selectedDateRange);
export const vriDateRangeSelector = createSelector(vriResourcesSelector, (resources) =>
  resources?.map(({ reportDateFrom, reportDateTo, id, rating }) => ({
    dateRange: { reportDateFrom, reportDateTo, id } as DateRange,
    rating,
  })),
);
export const vriCurrentUploadSelector = createSelector(
  vriResourcesSelector,
  vriSelectedDateRangeSelector,
  (resources, dateRange) => {
    return resources?.find((resourcesItem) => resourcesItem.id === dateRange.id);
  },
);
export const vriCurrentUploadReportsSelector = createSelector(
  vriCurrentUploadSelector,
  (currentUpload) => currentUpload?.reports,
);
export const vriCurrentUploadReportsResourceSelector = createSelector(vriCurrentUploadReportsSelector, (reports) =>
  cloneDeep(reports || []),
);
export const vriCurrentReportSelector = createSelector(
  vriCurrentUploadReportsSelector,
  vriUbnSelected,
  (currentUploadReports, ubn) => currentUploadReports?.find((report) => report.ubn === ubn),
);

export const vriGraphData = createSelector(vriCurrentUploadSelector, (currentReport) => {
  return cloneDeep(currentReport?.reports || ([] as VriReportSerialized[]));
});

export const vriStatistics = createSelector(vriSelector, (vri) => vri.statistics);
export const vriStatisticsResource = createSelector(vriStatistics, (statistics) => statistics.resource);
export const vriStatisticsIsLoading = createSelector(vriStatistics, (statistics) => statistics.isLoading);

export const vriConstantsSelector = createSelector(vriSelector, (vri) => vri.constants);
export const vriConstantsResourceSelector = createSelector(vriConstantsSelector, (constants) => constants.resource);
export const vriConstantsTop20Selector = createSelector(vriConstantsResourceSelector, (resource) => resource.top20);

export default vriReducer;

import { IntlKeys } from 'lib/localization/keys/__keys';

enum WurMfcEfemOriginal {
  WurMfcEfem = 'CFP_WUR_MCF',
}

enum WurMfcEfemSerialized {
  WurMfcEfem = 'WurMfcEfem',
}

enum WurMfcEfemUnserialized {
  WurMfcEfem = 'WurMfcEfem',
}

enum WurMfcEfemOriginalHeaders {
  'system' = 'System',
  'avgStorageDurationManure' = 'Average storage duration of manure',
  'mcf' = 'MCF',
}

enum WurMfcEfemSerializedHeaders {
  'system' = 'system',
  'avgStorageDurationManure' = 'avgStorageDurationManure',
  'mcf' = 'mcf',
}

enum WurMfcEfemUnserializedHeaders {
  'system' = 'system',
  'avgStorageDurationManure' = 'avg_storage_duration_manure',
  'mcf' = 'mcf',
}

export const WUR_MFC_EFEM_FIELDS = {
  [WurMfcEfemUnserialized.WurMfcEfem]: {
    original: WurMfcEfemOriginal.WurMfcEfem,
    serialized: WurMfcEfemSerialized.WurMfcEfem,
    unserialized: WurMfcEfemUnserialized.WurMfcEfem,
    view: IntlKeys.referenceTablesWurMfcEfem,
    viewName: 'CFP_WUR_MCF',

    columns: {
      [WurMfcEfemSerializedHeaders.system]: {
        original: WurMfcEfemOriginalHeaders.system,
        serialized: WurMfcEfemSerializedHeaders.system,
        unserialized: WurMfcEfemUnserializedHeaders.system,
        view: IntlKeys.referenceTablesWurMfcEfemSystem,
      },
      [WurMfcEfemSerializedHeaders.mcf]: {
        original: WurMfcEfemOriginalHeaders.mcf,
        serialized: WurMfcEfemSerializedHeaders.mcf,
        unserialized: WurMfcEfemUnserializedHeaders.mcf,
        view: IntlKeys.referenceTablesWurMfcEfemMfc,
      },
      [WurMfcEfemSerializedHeaders.avgStorageDurationManure]: {
        original: WurMfcEfemOriginalHeaders.avgStorageDurationManure,
        serialized: WurMfcEfemSerializedHeaders.avgStorageDurationManure,
        unserialized: WurMfcEfemUnserializedHeaders.avgStorageDurationManure,
        view: IntlKeys.referenceTablesWurMfcEfemDurationDung,
      },
    },
  },
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { TRANSPORTS, TransportsSerialized } from 'lib/tables/config/transports/base/config';

interface TransportsResponseEntry {
  company_scheme: string;
  company_value: string;
  end_date: string;
  granted_on: string;
  refused_on: string;
  replaced_by_id: string;
  revoked_on: string;
  start_date: string;
  status: number;
  is_valid: boolean;
  purpose: {
    name: string;
  };
  restrictions: [
    {
      restriction_type: string;
      description: string;
      date_start: string;
      date_end: string;
      scheme: string;
    },
  ];
}

const { columns, serialized, unserialized } = TRANSPORTS[TransportsSerialized.Transports];

const getSerializer = (data: DocumentData<TransportsResponseEntry[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),

      new CellSerializer(columns.transportId.unserialized, columns.transportId.serialized),
      new CellSerializer(columns.transportReferenceId.unserialized, columns.transportReferenceId.serialized),
      new CellSerializer(columns.partyIdTypeCode.unserialized, columns.partyIdTypeCode.serialized),
      new CellSerializer(columns.partyIdValue.unserialized, columns.partyIdValue.serialized),
      new CellSerializer(
        columns.logisticServiceProviderName.unserialized,
        columns.logisticServiceProviderName.serialized,
      ),
      new CellSerializer(columns.labels.unserialized, columns.labels.serialized),

      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
    ]),
  ]);
};

export const serializeTransports = (serverData: ResourcesWrapper<TransportsResponseEntry>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

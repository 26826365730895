import { RAV_CODES_FIELDS } from 'lib/excel/config/referenceTables/ravCodes/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = RAV_CODES_FIELDS.RavCodes;

export const getRavCodesDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.animalCategory.original),
      new CellValidator(columns.ravCode.original),
      new CellValidator(columns.animalDescriptionNl.original),
      new CellValidator(columns.animalDescriptionEn.original),
      new CellValidator(columns.animalEnclosureCategory.original),
      new CellValidator(columns.ammonia.original),
      new CellValidator(columns.ammoniaUnit.original),
    ]),
  ]);
};

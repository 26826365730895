import { Route } from '../../Route';
import RouteParser from 'route-parser';

import { generateBlockchainRecord } from '../../utils';

export const genetics = new Route('get', new RouteParser('/mock/genetics'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 1,
          ubn: '1111111',
          semen_producer_ubn: '2222222',
          delivery_date: '2004-02-03T20:59:56.000Z',
          male_type: 'mtype',
          male_amount: 23,
          female_type: 'fmtype',
          female_amount: 25,
          ...generateBlockchainRecord(),
        },
        {
          id: 2,
          ubn: '1111112',
          semen_producer_ubn: '2222223',
          delivery_date: '2004-02-13T20:59:56.000Z',
          male_type: 'mtype',
          male_amount: 23,
          female_type: 'fmtype',
          female_amount: 25,
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';

import { CatalogueDataObject } from './entities';
import {
  fetchDataCatalogueAction,
  createDataObjectAction,
  deleteDataObjectAction,
  updateDataObjectAction,
} from './actions';

const initialState = {
  //
  meta: {
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  resources: [] as CatalogueDataObject[],
};

export const dataCatalogueReducer = createReducer(initialState, {
  ////////////////////////////////////////////////////////////////////////////
  [fetchDataCatalogueAction.request]: (draft, { payload }) => {
    draft.meta.isError = false;
    draft.meta.isLoading = true;
    draft.meta.isLoaded = false;
  },

  [fetchDataCatalogueAction.success]: (draft, { payload, meta }) => {
    draft.meta.isLoading = false;
    draft.meta.isLoaded = true;
    draft.resources = payload;
  },

  [fetchDataCatalogueAction.failure]: (draft, { payload }) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = false;
    draft.resources = initialState.resources;
  },
  ////////////////////////////////////////////////////////////////////////////
  [createDataObjectAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },

  [createDataObjectAction.success]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.resources.push(payload);
  },

  [createDataObjectAction.failure]: (draft, { payload }) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
  },
  ////////////////////////////////////////////////////////////////////////////
  [deleteDataObjectAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },

  [deleteDataObjectAction.success]: (draft, { payload, requestPayload }) => {
    draft.meta.isLoading = false;
    draft.resources = draft.resources.filter(({ id }) => id !== requestPayload.params.id);
  },

  [deleteDataObjectAction.failure]: (draft, { payload }) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
  },
  ////////////////////////////////////////////////////////////////////////////
  [updateDataObjectAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },

  [updateDataObjectAction.success]: (draft, { payload, requestPayload }) => {
    draft.meta.isLoading = false;
    draft.resources = draft.resources.map((category) =>
      category.id == requestPayload.params.id ? payload : category,
    );
  },

  [updateDataObjectAction.failure]: (draft, { payload }) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
  },
  ////////////////////////////////////////////////////////////////////////////
});

const dataCatalogueSelector = (state: RootState) => state.dataCatalogue;
export const dataCatalogueResourcesSelector = createSelector(
  dataCatalogueSelector,
  (dataCatalogue) => {
    return dataCatalogue.resources;
  },
);

export const dataCatalogueMetaSelector = createSelector(
  dataCatalogueSelector,
  (dataCatalogue) => dataCatalogue.meta,
);

export default dataCatalogueReducer;

import { call, select, takeEvery } from 'redux-saga/effects';

import { ApiClient } from 'lib/http/ApiClient';
import ensure from 'lib/ensure';

import {
  fetchVriDataAction,
  fetchUBNsAutocomplete,
  fetchVriStatisticsAction,
  fetchVriReportsConstantsAction,
  deleteReport,
  deleteReportUpload,
} from './actions';
import {
  serializeVriResourcesData,
  serializeVriStatistics,
  serializeFileReports,
  serializeVriReportsConstants,
} from './entities';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { fetchBatchesAction } from '../reducers/batchReducer';
import { PERMISSIONS } from 'store/auth/permissions';
import { userHasPermissionSelector } from 'store/auth/reducer';

export const ensureGetReportUploads = ensure({
  api: ApiClient.getReportUploads,
  action: fetchVriDataAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureAdminGetReportUploads = ensure({
  api: ApiClient.adminGetReportUploads,
  action: fetchVriDataAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureGetReports = ensure({
  api: ApiClient.getReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFileReports,
});

export const ensureAdminGetReports = ensure({
  api: ApiClient.adminGetReports,
  action: fetchBatchesAction,
  serializeSuccessPayload: serializeFileReports,
});

export const ensureAdminDeleteReport = ensure({
  api: ApiClient.adminDeleteReport,
  action: deleteReport,
});

export const ensureAdminDeleteReportUpload = ensure({
  api: ApiClient.adminDeleteReportUpload,
  action: deleteReportUpload,
});

const ensureGetUbnsAutocompleteOptions = ensure({
  api: ApiClient.getUbnList,
  action: fetchUBNsAutocomplete,
});

export const ensureGetReportsStatistics = ensure({
  api: ApiClient.getReportsStatistics,
  action: fetchVriStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

export const ensureAdminGetReportsStatistics = ensure({
  api: ApiClient.adminGetReportsStatistics,
  action: fetchVriStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

export const ensureGetReportsConstants = ensure({
  api: ApiClient.getReportsConstants,
  action: fetchVriReportsConstantsAction,
  serializeSuccessPayload: serializeVriReportsConstants,
});

export const ensureAdminGetReportsConstants = ensure({
  api: ApiClient.adminGetReportsConstants,
  action: fetchVriReportsConstantsAction,
  serializeSuccessPayload: serializeVriReportsConstants,
});

function* deleteVriUploads({ payload }: ObjectWithProps) {
  const { ensure, filters } = payload;

  yield call(ensure, {
    params: {
      id: filters.id,
    },
  });
}

function* getVriData({ payload }: ObjectWithProps) {
  const { ensure, filters, filter } = payload;

  yield call(ensure, { filters, filter });
}

function* getReportsConstants({ payload }: ObjectWithProps) {
  const { filter } = payload;
  const isAdmin: boolean = (yield select(userHasPermissionSelector))(PERMISSIONS.LEVEL1);

  yield call(isAdmin ? ensureAdminGetReportsConstants : ensureGetReportsConstants, { filter });
}

export default function* () {
  yield takeEvery(deleteReport.request, deleteVriUploads);
  yield takeEvery(deleteReportUpload.request, deleteVriUploads);
  yield takeEvery(fetchVriDataAction.request, getVriData);
  yield takeEvery(fetchVriStatisticsAction.request, getVriData);
  yield takeEvery(fetchUBNsAutocomplete.request, ensureGetUbnsAutocompleteOptions);
  yield takeEvery(fetchVriReportsConstantsAction.request, getReportsConstants);
}

import { IntlKeys } from 'lib/localization/keys/__keys';

// in uploaded excel
enum AdminOrganizationsOriginal {
  Org = 'Org',
}
export const AdminOrganizationsSerialized = 'adminOrganizations';
const AdminOrganizationsUnserialized = 'AdminOrganizations';
const AdminOrganizationsView = IntlKeys.adminUsersOrganizations;

const AdminOrganizationsViewHeaders = {
  id: IntlKeys.id,
  email: IntlKeys.email,
  kvk: IntlKeys.kvk,
  kvkCompanyName: IntlKeys.organizationsKvkName,
  updatedAt: IntlKeys.adminOrganizationsLastUpdate,
  //
  users: IntlKeys.organizationsUsers,
  userPassports: IntlKeys.titleUserPassports,
  customKvkName: IntlKeys.customKvkName,
};

export const AdminOrganizationsOriginalHeaders = {
  id: 'id',
  email: 'email',
  kvk: 'kvk',
  kvkCompanyName: 'kvk_company_name',
  updatedAt: 'updated_at',
  logoUrl: ['logo', 'url'],
  backgroundImageUrl: ['background_image', 'url'],
  //
  users: 'users',
  userPassports: 'user_passports',
  customKvkName: 'custom_kvk_name',
};

export enum AdminOrganizationsSerializedHeaders {
  id = 'id',
  email = 'email',
  kvk = 'kvk',
  kvkCompanyName = 'kvkCompanyName',
  updatedAt = 'updatedAt',
  logoUrl = 'logoUrl',
  backgroundImageUrl = 'backgroundImageUrl',
  //
  users = 'users',
  userPassports = 'userPassports',
  customKvkName = 'customKvkName',
}

export const AdminOrganizationsUnserializedHeaders = {
  id: 'id',
  email: 'email',
  kvk: 'kvk',
  kvkCompanyName: 'kvk_company_name',
  updatedAt: 'updated_at',
  logoUrl: ['logo', 'url'],
  backgroundImageUrl: ['background_image', 'url'],
  //
  users: 'users',
  userPassports: 'user_passports',
  customKvkName: 'custom_kvk_name',
};

export const ADMIN_ORGANIZATIONS_FIELDS = {
  [AdminOrganizationsSerialized]: {
    original: AdminOrganizationsOriginal.Org,
    serialized: AdminOrganizationsSerialized,
    unserialized: AdminOrganizationsUnserialized,
    view: AdminOrganizationsView,
    viewName: 'Organizations',
    columns: {
      [AdminOrganizationsSerializedHeaders.email]: {
        original: AdminOrganizationsOriginalHeaders.email,
        serialized: AdminOrganizationsSerializedHeaders.email,
        unserialized: AdminOrganizationsUnserializedHeaders.email,
        view: AdminOrganizationsViewHeaders.email,
      },
      [AdminOrganizationsSerializedHeaders.kvk]: {
        original: AdminOrganizationsOriginalHeaders.kvk,
        serialized: AdminOrganizationsSerializedHeaders.kvk,
        unserialized: AdminOrganizationsUnserializedHeaders.kvk,
        view: AdminOrganizationsViewHeaders.kvk,
      },
      [AdminOrganizationsSerializedHeaders.customKvkName]: {
        original: AdminOrganizationsOriginalHeaders.customKvkName,
        serialized: AdminOrganizationsSerializedHeaders.customKvkName,
        unserialized: AdminOrganizationsUnserializedHeaders.customKvkName,
        view: AdminOrganizationsViewHeaders.customKvkName,
      },
      [AdminOrganizationsSerializedHeaders.kvkCompanyName]: {
        original: AdminOrganizationsOriginalHeaders.kvkCompanyName,
        serialized: AdminOrganizationsSerializedHeaders.kvkCompanyName,
        unserialized: AdminOrganizationsUnserializedHeaders.kvkCompanyName,
        view: AdminOrganizationsViewHeaders.kvkCompanyName,
      },
      [AdminOrganizationsSerializedHeaders.updatedAt]: {
        original: AdminOrganizationsOriginalHeaders.updatedAt,
        serialized: AdminOrganizationsSerializedHeaders.updatedAt,
        unserialized: AdminOrganizationsUnserializedHeaders.updatedAt,
        view: AdminOrganizationsViewHeaders.updatedAt,
      },
      [AdminOrganizationsSerializedHeaders.logoUrl]: {
        original: AdminOrganizationsOriginalHeaders.logoUrl,
        serialized: AdminOrganizationsSerializedHeaders.logoUrl,
        unserialized: AdminOrganizationsUnserializedHeaders.logoUrl,
      },
      [AdminOrganizationsSerializedHeaders.backgroundImageUrl]: {
        original: AdminOrganizationsOriginalHeaders.backgroundImageUrl,
        serialized: AdminOrganizationsSerializedHeaders.backgroundImageUrl,
        unserialized: AdminOrganizationsUnserializedHeaders.backgroundImageUrl,
      },

      //
      [AdminOrganizationsSerializedHeaders.users]: {
        original: AdminOrganizationsOriginalHeaders.users,
        serialized: AdminOrganizationsSerializedHeaders.users,
        unserialized: AdminOrganizationsUnserializedHeaders.users,
        view: AdminOrganizationsViewHeaders.users,
      },
      [AdminOrganizationsSerializedHeaders.userPassports]: {
        original: AdminOrganizationsOriginalHeaders.userPassports,
        serialized: AdminOrganizationsSerializedHeaders.userPassports,
        unserialized: AdminOrganizationsUnserializedHeaders.userPassports,
        view: AdminOrganizationsViewHeaders.userPassports,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys';

enum GroupFinancialEventsSerialized {
  GroupFinancialEvents = 'GroupFinancialEvents',
}

enum GroupFinancialEventsUnserialized {
  GroupFinancialEvents = 'GroupFinancialEvents',
}

export enum GroupFinancialEventsSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  finEventIdentifier = 'finEventIdentifier',
  eventTypeIdentifier = 'eventTypeIdentifier',
  eventDate = 'eventDate',
  contactIdentifier = 'contactIdentifier',
  amountExVat = 'amountExVat',
  amountVat = 'amountVat',
  amountInVat = 'amountInVat',
  invoiceNumber = 'invoiceNumber',
  invoiceDate = 'invoiceDate',
  financialNotes = 'financialNotes',
  groupDatetime = 'groupDatetime',
  barnIdentifier = 'barnIdentifier',
  roomIdentifier = 'roomIdentifier',
  penIdentifier = 'penIdentifier',
  groupIdentifier = 'groupIdentifier',
  valve = 'valve',
  inputEventIdentifier = 'inputEventIdentifier',
  importIdentifier = 'importIdentifier',
  inputWarnings = 'inputWarnings',
  inputTypeStamp = 'inputTypeStamp',
  scannedIdentifiers = 'scannedIdentifiers',
  userIdentifier = 'userIdentifier',
  batchNumberProduction = 'batchNumberProduction',
  batchNumberDelivery = 'batchNumberDelivery',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupFinancialEventsUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  finEventIdentifier: 'fin_event_identifier',
  eventTypeIdentifier: 'event_type_identifier',
  eventDate: 'event_date',
  contactIdentifier: 'contact_identifier',
  amountExVat: 'amount_ex_vat',
  amountVat: 'amount_vat',
  amountInVat: 'amount_in_vat',
  invoiceNumber: 'invoice_number',
  invoiceDate: 'invoice_date',
  financialNotes: 'financial_notes',
  groupDatetime: 'group_datetime',
  barnIdentifier: 'barn_identifier',
  roomIdentifier: 'room_identifier',
  penIdentifier: 'pen_identifier',
  groupIdentifier: 'group_identifier',
  valve: 'valve',
  inputEventIdentifier: 'input_event_identifier',
  importIdentifier: 'import_identifier',
  inputWarnings: 'input_warnings',
  inputTypeStamp: 'input_type_stamp',
  scannedIdentifiers: 'scanned_identifiers',
  userIdentifier: 'user_identifier',
  batchNumberProduction: 'batch_number_production',
  batchNumberDelivery: 'batch_number_delivery',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_FINANCIAL_EVENTS = {
  [GroupFinancialEventsSerialized.GroupFinancialEvents]: {
    serialized: GroupFinancialEventsSerialized.GroupFinancialEvents,
    unserialized: GroupFinancialEventsUnserialized.GroupFinancialEvents,
    view: IntlKeys.farmManagementDataGroupFinancialEvents,

    columns: {
      [GroupFinancialEventsSerializedHeaders.id]: {
        serialized: GroupFinancialEventsSerializedHeaders.id,
        unserialized: GroupFinancialEventsUnserializedHeaders.id,
      },
      [GroupFinancialEventsSerializedHeaders.companyIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.companyIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupFinancialEventsSerializedHeaders.farmIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.farmIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupFinancialEventsSerializedHeaders.finEventIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.finEventIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.finEventIdentifier,
        view: IntlKeys.farmManagementDataFinEventId,
      },
      [GroupFinancialEventsSerializedHeaders.eventTypeIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.eventTypeIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.eventTypeIdentifier,
        view: IntlKeys.farmManagementDataEventTypeId,
      },
      [GroupFinancialEventsSerializedHeaders.eventDate]: {
        serialized: GroupFinancialEventsSerializedHeaders.eventDate,
        unserialized: GroupFinancialEventsUnserializedHeaders.eventDate,
        view: IntlKeys.farmManagementDataEventDate,
      },
      [GroupFinancialEventsSerializedHeaders.contactIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.contactIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.contactIdentifier,
        view: IntlKeys.farmManagementDataContactId,
      },
      [GroupFinancialEventsSerializedHeaders.amountExVat]: {
        serialized: GroupFinancialEventsSerializedHeaders.amountExVat,
        unserialized: GroupFinancialEventsUnserializedHeaders.amountExVat,
        view: IntlKeys.farmManagementDataAmountExVat,
      },
      [GroupFinancialEventsSerializedHeaders.amountVat]: {
        serialized: GroupFinancialEventsSerializedHeaders.amountVat,
        unserialized: GroupFinancialEventsUnserializedHeaders.amountVat,
        view: IntlKeys.farmManagementDataAmountVat,
      },
      [GroupFinancialEventsSerializedHeaders.amountInVat]: {
        serialized: GroupFinancialEventsSerializedHeaders.amountInVat,
        unserialized: GroupFinancialEventsUnserializedHeaders.amountInVat,
        view: IntlKeys.farmManagementDataAmountInVat,
      },
      [GroupFinancialEventsSerializedHeaders.invoiceNumber]: {
        serialized: GroupFinancialEventsSerializedHeaders.invoiceNumber,
        unserialized: GroupFinancialEventsUnserializedHeaders.invoiceNumber,
        view: IntlKeys.farmManagementDataInvoiceNumber,
      },
      [GroupFinancialEventsSerializedHeaders.invoiceDate]: {
        serialized: GroupFinancialEventsSerializedHeaders.invoiceDate,
        unserialized: GroupFinancialEventsUnserializedHeaders.invoiceDate,
        view: IntlKeys.farmManagementDataInvoceDate,
      },
      [GroupFinancialEventsSerializedHeaders.financialNotes]: {
        serialized: GroupFinancialEventsSerializedHeaders.financialNotes,
        unserialized: GroupFinancialEventsUnserializedHeaders.financialNotes,
        view: IntlKeys.farmManagementDataFinancialNotes,
      },
      [GroupFinancialEventsSerializedHeaders.groupDatetime]: {
        serialized: GroupFinancialEventsSerializedHeaders.groupDatetime,
        unserialized: GroupFinancialEventsUnserializedHeaders.groupDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupFinancialEventsSerializedHeaders.barnIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.barnIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.barnIdentifier,
        view: IntlKeys.farmManagementDataBarnId,
      },
      [GroupFinancialEventsSerializedHeaders.roomIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.roomIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.roomIdentifier,
        view: IntlKeys.farmManagementDataRoomId,
      },
      [GroupFinancialEventsSerializedHeaders.penIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.penIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.penIdentifier,
        view: IntlKeys.farmManagementDataPenId,
      },
      [GroupFinancialEventsSerializedHeaders.groupIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.groupIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.groupIdentifier,
        view: IntlKeys.farmManagementDataGroupId,
      },
      [GroupFinancialEventsSerializedHeaders.valve]: {
        serialized: GroupFinancialEventsSerializedHeaders.valve,
        unserialized: GroupFinancialEventsUnserializedHeaders.valve,
        view: IntlKeys.farmManagementDataValve,
      },
      [GroupFinancialEventsSerializedHeaders.inputEventIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.inputEventIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.inputEventIdentifier,
        view: IntlKeys.farmManagementDataInputEventId,
      },
      [GroupFinancialEventsSerializedHeaders.importIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.importIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.importIdentifier,
        view: IntlKeys.farmManagementDataImportId,
      },
      [GroupFinancialEventsSerializedHeaders.inputWarnings]: {
        serialized: GroupFinancialEventsSerializedHeaders.inputWarnings,
        unserialized: GroupFinancialEventsUnserializedHeaders.inputWarnings,
        view: IntlKeys.farmManagementDataInputWarnings,
      },
      [GroupFinancialEventsSerializedHeaders.inputTypeStamp]: {
        serialized: GroupFinancialEventsSerializedHeaders.inputTypeStamp,
        unserialized: GroupFinancialEventsUnserializedHeaders.inputTypeStamp,
        view: IntlKeys.farmManagementDataInputTypeStamp,
      },
      [GroupFinancialEventsSerializedHeaders.scannedIdentifiers]: {
        serialized: GroupFinancialEventsSerializedHeaders.scannedIdentifiers,
        unserialized: GroupFinancialEventsUnserializedHeaders.scannedIdentifiers,
        view: IntlKeys.farmManagementDataScannedIds,
      },
      [GroupFinancialEventsSerializedHeaders.userIdentifier]: {
        serialized: GroupFinancialEventsSerializedHeaders.userIdentifier,
        unserialized: GroupFinancialEventsUnserializedHeaders.userIdentifier,
        view: IntlKeys.farmManagementDataUserId,
      },
      [GroupFinancialEventsSerializedHeaders.batchNumberProduction]: {
        serialized: GroupFinancialEventsSerializedHeaders.batchNumberProduction,
        unserialized: GroupFinancialEventsUnserializedHeaders.batchNumberProduction,
        view: IntlKeys.farmManagementDataBatchNumberProduction,
      },
      [GroupFinancialEventsSerializedHeaders.batchNumberDelivery]: {
        serialized: GroupFinancialEventsSerializedHeaders.batchNumberDelivery,
        unserialized: GroupFinancialEventsUnserializedHeaders.batchNumberDelivery,
        view: IntlKeys.farmManagementDataBatchNumberDelivery,
      },
      [GroupFinancialEventsSerializedHeaders.ubnList]: {
        serialized: GroupFinancialEventsSerializedHeaders.ubnList,
        unserialized: GroupFinancialEventsUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupFinancialEventsSerializedHeaders.kvkList]: {
        serialized: GroupFinancialEventsSerializedHeaders.kvkList,
        unserialized: GroupFinancialEventsUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupFinancialEventsSerializedHeaders.registrationNameList]: {
        serialized: GroupFinancialEventsSerializedHeaders.registrationNameList,
        unserialized: GroupFinancialEventsUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import React from 'react';
import { Link } from 'react-router-dom';
import MaterialIconButton from '@material-ui/core/IconButton';
import MaterialCloudUploadIcon from '@material-ui/icons/CloudUpload';

interface OwnProps {
  url: string;
}

const UploadButton: React.FC<OwnProps> = ({ url }) => {
  return (
    <MaterialIconButton
      aria-label="upload file"
      color="inherit"
      edge="end"
      component={Link}
      to={url}
      data-testid="go-to-upload"
    >
      <MaterialCloudUploadIcon style={{ color: 'white' }} />
    </MaterialIconButton>
  );
};

export default React.memo(UploadButton);

/**
 * @deprecated use single IntlKeys
 */
export enum MovementEventsKeys {
  eventId = 'movementEvents.eventId',
  eventType = 'movementEvents.eventType',
  eventDateTime = 'movementEvents.eventDateTime',
  animalCategory = 'movementEvents.animalCategory',
  animalCount = 'movementEvents.animalCount',
  transportId = 'movementEvents.transportId',
  groupDate = 'movementEvents.groupDate',
  kvk = 'movementEvents.kvk',
  ubn = 'movementEvents.ubn',
  destination = 'movementEvents.destination',
  origin = 'movementEvents.origin',
  eventLocationId = 'movementEvents.eventLocationId',
  eventIdMatch = 'movementEvents.eventIdMatch',
}

import { IntlKeys } from 'lib/localization/keys/__keys';

enum FermentationReportsSerialized {
  FermentationReports = 'FermentationReports',
  FermentationReportsSubmit = 'FermentationReportsSubmit',
}

enum FermentationReportsUnserialized {
  FermentationReports = 'FermentationReports',
  FermentationReportsSubmit = 'FermentationReportsSubmit',
}

export enum FermentationReportsSerializedHeaders {
  id = 'id',
  status = 'status',
  ubn = 'ubn',
  idType = 'idType',
  companyReport = 'companyReport',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  purchaseDate = 'purchaseDate',
  productCode = 'productCode',
  productName = 'productName',
  supplierName = 'supplierName',
  deliveredQuantity = 'deliveredQuantity',
  unit = 'unit',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
}

export const FermentationReportsUnserializedHeaders = {
  id: 'id',
  status: 'status',
  ubn: ['company_report', 'user_passport', 'ubn'],
  idType: ['company_report', 'id_type'],
  companyReport: ['company_report', 'id'],
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  purchaseDate: 'purchase_date',
  productCode: 'product_code',
  productName: 'product_name',
  supplierName: 'supplier_name',
  deliveredQuantity: 'delivered_quantity',
  unit: 'unit',
  updatedAt: 'updated_at',
  updatedBy: ['updated_by', 'full_name'],
} as const;

export enum FermentationReportsSubmitSerializedHeaders {
  id = 'id',
  status = 'status',
  companyReport = 'companyReport',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  purchaseDate = 'purchaseDate',
  productCode = 'productCode',
  productName = 'productName',
  supplierName = 'supplierName',
  deliveredQuantity = 'deliveredQuantity',
  unit = 'unit',
}

export const FermentationReportsSubmitUnserializedHeaders = {
  id: 'id',
  status: 'status',
  companyReport: 'company_report_id',
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  purchaseDate: 'purchase_date',
  productCode: 'product_code',
  productName: 'product_name',
  supplierName: 'supplier_name',
  deliveredQuantity: 'delivered_quantity',
  unit: 'unit',
} as const;

export const FERMENTATION_REPORTS = {
  [FermentationReportsSerialized.FermentationReports]: {
    serialized: FermentationReportsSerialized.FermentationReports,
    unserialized: FermentationReportsUnserialized.FermentationReports,
    view: IntlKeys.titleReportList,

    columns: {
      [FermentationReportsSerializedHeaders.id]: {
        serialized: FermentationReportsSerializedHeaders.id,
        unserialized: FermentationReportsUnserializedHeaders.id,
        view: IntlKeys.farmerInputSystemId,
      },
      [FermentationReportsSerializedHeaders.status]: {
        serialized: FermentationReportsSerializedHeaders.status,
        unserialized: FermentationReportsUnserializedHeaders.status,
        view: IntlKeys.farmerInputStatus,
      },
      [FermentationReportsSerializedHeaders.ubn]: {
        serialized: FermentationReportsSerializedHeaders.ubn,
        unserialized: FermentationReportsUnserializedHeaders.ubn,
        view: IntlKeys.farmerInputIdCode,
      },
      [FermentationReportsSerializedHeaders.idType]: {
        serialized: FermentationReportsSerializedHeaders.idType,
        unserialized: FermentationReportsUnserializedHeaders.idType,
        view: IntlKeys.farmerInputIdType,
      },
      [FermentationReportsSerializedHeaders.companyReport]: {
        serialized: FermentationReportsSerializedHeaders.companyReport,
        unserialized: FermentationReportsUnserializedHeaders.companyReport,
        view: IntlKeys.farmerInputMainReportId,
      },
      [FermentationReportsSerializedHeaders.reportingPeriodDateStart]: {
        serialized: FermentationReportsSerializedHeaders.reportingPeriodDateStart,
        unserialized: FermentationReportsUnserializedHeaders.reportingPeriodDateStart,
        view: IntlKeys.farmerInputPeriodStartDate,
      },
      [FermentationReportsSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: FermentationReportsSerializedHeaders.reportingPeriodDateEnd,
        unserialized: FermentationReportsUnserializedHeaders.reportingPeriodDateEnd,
        view: IntlKeys.farmerInputPeriodEndDate,
      },
      [FermentationReportsSerializedHeaders.purchaseDate]: {
        serialized: FermentationReportsSerializedHeaders.purchaseDate,
        unserialized: FermentationReportsUnserializedHeaders.purchaseDate,
        view: IntlKeys.farmerInputDatePurchase,
      },
      [FermentationReportsSerializedHeaders.productCode]: {
        serialized: FermentationReportsSerializedHeaders.productCode,
        unserialized: FermentationReportsUnserializedHeaders.productCode,
        view: IntlKeys.farmerInputProductCode,
      },
      [FermentationReportsSerializedHeaders.productName]: {
        serialized: FermentationReportsSerializedHeaders.productName,
        unserialized: FermentationReportsUnserializedHeaders.productName,
        view: IntlKeys.farmerInputProductName,
      },
      [FermentationReportsSerializedHeaders.supplierName]: {
        serialized: FermentationReportsSerializedHeaders.supplierName,
        unserialized: FermentationReportsUnserializedHeaders.supplierName,
        view: IntlKeys.farmerInputSupplierName,
      },
      [FermentationReportsSerializedHeaders.deliveredQuantity]: {
        serialized: FermentationReportsSerializedHeaders.deliveredQuantity,
        unserialized: FermentationReportsUnserializedHeaders.deliveredQuantity,
        view: IntlKeys.farmerInputQuantityDelivered,
      },
      [FermentationReportsSerializedHeaders.unit]: {
        serialized: FermentationReportsSerializedHeaders.unit,
        unserialized: FermentationReportsUnserializedHeaders.unit,
        view: IntlKeys.farmerInputUnit,
      },
      [FermentationReportsSerializedHeaders.updatedAt]: {
        serialized: FermentationReportsSerializedHeaders.updatedAt,
        unserialized: FermentationReportsUnserializedHeaders.updatedAt,
        view: IntlKeys.farmerInputUpdatedOn,
      },
      [FermentationReportsSerializedHeaders.updatedBy]: {
        serialized: FermentationReportsSerializedHeaders.updatedBy,
        unserialized: FermentationReportsUnserializedHeaders.updatedBy,
        view: IntlKeys.farmerInputUpdatedBy,
      },
    },
  },
};

export const FERMENTATION_REPORTS_SUBMIT = {
  [FermentationReportsSerialized.FermentationReportsSubmit]: {
    serialized: FermentationReportsSerialized.FermentationReportsSubmit,
    unserialized: FermentationReportsUnserialized.FermentationReportsSubmit,

    columns: {
      [FermentationReportsSubmitSerializedHeaders.id]: {
        serialized: FermentationReportsSubmitSerializedHeaders.id,
        unserialized: FermentationReportsSubmitUnserializedHeaders.id,
      },
      [FermentationReportsSubmitSerializedHeaders.status]: {
        serialized: FermentationReportsSubmitSerializedHeaders.status,
        unserialized: FermentationReportsSubmitUnserializedHeaders.status,
      },
      [FermentationReportsSubmitSerializedHeaders.companyReport]: {
        serialized: FermentationReportsSubmitSerializedHeaders.companyReport,
        unserialized: FermentationReportsSubmitUnserializedHeaders.companyReport,
      },
      [FermentationReportsSubmitSerializedHeaders.reportingPeriodDateStart]: {
        serialized: FermentationReportsSubmitSerializedHeaders.reportingPeriodDateStart,
        unserialized: FermentationReportsSubmitUnserializedHeaders.reportingPeriodDateStart,
      },
      [FermentationReportsSubmitSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: FermentationReportsSubmitSerializedHeaders.reportingPeriodDateEnd,
        unserialized: FermentationReportsSubmitUnserializedHeaders.reportingPeriodDateEnd,
      },
      [FermentationReportsSubmitSerializedHeaders.purchaseDate]: {
        serialized: FermentationReportsSubmitSerializedHeaders.purchaseDate,
        unserialized: FermentationReportsSubmitUnserializedHeaders.purchaseDate,
      },
      [FermentationReportsSubmitSerializedHeaders.productCode]: {
        serialized: FermentationReportsSubmitSerializedHeaders.productCode,
        unserialized: FermentationReportsSubmitUnserializedHeaders.productCode,
      },
      [FermentationReportsSubmitSerializedHeaders.productName]: {
        serialized: FermentationReportsSubmitSerializedHeaders.productName,
        unserialized: FermentationReportsSubmitUnserializedHeaders.productName,
      },
      [FermentationReportsSubmitSerializedHeaders.supplierName]: {
        serialized: FermentationReportsSubmitSerializedHeaders.supplierName,
        unserialized: FermentationReportsSubmitUnserializedHeaders.supplierName,
      },
      [FermentationReportsSubmitSerializedHeaders.deliveredQuantity]: {
        serialized: FermentationReportsSubmitSerializedHeaders.deliveredQuantity,
        unserialized: FermentationReportsSubmitUnserializedHeaders.deliveredQuantity,
      },
      [FermentationReportsSubmitSerializedHeaders.unit]: {
        serialized: FermentationReportsSubmitSerializedHeaders.unit,
        unserialized: FermentationReportsSubmitUnserializedHeaders.unit,
      },
    },
  },
};

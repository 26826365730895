export interface CallableClass<T> {
  new (...args: Array<any>): T;
}

export class Serializer {
  static fromArray<ClassI>(callableClass: CallableClass<ClassI>, serverArray: Array<any>) {
    return serverArray.map((serverItem) => Serializer.serialize<ClassI>(callableClass, serverItem));
  }

  static serialize<ClassI>(callableClass: CallableClass<ClassI>, serverArray: Array<any>) {
    return new callableClass(serverArray) as ClassI;
  }
}

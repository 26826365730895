import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { ColumnsSettingsResolver } from 'lib/tables/ColumnsSettingsResolver';
import { TableColumnsGenerator } from 'lib/tables/TableColumnsGenerator';
import { HybridView } from 'lib/tables/HybridView';

import { changeHeadersOrder, patchTableConfig, tableSettingsSelector } from 'store/reducers/userSettingsReducer';

import { DataContext } from '../../index';

export const useColumns = (tableRef: React.MutableRefObject<any>) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { tableConfig, currentTab } = useContext(DataContext);
  const tableSettings = useSelector(tableSettingsSelector);

  const { useLocalization } = currentTab;

  const columns = useMemo(
    () => ColumnsSettingsResolver.resolve(tableSettings, tableConfig.id, currentTab, formatMessage, useLocalization),
    [currentTab, tableSettings, formatMessage, useLocalization, tableConfig.id],
  );

  // BEWARE: tableRef has already updated columns
  const onColumnDragged = (sourceIndex: number, destinationIndex: number) => {
    const destinationColumnIndex = destinationIndex === 0 ? 1 : destinationIndex - 1;

    const columns = tableRef?.current?.dataManager?.columns as any[];

    const sourceColumn = columns?.find(
      ({ tableData }) => tableData?.columnOrder === destinationIndex && tableData?.groupOrder === undefined,
    );
    const destinationColumn = columns?.find(
      ({ tableData }) => tableData?.columnOrder === destinationColumnIndex && tableData?.groupOrder === undefined,
    );

    if (sourceColumn && destinationColumn) {
      const source = new HybridView({ settings: sourceColumn });
      const destination = new HybridView({ settings: destinationColumn });

      dispatch(
        changeHeadersOrder({
          source,
          destination,
          tableId: tableConfig.id,
          tabId: currentTab.id,
          draggedToStart: destinationIndex === 0,
        }),
      );
    } else {
      console.error('Failed to find dragged columns in table', {
        sourceColumn,
        destinationColumn,
        sourceIndex,
        destinationIndex,
      });
    }
  };

  useEffect(() => {
    if (!TableColumnsGenerator.hasValidConfig(tableSettings, tableConfig)) {
      dispatch(patchTableConfig(TableColumnsGenerator.defaultSettings(tableConfig)));
    }
  }, [tableSettings, tableConfig, dispatch]);

  return { columns, onColumnDragged };
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum DeliveryMessagesSerialized {
  DeliveryMessages = 'deliveryMessages',
}

enum DeliveryMessagesUnserialized {
  DeliveryMessages = 'DeliveryMessages',
}

enum DeliveryMessagesSerializedHeaders {
  id = 'id',
  messageId = 'messageId',
  messageOrderNumber = 'messageOrderNumber',
  associationAssignedCode = 'associationAssignedCode',
  sendMessageDate = 'sendMessageDate',
  senderId = 'senderId',
  messageVersion = 'messageVersion',
  supplierUbn = 'supplierUbn',
  supplierName = 'supplierName',
  supplierAddress = 'supplierAddress',
  supplierPostalCode = 'supplierPostalCode',
  supplierHouseNumber = 'supplierHouseNumber',
  supplierCity = 'supplierCity',
  supplierCountryCode = 'supplierCountryCode',
  supplierPhone = 'supplierPhone',
  supplierEmail = 'supplierEmail',
  supplierUrl = 'supplierUrl',
  supplierVatNumber = 'supplierVatNumber',
  clientUbn = 'clientUbn',
  clientName = 'clientName',
  clientAddress = 'clientAddress',
  clientHouseNumber = 'clientHouseNumber',
  clientPostalCode = 'clientPostalCode',
  clientCity = 'clientCity',
  clientCountryCode = 'clientCountryCode',
}

enum DeliveryMessagesUnserializedHeaders {
  id = 'id',
  messageId = 'message_id',
  messageOrderNumber = 'message_order_number',
  associationAssignedCode = 'association_assigned_code',
  sendMessageDate = 'send_message_date',
  senderId = 'sender_id',
  messageVersion = 'message_version',
  supplierUbn = 'supplier_ubn',
  supplierName = 'supplier_name',
  supplierAddress = 'supplier_address',
  supplierPostalCode = 'supplier_postal_code',
  supplierHouseNumber = 'supplier_house_number',
  supplierCity = 'supplier_city',
  supplierCountryCode = 'supplier_country_code',
  supplierPhone = 'supplier_phone',
  supplierEmail = 'supplier_email',
  supplierUrl = 'supplier_url',
  supplierVatNumber = 'supplier_vat_number',
  clientUbn = 'customer_ubn',
  clientName = 'customer_name',
  clientAddress = 'customer_address',
  clientHouseNumber = 'customer_house_number',
  clientPostalCode = 'customer_postal_code',
  clientCity = 'customer_city',
  clientCountryCode = 'customer_country_code',
}

export const DELIVERY_MESSAGES_FIELDS = {
  [DeliveryMessagesSerialized.DeliveryMessages]: {
    serialized: DeliveryMessagesSerialized.DeliveryMessages,
    unserialized: DeliveryMessagesUnserialized.DeliveryMessages,
    view: IntlKeys.titleDeliveryMessages,
    columns: {
      [DeliveryMessagesSerializedHeaders.id]: {
        serialized: DeliveryMessagesSerializedHeaders.id,
        unserialized: DeliveryMessagesUnserializedHeaders.id,
        view: IntlKeys.serviceBlockchainMessageId,
      },
      [DeliveryMessagesSerializedHeaders.messageOrderNumber]: {
        serialized: DeliveryMessagesSerializedHeaders.messageOrderNumber,
        unserialized: DeliveryMessagesUnserializedHeaders.messageOrderNumber,
        view: IntlKeys.deliveryHeaderUniqueMessageNumber,
      },
      [DeliveryMessagesSerializedHeaders.associationAssignedCode]: {
        serialized: DeliveryMessagesSerializedHeaders.associationAssignedCode,
        unserialized: DeliveryMessagesUnserializedHeaders.associationAssignedCode,
        view: IntlKeys.deliveryHeaderAssociationAssignedCode,
      },
      [DeliveryMessagesSerializedHeaders.sendMessageDate]: {
        serialized: DeliveryMessagesSerializedHeaders.sendMessageDate,
        unserialized: DeliveryMessagesUnserializedHeaders.sendMessageDate,
        view: IntlKeys.deliveryHeaderMessageSentDate,
      },
      [DeliveryMessagesSerializedHeaders.senderId]: {
        serialized: DeliveryMessagesSerializedHeaders.senderId,
        unserialized: DeliveryMessagesUnserializedHeaders.senderId,
        view: IntlKeys.deliveryHeaderSenderId,
      },
      [DeliveryMessagesSerializedHeaders.messageVersion]: {
        serialized: DeliveryMessagesSerializedHeaders.messageVersion,
        unserialized: DeliveryMessagesUnserializedHeaders.messageVersion,
        view: IntlKeys.deliveryHeaderMessageVersionNumber,
      },
      [DeliveryMessagesSerializedHeaders.supplierUbn]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierUbn,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierUbn,
        view: IntlKeys.deliverySupplierNumber,
      },
      [DeliveryMessagesSerializedHeaders.supplierName]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierName,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierName,
        view: IntlKeys.deliverySupplierName,
      },
      [DeliveryMessagesSerializedHeaders.supplierAddress]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierAddress,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierAddress,
        view: IntlKeys.deliverySupplierStreetName,
      },
      [DeliveryMessagesSerializedHeaders.supplierHouseNumber]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierHouseNumber,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierHouseNumber,
        view: IntlKeys.deliverySupplierHouseNumber,
      },
      [DeliveryMessagesSerializedHeaders.supplierPostalCode]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierPostalCode,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierPostalCode,
        view: IntlKeys.deliverySupplierPostalCode,
      },
      [DeliveryMessagesSerializedHeaders.supplierCity]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierCity,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierCity,
        view: IntlKeys.deliverySupplierCityName,
      },
      [DeliveryMessagesSerializedHeaders.supplierCountryCode]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierCountryCode,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierCountryCode,
        view: IntlKeys.deliverySupplierCountry,
      },
      [DeliveryMessagesSerializedHeaders.supplierPhone]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierPhone,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierPhone,
        view: IntlKeys.deliverySupplierPhoneNumber,
      },
      [DeliveryMessagesSerializedHeaders.supplierEmail]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierEmail,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierEmail,
        view: IntlKeys.deliverySupplierEmailAddress,
      },
      [DeliveryMessagesSerializedHeaders.supplierUrl]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierUrl,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierUrl,
        view: IntlKeys.deliverySupplierUrl,
      },
      [DeliveryMessagesSerializedHeaders.supplierVatNumber]: {
        serialized: DeliveryMessagesSerializedHeaders.supplierVatNumber,
        unserialized: DeliveryMessagesUnserializedHeaders.supplierVatNumber,
        view: IntlKeys.deliverySupplierVat,
      },
      [DeliveryMessagesSerializedHeaders.clientUbn]: {
        serialized: DeliveryMessagesSerializedHeaders.clientUbn,
        unserialized: DeliveryMessagesUnserializedHeaders.clientUbn,
        view: IntlKeys.deliveryCustomerNumber,
      },
      [DeliveryMessagesSerializedHeaders.clientName]: {
        serialized: DeliveryMessagesSerializedHeaders.clientName,
        unserialized: DeliveryMessagesUnserializedHeaders.clientName,
        view: IntlKeys.deliveryCustomerName,
      },
      [DeliveryMessagesSerializedHeaders.clientAddress]: {
        serialized: DeliveryMessagesSerializedHeaders.clientAddress,
        unserialized: DeliveryMessagesUnserializedHeaders.clientAddress,
        view: IntlKeys.deliveryCustomerStreetName,
      },
      [DeliveryMessagesSerializedHeaders.clientHouseNumber]: {
        serialized: DeliveryMessagesSerializedHeaders.clientHouseNumber,
        unserialized: DeliveryMessagesUnserializedHeaders.clientHouseNumber,
        view: IntlKeys.deliveryCustomerHouseNumber,
      },
      [DeliveryMessagesSerializedHeaders.clientPostalCode]: {
        serialized: DeliveryMessagesSerializedHeaders.clientPostalCode,
        unserialized: DeliveryMessagesUnserializedHeaders.clientPostalCode,
        view: IntlKeys.deliveryCustomerPostalCode,
      },
      [DeliveryMessagesSerializedHeaders.clientCity]: {
        serialized: DeliveryMessagesSerializedHeaders.clientCity,
        unserialized: DeliveryMessagesUnserializedHeaders.clientCity,
        view: IntlKeys.deliveryCustomerCityName,
      },
      [DeliveryMessagesSerializedHeaders.clientCountryCode]: {
        serialized: DeliveryMessagesSerializedHeaders.clientCountryCode,
        unserialized: DeliveryMessagesUnserializedHeaders.clientCountryCode,
        view: IntlKeys.deliveryCustomerCountry,
      },
      [DeliveryMessagesSerializedHeaders.messageId]: {
        serialized: DeliveryMessagesSerializedHeaders.messageId,
        unserialized: DeliveryMessagesUnserializedHeaders.messageId,
        view: IntlKeys.messageId,
      },
    },
  },
};

import { get, set } from 'lodash';

import { Serializer } from '../index';
import { DocumentData } from './Sheet';

export type ObjectWithProps = { [index: string]: any };

export type PropertyPath = string | string[] | readonly string[];

export type CellAggregationFunction<T = ObjectWithProps> = (
  data: DocumentData<T[]>,
  sheetData: Array<T>,
  excelRow: T,
  propertyOriginalPath: PropertyPath,
  ...args: Array<unknown>
) => unknown;

const AggregationStub = <T = ObjectWithProps>(
  data: DocumentData<T[]>,
  sheetData: Array<T>,
  excelRow: T,
  propertyOriginalName: PropertyPath,
) => {
  return get(excelRow, propertyOriginalName, '');
};

type CellValidationFunction = (value: unknown) => boolean;
const ValidationStub = (value?: unknown) => true;

export class CellSerializer<T = ObjectWithProps, R = T> implements Serializer {
  private value: unknown;

  constructor(
    private readonly originalName: PropertyPath,
    private readonly serializedName: PropertyPath,
    private readonly aggregationFunction: CellAggregationFunction<T> = AggregationStub,
    private readonly validationFunction: CellValidationFunction = ValidationStub,
    private readonly defaultValue: string | null = '',
  ) {}

  private _serialize = (propertyCurrentName: PropertyPath, propertyNewName: PropertyPath) => {
    return (data: DocumentData<T[]>, sheetData: Array<T>, excelRow: T) => {
      this.value = this.aggregationFunction(
        //
        data,
        sheetData,
        excelRow,
        propertyCurrentName,
        propertyNewName,
      );

      return set<R>(
        {},
        propertyNewName,
        // check for falsy value except 0 and boolean
        this.value || this.value === 0 || typeof this.value === 'boolean' ? this.value : this.defaultValue,
      );
    };
  };

  serialize = this._serialize(this.originalName, this.serializedName) as any;
  unserialize = this._serialize(this.serializedName, this.originalName) as any;

  isValid = () => {
    return this.validationFunction(this.value);
  };
}

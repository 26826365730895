// AS_PensLevel
import { getPenLevelDisplayModel } from 'lib/excel/config/referenceTables/penLevel/display';
import { serializePenLevelData } from 'lib/excel/config/referenceTables/penLevel/serialization';
import { getPenLevelDocumentValidator } from 'lib/excel/config/referenceTables/penLevel/validation';
import { getPenLevelView } from 'lib/excel/config/referenceTables/penLevel/view';

// AS_PensTypeSowBarn
import { getPensTypeSowBarnDisplayModel } from 'lib/excel/config/referenceTables/pensTypeSowBarn/display';
import { serializePensTypeSowBarnData } from 'lib/excel/config/referenceTables/pensTypeSowBarn/serialization';
import { getPensTypeSowBarnDocumentValidator } from 'lib/excel/config/referenceTables/pensTypeSowBarn/validation';
import { getPensTypeSowBarnView } from 'lib/excel/config/referenceTables/pensTypeSowBarn/view';

// AS_YesNo
import { getYesNoDisplayModel } from 'lib/excel/config/referenceTables/yesNo/display';
import { serializeYesNoData } from 'lib/excel/config/referenceTables/yesNo/serialization';
import { getYesNoDocumentValidator } from 'lib/excel/config/referenceTables/yesNo/validation';
import { getYesNoView } from 'lib/excel/config/referenceTables/yesNo/view';

// CFP_CO2EmissionFactors
import { getCfpCo2EmissionFactorDisplayModel } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/display';
import { serializeCfpCo2EmissionFactorData } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/serialization';
import { getCfpCo2EmissionFactorDocumentValidator } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/validation';
import { getCfpCo2EmissionFactorView } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/view';

// CFP_CVB_table
import { getCvbTabelDisplayModel } from 'lib/excel/config/referenceTables/cvbTable/display';
import { serializeCvbTableData } from 'lib/excel/config/referenceTables/cvbTable/serialization';
import { getCvbTableDocumentValidator } from 'lib/excel/config/referenceTables/cvbTable/validation';
import { getCvbTableView } from 'lib/excel/config/referenceTables/cvbTable/view';

// CFP_IPCC_Emission_factors_indir
import { getIpccEmissionFactorsDisplayModel } from 'lib/excel/config/referenceTables/ipccEmissionFactors/display';
import { serializeIpccEmissionFactorsData } from 'lib/excel/config/referenceTables/ipccEmissionFactors/serialization';
import { getIpccEmissionFactorsDocumentValidator } from 'lib/excel/config/referenceTables/ipccEmissionFactors/validation';
import { getIpccEmissionFactorsView } from 'lib/excel/config/referenceTables/ipccEmissionFactors/view';

// CFP_GFLI_FeedprintTable
import { getcfpGfliFeedprintTableDisplayModel } from 'lib/excel/config/referenceTables/cfpGfliFeedprintTable/display';
import { serializecfpGfliFeedprintTableData } from 'lib/excel/config/referenceTables/cfpGfliFeedprintTable/serialization';
import { getcfpGfliFeedprintTableDocumentValidator } from 'lib/excel/config/referenceTables/cfpGfliFeedprintTable/validation';
import { getcfpGfliFeedprintTableView } from 'lib/excel/config/referenceTables/cfpGfliFeedprintTable/view';

// CFP_NEMA_Emission_factors_for_other_gaseous_N-losses
import { getContentOfNitricOxideDisplayModel } from 'lib/excel/config/referenceTables/contentOfNitricOxide/display';
import { serializeContentOfNitricOxideData } from 'lib/excel/config/referenceTables/contentOfNitricOxide/serialization';
import { getcontentOfNitricOxideDocumentValidator } from 'lib/excel/config/referenceTables/contentOfNitricOxide/validation';
import { getcontentOfNitricOxideView } from 'lib/excel/config/referenceTables/contentOfNitricOxide/view';

//CFP_NEMA_NH3-emission_factors_for_pig_housing
import { getPigHousingEmissionFactorsDisplayModel } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/display';
import { serializePigHousingEmissionFactorsData } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/serialization';
import { getPigHousingEmissionFactorsDocumentValidator } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/validation';
import { getPigHousingEmissionFactorsView } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/view';

// CFP_NIBUD_electriciteitsverbruik
import { getElectricityConsumptionRatesDisplayModel } from 'lib/excel/config/referenceTables/electricityConsumptionRates/display';
import { serializeElectricityConsumptionRatesData } from 'lib/excel/config/referenceTables/electricityConsumptionRates/serialization';
import { getElectricityConsumptionRatesDocumentValidator } from 'lib/excel/config/referenceTables/electricityConsumptionRates/validation';
import { getElectricityConsumptionRatesView } from 'lib/excel/config/referenceTables/electricityConsumptionRates/view';

// CFP_NIBUD_gasverbruik
import { getGasConsumptionRatesDisplayModel } from 'lib/excel/config/referenceTables/gasConsumptionRates/display';
import { serializeGasConsumptionRatesData } from 'lib/excel/config/referenceTables/gasConsumptionRates/serialization';
import { getGasConsumptionRatesDocumentValidator } from 'lib/excel/config/referenceTables/gasConsumptionRates/validation';
import { getGasConsumptionRatesView } from 'lib/excel/config/referenceTables/gasConsumptionRates/view';

// CFP_NIBUD_waterverbruik
import { getWaterConsumptionRatesDisplayModel } from 'lib/excel/config/referenceTables/waterConsumptionRates/display';
import { serializeWaterConsumptionRatesData } from 'lib/excel/config/referenceTables/waterConsumptionRates/serialization';
import { getWaterConsumptionRatesDocumentValidator } from 'lib/excel/config/referenceTables/waterConsumptionRates/validation';
import { getWaterConsumptionRatesView } from 'lib/excel/config/referenceTables/waterConsumptionRates/view';

// CFP_RAV_codes
import { getRavCodesDisplayModel } from 'lib/excel/config/referenceTables/ravCodes/display';
import { serializeRavCodesData } from 'lib/excel/config/referenceTables/ravCodes/serialization';
import { getRavCodesDocumentValidator } from 'lib/excel/config/referenceTables/ravCodes/validation';
import { getRavCodesView } from 'lib/excel/config/referenceTables/ravCodes/view';

// CFP_WUR_GWP100factor
import { getGwp100FactorsDisplayModel } from 'lib/excel/config/referenceTables/gwp100Factors/display';
import { serializeGwp100FactorsData } from 'lib/excel/config/referenceTables/gwp100Factors/serialization';
import { getGwp100FactorsDocumentValidator } from 'lib/excel/config/referenceTables/gwp100Factors/validation';
import { getGwp100FactorsView } from 'lib/excel/config/referenceTables/gwp100Factors/view';

// CFP_WUR_MCF
import { getWurMfcEfemDisplayModel } from 'lib/excel/config/referenceTables/wurMfcEfem/display';
import { serializeWurMfcEfemData } from 'lib/excel/config/referenceTables/wurMfcEfem/serialization';
import { getWurMfcEfemDocumentValidator } from 'lib/excel/config/referenceTables/wurMfcEfem/validation';
import { getWurMfcEfemView } from 'lib/excel/config/referenceTables/wurMfcEfem/view';

// cl550
import { getCl550DisplayModel } from 'lib/excel/config/referenceTables/cl550/display';
import { serializeCl550Data } from 'lib/excel/config/referenceTables/cl550/serialization';
import { getCl550DocumentValidator } from 'lib/excel/config/referenceTables/cl550/validation';
import { getCl550View } from 'lib/excel/config/referenceTables/cl550/view';

// cl649
import { getCl649DisplayModel } from 'lib/excel/config/referenceTables/cl649/display';
import { serializeCl649Data } from 'lib/excel/config/referenceTables/cl649/serialization';
import { getCl649DocumentValidator } from 'lib/excel/config/referenceTables/cl649/validation';
import { getCl649View } from 'lib/excel/config/referenceTables/cl649/view';

// cl650
import { getCl650DisplayModel } from 'lib/excel/config/referenceTables/cl650/display';
import { serializeCl650Data } from 'lib/excel/config/referenceTables/cl650/serialization';
import { getCl650DocumentValidator } from 'lib/excel/config/referenceTables/cl650/validation';
import { getCl650View } from 'lib/excel/config/referenceTables/cl650/view';

// cl607
import { getCl607DisplayModel } from 'lib/excel/config/referenceTables/cl607/display';
import { serializeCl607Data } from 'lib/excel/config/referenceTables/cl607/serialization';
import { getCl607DocumentValidator } from 'lib/excel/config/referenceTables/cl607/validation';
import { getCl607View } from 'lib/excel/config/referenceTables/cl607/view';

// cl420(suppliers numbers)
import { getSuppliersNumbersDisplayModel } from 'lib/excel/config/referenceTables/suppliersNumbers/display';
import { serializeSuppliersNumbersData } from 'lib/excel/config/referenceTables/suppliersNumbers/serialization';
import { getSuppliersNumbersDocumentValidator } from 'lib/excel/config/referenceTables/suppliersNumbers/validation';
import { getSuppliersNumbersView } from 'lib/excel/config/referenceTables/suppliersNumbers/view';

// ISO 3166 country codes
import { getCountryCodesDisplayModel } from 'lib/excel/config/referenceTables/countryCodes/display';
import { serializeCountryCodesData } from 'lib/excel/config/referenceTables/countryCodes/serialization';
import { getCountryCodesDocumentValidator } from 'lib/excel/config/referenceTables/countryCodes/validation';
import { getCountryCodesView } from 'lib/excel/config/referenceTables/countryCodes/view';

// RAV_Referentielijst
import { getRavReferenceListDisplayModel } from 'lib/excel/config/referenceTables/ravReferenceList/display';
import { serializeRavReferenceListData } from 'lib/excel/config/referenceTables/ravReferenceList/serialization';
import { getRavReferenceListDocumentValidator } from 'lib/excel/config/referenceTables/ravReferenceList/validation';
import { getRavReferenceListView } from 'lib/excel/config/referenceTables/ravReferenceList/view';

// Allocation_Manure
import { getAllocationManureDisplayModel } from 'lib/excel/config/referenceTables/allocationManure/display';
import { serializeAllocationManureData } from 'lib/excel/config/referenceTables/allocationManure/serialization';
import { getAllocationManureDocumentValidator } from 'lib/excel/config/referenceTables/allocationManure/validation';
import { getAllocationManureView } from 'lib/excel/config/referenceTables/allocationManure/view';

// Greenhouse gas emissions
import { getGreenhouseGasEmissionNoDisplayModel } from 'lib/excel/config/referenceTables/greenhouseGasEmission/display';
import { serializeGreenhouseGasEmissionData } from 'lib/excel/config/referenceTables/greenhouseGasEmission/serialization';
import { getGreenhouseGasEmissionDocumentValidator } from 'lib/excel/config/referenceTables/greenhouseGasEmission/validation';
import { getGreenhouseGasEmissionView } from 'lib/excel/config/referenceTables/greenhouseGasEmission/view';

// Entities list
import { getEntitiesListDisplayModel } from 'lib/excel/config/referenceTables/entitiesList/display';
import { serializeEntitiesListData } from 'lib/excel/config/referenceTables/entitiesList/serialization';
import { getEntitiesListDocumentValidator } from 'lib/excel/config/referenceTables/entitiesList/validation';
import { getEntitiesListView } from 'lib/excel/config/referenceTables/entitiesList/view';

// Note: the key is the original value of the file, and proper value gets
// from lib/excel/config/*/structure serialized enum

export const REFERENCE_TABLES_TYPES_KEYS = {
  AS_PensLevel: 'PensLevel',
  AS_PensTypeSowBarn: 'PensTypeSowBarn',
  AS_YesNo: 'YesNo',
  CFP_CO2EmissionFactors: 'CFP_CO2EmissionFactors',
  CFP_CVB_table: 'CvbTable',
  CFP_IPCC_Emission_factors_indir: 'IpccEmissionFactors',
  CFP_GFLI_Feedprint_table: 'CfpGfliFeedprintTable',
  CFP_NEMA_Emission_factors_for_o: 'ContentOfNitricOxide',
  'CFP_NEMA_NH3-emission_factors_f': 'PigHousingEmissionFactors',
  CFP_NIBUD_electriciteitsverbrui: 'ElectricityConsumptionRates',
  CFP_NIBUD_gasverbruik: 'GasConsumptionRates',
  CFP_NIBUD_waterverbruik: 'WaterConsumptionRates',
  CFP_RAV_codes: 'RavCodes',
  CFP_WUR_GWP100factor: 'Gwp100Factors',
  CFP_WUR_MCF: 'WurMfcEfem',
  cl550: 'Cl550',
  cl649: 'Cl649',
  cl650: 'Cl650',
  cl607: 'Cl607',
  cl420: 'SuppliersNumbers',
  'ISO 3166 country codes': 'CountryCodes',
  RAV_Referentielijst: 'RavReferenceList',
  Allocation_Manure: 'AllocationManure',
  'Greenhouse gas emissions': 'GreenhouseGasEmission',
  'Entities list': 'EntitiesList',
} as const;

export enum REFERENCE_TABLES_TYPES {
  penLevel = 'AS_PensLevel',
  pensTypeSowBarn = 'AS_PensTypeSowBarn',
  yesNo = 'AS_YesNo',
  cfpCo2EmissionFactor = 'CFP_CO2EmissionFactors',
  cvbTable = 'CFP_CVB_table',
  ipccEmissionFactors = 'CFP_IPCC_Emission_factors_indir',
  cfpGfliFeedprintTable = 'CFP_GFLI_Feedprint_table',
  contentOfNitricOxide = 'CFP_NEMA_Emission_factors_for_o',
  pigHousingEmissionFactors = 'CFP_NEMA_NH3-emission_factors_f',
  electricityConsumptionRates = 'CFP_NIBUD_electriciteitsverbrui',
  gasConsumptionRates = 'CFP_NIBUD_gasverbruik',
  waterConsumptionRates = 'CFP_NIBUD_waterverbruik',
  ravCodes = 'CFP_RAV_codes',
  gwp100Factors = 'CFP_WUR_GWP100factor',
  wurMfcEfem = 'CFP_WUR_MCF',
  cl550 = 'cl550',
  cl649 = 'cl649',
  cl650 = 'cl650',
  cl607 = 'cl607',
  suppliersNumbers = 'cl420',
  countryCodes = 'ISO 3166 country codes',
  ravReferenceList = 'RAV_Referentielijst',
  allocationManure = 'Allocation_Manure',
  greenhouseGasEmission = 'Greenhouse gas emissions',
  entitiesList = 'Entities list',
}

export const REFERENCE_TABELS_UTILS = {
  [REFERENCE_TABLES_TYPES.penLevel]: {
    displayModel: getPenLevelDisplayModel,
    viewer: getPenLevelView,
    serializer: serializePenLevelData,
    validator: getPenLevelDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.pensTypeSowBarn]: {
    displayModel: getPensTypeSowBarnDisplayModel,
    viewer: getPensTypeSowBarnView,
    serializer: serializePensTypeSowBarnData,
    validator: getPensTypeSowBarnDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.yesNo]: {
    displayModel: getYesNoDisplayModel,
    viewer: getYesNoView,
    serializer: serializeYesNoData,
    validator: getYesNoDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.cfpCo2EmissionFactor]: {
    displayModel: getCfpCo2EmissionFactorDisplayModel,
    viewer: getCfpCo2EmissionFactorView,
    serializer: serializeCfpCo2EmissionFactorData,
    validator: getCfpCo2EmissionFactorDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.cvbTable]: {
    displayModel: getCvbTabelDisplayModel,
    viewer: getCvbTableView,
    serializer: serializeCvbTableData,
    validator: getCvbTableDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.ipccEmissionFactors]: {
    displayModel: getIpccEmissionFactorsDisplayModel,
    viewer: getIpccEmissionFactorsView,
    serializer: serializeIpccEmissionFactorsData,
    validator: getIpccEmissionFactorsDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.cfpGfliFeedprintTable]: {
    displayModel: getcfpGfliFeedprintTableDisplayModel,
    viewer: getcfpGfliFeedprintTableView,
    serializer: serializecfpGfliFeedprintTableData,
    validator: getcfpGfliFeedprintTableDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.contentOfNitricOxide]: {
    displayModel: getContentOfNitricOxideDisplayModel,
    viewer: getcontentOfNitricOxideView,
    serializer: serializeContentOfNitricOxideData,
    validator: getcontentOfNitricOxideDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.pigHousingEmissionFactors]: {
    displayModel: getPigHousingEmissionFactorsDisplayModel,
    viewer: getPigHousingEmissionFactorsView,
    serializer: serializePigHousingEmissionFactorsData,
    validator: getPigHousingEmissionFactorsDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.electricityConsumptionRates]: {
    displayModel: getElectricityConsumptionRatesDisplayModel,
    viewer: getElectricityConsumptionRatesView,
    serializer: serializeElectricityConsumptionRatesData,
    validator: getElectricityConsumptionRatesDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.gasConsumptionRates]: {
    displayModel: getGasConsumptionRatesDisplayModel,
    viewer: getGasConsumptionRatesView,
    serializer: serializeGasConsumptionRatesData,
    validator: getGasConsumptionRatesDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.waterConsumptionRates]: {
    displayModel: getWaterConsumptionRatesDisplayModel,
    viewer: getWaterConsumptionRatesView,
    serializer: serializeWaterConsumptionRatesData,
    validator: getWaterConsumptionRatesDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.ravCodes]: {
    displayModel: getRavCodesDisplayModel,
    viewer: getRavCodesView,
    serializer: serializeRavCodesData,
    validator: getRavCodesDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.gwp100Factors]: {
    displayModel: getGwp100FactorsDisplayModel,
    viewer: getGwp100FactorsView,
    serializer: serializeGwp100FactorsData,
    validator: getGwp100FactorsDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.wurMfcEfem]: {
    displayModel: getWurMfcEfemDisplayModel,
    viewer: getWurMfcEfemView,
    serializer: serializeWurMfcEfemData,
    validator: getWurMfcEfemDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.cl550]: {
    displayModel: getCl550DisplayModel,
    viewer: getCl550View,
    serializer: serializeCl550Data,
    validator: getCl550DocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.cl649]: {
    displayModel: getCl649DisplayModel,
    viewer: getCl649View,
    serializer: serializeCl649Data,
    validator: getCl649DocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.cl650]: {
    displayModel: getCl650DisplayModel,
    viewer: getCl650View,
    serializer: serializeCl650Data,
    validator: getCl650DocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.cl607]: {
    displayModel: getCl607DisplayModel,
    viewer: getCl607View,
    serializer: serializeCl607Data,
    validator: getCl607DocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.suppliersNumbers]: {
    displayModel: getSuppliersNumbersDisplayModel,
    viewer: getSuppliersNumbersView,
    serializer: serializeSuppliersNumbersData,
    validator: getSuppliersNumbersDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.countryCodes]: {
    displayModel: getCountryCodesDisplayModel,
    viewer: getCountryCodesView,
    serializer: serializeCountryCodesData,
    validator: getCountryCodesDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.ravReferenceList]: {
    displayModel: getRavReferenceListDisplayModel,
    viewer: getRavReferenceListView,
    serializer: serializeRavReferenceListData,
    validator: getRavReferenceListDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.allocationManure]: {
    displayModel: getAllocationManureDisplayModel,
    viewer: getAllocationManureView,
    serializer: serializeAllocationManureData,
    validator: getAllocationManureDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.greenhouseGasEmission]: {
    displayModel: getGreenhouseGasEmissionNoDisplayModel,
    viewer: getGreenhouseGasEmissionView,
    serializer: serializeGreenhouseGasEmissionData,
    validator: getGreenhouseGasEmissionDocumentValidator,
  },
  [REFERENCE_TABLES_TYPES.entitiesList]: {
    displayModel: getEntitiesListDisplayModel,
    viewer: getEntitiesListView,
    serializer: serializeEntitiesListData,
    validator: getEntitiesListDocumentValidator,
  },
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum IpccEmissionFactorsOriginal {
  IpccEmissionFactors = 'CFP_IPCC_Emission_factors_indir',
}

enum IpccEmissionFactorsSerialized {
  IpccEmissionFactors = 'IpccEmissionFactors',
}

enum IpccEmissionFactorsUnserialized {
  IpccEmissionFactors = 'IpccEmissionFactors',
}

enum IpccEmissionFactorsOriginalHeaders {
  'factor' = 'Factor',
  'description' = 'Description',
  'defaultValue' = 'Default value',
  'uncertaintyRange' = 'Uncertainty range',
}

enum IpccEmissionFactorsSerializedHeaders {
  'factor' = 'factor',
  'description' = 'description',
  'defaultValue' = 'defaultValue',
  'uncertaintyRange' = 'uncertaintyRange',
}
enum IpccEmissionFactorsUnserializedHeaders {
  'factor' = 'factor',
  'description' = 'description',
  'defaultValue' = 'default_value',
  'uncertaintyRange' = 'uncertainty_range',
}

export const IPCC_EMISSION_FACTORS_FIELDS = {
  [IpccEmissionFactorsSerialized.IpccEmissionFactors]: {
    original: IpccEmissionFactorsOriginal.IpccEmissionFactors,
    serialized: IpccEmissionFactorsSerialized.IpccEmissionFactors,
    unserialized: IpccEmissionFactorsUnserialized.IpccEmissionFactors,
    view: IntlKeys.referenceTablesIpccEmissionFactors,
    viewName: 'CFP_IPCC_Emission_Factors_Indir',

    columns: {
      [IpccEmissionFactorsSerializedHeaders.factor]: {
        original: IpccEmissionFactorsOriginalHeaders.factor,
        serialized: IpccEmissionFactorsSerializedHeaders.factor,
        unserialized: IpccEmissionFactorsUnserializedHeaders.factor,
        view: IntlKeys.referenceTablesFactor,
      },
      [IpccEmissionFactorsSerializedHeaders.description]: {
        original: IpccEmissionFactorsOriginalHeaders.description,
        serialized: IpccEmissionFactorsSerializedHeaders.description,
        unserialized: IpccEmissionFactorsUnserializedHeaders.description,
        view: IntlKeys.referenceTablesDescription,
      },
      [IpccEmissionFactorsSerializedHeaders.defaultValue]: {
        original: IpccEmissionFactorsOriginalHeaders.defaultValue,
        serialized: IpccEmissionFactorsSerializedHeaders.defaultValue,
        unserialized: IpccEmissionFactorsUnserializedHeaders.defaultValue,
        view: IntlKeys.referenceTablesDefaultValue,
      },
      [IpccEmissionFactorsSerializedHeaders.uncertaintyRange]: {
        original: IpccEmissionFactorsOriginalHeaders.uncertaintyRange,
        serialized: IpccEmissionFactorsSerializedHeaders.uncertaintyRange,
        unserialized: IpccEmissionFactorsUnserializedHeaders.uncertaintyRange,
        view: IntlKeys.referenceTablesUncertaintyRange,
      },
    },
  },
};

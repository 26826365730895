import { IntlKeys } from 'lib/localization/keys';

enum GroupPurchasedFoodSerialized {
  GroupPurchasedFood = 'GroupPurchasedFood',
}

enum GroupPurchasedFoodUnserialized {
  GroupPurchasedFood = 'GroupPurchasedFood',
}

export enum GroupPurchasedFoodSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  finEventIdentifier = 'finEventIdentifier',
  sows = 'sows',
  finishers = 'finishers',
  gilts = 'gilts',
  weaners = 'weaners',
  totalWeight = 'totalWeight',
  eV = 'eV',
  dM = 'dM',
  purchasedFoodDatetime = 'purchasedFoodDatetime',
  acctIdentifier = 'acctIdentifier',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupPurchasedFoodUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  finEventIdentifier: 'financial_event_identifier',
  sows: 'sows',
  finishers: 'finishers',
  gilts: 'gilts',
  weaners: 'weaners',
  totalWeight: 'total_weight',
  eV: 'ev',
  dM: 'dm',
  purchasedFoodDatetime: 'purchased_food_datetime',
  acctIdentifier: 'acct_identifier',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_PURCHASED_FOOD = {
  [GroupPurchasedFoodSerialized.GroupPurchasedFood]: {
    serialized: GroupPurchasedFoodSerialized.GroupPurchasedFood,
    unserialized: GroupPurchasedFoodUnserialized.GroupPurchasedFood,
    view: IntlKeys.farmManagementDataPurchasedFoodList,

    columns: {
      [GroupPurchasedFoodSerializedHeaders.id]: {
        serialized: GroupPurchasedFoodSerializedHeaders.id,
        unserialized: GroupPurchasedFoodUnserializedHeaders.id,
      },
      [GroupPurchasedFoodSerializedHeaders.companyIdentifier]: {
        serialized: GroupPurchasedFoodSerializedHeaders.companyIdentifier,
        unserialized: GroupPurchasedFoodUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupPurchasedFoodSerializedHeaders.farmIdentifier]: {
        serialized: GroupPurchasedFoodSerializedHeaders.farmIdentifier,
        unserialized: GroupPurchasedFoodUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupPurchasedFoodSerializedHeaders.finEventIdentifier]: {
        serialized: GroupPurchasedFoodSerializedHeaders.finEventIdentifier,
        unserialized: GroupPurchasedFoodUnserializedHeaders.finEventIdentifier,
        view: IntlKeys.farmManagementDataFinEventId,
      },
      [GroupPurchasedFoodSerializedHeaders.sows]: {
        serialized: GroupPurchasedFoodSerializedHeaders.sows,
        unserialized: GroupPurchasedFoodUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataSows,
      },
      [GroupPurchasedFoodSerializedHeaders.finishers]: {
        serialized: GroupPurchasedFoodSerializedHeaders.finishers,
        unserialized: GroupPurchasedFoodUnserializedHeaders.finishers,
        view: IntlKeys.farmManagementDataFinishers,
      },
      [GroupPurchasedFoodSerializedHeaders.gilts]: {
        serialized: GroupPurchasedFoodSerializedHeaders.gilts,
        unserialized: GroupPurchasedFoodUnserializedHeaders.gilts,
        view: IntlKeys.farmManagementDataGilts,
      },
      [GroupPurchasedFoodSerializedHeaders.weaners]: {
        serialized: GroupPurchasedFoodSerializedHeaders.weaners,
        unserialized: GroupPurchasedFoodUnserializedHeaders.weaners,
        view: IntlKeys.farmManagementDataWeaners,
      },
      [GroupPurchasedFoodSerializedHeaders.totalWeight]: {
        serialized: GroupPurchasedFoodSerializedHeaders.totalWeight,
        unserialized: GroupPurchasedFoodUnserializedHeaders.totalWeight,
        view: IntlKeys.farmManagementDataTotalWeight,
      },
      [GroupPurchasedFoodSerializedHeaders.eV]: {
        serialized: GroupPurchasedFoodSerializedHeaders.eV,
        unserialized: GroupPurchasedFoodUnserializedHeaders.eV,
        view: IntlKeys.farmManagementDataEv,
      },
      [GroupPurchasedFoodSerializedHeaders.dM]: {
        serialized: GroupPurchasedFoodSerializedHeaders.dM,
        unserialized: GroupPurchasedFoodUnserializedHeaders.dM,
        view: IntlKeys.farmManagementDataDm,
      },
      [GroupPurchasedFoodSerializedHeaders.purchasedFoodDatetime]: {
        serialized: GroupPurchasedFoodSerializedHeaders.purchasedFoodDatetime,
        unserialized: GroupPurchasedFoodUnserializedHeaders.purchasedFoodDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupPurchasedFoodSerializedHeaders.acctIdentifier]: {
        serialized: GroupPurchasedFoodSerializedHeaders.acctIdentifier,
        unserialized: GroupPurchasedFoodUnserializedHeaders.acctIdentifier,
        view: IntlKeys.farmManagementDataAcctId,
      },
      [GroupPurchasedFoodSerializedHeaders.ubnList]: {
        serialized: GroupPurchasedFoodSerializedHeaders.ubnList,
        unserialized: GroupPurchasedFoodUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupPurchasedFoodSerializedHeaders.kvkList]: {
        serialized: GroupPurchasedFoodSerializedHeaders.kvkList,
        unserialized: GroupPurchasedFoodUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupPurchasedFoodSerializedHeaders.registrationNameList]: {
        serialized: GroupPurchasedFoodSerializedHeaders.registrationNameList,
        unserialized: GroupPurchasedFoodUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { PIG_HOUSING_EMISSION_FACTORS } from 'lib/excel/config/referenceTables/pigHousingEmissionFactors/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = PIG_HOUSING_EMISSION_FACTORS.PigHousingEmissionFactors;

export const getPigHousingEmissionFactorsDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.livestockCategory.original),
      new CellValidator(columns.housingSystem.original),
      new CellValidator(columns.yearValue.original),
      new CellValidator(columns.emissionValue.original),
      new CellValidator(columns.unit.original),
    ]),
  ]);
};

import theme from 'theme';
import { Options } from 'material-table';

type StyleOptions = Pick<
  Options<object>,
  | 'actionsCellStyle'
  | 'detailPanelColumnStyle'
  | 'editCellStyle'
  | 'filterCellStyle'
  | 'filterRowStyle'
  | 'headerStyle'
  | 'rowStyle'
  | 'searchFieldStyle'
>;

export const stylesOptions: StyleOptions = {
  headerStyle: {
    borderBottom: `1px solid ${theme.colors.border.grey}`,
  },
  detailPanelColumnStyle: {
    borderBottom: `1px solid ${theme.colors.border.grey}`,
  },
};

import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  Modal as MaterialModal,
  ModalProps,
  Fade,
  Button,
  Paper,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import cx from 'clsx';

import { IntlKeys } from 'lib/localization/keys';

import { Check, Close as CloseIcon, ErrorOutline } from '@material-ui/icons';

interface OwnProps {
  isOpen: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  isFailure?: boolean;
  onSubmitClose?: () => void;
  closeModal: () => void;
  className?: string;
  classes?: Record<'wrapper' | 'content', string>;
  BackdropProps?: ModalProps['BackdropProps'];
  successMessage?: string;
  errorMessage?: string;
  children: React.ReactNode;
  title?: React.ReactNode;
}

const Modal: React.FC<OwnProps> = ({
  isOpen,
  isLoading,
  isSuccess,
  isFailure,
  onSubmitClose,
  closeModal,
  className,
  classes,
  BackdropProps = {},
  successMessage,
  errorMessage,
  children,
  title,
}) => {
  const localClasses = useStyles();
  const { formatMessage } = useIntl();

  const isMainContentHidden = isLoading || isSuccess || isFailure;

  const handleSubmitClose = useCallback(() => {
    if (onSubmitClose) onSubmitClose();

    closeModal();
  }, [onSubmitClose, closeModal]);

  return (
    <MaterialModal
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 300,
        ...BackdropProps,
      }}
      className={localClasses.modal}
    >
      <Fade in={isOpen} mountOnEnter={true} unmountOnExit={true}>
        <ContentWrapper className={classes?.wrapper}>
          <div className={localClasses.header}>
            {!!title && (
              <Typography variant="h6" className={localClasses.headerTitle}>
                {title}
              </Typography>
            )}

            <Button type="button" className={localClasses.closeButton} onClick={closeModal}>
              <CloseIcon />
            </Button>
          </div>

          <div className={cx(className, classes?.content, { [localClasses.faded]: isMainContentHidden })}>
            {children}
          </div>

          {isMainContentHidden && (
            <div className={localClasses.fullSpace}>
              {isLoading ? (
                <div className={cx(localClasses.loader, localClasses.centered)}>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                (isSuccess || isFailure) && (
                  <div className={cx(localClasses.messageContainer, localClasses.centered)}>
                    {isFailure ? (
                      // case FAILURE
                      <div className={cx(localClasses.messageBox, localClasses.centered)}>
                        <ErrorOutline className={localClasses.errorIcon} />
                        <p className={localClasses.messageMain}>{formatMessage({ id: IntlKeys.error })}</p>
                        <p className={localClasses.messageSecondary}>
                          {errorMessage || formatMessage({ id: IntlKeys.genericErrorMsg })}
                        </p>
                      </div>
                    ) : (
                      // case SUCCESS
                      <div className={cx(localClasses.messageBox, localClasses.centered)}>
                        <Check className={localClasses.checkedIcon} />
                        <p className={localClasses.messageMain}>{formatMessage({ id: IntlKeys.success })}</p>
                        <p className={localClasses.messageSecondary}>
                          {successMessage || formatMessage({ id: IntlKeys.genericSuccessMsg })}
                        </p>
                      </div>
                    )}

                    <Button onClick={handleSubmitClose} variant="contained" color="primary">
                      {formatMessage({ id: IntlKeys.close })}
                    </Button>
                  </div>
                )
              )}
            </div>
          )}
        </ContentWrapper>
      </Fade>
    </MaterialModal>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    lineHeight: '28px',
    fontWeight: 'normal',
  },
  faded: {
    visibility: 'hidden',
  },
  fullSpace: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.2)',
  },
  messageContainer: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',

    '& > *': {
      margin: '15px 0',
    },
  },
  messageBox: {
    flexDirection: 'column',
    height: 'calc(100% - 100px)',
    minHeight: 100,
  },
  messageMain: {
    margin: '10px 0 15px',
    fontSize: 18,
    fontWeight: 'bold',
  },
  messageSecondary: {
    fontStyle: 'italic',
  },
  checkedIcon: {
    color: 'green',
  },
  errorIcon: {
    color: 'red',
  },
  closeButton: {
    padding: 0,
    width: 32,
    minWidth: 28,
    height: 32,
    minHeight: 28,
    marginLeft: 'auto',
  },
}));

const ContentWrapper = styled(Paper)`
  position: relative;
  min-width: 350px;
  padding: 16px;
`;

export default React.memo(Modal);

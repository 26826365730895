import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';

export const slaughterChargeOrPremiums = new Route(
  'get',
  new RouteParser('/mock/slaughter_price_corrections/:id/slaughter_charge_or_premiums'),
  () => {
    return [
      200,
      {
        resources: [
          {
            id: 1,
            code_list_id: '4tre4e',
            code: 'qjg',
            base_code: 'gix',
            amount: 851.0,
            vat_percentage: 30.0,
            vat_amount: 467.0,
            created_at: '2021-01-15T07:10:16.914Z',
            updated_at: '2021-01-15T07:10:16.914Z',
          },
          {
            id: 2,
            code_list_id: 'vhmyne',
            code: 's1k',
            base_code: 'ct5',
            amount: 44.0,
            vat_percentage: 20.0,
            vat_amount: 884.0,
            created_at: '2021-01-15T07:10:16.920Z',
            updated_at: '2021-01-15T07:10:16.920Z',
          },
        ],
      },
    ];
  },
);

import { IntlKeys } from 'lib/localization/keys';

enum GroupUsedFoodSerialized {
  GroupUsedFood = 'GroupUsedFood',
}

enum CodesUsedFoodUnserialized {
  GroupUsedFood = 'GroupUsedFood',
}

export enum GroupUsedFoodSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  finEventIdentifier = 'finEventIdentifier',
  sows = 'sows',
  finishers = 'finishers',
  gilts = 'gilts',
  weaners = 'weaners',
  totalWeight = 'totalWeight',
  eV = 'eV',
  dM = 'dM',
  usedFoodDateTime = 'usedFoodDateTime',
  acctIdentifier = 'acctIdentifier',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const GroupUsedFoodUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  finEventIdentifier: 'financial_event_identifier',
  sows: 'sows',
  finishers: 'finishers',
  gilts: 'gilts',
  weaners: 'weaners',
  totalWeight: 'total_weight',
  eV: 'ev',
  dM: 'dm',
  usedFoodDateTime: 'used_food_datetime',
  acctIdentifier: 'acct_identifier',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const GROUP_USED_FOOD = {
  [GroupUsedFoodSerialized.GroupUsedFood]: {
    serialized: GroupUsedFoodSerialized.GroupUsedFood,
    unserialized: CodesUsedFoodUnserialized.GroupUsedFood,
    view: IntlKeys.farmManagementDataUsedFoodList,

    columns: {
      [GroupUsedFoodSerializedHeaders.id]: {
        serialized: GroupUsedFoodSerializedHeaders.id,
        unserialized: GroupUsedFoodUnserializedHeaders.id,
      },
      [GroupUsedFoodSerializedHeaders.companyIdentifier]: {
        serialized: GroupUsedFoodSerializedHeaders.companyIdentifier,
        unserialized: GroupUsedFoodUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupUsedFoodSerializedHeaders.farmIdentifier]: {
        serialized: GroupUsedFoodSerializedHeaders.farmIdentifier,
        unserialized: GroupUsedFoodUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupUsedFoodSerializedHeaders.finEventIdentifier]: {
        serialized: GroupUsedFoodSerializedHeaders.finEventIdentifier,
        unserialized: GroupUsedFoodUnserializedHeaders.finEventIdentifier,
        view: IntlKeys.farmManagementDataFinEventId,
      },
      [GroupUsedFoodSerializedHeaders.sows]: {
        serialized: GroupUsedFoodSerializedHeaders.sows,
        unserialized: GroupUsedFoodUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataSows,
      },
      [GroupUsedFoodSerializedHeaders.finishers]: {
        serialized: GroupUsedFoodSerializedHeaders.finishers,
        unserialized: GroupUsedFoodUnserializedHeaders.finishers,
        view: IntlKeys.farmManagementDataFinishers,
      },
      [GroupUsedFoodSerializedHeaders.gilts]: {
        serialized: GroupUsedFoodSerializedHeaders.gilts,
        unserialized: GroupUsedFoodUnserializedHeaders.gilts,
        view: IntlKeys.farmManagementDataGilts,
      },
      [GroupUsedFoodSerializedHeaders.weaners]: {
        serialized: GroupUsedFoodSerializedHeaders.weaners,
        unserialized: GroupUsedFoodUnserializedHeaders.weaners,
        view: IntlKeys.farmManagementDataWeaners,
      },
      [GroupUsedFoodSerializedHeaders.totalWeight]: {
        serialized: GroupUsedFoodSerializedHeaders.totalWeight,
        unserialized: GroupUsedFoodUnserializedHeaders.totalWeight,
        view: IntlKeys.farmManagementDataTotalWeight,
      },
      [GroupUsedFoodSerializedHeaders.eV]: {
        serialized: GroupUsedFoodSerializedHeaders.eV,
        unserialized: GroupUsedFoodUnserializedHeaders.eV,
        view: IntlKeys.farmManagementDataEv,
      },
      [GroupUsedFoodSerializedHeaders.dM]: {
        serialized: GroupUsedFoodSerializedHeaders.dM,
        unserialized: GroupUsedFoodUnserializedHeaders.dM,
        view: IntlKeys.farmManagementDataDm,
      },
      [GroupUsedFoodSerializedHeaders.usedFoodDateTime]: {
        serialized: GroupUsedFoodSerializedHeaders.usedFoodDateTime,
        unserialized: GroupUsedFoodUnserializedHeaders.usedFoodDateTime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupUsedFoodSerializedHeaders.acctIdentifier]: {
        serialized: GroupUsedFoodSerializedHeaders.acctIdentifier,
        unserialized: GroupUsedFoodUnserializedHeaders.acctIdentifier,
        view: IntlKeys.farmManagementDataAcctId,
      },
      [GroupUsedFoodSerializedHeaders.ubnList]: {
        serialized: GroupUsedFoodSerializedHeaders.ubnList,
        unserialized: GroupUsedFoodUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [GroupUsedFoodSerializedHeaders.kvkList]: {
        serialized: GroupUsedFoodSerializedHeaders.kvkList,
        unserialized: GroupUsedFoodUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [GroupUsedFoodSerializedHeaders.registrationNameList]: {
        serialized: GroupUsedFoodSerializedHeaders.registrationNameList,
        unserialized: GroupUsedFoodUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import { Method } from 'lib/http/ApiClient';
import { ApiCall } from './http/utils';
import { CustomAction, Deferred } from 'lib/actions';
import toast from 'lib/toast';
import { ClientStorage, PREF_LANG } from "./storage/Storage";

export type Params = {
  params?: {
    [T: string]: string | number | Params;
  };

  [T: string]: any;
};

export interface Callbacks {
  onSuccess?: (result: { requestPayload: object; payload: object; meta: object }) => void;
  onFailure?: (result: { requestPayload: object; payload: object }) => void;
}

export interface ApiCallTransformers {
  transformRequestData?: (data: any, method?: Method) => object;
  serializeSuccessPayload?: (...args: any[]) => any;
  serializeFailurePayload?: (...args: any[]) => any;
}

interface Config extends ApiCallTransformers {
  api: ApiCall;
  action: {
    success: string;
    failure: string;
    deferred: Deferred;
  };
  responseConfigData?: (
    data: any,
  ) => {
    saveConfigData: boolean;
    resConfigData: string;
  };
  showToast?: boolean;
}

export function wrapIntoAxiosStructure({ data, method }: { data?: object; method?: Method } = { method: 'get' }) {
  switch (method) {
    case 'put':
    case 'post': {
      return { data };
    }
    case 'get':
    default:
      return { params: data };
  }
}

type PayloadType = Params & { meta?: Record<string, unknown> } & Callbacks;

const unboxPayload = (obj: CustomAction<PayloadType> | PayloadType) => {
  if (obj.type && obj.payload) {
    // if it has type and payload then its probably action
    return obj.payload;
  }

  return obj;
};

/* *
 * Function helper ensure
 * @function name ensure;
 * @param api {function} method Api
 * @param action {object} contain string params success ans failure
 * @param serializer {function} serialize function
 * @param fieldToSerialize {string} name of params field to add to serialize function args
 * */
const ensure = function ({
  api,
  action: actionCreator,
  transformRequestData = (d) => d,
  serializeSuccessPayload = (r) => r.data['resource'] || r.data['resources'] || r.data,
  serializeFailurePayload = (axiosError) => axiosError,
  responseConfigData = (d) => ({
    saveConfigData: false,
    resConfigData: JSON.stringify(d),
  }),
  showToast = true,
}: Config) {
  const _serializeFailurePayload: typeof serializeFailurePayload = (...args) => {
    try {
      return serializeFailurePayload(...args);
    } catch (e) {
      return 'Something went wrong...';
    }
  };

  return function* (_payload: PayloadType = {}) {
    const payload = unboxPayload(_payload);
    const {
      params = {},
      meta = {},
      onSuccess,
      onFailure,
      headers,
      baseURL,
      serializeSuccessPayload: serializeSuccess,
      type, // if you remove this, it'd be wrapped in axios request as query string param
      ...args
    } = payload;

    try {
      const requestConfig = wrapIntoAxiosStructure({
        data: transformRequestData(args),
        method: api.method,
      });

      const response = yield call(api, {
        baseURL,
        urlParams: params,
        headers,
        ...requestConfig,
      });

      const serializedResponse = serializeSuccess
        ? serializeSuccess(response, payload)
        : serializeSuccessPayload(response, payload);

      const result = {
        payload: serializedResponse,
        meta: { ...meta, ...response.data.meta },
        requestPayload: payload,
      };

      yield put({
        type: actionCreator.success,
        ...result,
      });

      actionCreator.deferred.resolve(result);

      if (typeof onSuccess === 'function') {
        yield call(onSuccess, result);
      }

      return result;
    } catch (error: any) {
      console.error(`Error occurred`, error);

      let catchedError = error;

      const { saveConfigData, resConfigData } = responseConfigData(args);

      if (saveConfigData && resConfigData) {
        const errorWithConfigData = {
          ...error,
          response: {
            ...error?.response,
            data: { ...error?.response?.data, record_errors: error?.response?.data?.errors, errors: undefined },
            config: { ...error?.response?.config, data: resConfigData },
          },
        };
        catchedError = errorWithConfigData;
      }

      const serializedResponse = _serializeFailurePayload(catchedError);

      const result = {
        payload: serializedResponse,
        requestPayload: payload,
      };

      yield put({
        type: actionCreator.failure,
        ...result,
      });

      if (error.response?.status !== 401) {
        if (error.response?.status === 403) {
          const prefLang = ClientStorage.getItem(PREF_LANG);
          const forbiddenMessage = prefLang === 'nl'
            ? 'Ongeautoriseerd, neem contact op met de beheerder'
            : 'Unauthorized, contact administrator';
          toast.error(forbiddenMessage);
        } else {
          if (showToast) {
            toast.error(`${catchedError}`);
          }
        }
      }

      actionCreator.deferred.reject(result);

      if (typeof onFailure === 'function') {
        yield call(onFailure, result);
      }

      return result;

      // throw error;
    }
  };
};

export type Ensure = ReturnType<typeof ensure>;

export default ensure;

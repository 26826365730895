import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import { COUNTRY_CODES } from 'store/entities/referenceTables/countryCodes/config';

const { columns, view, serialized } = COUNTRY_CODES.CountryCodes;

export const countryCodesTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.iso31661Code.view,
          field: columns.iso31661Code.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.subdivisionName.view,
          field: columns.subdivisionName.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.iso31662Code.view,
          field: columns.iso31662Code.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.countryName.view,
          field: columns.countryName.serialized,
        },
      }),
    ],
  }),
]);

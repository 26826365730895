import { IntlKeys } from 'lib/localization/keys';

enum CodesAccountSerialized {
  CodesAccount = 'CodesAccount',
}

enum CodesAccountUnserialized {
  CodesAccount = 'CodesAccount',
}

export enum CodesAccountSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  accountIdentifier = 'accountIdentifier',
  code = 'code',
  abbreviation = 'abbreviation',
  description = 'description',
  contactIdentifier = 'contactIdentifier',
  percentSows = 'percentSows',
  percentWeaners = 'percentWeaners',
  percentFinishers = 'percentFinishers',
  percentReplGilts = 'percentReplGilts',
  typeIdentifier = 'typeIdentifier',
  active = 'active',
  accountDatetime = 'accountDatetime',
  evType = 'evType',
  vatIdentifier = 'vatIdentifier',
  barn = 'barn',
  medicine = 'medicine',
  inoculation = 'inoculation',
  hormone = 'hormone',
  medicineEanCode = 'medicineEanCode',
  medicineCountryCode = 'medicineCountryCode',
  medicineOfficialName = 'medicineOfficialName',
  medicineDateEnd = 'medicineDateEnd',
  medicineUnit = 'medicineUnit',
  ev = 'ev',
  medicineWhPeriod = 'medicineWhPeriod',
  medicineDosage = 'medicineDosage',
  medicineDosageFactor = 'medicineDosageFactor',
  supplierIdentifier = 'supplierIdentifier',
  medicineEmbCode = 'medicineEmbCode',
}

export const CodesAccountUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  accountIdentifier: 'account_identifier',
  code: 'code',
  abbreviation: 'abbreviation',
  description: 'description',
  contactIdentifier: 'contact_identifier',
  percentSows: 'percent_sows',
  percentWeaners: 'percent_weaners',
  percentFinishers: 'percent_finishers',
  percentReplGilts: 'percent_repl_gilts',
  typeIdentifier: 'type_identifier',
  active: 'active',
  accountDatetime: 'account_datetime',
  evType: 'ev_type',
  vatIdentifier: 'vat_identifier',
  barn: 'barn',
  medicine: 'medicine',
  inoculation: 'inoculation',
  hormone: 'hormone',
  medicineEanCode: 'medicine_ean_code',
  medicineCountryCode: 'medicine_country_code',
  medicineOfficialName: 'medicine_official_name',
  medicineDateEnd: 'medicine_date_end',
  medicineUnit: 'medicine_unit',
  ev: 'ev',
  medicineWhPeriod: 'medicine_wh_period',
  medicineDosage: 'medicine_dosage',
  medicineDosageFactor: 'medicine_dosage_factor',
  supplierIdentifier: 'supplier_identifier',
  medicineEmbCode: 'medicine_emb_code',
} as const;

export const CODES_ACCOUNT = {
  [CodesAccountSerialized.CodesAccount]: {
    serialized: CodesAccountSerialized.CodesAccount,
    unserialized: CodesAccountUnserialized.CodesAccount,
    view: IntlKeys.farmManagementDataAccountList,

    columns: {
      [CodesAccountSerializedHeaders.id]: {
        serialized: CodesAccountSerializedHeaders.id,
        unserialized: CodesAccountUnserializedHeaders.id,
      },
      [CodesAccountSerializedHeaders.companyIdentifier]: {
        serialized: CodesAccountSerializedHeaders.companyIdentifier,
        unserialized: CodesAccountUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesAccountSerializedHeaders.accountIdentifier]: {
        serialized: CodesAccountSerializedHeaders.accountIdentifier,
        unserialized: CodesAccountUnserializedHeaders.accountIdentifier,
        view: IntlKeys.farmManagementDataAcctId,
      },
      [CodesAccountSerializedHeaders.code]: {
        serialized: CodesAccountSerializedHeaders.code,
        unserialized: CodesAccountUnserializedHeaders.code,
        view: IntlKeys.farmManagementDataCode,
      },
      [CodesAccountSerializedHeaders.abbreviation]: {
        serialized: CodesAccountSerializedHeaders.abbreviation,
        unserialized: CodesAccountUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesAccountSerializedHeaders.description]: {
        serialized: CodesAccountSerializedHeaders.description,
        unserialized: CodesAccountUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesAccountSerializedHeaders.contactIdentifier]: {
        serialized: CodesAccountSerializedHeaders.contactIdentifier,
        unserialized: CodesAccountUnserializedHeaders.contactIdentifier,
        view: IntlKeys.farmManagementDataContactId,
      },
      [CodesAccountSerializedHeaders.percentSows]: {
        serialized: CodesAccountSerializedHeaders.percentSows,
        unserialized: CodesAccountUnserializedHeaders.percentSows,
        view: IntlKeys.farmManagementDataPercentSows,
      },
      [CodesAccountSerializedHeaders.percentWeaners]: {
        serialized: CodesAccountSerializedHeaders.percentWeaners,
        unserialized: CodesAccountUnserializedHeaders.percentWeaners,
        view: IntlKeys.farmManagementDataPercentWeaners,
      },
      [CodesAccountSerializedHeaders.percentFinishers]: {
        serialized: CodesAccountSerializedHeaders.percentFinishers,
        unserialized: CodesAccountUnserializedHeaders.percentFinishers,
        view: IntlKeys.farmManagementDataPercentFinishers,
      },
      [CodesAccountSerializedHeaders.percentReplGilts]: {
        serialized: CodesAccountSerializedHeaders.percentReplGilts,
        unserialized: CodesAccountUnserializedHeaders.percentReplGilts,
        view: IntlKeys.farmManagementDataPercentReplGilts,
      },
      [CodesAccountSerializedHeaders.typeIdentifier]: {
        serialized: CodesAccountSerializedHeaders.typeIdentifier,
        unserialized: CodesAccountUnserializedHeaders.typeIdentifier,
        view: IntlKeys.farmManagementDataTypeId,
      },
      [CodesAccountSerializedHeaders.active]: {
        serialized: CodesAccountSerializedHeaders.active,
        unserialized: CodesAccountUnserializedHeaders.active,
        view: IntlKeys.farmManagementDataActive,
      },
      [CodesAccountSerializedHeaders.accountDatetime]: {
        serialized: CodesAccountSerializedHeaders.accountDatetime,
        unserialized: CodesAccountUnserializedHeaders.accountDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesAccountSerializedHeaders.evType]: {
        serialized: CodesAccountSerializedHeaders.evType,
        unserialized: CodesAccountUnserializedHeaders.evType,
        view: IntlKeys.farmManagementDataEvType,
      },
      [CodesAccountSerializedHeaders.vatIdentifier]: {
        serialized: CodesAccountSerializedHeaders.vatIdentifier,
        unserialized: CodesAccountUnserializedHeaders.vatIdentifier,
        view: IntlKeys.farmManagementDataVatId,
      },
      [CodesAccountSerializedHeaders.barn]: {
        serialized: CodesAccountSerializedHeaders.barn,
        unserialized: CodesAccountUnserializedHeaders.barn,
        view: IntlKeys.farmManagementDataBarn,
      },
      [CodesAccountSerializedHeaders.medicine]: {
        serialized: CodesAccountSerializedHeaders.medicine,
        unserialized: CodesAccountUnserializedHeaders.medicine,
        view: IntlKeys.farmManagementDataMedicine,
      },
      [CodesAccountSerializedHeaders.inoculation]: {
        serialized: CodesAccountSerializedHeaders.inoculation,
        unserialized: CodesAccountUnserializedHeaders.inoculation,
        view: IntlKeys.farmManagementDataInoculation,
      },
      [CodesAccountSerializedHeaders.hormone]: {
        serialized: CodesAccountSerializedHeaders.hormone,
        unserialized: CodesAccountUnserializedHeaders.hormone,
        view: IntlKeys.farmManagementDataHormone,
      },
      [CodesAccountSerializedHeaders.medicineEanCode]: {
        serialized: CodesAccountSerializedHeaders.medicineEanCode,
        unserialized: CodesAccountUnserializedHeaders.medicineEanCode,
        view: IntlKeys.farmManagementDataMedicineEanCode,
      },
      [CodesAccountSerializedHeaders.medicineCountryCode]: {
        serialized: CodesAccountSerializedHeaders.medicineCountryCode,
        unserialized: CodesAccountUnserializedHeaders.medicineCountryCode,
        view: IntlKeys.farmManagementDataMedicineCountryCode,
      },
      [CodesAccountSerializedHeaders.medicineOfficialName]: {
        serialized: CodesAccountSerializedHeaders.medicineOfficialName,
        unserialized: CodesAccountUnserializedHeaders.medicineOfficialName,
        view: IntlKeys.farmManagementDataMedicineOfficialName,
      },
      [CodesAccountSerializedHeaders.medicineDateEnd]: {
        serialized: CodesAccountSerializedHeaders.medicineDateEnd,
        unserialized: CodesAccountUnserializedHeaders.medicineDateEnd,
        view: IntlKeys.farmManagementDataMedicineDateEnd,
      },
      [CodesAccountSerializedHeaders.medicineUnit]: {
        serialized: CodesAccountSerializedHeaders.medicineUnit,
        unserialized: CodesAccountUnserializedHeaders.medicineUnit,
        view: IntlKeys.farmManagementDataMedicineUnit,
      },
      [CodesAccountSerializedHeaders.ev]: {
        serialized: CodesAccountSerializedHeaders.ev,
        unserialized: CodesAccountUnserializedHeaders.ev,
        view: IntlKeys.farmManagementDataEv,
      },
      [CodesAccountSerializedHeaders.medicineWhPeriod]: {
        serialized: CodesAccountSerializedHeaders.medicineWhPeriod,
        unserialized: CodesAccountUnserializedHeaders.medicineWhPeriod,
        view: IntlKeys.farmManagementDataMedicineWhPeriod,
      },
      [CodesAccountSerializedHeaders.medicineDosage]: {
        serialized: CodesAccountSerializedHeaders.medicineDosage,
        unserialized: CodesAccountUnserializedHeaders.medicineDosage,
        view: IntlKeys.farmManagementDataMedicineDosage,
      },
      [CodesAccountSerializedHeaders.medicineDosageFactor]: {
        serialized: CodesAccountSerializedHeaders.medicineDosageFactor,
        unserialized: CodesAccountUnserializedHeaders.medicineDosageFactor,
        view: IntlKeys.farmManagementDataMedicineDosageFactor,
      },
      [CodesAccountSerializedHeaders.supplierIdentifier]: {
        serialized: CodesAccountSerializedHeaders.supplierIdentifier,
        unserialized: CodesAccountUnserializedHeaders.supplierIdentifier,
        view: IntlKeys.farmManagementDataSupplierId,
      },
      [CodesAccountSerializedHeaders.medicineEmbCode]: {
        serialized: CodesAccountSerializedHeaders.medicineEmbCode,
        unserialized: CodesAccountUnserializedHeaders.medicineEmbCode,
        view: IntlKeys.farmManagementDataMedicineEmbCode,
      },
    },
  },
};

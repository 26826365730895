import React from 'react';
import { get } from 'lodash';

import { DocumentData, SheetSerializer } from '../../../lib/excel/serilizers/Sheet';
import { DocumentSerializer } from '../../../lib/excel/serilizers/Document';
import { CellSerializer, PropertyPath } from '../../../lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from '../../../lib/excel/config/service';
import { dateToFormattedString } from '../../../lib/excel/serilizers/utils';
import { ResourcesWrapper } from 'lib/http/utils';
import { INVOICE_DETAILS } from '../../../lib/tables/config/invoices/details/config';

interface InvoiceDetailsI {
  id: 1;
  invoice: {
    id: 3;
    message_id: '39814561';
  };
  line_number: '76';
  delivery_date: '2020-04-03';
  delivery_note_number: '45125011';
  delivery_note_line_number: '72';
  product_quantity: '89076';
  product_article_code: '275946378179830043';
  product_description: 'Commodi soluta sunt laudantium.';
  product_article_group_code: '65';
  product_article_group_code_description: 'Eum ea incidunt corporis.';
}

const { columns, serialized, unserialized } = INVOICE_DETAILS.InvoiceDetails;

const {
  deliveryDate,
  deliveryNoteLineNumber,
  deliveryNoteNumber,
  lineNumber,
  productArticleCode,
  productArticleGroupCode,
  productArticleGroupCodeDescription,
  productDescription,
  productQuantity,
  invoice,
  invoiceMessageId,
  unit,
  productEnergeticValue,
} = columns;

export const invoiceSerializer = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: { [index: string]: any },
  propOriginalPath: PropertyPath,
) => {
  const value = get(excelRow, propOriginalPath, {});

  return (
    <ul>
      {Object.entries(value).map(([key, value]) => (
        <li key={key}>{key + ' : ' + value}</li>
      ))}
    </ul>
  );
};

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(
        SERVICE_FIELDS.createdAt.unserialized,
        SERVICE_FIELDS.createdAt.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(invoice.unserialized, invoice.serialized, invoiceSerializer),
      new CellSerializer(invoiceMessageId.unserialized, invoiceMessageId.serialized),
      new CellSerializer(unit.unserialized, unit.serialized),
      new CellSerializer(deliveryDate.unserialized, deliveryDate.serialized, dateToFormattedString),
      new CellSerializer(deliveryNoteLineNumber.unserialized, deliveryNoteLineNumber.serialized),
      new CellSerializer(deliveryNoteNumber.unserialized, deliveryNoteNumber.serialized),
      new CellSerializer(lineNumber.unserialized, lineNumber.serialized),
      new CellSerializer(productArticleCode.unserialized, productArticleCode.serialized),
      new CellSerializer(productArticleGroupCode.unserialized, productArticleGroupCode.serialized),
      new CellSerializer(
        productArticleGroupCodeDescription.unserialized,
        productArticleGroupCodeDescription.serialized,
      ),
      new CellSerializer(productDescription.unserialized, productDescription.serialized),
      new CellSerializer(productQuantity.unserialized, productQuantity.serialized),
      new CellSerializer(productEnergeticValue.unserialized, productEnergeticValue.serialized),

      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
    ]),
  ]);
};

export const serializeInvoiceDetailsInfo = (serverData: ResourcesWrapper<InvoiceDetailsI>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { IPCC_EMISSION_FACTORS_FIELDS } from '../structure';
import { DocumentSerializer } from '../../../../serilizers/Document';
import { DocumentData, SheetSerializer } from '../../../../serilizers/Sheet';
import { CellSerializer } from '../../../../serilizers/Cell';

const { columns, serialized, original } = IPCC_EMISSION_FACTORS_FIELDS.IpccEmissionFactors;

export const serializeIpccEmissionFactorsData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.factor.original, columns.factor.serialized),
      new CellSerializer(columns.description.original, columns.description.serialized),
      new CellSerializer(columns.defaultValue.original, columns.defaultValue.serialized),
      new CellSerializer(columns.uncertaintyRange.original, columns.uncertaintyRange.serialized),
    ]),
  ]);
};

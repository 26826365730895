import { COUNTRY_CODES_FIELDS } from 'lib/excel/config/referenceTables/countryCodes/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = COUNTRY_CODES_FIELDS.CountryCodes;

export const getCountryCodesDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.iso31661Code.original),
      new CellValidator(columns.subdivisionName.original),
      new CellValidator(columns.iso31662Code.original),
      new CellValidator(columns.countryName.original),
    ]),
  ]);
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper } from 'lib/http/utils';

import { ERRORS_LOG } from './config';

export type ErrorsLogPayload = {
  id: number;
  log_datetime: string;
  error_log_type: string;
  error_data: {
    error_body: string;
    error_code: number;
    message_id: number;
  };
  created_at: string;
  updated_at: string;
};

export type ErrorsLogSerialized = {
  id: number;
  logDatetime: string;
  errorLogType: string;
  errorBody: string;
  errorCode: number;
  messageId: number;
  createdAt: string;
  updatedAt: string;
};

const { columns } = ERRORS_LOG.ErrorsLog;

const getDefaultSerializer = (data: DocumentData<ErrorsLogPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(ERRORS_LOG.ErrorsLog.unserialized, ERRORS_LOG.ErrorsLog.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(columns.logDatetime.unserialized, columns.logDatetime.serialized),
      new CellSerializer(columns.errorLogType.unserialized, columns.errorLogType.serialized),
      new CellSerializer(columns.errorBody.unserialized, columns.errorBody.serialized),
      new CellSerializer(columns.errorCode.unserialized, columns.errorCode.serialized),
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
    ]),
  ]);
};

export const serializeErrorsLog = (serverData: ResourcesWrapper<ErrorsLogPayload>) => {
  const { unserialized } = ERRORS_LOG.ErrorsLog;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { SUPPLIERS_NUMBERS_FIELDS } from 'lib/excel/config/referenceTables/suppliersNumbers/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = SUPPLIERS_NUMBERS_FIELDS.SuppliersNumbers;

export const serializeSuppliersNumbersData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.codeList.original, columns.codeList.serialized),
      new CellSerializer(columns.supplierNumber.original, columns.supplierNumber.serialized),
      new CellSerializer(columns.feedSupplierName.original, columns.feedSupplierName.serialized),
      new CellSerializer(columns.entryDate.original, columns.entryDate.serialized),
      new CellSerializer(columns.dischargeDate.original, columns.dischargeDate.serialized),
      new CellSerializer(columns.remark.original, columns.remark.serialized),
    ]),
  ]);
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { SLAUGHTER_PRICE_CORRECTIONS } from 'lib/tables/config/slaughter/priceCorrections/config';

interface SlaughterPriceCorrection {
  id: number;
  financial_result_id: string;
  financial_result_date_time: string;
  slaughter_house_type_code: string;
  slaughter_house_value: string;
  trader_type_code: string;
  trader_value: string;
  animal_type_code: string;
  animal_value: string;
  number_of_animals: string;
  currency_code: string;
  created_at: string;
  updated_at: string;
}

const { columns, serialized, unserialized } = SLAUGHTER_PRICE_CORRECTIONS.SlaughterPriceCorrections;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(columns.financialResultId.unserialized, columns.financialResultId.serialized),
      new CellSerializer(columns.financialResultDateTime.unserialized, columns.financialResultDateTime.serialized),
      new CellSerializer(columns.slaughterHouseTypeCode.unserialized, columns.slaughterHouseTypeCode.serialized),
      new CellSerializer(columns.slaughterHouseValue.unserialized, columns.slaughterHouseValue.serialized),
      new CellSerializer(columns.traderTypeCode.unserialized, columns.traderTypeCode.serialized),
      new CellSerializer(columns.traderValue.unserialized, columns.traderValue.serialized),
      new CellSerializer(columns.animalTypeCode.unserialized, columns.animalTypeCode.serialized),
      new CellSerializer(columns.animalValue.unserialized, columns.animalValue.serialized),
      new CellSerializer(columns.numberOfAnimals.unserialized, columns.numberOfAnimals.serialized),
      new CellSerializer(columns.currencyCode.unserialized, columns.currencyCode.serialized),
      new CellSerializer(columns.labels.unserialized, columns.labels.serialized),
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeSlaughterPriceCorrections = (serverData: ResourcesWrapper<SlaughterPriceCorrection>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys';

enum SuppliersNumbersSerialized {
  SuppliersNumbers = 'SuppliersNumbers',
}

enum SuppliersNumbersUnserialized {
  SuppliersNumbers = 'SuppliersNumbers',
}

export enum SuppliersNumbersSerializedHeaders {
  id = 'id',
  referenceUploadID = 'referenceUploadID',
  codeList = 'codeList',
  supplierNumber = 'supplierNumber',
  feedSupplierName = 'feedSupplierName',
  entryDate = 'entryDate',
  dischargeDate = 'dischargeDate',
  remark = 'remark',
  uploadType = 'uploadType',
  filename = 'filename',
  status = 'status',
  validDateFrom = 'validDateFrom',
  validDateTo = 'validDateTo',
  notes = 'notes',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
}

export const SuppliersNumbersUnserializedHeaders = {
  id: 'id',
  referenceUploadID: ['supplier_number_upload', 'id'],
  codeList: 'code_list',
  supplierNumber: 'supplier_code',
  feedSupplierName: 'supplier_name',
  entryDate: 'date_from',
  dischargeDate: 'date_to',
  remark: 'remark',
  uploadType: ['supplier_number_upload', 'upload_type'],
  filename: ['supplier_number_upload', 'file_name'],
  status: ['supplier_number_upload', 'status'],
  validDateFrom: ['supplier_number_upload', 'valid_from'],
  validDateTo: ['supplier_number_upload', 'valid_to'],
  notes: ['supplier_number_upload', 'notes'],
  uploadedAt: ['supplier_number_upload', 'uploaded_at'],
  uploadedBy: ['supplier_number_upload', 'uploaded_by', 'full_name'],
} as const;

export const SUPPLIERS_NUMBERS = {
  [SuppliersNumbersSerialized.SuppliersNumbers]: {
    serialized: SuppliersNumbersSerialized.SuppliersNumbers,
    unserialized: SuppliersNumbersUnserialized.SuppliersNumbers,
    view: IntlKeys.referenceTablesSuppliersNumbers,

    columns: {
      [SuppliersNumbersSerializedHeaders.id]: {
        serialized: SuppliersNumbersSerializedHeaders.id,
        unserialized: SuppliersNumbersUnserializedHeaders.id,
      },
      [SuppliersNumbersSerializedHeaders.referenceUploadID]: {
        serialized: SuppliersNumbersSerializedHeaders.referenceUploadID,
        unserialized: SuppliersNumbersUnserializedHeaders.referenceUploadID,
      },
      [SuppliersNumbersSerializedHeaders.codeList]: {
        serialized: SuppliersNumbersSerializedHeaders.codeList,
        unserialized: SuppliersNumbersUnserializedHeaders.codeList,
        view: IntlKeys.referenceTablesSuppliersNumbersCodeList,
      },
      [SuppliersNumbersSerializedHeaders.supplierNumber]: {
        serialized: SuppliersNumbersSerializedHeaders.supplierNumber,
        unserialized: SuppliersNumbersUnserializedHeaders.supplierNumber,
        view: IntlKeys.referenceTablesSuppliersNumbersSupplierNumber,
      },
      [SuppliersNumbersSerializedHeaders.feedSupplierName]: {
        serialized: SuppliersNumbersSerializedHeaders.feedSupplierName,
        unserialized: SuppliersNumbersUnserializedHeaders.feedSupplierName,
        view: IntlKeys.referenceTablesSuppliersNumbersFeedSupplierName,
      },
      [SuppliersNumbersSerializedHeaders.entryDate]: {
        serialized: SuppliersNumbersSerializedHeaders.entryDate,
        unserialized: SuppliersNumbersUnserializedHeaders.entryDate,
        view: IntlKeys.referenceTablesSuppliersNumbersEntryDate,
      },
      [SuppliersNumbersSerializedHeaders.dischargeDate]: {
        serialized: SuppliersNumbersSerializedHeaders.dischargeDate,
        unserialized: SuppliersNumbersUnserializedHeaders.dischargeDate,
        view: IntlKeys.referenceTablesSuppliersNumbersDischargeDate,
      },
      [SuppliersNumbersSerializedHeaders.remark]: {
        serialized: SuppliersNumbersSerializedHeaders.remark,
        unserialized: SuppliersNumbersUnserializedHeaders.remark,
        view: IntlKeys.referenceTablesSuppliersNumbersRemark,
      },
      [SuppliersNumbersSerializedHeaders.status]: {
        serialized: SuppliersNumbersSerializedHeaders.status,
        unserialized: SuppliersNumbersUnserializedHeaders.status,
      },
      [SuppliersNumbersSerializedHeaders.uploadType]: {
        serialized: SuppliersNumbersSerializedHeaders.uploadType,
        unserialized: SuppliersNumbersUnserializedHeaders.uploadType,
      },
      [SuppliersNumbersSerializedHeaders.notes]: {
        serialized: SuppliersNumbersSerializedHeaders.notes,
        unserialized: SuppliersNumbersUnserializedHeaders.notes,
      },
      [SuppliersNumbersSerializedHeaders.filename]: {
        serialized: SuppliersNumbersSerializedHeaders.filename,
        unserialized: SuppliersNumbersUnserializedHeaders.filename,
      },
      [SuppliersNumbersSerializedHeaders.validDateFrom]: {
        serialized: SuppliersNumbersSerializedHeaders.validDateFrom,
        unserialized: SuppliersNumbersUnserializedHeaders.validDateFrom,
      },
      [SuppliersNumbersSerializedHeaders.validDateTo]: {
        serialized: SuppliersNumbersSerializedHeaders.validDateTo,
        unserialized: SuppliersNumbersUnserializedHeaders.validDateTo,
      },
      [SuppliersNumbersSerializedHeaders.uploadedAt]: {
        serialized: SuppliersNumbersSerializedHeaders.uploadedAt,
        unserialized: SuppliersNumbersUnserializedHeaders.uploadedAt,
      },
      [SuppliersNumbersSerializedHeaders.uploadedBy]: {
        serialized: SuppliersNumbersSerializedHeaders.uploadedBy,
        unserialized: SuppliersNumbersUnserializedHeaders.uploadedBy,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys/__keys';

export enum JoindataParticipationsSerialized {
  JoindataParticipations = 'JoindataParticipations',
}

enum JoindataParticipationsUnserialized {
  JoindataParticipations = 'JoindataParticipations',
}

enum MyJoindataSerializedHeaders {
  kvk = 'kvk',
  name = 'name',
  participationGrantedOn = 'participationGrantedOn',
  participationStartDate = 'participationStartDate',
  participationStatus = 'participationStatus',
  participationValid = 'participationValid',
  restrictionType = 'restrictionType',
  restrictionDescription = 'restrictionDescription',
  restrictionStart = 'restrictionStart',
}

const MyJoindataUnserializedHeaders = {
  kvk: 'company_value',
  name: ['purpose', 'name'],
  participationGrantedOn: 'granted_on',
  participationStartDate: 'start_date',
  participationStatus: 'status',
  participationValid: 'is_valid',
  restrictionType: 'restriction_type',
  restrictionDescription: 'restriction_description',
  restrictionStart: 'restriction_start',
};

export const JOINDATA_PARTICIPATIONS = {
  [JoindataParticipationsSerialized.JoindataParticipations]: {
    serialized: JoindataParticipationsSerialized.JoindataParticipations,
    unserialized: JoindataParticipationsUnserialized.JoindataParticipations,
    view: IntlKeys.participationPlural,
    columns: {
      [MyJoindataSerializedHeaders.kvk]: {
        serialized: MyJoindataSerializedHeaders.kvk,
        unserialized: MyJoindataUnserializedHeaders.kvk,
        view: IntlKeys.kvk,
      },
      [MyJoindataSerializedHeaders.name]: {
        serialized: MyJoindataSerializedHeaders.name,
        unserialized: MyJoindataUnserializedHeaders.name,
        view: IntlKeys.joindataPurposeName,
      },
      [MyJoindataSerializedHeaders.participationGrantedOn]: {
        serialized: MyJoindataSerializedHeaders.participationGrantedOn,
        unserialized: MyJoindataUnserializedHeaders.participationGrantedOn,
        view: IntlKeys.joindataParticipationGrantedOn,
      },
      [MyJoindataSerializedHeaders.participationStartDate]: {
        serialized: MyJoindataSerializedHeaders.participationStartDate,
        unserialized: MyJoindataUnserializedHeaders.participationStartDate,
        view: IntlKeys.joindataParticipationStartDate,
      },
      [MyJoindataSerializedHeaders.participationStatus]: {
        serialized: MyJoindataSerializedHeaders.participationStatus,
        unserialized: MyJoindataUnserializedHeaders.participationStatus,
        view: IntlKeys.joindataParticipationStatus,
      },
      [MyJoindataSerializedHeaders.participationValid]: {
        serialized: MyJoindataSerializedHeaders.participationValid,
        unserialized: MyJoindataUnserializedHeaders.participationValid,
        view: IntlKeys.joindataParticipationValid,
      },
      [MyJoindataSerializedHeaders.restrictionType]: {
        serialized: MyJoindataSerializedHeaders.restrictionType,
        unserialized: MyJoindataUnserializedHeaders.restrictionType,
        view: IntlKeys.joindataRestrictionType,
      },
      [MyJoindataSerializedHeaders.restrictionDescription]: {
        serialized: MyJoindataSerializedHeaders.restrictionDescription,
        unserialized: MyJoindataUnserializedHeaders.restrictionDescription,
        view: IntlKeys.joindataRestrictionDescription,
      },
      [MyJoindataSerializedHeaders.restrictionStart]: {
        serialized: MyJoindataSerializedHeaders.restrictionStart,
        unserialized: MyJoindataUnserializedHeaders.restrictionStart,
        view: IntlKeys.joindataRestrictionStart,
      },
    },
  },
};

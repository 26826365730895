import { CONTENT_OF_NITRIC_OXIDE_FIELDS } from 'lib/excel/config/referenceTables/contentOfNitricOxide/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = CONTENT_OF_NITRIC_OXIDE_FIELDS.ContentOfNitricOxide;

export const serializeContentOfNitricOxideData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.livestockCategory.original, columns.livestockCategory.serialized),
      new CellSerializer(columns.manureType.original, columns.manureType.serialized),
      new CellSerializer(columns.valueN2oAndNo.original, columns.valueN2oAndNo.serialized),
      new CellSerializer(columns.unitN2oAndNo.original, columns.unitN2oAndNo.serialized),
      new CellSerializer(columns.valueN2.original, columns.valueN2.serialized),
      new CellSerializer(columns.unitN2.original, columns.unitN2.serialized),
    ]),
  ]);
};

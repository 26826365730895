import { RAV_REFERENCE_LIST_FIELDS } from 'lib/excel/config/referenceTables/ravReferenceList/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo2DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = RAV_REFERENCE_LIST_FIELDS.RavReferenceList;

export const getRavReferenceListView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.animalCategory.serialized,
        viewPropName: columns.animalCategory.view,
      }),
      new CellViewer({ serializedPropName: columns.description.serialized, viewPropName: columns.description.view }),
      new CellViewer({
        serializedPropName: columns.efEm.serialized,
        viewPropName: columns.efEm.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({ serializedPropName: columns.housingType.serialized, viewPropName: columns.housingType.view }),
    ]),
  ]);
};

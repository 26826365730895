import { TitlesKeys } from 'lib/localization/keys';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { IntlKeys } from 'lib/localization/keys/__keys';

enum AdminUsersOriginal {
  AdminUsers = 'User',
}

enum AdminUsersSerialized {
  AdminUsers = 'AdminUsers',
}

enum AdminUsersUnserialized {
  AdminUsers = 'AdminUsers',
}

export const AdminUsersUnserializedHeaders = {
  fullName: 'full_name',
  email: 'email',
  prefLang: 'pref_lang',
  uid: 'uid',
  userRole: 'user_role',
  userType: 'user_type',
  isContactPerson: 'is_contact_person',
  concepts: 'concepts',
  status: 'status',
  userPassportIDs: 'user_passport_ids',
  userPassports: 'user_passports',
  companies: 'companies',
  lastSignInAt: 'last_sign_in_at',
  signInCount: 'sign_in_count',
  permissionProfileTemplate: 'permission_profile_template',
  colorTheme: ['profile_settings', 'color_theme'],
};

export enum AdminUsersSerializedHeaders {
  fullName = 'fullName',
  email = 'email',
  prefLang = 'prefLang',
  uid = 'uid',
  userRole = 'userRole',
  userType = 'userType',
  isContactPerson = 'isContactPerson',
  concepts = 'concepts',
  status = 'status',
  userPassportIDs = 'userPassportIDs',
  userPassports = 'userPassports',
  companies = 'companies',
  lastSignInAt = 'lastSignInAt',
  signInCount = 'signInCount',
  permissionProfileTemplate = 'permissionProfileTemplate',
  colorTheme = 'colorTheme',
}

export enum AdminUsersOriginalHeaders {
  fullName = 'full_name',
  email = 'email',
  prefLang = 'pref_lang',
  uid = 'uid',
  userRole = 'user_role',
  userType = 'user_type',
  isContactPerson = 'is_contact_person',
  concepts = 'concepts',
  status = 'status',
  userPassportIDs = 'user_passport_ids',
  userPassports = 'user_passports',
  companies = 'companies',
  lastSignInAt = 'last_sign_in_at',
  signInCount = 'sign_in_count',
  permissionProfileTemplate = 'permission_profile_template',
  colorTheme = 'color_theme',
}

export const ADMIN_USERS = {
  [AdminUsersSerialized.AdminUsers]: {
    serialized: AdminUsersSerialized.AdminUsers,
    unserialized: AdminUsersUnserialized.AdminUsers,
    original: AdminUsersOriginal.AdminUsers,
    view: TitlesKeys.allUsers,
    viewName: 'Users',

    columns: {
      [SERVICE_FIELDS.id.serialized]: {
        serialized: SERVICE_FIELDS.id.serialized,
        unserialized: SERVICE_FIELDS.id.unserialized,
        original: SERVICE_FIELDS.id.unserialized,
        view: SERVICE_FIELDS.id.view,
      },
      [AdminUsersSerializedHeaders.email]: {
        serialized: AdminUsersSerializedHeaders.email,
        unserialized: AdminUsersUnserializedHeaders.email,
        original: AdminUsersOriginalHeaders.email,
        view: IntlKeys.email,
      },
      [AdminUsersSerializedHeaders.fullName]: {
        serialized: AdminUsersSerializedHeaders.fullName,
        unserialized: AdminUsersUnserializedHeaders.fullName,
        original: AdminUsersOriginalHeaders.fullName,
        view: IntlKeys.adminUsersFullName,
      },
      [AdminUsersSerializedHeaders.status]: {
        serialized: AdminUsersSerializedHeaders.status,
        unserialized: AdminUsersUnserializedHeaders.status,
        original: AdminUsersOriginalHeaders.status,
        view: IntlKeys.adminUsersStatus,
      },
      [AdminUsersSerializedHeaders.userPassportIDs]: {
        serialized: AdminUsersSerializedHeaders.userPassportIDs,
        unserialized: AdminUsersUnserializedHeaders.userPassportIDs,
        original: AdminUsersOriginalHeaders.userPassportIDs,
        view: IntlKeys.adminUsersUserPassportIds,
      },
      [AdminUsersSerializedHeaders.companies]: {
        serialized: AdminUsersSerializedHeaders.companies,
        unserialized: AdminUsersUnserializedHeaders.companies,
        original: AdminUsersOriginalHeaders.companies,
        view: IntlKeys.adminUsersCompanies,
      },
      [AdminUsersSerializedHeaders.prefLang]: {
        serialized: AdminUsersSerializedHeaders.prefLang,
        unserialized: AdminUsersUnserializedHeaders.prefLang,
        original: AdminUsersOriginalHeaders.prefLang,
        view: IntlKeys.adminUsersPrefLang,
      },
      [AdminUsersSerializedHeaders.uid]: {
        serialized: AdminUsersSerializedHeaders.uid,
        unserialized: AdminUsersUnserializedHeaders.uid,
        original: AdminUsersOriginalHeaders.uid,
        view: IntlKeys.email,
      },
      [AdminUsersSerializedHeaders.userRole]: {
        serialized: AdminUsersSerializedHeaders.userRole,
        unserialized: AdminUsersUnserializedHeaders.userRole,
        original: AdminUsersOriginalHeaders.userRole,
        view: IntlKeys.adminUsersUserRole,
      },
      [AdminUsersSerializedHeaders.permissionProfileTemplate]: {
        serialized: AdminUsersSerializedHeaders.permissionProfileTemplate,
        unserialized: AdminUsersUnserializedHeaders.permissionProfileTemplate,
        original: AdminUsersOriginalHeaders.permissionProfileTemplate,
        view: IntlKeys.adminUsersUserPermissionProfile,
      },
      [AdminUsersSerializedHeaders.userType]: {
        serialized: AdminUsersSerializedHeaders.userType,
        unserialized: AdminUsersUnserializedHeaders.userType,
        original: AdminUsersOriginalHeaders.userType,
        view: IntlKeys.adminUsersUserType,
      },
      [AdminUsersSerializedHeaders.isContactPerson]: {
        serialized: AdminUsersSerializedHeaders.isContactPerson,
        unserialized: AdminUsersUnserializedHeaders.isContactPerson,
        original: AdminUsersOriginalHeaders.isContactPerson,
        view: IntlKeys.adminUsersIsContactPerson,
      },
      [AdminUsersSerializedHeaders.concepts]: {
        serialized: AdminUsersSerializedHeaders.concepts,
        unserialized: AdminUsersUnserializedHeaders.concepts,
        original: AdminUsersOriginalHeaders.concepts,
        view: IntlKeys.adminUsersConcepts,
      },
      [AdminUsersSerializedHeaders.lastSignInAt]: {
        serialized: AdminUsersSerializedHeaders.lastSignInAt,
        unserialized: AdminUsersUnserializedHeaders.lastSignInAt,
        original: AdminUsersOriginalHeaders.lastSignInAt,
        view: IntlKeys.adminUsersLastSignInAt,
      },
      [AdminUsersSerializedHeaders.signInCount]: {
        serialized: AdminUsersSerializedHeaders.signInCount,
        unserialized: AdminUsersUnserializedHeaders.signInCount,
        original: AdminUsersOriginalHeaders.signInCount,
        view: IntlKeys.adminUsersSignInCount,
      },
      [AdminUsersSerializedHeaders.colorTheme]: {
        serialized: AdminUsersSerializedHeaders.colorTheme,
        unserialized: AdminUsersUnserializedHeaders.colorTheme,
        original: AdminUsersOriginalHeaders.colorTheme,
        view: IntlKeys.colorTheme,
      },
      [SERVICE_FIELDS.createdAt.serialized]: {
        serialized: SERVICE_FIELDS.createdAt.serialized,
        unserialized: SERVICE_FIELDS.createdAt.unserialized,
        original: SERVICE_FIELDS.createdAt.unserialized,
        view: SERVICE_FIELDS.createdAt.view,
      },
      [SERVICE_FIELDS.updatedAt.serialized]: {
        serialized: SERVICE_FIELDS.updatedAt.serialized,
        unserialized: SERVICE_FIELDS.updatedAt.unserialized,
        original: SERVICE_FIELDS.updatedAt.unserialized,
        view: SERVICE_FIELDS.updatedAt.view,
      },
    },
  },
};

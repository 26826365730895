import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const StyledListItem = styled((props) => <ListItem {...props} />)`
  && * {
    white-space: unset;
  }
`;

const StyledListItemTitle = styled((props) => <Typography {...props} />)`
  && {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
`;

const StyledListItemData = styled((props) => <Typography {...props} />)`
  && {
    font-size: 1rem;
  }
`;

interface OwnProps {
  label: React.ReactNode;
  text: React.ReactNode;
  className?: string;
  withDivider?: boolean;
}

const TitledRow: React.FC<OwnProps> = ({ label, text, withDivider = true, className }) => {
  return (
    <>
      <StyledListItem className={className}>
        <ListItemText
          primary={label}
          secondary={text}
          primaryTypographyProps={{
            component: StyledListItemTitle,
            color: 'textSecondary',
          }}
          secondaryTypographyProps={{ component: StyledListItemData, color: 'textPrimary' }}
        />
      </StyledListItem>
      {withDivider && <Divider />}
    </>
  );
};

export default React.memo(TitledRow);

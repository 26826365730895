import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { CompanyReportPayload } from '../companyReports';
import { MANURE_REPORTS, MANURE_REPORTS_SUBMIT } from './config';

export type ManureReportPayload = {
  id: number;
  status: 'draft' | 'submitted';
  company_report: CompanyReportPayload;
  reporting_period_date_start: string;
  reporting_period_date_end: string;
  processed_manure?: number;
  sodium_hydroxide?: number;
  glycerine_used?: number;
  digestate_production?: number;
  biogas_production?: number;
  electricity_production?: number;
  updated_at: string;
  updated_by: {
    email: string;
    full_name: string;
  };
};

export type ManureReportSerialized = {
  id: number;
  status: 'draft' | 'submitted';
  ubn: string;
  companyReport: number | null;
  reportingPeriodDateStart: string;
  reportingPeriodDateEnd: string;
  processedManure?: number;
  sodiumHydroxide?: number;
  glycerineUsed?: number;
  digestateProduction?: number;
  biogasProduction?: number;
  electricityProduction?: number;
  updatedAt: string;
  updatedBy: string;
};

export type ManureReportSubmitPayload = Omit<ManureReportPayload, 'id' | 'updated_at' | 'updated_by'>;
export type ManureReportSubmitSerialized = Omit<ManureReportSerialized, 'id' | 'updatedAt' | 'updatedBy' | 'ubn'>;

const { columns: defaultColumns } = MANURE_REPORTS.ManureReports;
const { columns: submitColumns } = MANURE_REPORTS_SUBMIT.ManureReportsSubmit;

const getDefaultSerializer = (data: DocumentData<ManureReportPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(MANURE_REPORTS.ManureReports.unserialized, MANURE_REPORTS.ManureReports.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.status.unserialized, defaultColumns.status.serialized),
      new CellSerializer(defaultColumns.ubn.unserialized, defaultColumns.ubn.serialized),
      new CellSerializer(defaultColumns.idType.unserialized, defaultColumns.idType.serialized),
      new CellSerializer(defaultColumns.companyReport.unserialized, defaultColumns.companyReport.serialized),
      new CellSerializer(
        defaultColumns.reportingPeriodDateStart.unserialized,
        defaultColumns.reportingPeriodDateStart.serialized,
      ),
      new CellSerializer(
        defaultColumns.reportingPeriodDateEnd.unserialized,
        defaultColumns.reportingPeriodDateEnd.serialized,
      ),
      new CellSerializer(defaultColumns.processedManure.unserialized, defaultColumns.processedManure.serialized),
      new CellSerializer(defaultColumns.sodiumHydroxide.unserialized, defaultColumns.sodiumHydroxide.serialized),
      new CellSerializer(defaultColumns.glycerineUsed.unserialized, defaultColumns.glycerineUsed.serialized),
      new CellSerializer(
        defaultColumns.digestateProduction.unserialized,
        defaultColumns.digestateProduction.serialized,
      ),
      new CellSerializer(defaultColumns.biogasProduction.unserialized, defaultColumns.biogasProduction.serialized),
      new CellSerializer(
        defaultColumns.electricityProduction.unserialized,
        defaultColumns.electricityProduction.serialized,
      ),
      new CellSerializer(defaultColumns.updatedAt.unserialized, defaultColumns.updatedAt.serialized),
      new CellSerializer(defaultColumns.updatedBy.unserialized, defaultColumns.updatedBy.serialized),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<ManureReportSubmitSerialized[]>) => {
  return new DocumentSerializer<ManureReportSubmitPayload, ManureReportSubmitSerialized>(data, [
    new SheetSerializer(
      MANURE_REPORTS_SUBMIT.ManureReportsSubmit.serialized,
      MANURE_REPORTS_SUBMIT.ManureReportsSubmit.unserialized,
      [
        new CellSerializer(submitColumns.status.unserialized, submitColumns.status.serialized),
        new CellSerializer(submitColumns.companyReport.unserialized, submitColumns.companyReport.serialized),
        new CellSerializer(
          submitColumns.reportingPeriodDateStart.unserialized,
          submitColumns.reportingPeriodDateStart.serialized,
        ),
        new CellSerializer(
          submitColumns.reportingPeriodDateEnd.unserialized,
          submitColumns.reportingPeriodDateEnd.serialized,
        ),
        new CellSerializer(
          submitColumns.processedManure.unserialized,
          submitColumns.processedManure.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.sodiumHydroxide.unserialized,
          submitColumns.sodiumHydroxide.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.glycerineUsed.unserialized,
          submitColumns.glycerineUsed.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.digestateProduction.unserialized,
          submitColumns.digestateProduction.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.biogasProduction.unserialized,
          submitColumns.biogasProduction.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.electricityProduction.unserialized,
          submitColumns.electricityProduction.serialized,
          undefined,
          undefined,
          null,
        ),
      ],
    ),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<ManureReportSerialized[]>) => {
  return new DocumentSerializer<ManureReportSerialized, ManureReportSubmitSerialized>(data, [
    new SheetSerializer(
      MANURE_REPORTS_SUBMIT.ManureReportsSubmit.serialized,
      MANURE_REPORTS_SUBMIT.ManureReportsSubmit.unserialized,
      [
        new CellSerializer(defaultColumns.status.serialized, submitColumns.status.serialized),
        new CellSerializer(defaultColumns.companyReport.serialized, submitColumns.companyReport.serialized),
        new CellSerializer(
          defaultColumns.reportingPeriodDateStart.serialized,
          submitColumns.reportingPeriodDateStart.serialized,
        ),
        new CellSerializer(
          defaultColumns.reportingPeriodDateEnd.serialized,
          submitColumns.reportingPeriodDateEnd.serialized,
        ),
        new CellSerializer(
          defaultColumns.processedManure.serialized,
          submitColumns.processedManure.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.sodiumHydroxide.serialized,
          submitColumns.sodiumHydroxide.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.glycerineUsed.serialized,
          submitColumns.glycerineUsed.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.digestateProduction.serialized,
          submitColumns.digestateProduction.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.biogasProduction.serialized,
          submitColumns.biogasProduction.serialized,
          undefined,
          undefined,
          undefined,
        ),
        new CellSerializer(
          defaultColumns.electricityProduction.serialized,
          submitColumns.electricityProduction.serialized,
          undefined,
          undefined,
          undefined,
        ),
      ],
    ),
  ]);
};

export const serializeManureReports = (serverData: ResourcesWrapper<ManureReportPayload>) => {
  const { unserialized } = MANURE_REPORTS.ManureReports;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const serializeManureReport = (serverData: ResourceWrapper<ManureReportPayload>) => {
  const { serialized, unserialized } = MANURE_REPORTS.ManureReports;
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

export const unserializeManureReportForSubmit = (resource: ManureReportSubmitSerialized): ManureReportSubmitPayload => {
  const { serialized, unserialized } = MANURE_REPORTS_SUBMIT.ManureReportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeManureReportStoSS = (resource: ManureReportSerialized) => {
  const { serialized, unserialized } = MANURE_REPORTS_SUBMIT.ManureReportsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

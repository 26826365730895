import SearchIcon from '@material-ui/icons/Search';
import { FILTER_TYPES } from './index';

export const strictValueFilter = {
  type: FILTER_TYPES.STRICT_VALUE,
  getInitialValues: () => '',
  getQuery: (filterValue: string | number) => filterValue || {},
  getValues: (filterValue: string | number) => filterValue || null,
  getLabel: (filterValue: string | number) => filterValue || '',
  icon: SearchIcon,
};

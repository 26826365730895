import { RAV_CODES_FIELDS } from 'lib/excel/config/referenceTables/ravCodes/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo2DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = RAV_CODES_FIELDS.RavCodes;

export const getRavCodesView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.animalCategory.serialized,
        viewPropName: columns.animalCategory.view,
      }),
      new CellViewer({
        serializedPropName: columns.ravCode.serialized,
        viewPropName: columns.ravCode.view,
      }),
      new CellViewer({
        serializedPropName: columns.animalDescriptionNl.serialized,
        viewPropName: columns.animalDescriptionNl.view,
      }),
      new CellViewer({
        serializedPropName: columns.animalDescriptionEn.serialized,
        viewPropName: columns.animalDescriptionEn.view,
      }),
      new CellViewer({
        serializedPropName: columns.animalEnclosureCategory.serialized,
        viewPropName: columns.animalEnclosureCategory.view,
      }),
      new CellViewer({
        serializedPropName: columns.ammonia.serialized,
        viewPropName: columns.ammonia.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({
        serializedPropName: columns.ammoniaUnit.serialized,
        viewPropName: columns.ammoniaUnit.view,
      }),
    ]),
  ]);
};

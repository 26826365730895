import { CL550_FIELDS } from 'lib/excel/config/referenceTables/cl550/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = CL550_FIELDS.Cl550;

export const getCl550DocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.mainGroupCode.original),
      new CellValidator(columns.subGroupCode.original),
      new CellValidator(columns.codeDetails.original),
      new CellValidator(columns.mainGroupName.original),
      new CellValidator(columns.subgroupName.original),
      new CellValidator(columns.detailing.original),
      new CellValidator(columns.english.original),
      new CellValidator(columns.german.original),
      new CellValidator(columns.codeCondition.original),
      new CellValidator(columns.edi.original),
      new CellValidator(columns.dgr.original),
      new CellValidator(columns.diseaseAndTreatment.original),
      new CellValidator(columns.failure.original),
      new CellValidator(columns.disposal.original),
      new CellValidator(columns.selection.original),
      new CellValidator(columns.labResult.original),
      new CellValidator(columns.section.original),
      new CellValidator(columns.slaughterhouseData.original),
      new CellValidator(columns.agility.original),
      new CellValidator(columns.coverage.original),
      new CellValidator(columns.birth.original),
      new CellValidator(columns.suckingPiglet.original),
      new CellValidator(columns.weanedBig.original),
      new CellValidator(columns.rearingSowAndBoar.original),
      new CellValidator(columns.goodSow.original),
      new CellValidator(columns.gestationSow.original),
      new CellValidator(columns.lactatingSow.original),
      new CellValidator(columns.beer.original),
      new CellValidator(columns.finisher.original),
      new CellValidator(columns.comment.original),
      new CellValidator(columns.added.original),
      new CellValidator(columns.deleted.original),
    ]),
  ]);
};

import { call, select, takeEvery } from 'redux-saga/effects';

import ensure, { Ensure } from 'lib/ensure';
import { ApiClient } from 'lib/http/ApiClient';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import {
  fetchVriFirstReportResourcesAction,
  fetchVriFirstReportStatisticsAction,
  fetchVriSecondReportResourcesAction,
  fetchVriSecondReportStatisticsAction,
  updateVriFirstReportResourcesAction,
  updateVriSecondReportResourcesAction,
  visitVriComparisonToolAction,
} from './actions';
import { serializeVriResourcesData, serializeVriResourceData, serializeVriStatistics } from '../vriCalculated/entities';
import { userHasPermissionSelector } from 'store/auth/reducer';
import { PERMISSIONS } from 'store/auth/permissions';
import { CustomAction } from 'lib/actions';

// VRI FIRST REPORT
export const ensureGetFirstCalculatedReports = ensure({
  api: ApiClient.getCalculatedReports,
  action: fetchVriFirstReportResourcesAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureAdminGetFirstCalculatedReports = ensure({
  api: ApiClient.adminGetCalculatedReports,
  action: fetchVriFirstReportResourcesAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureUpdateFirstCalculatedReports = ensure({
  api: ApiClient.updateCalculatedReports,
  action: updateVriFirstReportResourcesAction,
  serializeSuccessPayload: serializeVriResourceData,
});

export const ensureAdminUpdateFirstCalculatedReports = ensure({
  api: ApiClient.adminUpdateCalculatedReports,
  action: updateVriFirstReportResourcesAction,
  serializeSuccessPayload: serializeVriResourceData,
});

export const ensureGetFirstCalculatedReportsStatistics = ensure({
  api: ApiClient.getCalculatedReportsStatistics,
  action: fetchVriFirstReportStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

export const ensureAdminGetFirstCalculatedReportsStatistics = ensure({
  api: ApiClient.adminGetCalculatedReportsStatistics,
  action: fetchVriFirstReportStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

// VRI SECOND REPORT
export const ensureGetSecondCalculatedReports = ensure({
  api: ApiClient.getCalculatedReports,
  action: fetchVriSecondReportResourcesAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureAdminGetSecondCalculatedReports = ensure({
  api: ApiClient.adminGetCalculatedReports,
  action: fetchVriSecondReportResourcesAction,
  serializeSuccessPayload: serializeVriResourcesData,
});

export const ensureUpdateSecondCalculatedReports = ensure({
  api: ApiClient.updateCalculatedReports,
  action: updateVriSecondReportResourcesAction,
  serializeSuccessPayload: serializeVriResourceData,
});

export const ensureAdminUpdateSecondCalculatedReports = ensure({
  api: ApiClient.adminUpdateCalculatedReports,
  action: updateVriSecondReportResourcesAction,
  serializeSuccessPayload: serializeVriResourceData,
});

export const ensureGetSecondCalculatedReportsStatistics = ensure({
  api: ApiClient.getCalculatedReportsStatistics,
  action: fetchVriSecondReportStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

export const ensureAdminGetSecondCalculatedReportsStatistics = ensure({
  api: ApiClient.adminGetCalculatedReportsStatistics,
  action: fetchVriSecondReportStatisticsAction,
  serializeSuccessPayload: serializeVriStatistics,
});

export const ensureVisitVriComparisonTool = ensure({
  api: ApiClient.visitVriComparisonTool,
  action: visitVriComparisonToolAction,
});

function* getCalculatedData({ payload }: ObjectWithProps) {
  const { ensure, ...otherPayload } = payload;

  yield call(ensure, otherPayload);
}

function* getFirstCalculatedReportsStatistics({ payload }: ObjectWithProps) {
  const { filter } = payload;
  const isAdmin: boolean = (yield select(userHasPermissionSelector))(PERMISSIONS.LEVEL1);

  yield call(isAdmin ? ensureAdminGetFirstCalculatedReportsStatistics : ensureGetFirstCalculatedReportsStatistics, {
    filter,
  });
}

function* getSecondCalculatedReportsStatistics({ payload }: ObjectWithProps) {
  const { filter } = payload;
  const isAdmin: boolean = (yield select(userHasPermissionSelector))(PERMISSIONS.LEVEL1);

  yield call(isAdmin ? ensureAdminGetSecondCalculatedReportsStatistics : ensureGetSecondCalculatedReportsStatistics, {
    filter,
  });
}

const callEnsure = (ensure: Ensure) =>
  function* ({ payload }: CustomAction) {
    yield call(ensure, payload);
  };

export default function* () {
  yield takeEvery(fetchVriFirstReportResourcesAction.request, getCalculatedData);
  yield takeEvery(updateVriFirstReportResourcesAction.request, getCalculatedData);
  yield takeEvery(fetchVriFirstReportStatisticsAction.request, getFirstCalculatedReportsStatistics);

  yield takeEvery(fetchVriSecondReportResourcesAction.request, getCalculatedData);
  yield takeEvery(updateVriSecondReportResourcesAction.request, getCalculatedData);
  yield takeEvery(fetchVriSecondReportStatisticsAction.request, getSecondCalculatedReportsStatistics);
  yield takeEvery(visitVriComparisonToolAction.request, callEnsure(ensureVisitVriComparisonTool));
}

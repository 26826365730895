import { Reducer } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { merge, memoize } from 'lodash';

import { asyncAction, syncAction } from 'lib/actions';
import { RootState } from 'store/store';
import { getAuthHeadersFromStorage } from 'store/auth/helpers';
import { UserI } from 'store/auth/User';
import { PERMISSIONS } from './permissions';

const staticInitialState = {
  meta: {
    isAuthorized: false,
    isLoading: false,
    isError: false,
    errorMsg: '',
    isMaintenance: false,
  },
  headers: {
    token: '',
    type: '',
    uid: '',
    client: '',
    expiry: '',
  },
  user: ({
    permissions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: false },
  } as unknown) as UserI,
};

export const initialState = {
  get auth() {
    const headers = getAuthHeadersFromStorage();

    return merge({}, staticInitialState, {
      meta: {
        isAuthorized: Boolean(headers.token),
      },
      headers,
      user: ({
        permissions: { [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: Boolean(headers.token) },
      } as unknown) as UserI,
    });
  },
};

export const signInAction = asyncAction('auth/SIGN_IN');
export const signOutAction = asyncAction('auth/SIGN_OUT');
export const getUserAction = asyncAction('auth/GET_USER');
export const resetPasswordAction = asyncAction('auth/RESET_PASSWORD');
export const passwordChangeAction = asyncAction('auth/CHANGE_PASSWORD');
export const resetErrorAction = syncAction('auth/RESET_ERROR');
export const setMaintenanceAction = syncAction<boolean>('auth/setMaintenance');

const reducer = createReducer(staticInitialState, {
  ////////////////////////////////////////////////////////////////////////////////////
  [signInAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
    draft.meta.isAuthorized = false;

    draft.meta.isError = staticInitialState.meta.isError;
    draft.meta.errorMsg = staticInitialState.meta.errorMsg;
  },
  [signInAction.success]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.meta.isAuthorized = true;

    draft.headers = payload.headers;
    draft.user = payload.user;
  },
  [signInAction.failure]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.meta.isAuthorized = false;

    draft.meta.isError = true;
    draft.meta.errorMsg = payload.errorMsg;
  },
  ////////////////////////////////////////////////////////////////////////////////////
  [signOutAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },
  [signOutAction.success]: (draft, { payload }) => {
    // draft.meta.isLoading = false;
    // draft.meta.isAuthorized = false;
    //
    // draft.user = staticInitialState.user;
    // draft.headers = staticInitialState.headers;
  },
  [signOutAction.failure]: (draft, { payload }) => {
    draft.meta.isLoading = false;
  },
  ////////////////////////////////////////////////////////////////////////////////////
  [getUserAction.request]: (draft, { payload }) => {
    draft.meta.isLoading = true;
  },
  [getUserAction.success]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.meta.isAuthorized = true;

    draft.headers = payload.headers;
    draft.user = payload.user;
  },
  [getUserAction.failure]: (draft, { payload }) => {
    draft.meta.isLoading = false;
    draft.meta.isAuthorized = false;

    draft.user = staticInitialState.user;
  },
  ////////////////////////////////////////////////////////////////////////////////////
  [resetErrorAction.type]: (draft) => {
    draft.meta.isError = staticInitialState.meta.isError;
    draft.meta.errorMsg = staticInitialState.meta.errorMsg;
  },
  ////////////////////////////////////////////////////////////////////////////////////
  [setMaintenanceAction.type]: (draft, { payload }) => {
    draft.meta.isMaintenance = payload;
  },
});

export const authReducer: Reducer<typeof staticInitialState> = (draft = initialState.auth, action) => {
  return reducer(draft, action);
};

const authSelector = (state: RootState) => state.auth;
export const authHeadersSelector = createSelector(authSelector, (auth) => auth.headers);
export const authIsAuthorizedSelector = createSelector(authSelector, (auth) => auth.meta.isAuthorized);
export const authMetaSelector = createSelector(authSelector, (auth) => auth.meta);
export const authUserSelector = createSelector(authSelector, (auth) => auth.user);
export const authPermissionsSelector = createSelector(authSelector, (auth) => auth.user.permissions);
export const userIdSelector = createSelector(authSelector, (auth) => auth.user.id);
export const userColorThemeSelector = createSelector(authSelector, (auth) => auth.user.colorTheme);
export const userHasPermissionSelector = createSelector(authPermissionsSelector, (permissions) =>
  memoize((permission: PERMISSIONS) => Boolean(permissions[permission])),
);
export const isMaintenanceSelector = createSelector(authMetaSelector, ({ isMaintenance }) => isMaintenance);

export default authReducer;

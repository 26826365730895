import { CFP_CO2_EMISSION_FACTOR } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = CFP_CO2_EMISSION_FACTOR.CFP_CO2EmissionFactors;

export const getCfpCo2EmissionFactorDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.emissionSource.original),
      new CellValidator(columns.co2Volume.original),
      new CellValidator(columns.reference.original),
    ]),
  ]);
};

import { ENTITIES_LIST_FIELDS } from 'lib/excel/config/referenceTables/entitiesList/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';

const { columns, serialized, view } = ENTITIES_LIST_FIELDS.EntitiesList;

export const getEntitiesListView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({ serializedPropName: columns.entityId.serialized, viewPropName: columns.entityId.view }),
      new CellViewer({ serializedPropName: columns.entityName.serialized, viewPropName: columns.entityName.view }),
      new CellViewer({ serializedPropName: columns.country.serialized, viewPropName: columns.country.view }),
      new CellViewer({ serializedPropName: columns.businessUnit.serialized, viewPropName: columns.businessUnit.view }),
    ]),
  ]);
};

import { CellViewer } from './Cell';
import { v4 as uuidv4 } from 'uuid';

export class SheetViewer {
  constructor(
    readonly serializedSheetName: string,
    readonly viewSheetName: string,
    private readonly cellViewers: Array<CellViewer>,
    private cellData = new Array<object>(),
  ) {}

  setData = (data: Array<object>) => {
    this.cellData = data.map((dataRow, id) => {
      return this.cellViewers.reduce(
        (cumulative, cellViewer) => {
          return {
            ...cumulative,
            ...cellViewer.getValue(dataRow, data),
          };
        },
        {
          id: uuidv4(),
          orderNumber: id
        },
      ); /* this is there we set uid for each serialized row */
    });
  };

  getData = () => {
    return this.cellData;
  };
}

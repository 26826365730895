import { CL550_FIELDS } from 'lib/excel/config/referenceTables/cl550/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = CL550_FIELDS.Cl550;

export const serializeCl550Data = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.mainGroupCode.original, columns.mainGroupCode.serialized),
      new CellSerializer(columns.subGroupCode.original, columns.subGroupCode.serialized),
      new CellSerializer(columns.codeDetails.original, columns.codeDetails.serialized),
      new CellSerializer(columns.mainGroupName.original, columns.mainGroupName.serialized),
      new CellSerializer(columns.subgroupName.original, columns.subgroupName.serialized),
      new CellSerializer(columns.detailing.original, columns.detailing.serialized),
      new CellSerializer(columns.english.original, columns.english.serialized),
      new CellSerializer(columns.german.original, columns.german.serialized),
      new CellSerializer(columns.codeCondition.original, columns.codeCondition.serialized),
      new CellSerializer(columns.edi.original, columns.edi.serialized),
      new CellSerializer(columns.dgr.original, columns.dgr.serialized),
      new CellSerializer(columns.diseaseAndTreatment.original, columns.diseaseAndTreatment.serialized),
      new CellSerializer(columns.failure.original, columns.failure.serialized),
      new CellSerializer(columns.disposal.original, columns.disposal.serialized),
      new CellSerializer(columns.selection.original, columns.selection.serialized),
      new CellSerializer(columns.labResult.original, columns.labResult.serialized),
      new CellSerializer(columns.section.original, columns.section.serialized),
      new CellSerializer(columns.slaughterhouseData.original, columns.slaughterhouseData.serialized),
      new CellSerializer(columns.agility.original, columns.agility.serialized),
      new CellSerializer(columns.coverage.original, columns.coverage.serialized),
      new CellSerializer(columns.birth.original, columns.birth.serialized),
      new CellSerializer(columns.suckingPiglet.original, columns.suckingPiglet.serialized),
      new CellSerializer(columns.weanedBig.original, columns.weanedBig.serialized),
      new CellSerializer(columns.rearingSowAndBoar.original, columns.rearingSowAndBoar.serialized),
      new CellSerializer(columns.goodSow.original, columns.goodSow.serialized),
      new CellSerializer(columns.gestationSow.original, columns.gestationSow.serialized),
      new CellSerializer(columns.lactatingSow.original, columns.lactatingSow.serialized),
      new CellSerializer(columns.beer.original, columns.beer.serialized),
      new CellSerializer(columns.finisher.original, columns.finisher.serialized),
      new CellSerializer(columns.comment.original, columns.comment.serialized),
      new CellSerializer(columns.added.original, columns.added.serialized),
      new CellSerializer(columns.deleted.original, columns.deleted.serialized),
    ]),
  ]);
};

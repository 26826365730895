/**
 * @deprecated use single IntlKeys
 */
export enum CertificatesWelfareKeys {
  ubn = 'certificates.welfare.ubn',
  validFrom = 'certificates.welfare.validFrom',
  validTo = 'certificates.welfare.validTo',
  type = 'certificates.welfare.type',
}

/**
 * @deprecated use single IntlKeys
 */
export enum CertificatesIkbKeys {
  ubn = 'certificates.ikb.ubn',
  validFrom = 'certificates.ikb.validFrom',
  validTo = 'certificates.ikb.validTo',
  type = 'certificates.ikb.type',
}

/**
 * @deprecated use single IntlKeys
 */
export enum CertificatesKiwaDailyKeys {
  RegNumber = 'certificates.kiwa.daily.regNumber',
  CountryCodeLoc = 'certificates.kiwa.daily.countryCodeLoc',
  BusinessType = 'certificates.kiwa.daily.businessType',
  StartDate = 'certificates.kiwa.daily.startDate',
  OwnTransport = 'certificates.kiwa.daily.ownTransport',
  ValidUntil = 'certificates.kiwa.daily.validUntil',
  Remark = 'certificates.kiwa.daily.remark',
  VC = 'certificates.kiwa.daily.vc',
  QSNumber = 'certificates.kiwa.daily.qsNumber',
  GGNr = 'certificates.kiwa.daily.ggNr',
  PigTomorrow = 'certificates.kiwa.daily.pigTomorrow',
  BLK = 'certificates.kiwa.daily.blk',
  CertificateComment = 'certificates.kiwa.daily.certificateComment',
  SalmonellaInfo = 'certificates.kiwa.daily.salmonellaInfo',
}

/**
 * @deprecated use single IntlKeys
 */
export enum CertificatesKiwaWeeklyFarms {
  UBN = 'certificates.kiwa.weekly.farms.ubn',
  Name = 'certificates.kiwa.weekly.farms.name',
  Address = 'certificates.kiwa.weekly.farms.address',
  PostalCode = 'certificates.kiwa.weekly.farms.postalCode',
  Place = 'certificates.kiwa.weekly.farms.place',
  Phone = 'certificates.kiwa.weekly.farms.phone',
  Date = 'certificates.kiwa.weekly.farms.date',
  Sows = 'certificates.kiwa.weekly.farms.sows',
  Vlv = 'certificates.kiwa.weekly.farms.vlv',
  Program = 'certificates.kiwa.weekly.farms.program',
  Remark = 'certificates.kiwa.weekly.farms.remark',
  Type = 'certificates.kiwa.weekly.farms.type',
}

/**
 * @deprecated use single IntlKeys
 */
export enum CertificatesKiwaWeeklyRecognitions {
  UBN = 'certificates.kiwa.weekly.recognitions.ubn',
  Date = 'certificates.kiwa.weekly.recognitions.date',
  Program = 'certificates.kiwa.weekly.recognitions.program',
  Code = 'certificates.kiwa.weekly.recognitions.code',
  Regnr = 'certificates.kiwa.weekly.recognitions.regnr',
  Name = 'certificates.kiwa.weekly.recognitions.name',
}

/**
 * @deprecated use single IntlKeys
 */
export enum CertificatesKiwaWeeklyDeviations {
  UBN = 'certificates.kiwa.weekly.deviations.ubn',
  Program = 'certificates.kiwa.weekly.deviations.program',
  Date = 'certificates.kiwa.weekly.deviations.date',
  QuestionCode = 'certificates.kiwa.weekly.deviations.questionCode',
  Remark = 'certificates.kiwa.weekly.deviations.remark',
  Answer = 'certificates.kiwa.weekly.deviations.answer',
}

import { IntlKeys } from 'lib/localization/keys/__keys';

enum YesNoOriginal {
  YesNo = 'AS_YesNo',
}

enum YesNoSerialized {
  YesNo = 'YesNo',
}

enum YesNoUnserialized {
  YesNo = 'YesNo',
}

enum YesNoOriginalHeaders {
  'yesNoCode' = 'yesNoCode',
  'yesNoName' = 'yesNoName',
}

enum YesNoSerializedHeaders {
  'yesNoCode' = 'yesNoCode',
  'yesNoName' = 'yesNoName',
}
enum YesNoUnserializedHeaders {
  'yesNoCode' = 'yes_no_code',
  'yesNoName' = 'yes_no_name',
}

export const YES_NO_FIELDS = {
  [YesNoUnserialized.YesNo]: {
    original: YesNoOriginal.YesNo,
    serialized: YesNoSerialized.YesNo,
    unserialized: YesNoUnserialized.YesNo,
    view: IntlKeys.referenceTablesAsYesNo, //
    viewName: 'AS_YesNo',

    columns: {
      [YesNoSerializedHeaders.yesNoCode]: {
        original: YesNoOriginalHeaders.yesNoCode,
        serialized: YesNoSerializedHeaders.yesNoCode,
        unserialized: YesNoUnserializedHeaders.yesNoCode,
        view: IntlKeys.referenceTablesYesNoCode,
      },
      [YesNoSerializedHeaders.yesNoName]: {
        original: YesNoOriginalHeaders.yesNoName,
        serialized: YesNoSerializedHeaders.yesNoName,
        unserialized: YesNoUnserializedHeaders.yesNoName,
        view: IntlKeys.referenceTablesYesNoName,
      },
    },
  },
};

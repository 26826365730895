import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { UserPassportPayload } from 'store/entities/userPassports';

import { LABELS, LABELS_SUBMIT, LabelsUnserializedHeaders, LabelsSubmitUnserializedHeaders } from './config';

export type LabelPayload = {
  id: number;
  name: string;
  color: string;
  notes: string;
  user_passports: UserPassportPayload[];
};

export type LabelSerialized = {
  id?: number;
  color: string;
  name: string;
  notes: string;
  userPassportIds: number[];
};

export type LabelSubmitPayload = Pick<LabelPayload, 'color' | 'name' | 'notes'> & {
  user_passport_ids: number[];
};

export type LabelSubmitSerialized = Pick<LabelSerialized, 'id' | 'color' | 'name' | 'notes'> & {
  userPassportIds: number[];
};

const { columns: defaultColumns } = LABELS.Labels;
const { columns: submitColumns } = LABELS_SUBMIT.LabelsSubmit;

const getDefaultSerializer = (data: DocumentData<LabelPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(LABELS.Labels.unserialized, LABELS.Labels.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.color.unserialized, defaultColumns.color.serialized),
      new CellSerializer(defaultColumns.name.unserialized, defaultColumns.name.serialized),
      new CellSerializer(defaultColumns.notes.unserialized, defaultColumns.notes.serialized),
      new CellSerializer(
        defaultColumns.userPassportIds.unserialized,
        defaultColumns.userPassportIds.serialized,
        (data, sheetData, excelRow, propertyOriginalPath) => {
          const userPassports = excelRow[propertyOriginalPath as typeof LabelsUnserializedHeaders.userPassportIds];

          return userPassports.map((userPassport) => userPassport.id);
        },
      ),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<LabelSubmitSerialized[]>) => {
  return new DocumentSerializer<LabelSubmitPayload, LabelSubmitSerialized>(data, [
    new SheetSerializer(LABELS_SUBMIT.LabelsSubmit.serialized, LABELS_SUBMIT.LabelsSubmit.unserialized, [
      new CellSerializer(submitColumns.color.unserialized, submitColumns.color.serialized),
      new CellSerializer(submitColumns.name.unserialized, submitColumns.name.serialized),
      new CellSerializer(submitColumns.notes.unserialized, submitColumns.notes.serialized, undefined, undefined, null),
      new CellSerializer(
        submitColumns.userPassportIds.unserialized,
        submitColumns.userPassportIds.serialized,
        undefined,
        undefined,
        null,
      ),
    ]),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<LabelSerialized[]>) => {
  return new DocumentSerializer<LabelSerialized, LabelSubmitSerialized>(data, [
    new SheetSerializer(LABELS_SUBMIT.LabelsSubmit.serialized, LABELS_SUBMIT.LabelsSubmit.unserialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.color.serialized, submitColumns.color.serialized),
      new CellSerializer(defaultColumns.name.serialized, submitColumns.name.serialized),
      new CellSerializer(
        defaultColumns.notes.serialized,
        submitColumns.notes.serialized,
        undefined,
        undefined,
        undefined,
      ),
      new CellSerializer(
        defaultColumns.userPassportIds.serialized,
        submitColumns.userPassportIds.serialized,
        undefined,
        undefined,
        undefined,
      ),
    ]),
  ]);
};

export const serializeLabel = (serverData: ResourceWrapper<LabelPayload>) => {
  const { serialized, unserialized } = LABELS.Labels;
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

export const serializeLabels = (serverData: ResourcesWrapper<LabelPayload>) => {
  const { unserialized } = LABELS.Labels;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const unserializeLabelForSubmit = (resource: LabelSubmitSerialized): LabelSubmitPayload => {
  const { serialized, unserialized } = LABELS_SUBMIT.LabelsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeLabelStoSS = (resource: LabelSerialized) => {
  const { serialized, unserialized } = LABELS_SUBMIT.LabelsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

import { IntlKeys } from 'lib/localization/keys';

enum FtpUpdatesSerialized {
  FtpUpdates = 'FtpUpdates',
}

enum FtpUpdatesUnserialized {
  FtpUpdates = 'FtpUpdates',
}

export enum FtpUpdatesSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  uploadType = 'uploadType',
  fileName = 'fileName',
  uploadSource = 'uploadSource',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export enum FtpUpdatesCategories {
  groupFinancial = 'group_financial_event_upload',
  groupGroup = 'farm_group_upload',
  groupArrival = 'group_arrival_upload',
  groupMove = 'group_move_upload',
  groupRemoval = 'group_removal_upload',
  groupWeight = 'group_weight_upload',
  codesAnimalRemarks = 'animal_remark_upload',
  codesDefect = 'defect_upload',
  codesDiagnose = 'diagnose_upload',
  codesAnimalScore = 'animal_score_upload',
  codesInsemination = 'insemination_upload',
  codesTreatments = 'treatment_upload',
  groupPurchasedFood = 'purchased_food_upload',
  groupUsedFood = 'used_food_upload',
  codesMatingType = 'mating_type_upload',
  codesWeightType = 'weight_type_upload',
  codesRemovalType = 'removal_type_upload',
  codesAnimalIdentityType = 'animal_identifier_type_upload',
  codesFarmOption = 'farm_option_upload',
  codesBarn = 'code_barn_upload',
  codesRoom = 'code_room_upload',
  codesPen = 'code_pen_upload',
  codesAccount = 'code_account_upload',
  codesEventType = 'code_event_type_upload',
  groupExpense = 'group_expense_upload',
}

export const FtpUpdatesUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  uploadType: 'upload_type',
  fileName: 'file_name',
  uploadSource: 'upload_source',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const FTP_UPDATES = {
  [FtpUpdatesSerialized.FtpUpdates]: {
    serialized: FtpUpdatesSerialized.FtpUpdates,
    unserialized: FtpUpdatesUnserialized.FtpUpdates,
    view: IntlKeys.titleFtpUpdates,

    columns: {
      [FtpUpdatesSerializedHeaders.id]: {
        serialized: FtpUpdatesSerializedHeaders.id,
        unserialized: FtpUpdatesUnserializedHeaders.id,
        view: IntlKeys.farmManagementDataId,
      },
      [FtpUpdatesSerializedHeaders.companyIdentifier]: {
        serialized: FtpUpdatesSerializedHeaders.companyIdentifier,
        unserialized: FtpUpdatesUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [FtpUpdatesSerializedHeaders.farmIdentifier]: {
        serialized: FtpUpdatesSerializedHeaders.farmIdentifier,
        unserialized: FtpUpdatesUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [FtpUpdatesSerializedHeaders.uploadType]: {
        serialized: FtpUpdatesSerializedHeaders.uploadType,
        unserialized: FtpUpdatesUnserializedHeaders.uploadType,
        view: IntlKeys.farmManagementDataUploadType,
      },
      [FtpUpdatesSerializedHeaders.fileName]: {
        serialized: FtpUpdatesSerializedHeaders.fileName,
        unserialized: FtpUpdatesUnserializedHeaders.fileName,
        view: IntlKeys.farmManagementDataFileName,
      },
      [FtpUpdatesSerializedHeaders.uploadSource]: {
        serialized: FtpUpdatesSerializedHeaders.uploadSource,
        unserialized: FtpUpdatesUnserializedHeaders.uploadSource,
        view: IntlKeys.farmManagementDataSource,
      },
      [FtpUpdatesSerializedHeaders.ubnList]: {
        serialized: FtpUpdatesSerializedHeaders.ubnList,
        unserialized: FtpUpdatesUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [FtpUpdatesSerializedHeaders.kvkList]: {
        serialized: FtpUpdatesSerializedHeaders.kvkList,
        unserialized: FtpUpdatesUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [FtpUpdatesSerializedHeaders.registrationNameList]: {
        serialized: FtpUpdatesSerializedHeaders.registrationNameList,
        unserialized: FtpUpdatesUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

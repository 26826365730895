import React, { ReactNode } from 'react';
import { Column } from 'material-table';
import _ from 'lodash';

import Comparator from './CustomComparator';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import theme from 'theme';

export type HybridColumnSettings = Column<object> & React.CSSProperties;

export interface HybridViewI {
  hasCardView?: boolean;
  hasTableView?: boolean;
  hasDetailsView?: boolean;
  isCardHeader?: boolean;
  isDetailsHeader?: boolean;
  isRemovable?: boolean;
  isService?: boolean;
  serviceLabel?: string;
  settings: HybridColumnSettings;
  id?: string;
  renderDetails?: (data: ObjectWithProps) => ReactNode;
  order?: number;
}

const getDefaultHeaderSettings = (field?: string): HybridColumnSettings => {
  let dataType: ReturnType<typeof Comparator.resolveType>;

  return {
    width: 200,
    customSort: (data1, data2) => {
      if (!field) return 0;

      const val1 = data1[field as keyof typeof data1] as string | number;
      const val2 = data2[field as keyof typeof data2] as string | number;

      if (!dataType) {
        dataType = Comparator.resolveType(val1, val2);
      }

      return Comparator.compare(val1, val2, dataType);
    },
    headerStyle: {
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    cellStyle: {
      borderBottom: `1px solid ${theme.colors.border.grey}`,
      textAlign: 'left',
    },
  };
};

export class HybridView implements HybridViewI {
  readonly hasCardView: boolean = false;
  readonly hasDetailsView: boolean = true;
  readonly hasTableView: boolean = true;
  readonly isCardHeader?: boolean = false;
  readonly isDetailsHeader?: boolean = false;
  readonly settings: HybridColumnSettings = {};
  readonly id: string = '';
  readonly isRemovable: boolean = true;
  readonly isService: boolean = false;
  readonly serviceLabel: string = '';
  readonly renderDetails?: (data: ObjectWithProps) => ReactNode;
  readonly order?: number;
  constructor({
    hasCardView = false,
    hasDetailsView = true,
    hasTableView = true,
    isCardHeader = false,
    isDetailsHeader = false,
    isRemovable = true,
    settings = {},
    isService = false,
    serviceLabel = '',
    id = settings.field,
    renderDetails,
    order,
  }: HybridViewI) {
    this.settings = this.generateSettings(settings);
    this.hasDetailsView = hasDetailsView;
    this.hasCardView = hasCardView;
    this.hasTableView = hasTableView;
    this.isCardHeader = isCardHeader;
    this.isDetailsHeader = isDetailsHeader;
    this.id = id!;
    this.isRemovable = isRemovable;
    this.isService = isService;
    this.serviceLabel = serviceLabel;
    this.renderDetails = renderDetails;
    this.order = order;
  }

  private generateSettings = (settings: HybridColumnSettings): HybridColumnSettings => {
    return _.merge(getDefaultHeaderSettings(settings.field), settings);
  };
}

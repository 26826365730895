import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo2DigitsFloating } from 'lib/helpers/renderHelpers';

import { ALLOCATION_MANURE } from 'store/entities/referenceTables/allocationManure/config';

const { columns, view, serialized } = ALLOCATION_MANURE.AllocationManure;

export const allocationManureTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.animalCategory.view,
          field: columns.animalCategory.serialized,
        },
      }),
      new HybridView({
        settings: {
          title: columns.manure.view,
          field: columns.manure.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.manure.serialized] && roundTo2DigitsFloating(data[columns.manure.serialized]),
        },
      }),
      new HybridView({
        settings: {
          title: columns.gas.view,
          field: columns.gas.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.gas.serialized] && roundTo2DigitsFloating(data[columns.gas.serialized]),
        },
      }),
      new HybridView({
        settings: {
          title: columns.electricity.view,
          field: columns.electricity.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.electricity.serialized] && roundTo2DigitsFloating(data[columns.electricity.serialized]),
        },
      }),
      new HybridView({
        settings: {
          title: columns.water.view,
          field: columns.water.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.water.serialized] && roundTo2DigitsFloating(data[columns.water.serialized]),
        },
      }),
    ],
  }),
]);

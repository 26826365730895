import { IntlKeys } from 'lib/localization/keys/__keys';

enum Cl649Original {
  Cl649 = 'cl649',
}

enum Cl649Serialized {
  Cl649 = 'Cl649',
}

enum Cl649Unserialized {
  Cl649 = 'Cl649',
}

enum Cl649OriginalHeaders {
  'code' = 'code',
  'description' = 'description',
  'remarks' = 'notes',
}

enum Cl649SerializedHeaders {
  'code' = 'code',
  'description' = 'description',
  'remarks' = 'remarks',
}
enum Cl649UnserializedHeaders {
  'code' = 'code',
  'description' = 'description',
  'remarks' = 'remarks',
}

export const CL649_FIELDS = {
  [Cl649Unserialized.Cl649]: {
    original: Cl649Original.Cl649,
    serialized: Cl649Serialized.Cl649,
    unserialized: Cl649Unserialized.Cl649,
    view: IntlKeys.referenceTablesCl649,
    viewName: 'cl649',

    columns: {
      [Cl649SerializedHeaders.code]: {
        original: Cl649OriginalHeaders.code,
        serialized: Cl649SerializedHeaders.code,
        unserialized: Cl649UnserializedHeaders.code,
        view: IntlKeys.referenceTablesCl649Code,
      },
      [Cl649SerializedHeaders.description]: {
        original: Cl649OriginalHeaders.description,
        serialized: Cl649SerializedHeaders.description,
        unserialized: Cl649UnserializedHeaders.description,
        view: IntlKeys.referenceTablesCl649Description,
      },
      [Cl649SerializedHeaders.remarks]: {
        original: Cl649OriginalHeaders.remarks,
        serialized: Cl649SerializedHeaders.remarks,
        unserialized: Cl649UnserializedHeaders.remarks,
        view: IntlKeys.referenceTablesCl649Remarks,
      },
    },
  },
};

import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { RootState } from 'store/store';
import { PERMISSIONS } from 'store/auth/permissions';
import { authIsAuthorizedSelector, userHasPermissionSelector } from 'store/auth/reducer';

import { useRouteTitle } from 'routes/useRouteTitle';
import { getUrl, getUrlWithParams } from 'routes';

import UploadButton from './UploadButton';
import LanguageSwitcher from './LanguageSwitcher';
import DashBoardButton from './DashboardButton';

import { AppBar, IconButton, Typography, Slide, Toolbar, useScrollTrigger, makeStyles, Theme } from '@material-ui/core';
import MaterialMenuIcon from '@material-ui/icons/Menu';

interface OwnProps {
  isSideBarOpen: boolean;
  setIsSideBarOpen: Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<OwnProps> = ({ setIsSideBarOpen, isSideBarOpen }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { isAdmin, isAuthorized, canUploadDocuments } = useSelector(mapStateToProps);

  const title = useRouteTitle({ prefix: '' });

  const hide = useScrollTrigger();

  const shouldShowHeader = useMemo(() => !hide || isSideBarOpen, [hide, isSideBarOpen]);

  return (
    <div className={classes.rootAppBatContainer}>
      <Slide appear={false} direction="down" in={shouldShowHeader}>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <div className={classes.vertCentered}>
              {isAuthorized && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  data-testid={'side-menu'}
                  onClick={() => setIsSideBarOpen(!isSideBarOpen)}
                  className={classes.iconButton}
                >
                  <MaterialMenuIcon />
                </IconButton>
              )}

              <Typography className={classes.typography} variant="h6" noWrap data-testid="header-title">
                {formatMessage({ id: title })}
              </Typography>
            </div>

            <div className={classes.spacer} />

            <div className={classes.vertCentered}>
              <LanguageSwitcher />
              <DashBoardButton />
              {canUploadDocuments && <UploadButton url={getUrl('uploadExcel')} />}
              {isAdmin && <UploadButton url={getUrlWithParams('adminUploads', { entity: 'organizations-passports-users' })} />}
            </div>
          </Toolbar>
        </AppBar>
      </Slide>

      <Toolbar />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const hasPermission = userHasPermissionSelector(state);

  return {
    isAuthorized: authIsAuthorizedSelector(state),
    isAdmin: hasPermission(PERMISSIONS.ACCESS_ADMIN_PANEL),
    canUploadDocuments: hasPermission(PERMISSIONS.UPLOAD_DOCUMENTS),
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  vertCentered: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    width: '100%',
  },
  rootAppBatContainer: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  iconButton: {
    marginRight: '1em',
  },
  typography: {
    flexGrow: 1,
    display: 'block',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: 1400,
    padding: 0,
  },
}));

export default React.memo(Header);

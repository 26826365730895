import { CertificatesKiwaDailyKeys } from 'lib/localization/keys/certificates';
import { TitlesKeys } from 'lib/localization/keys/titles';
import { IntlKeys } from 'lib/localization/keys';

enum DailyHeadersOriginal {
  Register = 'Register',
}
enum DailyHeadersSerialized {
  Register = 'Register',
}
enum DailyHeadersUnserialized {
  Register = 'resources',
}
// enum DailyHeadersView {
//   Register = 'Daily',
// }

enum RegisterHeadersOriginal {
  RegNumber = 'Reg Nummer',
  CountryCodeLoc = 'Landcode Loc.',
  BusinessType = 'bedrijfstype',
  StartDate = 'StartDatum',
  OwnTransport = 'EigenVervoer',
  ValidUntil = 'Geldig tot',
  Remark = 'Opmerking',
  VC = 'VC',
  QSNumber = 'QS nummer',
  GGNr = 'GG Nr.',
  PigTomorrow = 'Varken van Morgen',
  BLK = 'BLK',
  CertificateComment = 'Certificaat opmerking',
}

enum RegisterSerializedHeaders {
  RegNumber = 'regNumber',
  CountryCodeLoc = 'countryCodeLoc',
  BusinessType = 'businessType',
  StartDate = 'startDate',
  OwnTransport = 'ownTransport',
  ValidUntil = 'validUntil',
  Remark = 'remark',
  VC = 'vc',
  QSNumber = 'qsNumber',
  GGNr = 'ggNr',
  PigTomorrow = 'pigTomorrow',
  BLK = 'blk',
  CertificateComment = 'certificateComment',
  SalmonellaInfo = 'salmonellaInfo',
}

enum RegisterUnserializedHeaders {
  RegNumber = 'ubn',
  CountryCodeLoc = 'country_code',
  BusinessType = 'business_type',
  StartDate = 'valid_from',
  OwnTransport = 'has_own_transport',
  ValidUntil = 'valid_to',
  Remark = 'remark',
  VC = 'vc',
  QSNumber = 'qs_number',
  GGNr = 'gg_number',
  PigTomorrow = 'pigs_tomorrow',
  BLK = 'blk',
  CertificateComment = 'comment',
  SalmonellaInfo = 'salmonella_details',
}

// Reg Nummer
// Landcode Loc.
// bedrijfstype
// StartDatum
// EigenVervoer
// Geldig tot
// Opmerking
// VC
// QS nummer
// GG Nr.
// Varken van Morgen
// BLK
// Certificaat opmerking

// enum RegisterViewHeaders {
//   RegNumber = 'Reg Nummer',
//   CountryCodeLoc = 'Landcode Loc.',
//   BusinessType = 'bedrijfstype',
//   StartDate = 'StartDatum',
//   OwnTransport = 'EigenVervoer',
//   ValidUntil = 'Geldig tot',
//   Remark = 'Opmerking',
//   VC = 'VC',
//   QSNumber = 'QS nummer',
//   GGNr = 'GG Nr.',
//   PigTomorrow = 'Varken van Morgen',
//   BLK = 'BLK',
//   CertificateComment = 'Certificaat opmerking',
//   SalmonellaInfo = 'Salmonella info',
// }

export const DAILY_DOCUMENT_FIELDS = {
  [DailyHeadersSerialized.Register]: {
    original: DailyHeadersOriginal.Register,
    serialized: DailyHeadersSerialized.Register,
    unserialized: DailyHeadersUnserialized.Register,
    view: TitlesKeys.certificatesDaily,
    viewName: 'Daily',
    columns: {
      [RegisterSerializedHeaders.RegNumber]: {
        original: RegisterHeadersOriginal.RegNumber,
        serialized: RegisterSerializedHeaders.RegNumber,
        unserialized: RegisterUnserializedHeaders.RegNumber,
        view: CertificatesKiwaDailyKeys.RegNumber,
      },
      [RegisterSerializedHeaders.CountryCodeLoc]: {
        original: RegisterHeadersOriginal.CountryCodeLoc,
        serialized: RegisterSerializedHeaders.CountryCodeLoc,
        unserialized: RegisterUnserializedHeaders.CountryCodeLoc,
        view: CertificatesKiwaDailyKeys.CountryCodeLoc,
      },

      [RegisterSerializedHeaders.BusinessType]: {
        original: RegisterHeadersOriginal.BusinessType,
        serialized: RegisterSerializedHeaders.BusinessType,
        unserialized: RegisterUnserializedHeaders.BusinessType,
        view: CertificatesKiwaDailyKeys.BusinessType,
      },
      [RegisterSerializedHeaders.StartDate]: {
        original: RegisterHeadersOriginal.StartDate,
        serialized: RegisterSerializedHeaders.StartDate,
        unserialized: RegisterUnserializedHeaders.StartDate,
        view: IntlKeys.startDate,
      },

      [RegisterSerializedHeaders.OwnTransport]: {
        original: RegisterHeadersOriginal.OwnTransport,
        serialized: RegisterSerializedHeaders.OwnTransport,
        unserialized: RegisterUnserializedHeaders.OwnTransport,
        view: CertificatesKiwaDailyKeys.OwnTransport,
      },

      [RegisterSerializedHeaders.ValidUntil]: {
        original: RegisterHeadersOriginal.ValidUntil,
        serialized: RegisterSerializedHeaders.ValidUntil,
        unserialized: RegisterUnserializedHeaders.ValidUntil,
        view: CertificatesKiwaDailyKeys.ValidUntil,
      },
      [RegisterSerializedHeaders.Remark]: {
        original: RegisterHeadersOriginal.Remark,
        serialized: RegisterSerializedHeaders.Remark,
        unserialized: RegisterUnserializedHeaders.Remark,
        view: CertificatesKiwaDailyKeys.Remark,
      },
      [RegisterSerializedHeaders.VC]: {
        original: RegisterHeadersOriginal.VC,
        serialized: RegisterSerializedHeaders.VC,
        unserialized: RegisterUnserializedHeaders.VC,
        view: CertificatesKiwaDailyKeys.VC,
      },
      [RegisterSerializedHeaders.QSNumber]: {
        original: RegisterHeadersOriginal.QSNumber,
        serialized: RegisterSerializedHeaders.QSNumber,
        unserialized: RegisterUnserializedHeaders.QSNumber,
        view: CertificatesKiwaDailyKeys.QSNumber,
      },
      [RegisterSerializedHeaders.GGNr]: {
        original: RegisterHeadersOriginal.GGNr,
        serialized: RegisterSerializedHeaders.GGNr,
        unserialized: RegisterUnserializedHeaders.GGNr,
        view: CertificatesKiwaDailyKeys.GGNr,
      },
      [RegisterSerializedHeaders.PigTomorrow]: {
        original: RegisterHeadersOriginal.PigTomorrow,
        serialized: RegisterSerializedHeaders.PigTomorrow,
        unserialized: RegisterUnserializedHeaders.PigTomorrow,
        view: CertificatesKiwaDailyKeys.PigTomorrow,
      },
      [RegisterSerializedHeaders.BLK]: {
        original: RegisterHeadersOriginal.BLK,
        serialized: RegisterSerializedHeaders.BLK,
        unserialized: RegisterUnserializedHeaders.BLK,
        view: CertificatesKiwaDailyKeys.BLK,
      },
      [RegisterSerializedHeaders.CertificateComment]: {
        original: RegisterHeadersOriginal.CertificateComment,
        serialized: RegisterSerializedHeaders.CertificateComment,
        unserialized: RegisterUnserializedHeaders.CertificateComment,
        view: CertificatesKiwaDailyKeys.CertificateComment,
      },
      [RegisterSerializedHeaders.SalmonellaInfo]: {
        /* no original here */
        serialized: RegisterSerializedHeaders.SalmonellaInfo,
        unserialized: RegisterUnserializedHeaders.SalmonellaInfo,
        view: CertificatesKiwaDailyKeys.SalmonellaInfo,
      },
    },
  },
};

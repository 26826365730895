import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_PEN } from './config';

export type CodesPenPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  barn_identifier: string;
  room_identifier: string;
  abbreviation: string;
  description: string;
  first_value: string;
  second_value: string;
  code_pen_datetime: string;
  transponder: string;
  number_places: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type CodesPenSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  barnIdentifier: string;
  roomIdentifier: string;
  abbreviation: string;
  description: string;
  firstValue: string;
  secondValue: string;
  codePenDatetime: string;
  transponder: string;
  numberPlaces: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = CODES_PEN.CodesPen;

const getDefaultSerializer = (data: DocumentData<CodesPenPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.barnIdentifier.unserialized, columns.barnIdentifier.serialized),
      new CellSerializer(columns.roomIdentifier.unserialized, columns.roomIdentifier.serialized),
      new CellSerializer(columns.abbreviation.unserialized, columns.abbreviation.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.firstValue.unserialized, columns.firstValue.serialized),
      new CellSerializer(columns.secondValue.unserialized, columns.secondValue.serialized),
      new CellSerializer(columns.codePenDatetime.unserialized, columns.codePenDatetime.serialized),
      new CellSerializer(columns.transponder.unserialized, columns.transponder.serialized),
      new CellSerializer(columns.numberPlaces.unserialized, columns.numberPlaces.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeCodesPen = (serverData: ResourcesWrapper<CodesPenPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

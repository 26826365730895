import { IntlKeys } from 'lib/localization/keys';

enum CodesTreatmentsSerialized {
  CodesTreatments = 'CodesTreatments',
}

enum CodesTreatmentsUnserialized {
  CodesTreatments = 'CodesTreatments',
}

export enum CodesTreatmentsSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  treatmentIdentifier = 'treatmentIdentifier',
  registrationNumber = 'registrationNumber',
  abbreviation = 'abbreviation',
  description = 'description',
  WhPeriod = 'WhPeriod',
  destination = 'destination',
  note = 'note',
  treatmentDatetime = 'treatmentDatetime',
  sows = 'sows',
  sucklers = 'sucklers',
  weaners = 'weaners',
  replstock = 'replstock',
  finishers = 'finishers',
  boars = 'boars',
  treatmentsPerDay = 'treatmentsPerDay',
  firstRepeatTreatment = 'firstRepeatTreatment',
  secondRepeatTreatment = 'secondRepeatTreatment',
  thirdRepeatTreatment = 'thirdRepeatTreatment',
  fourthRepeatTreatment = 'fourthRepeatTreatment',
  fifthRepeatTreatment = 'fifthRepeatTreatment',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesTreatmentsUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  treatmentIdentifier: 'treatment_identifier',
  registrationNumber: 'registration_number',
  abbreviation: 'abbreviation',
  description: 'description',
  WhPeriod: 'wh_period',
  destination: 'destination',
  note: 'note',
  treatmentDatetime: 'treatment_datetime',
  sows: 'sows',
  sucklers: 'sucklers',
  weaners: 'weaners',
  replstock: 'replstock',
  finishers: 'finishers',
  boars: 'boars',
  treatmentsPerDay: 'treatments_per_day',
  firstRepeatTreatment: 'first_repeat_treatment',
  secondRepeatTreatment: 'second_repeat_treatment',
  thirdRepeatTreatment: 'third_repeat_treatment',
  fourthRepeatTreatment: 'fourth_repeat_treatment',
  fifthRepeatTreatment: 'fifth_repeat_treatment',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_TREATMENTS = {
  [CodesTreatmentsSerialized.CodesTreatments]: {
    serialized: CodesTreatmentsSerialized.CodesTreatments,
    unserialized: CodesTreatmentsUnserialized.CodesTreatments,
    view: IntlKeys.farmManagementDataTreatmentsList,

    columns: {
      [CodesTreatmentsSerializedHeaders.id]: {
        serialized: CodesTreatmentsSerializedHeaders.id,
        unserialized: CodesTreatmentsUnserializedHeaders.id,
      },
      [CodesTreatmentsSerializedHeaders.companyIdentifier]: {
        serialized: CodesTreatmentsSerializedHeaders.companyIdentifier,
        unserialized: CodesTreatmentsUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesTreatmentsSerializedHeaders.treatmentIdentifier]: {
        serialized: CodesTreatmentsSerializedHeaders.treatmentIdentifier,
        unserialized: CodesTreatmentsUnserializedHeaders.treatmentIdentifier,
        view: IntlKeys.farmManagementDataTreatmentId,
      },
      [CodesTreatmentsSerializedHeaders.registrationNumber]: {
        serialized: CodesTreatmentsSerializedHeaders.registrationNumber,
        unserialized: CodesTreatmentsUnserializedHeaders.registrationNumber,
        view: IntlKeys.farmManagementDataRegistrationNumber,
      },
      [CodesTreatmentsSerializedHeaders.abbreviation]: {
        serialized: CodesTreatmentsSerializedHeaders.abbreviation,
        unserialized: CodesTreatmentsUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesTreatmentsSerializedHeaders.description]: {
        serialized: CodesTreatmentsSerializedHeaders.description,
        unserialized: CodesTreatmentsUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesTreatmentsSerializedHeaders.WhPeriod]: {
        serialized: CodesTreatmentsSerializedHeaders.WhPeriod,
        unserialized: CodesTreatmentsUnserializedHeaders.WhPeriod,
        view: IntlKeys.farmManagementDataWhPeriod,
      },
      [CodesTreatmentsSerializedHeaders.destination]: {
        serialized: CodesTreatmentsSerializedHeaders.destination,
        unserialized: CodesTreatmentsUnserializedHeaders.destination,
        view: IntlKeys.farmManagementDataDestination,
      },
      [CodesTreatmentsSerializedHeaders.note]: {
        serialized: CodesTreatmentsSerializedHeaders.note,
        unserialized: CodesTreatmentsUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesTreatmentsSerializedHeaders.treatmentDatetime]: {
        serialized: CodesTreatmentsSerializedHeaders.treatmentDatetime,
        unserialized: CodesTreatmentsUnserializedHeaders.treatmentDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesTreatmentsSerializedHeaders.sows]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataSows,
      },
      [CodesTreatmentsSerializedHeaders.sucklers]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataSucklers,
      },
      [CodesTreatmentsSerializedHeaders.weaners]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataWeaners,
      },
      [CodesTreatmentsSerializedHeaders.replstock]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataReplstock,
      },
      [CodesTreatmentsSerializedHeaders.finishers]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataFinishers,
      },
      [CodesTreatmentsSerializedHeaders.boars]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataBoars,
      },
      [CodesTreatmentsSerializedHeaders.treatmentsPerDay]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataTreatmentsPerDay,
      },
      [CodesTreatmentsSerializedHeaders.firstRepeatTreatment]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataFirstRepeatTreatment,
      },
      [CodesTreatmentsSerializedHeaders.secondRepeatTreatment]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataSecondRepeatTreatment,
      },
      [CodesTreatmentsSerializedHeaders.thirdRepeatTreatment]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataThirdRepeatTreatment,
      },
      [CodesTreatmentsSerializedHeaders.fourthRepeatTreatment]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataFourthRepeatTreatment,
      },
      [CodesTreatmentsSerializedHeaders.fifthRepeatTreatment]: {
        serialized: CodesTreatmentsSerializedHeaders.sows,
        unserialized: CodesTreatmentsUnserializedHeaders.sows,
        view: IntlKeys.farmManagementDataFifthRepeatTreatment,
      },
      [CodesTreatmentsSerializedHeaders.ubnList]: {
        serialized: CodesTreatmentsSerializedHeaders.ubnList,
        unserialized: CodesTreatmentsUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesTreatmentsSerializedHeaders.kvkList]: {
        serialized: CodesTreatmentsSerializedHeaders.kvkList,
        unserialized: CodesTreatmentsUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesTreatmentsSerializedHeaders.registrationNameList]: {
        serialized: CodesTreatmentsSerializedHeaders.registrationNameList,
        unserialized: CodesTreatmentsUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

import { Validator } from '../index';
import { CellValidator } from './Cell';
import { ExcelSheets } from './Document';
import { ValidationError, ValidationErrorI, ValidationInfo } from './errors';

export class SheetValidator implements Validator {
  constructor(
    private readonly sheetName: string,
    private readonly viewName: string,
    private readonly cellValidators: Array<CellValidator>) {
  }

  private isSheetExist(data: ExcelSheets) {
    return !!data[this.sheetName];
  }

  private validateData = (data: ExcelSheets) => {
    this.cellValidators.forEach(validator => validator.errors = []);
    if (this.validationSampleExists(data)) {
      for (let i = 0; i < data[this.sheetName].length; i++) {
        this.validateRow(data[this.sheetName][i], i);
      }
      return this.cellValidators.reduce((errors, validator) => ([...errors, ...validator.errors]), [] as ValidationError[]);
    } else {
      return [new ValidationInfo(`Impossible to validate headers for "${this.sheetName}" : sample does not exist`)];
    }
  };

  private validateRow(validationSample: Record<string, unknown>, index: number) {
    return this.cellValidators.reduce((cumulative, validator) => {
      const { errors } = validator.validate(validationSample, this.sheetName, index);

      if (errors.length) {
        cumulative.push(...errors);
      }

      return cumulative;
    }, new Array<ValidationErrorI>());
  }

  private validationSampleExists(data: ExcelSheets) {
    return !!data[this.sheetName]?.[0];
  }

  public validate(data: ExcelSheets) {
    const errors: ValidationError[] = [];

    if (this.isSheetExist(data)) {
      errors.push(...this.validateData(data));
    } else {
      errors.push(new ValidationError(`Validation failed. Sheet "${this.sheetName}" is not found. Check if this is correct ${this.viewName} template`));
    }

    return {
      errors,
      warnings: [],
      infos: [],
    };
  }
}

import { GWP_100_FACTORS_FIELDS } from 'lib/excel/config/referenceTables/gwp100Factors/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = GWP_100_FACTORS_FIELDS.Gwp100Factors;

export const getGwp100FactorsDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.greenhouseGas.original),
      new CellValidator(columns.gwp100Factor.original),
      new CellValidator(columns.gwp100FactorUnit.original),
    ]),
  ]);
};

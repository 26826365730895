import { IntlKeys } from 'lib/localization/keys/__keys';

enum SlaughterMessagesSerialized {
  SlaughterMessages = 'SlaughterMessages',
}

enum SlaughterMessagesUnserialized {
  SlaughterMessages = 'SlaughterMessages',
}

enum SlaughterMessagesSerializedHeaders {
  id = 'id',
  messageId = 'messageId',
  supplierRelationNumber = 'supplierRelationNumber',
  messageDate = 'messageDate',
  currency = 'currency',
  slaughterHouseId = 'slaughterHouseId',
  slaughterDate = 'slaughterDate',
  slaughters = 'slaughters',
  slaughterPlaceId = 'slaughterPlaceId',
  supplierId = 'supplierId',
  messageSequenceNumber = 'messageSequenceNumber',
  animalsSuppliedCount = 'animalsSuppliedCount',
  labels = 'labels',
}

enum SlaughterMessagesUnserializedHeaders {
  id = 'id',
  messageId = 'message_id',
  supplierRelationNumber = 'farmer_id',
  messageDate = 'message_date',
  currency = 'currency',
  slaughterHouseId = 'slaughterhouse_id',
  slaughterDate = 'slaughter_date',
  slaughters = 'slaughters',
  slaughterPlaceId = 'slaughter_place_id',
  supplierId = 'ubn_list',
  messageSequenceNumber = 'message_sequence_number',
  animalsSuppliedCount = 'animals_supplied_count',
  labels = 'labels',
}

export const SLAUGHTER_MESSAGES = {
  [SlaughterMessagesSerialized.SlaughterMessages]: {
    serialized: SlaughterMessagesSerialized.SlaughterMessages,
    unserialized: SlaughterMessagesUnserialized.SlaughterMessages,
    view: IntlKeys.titleSlaughterMessages,
    columns: {
      [SlaughterMessagesSerializedHeaders.id]: {
        serialized: SlaughterMessagesSerializedHeaders.id,
        unserialized: SlaughterMessagesUnserializedHeaders.id,
        view: IntlKeys.serviceBlockchainMessageId,
      },
      [SlaughterMessagesSerializedHeaders.messageId]: {
        serialized: SlaughterMessagesSerializedHeaders.messageId,
        unserialized: SlaughterMessagesUnserializedHeaders.messageId,
        view: IntlKeys.messageId,
      },
      [SlaughterMessagesSerializedHeaders.slaughterPlaceId]: {
        serialized: SlaughterMessagesSerializedHeaders.slaughterPlaceId,
        unserialized: SlaughterMessagesUnserializedHeaders.slaughterPlaceId,
        view: IntlKeys.slaughterSlaughterPlaceId,
      },
      [SlaughterMessagesSerializedHeaders.supplierRelationNumber]: {
        serialized: SlaughterMessagesSerializedHeaders.supplierRelationNumber,
        unserialized: SlaughterMessagesUnserializedHeaders.supplierRelationNumber,
        view: IntlKeys.slaughterFarmerId,
      },
      [SlaughterMessagesSerializedHeaders.supplierId]: {
        serialized: SlaughterMessagesSerializedHeaders.supplierId,
        unserialized: SlaughterMessagesUnserializedHeaders.supplierId,
        view: IntlKeys.slaughterUbnList,
      },
      [SlaughterMessagesSerializedHeaders.messageDate]: {
        serialized: SlaughterMessagesSerializedHeaders.messageDate,
        unserialized: SlaughterMessagesUnserializedHeaders.messageDate,
        view: IntlKeys.slaughterMessageDate,
      },
      [SlaughterMessagesSerializedHeaders.currency]: {
        serialized: SlaughterMessagesSerializedHeaders.currency,
        unserialized: SlaughterMessagesUnserializedHeaders.currency,
        view: IntlKeys.slaughterCurrency,
      },
      [SlaughterMessagesSerializedHeaders.slaughterHouseId]: {
        serialized: SlaughterMessagesSerializedHeaders.slaughterHouseId,
        unserialized: SlaughterMessagesUnserializedHeaders.slaughterHouseId,
        view: IntlKeys.slaughterSlaughterHouseId,
      },
      [SlaughterMessagesSerializedHeaders.slaughterDate]: {
        serialized: SlaughterMessagesSerializedHeaders.slaughterDate,
        unserialized: SlaughterMessagesUnserializedHeaders.slaughterDate,
        view: IntlKeys.slaughterSlaughterDate,
      },
      [SlaughterMessagesSerializedHeaders.slaughters]: {
        serialized: SlaughterMessagesSerializedHeaders.slaughters,
        unserialized: SlaughterMessagesUnserializedHeaders.slaughters,
        view: IntlKeys.slaughterSlaughters,
      },
      [SlaughterMessagesSerializedHeaders.messageSequenceNumber]: {
        serialized: SlaughterMessagesSerializedHeaders.messageSequenceNumber,
        unserialized: SlaughterMessagesUnserializedHeaders.messageSequenceNumber,
        view: IntlKeys.slaughterMessageSequenceNumber,
      },
      [SlaughterMessagesSerializedHeaders.animalsSuppliedCount]: {
        serialized: SlaughterMessagesSerializedHeaders.animalsSuppliedCount,
        unserialized: SlaughterMessagesUnserializedHeaders.animalsSuppliedCount,
        view: IntlKeys.slaughterAnimalsSuppliedCount,
      },
      [SlaughterMessagesSerializedHeaders.labels]: {
        serialized: SlaughterMessagesSerializedHeaders.labels,
        unserialized: SlaughterMessagesUnserializedHeaders.labels,
        view: IntlKeys.titleLabels,
      },
    },
  },
};

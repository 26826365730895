import { CFP_GFLI_FEEDPRINT_TABLE } from 'lib/excel/config/referenceTables/cfpGfliFeedprintTable/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = CFP_GFLI_FEEDPRINT_TABLE.CfpGfliFeedprintTable;

export const getcfpGfliFeedprintTableDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.feedPrintCode.serialized, extractPropName: columns.feedPrintCode.view }),
      new DisplayHeader({ headerName: columns.cvbCode.serialized, extractPropName: columns.cvbCode.view }),
      new DisplayHeader({ headerName: columns.source.serialized, extractPropName: columns.source.view }),
      new DisplayHeader({ headerName: columns.productNameEn.serialized, extractPropName: columns.productNameEn.view }),
      new DisplayHeader({ headerName: columns.productNameNl.serialized, extractPropName: columns.productNameNl.view }),
      new DisplayHeader({ headerName: columns.productCodeEu.serialized, extractPropName: columns.productCodeEu.view }),
      new DisplayHeader({ headerName: columns.productNameEu.serialized, extractPropName: columns.productNameEu.view }),
      new DisplayHeader({ headerName: columns.origin.serialized, extractPropName: columns.origin.view }),
      new DisplayHeader({ headerName: columns.climate.serialized, extractPropName: columns.climate.view }),
    ]),
  ]);
};

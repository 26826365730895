import { GREENHOUSE_GAS_EMISSION } from 'lib/excel/config/referenceTables/greenhouseGasEmission/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = GREENHOUSE_GAS_EMISSION.GreenhouseGasEmission;

export const serializeGreenhouseGasEmissionData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.country.original, columns.country.serialized),
      new CellSerializer(columns.location.original, columns.location.serialized),
      new CellSerializer(columns.category.original, columns.category.serialized),
      new CellSerializer(columns.account.original, columns.account.serialized),
      new CellSerializer(columns.extraExternalId.original, columns.extraExternalId.serialized),
      new CellSerializer(columns.name.original, columns.name.serialized),
      new CellSerializer(columns.inputUnit.original, columns.inputUnit.serialized),
      new CellSerializer(columns.conversionFactor.original, columns.conversionFactor.serialized),
      new CellSerializer(columns.conversionFormula.original, columns.conversionFormula.serialized),
      new CellSerializer(columns.emissionFactors.original, columns.emissionFactors.serialized),
      new CellSerializer(columns.co2Unit.original, columns.co2Unit.serialized),
    ]),
  ]);
};

import { asyncAction, syncAction } from 'lib/actions';

export const fetchVriFirstReportResourcesAction = asyncAction('vriComparisonTool/fetchFirstReportResources');
export const updateVriFirstReportResourcesAction = asyncAction('vriCalculated/updateFirstReportResources');
export const fetchVriFirstReportStatisticsAction = asyncAction('vriComparisonTool/fetchFirstReportStatistics');

export const fetchVriSecondReportResourcesAction = asyncAction('vriComparisonTool/fetchSecondReportResources');
export const updateVriSecondReportResourcesAction = asyncAction('vriCalculated/updateSecondReportResources');
export const fetchVriSecondReportStatisticsAction = asyncAction('vriComparisonTool/fetchSecondReportStatistics');

export const resetVriResourcesAction = syncAction('vriComparisonTool/resetVriResources');
export const visitVriComparisonToolAction = asyncAction('vriComparisonTool/visit');

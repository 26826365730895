import React from 'react';
import { useSelector } from 'react-redux';

import { authUserSelector } from 'store/auth/reducer';

import { makeStyles, Theme } from '@material-ui/core';

const ProfileInfo = () => {
  const classes = useStyles();
  const user = useSelector(authUserSelector);

  return (
    <div className={classes.profile}>
      <h3 className={classes.name}>{user.fullName}</h3>

      <span className={classes.email}>{user.email}</span>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  profile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 16,
    margin: '16px 0 8px',
    wordBreak: 'break-word',

    '& > svg': {
      fontSize: 80,
      width: 80,
      height: 80,
    },
  },
  name: {
    marginBottom: 8,
    fontSize: '1.5em',
    fontWeight: 'normal',
  },
  email: {
    fontSize: '0.875em',
    lineHeight: '16px',
    letterSpacing: 0.4,
    color: theme.colors.dimGray,
  },
}));

export default ProfileInfo;

import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { IntlKeys } from 'lib/localization/keys/__keys';

import { AnimalPassportsSerializedEntry } from 'store/entities/animalPassports';
import { getUrl } from 'routes';

import DataContainer, { DataType } from '../components/DataContainer';
import GridContainer from '../components/GridContainer';
import { generateFiltersQuery } from '../utils';

interface OwnProps {
  data: AnimalPassportsSerializedEntry['certificates'];
}

const CERTIFICATES_DETAILS: Record<
  string,
  {
    routeKey: string;
    label: string;
  }
> = {
  ikbCertificate: {
    routeKey: 'ikbCertificates',
    label: 'IKB',
  },
  welfareCertificate: {
    routeKey: 'welfareCertificates',
    label: 'Welfare',
  },
  dailyCertificate: {
    routeKey: 'certificates',
    label: 'Kiwa Daily',
  },
};

const Certificates: React.FC<OwnProps> = ({ data }) => {
  const location = useLocation();

  const certificates = useMemo(() => {
    //
    const certificatesConfig = Object.entries(data).map(([certKey, certificateData]) => {
      if (!certificateData) return;

      const { validFrom, validTo } = certificateData;

      return {
        certificateType: certKey,
        validFrom,
        validTo,
        isActive: moment().isAfter(validFrom) && moment().isBefore(validTo),
      };
    });

    return certificatesConfig.filter(Boolean) as NonNullable<typeof certificatesConfig[0]>[];
  }, [data]);

  const { formatMessage } = useIntl();

  return (
    <div>
      {certificates.map(({ certificateType, isActive, validTo }) => {
        const { routeKey, label } = CERTIFICATES_DETAILS[certificateType];

        return (
          <GridContainer
            key={certificateType}
            detailsLink={
              certificateType in CERTIFICATES_DETAILS
                ? getUrl(routeKey) + generateFiltersQuery(location.search, 'validDate')
                : undefined
            }
          >
            <DataContainer label={formatMessage({ id: IntlKeys.animalPassportCertificatesCertificate })} data={label} />

            <div>
              <DataContainer
                label={formatMessage({ id: IntlKeys.animalPassportCertificatesStatus })}
                data={isActive ? 'True' : 'False'}
              />
              <DataContainer
                label={formatMessage({ id: IntlKeys.animalPassportCertificatesValidUntil })}
                data={validTo}
                dataType={DataType.Date}
              />
            </div>
          </GridContainer>
        );
      })}
    </div>
  );
};

export default React.memo(Certificates);

import { PENS_TYPE_SOW_BARN_FIELDS } from '../structure';
import { DisplayDocument } from '../../../../display/DisplayDocument';
import { DisplayTab } from '../../../../display/DisplayTab';
import { DisplayHeader } from '../../../../display/DisplayHeader';

const { columns, view } = PENS_TYPE_SOW_BARN_FIELDS.PensTypeSowBarn;

export const getPensTypeSowBarnDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({
        headerName: columns.pensTypeSowBarnCode.serialized,
        extractPropName: columns.pensTypeSowBarnCode.view,
      }),
      new DisplayHeader({
        headerName: columns.pensTypeSowBarnName.serialized,
        extractPropName: columns.pensTypeSowBarnName.view,
      }),
    ]),
  ]);
};

import React from 'react';
import { useIntl } from 'react-intl';

import { IntlKeys } from 'lib/localization/keys/__keys';
import { GeneticaTypeSerialized } from 'store/entities/animalPassports';

import DataContainer, { DataType } from '../components/DataContainer';
import GridContainer from '../components/GridContainer';

interface OwnProps {
  data: GeneticaTypeSerialized;
}

const Genetics: React.FC<OwnProps> = ({ data: { mothersStatistic, fathersStatistic } }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {mothersStatistic && Object.entries(mothersStatistic).map(([type, count], key) => (
        <GridContainer key={key}>
          <DataContainer label={formatMessage({ id: IntlKeys.animalPassportGeneticsFemaleType })} data={type} />
          <DataContainer
            label={formatMessage({ id: IntlKeys.animalPassportGeneticsFemaleAmount })}
            data={count}
            dataType={DataType.Number}
          />
        </GridContainer>
      ))}

      {fathersStatistic && Object.entries(fathersStatistic).map(([type, count], key) => (
        <GridContainer key={key}>
          <DataContainer label={formatMessage({ id: IntlKeys.animalPassportGeneticsMaleType })} data={type} />
          <DataContainer
            label={formatMessage({ id: IntlKeys.animalPassportGeneticsMaleAmount })}
            data={count}
            dataType={DataType.Number}
          />
        </GridContainer>
      ))}
    </div>
  );
};

export default React.memo(Genetics);

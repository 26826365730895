import { IntlKeys } from 'lib/localization/keys';

enum GroupExpenseSerialized {
  GroupExpense = 'GroupExpense',
}

enum GroupExpenseUnserialized {
  GroupExpense = 'GroupExpense',
}

export enum GroupExpenseSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  farmIdentifier = 'farmIdentifier',
  finEventIdentifier = 'finEventIdentifier',
  periodFrom = 'periodFrom',
  periodTo = 'periodTo',
  percentSows = 'percentSows',
  percentFinishers = 'percentFinishers',
  percentReplGilts = 'percentReplGilts',
  percentWeaners = 'percentWeaners',
  expenseDatetime = 'expenseDatetime',
  accountIdentifier = 'accountIdentifier',
  number = 'number',
}

export const GroupExpenseUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  farmIdentifier: 'farm_identifier',
  finEventIdentifier: 'fin_event_identifier',
  periodFrom: 'period_from',
  periodTo: 'period_to',
  percentSows: 'percent_sows',
  percentFinishers: 'percent_finishers',
  percentReplGilts: 'percent_repl_gilts',
  percentWeaners: 'percent_weaners',
  expenseDatetime: 'expense_datetime',
  accountIdentifier: 'account_identifier',
  number: 'number',
} as const;

export const GROUP_EXPENSE = {
  [GroupExpenseSerialized.GroupExpense]: {
    serialized: GroupExpenseSerialized.GroupExpense,
    unserialized: GroupExpenseUnserialized.GroupExpense,
    view: IntlKeys.farmManagementDataExpenseList,

    columns: {
      [GroupExpenseSerializedHeaders.id]: {
        serialized: GroupExpenseSerializedHeaders.id,
        unserialized: GroupExpenseUnserializedHeaders.id,
      },
      [GroupExpenseSerializedHeaders.companyIdentifier]: {
        serialized: GroupExpenseSerializedHeaders.companyIdentifier,
        unserialized: GroupExpenseUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [GroupExpenseSerializedHeaders.farmIdentifier]: {
        serialized: GroupExpenseSerializedHeaders.farmIdentifier,
        unserialized: GroupExpenseUnserializedHeaders.farmIdentifier,
        view: IntlKeys.farmManagementDataFarmId,
      },
      [GroupExpenseSerializedHeaders.finEventIdentifier]: {
        serialized: GroupExpenseSerializedHeaders.finEventIdentifier,
        unserialized: GroupExpenseUnserializedHeaders.finEventIdentifier,
        view: IntlKeys.farmManagementDataFinancialEventId,
      },
      [GroupExpenseSerializedHeaders.periodFrom]: {
        serialized: GroupExpenseSerializedHeaders.periodFrom,
        unserialized: GroupExpenseUnserializedHeaders.periodFrom,
        view: IntlKeys.farmManagementDataPeriodFrom,
      },
      [GroupExpenseSerializedHeaders.periodTo]: {
        serialized: GroupExpenseSerializedHeaders.periodTo,
        unserialized: GroupExpenseUnserializedHeaders.periodTo,
        view: IntlKeys.farmManagementDataPeriodTo,
      },
      [GroupExpenseSerializedHeaders.percentSows]: {
        serialized: GroupExpenseSerializedHeaders.percentSows,
        unserialized: GroupExpenseUnserializedHeaders.percentSows,
        view: IntlKeys.farmManagementDataPercentSows,
      },
      [GroupExpenseSerializedHeaders.percentFinishers]: {
        serialized: GroupExpenseSerializedHeaders.percentFinishers,
        unserialized: GroupExpenseUnserializedHeaders.percentFinishers,
        view: IntlKeys.farmManagementDataPercentFinishers,
      },
      [GroupExpenseSerializedHeaders.percentReplGilts]: {
        serialized: GroupExpenseSerializedHeaders.percentReplGilts,
        unserialized: GroupExpenseUnserializedHeaders.percentReplGilts,
        view: IntlKeys.farmManagementDataPercentReplGilts,
      },
      [GroupExpenseSerializedHeaders.percentWeaners]: {
        serialized: GroupExpenseSerializedHeaders.percentWeaners,
        unserialized: GroupExpenseUnserializedHeaders.percentWeaners,
        view: IntlKeys.farmManagementDataPercentWeaners,
      },
      [GroupExpenseSerializedHeaders.expenseDatetime]: {
        serialized: GroupExpenseSerializedHeaders.expenseDatetime,
        unserialized: GroupExpenseUnserializedHeaders.expenseDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [GroupExpenseSerializedHeaders.accountIdentifier]: {
        serialized: GroupExpenseSerializedHeaders.accountIdentifier,
        unserialized: GroupExpenseUnserializedHeaders.accountIdentifier,
        view: IntlKeys.farmManagementDataAcctId,
      },
      [GroupExpenseSerializedHeaders.number]: {
        serialized: GroupExpenseSerializedHeaders.number,
        unserialized: GroupExpenseUnserializedHeaders.number,
        view: IntlKeys.farmManagementDataNumber,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys/__keys';
import { FarmEventsKeys } from 'lib/localization/keys/farm';
import { ServiceKeys } from 'lib/localization/keys/service';

export enum FarmsUpdatesLogSerialized {
  UpdatesLog = 'updatesLog',
}

export enum FarmsUpdatesLogUnserialized {
  UpdatesLog = 'updatesLog',
}

export enum FarmsUpdatesLogView {
  UpdatesLog = 'Updates Log',
}

export enum FarmsUpdatesLogSerializedHeaders {
  id = 'id',
  modifiedAt = 'modifiedAt',
  action = 'action',
  ubn = 'ubn',
  stableName = 'stableName',
  departmentName = 'departmentName',
  modifiedByUser = 'modifiedByUser',
  modifiedByOrganization = 'modifiedByOrganization',
}

export enum FarmsUpdatesLogUnserializedHeaders {
  id = 'id',
  modifiedAt = 'modified_at',
  action = 'action',
  ubn = 'ubn',
  stableName = 'stable_name',
  departmentName = 'department_name',
  modifiedByUser = 'modified_by_user',
  modifiedByOrganization = 'modified_by_organization',
}

export enum FarmsUpdatesLogViewHeaders {
  id = 'id',
  modifiedAt = 'Last Modified On',
  action = 'Action',
  ubn = 'UBN',
  stableName = 'Stable Name',
  departmentName = 'Department Name',
  modifiedByUser = 'Last Modified by (user)',
  modifiedByOrganization = 'Last Modified by (organisation)',
}

export enum FarmEventUnserializedHeader {
  id = 'id',
  resourceUbn = 'resource_ubn',
  resourceNames = 'resource_names',
  resourceAccessId = 'resource_access_id',
  resourceType = 'resource_type',
  eventType = 'event_type',
  createdAt = 'created_at',
  updatedAt = 'updated_at',
  user = 'user',
  email = 'email',
  name = 'name',
}

export enum FarmEventSerializedHeader {
  id = 'id',
  resourceUbn = 'resourceUbn',
  resourceNames = 'resourceNames',
  resourceAccessId = 'resourceAccessId',
  resourceType = 'resourceType',
  eventType = 'eventType',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  user = 'user',
  email = 'email',
  name = 'name',
}

export const UPDATES_LOG = {
  [FarmsUpdatesLogSerialized.UpdatesLog]: {
    serialized: FarmsUpdatesLogSerialized.UpdatesLog,
    unserialized: FarmsUpdatesLogUnserialized.UpdatesLog,
    view: IntlKeys.titleFarmUpdates,
    columns: {
      [FarmEventSerializedHeader.resourceUbn]: {
        serialized: FarmEventSerializedHeader.resourceUbn,
        unserialized: FarmEventUnserializedHeader.resourceUbn,
        view: FarmEventsKeys.ubn,
      },
      [FarmEventSerializedHeader.resourceNames]: {
        serialized: FarmEventSerializedHeader.resourceNames,
        unserialized: FarmEventUnserializedHeader.resourceNames,
        view: FarmEventsKeys.stableName,
      },
      [FarmEventSerializedHeader.resourceType]: {
        serialized: FarmEventSerializedHeader.resourceType,
        unserialized: FarmEventUnserializedHeader.resourceType,
        view: FarmEventsKeys.type,
      },
      [FarmEventSerializedHeader.eventType]: {
        serialized: FarmEventSerializedHeader.eventType,
        unserialized: FarmEventUnserializedHeader.eventType,
        view: FarmEventsKeys.action,
      },
      [FarmEventSerializedHeader.createdAt]: {
        serialized: FarmEventSerializedHeader.createdAt,
        unserialized: FarmEventUnserializedHeader.createdAt,
        view: ServiceKeys.createdAt,
      },
      [FarmEventSerializedHeader.updatedAt]: {
        serialized: FarmEventSerializedHeader.updatedAt,
        unserialized: FarmEventUnserializedHeader.updatedAt,
        view: ServiceKeys.updatedAt,
      },
      [FarmEventSerializedHeader.user]: {
        serialized: `${FarmEventSerializedHeader.user}.${FarmEventSerializedHeader.name}`,
        unserialized: `${FarmEventUnserializedHeader.user}.${FarmEventUnserializedHeader.name}`,
        view: FarmEventsKeys.lastModifiedBy,
      },
    },
  },
};

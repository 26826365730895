import { PENS_TYPE_SOW_BARN_FIELDS } from '../structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = PENS_TYPE_SOW_BARN_FIELDS.PensTypeSowBarn;

export const getPensTypeSowBarnDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.pensTypeSowBarnCode.original),
      new CellValidator(columns.pensTypeSowBarnName.original),
    ]),
  ]);
};

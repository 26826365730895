import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';

import { ChildRouteType } from './index';
import { notifyRouteMountedAction } from 'store/reducers/routerReducer';

export const injectCurrentRouteTracking = (route: ChildRouteType) => {
  const Cmp: React.FC<RouteComponentProps> = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isNotified, setNotified] = useState(false);

    const Component = route.component as React.ComponentType<RouteComponentProps>;

    useLayoutEffect(() => {
      dispatch(notifyRouteMountedAction(route.key));
      setNotified(true);
    }, [dispatch, location.pathname]);

    return <>{isNotified && <Component {...props} />}</>;
  };

  return Cmp;
};

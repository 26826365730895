import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { ResourcesWrapper } from 'lib/http/utils';
import { serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';

import { UserPassportPayload, UserPassportSerialized } from 'store/entities/userPassports';
import { CERTIFICATES_GROUPED } from './config';
import { getUserPassportsCellSerializer } from '../../userPassportsCellSerializer';

export interface CertificatesGroupedRaw {
  id: number;
  ubn: string;
  valid_from: string;
  valid_to: string;
  pigs_tomorrow: boolean;
  blk: boolean;
  certificate_status: string;

  user_passports?: Array<Pick<UserPassportPayload, 'id' | 'registration_name' | 'kvk' | 'ubn'>>;
}

export interface CertificatesGroupedSerializedEntry {
  id: number;
  ubn: string;
  validFrom: string;
  validTo: string;
  pigsTomorrow: boolean;
  blk: boolean;
  certificateStatus: string;

  userPassports?: Array<Pick<UserPassportSerialized, 'id' | 'registrationName' | 'kvk' | 'ubn'>>;
}

const { columns, serialized, unserialized } = CERTIFICATES_GROUPED.CertificatesGrouped;

const getSerializer = (data: DocumentData<CertificatesGroupedRaw[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      //
      new CellSerializer(columns.id.unserialized, columns.id.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(columns.ubn.unserialized, columns.ubn.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(
        columns.validFrom.unserialized,
        columns.validFrom.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),
      new CellSerializer(columns.validTo.unserialized, columns.validTo.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(
        columns.pigsTomorrow.unserialized,
        columns.pigsTomorrow.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),
      new CellSerializer(columns.blk.unserialized, columns.blk.serialized, serializeObjectSnakeCaseToCamelCase),
      new CellSerializer(
        columns.certificateStatus.unserialized,
        columns.certificateStatus.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),

      getUserPassportsCellSerializer(columns.userPassports.unserialized, columns.userPassports.serialized),
    ]),
  ]);
};

export const serializeCertificatesGrouped = (serverData: ResourcesWrapper<CertificatesGroupedRaw>) => {
  //
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);
  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys/__keys';
import { GeneticKeys } from 'lib/localization/keys/genetics';

enum GeneticsSerialized {
  Genetics = 'Genetics',
}

enum GeneticsUnserialized {
  Genetics = 'Genetics',
}

export enum GeneticSerializedHeaders {
  ubn = 'ubn',
  semenProducerUBN = 'semenProducerUBN',
  deliveryDate = 'deliveryDate',
  maleType = 'maleType',
  maleAmount = 'maleAmount',
  femaleType = 'femaleType',
  femaleAmount = 'femaleAmount',
}

export enum GeneticUnserializedHeaders {
  ubn = 'ubn',
  semenProducerUbn = 'semen_producer_ubn',
  deliveryDate = 'delivery_date',
  maleType = 'male_type',
  maleNumber = 'male_amount',
  femaleType = 'female_type',
  femaleAmount = 'female_amount',
}

export const GENETICS = {
  [GeneticsSerialized.Genetics]: {
    serialized: GeneticsSerialized.Genetics,
    unserialized: GeneticsUnserialized.Genetics,
    view: IntlKeys.titleGenetics,
    columns: {
      [GeneticSerializedHeaders.semenProducerUBN]: {
        serialized: GeneticSerializedHeaders.semenProducerUBN,
        unserialized: GeneticUnserializedHeaders.semenProducerUbn,
        view: GeneticKeys.semenProducerUbn,
      },
      [GeneticSerializedHeaders.ubn]: {
        serialized: GeneticSerializedHeaders.ubn,
        unserialized: GeneticUnserializedHeaders.ubn,
        view: GeneticKeys.ubn,
      },
      [GeneticSerializedHeaders.deliveryDate]: {
        serialized: GeneticSerializedHeaders.deliveryDate,
        unserialized: GeneticUnserializedHeaders.deliveryDate,
        view: GeneticKeys.deliveryDate,
      },
      [GeneticSerializedHeaders.maleType]: {
        serialized: GeneticSerializedHeaders.maleType,
        unserialized: GeneticUnserializedHeaders.maleType,
        view: GeneticKeys.maleType,
      },
      [GeneticSerializedHeaders.maleAmount]: {
        serialized: GeneticSerializedHeaders.maleAmount,
        unserialized: GeneticUnserializedHeaders.maleNumber,
        view: GeneticKeys.maleAmount,
      },
      [GeneticSerializedHeaders.femaleType]: {
        serialized: GeneticSerializedHeaders.femaleType,
        unserialized: GeneticUnserializedHeaders.femaleType,
        view: GeneticKeys.femaleType,
      },
      [GeneticSerializedHeaders.femaleAmount]: {
        serialized: GeneticSerializedHeaders.femaleAmount,
        unserialized: GeneticUnserializedHeaders.femaleAmount,
        view: GeneticKeys.femaleAmount,
      },
    },
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';

import rowContextMenu from './rowContextMenu';

import { GROUP_GROUPS } from 'store/entities/farmManagementData/groupGroups/config';

const { columns, view, serialized } = GROUP_GROUPS.GroupGroups;

export const groupGroupsTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.companyIdentifier.view,
          field: columns.companyIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.farmIdentifier.view,
          field: columns.farmIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ubnList.view,
          field: columns.ubnList.serialized,
          render: (data: ObjectWithProps) => data[columns.ubnList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.kvkList.view,
          field: columns.kvkList.serialized,
          render: (data: ObjectWithProps) => data[columns.kvkList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.registrationNameList.view,
          field: columns.registrationNameList.serialized,
          render: (data: ObjectWithProps) => data[columns.registrationNameList.serialized].join(', '),
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupIdentifier.view,
          field: columns.groupIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupName.view,
          field: columns.groupName.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.dateEnd.view,
          field: columns.dateEnd.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.notes.view,
          field: columns.notes.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.barnIdentifier.view,
          field: columns.barnIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.roomIdentifier.view,
          field: columns.roomIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.penIdentifier.view,
          field: columns.penIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.groupDatetime.view,
          field: columns.groupDatetime.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.firstDeliveryGrowthExp.view,
          field: columns.firstDeliveryGrowthExp.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.lastDeliveryGrowthExp.view,
          field: columns.lastDeliveryGrowthExp.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.firstDeliveryPerc.view,
          field: columns.firstDeliveryPerc.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.lastDeliveryPerc.view,
          field: columns.lastDeliveryPerc.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.firstDeliveryWeight.view,
          field: columns.firstDeliveryWeight.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.lastDeliveryWeight.view,
          field: columns.lastDeliveryWeight.serialized,
        },
      }),
    ],
  }),
]);

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer, ObjectWithProps, PropertyPath } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import {
  VriSerializedHeaders,
  VriUnserializedHeaders,
  VriSerialized,
  VriUnserialized,
  VriStatisticsResourceUnserialized,
  VriReportsConstantsUnserialized,
} from 'lib/tables/config/vri/config';
import { get } from 'lodash';
import { VRI_DOCUMENT_FIELDS } from 'lib/excel/config/vriDatabase/structure';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { dateTimeString } from 'lib/excel/serilizers/utils';

const statisticsEntitySerializer = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propertyOriginalName: PropertyPath,
) => {
  const proceedData: ObjectWithProps = get(excelRow, propertyOriginalName, '');

  return {
    [VriSerializedHeaders.yourScore]: proceedData[VriUnserializedHeaders.yourScore],
    [VriSerializedHeaders.averageTop20Score]: proceedData[VriUnserializedHeaders.averageTop20Score],
    [VriSerializedHeaders.difference]: proceedData[VriUnserializedHeaders.difference],
  };
};

const constantsSerializer = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propertyOriginalName: PropertyPath,
) => {
  const proceedData: ObjectWithProps = get(excelRow, propertyOriginalName, '');

  return {
    [VriSerializedHeaders.growth]: proceedData[VriUnserializedHeaders.growth],
    [VriSerializedHeaders.feedIntakePerDay]: proceedData[VriUnserializedHeaders.feedIntakePerDay],
    [VriSerializedHeaders.ewConversion]: proceedData[VriUnserializedHeaders.ewConversion],
    [VriSerializedHeaders.layingBigWeight]: proceedData[VriUnserializedHeaders.layingBigWeight],
    [VriSerializedHeaders.loss]: proceedData[VriUnserializedHeaders.loss],
    [VriSerializedHeaders.genderWeight]: proceedData[VriUnserializedHeaders.genderWeight],
    [VriSerializedHeaders.baconWeight]: proceedData[VriUnserializedHeaders.baconWeight],
    [VriSerializedHeaders.meatPercent]: proceedData[VriUnserializedHeaders.meatPercent],
    [VriSerializedHeaders.epg]: proceedData[VriUnserializedHeaders.epg],
    [VriSerializedHeaders.averageWeight]: proceedData[VriUnserializedHeaders.averageWeight],
    [VriSerializedHeaders.averageWeightCorrection]: proceedData[VriUnserializedHeaders.averageWeightCorrection],
    [VriSerializedHeaders.averageFatThickness]: proceedData[VriUnserializedHeaders.averageFatThickness],
    [VriSerializedHeaders.averageBeaconCorrection]: proceedData[VriUnserializedHeaders.averageBeaconCorrection],
    [VriSerializedHeaders.averageMuscleThickness]: proceedData[VriUnserializedHeaders.averageMuscleThickness],
    [VriSerializedHeaders.averageMuscleCorrection]: proceedData[VriUnserializedHeaders.averageMuscleCorrection],
    [VriSerializedHeaders.averageMeat]: proceedData[VriUnserializedHeaders.averageMeat],
    [VriSerializedHeaders.averageMeatCorrection]: proceedData[VriUnserializedHeaders.averageMeatCorrection],
    [VriSerializedHeaders.averageClassificationCorrection]:
      proceedData[VriUnserializedHeaders.averageClassificationCorrection],
    [VriSerializedHeaders.averageSexAllowance]: proceedData[VriUnserializedHeaders.averageSexAllowance],
    [VriSerializedHeaders.averageConceptSurcharge]: proceedData[VriUnserializedHeaders.averageConceptSurcharge],
    [VriSerializedHeaders.pigsInDraft]: proceedData[VriUnserializedHeaders.pigsInDraft],
    [VriSerializedHeaders.balanceWithRespectToBase]: proceedData[VriUnserializedHeaders.balanceWithRespectToBase],
    [VriSerializedHeaders.approvedCarcass]: proceedData[VriUnserializedHeaders.approvedCarcass],
    [VriSerializedHeaders.pleurisy]: proceedData[VriUnserializedHeaders.pleurisy],
    [VriSerializedHeaders.inflamedSkin]: proceedData[VriUnserializedHeaders.inflamedSkin],
    [VriSerializedHeaders.inflamedLeg]: proceedData[VriUnserializedHeaders.inflamedLeg],
    [VriSerializedHeaders.approvedOrgan]: proceedData[VriUnserializedHeaders.approvedOrgan],
    [VriSerializedHeaders.liverAfg]: proceedData[VriUnserializedHeaders.liverAfg],
    [VriSerializedHeaders.lungAfg]: proceedData[VriUnserializedHeaders.lungAfg],
    [VriSerializedHeaders.healthy]: proceedData[VriUnserializedHeaders.healthy],
    [VriSerializedHeaders.euroPerPig]: proceedData[VriUnserializedHeaders.euroPerPig],
    [VriSerializedHeaders.vriTotal]: proceedData[VriUnserializedHeaders.vriTotal],
    [VriSerializedHeaders.vriEpg]: proceedData[VriUnserializedHeaders.vriEpg],
    [VriSerializedHeaders.vriBalance]: proceedData[VriUnserializedHeaders.vriBalance],
    [VriSerializedHeaders.vriHealth]: proceedData[VriUnserializedHeaders.vriHealth],
  };
};

const getVriStatisticsResourceSerializer = (data: DocumentData<VriStatisticsResourceUnserialized[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriUnserialized.vri, VriSerialized.vri, [
      new CellSerializer(VriUnserializedHeaders.vriTotal, VriSerializedHeaders.vriTotal, statisticsEntitySerializer),
      new CellSerializer(VriUnserializedHeaders.lapSpeed, VriSerializedHeaders.lapSpeed, statisticsEntitySerializer),
      new CellSerializer(
        VriUnserializedHeaders.deliveredAnimals,
        VriSerializedHeaders.deliveredAnimals,
        statisticsEntitySerializer,
      ),
      new CellSerializer(VriUnserializedHeaders.delivered, VriSerializedHeaders.delivered, statisticsEntitySerializer),
      new CellSerializer(VriUnserializedHeaders.vriEpg, VriSerializedHeaders.vriEpg, statisticsEntitySerializer),
      new CellSerializer(
        VriUnserializedHeaders.euroPerPig,
        VriSerializedHeaders.euroPerPig,
        statisticsEntitySerializer,
      ),
      new CellSerializer(VriUnserializedHeaders.growth, VriSerializedHeaders.growth, statisticsEntitySerializer),
      new CellSerializer(
        VriUnserializedHeaders.feedIntakePerDay,
        VriSerializedHeaders.feedIntakePerDay,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.ewConversion,
        VriSerializedHeaders.ewConversion,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.layingBigWeight,
        VriSerializedHeaders.layingBigWeight,
        statisticsEntitySerializer,
      ),
      new CellSerializer(VriUnserializedHeaders.loss, VriSerializedHeaders.loss, statisticsEntitySerializer),
      new CellSerializer(
        VriUnserializedHeaders.vriBalanceWithRespectToBase,
        VriSerializedHeaders.vriBalanceWithRespectToBase,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.balanceWithRespectToBase,
        VriSerializedHeaders.balanceWithRespectToBase,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageWeight,
        VriSerializedHeaders.averageWeight,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageWeightCorrection,
        VriSerializedHeaders.averageWeightCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageFatThickness,
        VriSerializedHeaders.averageFatThickness,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageBeaconCorrection,
        VriSerializedHeaders.averageBeaconCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageMuscleThickness,
        VriSerializedHeaders.averageMuscleThickness,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageMuscleCorrection,
        VriSerializedHeaders.averageMuscleCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageMeat,
        VriSerializedHeaders.averageMeat,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageMeatCorrection,
        VriSerializedHeaders.averageMeatCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageClassificationCorrection,
        VriSerializedHeaders.averageClassificationCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageSexAllowance,
        VriSerializedHeaders.averageSexAllowance,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.averageConceptSurcharge,
        VriSerializedHeaders.averageConceptSurcharge,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.pigsInDraft,
        VriSerializedHeaders.pigsInDraft,
        statisticsEntitySerializer,
      ),
      new CellSerializer(VriUnserializedHeaders.vriHealth, VriSerializedHeaders.vriHealth, statisticsEntitySerializer),
      new CellSerializer(
        VriUnserializedHeaders.euroPerPig,
        VriSerializedHeaders.euroPerPig,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.approvedCarcass,
        VriSerializedHeaders.approvedCarcass,
        statisticsEntitySerializer,
      ),
      new CellSerializer(VriUnserializedHeaders.pleurisy, VriSerializedHeaders.pleurisy, statisticsEntitySerializer),
      new CellSerializer(
        VriUnserializedHeaders.inflamedSkin,
        VriSerializedHeaders.inflamedSkin,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.inflamedLeg,
        VriSerializedHeaders.inflamedLeg,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriUnserializedHeaders.approvedOrgan,
        VriSerializedHeaders.approvedOrgan,
        statisticsEntitySerializer,
      ),
      new CellSerializer(VriUnserializedHeaders.liverAfg, VriSerializedHeaders.liverAfg, statisticsEntitySerializer),
      new CellSerializer(VriUnserializedHeaders.lungAfg, VriSerializedHeaders.lungAfg, statisticsEntitySerializer),
      new CellSerializer(VriUnserializedHeaders.healthy, VriSerializedHeaders.healthy, statisticsEntitySerializer),
      new CellSerializer(
        VriUnserializedHeaders.businessTotal,
        VriSerializedHeaders.businessTotal,
        (
          data: DocumentData,
          sheetData: Array<object>,
          excelRow: ObjectWithProps,
          propertyOriginalName: PropertyPath,
        ) => {
          const proceedData: ObjectWithProps = get(excelRow, propertyOriginalName, '');
          return {
            [VriSerializedHeaders.vriTotal]: proceedData[VriUnserializedHeaders.vriTotal],
            [VriSerializedHeaders.epgEuroPerPig]: proceedData[VriUnserializedHeaders.epgEuroPerPig],
            [VriSerializedHeaders.balanceWithRespectToBase]:
              proceedData[VriUnserializedHeaders.balanceWithRespectToBase],
            [VriSerializedHeaders.euroPerPig]: proceedData[VriUnserializedHeaders.euroPerPig],
          };
        },
      ),
    ]),
  ]);
};

const getVriResourcesItemSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriUnserialized.vri, VriSerialized.vri, [
      new CellSerializer(VriUnserializedHeaders.id, VriSerializedHeaders.id),
      new CellSerializer(VriUnserializedHeaders.vriSerialNumber, VriSerializedHeaders.vriSerialNumber),
      new CellSerializer(VriUnserializedHeaders.ubn, VriSerializedHeaders.ubn),
      new CellSerializer(VriUnserializedHeaders.name, VriSerializedHeaders.name),
      new CellSerializer(VriUnserializedHeaders.total, VriSerializedHeaders.total),
      new CellSerializer(VriUnserializedHeaders.vriEpg, VriSerializedHeaders.vriEpg),
      new CellSerializer(VriUnserializedHeaders.epgSerialNumber, VriSerializedHeaders.epgSerialNumber),
      new CellSerializer(VriUnserializedHeaders.growth, VriSerializedHeaders.growth),
      new CellSerializer(VriUnserializedHeaders.feedIntakePerDay, VriSerializedHeaders.feedIntakePerDay),
      new CellSerializer(VriUnserializedHeaders.ewConversion, VriSerializedHeaders.ewConversion),
      new CellSerializer(VriUnserializedHeaders.layingBigWeight, VriSerializedHeaders.layingBigWeight),
      new CellSerializer(VriUnserializedHeaders.loss, VriSerializedHeaders.loss),
      new CellSerializer(VriUnserializedHeaders.epg, VriSerializedHeaders.epg),
      new CellSerializer(VriUnserializedHeaders.vriClassification, VriSerializedHeaders.vriClassification),
      new CellSerializer(VriUnserializedHeaders.epgOrderNumber, VriSerializedHeaders.epgOrderNumber),
      new CellSerializer(
        VriUnserializedHeaders.balanceWithRespectToBase,
        VriSerializedHeaders.balanceWithRespectToBase,
      ),
      new CellSerializer(VriUnserializedHeaders.averageWeight, VriSerializedHeaders.averageWeight),
      new CellSerializer(VriUnserializedHeaders.averageWeightCorrection, VriSerializedHeaders.averageWeightCorrection),
      new CellSerializer(VriUnserializedHeaders.averageFatThickness, VriSerializedHeaders.averageFatThickness),
      new CellSerializer(VriUnserializedHeaders.averageBeaconCorrection, VriSerializedHeaders.averageBeaconCorrection),
      new CellSerializer(VriUnserializedHeaders.averageMuscleThickness, VriSerializedHeaders.averageMuscleThickness),
      new CellSerializer(VriUnserializedHeaders.averageMuscleCorrection, VriSerializedHeaders.averageMuscleCorrection),
      new CellSerializer(VriUnserializedHeaders.averageMeat, VriSerializedHeaders.averageMeat),
      new CellSerializer(VriUnserializedHeaders.averageMeatCorrection, VriSerializedHeaders.averageMeatCorrection),
      new CellSerializer(
        VriUnserializedHeaders.averageClassificationCorrection,
        VriSerializedHeaders.averageClassificationCorrection,
      ),
      new CellSerializer(VriUnserializedHeaders.averageSexAllowance, VriSerializedHeaders.averageSexAllowance),
      new CellSerializer(VriUnserializedHeaders.averageConceptSurcharge, VriSerializedHeaders.averageConceptSurcharge),
      new CellSerializer(VriUnserializedHeaders.pigsInDraft, VriSerializedHeaders.pigsInDraft),
      new CellSerializer(VriUnserializedHeaders.vriHealth, VriSerializedHeaders.vriHealth),
      new CellSerializer(VriUnserializedHeaders.epgNumber, VriSerializedHeaders.epgNumber),
      new CellSerializer(VriUnserializedHeaders.euroPerPig, VriSerializedHeaders.euroPerPig),
      new CellSerializer(VriUnserializedHeaders.approvedCarcass, VriSerializedHeaders.approvedCarcass),
      new CellSerializer(VriUnserializedHeaders.pleurisy, VriSerializedHeaders.pleurisy),
      new CellSerializer(VriUnserializedHeaders.inflamedSkin, VriSerializedHeaders.inflamedSkin),
      new CellSerializer(VriUnserializedHeaders.inflamedLeg, VriSerializedHeaders.inflamedLeg),
      new CellSerializer(VriUnserializedHeaders.approvedOrgan, VriSerializedHeaders.approvedOrgan),
      new CellSerializer(VriUnserializedHeaders.leverAfg, VriSerializedHeaders.leverAfg),
      new CellSerializer(VriUnserializedHeaders.longAfg, VriSerializedHeaders.longAfg),
      new CellSerializer(VriUnserializedHeaders.healthy, VriSerializedHeaders.healthy),
      new CellSerializer(VriUnserializedHeaders.createdAt, VriSerializedHeaders.createdAt, dateTimeString),
      new CellSerializer(VriUnserializedHeaders.updatedAt, VriSerializedHeaders.updatedAt, dateTimeString),
      new CellSerializer(
        VriUnserializedHeaders.fileUpload,
        VriSerializedHeaders.fileUpload,
        (
          data: DocumentData,
          sheetData: Array<object>,
          excelRow: ObjectWithProps,
          propertyOriginalName: PropertyPath,
        ) => {
          const proceedData: ObjectWithProps = get(excelRow, propertyOriginalName, '');

          if (!proceedData) return {};

          return {
            [VriSerializedHeaders.id]: proceedData[VriUnserializedHeaders.id],
            [VriSerializedHeaders.file]: proceedData[VriUnserializedHeaders.file],
            [VriSerializedHeaders.checksum]: proceedData[VriUnserializedHeaders.checksum],
            [VriSerializedHeaders.isEdited]: proceedData[VriUnserializedHeaders.isEdited],
            [VriSerializedHeaders.uploadToken]: proceedData[VriUnserializedHeaders.uploadToken],
            [VriSerializedHeaders.uploadType]: proceedData[VriUnserializedHeaders.uploadType],
            [SERVICE_FIELDS.uploadedAt.serialized]: proceedData[SERVICE_FIELDS.uploadedAt.unserialized],
            [VriSerializedHeaders.source]: proceedData[VriUnserializedHeaders.source],
            [VriSerializedHeaders.status]: proceedData[VriUnserializedHeaders.status],
            [VriSerializedHeaders.statusMessage]: proceedData[VriUnserializedHeaders.statusMessage],
            [SERVICE_FIELDS.createdAt.serialized]: proceedData[SERVICE_FIELDS.createdAt.unserialized],
            [VriSerializedHeaders.user]: proceedData[VriUnserializedHeaders.user],
          };
        },
      ),
      new CellSerializer(VriUnserializedHeaders.reportUpload, VriSerializedHeaders.reportUpload),
      new CellSerializer(VriUnserializedHeaders.endManufacturer, VriSerializedHeaders.endManufacturer),
      new CellSerializer(VriUnserializedHeaders.feedingSystem, VriSerializedHeaders.feedingSystem),
    ]),
  ]);
};

const getVriResourcesSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriUnserialized.vri, VriSerialized.vri, [
      new CellSerializer(VriUnserializedHeaders.id, VriSerializedHeaders.id),
      new CellSerializer(VriUnserializedHeaders.rating, VriSerializedHeaders.rating),
      new CellSerializer(VriUnserializedHeaders.reportDateFrom, VriSerializedHeaders.reportDateFrom, dateTimeString),
      new CellSerializer(VriUnserializedHeaders.reportDateTo, VriSerializedHeaders.reportDateTo, dateTimeString),
      new CellSerializer(VriUnserializedHeaders.totalMin, VriSerializedHeaders.totalMin),
      new CellSerializer(VriUnserializedHeaders.totalMax, VriSerializedHeaders.totalMax),
      new CellSerializer(VriUnserializedHeaders.epgMin, VriSerializedHeaders.epgMin),
      new CellSerializer(VriUnserializedHeaders.epgMax, VriSerializedHeaders.epgMax),
      new CellSerializer(VriUnserializedHeaders.euroPerPigMin, VriSerializedHeaders.euroPerPigMin),
      new CellSerializer(VriUnserializedHeaders.euroPerPigMax, VriSerializedHeaders.euroPerPigMax),
      new CellSerializer(
        VriUnserializedHeaders.balanceWithRespectToBaseMin,
        VriSerializedHeaders.balanceWithRespectToBaseMin,
      ),
      new CellSerializer(
        VriUnserializedHeaders.balanceWithRespectToBaseMax,
        VriSerializedHeaders.balanceWithRespectToBaseMax,
      ),
      new CellSerializer(VriUnserializedHeaders.constants, VriSerializedHeaders.constants),
    ]),
  ]);
};

const getVriReportsStatisticsResourceSerializer = (data: DocumentData<VriReportsConstantsUnserialized[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriUnserialized.vri, VriSerialized.vri, [
      new CellSerializer(VriUnserializedHeaders.top20, VriSerializedHeaders.top20, constantsSerializer),
      new CellSerializer(VriUnserializedHeaders.avg, VriSerializedHeaders.avg, constantsSerializer),
    ]),
  ]);
};

export const serializeVriResourcesData = (response: ResourcesWrapper) => {
  const resources = response.data.resources;
  return resources.map((resourceItem) => {
    const reportsData = {
      [VriSerialized.vri]: resourceItem.reports,
    };
    const resourceData = {
      [VriSerialized.vri]: [resourceItem],
    };

    const reportsSerializer = getVriResourcesItemSerializer(reportsData);
    const resourceSerializer = getVriResourcesSerializer(resourceData);

    const reports = reportsSerializer.serialize()[VriSerialized.vri];
    const resource = resourceSerializer.serialize()[VriSerialized.vri];

    return {
      ...resource[0],
      reports,
    };
  });
};

export const serializeVriStatistics = (response: ResourceWrapper) => {
  const resource = { [VriSerialized.vri]: [response.data.resource] };
  const serializer = getVriStatisticsResourceSerializer(resource);
  return serializer.serialize()[VriSerialized.vri][0];
};

export const serializeFileReports = (response: ResourcesWrapper) => {
  const resources = response.data.resources;

  const reports = resources.map((resourceItem) => {
    const resourceData = {
      [VriSerialized.vri]: [resourceItem],
    };

    //getVriResourcesItemSerializer
    const reportsSerializer = getVriResourcesItemSerializer(resourceData);

    const reports = reportsSerializer.serialize()[VriSerialized.vri];

    return {
      ...reports[0],
    };
  });

  return {
    [VRI_DOCUMENT_FIELDS.database.serialized]: reports,
  };
};

export const serializeVriReportsConstants = (response: ResourceWrapper<VriReportsConstantsUnserialized>) => {
  const resource = response.data.resource;

  const constants = {
    [VriSerialized.vri]: [resource],
  };

  const serializer = getVriReportsStatisticsResourceSerializer(constants);

  return serializer.serialize()[VriSerialized.vri][0];
};

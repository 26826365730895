import { InvoicesKeys } from 'lib/localization/keys/invoices';
import { TitlesKeys } from 'lib/localization/keys/titles';

enum InvoiceDetailsSerialized {
  InvoiceDetails = 'InvoiceDetails',
}

enum InvoiceDetailsUnserialized {
  InvoiceDetails = 'InvoiceDetails',
}

enum InvoiceDetailsView {
  InvoiceDetails = 'Invoice Details',
}

export enum InvoiceDetailsSerializedHeaders {
  invoice = 'invoice',
  unit = 'unit',
  invoiceMessageId = 'invoiceMessageId',
  lineNumber = 'lineNumber',
  deliveryDate = 'deliveryDate',
  deliveryNoteNumber = 'deliveryNoteNumber',
  deliveryNoteLineNumber = 'deliveryNoteLineNumber',
  productQuantity = 'productQuantity',
  productArticleCode = 'productArticleCode',
  productDescription = 'productDescription',
  productArticleGroupCode = 'productArticleGroupCode',
  productArticleGroupCodeDescription = 'productArticleGroupCodeDescription',
  productEnergeticValue = 'productEnergeticValue',
}

export enum InvoiceDetailsUnserializedHeaders {
  invoice = 'invoice',
  unit = 'unit',
  invoiceMessageId = 'invoice_message_id',
  lineNumber = 'line_number',
  deliveryDate = 'delivery_date',
  deliveryNoteNumber = 'delivery_note_number',
  deliveryNoteLineNumber = 'delivery_note_line_number',
  productQuantity = 'product_quantity',
  productArticleCode = 'product_article_code',
  productDescription = 'product_description',
  productArticleGroupCode = 'product_article_group_code',
  productArticleGroupCodeDescription = 'product_article_group_code_description',
  productEnergeticValue = 'product_energetic_value',
}

enum InvoiceDetailsViewHeaders {
  invoice = 'Invoice',
  unit = 'Unit',
  invoiceMessageId = 'Invoice Message Id',
  lineNumber = 'Line Number',
  deliveryDate = 'Delivery Date',
  deliveryNoteNumber = 'Delivery Note Number',
  deliveryNoteLineNumber = 'Delivery Note Line Number',
  productQuantity = 'Product Quantity',
  productArticleCode = 'Product Article Code',
  productDescription = 'Product Description',
  productArticleGroupCode = 'Product Article Group Code',
  productArticleGroupCodeDescription = 'Product Article Group Code Description',
  productEnergeticValue = 'Product Energetic Value',
}

export const INVOICE_DETAILS = {
  [InvoiceDetailsSerialized.InvoiceDetails]: {
    serialized: InvoiceDetailsSerialized.InvoiceDetails,
    unserialized: InvoiceDetailsUnserialized.InvoiceDetails,
    view: TitlesKeys.invoiceDetails,
    columns: {
      [InvoiceDetailsSerializedHeaders.invoice]: {
        serialized: InvoiceDetailsSerializedHeaders.invoice,
        unserialized: InvoiceDetailsUnserializedHeaders.invoice,
        view: InvoicesKeys.invoice,
      },
      [InvoiceDetailsSerializedHeaders.unit]: {
        serialized: InvoiceDetailsSerializedHeaders.unit,
        unserialized: InvoiceDetailsUnserializedHeaders.unit,
        view: InvoicesKeys.unit,
      },
      [InvoiceDetailsSerializedHeaders.invoiceMessageId]: {
        serialized: InvoiceDetailsSerializedHeaders.invoiceMessageId,
        unserialized: InvoiceDetailsUnserializedHeaders.invoiceMessageId,
        view: InvoicesKeys.invoiceMessageId,
      },
      [InvoiceDetailsSerializedHeaders.lineNumber]: {
        serialized: InvoiceDetailsSerializedHeaders.lineNumber,
        unserialized: InvoiceDetailsUnserializedHeaders.lineNumber,
        view: InvoicesKeys.lineNumber,
      },
      [InvoiceDetailsSerializedHeaders.deliveryDate]: {
        serialized: InvoiceDetailsSerializedHeaders.deliveryDate,
        unserialized: InvoiceDetailsUnserializedHeaders.deliveryDate,
        view: InvoicesKeys.deliveryDate,
      },
      [InvoiceDetailsSerializedHeaders.deliveryNoteNumber]: {
        serialized: InvoiceDetailsSerializedHeaders.deliveryNoteNumber,
        unserialized: InvoiceDetailsUnserializedHeaders.deliveryNoteNumber,
        view: InvoicesKeys.deliveryNoteNumber,
      },
      [InvoiceDetailsSerializedHeaders.deliveryNoteLineNumber]: {
        serialized: InvoiceDetailsSerializedHeaders.deliveryNoteLineNumber,
        unserialized: InvoiceDetailsUnserializedHeaders.deliveryNoteLineNumber,
        view: InvoicesKeys.deliveryNoteLineNumber,
      },
      [InvoiceDetailsSerializedHeaders.productQuantity]: {
        serialized: InvoiceDetailsSerializedHeaders.productQuantity,
        unserialized: InvoiceDetailsUnserializedHeaders.productQuantity,
        view: InvoicesKeys.productQuantity,
      },
      [InvoiceDetailsSerializedHeaders.productArticleCode]: {
        serialized: InvoiceDetailsSerializedHeaders.productArticleCode,
        unserialized: InvoiceDetailsUnserializedHeaders.productArticleCode,
        view: InvoicesKeys.productArticleCode,
      },
      [InvoiceDetailsSerializedHeaders.productDescription]: {
        serialized: InvoiceDetailsSerializedHeaders.productDescription,
        unserialized: InvoiceDetailsUnserializedHeaders.productDescription,
        view: InvoicesKeys.productDescription,
      },
      [InvoiceDetailsSerializedHeaders.productArticleGroupCode]: {
        serialized: InvoiceDetailsSerializedHeaders.productArticleGroupCode,
        unserialized: InvoiceDetailsUnserializedHeaders.productArticleGroupCode,
        view: InvoicesKeys.productArticleGroupCode,
      },
      [InvoiceDetailsSerializedHeaders.productArticleGroupCodeDescription]: {
        serialized: InvoiceDetailsSerializedHeaders.productArticleGroupCodeDescription,
        unserialized: InvoiceDetailsUnserializedHeaders.productArticleGroupCodeDescription,
        view: InvoicesKeys.productArticleGroupCodeDescription,
      },
      [InvoiceDetailsSerializedHeaders.productEnergeticValue]: {
        serialized: InvoiceDetailsSerializedHeaders.productEnergeticValue,
        unserialized: InvoiceDetailsUnserializedHeaders.productEnergeticValue,
        view: InvoicesKeys.productEnergeticValue,
      },
    },
  },
};

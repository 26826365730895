import { ALLOCATION_MANURE_FIELDS } from 'lib/excel/config/referenceTables/allocationManure/structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundTo2DigitsFloating } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = ALLOCATION_MANURE_FIELDS.AllocationManure;

export const getAllocationManureView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.animalCategory.serialized,
        viewPropName: columns.animalCategory.view,
      }),
      new CellViewer({
        serializedPropName: columns.manure.serialized,
        viewPropName: columns.manure.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({
        serializedPropName: columns.gas.serialized,
        viewPropName: columns.gas.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({
        serializedPropName: columns.electricity.serialized,
        viewPropName: columns.electricity.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
      new CellViewer({
        serializedPropName: columns.water.serialized,
        viewPropName: columns.water.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseFloat, roundTo2DigitsFloating),
      }),
    ]),
  ]);
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum CvbTableOriginal {
  CvbTable = 'CFP_CVB_table',
}

enum CvbTableSerialized {
  CvbTable = 'CvbTable',
}

enum CvbTableUnserialized {
  CvbTable = 'CvbTable',
}

enum CvbTableOriginalHeaders {
  'productCode' = 'product code',
  'productSubCode' = 'product_subcode',
  'productType' = 'soort_product',
  'productNameNL' = 'productnaam',
  'classNameNL' = 'klassenaam',
  'subClassNameNL' = 'subklassenaam',
  'productNameEN' = 'product name',
  'classNameEN' = 'class name',
  'subClassNameEN' = 'sub class name',
  'ds' = 'DS',
  'dsUnit' = 'DS Unit',
  'ras' = 'RAS',
  'rasUnit' = 'RAS Unit',
  'rein' = 'REin',
  'reinUnit' = 'REin Unit',
  're' = 'RE',
  'reUnit' = 'RE Unit',
  'cVcRe' = 'C_VC_RE',
  'cVcReUnit' = 'C_VC_RE Unit',
  'cVcOs' = 'C_VC_OS',
  'cVcOsUnit' = 'C_VC_OS Unit',
}

enum CvbTableSerializedHeaders {
  'productCode' = 'productCode',
  'productSubCode' = 'productSubCode',
  'productType' = 'productType',
  'productNameNL' = 'productNameNL',
  'classNameNL' = 'classNameNL',
  'subClassNameNL' = 'subClassNameNL',
  'productNameEN' = 'productNameEN',
  'classNameEN' = 'classNameEN',
  'subClassNameEN' = 'subClassNameEN',
  'ds' = 'ds',
  'dsUnit' = 'dsUnit',
  'ras' = 'ras',
  'rasUnit' = 'rasUnit',
  'rein' = 'rein',
  'reinUnit' = 'reinUnit',
  're' = 're',
  'reUnit' = 'reUnit',
  'cVcRe' = 'cVcRe',
  'cVcReUnit' = 'cVcReUnit',
  'cVcOs' = 'cVcOs',
  'cVcOsUnit' = 'cVcOsUnit',
}
enum CvbTableUnserializedHeaders {
  'productCode' = 'product_code',
  'productSubCode' = 'product_sub_code',
  'productType' = 'product_type',
  'productNameNL' = 'product_name_nl',
  'classNameNL' = 'class_name_nl',
  'subClassNameNL' = 'sub_class_name',
  'productNameEN' = 'product_name_en',
  'classNameEN' = 'class_name_en',
  'subClassNameEN' = 'sub_class_name_en',
  'ds' = 'ds',
  'dsUnit' = 'ds_unit',
  'ras' = 'ras',
  'rasUnit' = 'remote_unit',
  'rein' = 'rein',
  'reinUnit' = 'rein_unit',
  're' = 're',
  'reUnit' = 're_unit',
  'cVcRe' = 'c_vc_re',
  'cVcReUnit' = 'c_vc_re_unit',
  'cVcOs' = 'c_vc_os',
  'cVcOsUnit' = 'c_vc_os_unit',
}

export const CVB_TABLE_FIELDS = {
  [CvbTableSerialized.CvbTable]: {
    original: CvbTableOriginal.CvbTable,
    serialized: CvbTableSerialized.CvbTable,
    unserialized: CvbTableUnserialized.CvbTable,
    view: IntlKeys.referenceTablesCvbTable,
    viewName: 'CFP_CVB_Table',

    columns: {
      [CvbTableSerializedHeaders.productCode]: {
        original: CvbTableOriginalHeaders.productCode,
        serialized: CvbTableSerializedHeaders.productCode,
        unserialized: CvbTableUnserializedHeaders.productCode,
        view: IntlKeys.referenceTablesProductCode,
      },
      [CvbTableSerializedHeaders.productSubCode]: {
        original: CvbTableOriginalHeaders.productSubCode,
        serialized: CvbTableSerializedHeaders.productSubCode,
        unserialized: CvbTableUnserializedHeaders.productSubCode,
        view: IntlKeys.referenceTablesProductSubCode,
      },
      [CvbTableSerializedHeaders.productType]: {
        original: CvbTableOriginalHeaders.productType,
        serialized: CvbTableSerializedHeaders.productType,
        unserialized: CvbTableUnserializedHeaders.productType,
        view: IntlKeys.referenceTablesProductType,
      },
      [CvbTableSerializedHeaders.productNameNL]: {
        original: CvbTableOriginalHeaders.productNameNL,
        serialized: CvbTableSerializedHeaders.productNameNL,
        unserialized: CvbTableUnserializedHeaders.productNameNL,
        view: IntlKeys.referenceTablesProductName,
      },
      [CvbTableSerializedHeaders.classNameNL]: {
        original: CvbTableOriginalHeaders.classNameNL,
        serialized: CvbTableSerializedHeaders.classNameNL,
        unserialized: CvbTableUnserializedHeaders.classNameNL,
        view: IntlKeys.referenceTablesClassName,
      },
      [CvbTableSerializedHeaders.subClassNameNL]: {
        original: CvbTableOriginalHeaders.subClassNameNL,
        serialized: CvbTableSerializedHeaders.subClassNameNL,
        unserialized: CvbTableUnserializedHeaders.subClassNameNL,
        view: IntlKeys.referenceTablesSubClassName,
      },
      [CvbTableSerializedHeaders.productNameEN]: {
        original: CvbTableOriginalHeaders.productNameEN,
        serialized: CvbTableSerializedHeaders.productNameEN,
        unserialized: CvbTableUnserializedHeaders.productNameEN,
        view: IntlKeys.referenceTablesProductNameEN,
      },
      [CvbTableSerializedHeaders.classNameEN]: {
        original: CvbTableOriginalHeaders.classNameEN,
        serialized: CvbTableSerializedHeaders.classNameEN,
        unserialized: CvbTableUnserializedHeaders.classNameEN,
        view: IntlKeys.referenceTablesClassNameEN,
      },
      [CvbTableSerializedHeaders.subClassNameEN]: {
        original: CvbTableOriginalHeaders.subClassNameEN,
        serialized: CvbTableSerializedHeaders.subClassNameEN,
        unserialized: CvbTableUnserializedHeaders.subClassNameEN,
        view: IntlKeys.referenceTablesSubClassNameEN,
      },
      [CvbTableSerializedHeaders.ds]: {
        original: CvbTableOriginalHeaders.ds,
        serialized: CvbTableSerializedHeaders.ds,
        unserialized: CvbTableUnserializedHeaders.ds,
        view: IntlKeys.referenceTablesDs,
      },
      [CvbTableSerializedHeaders.dsUnit]: {
        original: CvbTableOriginalHeaders.dsUnit,
        serialized: CvbTableSerializedHeaders.dsUnit,
        unserialized: CvbTableUnserializedHeaders.dsUnit,
        view: IntlKeys.referenceTablesDsUnit,
      },
      [CvbTableSerializedHeaders.ras]: {
        original: CvbTableOriginalHeaders.ras,
        serialized: CvbTableSerializedHeaders.ras,
        unserialized: CvbTableUnserializedHeaders.ras,
        view: IntlKeys.referenceTablesRas,
      },
      [CvbTableSerializedHeaders.rasUnit]: {
        original: CvbTableOriginalHeaders.rasUnit,
        serialized: CvbTableSerializedHeaders.rasUnit,
        unserialized: CvbTableUnserializedHeaders.rasUnit,
        view: IntlKeys.referenceTablesRasUnit,
      },
      [CvbTableSerializedHeaders.rein]: {
        original: CvbTableOriginalHeaders.rein,
        serialized: CvbTableSerializedHeaders.rein,
        unserialized: CvbTableUnserializedHeaders.rein,
        view: IntlKeys.referenceTablesRein,
      },
      [CvbTableSerializedHeaders.reinUnit]: {
        original: CvbTableOriginalHeaders.reinUnit,
        serialized: CvbTableSerializedHeaders.reinUnit,
        unserialized: CvbTableUnserializedHeaders.reinUnit,
        view: IntlKeys.referenceTablesReinUnit,
      },
      [CvbTableSerializedHeaders.re]: {
        original: CvbTableOriginalHeaders.re,
        serialized: CvbTableSerializedHeaders.re,
        unserialized: CvbTableUnserializedHeaders.re,
        view: IntlKeys.referenceTablesRe,
      },
      [CvbTableSerializedHeaders.reUnit]: {
        original: CvbTableOriginalHeaders.reUnit,
        serialized: CvbTableSerializedHeaders.reUnit,
        unserialized: CvbTableUnserializedHeaders.reUnit,
        view: IntlKeys.referenceTablesReUnit,
      },
      [CvbTableSerializedHeaders.cVcRe]: {
        original: CvbTableOriginalHeaders.cVcRe,
        serialized: CvbTableSerializedHeaders.cVcRe,
        unserialized: CvbTableUnserializedHeaders.cVcRe,
        view: IntlKeys.referenceTablesCvcRe,
      },
      [CvbTableSerializedHeaders.cVcReUnit]: {
        original: CvbTableOriginalHeaders.cVcReUnit,
        serialized: CvbTableSerializedHeaders.cVcReUnit,
        unserialized: CvbTableUnserializedHeaders.cVcReUnit,
        view: IntlKeys.referenceTablesCvcReUnit,
      },
      [CvbTableSerializedHeaders.cVcOs]: {
        original: CvbTableOriginalHeaders.cVcOs,
        serialized: CvbTableSerializedHeaders.cVcOs,
        unserialized: CvbTableUnserializedHeaders.cVcOs,
        view: IntlKeys.referenceTablesCvcOs,
      },
      [CvbTableSerializedHeaders.cVcOsUnit]: {
        original: CvbTableOriginalHeaders.cVcOsUnit,
        serialized: CvbTableSerializedHeaders.cVcOsUnit,
        unserialized: CvbTableUnserializedHeaders.cVcOsUnit,
        view: IntlKeys.referenceTablesCvcOsUnit,
      },
    },
  },
};

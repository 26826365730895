import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { cloneDeep, merge } from 'lodash';

import {
  DateRange,
  VriCalculatedResourcesItemSerialized,
  VriCalculatedStatisticsResourceSerialized,
} from 'lib/tables/config/vriCalculated/config';

import {
  fetchVriFirstReportResourcesAction,
  fetchVriFirstReportStatisticsAction,
  fetchVriSecondReportResourcesAction,
  fetchVriSecondReportStatisticsAction,
  updateVriFirstReportResourcesAction,
  updateVriSecondReportResourcesAction,
  resetVriResourcesAction,
} from './actions';
import { RootState } from '../store';
import { FILTER_ENTITY_TYPES, filterSelector } from 'store/filters/reducer';

const initialReportData = {
  meta: {
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  resource: {} as VriCalculatedResourcesItemSerialized['resourceData'],
  constants: {} as VriCalculatedResourcesItemSerialized['aggregatedReportData'],
  reports: [] as VriCalculatedResourcesItemSerialized['calculatedReportItems'],
  selectedDateRange: {} as DateRange,
  statistics: {
    meta: {
      isError: false,
      isLoading: false,
      isLoaded: false,
    },
    resource: {} as VriCalculatedStatisticsResourceSerialized,
  },
};

const initialState = {
  meta: {
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  firstReport: initialReportData,
  secondReport: initialReportData,
};

export const vriComparisonToolReducer = createReducer(initialState, {
  [resetVriResourcesAction.type]: (draft) => {
    draft.firstReport.meta.isError = false;
    draft.firstReport.meta.isLoading = false;
    draft.firstReport.meta.isLoaded = false;
    draft.firstReport.resource = initialState.firstReport.resource;
    draft.firstReport.reports = initialState.firstReport.reports;
    draft.firstReport.statistics.meta = initialState.firstReport.statistics.meta;
    draft.firstReport.statistics.resource = initialState.firstReport.statistics.resource;

    draft.secondReport.meta.isError = false;
    draft.secondReport.meta.isLoading = false;
    draft.secondReport.meta.isLoaded = false;
    draft.secondReport.resource = initialState.secondReport.resource;
    draft.secondReport.reports = initialState.secondReport.reports;
    draft.secondReport.statistics.meta = initialState.secondReport.statistics.meta;
    draft.secondReport.statistics.resource = initialState.secondReport.statistics.resource;
  },

  // VRI FIRST REPORT
  [fetchVriFirstReportResourcesAction.request]: (draft, { payload }) => {
    const { date_start, date_end } = payload.filter;

    draft.firstReport.meta.isError = false;
    draft.firstReport.meta.isLoading = true;
    draft.firstReport.meta.isLoaded = false;
    draft.firstReport.resource = initialState.firstReport.resource;
    draft.firstReport.reports = initialState.firstReport.reports;
    draft.firstReport.statistics.meta = initialState.firstReport.statistics.meta;
    draft.firstReport.statistics.resource = initialState.firstReport.statistics.resource;
    draft.firstReport.selectedDateRange = {
      reportDateFrom: date_start,
      reportDateTo: date_end,
    };
  },

  [fetchVriFirstReportResourcesAction.success]: (draft, { payload }) => {
    const {
      calculatedReportItems,
      aggregatedReportData,
      resourceData,
    } = payload as VriCalculatedResourcesItemSerialized;

    draft.firstReport.meta.isError = false;
    draft.firstReport.meta.isLoading = false;
    draft.firstReport.meta.isLoaded = true;
    draft.firstReport.resource = resourceData;
    draft.firstReport.reports = calculatedReportItems;
    draft.firstReport.constants = aggregatedReportData;
  },

  [fetchVriFirstReportResourcesAction.failure]: (draft) => {
    draft.firstReport.meta.isError = true;
    draft.firstReport.meta.isLoading = false;
    draft.firstReport.meta.isLoaded = false;
    draft.firstReport.resource = initialState.firstReport.resource;
    draft.firstReport.reports = initialState.firstReport.reports;
    draft.firstReport.constants = initialState.firstReport.constants;
  },

  [fetchVriFirstReportStatisticsAction.request]: (draft, { payload }) => {
    draft.firstReport.statistics.meta.isError = false;
    draft.firstReport.statistics.meta.isLoading = true;
    draft.firstReport.statistics.meta.isLoaded = false;
    draft.firstReport.resource = initialState.firstReport.resource;
    draft.firstReport.reports = initialState.firstReport.reports;
    draft.firstReport.constants = initialState.firstReport.constants;

    draft.firstReport.selectedDateRange = {
      reportDateFrom: payload.filter.date_start,
      reportDateTo: payload.filter.date_end,
    };
  },

  [fetchVriFirstReportStatisticsAction.success]: (draft, { payload }) => {
    draft.firstReport.statistics.meta.isError = false;
    draft.firstReport.statistics.meta.isLoading = false;
    draft.firstReport.statistics.meta.isLoaded = true;
    draft.firstReport.statistics.resource = payload;
  },

  [fetchVriFirstReportStatisticsAction.failure]: (draft) => {
    draft.firstReport.statistics.meta.isError = true;
    draft.firstReport.statistics.meta.isLoading = false;
    draft.firstReport.statistics.meta.isLoaded = false;
    draft.firstReport.statistics.resource = initialState.firstReport.statistics.resource;
  },

  [updateVriFirstReportResourcesAction.request]: (draft) => {
    draft.meta.isError = false;
    draft.meta.isLoading = true;
    draft.meta.isLoaded = false;
  },

  [updateVriFirstReportResourcesAction.success]: (draft, { payload }) => {
    const {
      calculatedReportItems,
      aggregatedReportData,
      resourceData,
    } = payload as VriCalculatedResourcesItemSerialized;

    draft.meta.isError = false;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = true;
    draft.firstReport.resource = resourceData;
    draft.firstReport.constants = aggregatedReportData;
    draft.firstReport.reports = calculatedReportItems;
  },

  [updateVriFirstReportResourcesAction.failure]: (draft) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = false;
  },

  // VRI SECOND REPORT
  [fetchVriSecondReportResourcesAction.request]: (draft, { payload }) => {
    const { date_start, date_end } = payload.filter;

    draft.secondReport.meta.isError = false;
    draft.secondReport.meta.isLoading = true;
    draft.secondReport.meta.isLoaded = false;
    draft.secondReport.resource = initialState.secondReport.resource;
    draft.secondReport.reports = initialState.secondReport.reports;
    draft.secondReport.statistics.meta = initialState.secondReport.statistics.meta;
    draft.secondReport.statistics.resource = initialState.secondReport.statistics.resource;
    draft.secondReport.selectedDateRange = {
      reportDateFrom: date_start,
      reportDateTo: date_end,
    };
  },

  [fetchVriSecondReportResourcesAction.success]: (draft, { payload }) => {
    const {
      calculatedReportItems,
      aggregatedReportData,
      resourceData,
    } = payload as VriCalculatedResourcesItemSerialized;

    draft.secondReport.meta.isError = false;
    draft.secondReport.meta.isLoading = false;
    draft.secondReport.meta.isLoaded = true;
    draft.secondReport.resource = resourceData;
    draft.secondReport.reports = calculatedReportItems;
    draft.secondReport.constants = aggregatedReportData;
  },

  [fetchVriSecondReportResourcesAction.failure]: (draft) => {
    draft.secondReport.meta.isError = true;
    draft.secondReport.meta.isLoading = false;
    draft.secondReport.meta.isLoaded = false;
    draft.secondReport.resource = initialState.secondReport.resource;
    draft.secondReport.reports = initialState.secondReport.reports;
    draft.secondReport.constants = initialState.secondReport.constants;
  },

  [fetchVriSecondReportStatisticsAction.request]: (draft, { payload }) => {
    draft.secondReport.statistics.meta.isError = false;
    draft.secondReport.statistics.meta.isLoading = true;
    draft.secondReport.statistics.meta.isLoaded = false;
    draft.secondReport.resource = initialState.secondReport.resource;
    draft.secondReport.reports = initialState.secondReport.reports;
    draft.secondReport.constants = initialState.secondReport.constants;

    draft.secondReport.selectedDateRange = {
      reportDateFrom: payload.filter.date_start,
      reportDateTo: payload.filter.date_end,
    };
  },

  [fetchVriSecondReportStatisticsAction.success]: (draft, { payload }) => {
    draft.secondReport.statistics.meta.isError = false;
    draft.secondReport.statistics.meta.isLoading = false;
    draft.secondReport.statistics.meta.isLoaded = true;
    draft.secondReport.statistics.resource = payload;
  },

  [fetchVriSecondReportStatisticsAction.failure]: (draft) => {
    draft.secondReport.statistics.meta.isError = true;
    draft.secondReport.statistics.meta.isLoading = false;
    draft.secondReport.statistics.meta.isLoaded = false;
    draft.secondReport.statistics.resource = initialState.secondReport.statistics.resource;
  },

  [updateVriSecondReportResourcesAction.request]: (draft) => {
    draft.meta.isError = false;
    draft.meta.isLoading = true;
    draft.meta.isLoaded = false;
  },

  [updateVriSecondReportResourcesAction.success]: (draft, { payload }) => {
    const {
      calculatedReportItems,
      aggregatedReportData,
      resourceData,
    } = payload as VriCalculatedResourcesItemSerialized;

    draft.meta.isError = false;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = true;
    draft.secondReport.resource = resourceData;
    draft.secondReport.constants = aggregatedReportData;
    draft.secondReport.reports = calculatedReportItems;
  },

  [updateVriSecondReportResourcesAction.failure]: (draft) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = false;
  },
});

const vriComparisonToolSelector = (state: RootState) => state.vriComparisonTool;

export const vriMetaSelector = createSelector(vriComparisonToolSelector, (vriComparisonTool) => vriComparisonTool.meta);

export const vriFirstReportResourceSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.firstReport.resource,
);

export const vriFirstReportReportsSelector = createSelector(vriComparisonToolSelector, (vriComparisonTool) =>
  cloneDeep(vriComparisonTool.firstReport.reports),
);

export const vriFirstReportConstantsSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.firstReport.constants,
);

export const vriSecondReportResourceSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.secondReport.resource,
);

export const vriSecondReportReportsSelector = createSelector(vriComparisonToolSelector, (vriComparisonTool) =>
  cloneDeep(vriComparisonTool.secondReport.reports),
);

export const vriSecondReportConstantsSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.secondReport.constants,
);

export const vriReportsSelector = createSelector(
  vriFirstReportReportsSelector,
  vriSecondReportReportsSelector,
  (firstReports, secondReports) => merge(firstReports, secondReports),
);

export const vriFirstReportMetaSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.firstReport.meta,
);

export const vriSecondReportMetaSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.secondReport.meta,
);

export const vriFirstReportSelectedDateRangeSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.firstReport.selectedDateRange,
);

export const vriSecondReportSelectedDateRangeSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.secondReport.selectedDateRange,
);

export const vriFirstReportSelector = createSelector(
  vriFirstReportReportsSelector,
  filterSelector,
  (reports, filters) => {
    const vriFilter = filters(FILTER_ENTITY_TYPES.VRI_COMPARISON_TOOL);

    return reports?.find((report) => report.userPassportId === Number(vriFilter.vriComparisonPassportId));
  },
);

export const vriSecondReportSelector = createSelector(
  vriSecondReportReportsSelector,
  filterSelector,
  (reports, filters) => {
    const vriFilter = filters(FILTER_ENTITY_TYPES.VRI_COMPARISON_TOOL);

    return reports?.find((report) => report.userPassportId === Number(vriFilter.vriComparisonPassportId));
  },
);

const vriFirstReportStatisticsSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.firstReport.statistics,
);

const vriSecondReportStatisticsSelector = createSelector(
  vriComparisonToolSelector,
  (vriComparisonTool) => vriComparisonTool.secondReport.statistics,
);

export const vriFirstReportStatisticsResourceSelector = createSelector(
  vriFirstReportStatisticsSelector,
  (statistics) => statistics.resource,
);

export const vriSecondReportStatisticsResourceSelector = createSelector(
  vriSecondReportStatisticsSelector,
  (statistics) => statistics.resource,
);

export const vriFirstReportStatisticsMetaSelector = createSelector(
  vriFirstReportStatisticsSelector,
  (statistics) => statistics.meta,
);

export const vriSecondReportStatisticsMetaSelector = createSelector(
  vriSecondReportStatisticsSelector,
  (statistics) => statistics.meta,
);

export default vriComparisonToolReducer;

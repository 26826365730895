import { IntlKeys } from 'lib/localization/keys/__keys';

enum ElectricityConsumptionRatesOriginal {
  ElectricityConsumptionRates = 'CFP_NIBUD_electriciteitsverbrui',
}

enum ElectricityConsumptionRatesSerialized {
  ElectricityConsumptionRates = 'ElectricityConsumptionRates',
}

enum ElectricityConsumptionRatesUnserialized {
  ElectricityConsumptionRates = 'ElectricityConsumptionRates',
}

enum ElectricityConsumptionRatesOriginalHeaders {
  'householdPeopleQuantity' = 'nrPeopleHousehold',
  'electricityConsumption' = 'electricityConsumption',
}

enum ElectricityConsumptionRatesSerializedHeaders {
  'householdPeopleQuantity' = 'householdPeopleQuantity',
  'electricityConsumption' = 'electricityConsumption',
}
enum ElectricityConsumptionRatesUnserializedHeaders {
  'householdPeopleQuantity' = 'household_people_quantity',
  'electricityConsumption' = 'electricity_consumption',
}

export const ELECTRICITY_CONSUMPTION_RATES_FIELDS = {
  [ElectricityConsumptionRatesUnserialized.ElectricityConsumptionRates]: {
    original: ElectricityConsumptionRatesOriginal.ElectricityConsumptionRates,
    serialized: ElectricityConsumptionRatesSerialized.ElectricityConsumptionRates,
    unserialized: ElectricityConsumptionRatesUnserialized.ElectricityConsumptionRates,
    view: IntlKeys.referenceTablesElectricityConsumptionRates,
    viewName: 'CFP_NIBUD_electriciteitsverbruik',

    columns: {
      [ElectricityConsumptionRatesSerializedHeaders.householdPeopleQuantity]: {
        original: ElectricityConsumptionRatesOriginalHeaders.householdPeopleQuantity,
        serialized: ElectricityConsumptionRatesSerializedHeaders.householdPeopleQuantity,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.householdPeopleQuantity,
        view: IntlKeys.referenceTablesElectricityConsumptionRatesPeopleQuantity,
      },
      [ElectricityConsumptionRatesSerializedHeaders.electricityConsumption]: {
        original: ElectricityConsumptionRatesOriginalHeaders.electricityConsumption,
        serialized: ElectricityConsumptionRatesSerializedHeaders.electricityConsumption,
        unserialized: ElectricityConsumptionRatesUnserializedHeaders.electricityConsumption,
        view: IntlKeys.referenceTablesElectricityConsumptionRatesConsumption,
      },
    },
  },
};

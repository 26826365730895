import React from 'react';
import { useIntl } from 'react-intl';

import { TitlesKeys } from 'lib/localization/keys';

import { makeStyles, Theme } from '@material-ui/core';

import { SimLogoSvg } from 'components/icons';

const SolutionBySIM = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <a
      className={classes.solutionBySIMWrapper}
      href="https://www.impactbuying.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <SimLogoSvg className={classes.simLogo} />

      <span className={classes.simDescription} data-testid="company-tagline">{formatMessage({ id: TitlesKeys.solutionBySIM })}</span>
    </a>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  simDescription: {
    fontSize: '0.875em',
    lineHeight: '16px',
    letterSpacing: 0.4,
  },
  simLogo: {
    width: 65,
    height: 'auto',
    marginBottom: 15,
  },
  solutionBySIMWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25px 0',
    textDecoration: 'none',
    color: theme.colors.dimGray,

    [theme.breakpoints.down('md')]: {
      padding: '4px 0',
    },
  },
}));

export default SolutionBySIM;

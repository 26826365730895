import { createReducer, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import memoize from 'lodash/memoize';

import { FilterValueType } from 'lib/filters';
import { currentRouteSelector, isRouteMountedSelector } from 'store/reducers/routerReducer';

import { OptionType } from 'components/form/FormAutocomplete/FormAutocompleteComponent';

import {
  addFiltersAction,
  getFilterOptionsAction,
  resetFiltersAction,
  setFilterIsLoaded,
  setFiltersAction,
  setFiltersMenuStateAction,
} from './actions';
import { FilterOptionsEntities } from './sagas';

enum FILTER_ENTITY_TYPES {
  CERTIFICATES_ROOT = 'certificatesRoot',
  CERTIFICATES_DAILY = 'certificatesDaily',
  CERTIFICATES_WEEKLY_RECOGNITIONS = 'certificatesWeeklyRecognitions',
  CERTIFICATES_WEEKLY_FARMS = 'certificatesWeeklyFarms',
  CERTIFICATES_WEEKLY_DEVIATIONS = 'certificatesWeeklyDeviations',
  CERTIFICATES_IKB = 'certificatesIKB',
  CERTIFICATES_WELFARE = 'certificatesWelfare',
  SLAUGHTER_DETAILS = 'slaughterDetails',
  INVOICE_MESSAGES = 'invoiceMessages',
  INVOICE_DETAILS = 'invoiceDetails',
  DELIVERY_MESSAGES = 'deliveryMessages',
  SLAUGHTER_MESSAGES = 'slaughterMessages',
  SLAUGHTER_PRICE_CORRECTIONS = 'slaughterPriceCorrections',
  MOVEMENT_EVENTS = 'movementEvents',
  FARM_EVENTS = 'farmEvents',
  GENETICS = 'genetics',
  VETERINARIAN_DATA = 'veterinarianData',
  ANIMAL_PASSPORTS = 'animalPassports',
  VRI_CALCULATED = 'vriCalculated',
  VRI_CALCULATED_TOOL = 'vriCalculatedTool',
  VRI_COMPARISON_TOOL = 'vriComparisonTool',
  TRANSPORTS = 'transports',
  COMPANY_REPORT_FILTER = 'companyReportFilter',
  FARMER_INPUT = 'farmerInput',
  REFERENCE_TABLES = 'referenceTables',
  FARM_MANAGEMENT_CATEGORIES = 'farmManagementCategories',
  FARM_MANAGEMENT_DATA = 'farmManagementData',
  CARBON_FOOTPRINT = 'carbonFootprint',
  MONITORING_DASHBOARD = 'monitoringDashboard',
  MONITORING_DASHBOARD_STATISTIC = 'monitoringDashboardStatistic',
  MONITORING_DASHBOARD_DATA = 'monitoringDashboardData',
  RAW_MATERIALS_ORIGIN = 'rawMaterialsOrigin',
  HFM_OUTPUT = 'hfmOutput',
  ERRORS_LOG = 'errorsLog',
}

type Filters = Record<string, FilterValueType>;

type EntityFilters = {
  [key in FILTER_ENTITY_TYPES]: Filters;
};

interface FiltersReducer {
  //
  meta: {
    isFiltersMenuOpen: boolean;
  };

  filterOptions: Partial<
    Record<
      FilterOptionsEntities,
      {
        isLoading: boolean;
        isLoaded: boolean;
        options: OptionType[];
      }
    >
  >;

  entities: EntityFilters;
}

const emptyFilterOptions = {
  isLoading: false,
  isLoaded: false,
  options: [],
};
const emptyFilter = {};

const initialState: FiltersReducer = {
  meta: {
    isFiltersMenuOpen: false,
  },

  filterOptions: {},

  entities: {
    ...Object.values(FILTER_ENTITY_TYPES).reduce((acc, key) => {
      acc[key] = emptyFilter;
      return acc;
    }, {} as Record<FILTER_ENTITY_TYPES, Filters>),
  },
};

export const filtersReducer = createReducer(initialState, {
  [addFiltersAction.type]: (draft, { payload }) => {
    const entity = payload.entity as FILTER_ENTITY_TYPES;

    draft.entities[entity] = {
      ...draft.entities[entity],
      ...payload.filters,
    };
    //
  },

  [setFiltersAction.type]: (draft, { payload }) => {
    draft.entities[payload.entity as FILTER_ENTITY_TYPES] = payload.filters;
    //
  },

  [resetFiltersAction.type]: (draft, { payload }) => {
    const entity = payload.entity as FILTER_ENTITY_TYPES;

    if (Object.keys(draft.entities[entity]).length) {
      draft.entities[entity] = emptyFilter;
    }
  },
  [setFiltersMenuStateAction.type]: (draft, { payload }) => {
    draft.meta.isFiltersMenuOpen = payload;
  },
  [setFilterIsLoaded.type]: (draft, { payload }) => {
    draft.filterOptions['vriPassportId' as FilterOptionsEntities] = {
      ...emptyFilterOptions,
      isLoaded: payload
    };
  },

  ////////////////////////////////////////////////////////////////////////
  [getFilterOptionsAction.request]: (draft, { payload }) => {
    draft.filterOptions[payload as FilterOptionsEntities] = {
      ...emptyFilterOptions,
      isLoading: true,
      isLoaded: false,
    };
  },
  [getFilterOptionsAction.success]: (draft, { payload, meta }) => {
    draft.filterOptions[meta.optionKey as FilterOptionsEntities] = {
      isLoading: false,
      isLoaded: true,
      options: payload,
    };
  },
});

const filtersSelector = (state: RootState) => state.filters;

export const currentFiltersSelector = createSelector(
  filtersSelector,
  currentRouteSelector,
  isRouteMountedSelector,
  (filters, { filterType }, isRouteMounted) =>
    isRouteMounted ? (filterType && filters.entities[filterType]) || emptyFilter : undefined,
);
export const filterSelector = createSelector(filtersSelector, (filter) =>
  memoize((entity: FILTER_ENTITY_TYPES) => filter.entities[entity] || emptyFilter),
);

export const filterOptionsSelector = createSelector(filtersSelector, (filter) =>
  memoize((entity: string) => filter.filterOptions?.[entity as FilterOptionsEntities] || emptyFilterOptions),
);

export const filtersMenuStateSelector = createSelector(filtersSelector, (filter) => filter.meta.isFiltersMenuOpen);
export { FILTER_ENTITY_TYPES };

export default filtersReducer;

import { Route } from '../../Route';
import RouteParser from 'route-parser';

export const resetPassword = new Route('post', new RouteParser('/auth/password'), (params, data) => [
  200,
  {
    resource: {
      success: true,
      message: `An email has been sent to ${params.email} containing instructions for resetting your password.`,
    },
  },
]);

import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';

import {
  DateRange,
  VriCalculatedResourcesItemSerialized,
  VriCalculatedStatisticsResourceSerialized,
} from 'lib/tables/config/vriCalculated/config';

import {
  fetchVriCalculatedResourcesAction,
  fetchVriCalculatedStatisticsAction,
  updateVriCalculatedResourcesAction,
  resetVriResourcesAction,
} from './actions';
import { RootState } from '../store';
import { FILTER_ENTITY_TYPES, filterSelector } from 'store/filters/reducer';

const initialState = {
  meta: {
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  resource: {} as VriCalculatedResourcesItemSerialized['resourceData'],
  constants: {} as VriCalculatedResourcesItemSerialized['aggregatedReportData'],
  reports: [] as VriCalculatedResourcesItemSerialized['calculatedReportItems'],
  selectedDateRange: {} as DateRange,
  statistics: {
    meta: {
      isError: false,
      isLoading: false,
      isLoaded: false,
    },
    resource: {} as VriCalculatedStatisticsResourceSerialized,
  },
};

export const vriCalculatedReducer = createReducer(initialState, {
  [resetVriResourcesAction.type]: (draft) => {
    draft.meta.isError = false;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = false;
    draft.resource = initialState.resource;
    draft.reports = initialState.reports;
    draft.constants = initialState.constants;
    draft.statistics.meta = initialState.statistics.meta;
    draft.statistics.resource = initialState.statistics.resource;
  },

  // FETCH VRI
  [fetchVriCalculatedResourcesAction.request]: (draft, { payload }) => {
    const { date_start, date_end } = payload.filter;

    draft.meta.isError = false;
    draft.meta.isLoading = true;
    draft.meta.isLoaded = false;
    draft.resource = initialState.resource;
    draft.reports = initialState.reports;
    draft.statistics.meta = initialState.statistics.meta;
    draft.statistics.resource = initialState.statistics.resource;
    draft.selectedDateRange = {
      reportDateFrom: date_start,
      reportDateTo: date_end,
    };
  },

  [fetchVriCalculatedResourcesAction.success]: (draft, { payload }) => {
    const {
      calculatedReportItems,
      aggregatedReportData,
      resourceData,
    } = payload as VriCalculatedResourcesItemSerialized;

    draft.meta.isError = false;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = true;
    draft.resource = resourceData;
    draft.constants = aggregatedReportData;
    draft.reports = calculatedReportItems;
  },

  [fetchVriCalculatedResourcesAction.failure]: (draft) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = false;
    draft.reports = initialState.reports;
  },

  // UPDATE VRI
  [updateVriCalculatedResourcesAction.request]: (draft) => {
    draft.meta.isError = false;
    draft.meta.isLoading = true;
    draft.meta.isLoaded = false;
  },

  [updateVriCalculatedResourcesAction.success]: (draft, { payload }) => {
    const {
      calculatedReportItems,
      aggregatedReportData,
      resourceData,
    } = payload as VriCalculatedResourcesItemSerialized;

    draft.meta.isError = false;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = true;
    draft.resource = resourceData;
    draft.constants = aggregatedReportData;
    draft.reports = calculatedReportItems;
  },

  [updateVriCalculatedResourcesAction.failure]: (draft) => {
    draft.meta.isError = true;
    draft.meta.isLoading = false;
    draft.meta.isLoaded = false;
  },

  // FETCH VRI STATISTICS
  [fetchVriCalculatedStatisticsAction.request]: (draft) => {
    draft.statistics.meta.isError = false;
    draft.statistics.meta.isLoading = true;
    draft.statistics.meta.isLoaded = false;
    draft.reports = initialState.reports;
  },

  [fetchVriCalculatedStatisticsAction.success]: (draft, { payload }) => {
    draft.statistics.meta.isError = false;
    draft.statistics.meta.isLoading = false;
    draft.statistics.meta.isLoaded = true;
    draft.statistics.resource = payload;
  },

  [fetchVriCalculatedStatisticsAction.failure]: (draft) => {
    draft.statistics.meta.isError = true;
    draft.statistics.meta.isLoading = false;
    draft.statistics.meta.isLoaded = false;
    draft.statistics.resource = initialState.statistics.resource;
  },
});

const vriCalculatedSelector = (state: RootState) => state.vriCalculated;

export const vriCalculatedResourceSelector = createSelector(
  vriCalculatedSelector,
  (vriCalculated) => vriCalculated.resource,
);

export const vriCalculatedReportsSelector = createSelector(vriCalculatedSelector, (vriCalculated) =>
  cloneDeep(vriCalculated.reports),
);

export const vriCalculatedConstantsSelector = createSelector(
  vriCalculatedSelector,
  (vriCalculated) => vriCalculated.constants,
);

const vriCalculatedStatisticsSelector = createSelector(
  vriCalculatedSelector,
  (vriCalculated) => vriCalculated.statistics,
);

export const vriCalculatedMetaSelector = createSelector(vriCalculatedSelector, (vriCalculated) => vriCalculated.meta);

export const vriCalculatedSelectedDateRangeSelector = createSelector(
  vriCalculatedSelector,
  (vri) => vri.selectedDateRange,
);

export const vriCalculatedReportSelector = createSelector(
  vriCalculatedReportsSelector,
  filterSelector,
  (reports, filters) => {
    const vriFilter = filters(FILTER_ENTITY_TYPES.VRI_CALCULATED);

    return reports?.find((report) => report.userPassportId === Number(vriFilter.vriPassportId));
  },
);

export const vriCalculatedCurrentUploadReportsResourceSelector = createSelector(
  vriCalculatedReportsSelector,
  (reports) => cloneDeep(reports || []),
);

export const vriCalculatedStatisticsResourceSelector = createSelector(
  vriCalculatedStatisticsSelector,
  (statistics) => statistics.resource,
);

export const vriCalculatedStatisticsMetaSelector = createSelector(
  vriCalculatedStatisticsSelector,
  (statistics) => statistics.meta,
);

export default vriCalculatedReducer;

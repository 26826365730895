import { PEN_LEVEL_FIELDS } from '../structure';
import { DocumentData } from 'lib/excel/serilizers/Sheet';
import { DocumentViewer } from 'lib/excel/view/Document';
import { SheetViewer } from 'lib/excel/view/Sheet';
import { CellViewer } from 'lib/excel/view/Cell';
import { formatViewNumber } from 'lib/excel/serilizers/utils';
import { roundToInteger } from 'lib/helpers/renderHelpers';

const { columns, serialized, view } = PEN_LEVEL_FIELDS.PenLevel;

export const getPenLevelView = (data: DocumentData) => {
  return new DocumentViewer(data, [
    new SheetViewer(serialized, view, [
      new CellViewer({
        serializedPropName: columns.penLevel.serialized,
        viewPropName: columns.penLevel.view,
        conversionFunction: (dataRow, sheetData, viewPropName, serializedPropName) =>
          formatViewNumber(dataRow, sheetData, viewPropName, serializedPropName, parseInt, roundToInteger),
      }),
      new CellViewer({ serializedPropName: columns.penNameNL.serialized, viewPropName: columns.penNameNL.view }),
      new CellViewer({ serializedPropName: columns.penNameEN.serialized, viewPropName: columns.penNameEN.view }),
    ]),
  ]);
};

import { IntlKeys } from 'lib/localization/keys';

enum HFMOutputSerialized {
  HFMOutput = 'HFMOutput',
  HFMOutputSubmit = 'HFMOutputSubmit',
}

enum HFMOutputUnserialized {
  HFMOutput = 'HFMOutput',
  HFMOutputSubmit = 'HFMOutputSubmit',
}

export enum HFMOutputSerializedHeaders {
  id = 'id',
  year = 'year',
  period = 'period',
  quarter = 'quarter',
  yearToDate = 'yearToDate',
  entityExternalId = 'entityExternalId',
  accountHfm = 'accountHfm',
  extraExternalId = 'extraExternalId',
  consumption = 'consumption',
  co2Equivalent = 'co2Equivalent',
  //
  entities = 'entities',
  entitiesCountry = 'entitiesCountry',
  entitiesBusinessUnit = 'entitiesBusinessUnit',
  greenhouseConversionFactor = 'greenhouseConversionFactor',
  greenhouseEmissionFactor = 'greenhouseEmissionFactor',
  greenhouseCategory = 'greenhouseCategory',
  greenhouseName = 'greenhouseName',
  //
  uploadedAt = 'uploadedAt',
  fileName = 'fileName',
  uploadedBy = 'uploadedBy',
}

export const HFMOutputUnserializedHeaders = {
  id: 'id',
  year: 'year',
  period: 'period',
  quarter: 'quarter',
  yearToDate: 'year_to_date',
  entityExternalId: 'entity_external_id',
  accountHfm: 'account_hfm',
  extraExternalId: 'extra_external_id',
  consumption: 'consumption',
  co2Equivalent: 'co2_equivalent',
  //
  entities: ['entities_list', 'entity_name'],
  entitiesCountry: ['entities_list', 'country'],
  entitiesBusinessUnit: ['entities_list', 'business_unit'],
  greenhouseConversionFactor: ['greenhouse_gas_emission', 'conversion_factor'],
  greenhouseEmissionFactor: ['greenhouse_gas_emission', 'emission_factor'],
  greenhouseCategory: ['greenhouse_gas_emission', 'category'],
  greenhouseName: ['greenhouse_gas_emission', 'name'],
  //
  uploadedAt: 'uploaded_at',
  fileName: 'file_name',
  uploadedBy: ['uploaded_by', 'full_name'],
} as const;

export enum HFMOutputSubmitSerializedHeaders {
  id = 'id',
  year = 'year',
  period = 'period',
  quarter = 'quarter',
  yearToDate = 'yearToDate',
  entityExternalId = 'entityExternalId',
  accountHfm = 'accountHfm',
  extraExternalId = 'extraExternalId',
  consumption = 'consumption',
}

export const HFMOutputSubmitUnserializedHeaders = {
  id: 'id',
  year: 'year',
  period: 'period',
  quarter: 'quarter',
  yearToDate: 'year_to_date',
  entityExternalId: 'entity_external_id',
  accountHfm: 'account_hfm',
  extraExternalId: 'extra_external_id',
  consumption: 'consumption',
} as const;

export const HFM_OUTPUT = {
  [HFMOutputSerialized.HFMOutput]: {
    serialized: HFMOutputSerialized.HFMOutput,
    unserialized: HFMOutputUnserialized.HFMOutput,
    view: IntlKeys.titleHFMOutput,

    columns: {
      [HFMOutputSerializedHeaders.id]: {
        serialized: HFMOutputSerializedHeaders.id,
        unserialized: HFMOutputUnserializedHeaders.id,
        view: IntlKeys.carbonFootprintId,
      },
      [HFMOutputSerializedHeaders.year]: {
        serialized: HFMOutputSerializedHeaders.year,
        unserialized: HFMOutputUnserializedHeaders.year,
        view: IntlKeys.hfmOutputYear,
      },
      [HFMOutputSerializedHeaders.period]: {
        serialized: HFMOutputSerializedHeaders.period,
        unserialized: HFMOutputUnserializedHeaders.period,
        view: IntlKeys.hfmOutputPeriod,
      },
      [HFMOutputSerializedHeaders.quarter]: {
        serialized: HFMOutputSerializedHeaders.quarter,
        unserialized: HFMOutputUnserializedHeaders.quarter,
        view: IntlKeys.hfmOutputQuarter,
      },
      [HFMOutputSerializedHeaders.yearToDate]: {
        serialized: HFMOutputSerializedHeaders.yearToDate,
        unserialized: HFMOutputUnserializedHeaders.yearToDate,
        view: IntlKeys.hfmOutputYearToDate,
      },
      [HFMOutputSerializedHeaders.entityExternalId]: {
        serialized: HFMOutputSerializedHeaders.entityExternalId,
        unserialized: HFMOutputUnserializedHeaders.entityExternalId,
        view: IntlKeys.hfmOutputEntityExternalId,
      },
      [HFMOutputSerializedHeaders.accountHfm]: {
        serialized: HFMOutputSerializedHeaders.accountHfm,
        unserialized: HFMOutputUnserializedHeaders.accountHfm,
        view: IntlKeys.hfmOutputAccountHfm,
      },
      [HFMOutputSerializedHeaders.extraExternalId]: {
        serialized: HFMOutputSerializedHeaders.extraExternalId,
        unserialized: HFMOutputUnserializedHeaders.extraExternalId,
        view: IntlKeys.hfmOutputExtraExternalId,
      },
      [HFMOutputSerializedHeaders.consumption]: {
        serialized: HFMOutputSerializedHeaders.consumption,
        unserialized: HFMOutputUnserializedHeaders.consumption,
        view: IntlKeys.hfmOutputConsumption,
      },
      [HFMOutputSerializedHeaders.co2Equivalent]: {
        serialized: HFMOutputSerializedHeaders.co2Equivalent,
        unserialized: HFMOutputUnserializedHeaders.co2Equivalent,
        view: IntlKeys.hfmOutputCo2Equivalent,
      },
      [HFMOutputSerializedHeaders.entities]: {
        serialized: HFMOutputSerializedHeaders.entities,
        unserialized: HFMOutputUnserializedHeaders.entities,
        view: IntlKeys.hfmOutputEntities,
      },
      [HFMOutputSerializedHeaders.entitiesCountry]: {
        serialized: HFMOutputSerializedHeaders.entitiesCountry,
        unserialized: HFMOutputUnserializedHeaders.entitiesCountry,
        view: IntlKeys.hfmOutputEntitiesCountry,
      },
      [HFMOutputSerializedHeaders.entitiesBusinessUnit]: {
        serialized: HFMOutputSerializedHeaders.entitiesBusinessUnit,
        unserialized: HFMOutputUnserializedHeaders.entitiesBusinessUnit,
        view: IntlKeys.hfmOutputEntitiesBusinessUnit,
      },
      [HFMOutputSerializedHeaders.greenhouseConversionFactor]: {
        serialized: HFMOutputSerializedHeaders.greenhouseConversionFactor,
        unserialized: HFMOutputUnserializedHeaders.greenhouseConversionFactor,
        view: IntlKeys.hfmOutputGreenhouseConversionFactor,
      },
      [HFMOutputSerializedHeaders.greenhouseEmissionFactor]: {
        serialized: HFMOutputSerializedHeaders.greenhouseEmissionFactor,
        unserialized: HFMOutputUnserializedHeaders.greenhouseEmissionFactor,
        view: IntlKeys.hfmOutputGreenhouseEmissionFactor,
      },
      [HFMOutputSerializedHeaders.greenhouseCategory]: {
        serialized: HFMOutputSerializedHeaders.greenhouseCategory,
        unserialized: HFMOutputUnserializedHeaders.greenhouseCategory,
        view: IntlKeys.hfmOutputGreenhouseCategory,
      },
      [HFMOutputSerializedHeaders.greenhouseName]: {
        serialized: HFMOutputSerializedHeaders.greenhouseName,
        unserialized: HFMOutputUnserializedHeaders.greenhouseName,
        view: IntlKeys.hfmOutputGreenhouseName,
      },
      [HFMOutputSerializedHeaders.fileName]: {
        serialized: HFMOutputSerializedHeaders.fileName,
        unserialized: HFMOutputUnserializedHeaders.fileName,
        view: IntlKeys.carbonFootprintFileName,
      },
      [HFMOutputSerializedHeaders.uploadedAt]: {
        serialized: HFMOutputSerializedHeaders.uploadedAt,
        unserialized: HFMOutputUnserializedHeaders.uploadedAt,
      },
      [HFMOutputSerializedHeaders.uploadedBy]: {
        serialized: HFMOutputSerializedHeaders.uploadedBy,
        unserialized: HFMOutputUnserializedHeaders.uploadedBy,
      },
    },
  },
};

export const HFM_OUTPUT_SUBMIT = {
  [HFMOutputSerialized.HFMOutputSubmit]: {
    serialized: HFMOutputSerialized.HFMOutputSubmit,
    unserialized: HFMOutputUnserialized.HFMOutputSubmit,

    columns: {
      [HFMOutputSubmitSerializedHeaders.year]: {
        serialized: HFMOutputSubmitSerializedHeaders.year,
        unserialized: HFMOutputSubmitUnserializedHeaders.year,
      },
      [HFMOutputSubmitSerializedHeaders.period]: {
        serialized: HFMOutputSubmitSerializedHeaders.period,
        unserialized: HFMOutputSubmitUnserializedHeaders.period,
      },
      [HFMOutputSubmitSerializedHeaders.yearToDate]: {
        serialized: HFMOutputSubmitSerializedHeaders.yearToDate,
        unserialized: HFMOutputSubmitUnserializedHeaders.yearToDate,
      },
      [HFMOutputSubmitSerializedHeaders.entityExternalId]: {
        serialized: HFMOutputSubmitSerializedHeaders.entityExternalId,
        unserialized: HFMOutputSubmitUnserializedHeaders.entityExternalId,
      },
      [HFMOutputSubmitSerializedHeaders.accountHfm]: {
        serialized: HFMOutputSubmitSerializedHeaders.accountHfm,
        unserialized: HFMOutputSubmitUnserializedHeaders.accountHfm,
      },
      [HFMOutputSubmitSerializedHeaders.extraExternalId]: {
        serialized: HFMOutputSubmitSerializedHeaders.extraExternalId,
        unserialized: HFMOutputSubmitUnserializedHeaders.extraExternalId,
      },
      [HFMOutputSubmitSerializedHeaders.consumption]: {
        serialized: HFMOutputSubmitSerializedHeaders.consumption,
        unserialized: HFMOutputSubmitUnserializedHeaders.consumption,
      },
    },
  },
};

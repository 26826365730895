import { IntlKeys } from 'lib/localization/keys';

enum CodesAnimalRemarksSerialized {
  CodesAnimalRemarks = 'CodesAnimalRemarks',
}

enum CodesAnimalRemarksUnserialized {
  CodesAnimalRemarks = 'CodesAnimalRemarks',
}

export enum CodesAnimalRemarksSerializedHeaders {
  id = 'id',
  companyIdentifier = 'companyIdentifier',
  animalRemarkIdentifier = 'animalRemarkIdentifier',
  abbreviation = 'abbreviation',
  description = 'description',
  note = 'note',
  code = 'code',
  animalRemarkDatetime = 'animalRemarkDatetime',
  ubnList = 'ubnList',
  kvkList = 'kvkList',
  registrationNameList = 'registrationNameList',
}

export const CodesAnimalRemarksUnserializedHeaders = {
  id: 'id',
  companyIdentifier: 'company_identifier',
  animalRemarkIdentifier: 'animal_remark_identifier',
  abbreviation: 'abbreviation',
  description: 'description',
  note: 'note',
  code: 'code',
  animalRemarkDatetime: 'animal_remark_datetime',
  ubnList: 'ubn_list',
  kvkList: 'kvk_list',
  registrationNameList: 'registration_name_list',
} as const;

export const CODES_ANIMAL_REMARKS = {
  [CodesAnimalRemarksSerialized.CodesAnimalRemarks]: {
    serialized: CodesAnimalRemarksSerialized.CodesAnimalRemarks,
    unserialized: CodesAnimalRemarksUnserialized.CodesAnimalRemarks,
    view: IntlKeys.farmManagementDataAnimalRemarksList,

    columns: {
      [CodesAnimalRemarksSerializedHeaders.id]: {
        serialized: CodesAnimalRemarksSerializedHeaders.id,
        unserialized: CodesAnimalRemarksUnserializedHeaders.id,
      },
      [CodesAnimalRemarksSerializedHeaders.companyIdentifier]: {
        serialized: CodesAnimalRemarksSerializedHeaders.companyIdentifier,
        unserialized: CodesAnimalRemarksUnserializedHeaders.companyIdentifier,
        view: IntlKeys.farmManagementDataCompanyId,
      },
      [CodesAnimalRemarksSerializedHeaders.animalRemarkIdentifier]: {
        serialized: CodesAnimalRemarksSerializedHeaders.animalRemarkIdentifier,
        unserialized: CodesAnimalRemarksUnserializedHeaders.animalRemarkIdentifier,
        view: IntlKeys.farmManagementDataAnimalRemarkId,
      },
      [CodesAnimalRemarksSerializedHeaders.abbreviation]: {
        serialized: CodesAnimalRemarksSerializedHeaders.abbreviation,
        unserialized: CodesAnimalRemarksUnserializedHeaders.abbreviation,
        view: IntlKeys.farmManagementDataAbbreviation,
      },
      [CodesAnimalRemarksSerializedHeaders.description]: {
        serialized: CodesAnimalRemarksSerializedHeaders.description,
        unserialized: CodesAnimalRemarksUnserializedHeaders.description,
        view: IntlKeys.farmManagementDataDescription,
      },
      [CodesAnimalRemarksSerializedHeaders.note]: {
        serialized: CodesAnimalRemarksSerializedHeaders.note,
        unserialized: CodesAnimalRemarksUnserializedHeaders.note,
        view: IntlKeys.farmManagementDataNote,
      },
      [CodesAnimalRemarksSerializedHeaders.code]: {
        serialized: CodesAnimalRemarksSerializedHeaders.code,
        unserialized: CodesAnimalRemarksUnserializedHeaders.code,
        view: IntlKeys.farmManagementDataCode,
      },
      [CodesAnimalRemarksSerializedHeaders.animalRemarkDatetime]: {
        serialized: CodesAnimalRemarksSerializedHeaders.animalRemarkDatetime,
        unserialized: CodesAnimalRemarksUnserializedHeaders.animalRemarkDatetime,
        view: IntlKeys.farmManagementDataTimestamp,
      },
      [CodesAnimalRemarksSerializedHeaders.ubnList]: {
        serialized: CodesAnimalRemarksSerializedHeaders.ubnList,
        unserialized: CodesAnimalRemarksUnserializedHeaders.ubnList,
        view: IntlKeys.farmManagementDataUbn,
      },
      [CodesAnimalRemarksSerializedHeaders.kvkList]: {
        serialized: CodesAnimalRemarksSerializedHeaders.kvkList,
        unserialized: CodesAnimalRemarksUnserializedHeaders.kvkList,
        view: IntlKeys.farmManagementDataKvk,
      },
      [CodesAnimalRemarksSerializedHeaders.registrationNameList]: {
        serialized: CodesAnimalRemarksSerializedHeaders.registrationNameList,
        unserialized: CodesAnimalRemarksUnserializedHeaders.registrationNameList,
        view: IntlKeys.farmManagementDataKvkName,
      },
    },
  },
};

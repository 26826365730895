import { CFP_CO2_EMISSION_FACTOR } from 'lib/excel/config/referenceTables/cfpCo2EmissionFactor/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = CFP_CO2_EMISSION_FACTOR.CFP_CO2EmissionFactors;

export const serializeCfpCo2EmissionFactorData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.emissionSource.original, columns.emissionSource.serialized),
      new CellSerializer(columns.co2Volume.original, columns.co2Volume.serialized),
      new CellSerializer(columns.reference.original, columns.reference.serialized),
    ]),
  ]);
};

import { Route } from '../../Route';
import RouteParser from 'route-parser';

// import { generateBlockchainRecord } from '../utils';

export const organizations = new Route('get', new RouteParser('/mock/admin/organizations'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 32,
          name: 'Geert de Haas',
          email: 'dehaas63@home.nl',
          kvk: '17281975',
          kvk_name: 'De Asshoeve',
          ubn: '2258465',
          gln: 'EMPTY',
          concept: 'Concept 1',
          certificates: 'Kiwa',
          certificates_other: 'IKB',
          genetic_supplier: 'Supplier1',
          genetic_supplier_other: 'Other',
          ki_station: 'KI Station',
          ki_station_other: 'KI Station 2',
          sow_line: 'Line1',
          sow_line_other: '',
          breeding_program: 'Breed 1',
          sire_line: 'Sire',
          sire_line_other: '',
          veterinary_practice: 'local vet. clinic',
          veterinary_practice_other: '',
          feed_supplier: 'ForFarmers, Agrifirm',
          feed_supplier_other: 'FransenGerrits',
          fms_name: 'Main FMS',
          fms_module_agrisyst: 'Module1',
          fms_module_agro_coop: 'Module2',
          fms_module_agrovision: 'Module3',
          feeding_system: 'Feeding system 1',
          feeding_system_other: '',
          climate_system: 'none',
          climate_system_other: '',
          company_name: 'De Asshoeve',
          address_line_1: 'Haps',
          address_line_2: 'Schuttersweg 6',
          postal_code: '5443 PR',
          city: 'Haps',
          telephone_number: '31621244765',
          vat_number: '31621244765',
          users: [],
        },
        {
          id: 29,
          name: 'Geert de Haas',
          email: 'dehaas63@home.nl',
          kvk: '17281975',
          kvk_name: 'De Asshoeve',
          ubn: '6090928',
          gln: 'EMPTY',
          concept: 'Concept 2',
          certificates: 'Kiwa',
          certificates_other: 'Welfare',
          genetic_supplier: 'Supplier1',
          genetic_supplier_other: 'Other',
          ki_station: 'KI Station',
          ki_station_other: 'KI Station 2',
          sow_line: 'Line1',
          sow_line_other: '',
          breeding_program: 'Breed 1',
          sire_line: 'Sire',
          sire_line_other: '',
          veterinary_practice: 'local vet. clinic',
          veterinary_practice_other: '',
          feed_supplier: 'ForFarmers, Agrifirm',
          feed_supplier_other: 'FransenGerrits',
          fms_name: 'Main FMS',
          fms_module_agrisyst: 'Module1',
          fms_module_agro_coop: 'Module2',
          fms_module_agrovision: 'Module3',
          feeding_system: 'Feeding system 1',
          feeding_system_other: '',
          climate_system: 'none',
          climate_system_other: '',
          company_name: 'De Asshoeve',
          address_line_1: 'Haps',
          address_line_2: 'Schuttersweg 6',
          postal_code: '5443 PR',
          city: 'Haps',
          telephone_number: '31621244765',
          vat_number: '31621244765',
          users: [
            {
              id: 16,
              name: 'Geert',
              full_name: 'Geert de Haas',
              company_id: 29,
              pref_lang: 'nl',
              uid: 'dehaas63@home.nl',
              provider: 'email',
              user_role: 'company_admin',
              user_type: null,
              is_contact_person: false,
              created_at: '2020-11-10T13:44:13.212Z',
              updated_at: '2020-11-10T13:44:13.274Z',
            },
          ],
        },
      ],
    },
  ];
});

export const createOrganization = new Route('post', new RouteParser('/mock/admin/organizations'), (...data) => {
  return [
    200,
    {
      resource: {
        address_line_1: '465 Sydney Brooks, West Kandis, SC 54860-9864',
        address_line_2: '66974 Glover Vista, Schuppeberg, PA 67057',
        breeding_program: 'maxime',
        certificates: 'iusto',
        certificates_other: 'ullam',
        city: 'Port Bart',
        climate_system: 'quisquam',
        climate_system_other: 'voluptatum',
        company_name: '2molestias',
        concept: 'velit',
        email: '2dominica@gmail.com',
        feed_supplier: 'ut',
        feed_supplier_other: 'voluptate',
        feeding_system: 'atque',
        feeding_system_other: 'qui',
        fms_module_agrisyst: 'et',
        fms_module_agro_coop: 'reiciendis',
        fms_module_agrovision: 'eius',
        fms_name: 'non',
        genetic_supplier: 'ipsum',
        genetic_supplier_other: 'incidunt',
        gln: 'hyjorgkddh',
        ki_station: 'soluta',
        ki_station_other: 'iure',
        kvk: 'lbdakggibq',
        kvk_name: '2velit',
        name: 'provident',
        postal_code: '35867',
        sire_line: 'deleniti',
        sire_line_other: 'tenetur',
        sow_line: 'consectetur',
        sow_line_other: 'est',
        telephone_number: '(347) 162-1152',
        ubn: 'ihlwrrxtgo',
        vat_number: '2njnvmvizwy',
        veterinary_practice: 'occaecati',
        veterinary_practice_other: 'odio',
      },
    },
  ];
});

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { PENS_TYPE_SOW_BARN } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type PensTypeSowBarnPayload = {
  id: number;
  pen_type_sow_barn: number | null;
  pen_barn_name: string | null;
  pen_type_sow_barn_upload: ManageUploadPayload;
};

export type PensTypeSowBarnSerialized = {
  id: number;
  referenceUploadID: number;
  penTypeSowBarnCode: string;
  penTypeSowBarnName: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = PENS_TYPE_SOW_BARN.PensTypeSowBarn;

const getDefaultSerializer = (data: DocumentData<PensTypeSowBarnPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.penTypeSowBarnCode.unserialized, columns.penTypeSowBarnCode.serialized),
      new CellSerializer(columns.penTypeSowBarnName.unserialized, columns.penTypeSowBarnName.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializePensTypeSowBarn = (serverData: ResourcesWrapper<PensTypeSowBarnPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { ALLOCATION_MANURE_FIELDS } from 'lib/excel/config/referenceTables/allocationManure/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = ALLOCATION_MANURE_FIELDS.AllocationManure;

export const getAllocationManureDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.animalCategory.original),
      new CellValidator(columns.manure.original),
      new CellValidator(columns.gas.original),
      new CellValidator(columns.electricity.original),
      new CellValidator(columns.water.original),
    ]),
  ]);
};

import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const invoiceDetails = new Route('get', new RouteParser('/deliveries'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 10,
          invoice_message_id: 8,
          line_number: '96',
          delivery_date: '2020-04-01',
          delivery_note_number: '46701046',
          delivery_note_line_number: '64',
          product_quantity: '49072',
          product_article_code: '295579223209539160',
          product_description: 'Delectus commodi in libero.',
          product_article_group_code: '51',
          product_article_group_code_description: 'Ab asperiores labore possimus.',
          unit: null,
          created_at: '2020-07-09T07:34:17.978Z',
          invoice_message: {
            id: 8,
            message_id: '82003618',
            currency_code: 'Pataca',
            overview_type: 'sit',
            invoice_number: '1928517509',
            invoice_date: '2019-10-16',
            unique_message_number: '500270864',
            supplier_name: 'Ea tempora magni.',
            supplier_postal_address_street: "O'Conner Course",
            supplier_postal_address_house_number: '7439',
            supplier_postal_address_place: 'Paucekton',
            supplier_postal_address_postcode: '35980',
            supplier_ean_address_code: '96289',
            supplier_bank_account_number: '0436539652',
            supplier_iban: 'NL17KRTD0511051957',
            supplier_international_vat_number: 'NL76IYND4211598620',
            customer_name: 'Enim dicta suscipit.',
            customer_street_address: 'Lawrence Center',
            customer_postal_address_place: 'Dachmouth',
            customer_relation_number: '726',
            created_at: '2020-07-09T07:34:17.971Z',
          },
          ...generateBlockchainRecord(),
        },
        {
          id: 10,
          invoice_message_id: 8,
          line_number: '96',
          delivery_date: '2020-04-01',
          delivery_note_number: '46701046',
          delivery_note_line_number: '64',
          product_quantity: '49072',
          product_article_code: '295579223209539160',
          product_description: 'Delectus commodi in libero.',
          product_article_group_code: '51',
          product_article_group_code_description: 'Ab asperiores labore possimus.',
          unit: null,
          created_at: '2020-07-09T07:34:17.978Z',
          invoice_message: {
            id: 8,
            message_id: '82003618',
            currency_code: 'Pataca',
            overview_type: 'sit',
            invoice_number: '1928517509',
            invoice_date: '2019-10-16',
            unique_message_number: '500270864',
            supplier_name: 'Ea tempora magni.',
            supplier_postal_address_street: "O'Conner Course",
            supplier_postal_address_house_number: '7439',
            supplier_postal_address_place: 'Paucekton',
            supplier_postal_address_postcode: '35980',
            supplier_ean_address_code: '96289',
            supplier_bank_account_number: '0436539652',
            supplier_iban: 'NL17KRTD0511051957',
            supplier_international_vat_number: 'NL76IYND4211598620',
            customer_name: 'Enim dicta suscipit.',
            customer_street_address: 'Lawrence Center',
            customer_postal_address_place: 'Dachmouth',
            customer_relation_number: '726',
            created_at: '2020-07-09T07:34:17.971Z',
          },
          ...generateBlockchainRecord(),
        },
        {
          id: 10,
          invoice_message_id: 8,
          line_number: '96',
          delivery_date: '2020-04-01',
          delivery_note_number: '46701046',
          delivery_note_line_number: '64',
          product_quantity: '49072',
          product_article_code: '295579223209539160',
          product_description: 'Delectus commodi in libero.',
          product_article_group_code: '51',
          product_article_group_code_description: 'Ab asperiores labore possimus.',
          unit: null,
          created_at: '2020-07-09T07:34:17.978Z',
          invoice_message: {
            id: 8,
            message_id: '82003618',
            currency_code: 'Pataca',
            overview_type: 'sit',
            invoice_number: '1928517509',
            invoice_date: '2019-10-16',
            unique_message_number: '500270864',
            supplier_name: 'Ea tempora magni.',
            supplier_postal_address_street: "O'Conner Course",
            supplier_postal_address_house_number: '7439',
            supplier_postal_address_place: 'Paucekton',
            supplier_postal_address_postcode: '35980',
            supplier_ean_address_code: '96289',
            supplier_bank_account_number: '0436539652',
            supplier_iban: 'NL17KRTD0511051957',
            supplier_international_vat_number: 'NL76IYND4211598620',
            customer_name: 'Enim dicta suscipit.',
            customer_street_address: 'Lawrence Center',
            customer_postal_address_place: 'Dachmouth',
            customer_relation_number: '726',
            created_at: '2020-07-09T07:34:17.971Z',
          },
          ...generateBlockchainRecord(),
        },
        {
          id: 10,
          invoice_message_id: 8,
          line_number: '96',
          delivery_date: '2020-04-01',
          delivery_note_number: '46701046',
          delivery_note_line_number: '64',
          product_quantity: '49072',
          product_article_code: '295579223209539160',
          product_description: 'Delectus commodi in libero.',
          product_article_group_code: '51',
          product_article_group_code_description: 'Ab asperiores labore possimus.',
          unit: null,
          created_at: '2020-07-09T07:34:17.978Z',
          invoice_message: {
            id: 8,
            message_id: '82003618',
            currency_code: 'Pataca',
            overview_type: 'sit',
            invoice_number: '1928517509',
            invoice_date: '2019-10-16',
            unique_message_number: '500270864',
            supplier_name: 'Ea tempora magni.',
            supplier_postal_address_street: "O'Conner Course",
            supplier_postal_address_house_number: '7439',
            supplier_postal_address_place: 'Paucekton',
            supplier_postal_address_postcode: '35980',
            supplier_ean_address_code: '96289',
            supplier_bank_account_number: '0436539652',
            supplier_iban: 'NL17KRTD0511051957',
            supplier_international_vat_number: 'NL76IYND4211598620',
            customer_name: 'Enim dicta suscipit.',
            customer_street_address: 'Lawrence Center',
            customer_postal_address_place: 'Dachmouth',
            customer_relation_number: '726',
            created_at: '2020-07-09T07:34:17.971Z',
          },
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

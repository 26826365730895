import { all, fork } from 'redux-saga/effects';

import init from './init';
import settings from './settings';
import auth from '../auth/sagas';
import admin from '../admin/sagas';
import batches from './batches';
import upload from './upload';
import filters from '../filters/sagas';
import dataCatalogue from '../dataCatalogue/sagas';
import vri from '../vri/sagas';
import vriCalculated from '../vriCalculated/sagas';
import vriComparisonTool from '../vriComparisonTool/sagas';
import farm from './farm';
import ui from './ui';

export function* rootSaga() {
  yield all([
    fork(init),
    fork(auth),
    fork(admin),
    fork(batches),
    fork(upload),
    fork(filters),
    fork(dataCatalogue),
    fork(vri),
    fork(vriCalculated),
    fork(vriComparisonTool),
    fork(settings),
    fork(farm),
    fork(ui),
  ]);
}

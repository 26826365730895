import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useTheme } from 'styled-components';

import { CustomTheme } from 'theme';

export type XSVariants = 4 | 6 | 12;

export const useResponsiveXS: () => [XSVariants, (xs: XSVariants) => void] = () => {
  const theme = useTheme() as CustomTheme;

  const { spacings, initialXS } = useMemo<{ spacings: [MediaQueryList, XSVariants][]; initialXS: XSVariants }>(() => {
    const spacings: [MediaQueryList, XSVariants][] = [
      // up / ascending width
      [window.matchMedia(theme.media.up.sm.replace('@media ', '')), 6],
      [window.matchMedia(theme.media.up.md.replace('@media ', '')), 4],
      // down / descending width
      [window.matchMedia(theme.media.down.md.replace('@media ', '')), 6],
      [window.matchMedia(theme.media.down.sm.replace('@media ', '')), 12],
    ];

    const [_, initialXS] = spacings.reverse().find(([mql, spacing]) => mql.matches)!;

    return {
      initialXS,
      spacings,
    };
  }, []);

  //
  const [xs, _setXS] = useState<XSVariants>(initialXS);

  const setXS = useCallback(
    debounce<typeof _setXS>((spacing) => {
      _setXS(spacing);
    }, 35),
    [],
  );

  useEffect(() => {
    const unsubscribers = spacings.reduce<Function[]>((acc, [mql, spacing]) => {
      const listener: Parameters<typeof mql.addListener>[0] = (e) => {
        if (e.matches) {
          setXS(spacing);
        }
      };

      mql.addListener(listener);

      acc.push(() => {
        mql.removeListener(listener);
      });

      return acc;
    }, []);

    return () => {
      unsubscribers.forEach((unsubscriber) => unsubscriber());
    };
  }, []);

  return [xs, setXS];
};

import { IntlKeys } from 'lib/localization/keys';

enum RequestLogUsersOriginal {
  RequestLogUsers = 'request_log_users',
}

enum RequestLogUsersSerialized {
  RequestLogUsers = 'RequestLogUsers',
}

enum RequestLogUsersUnserialized {
  RequestLogUsers = 'RequestLogUsers',
}

export enum RequestLogUsersOriginalHeaders {
  fullName = 'full_name',
  email = 'email',
  lastLogin = 'last_login',
  prefLang = 'pref_lang',
  requestCount = 'request_count',
  userPassports = 'user_passports',
  labels = 'labels',
  requestCountByApplication = 'request_count_by_application',
}

export enum RequestLogUsersSerializedHeaders {
  fullName = 'fullName',
  email = 'email',
  lastLogin = 'lastLogin',
  prefLang = 'prefLang',
  requestCount = 'requestCount',
  userPassports = 'userPassports',
  labels = 'labels',
  requestCountByApplication = 'requestCountByApplication',
}

export enum RequestLogUsersUnserializedHeaders {
  fullName = 'full_name',
  email = 'email',
  lastLogin = 'last_login',
  prefLang = 'pref_lang',
  requestCount = 'request_count',
  userPassports = 'user_passports',
  labels = 'labels',
  requestCountByApplication = 'request_count_by_application',
}

export const REQUEST_LOG_USERS = {
  [RequestLogUsersSerialized.RequestLogUsers]: {
    original: RequestLogUsersOriginal.RequestLogUsers,
    serialized: RequestLogUsersSerialized.RequestLogUsers,
    unserialized: RequestLogUsersUnserialized.RequestLogUsers,
    view: IntlKeys.titleUsers,
    columns: {
      [RequestLogUsersSerializedHeaders.fullName]: {
        original: RequestLogUsersOriginalHeaders.fullName,
        serialized: RequestLogUsersSerializedHeaders.fullName,
        unserialized: RequestLogUsersUnserializedHeaders.fullName,
        view: IntlKeys.requestLogUsersFullName,
      },
      [RequestLogUsersSerializedHeaders.email]: {
        original: RequestLogUsersOriginalHeaders.email,
        serialized: RequestLogUsersSerializedHeaders.email,
        unserialized: RequestLogUsersUnserializedHeaders.email,
        view: IntlKeys.requestLogUsersEmail,
      },
      [RequestLogUsersSerializedHeaders.lastLogin]: {
        original: RequestLogUsersOriginalHeaders.lastLogin,
        serialized: RequestLogUsersSerializedHeaders.lastLogin,
        unserialized: RequestLogUsersUnserializedHeaders.lastLogin,
        view: IntlKeys.requestLogUsersLastLogin,
      },
      [RequestLogUsersSerializedHeaders.prefLang]: {
        original: RequestLogUsersOriginalHeaders.prefLang,
        serialized: RequestLogUsersSerializedHeaders.prefLang,
        unserialized: RequestLogUsersUnserializedHeaders.prefLang,
        view: IntlKeys.requestLogUsersPrefLang,
      },
      [RequestLogUsersSerializedHeaders.requestCount]: {
        original: RequestLogUsersOriginalHeaders.requestCount,
        serialized: RequestLogUsersSerializedHeaders.requestCount,
        unserialized: RequestLogUsersUnserializedHeaders.requestCount,
        view: IntlKeys.requestLogUsersRequestCount,
      },
      [RequestLogUsersSerializedHeaders.userPassports]: {
        original: RequestLogUsersOriginalHeaders.userPassports,
        serialized: RequestLogUsersSerializedHeaders.userPassports,
        unserialized: RequestLogUsersUnserializedHeaders.userPassports,
        view: IntlKeys.requestLogUsersUserPassports,
      },
      [RequestLogUsersSerializedHeaders.labels]: {
        original: RequestLogUsersOriginalHeaders.labels,
        serialized: RequestLogUsersSerializedHeaders.labels,
        unserialized: RequestLogUsersUnserializedHeaders.labels,
        view: IntlKeys.requestLogUsersLabels,
      },
      [RequestLogUsersSerializedHeaders.requestCountByApplication]: {
        original: RequestLogUsersOriginalHeaders.requestCountByApplication,
        serialized: RequestLogUsersSerializedHeaders.requestCountByApplication,
        unserialized: RequestLogUsersUnserializedHeaders.requestCountByApplication,
      },
    },
  },
};

import { ResourcesWrapper } from 'lib/http/utils';
import { USER_PASSPORTS } from 'lib/tables/config/userPassports/config';
import { getSerializer as getUserPassportsSerializer, UserPassportSerialized } from 'store/entities/userPassports';

const { unserialized } = USER_PASSPORTS.UserPassports;

export const serializeUbnData = (serverData: ResourcesWrapper<UserPassportSerialized>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getUserPassportsSerializer(data);

  return serializer.serialize().UserPassports;
};

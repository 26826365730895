import { IntlKeys } from 'lib/localization/keys/__keys';

enum DeliverySegmentContentsSerialized {
  DeliverySegmentContents = 'DeliverySegmentContents',
}

enum DeliverySegmentContentsUnserialized {
  DeliverySegmentContents = 'DeliverySegmentContents',
}

enum DeliverySegmentContentsSerializedHeaders {
  id = 'id',
  itemLineNumber = 'itemLineNumber',
  itemNumber = 'itemNumber',
  itemDescription = 'itemDescription',
  itemQuantity = 'itemQuantity',
  itemUnits = 'itemUnits',
  itemGroupCode = 'itemGroupCode',
  productionNumber = 'productionNumber',
  productionLocation = 'productionLocation',
  gmpNumber = 'gmpNumber',
  invoiceNumber = 'invoiceNumber',
  basicContent = 'basicContent',
  orderedItemQuantity = 'orderedItemQuantity',
  nutrientN = 'nutrientN',
  nutrientP = 'nutrientP',
  nutrientRe = 'nutrientRe',
  nutrientFos = 'nutrientFos',
  nutrientVem = 'nutrientVem',
  nutrientVevi = 'nutrientVevi',
  nutrientEw = 'nutrientEw',
  nutrientDs = 'nutrientDs',
  nutrientNdf = 'nutrientNdf',
  nutrientZetmeel = 'nutrientZetmeel',
  nutrientEf00 = 'nutrientEf00',
  nutrientEf40 = 'nutrientEf40',
  nutrientEf80 = 'nutrientEf80',
  nutrientCo2 = 'nutrientCo2',
  nutrientVCos = 'nutrientVCos',
  nutrientVCre = 'nutrientVCre',
  animalCode = 'animalCode',
  feedCode = 'feedCode',
  feedCodeKlw = 'feedCodeKlw',
  ras = 'ras',
}

enum DeliverySegmentContentsUnserializedHeaders {
  id = 'id',
  itemLineNumber = 'item_line_number',
  itemNumber = 'item_number',
  itemDescription = 'item_description',
  itemQuantity = 'item_quantity',
  itemUnits = 'item_units',
  itemGroupCode = 'item_group_code',
  productionNumber = 'production_number',
  productionLocation = 'production_location',
  gmpNumber = 'gmp_number',
  invoiceNumber = 'invoice_number',
  basicContent = 'basic_content',
  orderedItemQuantity = 'ordered_item_quantity',
  nutrientN = 'nutrient_n',
  nutrientP = 'nutrient_p',
  nutrientRe = 'nutrient_re',
  nutrientFos = 'nutrient_fos',
  nutrientVem = 'nutrient_vem',
  nutrientVevi = 'nutrient_vevi',
  nutrientEw = 'nutrient_ew',
  nutrientDs = 'nutrient_ds',
  nutrientNdf = 'nutrient_ndf',
  nutrientZetmeel = 'nutrient_zetmeel',
  nutrientEf00 = 'nutrient_ef00',
  nutrientEf40 = 'nutrient_ef40',
  nutrientEf80 = 'nutrient_ef80',
  nutrientCo2 = 'nutrient_co2',
  nutrientVCos = 'nutrient_vcos',
  nutrientVCre = 'nutrient_vcre',
  animalCode = 'animal_code',
  feedCode = 'feed_code',
  feedCodeKlw = 'feed_code_klw',
  ras = 'ras',
}

export const DELIVERY_SEGMENT_CONTENTS_FIELDS = {
  [DeliverySegmentContentsSerialized.DeliverySegmentContents]: {
    serialized: DeliverySegmentContentsSerialized.DeliverySegmentContents,
    unserialized: DeliverySegmentContentsUnserialized.DeliverySegmentContents,
    view: IntlKeys.titleDeliverySegmentContents,
    columns: {
      [DeliverySegmentContentsSerializedHeaders.id]: {
        serialized: DeliverySegmentContentsSerializedHeaders.id,
        unserialized: DeliverySegmentContentsUnserializedHeaders.id,
        view: IntlKeys.serviceBlockchainMessageId,
      },
      [DeliverySegmentContentsSerializedHeaders.itemLineNumber]: {
        serialized: DeliverySegmentContentsSerializedHeaders.itemLineNumber,
        unserialized: DeliverySegmentContentsUnserializedHeaders.itemLineNumber,
        view: IntlKeys.deliveryNoteLineNumber,
      },
      [DeliverySegmentContentsSerializedHeaders.itemNumber]: {
        serialized: DeliverySegmentContentsSerializedHeaders.itemNumber,
        unserialized: DeliverySegmentContentsUnserializedHeaders.itemNumber,
        view: IntlKeys.deliveryArticleNumber,
      },
      [DeliverySegmentContentsSerializedHeaders.itemDescription]: {
        serialized: DeliverySegmentContentsSerializedHeaders.itemDescription,
        unserialized: DeliverySegmentContentsUnserializedHeaders.itemDescription,
        view: IntlKeys.deliveryArticleDescription,
      },
      [DeliverySegmentContentsSerializedHeaders.itemQuantity]: {
        serialized: DeliverySegmentContentsSerializedHeaders.itemQuantity,
        unserialized: DeliverySegmentContentsUnserializedHeaders.itemQuantity,
        view: IntlKeys.deliveryNetQuantityOfProductDelivered,
      },
      [DeliverySegmentContentsSerializedHeaders.itemUnits]: {
        serialized: DeliverySegmentContentsSerializedHeaders.itemUnits,
        unserialized: DeliverySegmentContentsUnserializedHeaders.itemUnits,
        view: IntlKeys.deliveryQuantityUnit,
      },
      [DeliverySegmentContentsSerializedHeaders.itemGroupCode]: {
        serialized: DeliverySegmentContentsSerializedHeaders.itemGroupCode,
        unserialized: DeliverySegmentContentsUnserializedHeaders.itemGroupCode,
        view: IntlKeys.deliveryArticleGroupCode,
      },
      [DeliverySegmentContentsSerializedHeaders.orderedItemQuantity]: {
        serialized: DeliverySegmentContentsSerializedHeaders.orderedItemQuantity,
        unserialized: DeliverySegmentContentsUnserializedHeaders.orderedItemQuantity,
        view: IntlKeys.deliveryQuantityOfProductOrdered,
      },
      [DeliverySegmentContentsSerializedHeaders.productionNumber]: {
        serialized: DeliverySegmentContentsSerializedHeaders.productionNumber,
        unserialized: DeliverySegmentContentsUnserializedHeaders.productionNumber,
        view: IntlKeys.deliveryBranchNumber,
      },
      [DeliverySegmentContentsSerializedHeaders.productionLocation]: {
        serialized: DeliverySegmentContentsSerializedHeaders.productionLocation,
        unserialized: DeliverySegmentContentsUnserializedHeaders.productionLocation,
        view: IntlKeys.deliveryBranchName,
      },
      [DeliverySegmentContentsSerializedHeaders.gmpNumber]: {
        serialized: DeliverySegmentContentsSerializedHeaders.gmpNumber,
        unserialized: DeliverySegmentContentsUnserializedHeaders.gmpNumber,
        view: IntlKeys.deliveryGmpNumber,
      },
      [DeliverySegmentContentsSerializedHeaders.invoiceNumber]: {
        serialized: DeliverySegmentContentsSerializedHeaders.invoiceNumber,
        unserialized: DeliverySegmentContentsUnserializedHeaders.invoiceNumber,
        view: IntlKeys.deliveryInvoiceNumber,
      },
      [DeliverySegmentContentsSerializedHeaders.basicContent]: {
        serialized: DeliverySegmentContentsSerializedHeaders.basicContent,
        unserialized: DeliverySegmentContentsUnserializedHeaders.basicContent,
        view: IntlKeys.deliveryBaseContent,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientN]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientN,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientN,
        view: IntlKeys.deliveryNutrientN,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientP]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientP,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientP,
        view: IntlKeys.deliveryNutrientP,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientRe]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientRe,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientRe,
        view: IntlKeys.deliveryNutrientRe,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientFos]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientFos,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientFos,
        view: IntlKeys.deliveryNutrientFos,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientVem]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientVem,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientVem,
        view: IntlKeys.deliveryNutrientVem,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientVevi]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientVevi,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientVevi,
        view: IntlKeys.deliveryNutrientVevi,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientEw]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientEw,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientEw,
        view: IntlKeys.deliveryEnergyValueEw,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientDs]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientDs,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientDs,
        view: IntlKeys.deliveryNutrientDs,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientNdf]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientNdf,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientNdf,
        view: IntlKeys.deliveryNutrientNdf,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientZetmeel]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientZetmeel,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientZetmeel,
        view: IntlKeys.deliveryNutrientStarch,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientEf00]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientEf00,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientEf00,
        view: IntlKeys.deliveryNutrientEf00,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientEf40]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientEf40,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientEf40,
        view: IntlKeys.deliveryNutrientEf40,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientEf80]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientEf80,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientEf80,
        view: IntlKeys.deliveryNutrientEf80,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientCo2]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientCo2,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientCo2,
        view: IntlKeys.deliveryNutrientCo2,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientVCos]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientVCos,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientVCos,
        view: IntlKeys.deliveryNutrientVCos,
      },
      [DeliverySegmentContentsSerializedHeaders.nutrientVCre]: {
        serialized: DeliverySegmentContentsSerializedHeaders.nutrientVCre,
        unserialized: DeliverySegmentContentsUnserializedHeaders.nutrientVCre,
        view: IntlKeys.deliveryNutrientVCre,
      },
      [DeliverySegmentContentsSerializedHeaders.animalCode]: {
        serialized: DeliverySegmentContentsSerializedHeaders.animalCode,
        unserialized: DeliverySegmentContentsUnserializedHeaders.animalCode,
        view: IntlKeys.deliveryAnimalCategoryCode,
      },
      [DeliverySegmentContentsSerializedHeaders.feedCode]: {
        serialized: DeliverySegmentContentsSerializedHeaders.feedCode,
        unserialized: DeliverySegmentContentsUnserializedHeaders.feedCode,
        view: IntlKeys.deliveryFeedTypeCode,
      },
      [DeliverySegmentContentsSerializedHeaders.feedCodeKlw]: {
        serialized: DeliverySegmentContentsSerializedHeaders.feedCodeKlw,
        unserialized: DeliverySegmentContentsUnserializedHeaders.feedCodeKlw,
        view: IntlKeys.deliveryFeedTypeCodeKlw,
      },
      [DeliverySegmentContentsSerializedHeaders.ras]: {
        serialized: DeliverySegmentContentsSerializedHeaders.ras,
        unserialized: DeliverySegmentContentsUnserializedHeaders.ras,
        view: IntlKeys.deliveryRas,
      },
    },
  },
};

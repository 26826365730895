import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';

import { CARBON_FOOTPRINTS, CARBON_FOOTPRINTS_SUBMIT } from './config';

export type CarbonFootprintOption = {
  value: number;
  percent: number;
  average?: number;
  averageKg?: number;
};

export type CarbonFootprintPayload = {
  id: number;
  ubn: string;
  kvk: string;
  kvk_name: string;
  company_type: string;
  breeding_total: {
    cultivation_and_production_of_feed_co2_breeder: CarbonFootprintOption;
    transport_of_feed_co2_breeder: CarbonFootprintOption;
    manure_storage_of_feed_co2_breeder: CarbonFootprintOption;
    manure_transport_of_feed_co2_breeder: CarbonFootprintOption;
    enteric_fermentation_co2_breeder: CarbonFootprintOption;
    energy_use_co2_breeder: CarbonFootprintOption;
    total: CarbonFootprintOption;
    perPiglet: CarbonFootprintOption;
  };
  economic_allocation_total: {
    sows_value: CarbonFootprintOption;
    piglets_value: CarbonFootprintOption;
    sows_co2: CarbonFootprintOption;
    piglets_co2: CarbonFootprintOption;
    total_euro_value: CarbonFootprintOption;
    total_kg_value: CarbonFootprintOption;
  };
  fattening_total: {
    piglets_co2: CarbonFootprintOption;
    transport_piglets_co2_fattener: CarbonFootprintOption;
    cultivation_and_production_of_feed_co2_fattener: CarbonFootprintOption;
    transport_of_feed_co2_fattener: CarbonFootprintOption;
    manure_storage_of_feed_co2_fattener: CarbonFootprintOption;
    manure_transport_of_feed_co2_fattener: CarbonFootprintOption;
    enteric_fermentation_co2_fattener: CarbonFootprintOption;
    energy_use_co2_fattener: CarbonFootprintOption;
    total: CarbonFootprintOption;
  };
  co2_per_kg_of_live_weight: number;
  co2_per_kg_of_live_weight_average: number;
  status: 'active' | 'inactive';
  file_name: string;
  valid_from: string | null;
  valid_until: string | null;
  notes: string;
  uploaded_at: string;
  uploaded_by: {
    full_name: string;
  };
};

export type CarbonFootprintSerialized = {
  id: number;
  ubn: string;
  kvk: string;
  kvkName: string;
  companyType: string;
  cultivationAndProductionOfFeedBreeder: CarbonFootprintOption;
  transportOfFeedBreeder: CarbonFootprintOption;
  manureStorageOfFeedBreeder: CarbonFootprintOption;
  manureTransportOfFeedBreeder: CarbonFootprintOption;
  entericFermentationBreeder: CarbonFootprintOption;
  energyUseBreeder: CarbonFootprintOption;
  breedingTotal: CarbonFootprintOption;
  breedingPerPiglet: CarbonFootprintOption;
  sowsValue: CarbonFootprintOption;
  pigletsValue: CarbonFootprintOption;
  sows: CarbonFootprintOption;
  piglets: CarbonFootprintOption;
  economicAllocationTotalEuro: CarbonFootprintOption;
  economicAllocationTotalKg: CarbonFootprintOption;
  pigletsFattener: CarbonFootprintOption;
  transportPigletsFattener: CarbonFootprintOption;
  cultivationAndProductionOfFeedFattener: CarbonFootprintOption;
  transportOfFeedFattener: CarbonFootprintOption;
  manureStorageOfFeedFattener: CarbonFootprintOption;
  manureTransportOfFeedFattener: CarbonFootprintOption;
  entericFermentationFattener: CarbonFootprintOption;
  energyUseFattener: CarbonFootprintOption;
  fatteningTotal: CarbonFootprintOption;
  perKgOfLiveWeight: number;
  perKgOfLiveWeightAverage: number;
  status: 'active' | 'inactive' | boolean;
  fileName: string;
  validDateFrom: string | null;
  validDateUntil: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

export type CarbonFootprintSubmitPayload = Pick<
  CarbonFootprintPayload,
  'status' | 'valid_from' | 'valid_until' | 'notes'
>;
export type CarbonFootprintSubmitSerialized = Pick<
  CarbonFootprintSerialized,
  'status' | 'validDateFrom' | 'validDateUntil' | 'notes'
>;

const { columns: defaultColumns } = CARBON_FOOTPRINTS.CarbonFootprint;
const { columns: submitColumns } = CARBON_FOOTPRINTS_SUBMIT.CarbonFootprintsSubmit;

const getDefaultSerializer = (data: DocumentData<CarbonFootprintPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(CARBON_FOOTPRINTS.CarbonFootprint.unserialized, CARBON_FOOTPRINTS.CarbonFootprint.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.status.unserialized, defaultColumns.status.serialized),
      new CellSerializer(defaultColumns.ubn.unserialized, defaultColumns.ubn.serialized),
      new CellSerializer(defaultColumns.kvk.unserialized, defaultColumns.kvk.serialized),
      new CellSerializer(defaultColumns.kvkName.unserialized, defaultColumns.kvkName.serialized),
      new CellSerializer(defaultColumns.companyType.unserialized, defaultColumns.companyType.serialized),
      new CellSerializer(
        defaultColumns.cultivationAndProductionOfFeedBreeder.unserialized,
        defaultColumns.cultivationAndProductionOfFeedBreeder.serialized,
      ),
      new CellSerializer(
        defaultColumns.transportOfFeedBreeder.unserialized,
        defaultColumns.transportOfFeedBreeder.serialized,
      ),
      new CellSerializer(
        defaultColumns.manureStorageOfFeedBreeder.unserialized,
        defaultColumns.manureStorageOfFeedBreeder.serialized,
      ),
      new CellSerializer(
        defaultColumns.manureTransportOfFeedBreeder.unserialized,
        defaultColumns.manureTransportOfFeedBreeder.serialized,
      ),
      new CellSerializer(
        defaultColumns.entericFermentationBreeder.unserialized,
        defaultColumns.entericFermentationBreeder.serialized,
      ),
      new CellSerializer(defaultColumns.energyUseBreeder.unserialized, defaultColumns.energyUseBreeder.serialized),
      new CellSerializer(defaultColumns.breedingTotal.unserialized, defaultColumns.breedingTotal.serialized),
      new CellSerializer(defaultColumns.breedingPerPiglet.unserialized, defaultColumns.breedingPerPiglet.serialized),
      new CellSerializer(defaultColumns.sowsValue.unserialized, defaultColumns.sowsValue.serialized),
      new CellSerializer(defaultColumns.pigletsValue.unserialized, defaultColumns.pigletsValue.serialized),
      new CellSerializer(defaultColumns.sows.unserialized, defaultColumns.sows.serialized),
      new CellSerializer(defaultColumns.piglets.unserialized, defaultColumns.piglets.serialized),
      new CellSerializer(
        defaultColumns.economicAllocationTotalEuro.unserialized,
        defaultColumns.economicAllocationTotalEuro.serialized,
      ),
      new CellSerializer(
        defaultColumns.economicAllocationTotalKg.unserialized,
        defaultColumns.economicAllocationTotalKg.serialized,
      ),
      new CellSerializer(defaultColumns.pigletsFattener.unserialized, defaultColumns.pigletsFattener.serialized),
      new CellSerializer(
        defaultColumns.transportPigletsFattener.unserialized,
        defaultColumns.transportPigletsFattener.serialized,
      ),
      new CellSerializer(
        defaultColumns.cultivationAndProductionOfFeedFattener.unserialized,
        defaultColumns.cultivationAndProductionOfFeedFattener.serialized,
      ),
      new CellSerializer(
        defaultColumns.transportOfFeedFattener.unserialized,
        defaultColumns.transportOfFeedFattener.serialized,
      ),
      new CellSerializer(
        defaultColumns.manureStorageOfFeedFattener.unserialized,
        defaultColumns.manureStorageOfFeedFattener.serialized,
      ),
      new CellSerializer(
        defaultColumns.manureTransportOfFeedFattener.unserialized,
        defaultColumns.manureTransportOfFeedFattener.serialized,
      ),
      new CellSerializer(
        defaultColumns.entericFermentationFattener.unserialized,
        defaultColumns.entericFermentationFattener.serialized,
      ),
      new CellSerializer(defaultColumns.energyUseFattener.unserialized, defaultColumns.energyUseFattener.serialized),
      new CellSerializer(defaultColumns.fatteningTotal.unserialized, defaultColumns.fatteningTotal.serialized),
      new CellSerializer(defaultColumns.perKgOfLiveWeight.unserialized, defaultColumns.perKgOfLiveWeight.serialized),
      new CellSerializer(defaultColumns.perKgOfLiveWeightAverage.unserialized, defaultColumns.perKgOfLiveWeightAverage.serialized),
      new CellSerializer(defaultColumns.fileName.unserialized, defaultColumns.fileName.serialized),
      new CellSerializer(defaultColumns.validDateFrom.unserialized, defaultColumns.validDateFrom.serialized),
      new CellSerializer(defaultColumns.validDateUntil.unserialized, defaultColumns.validDateUntil.serialized),
      new CellSerializer(defaultColumns.notes.unserialized, defaultColumns.notes.serialized),
      new CellSerializer(defaultColumns.uploadedAt.unserialized, defaultColumns.uploadedAt.serialized),
      new CellSerializer(defaultColumns.uploadedBy.unserialized, defaultColumns.uploadedBy.serialized),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<CarbonFootprintSubmitSerialized[]>) => {
  return new DocumentSerializer<CarbonFootprintSubmitPayload, CarbonFootprintSubmitSerialized>(data, [
    new SheetSerializer(
      CARBON_FOOTPRINTS_SUBMIT.CarbonFootprintsSubmit.serialized,
      CARBON_FOOTPRINTS_SUBMIT.CarbonFootprintsSubmit.unserialized,
      [
        new CellSerializer(submitColumns.status.unserialized, submitColumns.status.serialized),
        new CellSerializer(
          submitColumns.validDateFrom.unserialized,
          submitColumns.validDateFrom.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.validDateUntil.unserialized,
          submitColumns.validDateUntil.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.notes.unserialized,
          submitColumns.notes.serialized,
          undefined,
          undefined,
          null,
        ),
      ],
    ),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<CarbonFootprintSerialized[]>) => {
  return new DocumentSerializer<CarbonFootprintSerialized, CarbonFootprintSubmitSerialized>(data, [
    new SheetSerializer(
      CARBON_FOOTPRINTS_SUBMIT.CarbonFootprintsSubmit.serialized,
      CARBON_FOOTPRINTS_SUBMIT.CarbonFootprintsSubmit.unserialized,
      [
        new CellSerializer(defaultColumns.status.serialized, submitColumns.status.serialized),
        new CellSerializer(
          defaultColumns.validDateFrom.serialized,
          submitColumns.validDateFrom.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          defaultColumns.validDateUntil.serialized,
          submitColumns.validDateUntil.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          defaultColumns.notes.serialized,
          submitColumns.notes.serialized,
          undefined,
          undefined,
          undefined,
        ),
      ],
    ),
  ]);
};

export const serializeCarbonFootprints = (serverData: ResourcesWrapper<CarbonFootprintPayload>) => {
  const { unserialized } = CARBON_FOOTPRINTS.CarbonFootprint;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const serializeCarbonFootprint = (serverData: ResourceWrapper<CarbonFootprintPayload>) => {
  const { serialized, unserialized } = CARBON_FOOTPRINTS.CarbonFootprint;
  const data = {
    [unserialized]: [serverData.data.resource],
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize()[serialized][0];
};

export const unserializeCarbonFootprintForSubmit = (
  resource: CarbonFootprintSubmitSerialized,
): CarbonFootprintSubmitPayload => {
  const { serialized, unserialized } = CARBON_FOOTPRINTS_SUBMIT.CarbonFootprintsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeCarbonFootprintStoSS = (resource: CarbonFootprintSerialized) => {
  const { serialized, unserialized } = CARBON_FOOTPRINTS_SUBMIT.CarbonFootprintsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

import { CL550_FIELDS } from 'lib/excel/config/referenceTables/cl550/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = CL550_FIELDS.Cl550;

export const getCl550DisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.mainGroupCode.serialized, extractPropName: columns.mainGroupCode.view }),
      new DisplayHeader({ headerName: columns.subGroupCode.serialized, extractPropName: columns.subGroupCode.view }),
      new DisplayHeader({ headerName: columns.codeDetails.serialized, extractPropName: columns.codeDetails.view }),
      new DisplayHeader({ headerName: columns.mainGroupName.serialized, extractPropName: columns.mainGroupName.view }),
      new DisplayHeader({ headerName: columns.subgroupName.serialized, extractPropName: columns.subgroupName.view }),
      new DisplayHeader({ headerName: columns.detailing.serialized, extractPropName: columns.detailing.view }),
      new DisplayHeader({ headerName: columns.english.serialized, extractPropName: columns.english.view }),
      new DisplayHeader({ headerName: columns.german.serialized, extractPropName: columns.german.view }),
      new DisplayHeader({ headerName: columns.codeCondition.serialized, extractPropName: columns.codeCondition.view }),
      new DisplayHeader({ headerName: columns.edi.serialized, extractPropName: columns.edi.view }),
      new DisplayHeader({ headerName: columns.dgr.serialized, extractPropName: columns.dgr.view }),
      new DisplayHeader({
        headerName: columns.diseaseAndTreatment.serialized,
        extractPropName: columns.diseaseAndTreatment.view,
      }),
      new DisplayHeader({ headerName: columns.failure.serialized, extractPropName: columns.failure.view }),
      new DisplayHeader({ headerName: columns.disposal.serialized, extractPropName: columns.disposal.view }),
      new DisplayHeader({ headerName: columns.selection.serialized, extractPropName: columns.selection.view }),
      new DisplayHeader({ headerName: columns.labResult.serialized, extractPropName: columns.labResult.view }),
      new DisplayHeader({ headerName: columns.section.serialized, extractPropName: columns.section.view }),
      new DisplayHeader({
        headerName: columns.slaughterhouseData.serialized,
        extractPropName: columns.slaughterhouseData.view,
      }),
      new DisplayHeader({ headerName: columns.agility.serialized, extractPropName: columns.agility.view }),
      new DisplayHeader({ headerName: columns.coverage.serialized, extractPropName: columns.coverage.view }),
      new DisplayHeader({ headerName: columns.birth.serialized, extractPropName: columns.birth.view }),
      new DisplayHeader({ headerName: columns.suckingPiglet.serialized, extractPropName: columns.suckingPiglet.view }),
      new DisplayHeader({ headerName: columns.weanedBig.serialized, extractPropName: columns.weanedBig.view }),
      new DisplayHeader({
        headerName: columns.rearingSowAndBoar.serialized,
        extractPropName: columns.rearingSowAndBoar.view,
      }),
      new DisplayHeader({ headerName: columns.goodSow.serialized, extractPropName: columns.goodSow.view }),
      new DisplayHeader({ headerName: columns.gestationSow.serialized, extractPropName: columns.gestationSow.view }),
      new DisplayHeader({ headerName: columns.lactatingSow.serialized, extractPropName: columns.lactatingSow.view }),
      new DisplayHeader({ headerName: columns.beer.serialized, extractPropName: columns.beer.view }),
      new DisplayHeader({ headerName: columns.finisher.serialized, extractPropName: columns.finisher.view }),
      new DisplayHeader({ headerName: columns.comment.serialized, extractPropName: columns.comment.view }),
      new DisplayHeader({ headerName: columns.added.serialized, extractPropName: columns.added.view }),
      new DisplayHeader({ headerName: columns.deleted.serialized, extractPropName: columns.deleted.view }),
    ]),
  ]);
};

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

import { WELFARE_CERTIFICATES } from 'lib/tables/config/certificates/welfare/config';

interface CertificatesWelfareUnserialized {
  id: number;
  program: string;
  remark: string;
  answer: string;
  code: string;
  updated_date: string;
  created_at: string;
  updated_at: string;
  ubn: string;
}

const getSerializer = (data: DocumentData) => {
  const { id, ubn, validFrom, validTo, type } = WELFARE_CERTIFICATES.CertificatesWelfare.columns;

  return new DocumentSerializer(data, [
    new SheetSerializer(
      WELFARE_CERTIFICATES.CertificatesWelfare.unserialized,
      WELFARE_CERTIFICATES.CertificatesWelfare.serialized,
      [
        new CellSerializer(id.unserialized, id.serialized),
        new CellSerializer(ubn.unserialized, ubn.serialized),
        new CellSerializer(validFrom.unserialized, validFrom.serialized),
        new CellSerializer(validTo.unserialized, validTo.serialized),
        new CellSerializer(type.unserialized, type.serialized),
        new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
        new CellSerializer(
          SERVICE_FIELDS.blockchainTimestamp.unserialized,
          SERVICE_FIELDS.blockchainTimestamp.serialized,
        ),
        new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
      ],
    ),
  ]);
};

export const serializeCertificatesWelfare = (serverData: ResourcesWrapper<CertificatesWelfareUnserialized>) => {
  const data = {
    [WELFARE_CERTIFICATES.CertificatesWelfare.unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

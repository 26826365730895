import { IntlKeys } from 'lib/localization/keys';

enum SlaughterDetailsSerialized {
  SlaughterDetails = 'SlaughterDetails',
}

enum SlaughterDetailsUnserialized {
  SlaughterDetails = 'SlaughterDetails',
}

export enum SlaughterDetailsSerializedHeaders {
  slaughterMessageId = 'slaughterMessageId',
  slaughterMessage = 'slaughterMessage',
  slaughterKey = 'slaughterKey',
  animalType = 'animalType',
  carcassCode = 'carcassCode',
  animalWeight = 'animalWeight',
  organCode = 'organCode',
  muscleThickness = 'muscleThickness',
  fatThickness = 'fatThickness',
  afterCorrectionPigAmount = 'afterCorrectionPigAmount',
  containerNumber = 'containerNumber',
  meat = 'meat',
  animalIdentificationType = 'animalIdentificationType',
}

export enum SlaughterDetailsUnserializedHeaders {
  slaughterMessageId = 'slaughter_message_id',
  slaughterMessage = 'slaughter_message',
  slaughterKey = 'slaughter_key',
  animalType = 'animal_type',
  carcassCode = 'carcass_code',
  animalWeight = 'animal_weight',
  organCode = 'organ_code',
  muscleThickness = 'muscle_thickness',
  fatThickness = 'fat_thickness',
  afterCorrectionPigAmount = 'after_correction_pig_amount',
  containerNumber = 'container_number',
  meat = 'meat',
  animalIdentificationType = 'animal_identification_type',
}

export const SLAUGHTER_DETAILS = {
  [SlaughterDetailsSerialized.SlaughterDetails]: {
    serialized: SlaughterDetailsSerialized.SlaughterDetails,
    unserialized: SlaughterDetailsUnserialized.SlaughterDetails,
    view: IntlKeys.titleSlaughterDetails,
    columns: {
      [SlaughterDetailsSerializedHeaders.slaughterMessageId]: {
        serialized: SlaughterDetailsSerializedHeaders.slaughterMessageId,
        unserialized: SlaughterDetailsUnserializedHeaders.slaughterMessageId,
        view: IntlKeys.slaughterSlaughterMessageId,
      },
      [SlaughterDetailsSerializedHeaders.slaughterKey]: {
        serialized: SlaughterDetailsSerializedHeaders.slaughterKey,
        unserialized: SlaughterDetailsUnserializedHeaders.slaughterKey,
        view: IntlKeys.slaughterSlaughterKey,
      },
      [SlaughterDetailsSerializedHeaders.animalType]: {
        serialized: SlaughterDetailsSerializedHeaders.animalType,
        unserialized: SlaughterDetailsUnserializedHeaders.animalType,
        view: IntlKeys.slaughterAnimalType,
      },
      [SlaughterDetailsSerializedHeaders.containerNumber]: {
        serialized: SlaughterDetailsSerializedHeaders.containerNumber,
        unserialized: SlaughterDetailsUnserializedHeaders.containerNumber,
        view: IntlKeys.slaughterContainerNumber,
      },
      [SlaughterDetailsSerializedHeaders.carcassCode]: {
        serialized: SlaughterDetailsSerializedHeaders.carcassCode,
        unserialized: SlaughterDetailsUnserializedHeaders.carcassCode,
        view: IntlKeys.slaughterCarcassCode,
      },
      [SlaughterDetailsSerializedHeaders.animalWeight]: {
        serialized: SlaughterDetailsSerializedHeaders.animalWeight,
        unserialized: SlaughterDetailsUnserializedHeaders.animalWeight,
        view: IntlKeys.slaughterAnimalWeight,
      },
      [SlaughterDetailsSerializedHeaders.meat]: {
        serialized: SlaughterDetailsSerializedHeaders.meat,
        unserialized: SlaughterDetailsUnserializedHeaders.meat,
        view: IntlKeys.slaughterMeat,
      },
      [SlaughterDetailsSerializedHeaders.organCode]: {
        serialized: SlaughterDetailsSerializedHeaders.organCode,
        unserialized: SlaughterDetailsUnserializedHeaders.organCode,
        view: IntlKeys.slaughterOrganCode,
      },
      [SlaughterDetailsSerializedHeaders.muscleThickness]: {
        serialized: SlaughterDetailsSerializedHeaders.muscleThickness,
        unserialized: SlaughterDetailsUnserializedHeaders.muscleThickness,
        view: IntlKeys.slaughterMuscleThickness,
      },
      [SlaughterDetailsSerializedHeaders.fatThickness]: {
        serialized: SlaughterDetailsSerializedHeaders.fatThickness,
        unserialized: SlaughterDetailsUnserializedHeaders.fatThickness,
        view: IntlKeys.slaughterFatThickness,
      },
      [SlaughterDetailsSerializedHeaders.afterCorrectionPigAmount]: {
        serialized: SlaughterDetailsSerializedHeaders.afterCorrectionPigAmount,
        unserialized: SlaughterDetailsUnserializedHeaders.afterCorrectionPigAmount,
        view: IntlKeys.slaughterAfterCorrectionPigAmount,
      },
      [SlaughterDetailsSerializedHeaders.slaughterMessage]: {
        serialized: SlaughterDetailsSerializedHeaders.slaughterMessage,
        unserialized: SlaughterDetailsUnserializedHeaders.slaughterMessage,
        view: IntlKeys.slaughterSlaughterMessage,
      },
      [SlaughterDetailsSerializedHeaders.slaughterMessage]: {
        serialized: SlaughterDetailsSerializedHeaders.slaughterMessage,
        unserialized: SlaughterDetailsUnserializedHeaders.slaughterMessage,
        view: IntlKeys.slaughterSlaughterMessage,
      },
      [SlaughterDetailsSerializedHeaders.animalIdentificationType]: {
        serialized: SlaughterDetailsSerializedHeaders.animalIdentificationType,
        unserialized: SlaughterDetailsUnserializedHeaders.animalIdentificationType,
        view: IntlKeys.slaughterAnimalIdentificationType,
      },
    },
  },
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum ByProductsSerialized {
  ByProducts = 'ByProducts',
  ByProductsSubmit = 'ByProductsSubmit',
}

enum ByProductsUnserialized {
  ByProducts = 'ByProducts',
  ByProductsSubmit = 'ByProductsSubmit',
}

export enum ByProductsSerializedHeaders {
  id = 'id',
  status = 'status',
  ubn = 'ubn',
  idType = 'idType',
  companyReport = 'companyReport',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  purchaseDate = 'purchaseDate',
  productCode = 'productCode',
  productName = 'productName',
  productOrigin = 'productOrigin',
  productQuantity = 'productQuantity',
  quantityType = 'quantityType',
  dryMatter = 'dryMatter',
  re = 're',
  ras = 'ras',
  vcre = 'vcre',
  vcos = 'vcos',
  isProductCodeManualEntered = 'isProductCodeManualEntered',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
  pigletsPercent = 'pigletsPercent',
  sowsPercent = 'sowsPercent',
  pigsPercent = 'pigsPercent',
}

export const ByProductsUnserializedHeaders = {
  id: 'id',
  status: 'status',
  ubn: ['company_report', 'user_passport', 'ubn'],
  idType: ['company_report', 'id_type'],
  companyReport: ['company_report', 'id'],
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  purchaseDate: 'purchase_date',
  productCode: 'product_code',
  productName: 'product_name',
  productOrigin: 'product_origin',
  productQuantity: 'product_quantity',
  quantityType: 'quantity_type',
  dryMatter: 'dry_matter',
  re: 're',
  ras: 'ras',
  vcre: 'vcre',
  vcos: 'vcos',
  isProductCodeManualEntered: 'is_product_code_manual_entered',
  updatedAt: 'updated_at',
  updatedBy: ['updated_by', 'full_name'],
  pigletsPercent: 'piglets_percent',
  sowsPercent: 'sows_percent',
  pigsPercent: 'pigs_percent',
} as const;

export enum ByProductsSubmitSerializedHeaders {
  id = 'id',
  status = 'status',
  companyReport = 'companyReport',
  reportingPeriodDateStart = 'reportingPeriodDateStart',
  reportingPeriodDateEnd = 'reportingPeriodDateEnd',
  purchaseDate = 'purchaseDate',
  productCode = 'productCode',
  productName = 'productName',
  productOrigin = 'productOrigin',
  productQuantity = 'productQuantity',
  quantityType = 'quantityType',
  dryMatter = 'dryMatter',
  re = 're',
  ras = 'ras',
  vcre = 'vcre',
  vcos = 'vcos',
  isProductCodeManualEntered = 'isProductCodeManualEntered',
  pigletsPercent = 'pigletsPercent',
  sowsPercent = 'sowsPercent',
  pigsPercent = 'pigsPercent',
}

export const ByProductsSubmitUnserializedHeaders = {
  id: 'id',
  status: 'status',
  companyReport: 'company_report_id',
  reportingPeriodDateStart: 'reporting_period_date_start',
  reportingPeriodDateEnd: 'reporting_period_date_end',
  purchaseDate: 'purchase_date',
  productCode: 'product_code',
  productName: 'product_name',
  productOrigin: 'product_origin',
  productQuantity: 'product_quantity',
  quantityType: 'quantity_type',
  dryMatter: 'dry_matter',
  re: 're',
  ras: 'ras',
  vcre: 'vcre',
  vcos: 'vcos',
  isProductCodeManualEntered: 'is_product_code_manual_entered',
  pigletsPercent: 'piglets_percent',
  sowsPercent: 'sows_percent',
  pigsPercent: 'pigs_percent',
} as const;

export const BY_PRODUCTS = {
  [ByProductsSerialized.ByProducts]: {
    serialized: ByProductsSerialized.ByProducts,
    unserialized: ByProductsUnserialized.ByProducts,
    view: IntlKeys.titleReportList,

    columns: {
      [ByProductsSerializedHeaders.id]: {
        serialized: ByProductsSerializedHeaders.id,
        unserialized: ByProductsUnserializedHeaders.id,
        view: IntlKeys.farmerInputSystemId,
      },
      [ByProductsSerializedHeaders.status]: {
        serialized: ByProductsSerializedHeaders.status,
        unserialized: ByProductsUnserializedHeaders.status,
        view: IntlKeys.farmerInputStatus,
      },
      [ByProductsSerializedHeaders.ubn]: {
        serialized: ByProductsSerializedHeaders.ubn,
        unserialized: ByProductsUnserializedHeaders.ubn,
        view: IntlKeys.farmerInputIdCode,
      },
      [ByProductsSerializedHeaders.idType]: {
        serialized: ByProductsSerializedHeaders.idType,
        unserialized: ByProductsUnserializedHeaders.idType,
        view: IntlKeys.farmerInputIdType,
      },
      [ByProductsSerializedHeaders.companyReport]: {
        serialized: ByProductsSerializedHeaders.companyReport,
        unserialized: ByProductsUnserializedHeaders.companyReport,
        view: IntlKeys.farmerInputMainReportId,
      },
      [ByProductsSerializedHeaders.reportingPeriodDateStart]: {
        serialized: ByProductsSerializedHeaders.reportingPeriodDateStart,
        unserialized: ByProductsUnserializedHeaders.reportingPeriodDateStart,
        view: IntlKeys.farmerInputPeriodStartDate,
      },
      [ByProductsSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: ByProductsSerializedHeaders.reportingPeriodDateEnd,
        unserialized: ByProductsUnserializedHeaders.reportingPeriodDateEnd,
        view: IntlKeys.farmerInputPeriodEndDate,
      },
      [ByProductsSerializedHeaders.purchaseDate]: {
        serialized: ByProductsSerializedHeaders.purchaseDate,
        unserialized: ByProductsUnserializedHeaders.purchaseDate,
        view: IntlKeys.farmerInputDatePurchase,
      },
      [ByProductsSerializedHeaders.productCode]: {
        serialized: ByProductsSerializedHeaders.productCode,
        unserialized: ByProductsUnserializedHeaders.productCode,
        view: IntlKeys.farmerInputProductCode,
      },
      [ByProductsSerializedHeaders.productName]: {
        serialized: ByProductsSerializedHeaders.productName,
        unserialized: ByProductsUnserializedHeaders.productName,
        view: IntlKeys.farmerInputProductName,
      },
      [ByProductsSerializedHeaders.productOrigin]: {
        serialized: ByProductsSerializedHeaders.productOrigin,
        unserialized: ByProductsUnserializedHeaders.productOrigin,
        view: IntlKeys.farmerInputProductOrigin,
      },
      [ByProductsSerializedHeaders.productQuantity]: {
        serialized: ByProductsSerializedHeaders.productQuantity,
        unserialized: ByProductsUnserializedHeaders.productQuantity,
        view: IntlKeys.farmerInputProductQuantity,
      },
      [ByProductsSerializedHeaders.quantityType]: {
        serialized: ByProductsSerializedHeaders.quantityType,
        unserialized: ByProductsUnserializedHeaders.quantityType,
        view: IntlKeys.farmerInputQuantityType,
      },
      [ByProductsSerializedHeaders.dryMatter]: {
        serialized: ByProductsSerializedHeaders.dryMatter,
        unserialized: ByProductsUnserializedHeaders.dryMatter,
        view: IntlKeys.farmerInputDryMatter,
      },
      [ByProductsSerializedHeaders.re]: {
        serialized: ByProductsSerializedHeaders.re,
        unserialized: ByProductsUnserializedHeaders.re,
        view: IntlKeys.farmerInputRe,
      },
      [ByProductsSerializedHeaders.ras]: {
        serialized: ByProductsSerializedHeaders.ras,
        unserialized: ByProductsUnserializedHeaders.ras,
        view: IntlKeys.farmerInputRas,
      },
      [ByProductsSerializedHeaders.vcre]: {
        serialized: ByProductsSerializedHeaders.vcre,
        unserialized: ByProductsUnserializedHeaders.vcre,
        view: IntlKeys.farmerInputVcre,
      },
      [ByProductsSerializedHeaders.vcos]: {
        serialized: ByProductsSerializedHeaders.vcos,
        unserialized: ByProductsUnserializedHeaders.vcos,
        view: IntlKeys.farmerInputVcos,
      },
      [ByProductsSerializedHeaders.isProductCodeManualEntered]: {
        serialized: ByProductsSerializedHeaders.isProductCodeManualEntered,
        unserialized: ByProductsUnserializedHeaders.isProductCodeManualEntered,
      },
      [ByProductsSerializedHeaders.pigletsPercent]: {
        serialized: ByProductsSerializedHeaders.pigletsPercent,
        unserialized: ByProductsUnserializedHeaders.pigletsPercent,
        view: IntlKeys.farmerInputPigletsPercent,
      },
      [ByProductsSerializedHeaders.sowsPercent]: {
        serialized: ByProductsSerializedHeaders.sowsPercent,
        unserialized: ByProductsUnserializedHeaders.sowsPercent,
        view: IntlKeys.farmerInputSowsPercent,
      },
      [ByProductsSerializedHeaders.pigsPercent]: {
        serialized: ByProductsSerializedHeaders.pigsPercent,
        unserialized: ByProductsUnserializedHeaders.pigsPercent,
        view: IntlKeys.farmerInputPigsPercent,
      },
      [ByProductsSerializedHeaders.updatedAt]: {
        serialized: ByProductsSerializedHeaders.updatedAt,
        unserialized: ByProductsUnserializedHeaders.updatedAt,
        view: IntlKeys.farmerInputUpdatedOn,
      },
      [ByProductsSerializedHeaders.updatedBy]: {
        serialized: ByProductsSerializedHeaders.updatedBy,
        unserialized: ByProductsUnserializedHeaders.updatedBy,
        view: IntlKeys.farmerInputUpdatedBy,
      },
    },
  },
};

export const BY_PRODUCTS_SUBMIT = {
  [ByProductsSerialized.ByProductsSubmit]: {
    serialized: ByProductsSerialized.ByProductsSubmit,
    unserialized: ByProductsUnserialized.ByProductsSubmit,

    columns: {
      [ByProductsSubmitSerializedHeaders.id]: {
        serialized: ByProductsSubmitSerializedHeaders.id,
        unserialized: ByProductsSubmitUnserializedHeaders.id,
      },
      [ByProductsSubmitSerializedHeaders.status]: {
        serialized: ByProductsSubmitSerializedHeaders.status,
        unserialized: ByProductsSubmitUnserializedHeaders.status,
      },
      [ByProductsSubmitSerializedHeaders.companyReport]: {
        serialized: ByProductsSubmitSerializedHeaders.companyReport,
        unserialized: ByProductsSubmitUnserializedHeaders.companyReport,
      },
      [ByProductsSubmitSerializedHeaders.reportingPeriodDateStart]: {
        serialized: ByProductsSubmitSerializedHeaders.reportingPeriodDateStart,
        unserialized: ByProductsSubmitUnserializedHeaders.reportingPeriodDateStart,
      },
      [ByProductsSubmitSerializedHeaders.reportingPeriodDateEnd]: {
        serialized: ByProductsSubmitSerializedHeaders.reportingPeriodDateEnd,
        unserialized: ByProductsSubmitUnserializedHeaders.reportingPeriodDateEnd,
      },
      [ByProductsSubmitSerializedHeaders.purchaseDate]: {
        serialized: ByProductsSubmitSerializedHeaders.purchaseDate,
        unserialized: ByProductsSubmitUnserializedHeaders.purchaseDate,
      },
      [ByProductsSubmitSerializedHeaders.productCode]: {
        serialized: ByProductsSubmitSerializedHeaders.productCode,
        unserialized: ByProductsSubmitUnserializedHeaders.productCode,
      },
      [ByProductsSubmitSerializedHeaders.productName]: {
        serialized: ByProductsSubmitSerializedHeaders.productName,
        unserialized: ByProductsSubmitUnserializedHeaders.productName,
      },
      [ByProductsSubmitSerializedHeaders.productOrigin]: {
        serialized: ByProductsSubmitSerializedHeaders.productOrigin,
        unserialized: ByProductsSubmitUnserializedHeaders.productOrigin,
      },
      [ByProductsSubmitSerializedHeaders.productQuantity]: {
        serialized: ByProductsSubmitSerializedHeaders.productQuantity,
        unserialized: ByProductsSubmitUnserializedHeaders.productQuantity,
      },
      [ByProductsSubmitSerializedHeaders.quantityType]: {
        serialized: ByProductsSubmitSerializedHeaders.quantityType,
        unserialized: ByProductsSubmitUnserializedHeaders.quantityType,
      },
      [ByProductsSubmitSerializedHeaders.dryMatter]: {
        serialized: ByProductsSubmitSerializedHeaders.dryMatter,
        unserialized: ByProductsSubmitUnserializedHeaders.dryMatter,
      },
      [ByProductsSubmitSerializedHeaders.re]: {
        serialized: ByProductsSubmitSerializedHeaders.re,
        unserialized: ByProductsSubmitUnserializedHeaders.re,
      },
      [ByProductsSubmitSerializedHeaders.ras]: {
        serialized: ByProductsSubmitSerializedHeaders.ras,
        unserialized: ByProductsSubmitUnserializedHeaders.ras,
      },
      [ByProductsSubmitSerializedHeaders.vcre]: {
        serialized: ByProductsSubmitSerializedHeaders.vcre,
        unserialized: ByProductsSubmitUnserializedHeaders.vcre,
      },
      [ByProductsSubmitSerializedHeaders.vcos]: {
        serialized: ByProductsSubmitSerializedHeaders.vcos,
        unserialized: ByProductsSubmitUnserializedHeaders.vcos,
      },
      [ByProductsSubmitSerializedHeaders.isProductCodeManualEntered]: {
        serialized: ByProductsSubmitSerializedHeaders.isProductCodeManualEntered,
        unserialized: ByProductsSubmitUnserializedHeaders.isProductCodeManualEntered,
      },
      [ByProductsSubmitSerializedHeaders.pigletsPercent]: {
        serialized: ByProductsSubmitSerializedHeaders.pigletsPercent,
        unserialized: ByProductsSubmitUnserializedHeaders.pigletsPercent,
      },
      [ByProductsSubmitSerializedHeaders.sowsPercent]: {
        serialized: ByProductsSubmitSerializedHeaders.sowsPercent,
        unserialized: ByProductsSubmitUnserializedHeaders.sowsPercent,
      },
      [ByProductsSubmitSerializedHeaders.pigsPercent]: {
        serialized: ByProductsSubmitSerializedHeaders.pigsPercent,
        unserialized: ByProductsSubmitUnserializedHeaders.pigsPercent,
      },
    },
  },
};

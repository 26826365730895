import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import qs from 'qs';

import { IntlKeys } from 'lib/localization/keys';

import { RootState } from 'store/store';
import { currentRouteSelector } from 'store/reducers/routerReducer';
import { userHasPermissionSelector } from 'store/auth/reducer';
import { setFiltersMenuStateAction } from 'store/filters/actions';
import filterConfigs from 'store/filters/entities';

import { makeStyles } from '@material-ui/core';
import MuiChip from '@material-ui/core/Chip';
import { restoreFilterFromStorage } from 'lib/filters';

const mapStateToProps = (state: RootState) => {
  const currentRoute = currentRouteSelector(state);

  return {
    userHasPermission: userHasPermissionSelector(state),
    filterConfig: currentRoute.filterType ? filterConfigs[currentRoute.filterType] : undefined,
  };
};

const FilterChips = () => {
  const classes = useStyles();
  const location = useLocation();
  const { filterConfig, userHasPermission } = useSelector(mapStateToProps);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  let { filter } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as Partial<{ filter?: { [key: string]: string | string[] | Record<string, unknown> } }>;

  filter = restoreFilterFromStorage(filter);

  const fields = useMemo(
    () =>
      filterConfig
        ? Object.entries(filterConfig.config)
            .map(([filterKey, filterConfig]) => ({
              title: filterConfig.title,
              key: filterKey,
              label: filterConfig.getLabel(filter?.[filterKey]),
              Icon: filterConfig.icon,
              visible: !filterConfig.permissions || filterConfig.permissions!.every(userHasPermission),
            }))
            .filter(({ visible }) => visible)
        : [],
    [filter, filterConfig, userHasPermission],
  );

  const handleChipClick = useCallback(() => {
    dispatch(setFiltersMenuStateAction(true));
  }, [dispatch]);

  return (
    <React.Fragment>
      {fields.map(
        ({ key, Icon, label, title }) =>
          label && (
            <MuiChip
              key={key}
              variant="outlined"
              icon={<Icon />}
              label={`
                ${formatMessage({ id: title || IntlKeys.filter })}:
                ${formatMessage({ id: label && label.intlId || label, defaultMessage: label })}
              `}
              onClick={handleChipClick}
              className={classes.chip}
            />
          ),
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles(() => ({
  chip: {
    backgroundColor: '#fff !important',
    margin: '0 8px 8px 0',

    '& > .MuiChip-icon': {
      marginLeft: 8,
    },

    '& > .MuiChip-label': {
      letterSpacing: 0.4,
      lineHeight: '16px',
    },
  },
}));

export default FilterChips;

import { CL607_FIELDS } from 'lib/excel/config/referenceTables/cl607/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = CL607_FIELDS.Cl607;

export const getCl607DocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.code.original),
      new CellValidator(columns.description.original),
      new CellValidator(columns.remarks.original),
    ]),
  ]);
};

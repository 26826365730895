import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { ResourcesWrapper } from 'lib/http/utils';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import {
  TRANSPORT_LOADINGS,
  TRANSPORT_UNLOADINGS,
  TransportLoadingsSerialized,
} from 'lib/tables/config/transports/loadings/config';

interface TransportLoadingResponseEntry {
  company_scheme: string;
  company_value: string;
  end_date: string;
  granted_on: string;
  refused_on: string;
  replaced_by_id: string;
  revoked_on: string;
  start_date: string;
  status: number;
  is_valid: boolean;
  purpose: {
    name: string;
  };
  restrictions: [
    {
      restriction_type: string;
      description: string;
      date_start: string;
      date_end: string;
      scheme: string;
    },
  ];
}

type Config = typeof TRANSPORT_LOADINGS[TransportLoadingsSerialized.Loadings];

const getSerializerFactory = ({ columns, serialized, unserialized }: Config) => {
  return (data: DocumentData<TransportLoadingResponseEntry[]>) => {
    return new DocumentSerializer(data, [
      new SheetSerializer(unserialized, serialized, [
        new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),

        new CellSerializer(columns.transportId.unserialized, columns.transportId.serialized),
        new CellSerializer(columns.plannedArrivalTime.unserialized, columns.plannedArrivalTime.serialized),
        new CellSerializer(columns.actualArrivalTime.unserialized, columns.actualArrivalTime.serialized),
        new CellSerializer(columns.startLoadingTime.unserialized, columns.startLoadingTime.serialized),
        new CellSerializer(columns.endLoadingTime.unserialized, columns.endLoadingTime.serialized),
        new CellSerializer(columns.plannedDepartureTime.unserialized, columns.plannedDepartureTime.serialized),
        new CellSerializer(columns.actualDepartureTime.unserialized, columns.actualDepartureTime.serialized),
        new CellSerializer(columns.numberOfAnimals.unserialized, columns.numberOfAnimals.serialized),
        new CellSerializer(columns.locationIdTypeCode.unserialized, columns.locationIdTypeCode.serialized),
        new CellSerializer(columns.locationIdValue.unserialized, columns.locationIdValue.serialized),
        new CellSerializer(columns.locationName.unserialized, columns.locationName.serialized),

        new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
        new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
        new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
        new CellSerializer(
          SERVICE_FIELDS.blockchainTimestamp.unserialized,
          SERVICE_FIELDS.blockchainTimestamp.serialized,
        ),
        new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      ]),
    ]);
  };
};

export const serializeTransportLoadings = (serverData: ResourcesWrapper<TransportLoadingResponseEntry>) => {
  const config = TRANSPORT_LOADINGS[TransportLoadingsSerialized.Loadings];

  const data = {
    [config.unserialized]: serverData.data.resources,
  };

  const getSerializer = getSerializerFactory(TRANSPORT_LOADINGS[TransportLoadingsSerialized.Loadings]);

  const serializer = getSerializer(data);

  return serializer.serialize();
};

export const serializeTransportUnloadings = (serverData: ResourcesWrapper<TransportLoadingResponseEntry>) => {
  const config = TRANSPORT_UNLOADINGS[TransportLoadingsSerialized.Unloadings];

  const data = {
    [config.unserialized]: serverData.data.resources,
  };

  const getSerializer = getSerializerFactory(config);

  const serializer = getSerializer(data);

  return serializer.serialize();
};

import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import { createRootReducer } from './createRootReducer';
import { history } from './reducers/routerReducer';
import { AppMode, EnvVars } from 'lib/env/Env';

import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const createStore = () => {
  const rootReducer = createRootReducer();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [routerMiddleware(history), sagaMiddleware],
    devTools: true,
    enhancers: [],
  });

  if (process.env[EnvVars.MODE] !== AppMode.PROD) {
    if (module.hot) {
      module.hot.accept('./createRootReducer', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = createStore();
export { history };
export type RootState = ReturnType<typeof store.getState>;

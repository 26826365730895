import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_ROOM } from './config';

export type CodesRoomPayload = {
  id: number;
  company_identifier: string;
  farm_identifier: string;
  barn_identifier: string;
  room_identifier: string;
  abbreviation: string;
  description: string;
  code_room_datetime: string;
  surface: string;
  room_type_identifier: string;
  color: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type CodesRoomSerialized = {
  id: number;
  companyIdentifier: string;
  farmIdentifier: string;
  barnIdentifier: string;
  roomIdentifier: string;
  abbreviation: string;
  description: string;
  codeRoomDatetime: string;
  surface: string;
  roomTypeIdentifier: string;
  color: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = CODES_ROOM.CodesRoom;

const getDefaultSerializer = (data: DocumentData<CodesRoomPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.farmIdentifier.unserialized, columns.farmIdentifier.serialized),
      new CellSerializer(columns.barnIdentifier.unserialized, columns.barnIdentifier.serialized),
      new CellSerializer(columns.roomIdentifier.unserialized, columns.roomIdentifier.serialized),
      new CellSerializer(columns.abbreviation.unserialized, columns.abbreviation.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.codeRoomDatetime.unserialized, columns.codeRoomDatetime.serialized),
      new CellSerializer(columns.surface.unserialized, columns.surface.serialized),
      new CellSerializer(columns.roomTypeIdentifier.unserialized, columns.roomTypeIdentifier.serialized),
      new CellSerializer(columns.color.unserialized, columns.color.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeCodesRoom = (serverData: ResourcesWrapper<CodesRoomPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys/__keys';

enum CfpGfliFeedprintTableOriginal {
  CfpGfliFeedprintTable = 'CFP_GFLI_Feedprint_table',
}

enum CfpGfliFeedprintTableSerialized {
  CfpGfliFeedprintTable = 'CfpGfliFeedprintTable',
}

enum CfpGfliFeedprintTableUnserialized {
  CfpGfliFeedprintTable = 'CfpGfliFeedprintTable',
}

enum CfpGfliFeedprintTableOriginalHeaders {
  'feedPrintCode' = 'Code FeedprintCVB',
  'cvbCode' = 'CVB code',
  'source' = 'Source',
  'productNameEn' = 'Product Name EN ',
  'productNameNl' = 'Product Name NL ',
  'productCodeEu' = 'EU product code ',
  'productNameEu' = 'EU product name ',
  'origin' = 'Origin',
  'climate' = 'Climate (incl. luc) (g CO2 eq/kg)',
}

enum CfpGfliFeedprintTableSerializedHeaders {
  'feedPrintCode' = 'feedPrintCode',
  'cvbCode' = 'cvbCode',
  'source' = 'source',
  'productNameEn' = 'productNameEn',
  'productNameNl' = 'productNameNl',
  'productCodeEu' = 'productCodeEu',
  'productNameEu' = 'productNameEu',
  'origin' = 'origin',
  'climate' = 'climate',
}
enum CfpGfliFeedprintTableUnserializedHeaders {
  'feedPrintCode' = 'feed_print_code',
  'cvbCode' = 'cvb_code',
  'source' = 'source',
  'productNameEn' = 'product_name_en',
  'productNameNl' = 'product_name_nl',
  'productCodeEu' = 'product_code_eu',
  'productNameEu' = 'product_name_eu',
  'origin' = 'origin',
  'climate' = 'climate',
}

export const CFP_GFLI_FEEDPRINT_TABLE = {
  [CfpGfliFeedprintTableUnserialized.CfpGfliFeedprintTable]: {
    original: CfpGfliFeedprintTableOriginal.CfpGfliFeedprintTable,
    serialized: CfpGfliFeedprintTableSerialized.CfpGfliFeedprintTable,
    unserialized: CfpGfliFeedprintTableUnserialized.CfpGfliFeedprintTable,
    view: IntlKeys.referenceTablesCfpGfliFeedprintTable,
    viewName: 'CFP_GFLI_Feedprint_table',

    columns: {
      [CfpGfliFeedprintTableSerializedHeaders.feedPrintCode]: {
        original: CfpGfliFeedprintTableOriginalHeaders.feedPrintCode,
        serialized: CfpGfliFeedprintTableSerializedHeaders.feedPrintCode,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.feedPrintCode,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableFeedPrintCode,
      },
      [CfpGfliFeedprintTableSerializedHeaders.cvbCode]: {
        original: CfpGfliFeedprintTableOriginalHeaders.cvbCode,
        serialized: CfpGfliFeedprintTableSerializedHeaders.cvbCode,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.cvbCode,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableCvbCode,
      },
      [CfpGfliFeedprintTableSerializedHeaders.source]: {
        original: CfpGfliFeedprintTableOriginalHeaders.source,
        serialized: CfpGfliFeedprintTableSerializedHeaders.source,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.source,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableSource,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productNameEn]: {
        original: CfpGfliFeedprintTableOriginalHeaders.productNameEn,
        serialized: CfpGfliFeedprintTableSerializedHeaders.productNameEn,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productNameEn,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEn,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productNameNl]: {
        original: CfpGfliFeedprintTableOriginalHeaders.productNameNl,
        serialized: CfpGfliFeedprintTableSerializedHeaders.productNameNl,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productNameNl,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameNl,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productCodeEu]: {
        original: CfpGfliFeedprintTableOriginalHeaders.productCodeEu,
        serialized: CfpGfliFeedprintTableSerializedHeaders.productCodeEu,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productCodeEu,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductCodeEu,
      },
      [CfpGfliFeedprintTableSerializedHeaders.productNameEu]: {
        original: CfpGfliFeedprintTableOriginalHeaders.productNameEu,
        serialized: CfpGfliFeedprintTableSerializedHeaders.productNameEu,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.productNameEu,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEu,
      },
      [CfpGfliFeedprintTableSerializedHeaders.origin]: {
        original: CfpGfliFeedprintTableOriginalHeaders.origin,
        serialized: CfpGfliFeedprintTableSerializedHeaders.origin,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.origin,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableOrigin,
      },
      [CfpGfliFeedprintTableSerializedHeaders.climate]: {
        original: CfpGfliFeedprintTableOriginalHeaders.climate,
        serialized: CfpGfliFeedprintTableSerializedHeaders.climate,
        unserialized: CfpGfliFeedprintTableUnserializedHeaders.climate,
        view: IntlKeys.referenceTablesCfpGfliFeedprintTableClimate,
      },
    },
  },
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { WUR_MCF_EFEM } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type WurMcfEfemPayload = {
  id: number;
  system: string;
  avg_storage_duration_manure: string;
  mcf: number;
  wur_mcf_efem_upload: ManageUploadPayload;
};

export type WurMcfEfemSerialized = {
  id: number;
  referenceUploadID: number;
  system: string;
  avgStorageDurationManure: string;
  mcf: number;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = WUR_MCF_EFEM.WurMcfEfem;

const getDefaultSerializer = (data: DocumentData<WurMcfEfemPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.system.unserialized, columns.system.serialized),
      new CellSerializer(columns.avgStorageDurationManure.unserialized, columns.avgStorageDurationManure.serialized),
      new CellSerializer(columns.mcf.unserialized, columns.mcf.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeWurMcfEfem = (serverData: ResourcesWrapper<WurMcfEfemPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

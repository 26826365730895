import { CL607_FIELDS } from 'lib/excel/config/referenceTables/cl607/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = CL607_FIELDS.Cl607;

export const getCl607DisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.code.serialized, extractPropName: columns.code.view }),
      new DisplayHeader({ headerName: columns.description.serialized, extractPropName: columns.description.view }),
      new DisplayHeader({ headerName: columns.remarks.serialized, extractPropName: columns.remarks.view }),
    ]),
  ]);
};

import { IPCC_EMISSION_FACTORS_FIELDS } from '../structure';
import { DisplayDocument } from '../../../../display/DisplayDocument';
import { DisplayTab } from '../../../../display/DisplayTab';
import { DisplayHeader } from '../../../../display/DisplayHeader';

const { columns, view } = IPCC_EMISSION_FACTORS_FIELDS.IpccEmissionFactors;

export const getIpccEmissionFactorsDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.factor.serialized, extractPropName: columns.factor.view }),
      new DisplayHeader({ headerName: columns.description.serialized, extractPropName: columns.description.view }),
      new DisplayHeader({ headerName: columns.defaultValue.serialized, extractPropName: columns.defaultValue.view }),
      new DisplayHeader({
        headerName: columns.uncertaintyRange.serialized,
        extractPropName: columns.uncertaintyRange.view,
      }),
    ]),
  ]);
};

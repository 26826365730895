import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { PIG_HOUSING_EMISSION_FACTORS } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type PigHousingEmissionFactorsPayload = {
  id: number;
  livestock_category: string | null;
  housing_system: string | null;
  year_value: number | null;
  emissionValue: number | null;
  unit: string | null;
  pig_housing_emission_factor_upload: ManageUploadPayload;
};

export type PigHousingEmissionFactorsSerialized = {
  id: number;
  referenceUploadID: number;
  livestockCategory: string;
  housingSystem: string;
  yearValue: number;
  emissionValue: number;
  unit: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = PIG_HOUSING_EMISSION_FACTORS.PigHousingEmissionFactors;

const getDefaultSerializer = (data: DocumentData<PigHousingEmissionFactorsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.livestockCategory.unserialized, columns.livestockCategory.serialized),
      new CellSerializer(columns.housingSystem.unserialized, columns.housingSystem.serialized),
      new CellSerializer(columns.yearValue.unserialized, columns.yearValue.serialized),
      new CellSerializer(columns.emissionValue.unserialized, columns.emissionValue.serialized),
      new CellSerializer(columns.unit.unserialized, columns.unit.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializePigHousingEmissionFactors = (serverData: ResourcesWrapper<PigHousingEmissionFactorsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

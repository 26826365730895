import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_TREATMENTS } from './config';

export type CodesTreatmentsPayload = {
  id: number;
  company_identifier: string;
  treatment_identifier: string;
  registration_number: string;
  abbreviation: string;
  description: string;
  wh_period: string;
  destination: string;
  note: string;
  treatment_datetime: string;
  sows: string;
  sucklers: string;
  weaners: string;
  replstock: string;
  finishers: string;
  boars: string;
  treatments_per_day: string;
  first_repeat_treatment: string;
  second_repeat_treatment: string;
  third_repeat_treatment: string;
  fourth_repeat_treatment: string;
  fifth_repeat_treatment: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type CodesTreatmentsSerialized = {
  id: number;
  companyIdentifier: string;
  treatmentIdentifier: string;
  abbreviation: string;
  description: string;
  WhPeriod: string;
  destination: string;
  note: string;
  treatmentDatetime: string;
  sows: string;
  sucklers: string;
  weaners: string;
  replstock: string;
  finishers: string;
  boars: string;
  treatmentsPerDay: string;
  firstRepeatTreatment: string;
  secondRepeatTreatment: string;
  thirdRepeatTreatment: string;
  fourthRepeatTreatment: string;
  fifthRepeatTreatment: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = CODES_TREATMENTS.CodesTreatments;

const getDefaultSerializer = (data: DocumentData<CodesTreatmentsPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.treatmentIdentifier.unserialized, columns.treatmentIdentifier.serialized),
      new CellSerializer(columns.abbreviation.unserialized, columns.abbreviation.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.WhPeriod.unserialized, columns.WhPeriod.serialized),
      new CellSerializer(columns.destination.unserialized, columns.destination.serialized),
      new CellSerializer(columns.note.unserialized, columns.note.serialized),
      new CellSerializer(columns.treatmentDatetime.unserialized, columns.treatmentDatetime.serialized),
      new CellSerializer(columns.sows.unserialized, columns.sows.serialized),
      new CellSerializer(columns.sucklers.unserialized, columns.sucklers.serialized),
      new CellSerializer(columns.weaners.unserialized, columns.weaners.serialized),
      new CellSerializer(columns.replstock.unserialized, columns.replstock.serialized),
      new CellSerializer(columns.finishers.unserialized, columns.finishers.serialized),
      new CellSerializer(columns.boars.unserialized, columns.boars.serialized),
      new CellSerializer(columns.treatmentsPerDay.unserialized, columns.treatmentsPerDay.serialized),
      new CellSerializer(columns.firstRepeatTreatment.unserialized, columns.firstRepeatTreatment.serialized),
      new CellSerializer(columns.secondRepeatTreatment.unserialized, columns.secondRepeatTreatment.serialized),
      new CellSerializer(columns.thirdRepeatTreatment.unserialized, columns.thirdRepeatTreatment.serialized),
      new CellSerializer(columns.fourthRepeatTreatment.unserialized, columns.fourthRepeatTreatment.serialized),
      new CellSerializer(columns.fifthRepeatTreatment.unserialized, columns.fifthRepeatTreatment.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeCodesTreatments = (serverData: ResourcesWrapper<CodesTreatmentsPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

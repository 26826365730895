/**
 * @deprecated use single IntlKeys
 */
export enum GeneticKeys {
  ubn = 'genetics.ubn',
  semenProducerUbn = 'genetics.semenProducerUBN',
  deliveryDate = 'genetics.deliveryDate',
  maleType = 'genetics.maleType',
  maleAmount = 'genetics.maleAmount',
  femaleType = 'genetics.femaleType',
  femaleAmount = 'genetics.femaleAmount',
}

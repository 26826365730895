import { IntlKeys } from 'lib/localization/keys/__keys';

enum PigHousingEmissionFactorsOriginal {
  PigHousingEmissionFactors = 'CFP_NEMA_NH3-emission_factors_f',
}

enum PigHousingEmissionFactorsSerialized {
  PigHousingEmissionFactors = 'PigHousingEmissionFactors',
}

enum PigHousingEmissionFactorsUnserialized {
  PigHousingEmissionFactors = 'PigHousingEmissionFactors',
}

enum PigHousingEmissionFactorsOriginalHeaders {
  'livestockCategory' = 'Livestock_category',
  'housingSystem' = 'Housing_system',
  'yearValue' = 'Year_of_Value',
  'emissionValue' = 'Value',
  'unit' = 'Value Unit',
}

enum PigHousingEmissionFactorsSerializedHeaders {
  'livestockCategory' = 'livestockCategory',
  'housingSystem' = 'housingSystem',
  'yearValue' = 'yearValue',
  'emissionValue' = 'emissionValue',
  'unit' = 'unit',
}
enum PigHousingEmissionFactorsUnserializedHeaders {
  'livestockCategory' = 'livestock_category',
  'housingSystem' = 'housing_system',
  'yearValue' = 'year_value',
  'emissionValue' = 'emission_value',
  'unit' = 'unit',
}

export const PIG_HOUSING_EMISSION_FACTORS = {
  [PigHousingEmissionFactorsUnserialized.PigHousingEmissionFactors]: {
    original: PigHousingEmissionFactorsOriginal.PigHousingEmissionFactors,
    serialized: PigHousingEmissionFactorsSerialized.PigHousingEmissionFactors,
    unserialized: PigHousingEmissionFactorsUnserialized.PigHousingEmissionFactors,
    view: IntlKeys.referenceTablesPigHousingEmissionFactors,
    viewName: 'CFP_NEMA_NH3-emission_factors_for_pig_housing',

    columns: {
      [PigHousingEmissionFactorsSerializedHeaders.livestockCategory]: {
        original: PigHousingEmissionFactorsOriginalHeaders.livestockCategory,
        serialized: PigHousingEmissionFactorsSerializedHeaders.livestockCategory,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.livestockCategory,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsCategory,
      },
      [PigHousingEmissionFactorsSerializedHeaders.housingSystem]: {
        original: PigHousingEmissionFactorsOriginalHeaders.housingSystem,
        serialized: PigHousingEmissionFactorsSerializedHeaders.housingSystem,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.housingSystem,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsSystem,
      },
      [PigHousingEmissionFactorsSerializedHeaders.yearValue]: {
        original: PigHousingEmissionFactorsOriginalHeaders.yearValue,
        serialized: PigHousingEmissionFactorsSerializedHeaders.yearValue,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.yearValue,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsYearValue,
      },
      [PigHousingEmissionFactorsSerializedHeaders.emissionValue]: {
        original: PigHousingEmissionFactorsOriginalHeaders.emissionValue,
        serialized: PigHousingEmissionFactorsSerializedHeaders.emissionValue,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.emissionValue,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsEmissionValue,
      },
      [PigHousingEmissionFactorsSerializedHeaders.unit]: {
        original: PigHousingEmissionFactorsOriginalHeaders.unit,
        serialized: PigHousingEmissionFactorsSerializedHeaders.unit,
        unserialized: PigHousingEmissionFactorsUnserializedHeaders.unit,
        view: IntlKeys.referenceTablesPigHousingEmissionFactorsUnit,
      },
    },
  },
};

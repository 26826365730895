import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { ENTITIES_LIST } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type EntitiesListPayload = {
  id: number;
  entity_id: string | null;
  entity_name: string | null;
  country: string | null;
  business_unit: string | null;
  entities_list_upload: ManageUploadPayload;
};

export type EntitiesListSerialized = {
  id: number;
  referenceUploadID: number;
  entityId: string;
  entityName: string;
  country: string;
  businessUnit: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = ENTITIES_LIST.EntitiesList;

const getDefaultSerializer = (data: DocumentData<EntitiesListPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.entityId.unserialized, columns.entityId.serialized),
      new CellSerializer(columns.entityName.unserialized, columns.entityName.serialized),
      new CellSerializer(columns.country.unserialized, columns.country.serialized),
      new CellSerializer(columns.businessUnit.unserialized, columns.businessUnit.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeEntitiesList = (serverData: ResourcesWrapper<EntitiesListPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

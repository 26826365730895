import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { COUNTRY_CODES } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type CountryCodesPayload = {
  id: number;
  iso_3166_1_code: string | null;
  subdivision_name: string | null;
  iso_3166_2_code: string | null;
  country_name: string | null;
  country_code_upload: ManageUploadPayload;
};

export type CountryCodesSerialized = {
  id: number;
  referenceUploadID: number;
  iso31661Code: string;
  subdivisionName: string;
  iso31662Code: string;
  countryName: string;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = COUNTRY_CODES.CountryCodes;

const getDefaultSerializer = (data: DocumentData<CountryCodesPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.iso31661Code.unserialized, columns.iso31661Code.serialized),
      new CellSerializer(columns.subdivisionName.unserialized, columns.subdivisionName.serialized),
      new CellSerializer(columns.iso31662Code.unserialized, columns.iso31662Code.serialized),
      new CellSerializer(columns.countryName.unserialized, columns.countryName.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeCountryCodes = (serverData: ResourcesWrapper<CountryCodesPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { CVB_TABLE_FIELDS } from '../structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = CVB_TABLE_FIELDS.CvbTable;

export const getCvbTableDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.productCode.original),
      new CellValidator(columns.productSubCode.original),
      new CellValidator(columns.productType.original),
      new CellValidator(columns.productNameNL.original),
      new CellValidator(columns.classNameNL.original),
      new CellValidator(columns.subClassNameNL.original),
      new CellValidator(columns.productNameEN.original),
      new CellValidator(columns.classNameEN.original),
      new CellValidator(columns.subClassNameEN.original),
      new CellValidator(columns.ds.original),
      new CellValidator(columns.dsUnit.original),
      new CellValidator(columns.ras.original),
      new CellValidator(columns.rasUnit.original),
      new CellValidator(columns.rein.original),
      new CellValidator(columns.reinUnit.original),
      new CellValidator(columns.re.original),
      new CellValidator(columns.reUnit.original),
      new CellValidator(columns.cVcRe.original),
      new CellValidator(columns.cVcReUnit.original),
      new CellValidator(columns.cVcOs.original),
      new CellValidator(columns.cVcOsUnit.original),
    ]),
  ]);
};

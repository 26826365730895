import { ELECTRICITY_CONSUMPTION_RATES_FIELDS } from 'lib/excel/config/referenceTables/electricityConsumptionRates/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = ELECTRICITY_CONSUMPTION_RATES_FIELDS.ElectricityConsumptionRates;

export const serializeElectricityConsumptionRatesData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.householdPeopleQuantity.original, columns.householdPeopleQuantity.serialized),
      new CellSerializer(columns.electricityConsumption.original, columns.electricityConsumption.serialized),
    ]),
  ]);
};

import moment, { Moment } from 'moment';

import { IntlKeys } from 'lib/localization/keys';

import DateRangeIcon from '@material-ui/icons/DateRange';

import { FILTER_TYPES } from './index';

export enum DateInterval {
  ALL = 'ALL',
  TODAY = 'TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  DATE_RANGE = 'DATE_RANGE',
}

export interface DateRangeFilterValues {
  interval?: DateInterval;
  dateFrom?: Date | Moment | string | null;
  dateTo?: Date | Moment | string | null;
}

export interface DateIntervalConfig {
  id: number;
  label: string;
  value: DateInterval;
  // For url query to sync view
  getQuery: <T extends DateRangeFilterValues>(
    filters?: T,
  ) => { interval: DateInterval; dateFrom?: Date; dateTo?: Date } | {};
  // For request
  getValues: <T extends DateRangeFilterValues>(filters?: T) => { dateFrom: Date; dateTo: Date } | {};
  isDefault?: boolean;
}

export const dateRangeConfigs = (defaultFilter: DateInterval) =>
  ({
    [DateInterval.ALL]: {
      id: 0,
      label: IntlKeys.uiAll,
      value: DateInterval.ALL,
      getQuery: () => ({ interval: DateInterval.ALL }),
      getValues: () => ({ interval: DateInterval.ALL, dateFrom: undefined, dateTo: undefined }),
      isDefault: defaultFilter === DateInterval.ALL,
    },
    [DateInterval.TODAY]: {
      id: 1,
      label: IntlKeys.uiToday,
      value: DateInterval.TODAY,
      getQuery: () => ({ interval: DateInterval.TODAY }),
      getValues: () => ({
        interval: DateInterval.TODAY,
        dateFrom: moment().utc().startOf('day').toDate(),
        dateTo: moment().utc().endOf('day').toDate(),
      }),
      isDefault: defaultFilter === DateInterval.TODAY,
    },
    [DateInterval.LAST_7_DAYS]: {
      id: 2,
      label: IntlKeys.uiThisWeek,
      getQuery: () => ({ interval: DateInterval.LAST_7_DAYS }),
      getValues: () => ({
        interval: DateInterval.LAST_7_DAYS,
        dateFrom: moment().utc().subtract(7, 'days').startOf('day').toDate(),
        dateTo: moment().utc().endOf('day').toDate(),
      }),
      value: DateInterval.LAST_7_DAYS,
      isDefault: defaultFilter === DateInterval.LAST_7_DAYS,
    },
    [DateInterval.THIS_MONTH]: {
      id: 3,
      label: IntlKeys.uiThisMonth,
      getQuery: () => ({ interval: DateInterval.THIS_MONTH }),
      getValues: () => ({
        interval: DateInterval.THIS_MONTH,
        dateFrom: moment().utc().startOf('month').toDate(),
        dateTo: moment().utc().endOf('day').toDate(),
      }),
      value: DateInterval.THIS_MONTH,
      isDefault: defaultFilter === DateInterval.THIS_MONTH,
    },
    [DateInterval.DATE_RANGE]: {
      id: 4,
      label: IntlKeys.uiDateRange,
      getQuery: (filters) => {
        const dateFrom = filters?.dateFrom || new Date(0);
        const dateTo = filters?.dateTo || new Date();

        return {
          interval: DateInterval.DATE_RANGE,
          dateFrom: moment(dateFrom).utc().startOf('day').toDate(),
          dateTo: moment(dateTo).utc().endOf('day').toDate(),
        };
      },
      getValues: (filters) => {
        const dateFrom = filters?.dateFrom || new Date(0);
        const dateTo = filters?.dateTo || new Date();

        return {
          interval: DateInterval.DATE_RANGE,
          dateFrom: moment(dateFrom).utc().startOf('day').toDate(),
          dateTo: moment(dateTo).utc().endOf('day').toDate(),
        };
      },
      value: DateInterval.DATE_RANGE,
      isDefault: false,
    },
  } as Record<DateInterval, DateIntervalConfig>);

export const dateRangeFilter = (
  title: string,
  defaultInterval: DateInterval = DateInterval.THIS_MONTH,
  defaultDates?: { dateFrom: Date, dateTo: Date }
) => {
  const config = dateRangeConfigs(defaultInterval);
  return {
    title,
    type: FILTER_TYPES.DATE_RANGE,
    getInitialValues: () => config[defaultInterval].getValues(defaultDates),
    getQuery: (dataRangeFilter: DateRangeFilterValues) => {
      if (defaultDates?.dateFrom && !dataRangeFilter.dateFrom) {
        dataRangeFilter.dateFrom = defaultDates.dateFrom;
      }
      if (defaultDates?.dateTo && !dataRangeFilter.dateTo) {
        dataRangeFilter.dateTo = defaultDates.dateTo;
      }
      return config[dataRangeFilter.interval || defaultInterval].getQuery(dataRangeFilter);
    },
    getValues: (dataRangeFilter: DateRangeFilterValues) => {
      if (defaultDates?.dateFrom && !dataRangeFilter.dateFrom) {
        dataRangeFilter.dateFrom = defaultDates.dateFrom;
      }
      if (defaultDates?.dateTo && !dataRangeFilter.dateTo) {
        dataRangeFilter.dateTo = defaultDates.dateTo;
      }
      return config[dataRangeFilter.interval || defaultInterval].getValues(dataRangeFilter);
    },
    getLabel: (dataRangeFilter: DateRangeFilterValues) => {
      return config[dataRangeFilter?.interval || defaultInterval].label;
    },
    icon: DateRangeIcon,
  };
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { WEEKLY_DOCUMENT_FIELDS } from 'lib/excel/config/weekly/structure';
import { dateToFormattedString } from 'lib/excel/serilizers/utils';
import { SERVICE_FIELDS } from 'lib/excel/config/service';

import { ResourcesWrapper } from 'lib/http/utils';

interface FarmUnserialized {
  id: number;
  ubn: string;
  address: string;
  postal_code: string;
  place: string;
  phone: string;
  farm_type: string;
  program: string;
  sows_count: number;
  updated_date: string;
  vlv: number;
  remark: string;
  created_at: string;
  updated_at: string;
  company_name: string;
}

const getCertificatesSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(WEEKLY_DOCUMENT_FIELDS.Company.unserialized, WEEKLY_DOCUMENT_FIELDS.Company.serialized, [
      new CellSerializer(SERVICE_FIELDS.createdAt.unserialized, SERVICE_FIELDS.createdAt.serialized),
      new CellSerializer(SERVICE_FIELDS.updatedAt.unserialized, SERVICE_FIELDS.updatedAt.serialized),
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.ubn.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.ubn.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.name.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.name.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.address.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.address.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.postalCode.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.postalCode.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.place.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.place.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.phone.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.phone.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.date.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.date.serialized,
        dateToFormattedString,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.sows.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.sows.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.vlv.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.vlv.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.program.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.program.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.remark.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.remark.serialized,
      ),
      new CellSerializer(
        WEEKLY_DOCUMENT_FIELDS.Company.columns.type.unserialized,
        WEEKLY_DOCUMENT_FIELDS.Company.columns.type.serialized,
      ),

      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeFarmsInfo = (serverData: ResourcesWrapper<FarmUnserialized>) => {
  const data = {
    [WEEKLY_DOCUMENT_FIELDS.Company.unserialized]: serverData.data.resources,
  };

  const serializer = getCertificatesSerializer(data);

  return serializer.serialize();
};

import { IntlKeys } from 'lib/localization/keys/__keys';

export enum TransportsSerialized {
  Transports = 'Transports',
}

enum TransportsUnserialized {
  Transports = 'Transports',
}

enum TransportsSerializedHeaders {
  loadings = 'loadings',
  unloadings = 'unloadings',
  transportId = 'transportId',
  transportReferenceId = 'transportReferenceId',
  partyIdTypeCode = 'partyIdTypeCode',
  partyIdValue = 'partyIdValue',
  logisticServiceProviderName = 'logisticServiceProviderName',
  labels = 'labels',
}

enum TransportsUnserializedHeaders {
  transportId = 'transport_id',
  transportReferenceId = 'transport_reference_id',
  partyIdTypeCode = 'party_id_type_code',
  partyIdValue = 'party_id_value',
  logisticServiceProviderName = 'logistic_service_provider_name',
  labels = 'labels',
}

export const TRANSPORTS = {
  [TransportsSerialized.Transports]: {
    serialized: TransportsSerialized.Transports,
    unserialized: TransportsUnserialized.Transports,
    view: IntlKeys.transports,
    columns: {
      [TransportsSerializedHeaders.loadings]: {
        serialized: TransportsSerializedHeaders.loadings,
        view: IntlKeys.transportLoadingsShort,
      },
      [TransportsSerializedHeaders.unloadings]: {
        serialized: TransportsSerializedHeaders.unloadings,
        view: IntlKeys.transportUnloadingsShort,
      },
      [TransportsSerializedHeaders.transportId]: {
        serialized: TransportsSerializedHeaders.transportId,
        unserialized: TransportsUnserializedHeaders.transportId,
        view: IntlKeys.movementEventsTransportId,
      },
      [TransportsSerializedHeaders.transportReferenceId]: {
        serialized: TransportsSerializedHeaders.transportReferenceId,
        unserialized: TransportsUnserializedHeaders.transportReferenceId,
        view: IntlKeys.transportsReferenceId,
      },
      [TransportsSerializedHeaders.partyIdTypeCode]: {
        serialized: TransportsSerializedHeaders.partyIdTypeCode,
        unserialized: TransportsUnserializedHeaders.partyIdTypeCode,
        view: IntlKeys.transportsPartyIdTypeCode,
      },
      [TransportsSerializedHeaders.partyIdValue]: {
        serialized: TransportsSerializedHeaders.partyIdValue,
        unserialized: TransportsUnserializedHeaders.partyIdValue,
        view: IntlKeys.transportsPartyIdValue,
      },
      [TransportsSerializedHeaders.logisticServiceProviderName]: {
        serialized: TransportsSerializedHeaders.logisticServiceProviderName,
        unserialized: TransportsUnserializedHeaders.logisticServiceProviderName,
        view: IntlKeys.transportsLogisticServiceProviderName,
      },
      [TransportsSerializedHeaders.labels]: {
        serialized: TransportsSerializedHeaders.labels,
        unserialized: TransportsUnserializedHeaders.labels,
        view: IntlKeys.titleLabels,
      },
    },
  },
};

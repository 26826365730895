import { IntlKeys } from 'lib/localization/keys/__keys';

enum GasConsumptionRatesOriginal {
  GasConsumptionRates = 'CFP_NIBUD_gasverbruik',
}

enum GasConsumptionRatesSerialized {
  GasConsumptionRates = 'GasConsumptionRates',
}

enum GasConsumptionRatesUnserialized {
  GasConsumptionRates = 'GasConsumptionRates',
}

enum GasConsumptionRatesOriginalHeaders {
  'houseType' = 'houseType',
  'gasConsumption' = 'gasConsumption',
}

enum GasConsumptionRatesSerializedHeaders {
  'houseType' = 'houseType',
  'gasConsumption' = 'gasConsumption',
}
enum GasConsumptionRatesUnserializedHeaders {
  'houseType' = 'house_type',
  'gasConsumption' = 'gas_consumption',
}

export const GAS_CONSUMPTION_RATES_FIELDS = {
  [GasConsumptionRatesUnserialized.GasConsumptionRates]: {
    original: GasConsumptionRatesOriginal.GasConsumptionRates,
    serialized: GasConsumptionRatesSerialized.GasConsumptionRates,
    unserialized: GasConsumptionRatesUnserialized.GasConsumptionRates,
    view: IntlKeys.referenceTablesGasConsumptionRates,
    viewName: 'CFP_NIBUD_gasverbruik',

    columns: {
      [GasConsumptionRatesSerializedHeaders.houseType]: {
        original: GasConsumptionRatesOriginalHeaders.houseType,
        serialized: GasConsumptionRatesSerializedHeaders.houseType,
        unserialized: GasConsumptionRatesUnserializedHeaders.houseType,
        view: IntlKeys.referenceTablesGasConsumptionRatesHouseType,
      },
      [GasConsumptionRatesSerializedHeaders.gasConsumption]: {
        original: GasConsumptionRatesOriginalHeaders.gasConsumption,
        serialized: GasConsumptionRatesSerializedHeaders.gasConsumption,
        unserialized: GasConsumptionRatesUnserializedHeaders.gasConsumption,
        view: IntlKeys.referenceTablesGasConsumptionRatesGasConsumption,
      },
    },
  },
};

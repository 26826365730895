import { CFP_GFLI_FEEDPRINT_TABLE } from 'lib/excel/config/referenceTables/cfpGfliFeedprintTable/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = CFP_GFLI_FEEDPRINT_TABLE.CfpGfliFeedprintTable;

export const getcfpGfliFeedprintTableDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.feedPrintCode.original),
      new CellValidator(columns.cvbCode.original),
      new CellValidator(columns.source.original),
      new CellValidator(columns.productNameEn.original),
      new CellValidator(columns.productNameNl.original),
      new CellValidator(columns.productCodeEu.original),
      new CellValidator(columns.productNameEu.original),
      new CellValidator(columns.origin.original),
      new CellValidator(columns.climate.original),
    ]),
  ]);
};

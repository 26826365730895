import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';

export const transports = new Route('get', new RouteParser('/mock/transports'), () => {
  return [
    200,
    {
      resources: [
        {
          id: 5,
          transport_id: '606261',
          transport_reference_id: '508601',
          party_id_type_code: 'qui',
          party_id_value: '984866',
          logistic_service_provider_name: 'Aspernatur at sit adipisci.',
          created_at: '2021-02-14T12:50:35.295Z',
          updated_at: '2021-02-14T12:50:35.295Z',
          active_blockchain_record: {
            stored_in_blockchain_at: '2021-02-14T12:50:35.307Z',
            hashed_data:
              '93e17822b5398d6adcb093b0314f88cc21b1e32b767582c6e1eee737e346f5fe5cf98c4256b0c19780f3d26d6c092394444d5b254fe64ee7babed51a64f5aa84',
            hash_type: 'sha512',
            properties: {
              has_many: {
                transport_unloadings: {
                  order_by: {
                    location_id_value: 'asc',
                  },
                  properties: [],
                },
              },
              properties: [
                'transport_id',
                'transport_reference_id',
                'party_id_type_code',
                'party_id_value',
                'logistic_service_provider_name',
                'transport_unloadings',
              ],
            },
            blockchain_id: null,
          },
        },
        {
          id: 4,
          transport_id: '433410',
          transport_reference_id: '775557',
          party_id_type_code: 'officia',
          party_id_value: '969324',
          logistic_service_provider_name: 'Minus quam dolores id.',
          created_at: '2021-02-14T12:50:35.262Z',
          updated_at: '2021-02-14T12:50:35.262Z',
          active_blockchain_record: {
            stored_in_blockchain_at: '2021-02-14T12:50:35.274Z',
            hashed_data:
              'a5e5ce8b6ae40f16e2a10bcadf7217fa04949589cf382cf4fd21f398b434620f726728502bf8e1951ace3461daeaa23f767f02a50dd927a5e5624ab6eb3c5b04',
            hash_type: 'sha512',
            properties: {
              has_many: {
                transport_unloadings: {
                  order_by: {
                    location_id_value: 'asc',
                  },
                  properties: [],
                },
              },
              properties: [
                'transport_id',
                'transport_reference_id',
                'party_id_type_code',
                'party_id_value',
                'logistic_service_provider_name',
                'transport_unloadings',
              ],
            },
            blockchain_id: null,
          },
        },
        {
          id: 3,
          transport_id: '332787',
          transport_reference_id: '578419',
          party_id_type_code: 'dolorem',
          party_id_value: '933233',
          logistic_service_provider_name: 'Adipisci eum voluptatem ipsum.',
          created_at: '2021-02-14T12:50:35.233Z',
          updated_at: '2021-02-14T12:50:35.233Z',
          active_blockchain_record: {
            stored_in_blockchain_at: '2021-02-14T12:50:35.243Z',
            hashed_data:
              '28b00e47f4821456262fca13bf252b984d4f14187ccd4496fd0b9242cae0feaa41677e92b061f58e3e018a35f15a129e73ee29eec9af0521bedb51460c8a2546',
            hash_type: 'sha512',
            properties: {
              has_many: {
                transport_unloadings: {
                  order_by: {
                    location_id_value: 'asc',
                  },
                  properties: [],
                },
              },
              properties: [
                'transport_id',
                'transport_reference_id',
                'party_id_type_code',
                'party_id_value',
                'logistic_service_provider_name',
                'transport_unloadings',
              ],
            },
            blockchain_id: null,
          },
        },
        {
          id: 2,
          transport_id: '533949',
          transport_reference_id: '847908',
          party_id_type_code: 'quia',
          party_id_value: '803416',
          logistic_service_provider_name: 'Expedita voluptatem animi eum.',
          created_at: '2021-02-14T12:50:35.206Z',
          updated_at: '2021-02-14T12:50:35.206Z',
          active_blockchain_record: {
            stored_in_blockchain_at: '2021-02-14T12:50:35.215Z',
            hashed_data:
              '01d6101c6cd9f69951b10a02e6d7448a1060a6d59eb42cb8dba535f28fa24007867b66ddb2d4a3c43233d71585d35013c6fa3ce4635463f71106122093e082a7',
            hash_type: 'sha512',
            properties: {
              has_many: {
                transport_unloadings: {
                  order_by: {
                    location_id_value: 'asc',
                  },
                  properties: [],
                },
              },
              properties: [
                'transport_id',
                'transport_reference_id',
                'party_id_type_code',
                'party_id_value',
                'logistic_service_provider_name',
                'transport_unloadings',
              ],
            },
            blockchain_id: null,
          },
        },
        {
          id: 1,
          transport_id: '482126',
          transport_reference_id: '233971',
          party_id_type_code: 'voluptatem',
          party_id_value: '661542',
          logistic_service_provider_name: 'Dignissimos facere odio doloremque.',
          created_at: '2021-02-14T12:50:35.056Z',
          updated_at: '2021-02-14T12:50:35.056Z',
          active_blockchain_record: {
            stored_in_blockchain_at: '2021-02-14T12:50:35.096Z',
            hashed_data:
              'b2534b9bb1c18f70f00a2e7cc0bc5ed670a8a72b1afcb929bc6217a98af3cdad5c6eb6c5bcd68a9bd4cc2f0aa261509dcaeef3217ceafca4a05cdf0cfbbc5bab',
            hash_type: 'sha512',
            properties: {
              has_many: {
                transport_unloadings: {
                  order_by: {
                    location_id_value: 'asc',
                  },
                  properties: [],
                },
              },
              properties: [
                'transport_id',
                'transport_reference_id',
                'party_id_type_code',
                'party_id_value',
                'logistic_service_provider_name',
                'transport_unloadings',
              ],
            },
            blockchain_id: null,
          },
        },
      ],
    },
  ];
});

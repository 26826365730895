import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';
import { serializeObjectSnakeCaseToCamelCase } from 'lib/excel/serilizers/utils';

import { REQUEST_LOG_USERS } from './config';
import { UserPassportPayload, UserPassportSerialized } from '../../userPassports';

export interface RequestCountByApplicationSerialized {
  carbonDioxideFootprints: number;
  organizations: number;
  participations: number;
  animalPassports: number;
  calculatedReports: number;
  reportUploads: number;
  companyReports: number;
  myFarms: number;
  joinDataLink: number;
}

export interface RequestCountByApplicationUnserialized {
  carbon_dioxide_footprints: number;
  organizations: number;
  participations: number;
  animal_passports: number;
  calculated_reports: number;
  report_uploads: number;
  company_reports: number;
  my_farms: number;
  join_data_link: number;
}

export type RequestLogUsersPayload = {
  full_name: string;
  email: string;
  last_login: string | null;
  pref_lang: string;
  request_count: number;
  user_passports: UserPassportPayload;
  request_count_by_application: RequestCountByApplicationUnserialized;
};

export type RequestLogUsersSerialized = {
  fullName: string;
  email: string;
  lastLogin: string | null;
  prefLang: string;
  requestCount: number;
  userPassports: UserPassportSerialized;
  requestCountByApplication: RequestCountByApplicationSerialized;
};

const { columns, serialized, unserialized } = REQUEST_LOG_USERS.RequestLogUsers;

const getDefaultSerializer = (data: DocumentData<RequestLogUsersPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.fullName.unserialized, columns.fullName.serialized),
      new CellSerializer(columns.email.unserialized, columns.email.serialized),
      new CellSerializer(columns.lastLogin.unserialized, columns.lastLogin.serialized),
      new CellSerializer(columns.prefLang.unserialized, columns.prefLang.serialized),
      new CellSerializer(columns.requestCount.unserialized, columns.requestCount.serialized),
      new CellSerializer(columns.userPassports.unserialized, columns.userPassports.serialized),
      new CellSerializer(
        columns.requestCountByApplication.unserialized,
        columns.requestCountByApplication.serialized,
        serializeObjectSnakeCaseToCamelCase,
      ),
    ]),
  ]);
};

export const serializeRequestLogUsers = (serverData: ResourcesWrapper<RequestLogUsersPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper } from 'lib/http/utils';

import { MANAGE_UPLOADS, MANAGE_UPLOADS_SUBMIT } from './config';

export type ManageUploadPayload = {
  id: number;
  status: 'active' | 'inactive';
  upload_type: string;
  file_name: string;
  valid_from: string | null;
  valid_to: string | null;
  notes: string;
  uploaded_at: string;
  uploaded_by: {
    full_name: string;
  };
};

export type ManageUploadSerialized = {
  id: number;
  status: 'active' | 'inactive' | boolean;
  uploadType: string;
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

export type ManageUploadSubmitPayload = Omit<ManageUploadPayload, 'id' | 'uploadedAt' | 'uploadedBy' | 'uploadType'>;
export type ManageUploadSubmitSerialized = Omit<
  ManageUploadSerialized,
  'id' | 'uploadedAt' | 'uploadedBy' | 'uploadType' | 'filename'
>;

const { columns: defaultColumns } = MANAGE_UPLOADS.ManageUploads;
const { columns: submitColumns } = MANAGE_UPLOADS_SUBMIT.ManageUploadsSubmit;

const getDefaultSerializer = (data: DocumentData<ManageUploadPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(MANAGE_UPLOADS.ManageUploads.unserialized, MANAGE_UPLOADS.ManageUploads.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.status.unserialized, defaultColumns.status.serialized),
      new CellSerializer(defaultColumns.uploadType.unserialized, defaultColumns.uploadType.serialized),
      new CellSerializer(defaultColumns.filename.unserialized, defaultColumns.filename.serialized),
      new CellSerializer(defaultColumns.validDateFrom.unserialized, defaultColumns.validDateFrom.serialized),
      new CellSerializer(defaultColumns.validDateTo.unserialized, defaultColumns.validDateTo.serialized),
      new CellSerializer(defaultColumns.notes.unserialized, defaultColumns.notes.serialized),
      new CellSerializer(defaultColumns.uploadedAt.unserialized, defaultColumns.uploadedAt.serialized),
      new CellSerializer(defaultColumns.uploadedBy.unserialized, defaultColumns.uploadedBy.serialized),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<ManageUploadSubmitSerialized[]>) => {
  return new DocumentSerializer<ManageUploadSubmitPayload, ManageUploadSubmitSerialized>(data, [
    new SheetSerializer(
      MANAGE_UPLOADS_SUBMIT.ManageUploadsSubmit.serialized,
      MANAGE_UPLOADS_SUBMIT.ManageUploadsSubmit.unserialized,
      [
        new CellSerializer(submitColumns.status.unserialized, submitColumns.status.serialized),
        new CellSerializer(
          submitColumns.validDateFrom.unserialized,
          submitColumns.validDateFrom.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.validDateTo.unserialized,
          submitColumns.validDateTo.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          submitColumns.notes.unserialized,
          submitColumns.notes.serialized,
          undefined,
          undefined,
          null,
        ),
      ],
    ),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<ManageUploadSerialized[]>) => {
  return new DocumentSerializer<ManageUploadSerialized, ManageUploadSubmitSerialized>(data, [
    new SheetSerializer(
      MANAGE_UPLOADS_SUBMIT.ManageUploadsSubmit.serialized,
      MANAGE_UPLOADS_SUBMIT.ManageUploadsSubmit.unserialized,
      [
        new CellSerializer(defaultColumns.status.serialized, submitColumns.status.serialized),
        new CellSerializer(
          defaultColumns.validDateFrom.serialized,
          submitColumns.validDateFrom.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          defaultColumns.validDateTo.serialized,
          submitColumns.validDateTo.serialized,
          undefined,
          undefined,
          null,
        ),
        new CellSerializer(
          defaultColumns.notes.serialized,
          submitColumns.notes.serialized,
          undefined,
          undefined,
          undefined,
        ),
      ],
    ),
  ]);
};

export const serializeManageUploads = (serverData: ResourcesWrapper<ManageUploadPayload>) => {
  const { unserialized } = MANAGE_UPLOADS.ManageUploads;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const unserializeManageUploadForSubmit = (resource: ManageUploadSubmitSerialized): ManageUploadSubmitPayload => {
  const { serialized, unserialized } = MANAGE_UPLOADS_SUBMIT.ManageUploadsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeManageUploadStoSS = (resource: ManageUploadSerialized) => {
  const { serialized, unserialized } = MANAGE_UPLOADS_SUBMIT.ManageUploadsSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

import { Route } from '__mocks__/Route';
import RouteParser from 'route-parser';

export const AdminUsers = new Route('get', new RouteParser('/mock/admin/users'), (parsedParams) => [
  200,
  {
    resources: [
      {
        company_id: null,
        created_at: '2020-12-17T14:12:28.432Z',
        full_name: 'TEST FARMER',
        id: 96,
        is_contact_person: false,
        last_sign_in_at: '2020-12-21T12:46:19.008Z',
        name: 'test farmer',
        organization_name: 'Mug test, F.P. Corp Intl',
        organizations: [{ id: 2, kvk: '123456789', kvk_company_name: 'Mug test', email: 'test@gmail.com' }],
        pref_lang: 'nl',
        provider: 'email',
        sign_in_count: 6,
        status: 'active',
        uid: 'test.farmer@test.test',
        updated_at: '2021-01-14T13:17:16.128Z',
        user_role: 'simple_user',
        user_type: 'farmer',
      },
    ],
  },
]);

import React from 'react';
import { Link } from 'react-router-dom';
import MaterialIconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { getUrl } from 'routes';

const DashBoardButton = () => {
  return (
    <MaterialIconButton
      aria-label="dashboard"
      color="inherit"
      edge="end"
      component={Link}
      to={getUrl()}
      data-testid="go-to-dashboard"
    >
      <DashboardIcon style={{ color: 'white' }} />
    </MaterialIconButton>
  );
};

export default React.memo(DashBoardButton);

import React from 'react';
import { Backdrop, CircularProgress, CircularProgressProps } from '@material-ui/core';
import cx from 'clsx';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface OwnProps {
  filled?: boolean;
  absolute?: boolean;
  relative?: boolean;
  size?: CircularProgressProps['size'];
  nobg?: boolean;
  className?: string;
}

export const ScreenLoader: React.FC<OwnProps> = ({
  //
  filled = true,
  absolute = false,
  relative = false,
  size,
  nobg = false,
  className,
}) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={cx(classes.backdrop, className, {
        [classes.filled]: filled,
        [classes.absolute]: absolute,
        [classes.relative]: relative,
        [classes.nobg]: nobg,
      })}
      open={true}
      timeout={0}
    >
      <CircularProgress color="primary" size={size} />
    </Backdrop>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    filled: {
      backgroundColor: '#dcdcdc',
    },
    absolute: {
      position: 'absolute',
    },
    relative: {
      position: 'relative',
    },
    nobg: {
      backgroundColor: 'transparent !important',
    },
  }),
);

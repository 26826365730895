import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const invoiceMessages = new Route('get', new RouteParser('/invoice_messages'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 3,
          message_id: '39814561',
          currency_code: 'New Taiwan Dollar',
          overview_type: 'tempore',
          invoice_number: '8542123243',
          invoice_date: '2019-10-28',
          unique_message_number: '514784280',
          supplier_name: 'Voluptatem modi qui.',
          supplier_postal_address_street: 'Skiles Glens',
          supplier_postal_address_house_number: '960',
          supplier_postal_address_place: 'North Neal',
          supplier_postal_address_postcode: '94555',
          supplier_ean_address_code: '82544-1167',
          supplier_bank_account_number: '8212404209',
          supplier_iban: 'NL66BAIW6596498958',
          supplier_international_vat_number: 'NL59WLFG3664361472',
          customer_name: 'Numquam rerum provident.',
          customer_street_address: 'Jewel Glen',
          customer_postal_address_place: 'Rossanaburgh',
          customer_relation_number: '87028',
          deliveries: [
            {
              id: 1,
              invoice: {
                id: 3,
                message_id: '39814561',
              },
              line_number: '76',
              delivery_date: '2020-04-03',
              delivery_note_number: '45125011',
              delivery_note_line_number: '72',
              product_quantity: '89076',
              product_article_code: '275946378179830043',
              product_description: 'Commodi soluta sunt laudantium.',
              product_article_group_code: '65',
              product_article_group_code_description: 'Eum ea incidunt corporis.',
            },
          ],
          ...generateBlockchainRecord(),
        },
        {
          id: 3,
          message_id: '39814561',
          currency_code: 'New Taiwan Dollar',
          overview_type: 'tempore',
          invoice_number: '8542123243',
          invoice_date: '2019-11-25',
          unique_message_number: '514784280',
          supplier_name: 'Voluptatem modi qui.',
          supplier_postal_address_street: 'Skiles Glens',
          supplier_postal_address_house_number: '960',
          supplier_postal_address_place: 'North Neal',
          supplier_postal_address_postcode: '94555',
          supplier_ean_address_code: '82544-1167',
          supplier_bank_account_number: '8212404209',
          supplier_iban: 'NL66BAIW6596498958',
          supplier_international_vat_number: 'NL59WLFG3664361472',
          customer_name: 'Numquam rerum provident.',
          customer_street_address: 'Jewel Glen',
          customer_postal_address_place: 'Rossanaburgh',
          customer_relation_number: '87028',
          deliveries: [
            {
              id: 1,
              invoice: {
                id: 3,
                message_id: '39814561',
              },
              line_number: '76',
              delivery_date: '2020-04-03',
              delivery_note_number: '45125011',
              delivery_note_line_number: '72',
              product_quantity: '89076',
              product_article_code: '275946378179830043',
              product_description: 'Commodi soluta sunt laudantium.',
              product_article_group_code: '65',
              product_article_group_code_description: 'Eum ea incidunt corporis.',
            },
          ],
          ...generateBlockchainRecord(),
        },
        {
          id: 3,
          message_id: '39814561',
          currency_code: 'New Taiwan Dollar',
          overview_type: 'tempore',
          invoice_number: '8542123243',
          invoice_date: '2019-11-11',
          unique_message_number: '514784280',
          supplier_name: 'Voluptatem modi qui.',
          supplier_postal_address_street: 'Skiles Glens',
          supplier_postal_address_house_number: '960',
          supplier_postal_address_place: 'North Neal',
          supplier_postal_address_postcode: '94555',
          supplier_ean_address_code: '82544-1167',
          supplier_bank_account_number: '8212404209',
          supplier_iban: 'NL66BAIW6596498958',
          supplier_international_vat_number: 'NL59WLFG3664361472',
          customer_name: 'Numquam rerum provident.',
          customer_street_address: 'Jewel Glen',
          customer_postal_address_place: 'Rossanaburgh',
          customer_relation_number: '87028',
          deliveries: [
            {
              id: 1,
              invoice: {
                id: 3,
                message_id: '39814561',
              },
              line_number: '76',
              delivery_date: '2020-04-03',
              delivery_note_number: '45125011',
              delivery_note_line_number: '72',
              product_quantity: '89076',
              product_article_code: '275946378179830043',
              product_description: 'Commodi soluta sunt laudantium.',
              product_article_group_code: '65',
              product_article_group_code_description: 'Eum ea incidunt corporis.',
            },
          ],
          ...generateBlockchainRecord(),
        },
        {
          id: 3,
          message_id: '39814561',
          currency_code: 'New Taiwan Dollar',
          overview_type: 'tempore',
          invoice_number: '8542123243',
          invoice_date: '2019-07-12',
          unique_message_number: '514784280',
          supplier_name: 'Voluptatem modi qui.',
          supplier_postal_address_street: 'Skiles Glens',
          supplier_postal_address_house_number: '960',
          supplier_postal_address_place: 'North Neal',
          supplier_postal_address_postcode: '94555',
          supplier_ean_address_code: '82544-1167',
          supplier_bank_account_number: '8212404209',
          supplier_iban: 'NL66BAIW6596498958',
          supplier_international_vat_number: 'NL59WLFG3664361472',
          customer_name: 'Numquam rerum provident.',
          customer_street_address: 'Jewel Glen',
          customer_postal_address_place: 'Rossanaburgh',
          customer_relation_number: '87028',
          deliveries: [
            {
              id: 1,
              invoice: {
                id: 3,
                message_id: '39814561',
              },
              line_number: '76',
              delivery_date: '2020-04-03',
              delivery_note_number: '45125011',
              delivery_note_line_number: '72',
              product_quantity: '89076',
              product_article_code: '275946378179830043',
              product_description: 'Commodi soluta sunt laudantium.',
              product_article_group_code: '65',
              product_article_group_code_description: 'Eum ea incidunt corporis.',
            },
          ],
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

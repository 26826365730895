import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper } from 'lib/http/utils';
import { UPDATES_LOG } from 'lib/tables/config/farmerInput/updatesLog/config';
import { ReportTypes } from 'lib/tables/config/farmerInput/updatesLog/utils';

export type UpdatesLogPayload = {
  id: number;
  loggable_id: number;
  event_data: {
    id_type: string;
    user_passport_id: number;
    loggable_id: number;
    loggable_type: string;
    id_value: string;
  };
  event_type: keyof typeof ReportTypes;
  done_at: string;
  done_by: {
    full_name: string;
  };
};

export type UpdatesLogSerialized = {
  id: number;
  reportId: number;
  reportType: keyof typeof ReportTypes;
  idType: string;
  idCode: number;
  action: string;
  actionDate: string;
  triggeredBy: string;
  loggableId: number;
};

const { columns, serialized, unserialized } = UPDATES_LOG.UpdatesLog;

const getDefaultSerializer = (data: DocumentData<UpdatesLogPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(columns.reportId.unserialized, columns.reportId.serialized),
      new CellSerializer(columns.reportType.unserialized, columns.reportType.serialized),
      new CellSerializer(columns.idType.unserialized, columns.idType.serialized),
      new CellSerializer(columns.idCode.unserialized, columns.idCode.serialized),
      new CellSerializer(columns.action.unserialized, columns.action.serialized),
      new CellSerializer(columns.actionDate.unserialized, columns.actionDate.serialized),
      new CellSerializer(columns.triggeredBy.unserialized, columns.triggeredBy.serialized),
      new CellSerializer(columns.loggableId.unserialized, columns.loggableId.serialized),
    ]),
  ]);
};

export const serializeFarmerInputUpdatesLog = (serverData: ResourcesWrapper<UpdatesLogPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

/**
    TODO all those keys separated in different files are becoming messy
      gather into 1 file matching lines with dictionaries



































 */
export enum IntlKeys {
  // common
  ok = 'common.ok',
  messageId = 'common.messageId',
  fromTo = 'common.fromTo',
  dateRange = 'common.dateRange',
  date = 'common.date',
  of = 'common.of',
  pig = 'common.pig',
  pigs = 'common.pigs',
  hideDetails = 'common.hideDetails',
  moreDetails = 'common.moreDetails',
  cancel = 'common.cancel',
  save = 'common.save',
  apply = 'common.apply',
  accept = 'common.accept',
  more = 'common.more',
  show = 'common.show',
  close = 'common.close',
  back = 'common.back',
  return = 'common.return',
  submit = 'common.submit',
  approve = 'common.approve',
  reject = 'common.reject',
  delete = 'common.delete',
  download = 'common.download',
  request = 'common.request',
  synchronize = 'common.synchronize',
  success = 'common.success',
  error = 'common.error',
  status = 'common.status',
  statusUpdatedBy = 'common.statusUpdatedBy',
  statusUpdatedAt = 'common.statusUpdatedAt',
  deleted = 'common.deleted',
  genericSuccessMsg = 'common.genericSuccessMsg',
  genericErrorMsg = 'common.genericErrorMsg',
  continueEditing = 'common.continueEditing',
  leaveWOSaving = 'common.leaveWOSaving',
  leavePage = 'common.leavePage',
  areYouSure = 'common.areYouSure',
  unsavedChanges = 'common.unsavedChanges',
  unsavedDataWarning = 'common.unsavedDataWarning',
  dataSaved = 'common.dataSaved',
  lastModified = 'common.lastModified',
  lastModifiedBy = 'common.lastModifiedBy',
  maximumCapacity = 'common.maximumCapacity',
  selectLocation = 'common.selectLocation',
  selectYourLocation = 'common.selectYourLocation',
  selectUserPassport = 'common.selectUserPassport',
  id = 'common.id',
  name = 'common.name',
  email = 'common.email',
  kvk = 'common.kvk',
  ubn = 'common.ubn',
  kvkCommon = 'kvk',
  ubnCommon = 'ubn',
  orgKvkCommon = 'orgKvk',
  ubns = 'common.ubns',
  gln = 'common.gln',
  btw = 'common.btw',
  certificates = 'common.certificates',
  notes = 'common.notes',
  scheme = 'common.scheme',
  title = 'common.title',
  filter = 'common.filter',
  yes = 'common.yes',
  no = 'common.no',
  created = 'common.created',
  requested = 'common.requested',
  valid = 'common.valid',
  updated = 'common.updated',
  approved = 'common.approved',
  invoice = 'common.invoice',
  message = 'common.message',
  importantMessage = 'common.importantMessage',
  receipt = 'common.receipt',
  event = 'common.event',
  delivery = 'common.delivery',
  export = 'common.export',
  startDate = 'common.startDate',
  endDate = 'common.endDate',
  duration = 'common.duration',
  admin = 'common.admin',
  new = 'common.new',
  existing = 'common.existing',
  application = 'common.application',
  vionFarming = 'common.vionFarming',
  slaughter = 'common.slaughter',
  messages = 'common.messages',
  info = 'common.info',
  managementInfo = 'common.managementInfo',
  customKvkName = 'common.customKvkName',
  newReport = 'common.newReport',
  manure = 'common.manure',
  fermentation = 'common.fermentation',
  byProducts = 'common.byProducts',
  companyData = 'common.companyData',
  zero = 'common.zero',
  one = 'common.one',
  two = 'common.two',
  three = 'common.three',
  four = 'common.four',
  five = 'common.five',
  duplicate = 'common.duplicate',
  saveAndSubmit = 'common.saveAndSubmit',
  saveAsDraft = 'common.saveAsDraft',
  revertToDraft = 'common.revertToDraft',
  removeRavCode = 'common.removeRavCode',
  addRavCode = 'common.addRavCode',
  addConcept = 'common.addConcept',
  templates = 'common.templates',
  description = 'common.description',
  viewEdit = 'common.viewEdit',
  update = 'common.update',
  deleteForever = 'common.deleteForever',
  newIdMapping = 'common.newIdMapping',
  newAlias = 'common.newAlias',
  validDate = 'common.validDate',
  uploadDate = 'common.uploadDate',
  uploadedBy = 'common.uploadedBy',
  type = 'common.type',
  primeUbn = 'common.primeUbn',
  ubnAliases = 'common.ubnAliases',
  uploadedAt = 'common.uploadedAt',
  downloadAliases = 'common.downloadAliases',
  continue = 'common.continue',
  remove = 'common.remove',
  keep = 'common.keep',
  createNew = 'common.createNew',
  or = 'common.or',
  labelName = 'common.labelName',
  assignedTo = 'common.assignedTo',
  create = 'common.create',
  vri = 'common.vri',
  epg = 'common.epg',
  healthBalance = 'common.healthBalance',
  balanceWrtBase = 'common.balanceWrtBase',
  vriTool = 'common.vriTool',
  vs = 'common.vs',
  score = 'common.score',
  selectApps = 'common.selectApps',
  requestDate = 'common.requestDate',
  concepts = 'common.concepts',
  concept = 'common.concept',
  companyAvatar = 'common.companyAvatar',
  isContactPerson = 'common.isContactPerson',
  contentAdmin = 'common.contentAdmin',
  userAdmin = 'common.userAdmin',
  monitoringAdmin = 'common.monitoringAdmin',
  simpleUser = 'common.simpleUser',
  fiveAndMore = 'common.fiveAndMore',
  lastUpdate = 'common.lastUpdate',
  quarter = 'common.quarter',
  halfYear = 'common.halfYear',
  year = 'common.year',
  timeRange = 'common.timeRange',
  lastUpdated = 'common.lastUpdated',
  colorTheme = 'common.colorTheme',
  quarters = 'common.quarters',
  halfYears = 'common.halfYears',
  years = 'common.years',
  here = 'common.here',
  view = 'common.view',
  companyEmail = 'common.companyEmail',
  statusSubmitted = 'status.submitted',
  statusDraft = 'status.draft',
  submitted = 'submitted',
  draft = 'draft',
  isRemovedSuccessfully = 'isRemovedSuccessfully',
  isCreatedSuccessfully = 'isCreatedSuccessfully',
  organization = 'organization',
  user = 'user',
  adminUserPassport = 'userPassport',
  alias = 'alias',
  permissionProfileTemplate = 'permissionProfileTemplate',
  samePassword = 'samePassword',
  passportStatus = 'passportStatus',

  // titles
  titleSolutionBySIM = 'title.solutionBySIM',
  titleSignIn = 'title.signIn',
  titleSignUp = 'title.signUp',
  titleSignOut = 'title.signOut',
  titlePassword = 'title.password',
  titleDashboard = 'title.dashboard',
  titleMap = 'title.map',
  titleCertificates = 'title.certificates',
  titleCertificatesDaily = 'title.certificatesDaily',
  titleCertificatesWeekly = 'title.certificatesWeekly',
  titleCertificatesDeviations = 'title.certificatesDeviations',
  titleCertificatesFarms = 'title.certificatesFarms',
  titleCertificatesRecognitions = 'title.certificatesRecognitions',
  titleCertificatesIKB = 'title.certificatesIKB',
  titleCertificatesWelfare = 'title.certificatesWelfare',
  titleCertificatesKiwa = 'title.certificatesKiwa',
  titleCertificatesKiwaDaily = 'title.certificatesKiwaDaily',
  titleCertificatesKiwaWeekly = 'title.certificatesKiwaWeekly',
  titleInvoices = 'title.invoices',
  titleInvoiceDetails = 'title.invoiceDetails',
  titleInvoiceMessages = 'title.invoiceMessages',
  titleSlaughter = 'title.slaughter',
  titleSlaughterMessages = 'title.slaughterMessages',
  titleSlaughterDetails = 'title.slaughterDetails',
  titleGenetics = 'title.genetics',
  titleDeliveryMessages = 'title.deliveryMessages',
  titleDeliverySegment = 'title.deliverySegment',
  titleDeliverySegmentContents = 'title.deliverySegmentContents',
  titleUserPassports = 'title.userPassports',
  titleMovementEvents = 'title.movementEvents',
  titleVeterinarianData = 'title.veterinarianData',
  titleUploadDocument = 'title.uploadDocument',
  titleFeedProvenanceValidityTool = 'title.feedProvenanceValidityTool',
  titleAdminFileUpload = 'title.adminFileUpload',
  titleDataCatalogue = 'title.dataCatalogue',
  titleVri = 'title.vri',
  titleVriCalculated = 'title.vriCalculated',
  titleVriEpg = 'title.vriEpg',
  titleVriHealthBalance = 'title.vriHealthBalance',
  titleVriBalanceBase = 'title.vriBalanceBase',
  titleVriReportingTool = 'title.vriReportingTool',
  titleVriCalculatedReportingTool = 'title.vriCalculatedReportingTool',
  titleVriDatabase = 'title.vriDatabase',
  titleVriDatabaseReports = 'title.vriDatabaseReports',
  titleMyFarm = 'title.myFarm',
  titleMyCompany = 'title.myCompany',
  titleFarmStables = 'title.farmStables',
  titleFarmReports = 'title.farmReports',
  titleFarmUpdates = 'title.farmUpdates',
  titleMyJoinData = 'title.myJoinData',
  titleHistory = 'title.history',
  titleAdmin = 'title.admin',
  titleAllOrganizations = 'title.allOrganizations',
  titleAllUsers = 'title.allUsers',
  titleUsers = 'title.users',
  titleCreateOrganization = 'title.createOrganization',
  titleUploads = 'title.uploads',
  titleUploadOrgsPassportsUsers = 'title.uploadOrgsPassportsUsers',
  titleUploadOrganization = 'title.uploadOrganization',
  titleUploadUserPassports = 'title.uploadUserPassports',
  titleUploadUsers = 'title.uploadUsers',
  titleAllRoles = 'title.allRoles',
  titleAdminMenu = 'title.adminMenu',
  titleAnimalPassport = 'title.animalPassport',
  titleFarmerInput = 'title.farmerInput',
  titleReportList = 'title.reportList',
  titleCompanyReportNew = 'title.companyReportNew',
  titleCompanyReportEdit = 'title.companyReportEdit',
  titleCompanyReportView = 'title.companyReportView',
  titleByProductsNew = 'title.byProductsNew',
  titleByProductsEdit = 'title.byProductsEdit',
  titleByProductsView = 'title.byProductsView',
  titleFermentationNew = 'title.fermentationNew',
  titleFermentationEdit = 'title.fermentationEdit',
  titleFermentationView = 'title.fermentationView',
  titleManureNew = 'title.manureNew',
  titleManureEdit = 'title.manureEdit',
  titleManureView = 'title.manureView',
  titleManageUploads = 'title.manageUploads',
  titleReferenceTables = 'title.referenceTables',
  titleUploadsList = 'title.uploadsList',
  titleManageUploadEdit = 'title.manageUploadEdit',
  titleFarmManagementData = 'title.farmManagementData',
  titleFarmManagement = 'title.farmManagement',
  titleGroupGroups = 'title.groupGroups',
  titleFtpUpdates = 'title.ftpUpdates',
  titleGroupFinancialEvents = 'title.groupFinancialEvents',
  titleGroupWeights = 'title.groupWeights',
  titleGroupRemovals = 'title.groupRemovals',
  titleGroupsArrival = 'title.groupsArrival',
  titleGroupMoves = 'title.groupMoves',
  titleCodesAnimalRemarks = 'title.animalRemarks',
  titleCodesDefects = 'title.codesDefects',
  titleCodesDiagnose = 'title.codesDiagnose',
  titleCodesAnimalScore = 'title.codesAnimalScore',
  titleCodesInsemination = 'title.codesInsemination',
  titleCodesTreatments = 'title.codesTreatments',
  titleGroupPurchasedFood = 'title.groupPurchasedFood',
  titleGroupUsedFood = 'title.groupUsedFood',
  titleCodesMatingType = 'title.codesMatingType',
  titleCodesWeightType = 'title.codesWeightType',
  titleCodesRemovalType = 'title.codesRemovalType',
  titleCodesAnimalIdentityType = 'title.codesAnimalIdentityType',
  titleCodesFarmOption = 'title.codesFarmOption',
  titleCodesBarn = 'title.codesBarn',
  titleCodesRoom = 'title.codesRoom',
  titleCodesPen = 'title.codesPen',
  titleFms = 'title.fms',
  titleFarms = 'title.farms',
  titleStablesGroups = 'title.stablesGroups',
  titleValidityCertificates = 'title.validityCertificates',
  titleReports = 'title.reports',
  titleAliases = 'title.aliases',
  titleCarbonFootprints = 'title.carbonFootprints',
  titleFarmsList = 'title.farmsList',
  titleUploadAliases = 'title.uploadAliases',
  titleMonitoringDashboard = 'title.monitoringDashboard',
  titleSuppliers = 'title.suppliers',
  titleData = 'title.data',
  titleLabels = 'title.labels',
  titleManageLabels = 'title.manageLabels',
  titleCreateLabel = 'title.createLabel',
  titleVriComparisonTool = 'title.vriComparisonTool',
  titleVriTotal = 'title.vriTotal',
  titleOrganization = 'title.organization',
  titleParticipation = 'title.participation',
  titleVisits = 'title.visits',
  titleCodesAccount = 'title.codesAccount',
  titleCodesEventTypes = 'title.codesEventTypes',
  titleGroupExpense = 'title.groupExpense',
  titleRawMaterialsOrigin = 'title.rawMaterialsOrigin',
  titleCarbonFootprintDetails = 'title.carbonFootprintDetails',
  titleHFMOutput = 'title.hfmOutput',
  titleVionGoodFarmingStar = 'title.vionGoodFarmingStar',
  titleVionGoodFarmingBalance = 'title.vionGoodFarmingBalance',
  titleOther = 'title.other',
  titleErrorsLog = 'title.errorsLog',
  titleBreeding = 'title.breeding',
  titleFlattening = 'title.flattening',
  titleClosed = 'title.closed',

  // UI

  uiBulkAction = 'ui.bulkAction',
  uiShowMenu = 'ui.showMenu',
  uiDisplayAs = 'ui.displayAs',
  uiList = 'ui.list',
  uiCards = 'ui.cards',
  uiFilterItems = 'ui.filterItems',
  uiAll = 'ui.all',
  uiToday = 'ui.today',
  uiThisWeek = 'ui.thisWeek',
  uiThisMonth = 'ui.thisMonth',
  uiDateRange = 'ui.dateRange',
  uiFrom = 'ui.from',
  uiTo = 'ui.to',
  uiResetFilters = 'ui.resetFilters',
  uiApplyFilters = 'ui.applyFilters',
  uiTableSettings = 'ui.tableSettings',
  uiApplySettings = 'ui.applySettings',
  uiResetToDefault = 'ui.resetToDefault',
  details = 'ui.details',
  uiProceed = 'ui.proceed',
  uiDiscard = 'ui.discard',
  sftpAccount = 'sftpAccount',
  fileName = 'fileName',
  //
  uiEdit = 'ui.edit',
  uiResetPassword = 'ui.resetPassword',
  uiSaveAndClose = 'ui.saveAndClose',
  uiCloseWOSaving = 'ui.closeWOSaveing',
  uiBack = 'ui.back',
  uiCreated = 'ui.created',
  uiUpdated = 'ui.updated',
  uiValid = 'ui.valid',
  uiNoOptions = 'ui.noOptions',
  uiNothingSelected = 'ui.nothingSelected',
  uiNothingFound = 'ui.nothingFound',
  uiOpenPassport = 'ui.openPassport',
  sync = 'sync',
  validate = 'validate',
  process = 'process',

  // admin

  adminUsersName = 'admin.users.name',
  adminUsersFullName = 'admin.users.fullName',
  adminUsersConcepts = 'admin.users.concepts',
  adminUsersCompanyId = 'admin.users.companyId',
  adminUsersOrganizations = 'admin.users.organizations',
  adminUsersOrganizationIds = 'admin.users.organizationIds',
  adminUsersUserPassportIds = 'admin.users.userPassportIds',
  adminUsersCompanies = 'admin.users.companies',
  adminUsersOrganizationName = 'admin.users.organizationName',
  adminUsersPrefLang = 'admin.users.prefLang',
  adminUsersUid = 'admin.users.uid',
  adminUsersProvider = 'admin.users.provider',
  adminUsersUserRole = 'admin.users.userRole',
  adminUsersUserTypeDescriptions = 'admin.users.userTypeDescriptions',
  adminUsersUserType = 'admin.users.userType',
  adminUsersUserPermissionProfile = 'admin.users.userPermissionProfile',
  adminUsersUserPermissionProfileTitle = 'title',
  adminUsersIsContactPerson = 'admin.users.isContactPerson',
  adminUsersStatus = 'admin.users.status',
  adminUsersSuspend = 'admin.users.suspend',
  adminUsersActivate = 'admin.users.activate',
  adminUsersDeleteUser = 'admin.users.deleteUser',
  adminUsersSaveAndNotify = 'admin.users.saveAndNotify',
  adminUsersSaveChanges = 'admin.users.saveChanges',
  adminUsersCloseWOSaving = 'admin.users.saveWOChanges',
  adminUsersCreateNewUser = 'admin.users.createNewUser',
  adminUsersCreateNewUserPassport = 'admin.users.createNewUserPassport',
  adminUsersSimpleUser = 'admin.users.simpleUser',
  adminUsersContentAdmin = 'admin.users.contentAdmin',
  adminUsersUserAdmin = 'admin.users.userAdmin',
  adminUsersNewUserPassport = 'admin.users.newUserPassport',
  adminUsersSuspended = 'admin.users.suspended',
  adminUsersActive = 'admin.users.active',
  adminUsersInvited = 'admin.users.invited',
  adminUsersFarmer = 'admin.users.farmer',
  adminUsersTransporter = 'admin.users.transporter',
  adminUsersVeterinarian = 'admin.users.veterinarian',
  adminUsersLastSignInAt = 'admin.users.lastSignInAt',
  adminUsersSignInCount = 'admin.users.signInCount',
  userProfileId = 'admin.users.userProfileId',
  userFullName = 'admin.users.userFullName',
  userEmail = 'admin.users.userEmail',
  doYouWantToSubmitPassport = 'doYouWantToSubmitPassport',

  // permissions

  adminPermissionProfileTemplate = 'admin.permissions.profileTemplate',
  adminPermissionRules = 'admin.permissions.rules',
  permissionAccessTo = 'permissions.accessTo',

  adminPermissionsGroupDataset = 'admin.permissions.group.dataset',
  adminPermissionsGroupApplications = 'admin.permissions.group.applications',
  adminPermissionsAlwaysOn = 'admin.permissions.alwaysOn',
  adminPermissionsAlwaysOff = 'admin.permissions.alwaysOff',
  adminPermissionsOrganizationTypePlaceholder = 'admin.permissions.organizationTypePlaceholder',
  adminPermissionsNotesPlaceholder = 'admin.permissions.NotesPlaceholder',

  adminPermissionsSourceNone = 'admin.permissions.source.none',
  adminPermissionsSourceFtp = 'admin.permissions.source.ftp',
  adminPermissionsSourceUpload = 'admin.permissions.source.upload',
  adminPermissionsSourceEdiCircle = 'admin.permissions.source.ediCircle',
  adminPermissionsSourceJoindata = 'admin.permissions.source.joindata',
  adminPermissionsSourceOrganizations = 'admin.permissions.source.organizations',

  adminPermissionsStatusAlwaysOn = 'admin.permissions.status.alwaysOn',
  adminPermissionsStatusAlwaysOff = 'admin.permissions.status.alwaysOff',
  adminPermissionsStatusAllowedFrom = 'admin.permissions.status.allowedFrom',
  adminPermissionsStatusAllowedTo = 'admin.permissions.status.allowedTo',
  adminPermissionsStatusAllowedFromTo = 'admin.permissions.status.allowedFromTo',

  adminCreatePermissionProfileTemplate = 'permissions.createProfileTemplate',
  adminEditPermissionProfileTemplate = 'permissions.editProfileTemplate',

  adminOrganizationsRegistrationName = 'admin.organizations.regisrationName',
  adminOrganizationsStreet = 'admin.organizations.street',
  adminOrganizationsEdiCircleCode = 'admin.organizations.ediCircleCode',
  adminOrganizationsPostcode = 'admin.organizations.postcode',
  adminOrganizationsPlace = 'admin.organizations.place',
  adminOrganizationsPhone = 'admin.organizations.phone',
  adminOrganizationsCertificationAuthority = 'admin.organizations.certificationAuthority',
  adminOrganizationsFeedSuppliers = 'admin.organizations.feedSuppliers',
  adminOrganizationsVisibilityRules = 'admin.organizations.visibilityRules',
  adminOrganizationsNewUser = 'admin.organizations.newUser',
  adminOrganizationsLastUpdate = 'admin.organizations.lastUpdate',
  adminOrganizationsBy = 'admin.organizations.by',
  adminOrganizationsOrganizationId = 'admin.organizations.organizationId',
  adminOrganizationsFeedSupplierOther = 'admin.organizations.feedSupplierOther',
  adminOrganizationsCertificates = 'admin.organizations.certificates',
  adminOrganizationsCertificatesOther = 'admin.organizations.certificatesOther',
  adminOrganizationsGeneticSuppliers = 'admin.organizations.geneticSuppliers',
  adminOrganizationsGeneticSuppliersOther = 'admin.organizations.geneticSuppliersOther',
  adminOrganizationsKiStation = 'admin.organizations.kiStation',
  adminOrganizationsKiStationOther = 'admin.organizations.kiStationOther',
  adminOrganizationsSowLines = 'admin.organizations.sowLines',
  adminOrganizationsSowLineOther = 'admin.organizations.sowLineOther',
  adminOrganizationsBreedingProgram = 'admin.organizations.breedingProgram',
  adminOrganizationsSireLines = 'admin.organizations.sireLines',
  adminOrganizationsSireLineOther = 'admin.organizations.sireLineOther',
  adminOrganizationsVeterinaryPractice = 'admin.organizations.veterinaryPractice',
  adminOrganizationsVeterinaryPracticeOther = 'admin.organizations.veterinaryPracticeOther',
  adminOrganizationsFmsNames = 'admin.organizations.fmsNames',
  adminOrganizationsFmsModuleAgrisysts = 'admin.organizations.fmsModuleAgrisysts',
  adminOrganizationsFmsModuleAgroCoops = 'admin.organizations.fmsModuleAgroCoops',
  adminOrganizationsFmsModuleAgrovisions = 'admin.organizations.fmsModuleAgrovisions',
  adminOrganizationsFeedingSystems = 'admin.organizations.feedingSystems',
  adminOrganizationsFeedingSystemOther = 'admin.organizations.feedingSystemOther',
  adminOrganizationsClimateSystems = 'admin.organizations.climateSystems',
  adminOrganizationsClimateSystemOther = 'admin.organizations.climateSystemOther',

  adminOrganizationMyUsers = 'admin.organization.myUsers',
  adminOrganizationOtherUsers = 'admin.organization.otherUsers',
  adminOrganizationOtherUsersDescription = 'admin.organization.otherUsersDescription',
  adminOrganizationAddExistingUsers = 'admin.organization.addExistingUsers',
  adminOrganizationGetInTouch = 'admin.organization.getInTouch',
  adminOrganizationGetInTouchDescription = 'admin.organization.getInTouchDescription',
  // prettier-ignore
  adminOrganizationDataSharingDescription = 'admin.organization.dataSharingDescription',
  adminOrganizationDataSharingButton = 'admin.organization.dataSharingButton',

  adminPermissionApplyToAll = 'admin.permission.applyToAll',
  adminUsersUserId = 'admin.organizations.userId',
  adminRolesRoleId = 'admin.organizations.roleID',
  userPassport = 'admin.passports.passport',
  userPassportId = 'admin.passports.passportId',
  userPassportUbnCustomName = 'admin.passports.ubnCustomName',
  userPassportSetUbnCustomName = 'admin.passports.setUbnCustomName',
  passportProfileId = 'admin.passports.passportProfileId',

  // animal passport

  animalPassportAverageWeightKg = 'animalPassport.averageWeightKg',
  animalPassportAnimalCategory = 'animalPassport.animalCategory',
  animalPassportDetails = 'animalPassport.details',
  animalPassportCustom = 'animalPassport.custom',

  animalPassportDataTabsFoodSafety = 'animalPassport.dataTabs.foodSafety',
  animalPassportDataTabsAnimalWelfare = 'animalPassport.dataTabs.animalWelfare',
  animalPassportDataTabsProductIntegrity = 'animalPassport.dataTabs.productIntegrity',
  animalPassportDataTabsSustainability = 'animalPassport.dataTabs.sustainability',

  animalPassportDataTabsCertification = 'animalPassport.dataTabs.certification',
  animalPassportDataTabsMedication = 'animalPassport.dataTabs.medication',
  animalPassportDataTabsFeed = 'animalPassport.dataTabs.feed',
  animalPassportDataTabsTransport = 'animalPassport.dataTabs.transport',
  animalPassportDataTabsFarm = 'animalPassportDataTabs.farm',
  animalPassportDataTabsSlaughter = 'animalPassportDataTabs.slaughter',
  animalPassportDataTabsGenetics = 'animalPassport.dataTabs.genetics',

  animalPassportCertificatesCertificate = 'animalPassport.certificates.certificate',
  animalPassportCertificatesStatus = 'animalPassport.certificates.status',
  animalPassportCertificatesValidUntil = 'animalPassport.certificates.validUntil',

  animalPassportFarmDeathCause = 'animalPassport.farm.deathCause',
  animalPassportFarmAnimalCount = 'animalPassport.farm.animalCount',
  animalPassportFarmAverageFeedType = 'animalPassport.farm.feedType',
  animalPassportFarmAverageAnimalWeight = 'animalPassport.farm.animalWeight',

  animalPassportFeedGmpType = 'animalPassport.feed.gmpType',
  animalPassportFeedItemQuantity = 'animalPassport.feed.itemQuantity',

  animalPassportGeneticsFemaleType = 'animalPassport.genetics.femaleType',
  animalPassportGeneticsFemaleAmount = 'animalPassport.genetics.femaleAmount',
  animalPassportGeneticsMaleType = 'animalPassport.genetics.maleType',
  animalPassportGeneticsMaleAmount = 'animalPassport.genetics.maleAmount',

  animalPassportMedicationAnimalLastExportDate = 'animalPassport.medication.lastExportDate',
  animalPassportMedicationAnimalPreviousExportDate = 'animalPassport.medication.previousExportDate',
  animalPassportMedicationAnimalLastDddTm = 'animalPassport.medication.LastDddTm',
  animalPassportMedicationAnimalPreviousDddTm = 'animalPassport.medication.previousDddTm',

  animalPassportSlaughterAnimalType = 'animalPassport.slaughter.animalType',
  animalPassportSlaughterAnimalCount = 'animalPassport.slaughter.animalCount',
  animalPassportSlaughterCarcassCodeTotal = 'animalPassport.slaughter.carcassCodeTotal',
  animalPassportSlaughterOrganCodeTotal = 'animalPassport.slaughter.organCodeTotal',

  animalPassportTransportNumberOfAnimals = 'animalPassport.transport.numberOfAnimals',

  animalTypeGiltOrCastratedBoar = 'animal.type.giltOrCastratedBoar',
  animalTypeBoar = 'animal.type.boar',

  animalCategorySowsAndBreedingGilts = 'animal.category.SowsAndBreedingGilts',
  animalCategoryBreedingBoars = 'animal.category.BreedingBoars',
  animalCategoryPiglets = 'animal.category.Piglets',
  animalCategorySlaughterPigs = 'animal.category.SlaughterPigs',
  animalCategorySlaughterSowsAndPiglets = 'animal.category.SlaughterSowsAndPiglets',

  carcassCodePleurisy = 'carcassCode.Pleurisy',
  carcassCodeInflamedSkin = 'carcassCode.InflamedSkin',
  carcassCodeInflamedLeg = 'carcassCode.InflamedLeg',

  organCodeLiverAffected = 'organCode.LiverAffected',
  organCodeLiverDisapproved = 'organCode.LiverDisapproved',
  organCodeLungDisapproved = 'organCode.LungDisapproved',

  dataNotToRate = 'data.NotToRate',
  dataNotClassified = 'data.notClassified',

  // dashboard

  dashboardFakeCategory = 'dashboard.fakeCategory',
  dashboardApplications = 'dashboard.applications',
  dashboardDataset = 'dashboard.dataset',
  dashboardVriHealthBalance = 'dashboard.vriHealthBalance',
  dashboardVriBalanceBase = 'dashboard.vriBalanceBase',
  dashboardDeliveryMessages = 'dashboard.deliveryMessages',
  dashboardMovementEvents = 'dashboard.movementEvents',

  dashboardTooltipAllowed = 'dashboard.tooltip.Allowed',
  dashboardTooltipFrom = 'dashboard.tooltip.from',
  dashboardTooltipTo = 'dashboard.tooltip.to',
  dashboardTooltipDataSources = 'dashboard.tooltip.MyDataUsedInProgram',
  dashboardTooltipDetailedDataUsageInfo = 'dashboard.tooltip.detailedDataUsageInfo',

  userPassportDraftAlert = 'userPassports.draft.alert',
  userPassportSubmittedAlert = 'userPassports.submitted.alert',
  userPassportRejectedAlert = 'userPassports.rejected.alert',

  // uploads

  uploadsUploadFile = 'uploads.uploadFile',
  uploadsDragNDrop = 'uploads.dragNDrop',
  uploadsDndToUpload = 'uploads.dndToUpload',
  uploadsDndOr = 'uploads.dndOr',
  uploadsDndSelectFile = 'uploads.dndSelectFile',
  uploadsDndSupportedFormats = 'uploads.dndSupportedFormats',
  uploadsDndSingleFile = 'uploads.dndSingleFile',
  uploadsDownloadTemplate = 'Download Template',
  uploadsDndSupportedFormatsCsv = 'uploads.dndSupportedFormatsCsv',
  discardFile = 'uploads.discardFile',
  yourFile = 'uploads.yourFile',

  uploadsUploadDaily = 'uploads.uploadDaily',
  uploadsUploadWeekly = 'uploads.uploadWeekly',
  uploadsUploadVriDatabase = 'uploads.uploadVriDatabase',
  uploadsUploadHistory = 'uploads.uploadHistory',

  uploadsPleaseStandBy = 'uploads.pleaseStandBy',
  uploadsCalculating = 'uploads.calculating',
  uploadsNSeconds = 'uploads.nSeconds',
  uploadsUploadSuccessful = 'uploads.uploadSuccessful',
  uploadsAlreadyUploaded = 'uploads.alreadyUploaded',
  uploadsAlreadyUploadedMsgConfirm = 'uploads.alreadyUploadedMsgConfirm',

  uploadsFileName = 'uploads.fileName',
  uploadsFileSize = 'uploads.fileSize',
  uploadsFileType = 'uploads.fileType',
  uploadsProcessingTime = 'uploads.processingTime',

  uploadsNSelected = 'uploads.nSelected',
  uploadsSortedAscending = 'uploads.sortedAscending',
  uploadsSortedDescending = 'uploads.sortedDescending',
  uploadsDensePadding = 'uploads.densePadding',
  uploadsFilterList = 'uploads.filterList',

  uploadsUploadReferenceTables = 'uploads.uploadReferenceTables',

  uploadsUploadCarbonFootprint = 'uploads.uploadCarbonFootprint',
  uploadsUploadHFMOutput = 'uploads.uploadHFMOutput',

  // certificates

  certificatesBlk = 'certificates.blk',
  certificatesPigsTomorrow = 'certificates.pigsTomorrow',
  certificatesIkb = 'certificates.ikb',
  certificatesVionWelfare = 'certificates.vionWelfare',
  certificatesActive = 'certificates.active',
  certificatesAboutToExpire = 'certificates.aboutToExpire',
  certificatesExpired = 'certificates.expired',

  // certificates IKB

  certificateIKBUbn = 'certificates.IKB.ubn',
  certificateIKBValidFrom = 'certificates.IKB.validFrom',
  certificateIKBValidTo = 'certificates.IKB.validTo',
  certificateIKBType = 'certificates.IKB.type',

  // certificates kiwa daily

  certificatesKiwaDailyRegNumber = 'certificates.kiwaDaily.regNumber',
  certificatesKiwaDailyCountryCodeLoc = 'certificates.kiwaDaily.countryCodeLoc',
  certificatesKiwaDailyBusinessType = 'certificates.kiwaDaily.businessType',
  certificatesKiwaDailyOwnTransport = 'certificates.kiwaDaily.ownTransport',
  certificatesKiwaDailyValidUntil = 'certificates.kiwaDaily.validUntil',
  certificatesKiwaDailyRemark = 'certificates.kiwaDaily.remark',
  certificatesKiwaDailyVC = 'certificates.kiwaDaily.vC',
  certificatesKiwaDailyQSNumber = 'certificates.kiwaDaily.qSNumber',
  certificatesKiwaDailyGGNr = 'certificates.kiwaDaily.gGNr',
  certificatesKiwaDailyPigTomorrow = 'certificates.kiwaDaily.pigTomorrow',
  certificatesKiwaDailyBLK = 'certificates.kiwaDaily.bLK',
  certificatesKiwaDailyCertificateComment = 'certificates.kiwaDaily.certificateComment',
  certificatesKiwaDailySalmonellaInfo = 'certificates.kiwaDaily.salmonellaInfo',

  //certificates kiwa weekly farms

  certificatesKiwaWeeklyFarmsUBN = 'certificates.kiwaWeekly.farms.ubn',
  certificatesKiwaWeeklyFarmsName = 'certificates.kiwaWeekly.farms.name',
  certificatesKiwaWeeklyFarmsAddress = 'certificates.kiwaWeekly.farms.address',
  certificatesKiwaWeeklyFarmsPostalCode = 'certificates.kiwaWeekly.farms.postalCode',
  certificatesKiwaWeeklyFarmsPlace = 'certificates.kiwaWeekly.farms.place',
  certificatesKiwaWeeklyFarmsPhone = 'certificates.kiwaWeekly.farms.phone',
  certificatesKiwaWeeklyFarmsDate = 'certificates.kiwaWeekly.farms.date',
  certificatesKiwaWeeklyFarmsSows = 'certificates.kiwaWeekly.farms.sows',
  certificatesKiwaWeeklyFarmsVlv = 'certificates.kiwaWeekly.farms.vlv',
  certificatesKiwaWeeklyFarmsProgram = 'certificates.kiwaWeekly.farms.program',
  certificatesKiwaWeeklyFarmsRemark = 'certificates.kiwaWeekly.farms.remark',
  certificatesKiwaWeeklyFarmsType = 'certificates.kiwaWeekly.farms.type',

  // certificates kiwa weekly recognitions

  certificatesKiwaWeeklyRecognitionsUBN = 'certificates.kiwaWeekly.recognitions.ubn',
  certificatesKiwaWeeklyRecognitionsDate = 'certificates.kiwaWeekly.recognitions.date',
  certificatesKiwaWeeklyRecognitionsProgram = 'certificates.kiwaWeekly.recognitions.program',
  certificatesKiwaWeeklyRecognitionsCode = 'certificates.kiwaWeekly.recognitions.code',
  certificatesKiwaWeeklyRecognitionsRegnr = 'certificates.kiwaWeekly.recognitions.regnr',
  certificatesKiwaWeeklyRecognitionsName = 'certificates.kiwaWeekly.recognitions.name',

  // certificates kiwa weekly deviations

  certificatesKiwaWeeklyDeviationsUBN = 'certificates.kiwaWeekly.deviations.ubn',
  certificatesKiwaWeeklyDeviationsProgram = 'certificates.kiwaWeekly.deviations.program',
  certificatesKiwaWeeklyDeviationsDate = 'certificates.kiwaWeekly.deviations.date',
  certificatesKiwaWeeklyDeviationsQuestionCode = 'certificates.kiwaWeekly.deviations.questioncode',
  certificatesKiwaWeeklyDeviationsRemark = 'certificates.kiwaWeekly.deviations.remark',
  certificatesKiwaWeeklyDeviationsAnswer = 'certificates.kiwaWeekly.deviations.answer',

  // certificates welfare

  certificatesWelfareUbn = 'certificates.welfare.ubn',
  certificatesWelfareValidFrom = 'certificates.welfare.validFrom',
  certificatesWelfareValidTo = 'certificates.welfare.validTo',
  certificatesWelfareType = 'certificates.welfare.type',

  // VRI

  vriVriRanking = 'vri.vriRanking',
  vriGoToVriReportingTool = 'vri.goToVriReportingTool',
  vriVriTotalRanking = 'vri.vriTotalRanking',
  vriSerialNumber = 'vri.serialNumber',
  vriYourScoreLabel = 'vri.yourScoreLabel',
  vriCentsPerKilogram = 'vri.centsPerKilogram',
  vriPerPig = 'vri.perPig',
  vriVriEpgTitle = 'vri.vriEpgTitle',
  vriVriHealthBalanceTitle = 'vri.vriHealthBalanceTitle',
  vriVriBalanceBaseTitle = 'vri.vriBalanceBaseTitle',
  vriChartUbn = 'vri.chartUbn',
  vriChartName = 'vri.chartName',
  vriChartTotal = 'vri.chartTotal',
  vriChartEpg = 'vri.chartEpg',
  vriChartClassification = 'vri.chartClassification',
  vriChartHealth = 'vri.chartHealth',
  vriTop20Label = 'vri.top20Label',
  vriAvgLabel = 'vri.avgLabel',
  vriAverageLabel = 'vri.averageLabel',
  vriPigsCapacity = 'vri.pigsCapacity',
  vriDifferenceLabel = 'vri.differenceLabel',
  vriTotalCompany = 'vri.totalCompany',
  vriDatabase = 'vri.database',
  vriDatabaseDetails = 'vri.databaseDetails',
  vriUploadedFile = 'vri.uploadedFile',
  datapoint = 'vri.datapoint',

  // VRI Balance Base

  vriBalanceBaseAverageWeightCorrection = 'vri.balanceBaseAverageWeightCorrection',
  vriBalanceBaseAverageFatCorrection = 'vri.balanceBaseAverageFatCorrection',
  vriBalanceBaseAverageMuscleCorrection = 'vri.balanceBaseAverageMuscleCorrection',
  vriBalanceBaseAverageMeatCorrection = 'vri.balanceBaseAverageMeatCorrection',

  // VRI values

  vriId = 'vri.id',
  vriRating = 'vri.rating',
  vriVriSerialNumber = 'vri.vriSerialNumber',
  vriUbn = 'vri.ubn',
  vriName = 'vri.name',
  vriTotal = 'vri.total',
  vriVriEpg = 'vri.vriEpg',
  vriEpgSerialNumber = 'vri.epgSerialNumber',
  vriGrowth = 'vri.growth',
  vriFeedIntakePerDay = 'vri.feedIntakePerDay',
  vriEwConversion = 'vri.ewConversion',
  vriLayingBigWeight = 'vri.layingBigWeight',
  vriLoss = 'vri.loss',
  vriEpg = 'vri.epg',
  vriVriClassification = 'vri.vriClassification',
  vriEpgOrderNumber = 'vri.epgOrderNumber',
  vriBalanceWithRespectToBase = 'vri.balanceWithRespectToBase',
  vriAverageWeight = 'vri.averageWeight',
  vriAverageWeightCorrection = 'vri.averageWeightCorrection',
  vriAverageFatThickness = 'vri.averageFatThickness',
  vriAverageBeaconCorrection = 'vri.averageBeaconCorrection',
  vriAverageMuscleThickness = 'vri.averageMuscleThickness',
  vriAverageMuscleCorrection = 'vri.averageMuscleCorrection',
  vriAverageMeat = 'vri.averageMeat',
  vriAverageMeatCorrection = 'vri.averageMeatCorrection',
  vriAverageClassificationCorrection = 'vri.averageClassificationCorrection',
  vriAverageSexAllowance = 'vri.averageSexAllowance',
  vriAverageConceptSurcharge = 'vri.averageConceptSurcharge',
  vriPigsInDraft = 'vri.pigsInDraft',
  vriVriHealth = 'vri.vriHealth',
  vriEpgNumber = 'vri.epgNumber',
  vriEuroPerPig = 'vri.euroPerPig',
  vriApprovedCarcass = 'vri.approvedCarcass',
  vriPleurisy = 'vri.pleurisy',
  vriInflamedSkin = 'vri.inflamedSkin',
  vriInflamedLeg = 'vri.inflamedLeg',
  vriApprovedOrgan = 'vri.approvedOrgan',
  vriLeverAfg = 'vri.leverAfg',
  vriLiverAfg = 'vri.liverAfg',
  vriLongAfg = 'vri.longAfg',
  vriLungAfg = 'vri.lungAfg',
  vriHealthy = 'vri.healthy',
  vriVriTotal = 'vri.vriTotal',
  vriLapSpeed = 'vri.lapSpeed',
  vriDeliveredAnimals = 'vri.deliveredAnimals',
  vriDelivered = 'vri.delivered',
  vriVriBalanceWithRespectToBase = 'vri.vriBalanceWithRespectToBase',
  vriEndManufacturer = 'vri.endManufacturer',
  vriFeedingSystem = 'vri.feedingSystem',
  vriReportDateTo = 'vri.reportDateTo',
  vriReportDateFrom = 'vri.reportDateFrom',
  vriEuroPerPigMax = 'vri.euroPerPigMax',
  vriEuroPerPigMin = 'vri.euroPerPigMin',
  vriEpgMax = 'vri.epgMax',
  vriEpgMin = 'vri.epgMin',
  vriAverageNutrientEw = 'vri.averageNutrientEw',
  vriRemovedPigsCount = 'vri.removedPigsCount',
  vriAverageEpgWeight = 'vri.averageEpgWeight',
  vriAverageBirthWeight = 'vri.averageBirthWeight',
  vriAverageRoundLengthDays = 'vri.averageRoundLengthDays',
  vriAveragePigsCount = 'vri.averagePigsCount',
  vriEpgEuro = 'vri.epgEuro',

  // invoices

  invoicesCurrencyCode = 'invoices.currencyCode',
  invoicesOverviewType = 'invoices.overviewType',
  invoicesInvoiceNumber = 'invoices.invoiceNumber',
  invoicesInvoiceDate = 'invoices.invoiceDate',
  invoicesUniqueMessageNumber = 'invoices.uniqueMessageNumber',
  invoicesSupplierName = 'invoices.supplierName',
  invoicesSupplierStreet = 'invoices.supplierStreet',
  invoicesSupplierHouseNumber = 'invoices.supplierHouseNumber',
  invoicesSupplierPlace = 'invoices.supplierPlace',
  invoicesSupplierPostCode = 'invoices.supplierPostCode',
  invoicesSupplierEANAddressCode = 'invoices.supplierEANAddressCode',
  invoicesSupplierBankAccount = 'invoices.supplierBankAccount',
  invoicesSupplierIban = 'invoices.supplierIban',
  invoicesSupplierVatNumber = 'invoices.supplierVatNumber',
  invoicesCustomerName = 'invoices.customerName',
  invoicesCustomerStreetAddress = 'invoices.customerStreetAddress',
  invoicesCustomerPostalAddressPlace = 'invoices.customerPostalAddressPlace',
  invoicesCustomerRelationNumber = 'invoices.customerRelationNumber',
  invoicesDeliveries = 'invoices.deliveries',

  // invoice details

  invoicesInvoice = 'invoices.invoice',
  invoicesUnit = 'invoices.unit',
  invoicesInvoiceMessageId = 'invoices.invoiceMessageId',
  invoicesLineNumber = 'invoices.lineNumber',
  invoicesDeliveryDate = 'invoices.deliveryDate',
  invoicesDeliveryNoteNumber = 'invoices.deliveryNoteNumber',
  invoicesDeliveryNoteLineNumber = 'invoices.deliveryNoteLineNumber',
  invoicesProductQuantity = 'invoices.productQuantity',
  invoicesProductArticleCode = 'invoices.productArticleCode',
  invoicesProductDescription = 'invoices.productDescription',
  invoicesProductArticleGroupCode = 'invoices.productArticleGroupCode',
  invoicesProductArticleGroupCodeDescription = 'invoices.productArticleGroupCodeDescription',
  invoicesProductEnergeticValue = 'invoices.productEnergeticValue',

  // slaughter

  slaughterFarmerId = 'slaughter.farmerId',
  slaughterMessageDate = 'slaughter.messageDate',
  slaughterCurrency = 'slaughter.currency',
  slaughterSlaughterHouseId = 'slaughter.slaughterHouseId',
  slaughterSlaughterDate = 'slaughter.slaughterDate',
  slaughterSlaughters = 'slaughter.slaughters',
  slaughterSlaughterPlaceId = 'slaughter.slaughterPlaceId',
  slaughterUbnList = 'slaughter.ubnList',
  slaughterMessageSequenceNumber = 'slaughter.messageSequenceNumber',
  slaughterAnimalsSuppliedCount = 'slaughter.animalsSuppliedCount',

  // slaughter details

  slaughterSlaughterMessageId = 'slaughter.slaughterMessageId',
  slaughterSlaughterMessage = 'slaughter.slaughterMessage',
  slaughterSlaughterKey = 'slaughter.slaughterKey',
  slaughterAnimalType = 'slaughter.animalType',
  slaughterCarcassCode = 'slaughter.carcassCode',
  slaughterAnimalWeight = 'slaughter.animalWeight',
  slaughterOrganCode = 'slaughter.organCode',
  slaughterMuscleThickness = 'slaughter.muscleThickness',
  slaughterFatThickness = 'slaughter.fatThickness',
  slaughterAfterCorrectionPigAmount = 'slaughter.afterCorrectionPigAmount',
  slaughterContainerNumber = 'slaughter.containerNumber',
  slaughterMeat = 'slaughter.meat',
  slaughterAnimalIdentificationType = 'slaughter.animalIdentificationType',

  // slaughter price corrections

  slaughterPriceCorrections = 'title.slaughterPriceCorrections',

  slaughterPCFinancialResultId = 'slaughterPC.financialResultId',
  slaughterPCFinancialResultDateTime = 'slaughterPC.financialResultDateTime',
  slaughterPCSlaughterHouseTypeCode = 'slaughterPC.slaughterHouseTypeCode',
  slaughterPCSlaughterHouseValue = 'slaughterPC.slaughterHouseValue',
  slaughterPCTraderTypeCode = 'slaughterPC.traderTypeCode',
  slaughterPCTraderValue = 'slaughterPC.traderValue',
  slaughterPCAnimalTypeCode = 'slaughterPC.animalTypeCode',
  slaughterPCAnimalValue = 'slaughterPC.animalValue',
  slaughterPCNumberOfAnimals = 'slaughterPC.numberOfAnimals',
  slaughterPCCurrencyCode = 'slaughterPC.currencyCode',

  // slaughter price corrections

  slaughterChargeOrPremiums = 'title.slaughterChargeOrPremiums',

  slaughterCOPCodeListId = 'slaughterCOP.codeListId',
  slaughterCOPCode = 'slaughterCOP.code',
  slaughterCOPBaseCode = 'slaughterCOP.baseCode',
  slaughterCOPAmount = 'slaughterCOP.amount',
  slaughterCOPVatPercentage = 'slaughterCOP.vatPercentage',
  slaughterCOPVatAmount = 'slaughterCOP.vatAmount',

  // genetics

  geneticsUbn = 'genetics.ubn',
  geneticsSemenProducerUbn = 'genetics.semenProducerUbn',
  geneticsDeliveryDate = 'genetics.deliveryDate',
  geneticsMaleType = 'genetics.maleType',
  geneticsMaleAmount = 'genetics.maleAmount',
  geneticsFemaleType = 'genetics.femaleType',
  geneticsFemaleAmount = 'genetics.femaleAmount',

  // organizations

  createOrganization = 'organizations.createOrganization',
  organizationPlural = 'organizations.organizationPlural',
  organizationChangeBanner = 'organizations.changeBanner',
  invalidFileExtension = 'invalidFileExtension',
  draftPassports = 'draftPassports',
  submittedPassports = 'submittedPassports',
  approvedPassports = 'approvedPassports',
  rejectedPassports = 'rejectedPassports',
  //
  organizationsKvkName = 'organizations.kvkName',
  organizationsConcepts = 'organizations.concepts',
  organizationsCertificatesOther = 'organizations.certificatesOther',
  organizationsGeneticSuppliers = 'organizations.geneticSuppliers',
  organizationsGeneticSupplierOther = 'organizations.geneticSupplierOther',
  organizationsKiStations = 'organizations.kiStations',
  organizationsKiStationOther = 'organizations.kiStationOther',
  organizationsSowLines = 'organizations.sowLines',
  organizationsSowLineOther = 'organizations.sowLineOther',
  organizationsBreedingProgram = 'organizations.breedingProgram',
  organizationsSireLines = 'organizations.sireLines',
  organizationsSireLineOther = 'organizations.sireLineOther',
  organizationsVeterinaryPractice = 'organizations.veterinaryPractice',
  organizationsVeterinaryPracticeOther = 'organizations.veterinaryPracticeOther',
  organizationsFeedSupplier = 'organizations.feedSupplier',
  organizationsFeedSupplierOther = 'organizations.feedSupplierOther',
  organizationsFmsNames = 'organizations.fmsNames',
  organizationsFmsModuleAgrisysts = 'organizations.fmsModuleAgrisysts',
  organizationsFmsModuleAgroCoops = 'organizations.fmsModuleAgroCoops',
  organizationsFmsModuleAgrovisions = 'organizations.fmsModuleAgrovisions',
  organizationsFeedingSystems = 'organizations.feedingSystems',
  organizationsFeedingSystemOther = 'organizations.feedingSystemOther',
  organizationsClimateSystems = 'organizations.climateSystem',
  organizationsClimateSystemOther = 'organizations.climateSystemOther',
  organizationsCompanyName = 'organizations.companyName',
  organizationsAddressLine1 = 'organizations.addressLine1',
  organizationsAddressLine2 = 'organizations.addressLine2',
  organizationsPostalCode = 'organizations.postalCode',
  organizationsCity = 'organizations.city',
  organizationsTelephoneNumber = 'organizations.telephoneNumber',
  organizationsVatNumber = 'organizations.vatNumber',
  organizationsUsers = 'organizations.users',
  organizationsPermissionProfile = 'organizations.permissionProfile',

  // organizations passports

  organizationsType = 'organization.type',
  // organizationsConcept = 'organizations.concept',
  organizationsKvk = 'organization.kvk',
  organizationsGln = 'organization.gln',
  organizationsUbn = 'organization.ubn',
  organizationsVionRelationNumber = 'organization.vionRelationNumber',
  organizationsName = 'organization.name',
  organizationsRegistrationName = 'organization.registrationName',
  organizationsVatNum = 'organization.vatNum',
  organizationsAddressStreet = 'organization.addressStreet',
  organizationsAddressPostcode = 'organization.addressPostcode',
  organizationsAddressPlace = 'organization.addressPlace',
  organizationsPhone = 'organization.phone',
  organizationsEmail = 'organization.email',
  organizationsCertificationAuthority = 'organization.certificationAuthority',
  organizationsFeedSuppliers = 'organization.feedSuppliers',
  organizationsVeterinarian = 'organization.veterinarian',
  organizationsTypeOfCompany = 'organization.typeOfCompany',

  // delivery messages

  deliveryHeaderUniqueMessageNumber = 'delivery.headerUniqueMessageNumber',
  deliveryHeaderAssociationAssignedCode = 'delivery.headerAssociationAssignedCode',
  deliveryHeaderMessageSentDate = 'delivery.headerMessageSentDate',
  deliveryHeaderSenderId = 'delivery.headerSenderId',
  deliveryHeaderMessageVersionNumber = 'delivery.headerMessageVersionNumber',
  deliverySupplierNumber = 'delivery.supplierNumber ',
  deliverySupplierName = 'delivery.supplierName',
  deliverySupplierStreetName = 'delivery.supplierStreetName',
  deliverySupplierHouseNumber = 'delivery.supplierHouseNumber',
  deliverySupplierPostalCode = 'delivery.supplierPostalCode',
  deliverySupplierCityName = 'delivery.supplierCityName',
  deliverySupplierCountry = 'delivery.supplierCountry',
  deliverySupplierPhoneNumber = 'delivery.supplierPhoneNumber',
  deliverySupplierEmailAddress = 'delivery.supplierEmailAddress',
  deliverySupplierUrl = 'delivery.supplierUrl',
  deliverySupplierVat = 'delivery.supplierVat',
  deliveryCustomerNumber = 'delivery.customerNumber',
  deliveryCustomerName = 'delivery.customerName',
  deliveryCustomerStreetName = 'delivery.customerStreetName',
  deliveryCustomerHouseNumber = 'delivery.customerHouseNumber',
  deliveryCustomerPostalCode = 'delivery.customerPostalCode',
  deliveryCustomerCityName = 'delivery.customerCityName',
  deliveryCustomerCountry = 'delivery.customerCountry',

  // delivery segment

  deliveryUniqueKeyReceipt = 'delivery.uniqueKeyReceipt',
  deliveryReceiptNumber = 'delivery.receiptNumber',
  deliveryReceiptDate = 'delivery.receiptDate',
  deliveryReceiptPrintDate = 'delivery.receiptPrintDate',
  deliveryUbnNumber = 'delivery.ubnNumber',
  deliveryStreetnameDeliveryAddress = 'delivery.streetnameDeliveryAddress',
  deliveryCityNameDeliveryAddress = 'delivery.cityNameDeliveryAddress',
  deliveryPostcodeDeliveryAddress = 'delivery.postcodeDeliveryAddress',
  deliveryCountryDeliveryAddress = 'delivery.countryDeliveryAddress',
  deliveryOrderNumber = 'delivery.orderNumber',
  deliveryDeliveryMethod = 'delivery.deliveryMethod',
  deliveryBrsNumber = 'delivery.brsNumber',

  // delivery segment contents

  deliveryNoteLineNumber = 'delivery.noteLineNumber',
  deliveryArticleNumber = 'delivery.articleNumber',
  deliveryArticleDescription = 'delivery.articleDescription',
  deliveryNetQuantityOfProductDelivered = 'delivery.netQuantityOfProductDelivered',
  deliveryQuantityUnit = 'delivery.quantityUnit',
  deliveryArticleGroupCode = 'delivery.articleGroupCode',
  deliveryQuantityOfProductOrdered = 'delivery.quantityOfProductOrdered',
  deliveryBranchNumber = 'delivery.branchNumber',
  deliveryBranchName = 'delivery.branchName',
  deliveryGmpNumber = 'delivery.gmpNumber',
  deliveryInvoiceNumber = 'delivery.invoiceNumber',
  deliveryBaseContent = 'delivery.baseContent',
  deliveryNutrientN = 'delivery.nutrientN',
  deliveryNutrientP = 'delivery.nutrientP',
  deliveryNutrientRe = 'delivery.nutrientRe',
  deliveryNutrientFos = 'delivery.nutrientFos',
  deliveryNutrientVem = 'delivery.nutrientVem',
  deliveryNutrientVevi = 'delivery.nutrientVevi',
  deliveryEnergyValueEw = 'delivery.energyValueEw',
  deliveryNutrientDs = 'delivery.nutrientDs',
  deliveryNutrientNdf = 'delivery.nutrientNdf',
  deliveryNutrientStarch = 'delivery.nutrientStarch',
  deliveryNutrientEf00 = 'delivery.nutrientEf00',
  deliveryNutrientEf40 = 'delivery.nutrientEf40',
  deliveryNutrientEf80 = 'delivery.nutrientEf80',
  deliveryNutrientCo2 = 'delivery.nutrientCo2',
  deliveryNutrientVCos = 'delivery.nutrientVCos',
  deliveryNutrientVCre = 'delivery.nutrientVCre',
  deliveryAnimalCategoryCode = 'delivery.animalCategoryCode',
  deliveryFeedTypeCode = 'delivery.feedTypeCode',
  deliveryFeedTypeCodeKlw = 'delivery.feedTypeCodeKlw',
  deliveryRas = 'delivery.ras',

  // veterinarian data

  veterinaryExportDate = 'veterinary.exportDate',
  veterinaryUbn = 'veterinary.ubn',
  veterinaryCompanyName = 'veterinary.companyName',
  veterinaryStreetAddress = 'veterinary.streetAddress',
  veterinaryPlaceAddress = 'veterinary.placeAddress',
  veterinaryPeriod = 'veterinary.period',
  veterinaryAnimalCategory = 'veterinary.animalCategory',
  veterinaryDddTm = 'veterinary.dddTm',
  veterinaryDeliveryDate = 'veterinary.deliveryDate',
  veterinaryRegistrationDate = 'veterinary.registrationDate',
  veterinaryVeterinarianName = 'veterinary.veterinarianName',
  veterinaryEan = 'veterinary.ean',
  veterinaryRegistrationNumberNl = 'veterinary.registrationNumberNl',
  veterinaryChoice = 'veterinary.choice',
  veterinaryProductName = 'veterinary.productName',
  veterinaryPackagesCount = 'veterinary.veterinaryPackagesCount',
  veterinaryCapacity = 'veterinary.capacity',
  veterinaryTotal = 'veterinary.total',
  veterinaryDosingFactor = 'veterinary.dosingFactor',
  veterinaryAvgAnimalsNumber = 'veterinary.avgAnimalsNumber',
  veterinaryWeightPerAnimal = 'veterinary.weightPerAnimal',
  veterinaryDddDaily = 'veterinary.dddDaily',

  // movement events

  movementEventsEventId = 'movementEvents.eventId',
  movementEventsEventType = 'movementEvents.eventType',
  movementEventsEventDateTime = 'movementEvents.eventDateTime',
  movementEventsAnimalCategory = 'movementEvents.animalCategory',
  movementEventsAnimalCount = 'movementEvents.animalCount',
  movementEventsTransportId = 'movementEvents.transportId',
  movementEventsGroupDate = 'movementEvents.groupDate',
  movementEventsKvk = 'movementEvents.kvk',
  movementEventsUbn = 'movementEvents.ubn',
  movementEventsDestination = 'movementEvents.destination',
  movementEventsOrigin = 'movementEvents.origin',
  movementEventsEventLocationId = 'movementEvents.eventLocationId',
  movementEventsEventIdMatch = 'movementEvents.eventIdMatch',

  // farm home
  farmFarmProfile = 'farm.farmProfile',
  farmStableData = 'farm.stableData',
  farmStableRegistrations = 'farm.stableRegistrations',

  // farm events

  farmUbn = 'farm.ubn',
  farmStableName = 'farm.stableName',
  farmType = 'farm.type',
  farmAction = 'farm.action',
  farmLastModifiedBy = 'farm.lastModifiedBy',

  // farm reports

  farmSupportTitle = 'farm.supportTitle',
  farmDeathsTitle = 'farm.deathsTitle',
  farmDeliveryTitle = 'farm.deliveryTitle',
  farmFeedConsumptionTitle = 'farm.feedConsumptionTitle',
  farmStable = 'farm.stable',
  farmDepartment = 'farm.department',
  farmOriginUbn = 'farm.originUbn',
  farmAmount = 'farm.amount',
  farmWeight = 'farm.weight',
  farmDate = 'farm.date',
  farmGeneticMother = 'farm.geneticMother',
  farmGeneticFather = 'farm.geneticFather',
  farmVaccination = 'farm.vaccination',
  farmNotes = 'farm.notes',
  farmCauseOfDeath = 'farm.causeOfDeath',
  farmActualWeightOfFeedType = 'farm.actualWeightOfFeedType',
  farmStartFeed = 'farm.startFeed',
  farmGrowthFeed = 'farm.growthFeed',
  farmFinishFeed = 'farm.finishFeed',
  farmDepartmentNotFound = 'farm.departmentNotFound',

  // farm stables

  farmSelectLocationTitle = 'farm.selectLocationTitle',
  farmStableNumber = 'farm.stableNumber',

  farmModifyStableTitle = 'farm.modifyStableTitle',
  // farmStableName = 'farm.stableName',  // duplicates
  farmAnimalCategory = 'farm.animalCategory',
  farmWeaningPiglets = 'farm.weaningPiglets',
  farmSows = 'farm.sows',
  farmFinishers = 'farm.finishers',
  farmFeeding = 'farm.feeding',
  farmFeedType = 'farm.feedType',
  farmDryFoodLimited = 'farm.dryFoodLimited',
  farmDryFoodUnlimited = 'farm.dryFoodUnlimited',
  farmLiquidFoodLimited = 'farm.liquidFoodLimited',
  farmLiquidFoodUnlimited = 'farm.liquidFoodUnlimited',
  farmDeleteStable = 'farm.deleteStable',
  farmEditDepartments = 'farm.editDepartments',

  farmDepartments = 'farm.departments',
  farmDepartmentsOptionalNote = 'farm.departmentsOptionalNote',

  farmNumberOfDepartments = 'farm.numberOfDepartments',
  farmPlacesPerDepartment = 'farm.placesPerDepartment',
  farmPlacesPerDepartmentAverage = 'farm.placesPerDepartmentAverage',
  farmEditDepartmentButtonText = 'farm.editDepartmentButtonText',

  // My Joindata

  purposePlural = 'joindata.purposePlural',
  participationPlural = 'joindata.participations',
  requestParticipations = 'joindata.requestParticipations',
  requestedParticipations = 'joindata.requestedParticipations',
  unmatchedOrganizations = 'joindata.unmatchedOrganizations',

  joindataPurposeName = 'joindata.name',
  joindataParticipationGrantedOn = 'joindata.participation.grantedOn',
  joindataParticipationStartDate = 'joindata.participation.startDate',
  joindataParticipationStatus = 'joindata.participation.status',
  joindataParticipationValid = 'joindata.participation.valid',
  joindataRestrictionType = 'joindata.restriction.type',
  joindataRestrictionDescription = 'joindata.restriction.description',
  joindataRestrictionStart = 'joindata.restriction.start',
  joindataContentStartDate = 'joindata.contentStartDate',
  joindataExternalParticipationId = 'joindata.externalParticipationId',

  // Purpose Details

  purposeStatus = 'purposeStatus',
  purposeDetails = 'purposeDetails',
  farmManagementData = 'purposeDetails.farmManagementData',
  deliveryNotices = 'purposeDetails.deliveryNotices',
  //
  purposeDashboardP1 = 'purposeDetails.dashboard.p1',
  purposeDashboardP2 = 'purposeDetails.dashboard.p2',
  purposeDashboardP3 = 'purposeDetails.dashboard.p3',
  purposeDashboardP4 = 'purposeDetails.dashboard.p4',
  purposeDashboardP5 = 'purposeDetails.dashboard.p5',
  purposeDashboardP6 = 'purposeDetails.dashboard.p6',
  purposeDashboardP7 = 'purposeDetails.dashboard.p7',
  purposeVriToolP1 = 'purposeDetails.vriTool.p1',
  purposeVriToolP2 = 'purposeDetails.vriTool.p2',
  purposeVriToolP3 = 'purposeDetails.vriTool.p3',
  purposeVriToolP4 = 'purposeDetails.vriTool.p4',
  purposeVriToolP5 = 'purposeDetails.vriTool.p5',
  purposeVriToolP6 = 'purposeDetails.vriTool.p6',
  purposeVriToolP7 = 'purposeDetails.vriTool.p7',
  purposeVriToolP8 = 'purposeDetails.vriTool.p8',
  purposeVriToolP9 = 'purposeDetails.vriTool.p9',
  purposeVriToolP10 = 'purposeDetails.vriTool.p10',
  purposeVriToolP11 = 'purposeDetails.vriTool.p11',
  purposeVriToolP12 = 'purposeDetails.vriTool.p12',
  purposeVriToolP13 = 'purposeDetails.vriTool.p13',
  purposeVriToolP14 = 'purposeDetails.vriTool.p14',
  purposeVriToolP15 = 'purposeDetails.vriTool.p15',
  purposeVriToolP16 = 'purposeDetails.vriTool.p16',
  purposeVriToolP17 = 'purposeDetails.vriTool.p17',
  purposeVriToolP18 = 'purposeDetails.vriTool.p18',
  purposeVriToolP19 = 'purposeDetails.vriTool.p19',
  purposeVriToolP20 = 'purposeDetails.vriTool.p20',
  purposeVriToolP21 = 'purposeDetails.vriTool.p21',
  purposeVriFarmingGroupP1 = 'purposeDetails.vriFarmingGroup.p1',
  purposeVriFarmingGroupP2 = 'purposeDetails.vriFarmingGroup.p2',
  purposeVriFarmingGroupP3 = 'purposeDetails.vriFarmingGroup.p3',
  purposeVriFarmingGroupP4 = 'purposeDetails.vriFarmingGroup.p4',
  purposeVriFarmingGroupP5 = 'purposeDetails.vriFarmingGroup.p5',
  purposeVriFarmingGroupP6 = 'purposeDetails.vriFarmingGroup.p6',
  purposeVriFarmingGroupP7 = 'purposeDetails.vriFarmingGroup.p7',
  purposeVriFarmingGroupP8 = 'purposeDetails.vriFarmingGroup.p8',
  purposeVriFarmingGroupP9 = 'purposeDetails.vriFarmingGroup.p9',
  purposeVriFarmingGroupP10 = 'purposeDetails.vriFarmingGroup.p10',
  purposeVriFarmingGroupP11 = 'purposeDetails.vriFarmingGroup.p11',
  purposeVriFarmingGroupP12 = 'purposeDetails.vriFarmingGroup.p12',
  purposeVriFarmingGroupP13 = 'purposeDetails.vriFarmingGroup.p13',
  purposeVriFarmingGroupP14 = 'purposeDetails.vriFarmingGroup.p14',
  purposeVriFarmingGroupP15 = 'purposeDetails.vriFarmingGroup.p15',
  purposeVriFarmingGroupP16 = 'purposeDetails.vriFarmingGroup.p16',
  purposeVriFarmingGroupP17 = 'purposeDetails.vriFarmingGroup.p17',
  purposeVisionDashboardGroupP1 = 'purposeDetails.visionDashboard.p1',
  purposeVisionDashboardGroupP2 = 'purposeDetails.visionDashboard.p2',
  purposeVisionDashboardGroupP3 = 'purposeDetails.visionDashboard.p3',
  purposeVisionDashboardGroupP4 = 'purposeDetails.visionDashboard.p4',
  purposeVisionDashboardGroupP5 = 'purposeDetails.visionDashboard.p5',
  purposeVisionDashboardGroupP6 = 'purposeDetails.visionDashboard.p6',
  purposeVisionDashboardGroupP7 = 'purposeDetails.visionDashboard.p7',
  purposeVisionDashboardGroupP8 = 'purposeDetails.visionDashboard.p8',
  purposeVisionDashboardGroupP9 = 'purposeDetails.visionDashboard.p9',
  purposeVisionDashboardGroupP10 = 'purposeDetails.visionDashboard.p10',
  purposeVisionDashboardGroupP11 = 'purposeDetails.visionDashboard.p11',
  purposeVisionDashboardGroupP12 = 'purposeDetails.visionDashboard.p12',
  purposeVisionDashboardGroupP13 = 'purposeDetails.visionDashboard.p13',
  purposeVisionDashboardGroupP14 = 'purposeDetails.visionDashboard.p14',
  purposeVisionDashboardGroupP15 = 'purposeDetails.visionDashboard.p15',
  purposeVisionDashboardGroupP16 = 'purposeDetails.visionDashboard.p16',
  purposeVisionDashboardGroupP17 = 'purposeDetails.visionDashboard.p17',
  purposeVisionDashboardGroupP18 = 'purposeDetails.visionDashboard.p18',
  purposeVisionDashboardGroupP19 = 'purposeDetails.visionDashboard.p19',
  purposeVisionDashboardGroupP20 = 'purposeDetails.visionDashboard.p20',
  purposeVisionDashboardGroupP21 = 'purposeDetails.visionDashboard.p21',
  purposeVisionDashboardGroupP22 = 'purposeDetails.visionDashboard.p22',
  purposeVisionDashboardGroupP23 = 'purposeDetails.visionDashboard.p23',
  titleKwaliteitscertificaten = 'titleKwaliteitscertificaten',
  titleSlachtAndSaldo = 'titleSlachtAndSaldo',
  titleDierverplaatsingsberichten = 'titleDierverplaatsingsberichten',
  titleDierenartsData = 'titleDierenartsData',
  titleTransports = 'titleTransports',
  purposeСo2FootprintPigFarmingGroupP1 = 'purposeDetails.Сo2FootprintPigFarming.p1',
  purposeСo2FootprintPigFarmingGroupP2 = 'purposeDetails.Сo2FootprintPigFarming.p2',
  purposeСo2FootprintPigFarmingGroupP3 = 'purposeDetails.Сo2FootprintPigFarming.p3',
  purposeСo2FootprintPigFarmingGroupP4 = 'purposeDetails.Сo2FootprintPigFarming.p4',
  purposeСo2FootprintPigFarmingGroupP5 = 'purposeDetails.Сo2FootprintPigFarming.p5',
  purposeСo2FootprintPigFarmingGroupP6 = 'purposeDetails.Сo2FootprintPigFarming.p6',
  purposeСo2FootprintPigFarmingGroupP7 = 'purposeDetails.Сo2FootprintPigFarming.p7',
  purposeСo2FootprintPigFarmingGroupP8 = 'purposeDetails.Сo2FootprintPigFarming.p8',
  purposeСo2FootprintPigFarmingGroupP9 = 'purposeDetails.Сo2FootprintPigFarming.p9',
  purposeСo2FootprintPigFarmingGroupP10 = 'purposeDetails.Сo2FootprintPigFarming.p10',
  purposeСo2FootprintPigFarmingGroupP11 = 'purposeDetails.Сo2FootprintPigFarming.p11',
  purposeСo2FootprintPigFarmingGroupP12 = 'purposeDetails.Сo2FootprintPigFarming.p12',
  purposeСo2FootprintPigFarmingGroupP13 = 'purposeDetails.Сo2FootprintPigFarming.p13',
  purposeСo2FootprintPigFarmingGroupP14 = 'purposeDetails.Сo2FootprintPigFarming.p14',
  purposeСo2FootprintPigFarmingGroupP15 = 'purposeDetails.Сo2FootprintPigFarming.p15',
  purposeСo2FootprintPigFarmingGroupP16 = 'purposeDetails.Сo2FootprintPigFarming.p16',
  purposeСo2FootprintPigFarmingGroupP17 = 'purposeDetails.Сo2FootprintPigFarming.p17',
  purposeСo2FootprintPigFarmingGroupP18 = 'purposeDetails.Сo2FootprintPigFarming.p18',
  purposeСo2FootprintPigFarmingGroupP19 = 'purposeDetails.Сo2FootprintPigFarming.p19',
  purposeСo2FootprintPigFarmingGroupP20 = 'purposeDetails.Сo2FootprintPigFarming.p20',
  purposeСo2FootprintPigFarmingGroupP21 = 'purposeDetails.Сo2FootprintPigFarming.p21',
  purposeСo2FootprintPigFarmingGroupP22 = 'purposeDetails.Сo2FootprintPigFarming.p22',
  purposeСo2FootprintPigFarmingGroupP23 = 'purposeDetails.Сo2FootprintPigFarming.p23',
  purposeСo2FootprintPigFarmingGroupP24 = 'purposeDetails.Сo2FootprintPigFarming.p24',
  purposeСo2FootprintPigFarmingGroupP25 = 'purposeDetails.Сo2FootprintPigFarming.p25',
  purposeСo2FootprintPigFarmingGroupP26 = 'purposeDetails.Сo2FootprintPigFarming.p26',
  purposeСo2FootprintPigFarmingGroupP27 = 'purposeDetails.Сo2FootprintPigFarming.p27',
  purposeСo2FootprintPigFarmingGroupP28 = 'purposeDetails.Сo2FootprintPigFarming.p28',
  titleDataFromSawFarming = 'titleDataFromSawFarming',
  titleDataFromFatteningPigIndustry = 'titleDataFromFatteningPigIndustry',
  purposeСo2FootprintFeedOriginGroupP1 = 'purposeDetails.Сo2FootprintFeedOrigin.p1',
  purposeСo2FootprintFeedOriginGroupP2 = 'purposeDetails.Сo2FootprintFeedOrigin.p2',
  purposeСo2FootprintFeedOriginGroupP3 = 'purposeDetails.Сo2FootprintFeedOrigin.p3',
  purposeСo2FootprintFeedOriginGroupP4 = 'purposeDetails.Сo2FootprintFeedOrigin.p4',
  purposeСo2FootprintFeedOriginGroupP5 = 'purposeDetails.Сo2FootprintFeedOrigin.p5',
  purposeСo2FootprintFeedOriginGroupP6 = 'purposeDetails.Сo2FootprintFeedOrigin.p6',
  purposeСo2FootprintFeedOriginGroupP7 = 'purposeDetails.Сo2FootprintFeedOrigin.p7',
  titleFeedDateFromFeedSupplier = 'titleFeedDateFromFeedSupplier',
  titleFeedDataFromFeedSupplierForCalculationCO2Emissions = 'titleFeedDataFromFeedSupplierForCalculationCO2Emissions',

  // Transports

  transports = 'transports.self',
  transportLoadings = 'transports.loadings',
  transportLoadingsShort = 'transports.loadings.short',
  transportUnloadings = 'transports.unloadings',
  transportUnloadingsShort = 'transports.unloadings.short',

  transportsReferenceId = 'transports.transportReferenceId',
  transportsPartyIdTypeCode = 'transports.partyIdTypeCode',
  transportsPartyIdValue = 'transports.partyIdValue',
  transportsLogisticServiceProviderName = 'transports.logisticServiceProviderName',

  transportsPlannedArrivalTime = 'transports.loadings.plannedArrivalTime',
  transportsActualArrivalTime = 'transports.loadings.actualArrivalTime',
  transportsStartLoadingTime = 'transports.loadings.startLoadingTime',
  transportsEndLoadingTime = 'transports.loadings.endLoadingTime',
  transportsPlannedDepartureTime = 'transports.loadings.plannedDepartureTime',
  transportsActualDepartureTime = 'transports.loadings.actualDepartureTime',
  transportsLocationIdTypeCode = 'transports.loadings.locationIdTypeCode',
  transportsLocationIdValue = 'transports.loadings.locationIdValue',
  transportsLocationName = 'transports.loadings.locationName',

  // farmer input

  farmerInputCategory = 'farmerInput.category',
  farmerInputIdCode = 'farmetInput.idCode',
  farmerInputIdType = 'farmerInput.IdType',
  farmerInputReportDate = 'farmerInput.reportDate',
  farmerInputStatus = 'farmerInput.status',
  farmerInputUpdatedBy = 'farmerInput.updatedBy',
  farmerInputUpdatedOn = 'farmerInput.updatedOn',
  farmerInputManureFileName = 'farmerInput.manureFileName',
  farmerInputAverageNumberOfSowsPerPeriod = 'farmerInput.averageNumberOfSowsPerPeriod',
  farmerInputAverageNumberOfRearingSowsPerPeriod = 'farmerInput.averageNumberOfRearingSowsPerPeriod',
  farmerInputAverageNumberOfPigletsPerPeriod = 'farmerInput.averageNumberOfPigletsPerPeriod',
  farmerInputAverageNumberOfFinishingPigsPerPeriod = 'farmerInput.averageNumberOfFinishingPigsPerPeriod',
  farmerInputTotalPriceSoldSows = 'farmerInput.totalPriceSoldSows',
  farmerInputTotalPriceSoldPiglets = 'farmerInput.totalPriceSoldPiglets',
  farmerInputAverageWeightPigletsPurchased = 'farmerInput.averageWeightPigletsPurchased',
  farmerInputAverageNumberOfPigletsProduced = 'farmerInput.averageNumberOfPigletsProduced',
  farmerInputFeedConversionBreeder = 'farmerInput.feedConversionBreeder',
  farmerInputFeedConversionFattener = 'farmerInput.feedConversionFattener',
  farmerInputCullingPercentage = 'farmerInput.cullingPercentage',
  farmerInputGrowthPerDay = 'farmerInput.growthPerDay',
  farmerInputEwConversion = 'farmerInput.ewConversion',
  farmerInputCompanyProductionResultsZeugenhouderij = 'farmerInput.companyProductionResultsZeugenhouderij',
  farmerInputCompanyProductionResultsVleesvarkenshouderij = 'farmerInput.companyProductionResultsVleesvarkenshouderij',
  farmerInputEnergyUse = 'farmerInput.energyUse',
  farmerInputEnergyUsageHint = 'farmeInput.energyUsageHint',
  farmerInputQuantityOfNonRenewableGasPurchased = 'farmerInput.quantityOfNonRenewableGasPurchased',
  farmerInputQuantityOfRenewableGasPurchased = 'farmerInput.quantityOfRenewableGasPurchased',
  farmerInputQuantityOfNonRenewableElectricityPurchased = 'farmerInput.quantityOfNonRenewableElectricityPurchased',
  farmerInputQuantityOfRenewableElectricityPurchased = 'farmerInput.quantityOfRenewableElectricityPurchased',
  farmerInputQuantityOfTapWaterUsed = 'farmerInput.quantityOfTapWaterUsed',
  farmerInputWaterFromOwnSource = 'farmerInput.waterFromOwnSource',
  farmerInputSmartWaterMeterAvailable = 'farmerInput.smartWaterMeterAvailable',
  farmerInputSmartGasMeterAvailable = 'farmerInput.smartGasMeterAvailable',
  farmerInputSmartElectiricyMeterAvailable = 'farmerInput.smartElectiricyMeterAvailable',
  farmerInputPeriodStartDate = 'farmerInput.periodStartDate',
  farmerInputPeriodEndDate = 'farmerInput.periodEndDate',
  farmerInputHouseholdComposition = 'farmerInput.householdComposition',
  farmerInputDatePurchase = 'farmerInput.datePurchase',
  farmerInputProductCode = 'farmerInput.productCode',
  farmerInputProductName = 'farmerInput.productName',
  farmerInputProductOrigin = 'farmerInput.productOrigin',
  farmerInputProductQuantity = 'farmerInput.productQuantity',
  farmerInputQuantityType = 'farmerInput.quantityType',
  farmerInputDryMatter = 'farmerInput.dryMatter',
  farmerInputRe = 'farmerInput.re',
  farmerInputRas = 'farmerInput.ras',
  farmerInputVcre = 'farmerInput.vcre',
  farmerInputVcos = 'farmerInput.vcos',
  farmerInputKg = 'farmerInput.kg',
  farmerInputGramKg = 'farmerInput.gramKg',
  farmerInputProduct = 'farmerInput.product',
  farmerInputLiter = 'farmerInput.liter',
  farmerInputSupplierName = 'farmerInput.supplierName',
  farmerInputQuantityDelivered = 'farmerInput.quantityDelivered',
  farmerInputUnit = 'farmerInput.unit',
  farmerInputProcessedManure = 'farmerInput.processedManure',
  farmerInputSodiumHydroxide = 'farmerInput.sodiumHydroxide',
  farmerInputGlycerineUsed = 'farmerInput.glycerineUsed',
  farmerInputDigestateProduction = 'farmerInput.digestateProduction',
  farmerInputBiogasProduction = 'farmerInput.biogasProduction',
  farmerInputElectricityProduction = 'farmerInput.electricityProduction',
  farmerInputTonsPeriod = 'farmerInput.tonsPeriod',
  farmerInputKgPeriod = 'farmerInput.kgPeriod',
  farmerInputM3Period = 'farmerInput.m3Period',
  farmerInputM2 = 'farmerInput.m2',
  farmerInputKwhPeriod = 'farmerInput.kWhPeriod',
  farmerInputGeneral = 'farmerInput.general',
  farmerInputOpen = 'farmerInput.open',
  farmerInputClosed = 'farmerInput.closed',
  farmerInputTypeOfCompany = 'farmerInput.typeOfCompany',
  farmerInputHouseholdCompositionInfo = 'farmerInput.householdCompositionInfo',
  farmerInputTotalPriceSowsInfo = 'farmerInput.totalPriceSowsInfo',
  farmerInputTotalPricePigletsInfo = 'farmerInput.totalPricePigletsInfo',
  farmerInputDraft = 'farmerInput.draft',
  farmerInputDraftDescription = 'farmerInput.draftDescription',
  farmerInputSubmitted = 'farmerInput.submitted',
  farmerInputSubmittedDescription = 'farmerInput.submittedDescription',
  farmerInputRevertToDraft = 'farmerInput.revertToDraft',
  farmerInputRevertToDraftDescription = 'farmerInput.revertToDraftDescription',
  farmerInputStableNumber = 'farmerInput.stableNumber',
  farmerInputRavCode = 'farmerInput.ravCode',
  farmerInputNrOfAnimals = 'farmerInput.nrOfAnimals',
  farmerInputSystemId = 'farmerInput.systemId',
  farmerInputMainReport = 'farmerInput.mainReport',
  farmerInputMainReportId = 'farmerInput.mainReportId',
  farmerInputNrPeriod = 'farmerInput.nrPeriod',
  farmerInputEurPeriod = 'farmerInput.eurPeriod',
  farmerInputNrSowPeriod = 'farmerInput.nrSowPeriod',
  farmerInputKgKg = 'farmerInput.kgKg',
  farmerInputGrDag = 'farmerInput.grDag',
  farmerInputTriggeredBy = 'farmerInput.triggeredBy',
  farmerInputReportType = 'farmerInput.reportType',
  farmerInputActionDate = 'farmerInput.actionDate',
  farmerInputAction = 'farmerInput.action',
  farmerInputRavCodesInfo = 'farmerInput.ravCodesInfo',
  farmerInputRavCodes = 'farmerInput.ravCodes',
  farmerInputRavCodesHint = 'farmerInput.ravCodesHint',
  farmerInputRavCodesHintOption1 = 'farmerInput.ravCodesHintOption1',
  farmerInputRavCodesHintOption2 = 'farmerInput.ravCodesHintOption2',
  farmerInputRavCodesHintOption3 = 'farmerInput.ravCodesHintOption3',
  farmerInputRavCodesHintOption4 = 'farmerInput.ravCodesHintOption4',
  farmerInputRavCodesHintOption5 = 'farmerInput.ravCodesHintOption5',
  farmerInputAddAtLeastOneRavCode = 'farmerInput.addAtLeastOneRavCode',
  farmerInputCompanyDataOption = 'farmerInput.companyDataOption',
  farmerInputAverageStorageDuration = 'farmerInput.averageStorageDuration',
  farmerInputAdditionalManureStorage = 'farmerInput.additionalManureStorage',
  farmerInputShorterThanOneMonth = 'farmerInput.shorterThanOneMonth',
  farmerInputBetweenOneAndThreeMonth = 'farmerInput.betweenOneAndThreeMonth',
  farmerInputBetweenThreeAndFiveMonth = 'farmerInput.betweenThreeAndFiveMonth',
  farmerInputBetweenFiveAndSixMonth = 'farmerInput.betweenFiveAndSixMonth',
  farmerInputLongerThanSixMonth = 'farmerInput.longerThanSixMonth',
  farmerInputNone = 'farmerInput.none',
  farmerInputManureStorageInSilo = 'farmerInput.ManureStorageInSilo',
  farmerInputManureStorageInBag = 'farmerInput.ManureStorageInBag',
  farmerInputSolidStorageInStacksWithoutBedding = 'farmerInput.solidStorageInStacksWithoutBedding',
  farmerInputSolidStorageInStacksWithBedding = 'farmerInput.solidStorageInStacksWithBedding',
  farmerInputManureStorageInSiloOrBag ='farmerInput.farmerInputManureStorageInSiloOrBag',
  farmerInputDurationOfStorage = 'farmerInput.durationOfStorage',
  farmerInputMonthlyManureRemoval = 'farmerInput.monthlyManureRemoval',
  farmerInputCallToActionWarningMessage = 'farmerInput.callToActionMessage',
  farmerInputReportsByProducts = 'farmerInput.reportsByProducts',
  farmerInputReportsFermentation = 'farmerInput.reportsFermentation',
  farmerInputReportsManure = 'farmerInput.reportsManure',
  farmerInputAverageFeedConsumptionPerSowPresent = 'farmerInput.averageFeedConsumptionPerSowPresent',
  farmerInputPartlyClosed = 'farmerInput.partlyClosed',
  farmerInputOpenSows = 'farmerInput.openSows',
  farmerInputOpenSowsPiglets = 'farmerInput.openSowsPiglets',
  farmerInputOpenPiglets = 'farmerInput.openPiglets',
  farmerInputOpenPigletsPigs = 'farmerInput.openPigletsPigs',
  farmerInputOpenFinishingPigs = 'farmerInput.openFinishingPigs',
  farmerInputFeedConversionKg = 'farmerInput.feedConversionKg',
  farmerInputElectricityUsageTooltipText = 'farmerInput.electricityUsageInfoIcon',
  farmerInputCollapsibleBlockTooltip = 'farmerInput.collapsibleBlockTooltip',
  farmerInputSharedSmartMeter = 'farmerInput.sharedSmartMeter',
  farmerInputQuantityOfElectricityProduced = 'farmerInput.quantityOfElectricityProduced',
  farmerInputSolarPanelsSquare = 'farmerInput.solarPanelsSquare',
  farmerInputOpenAnExampleLink = 'farmerInput.openAnExampleLink',
  farmerInputAverageNumberSucklingPiglets = 'farmerInput.averageNumberSucklingPiglets',
  farmerInputStatusAutomaticallyFound = 'farmerInput.statusAutomaticallyFound',
  farmerInputStatusManuallyEntered = 'farmerInput.statusManuallyEntered',
  farmerInputIsManureBeingRemoved = 'farmerInput.isManureBeingRemoved',
  farmerInputManureRemovingFrequency = 'farmerInput.manureRemovingFrequency',
  farmerInputByProductsOrLoose = 'farmerInput.byProductsOrLoose',
  farmerInputFeedFermentation = 'farmerInput.feedFermentation',
  daily = 'farmerInput.daily',
  weekly = 'farmerInput.weekly',
  monthly = 'farmerInput.monthly',
  onceEvery1to3Months = 'farmerInput.onceEvery1to3Months',
  onceEvery3to5Months = 'farmerInput.onceEvery3to5Months',
  lessThanOnceEvery6Months = 'farmerInput.lessThanOnceEvery6Months',
  farmerInputManureDigestion = 'farmerInput.manureDigestion',
  farmerInputFeedSuppliers = 'farmerInput.feedSuppliers',
  farmerInputFeedSuppliersHighlighted = 'farmerInput.feedSuppliersHighlighted',
  farmerInputFeedSuppliersPiglets = 'farmerInput.feedSuppliersPiglets',
  farmerInputFeedSuppliersSows = 'farmerInput.feedSuppliersSows',
  farmerInputFeedSuppliersFatteningPigs = 'farmerInput.feedSuppliersFatteningPigs',
  farmerInputFeedSuppliersFatteningPigsCategory = 'farmerInput.feedSuppliersFatteningPigsCategory',
  farmerInputFeedSuppliersSowsCategory = 'farmerInput.feedSuppliersSowsCategory',
  farmerInputFeedSuppliersPigletsCategory = 'farmerInput.feedSuppliersPigletsCategory',
  farmerInputFeedSuppliersHint = 'farmerInput.feedSuppliersHint',
  farmerInputAreRavCodesCorrect = 'farmerInput.areRavCodesCorrect',
  farmerInputRavCodesCorrect = 'farmerInput.ravCodesCorrect',
  farmerInputRavCodesIncorrect = 'farmerInput.ravCodesIncorrect',
  farmerInputCompanyTypeWarn = 'farmerInput.companyTypeWarn',
  farmerInputAtLeastOneFeedSupplierIsRequired = 'farmerInput.atLeastOneFeedSupplierIsRequired',
  farmerInputNotAvailableDutToTheCompanyType = 'farmerInput.notAvailableDueToTheCompanyType',
  farmerInputIncorrectValueRange = 'farmerInput.incorrectValueRange',
  farmerInputProductFedToAnimalCategory = 'farmerInput.productFedToAnimalCategory',
  farmerInputPigletsPercent = 'farmerInput.pigletsPercent',
  farmerInputSowsPercent = 'farmerInput.sowsPercent',
  farmerInputPigsPercent = 'farmerInput.pigsPercent',
  farmerInputSumOfPercentsShouldBe100 = 'farmerInput.percentsSumShouldBe100',

  farmerInputManureTransportations = 'farmerInput.manureTransportations',
  farmerInputManureInstructionFirstRow = 'farmerInput.manureInstruction',
  farmerInputManureInstructionSecondRow = 'farmerInput.manureInstructionFirstRow',
  farmerInputManureHere = 'farmerInput.manureHere',

  // reference tables

  referenceTablesId = 'referenceTables.id',
  referenceTablesStatus = 'referenceTables.status',
  referenceTablesCategory = 'referenceTables.category',
  referenceTablesFilename = 'referenceTables.filename',
  referenceTablesValidDateFrom = 'referenceTables.validDateFrom',
  referenceTablesValidDateTo = 'referenceTables.validDateTo',
  referenceTablesNotes = 'referenceTables.notes',
  referenceTablesValidDate = 'referenceTables.validDate',
  referenceTablesUploadDate = 'referenceTables.uploadDate',
  referenceTablesAsPensLevel = 'referenceTables.asPensLevel',
  referenceTablesPensLevel = 'referenceTables.pensLevel',
  referenceTablesPensName = 'referenceTables.pensName',
  referenceTablesPensNameEN = 'referenceTables.pensNameEN',
  referenceTablesActive = 'referenceTables.active',
  referenceTablesInputText = 'referenceTables.inputText',
  referenceTablesFrom = 'referenceTables.from',
  referenceTablesTo = 'referenceTables.to',
  referenceTablesUploadedInfo = 'referenceTables.uploadedInfo',
  referenceTablesAsPensTypeSowBarn = 'referenceTables.asPensTypeSowBarn',
  referenceTablesPensSowBarnCode = 'referenceTables.pensSowBarnCode',
  referenceTablesPensSowBarnName = 'referenceTables.pensSowBarnName',
  referenceTablesAsYesNo = 'referenceTables.asYesNo',
  referenceTablesYesNoCode = 'referenceTables.asYesNoCode',
  referenceTablesYesNoName = 'referenceTables.asYesNoName',
  referenceTablesInactive = 'referenceTables.inactive',
  referenceTablesCfpCo2EmissionFactors = 'referenceTables.cfpCo2EmissionFactors',
  referenceTablesCfpCo2EmissionFactorsSource = 'referenceTables.cfpCo2EmissionFactorsSource',
  referenceTablesCfpCo2EmissionFactorsVolume = 'referenceTables.cfpCo2EmissionFactorsVolume',
  referenceTablesCfpCo2EmissionFactorsReference = 'referenceTables.cfpCo2EmissionFactorsReference',
  referenceTablesCvbTable = 'referenceTables.cvbTable',
  referenceTablesProductCode = 'referenceTables.productCode',
  referenceTablesProductSubCode = 'referenceTables.productSubCode',
  referenceTablesProductType = 'referenceTables.productType',
  referenceTablesProductName = 'referenceTables.productName',
  referenceTablesClassName = 'referenceTables.className',
  referenceTablesSubClassName = 'referenceTables.subClassName',
  referenceTablesProductNameEN = 'referenceTables.productNameEN',
  referenceTablesClassNameEN = 'referenceTables.classNameEN',
  referenceTablesSubClassNameEN = 'referenceTables.subClassNameEN',
  referenceTablesDs = 'referenceTables.ds',
  referenceTablesDsUnit = 'referenceTables.dsUnit',
  referenceTablesRas = 'referenceTables.ras',
  referenceTablesRasUnit = 'referenceTables.rasUnit',
  referenceTablesRein = 'referenceTables.rein',
  referenceTablesReinUnit = 'referenceTables.reinUnit',
  referenceTablesRe = 'referenceTables.re',
  referenceTablesReUnit = 'referenceTables.reUnit',
  referenceTablesCvcRe = 'referenceTables.cVcRe',
  referenceTablesCvcReUnit = 'referenceTables.cVcReUnit',
  referenceTablesCvcOs = 'referenceTables.cVcOs',
  referenceTablesCvcOsUnit = 'referenceTables.cVcOsUnit',
  referenceTablesIpccEmissionFactors = 'referenceTables.ipccEmissionFactors',
  referenceTablesFactor = 'referenceTables.factor',
  referenceTablesDescription = 'referenceTables.description',
  referenceTablesDefaultValue = 'referenceTables.defaultValue',
  referenceTablesUncertaintyRange = 'referenceTables.uncertaintyRange',
  referenceTablesCfpGfliFeedprintTable = 'referenceTables.cfpGfliFeedprintTable',
  referenceTablesCfpGfliFeedprintTableFeedPrintCode = 'referenceTables.cfpGfliFeedprintTableFeedPrintCode',
  referenceTablesCfpGfliFeedprintTableCvbCode = 'referenceTables.cfpGfliFeedprintTableCvbCode',
  referenceTablesCfpGfliFeedprintTableSource = 'referenceTables.cfpGfliFeedprintTableSource',
  referenceTablesCfpGfliFeedprintTableProductNameEn = 'referenceTables.cfpGfliFeedprintTableProductNameEn',
  referenceTablesCfpGfliFeedprintTableProductNameNl = 'referenceTables.cfpGfliFeedprintTableProductNameNl',
  referenceTablesCfpGfliFeedprintTableProductCodeEu = 'referenceTables.cfpGfliFeedprintTableProductCodeEu',
  referenceTablesCfpGfliFeedprintTableProductNameEu = 'referenceTables.cfpGfliFeedprintTableProductNameEu',
  referenceTablesCfpGfliFeedprintTableOrigin = 'referenceTables.cfpGfliFeedprintTableOrigin',
  referenceTablesCfpGfliFeedprintTableClimate = 'referenceTables.cfpGfliFeedprintTableClimate',
  referenceTablesContentOfNitricOxide = 'referenceTables.contentOfNitricOxide',
  referenceTablesContentOfNitricOxideCategory = 'referenceTables.contentOfNitricOxideCategory',
  referenceTablesContentOfNitricOxideType = 'referenceTables.contentOfNitricOxideType',
  referenceTablesContentOfNitricOxideValueN2oNo = 'referenceTables.contentOfNitricOxideValueN2oNo',
  referenceTablesContentOfNitricOxideUnitN2oNo = 'referenceTables.contentOfNitricOxideUnitN2oNo',
  referenceTablesContentOfNitricOxideValueN2 = 'referenceTables.contentOfNitricOxideValueN2',
  referenceTablesContentOfNitricOxideUnitN2 = 'referenceTables.contentOfNitricOxideUnitN2',
  referenceTablesPigHousingEmissionFactors = 'referenceTables.PigHousingEmissionFactors',
  referenceTablesPigHousingEmissionFactorsCategory = 'referenceTables.PigHousingEmissionFactorsCategory',
  referenceTablesPigHousingEmissionFactorsSystem = 'referenceTables.PigHousingEmissionFactorsSystem',
  referenceTablesPigHousingEmissionFactorsYearValue = 'referenceTables.PigHousingEmissionFactorsYearValue',
  referenceTablesPigHousingEmissionFactorsEmissionValue = 'referenceTables.PigHousingEmissionFactorsEmissionValue',
  referenceTablesPigHousingEmissionFactorsUnit = 'referenceTables.PigHousingEmissionFactorsUnit',
  referenceTablesElectricityConsumptionRates = 'referenceTables.ElectricityConsumptionRates',
  referenceTablesElectricityConsumptionRatesPeopleQuantity = 'referenceTables.ElectricityConsumptionRatesPeopleQuantity',
  referenceTablesElectricityConsumptionRatesConsumption = 'referenceTables.ElectricityConsumptionRatesConsumption',
  referenceTablesGasConsumptionRates = 'referenceTables.gasConsumptionRates',
  referenceTablesGasConsumptionRatesHouseType = 'referenceTables.gasConsumptionRatesHouseType',
  referenceTablesGasConsumptionRatesGasConsumption = 'referenceTables.gasConsumptionRatesGasConsumption',
  referenceTablesWaterConsumptionRates = 'referenceTables.waterConsumptionRates',
  referenceTablesWaterConsumptionRatesHousehold = 'referenceTables.waterConsumptionRatesHousehold',
  referenceTablesWaterConsumptionRatesConsumption = 'referenceTables.waterConsumptionRatesConsumption',
  referenceTablesRavCodes = 'referenceTables.ravCodes',
  referenceTablesRavCodesAnimalCategory = 'referenceTables.ravCodesAnimalCategory',
  referenceTablesRavCodesRavCode = 'referenceTables.ravCodesRavCode',
  referenceTablesRavCodesAnimalDescriptionNl = 'referenceTables.ravCodesAnimalDescriptionNl',
  referenceTablesRavCodesAnimalDescriptionEn = 'referenceTables.ravCodesAnimalDescriptionEn',
  referenceTablesRavCodesAnimalEnclosureCategory = 'referenceTables.ravCodesAnimalEnclosureCategory',
  referenceTablesRavCodesAmmonia = 'referenceTables.ravCodesAmmonia',
  referenceTablesRavCodesAmmoniaUnit = 'referenceTables.ravCodesAmmoniaUnit',
  referenceTablesGwp100Factors = 'referenceTables.gwp100Factors',
  referenceTablesGwp100FactorsGreenhouseGas = 'referenceTables.gwp100FactorsGreenhouseGas',
  referenceTablesGwp100FactorsGwp = 'referenceTables.gwp100FactorsGwp',
  referenceTablesGwp100FactorsGwpUnit = 'referenceTables.gwp100FactorsGwpUnit',
  referenceTablesWurMfcEfem = 'referenceTables.wurMfcEfem',
  referenceTablesWurMfcEfemSystem = 'referenceTables.wurMfcEfemSystem',
  referenceTablesWurMfcEfemDurationDung = 'referenceTables.wurMfcEfemDurationDung',
  referenceTablesWurMfcEfemMfc = 'referenceTables.wurMfcEfemMfc',
  referenceTablesCl550 = 'referenceTables.cl550',
  referenceTablesCl550MainGroupCode = 'referenceTables.cl500MainGroupCode',
  referenceTablesCl550SubGroupCode = 'referenceTables.cl550SubGroupCode',
  referenceTablesCl550CodeDetails = 'referenceTables.cl550CodeDetails',
  referenceTablesCl550MainGroupName = 'referenceTables.cl550MainGroupName',
  referenceTablesCl550SubgroupName = 'referenceTables.cl550SubgroupName',
  referenceTablesCl550Detailing = 'referenceTables.cl550Detailing',
  referenceTablesCl550English = 'referenceTables.cl550English',
  referenceTablesCl550German = 'referenceTables.cl550German',
  referenceTablesCl550CodeCondition = 'referenceTables.cl550CodeCondition',
  referenceTablesCl550Edi = 'referenceTables.cl550Edi',
  referenceTablesCl550Dgr = 'referenceTables.cl550Dgr',
  referenceTablesCl550DiseaseAndTreatment = 'referenceTables.cl550DiseaseAndTreatment',
  referenceTablesCl550Failure = 'referenceTables.cl550Failure',
  referenceTablesCl550Disposal = 'referenceTables.cl550Disposal',
  referenceTablesCl550Selection = 'referenceTables.cl550Selection',
  referenceTablesCl550LabResult = 'referenceTables.cl550LabResult',
  referenceTablesCl550Section = 'referenceTables.cl550Section',
  referenceTablesCl550SlaughterhouseData = 'referenceTables.cl550SlaughterhouseData',
  referenceTablesCl550Agility = 'referenceTables.cl550Agility',
  referenceTablesCl550Coverage = 'referenceTables.cl550Coverage',
  referenceTablesCl550Birth = 'referenceTables.cl550Birth',
  referenceTablesCl550SuckingPiglet = 'referenceTables.cl550SuckingPiglet',
  referenceTablesCl550WeanedBig = 'referenceTables.cl550WeanedBig',
  referenceTablesCl550RearingSowAndBoar = 'referenceTables.cl550RearingSowAndBoar',
  referenceTablesCl550GoodSow = 'referenceTables.cl550GoodSow',
  referenceTablesCl550GestationSow = 'referenceTables.cl550GestationSow',
  referenceTablesCl550LactatingSow = 'referenceTables.cl550LactatingSow',
  referenceTablesCl550Beer = 'referenceTables.cl550Beer',
  referenceTablesCl550Finisher = 'referenceTables.cl550Finisher',
  referenceTablesCl550Comment = 'referenceTables.cl550Comment',
  referenceTablesCl550Added = 'referenceTables.cl550Added',
  referenceTablesCl550Deleted = 'referenceTables.cl550Deleted',
  referenceTablesCl649 = 'referenceTables.cl649',
  referenceTablesCl649Code = 'referenceTables.cl649Code',
  referenceTablesCl649Description = 'referenceTables.cl649Description',
  referenceTablesCl649Remarks = 'referenceTables.cl649Remarks',
  referenceTablesCl650 = 'referenceTables.cl650',
  referenceTablesCl650Code = 'referenceTables.cl650Code',
  referenceTablesCl650Description = 'referenceTables.cl650Description',
  referenceTablesCl650Note = 'referenceTables.cl650Note',
  referenceTablesCl607 = 'referenceTables.cl550Cl607',
  referenceTablesCl607Code = 'referenceTables.cl550Cl607Code',
  referenceTablesCl607Description = 'referenceTables.cl550Cl607Description',
  referenceTablesCl607Remarks = 'referenceTables.cl550Cl607Remarks',
  referenceTablesSuppliersNumbers = 'referenceTables.suppliersNumbers',
  referenceTablesSuppliersNumbersCodeList = 'referenceTables.codeList',
  referenceTablesSuppliersNumbersSupplierNumber = 'referenceTables.supplierNumber',
  referenceTablesSuppliersNumbersFeedSupplierName = 'referenceTables.feedSupplierName',
  referenceTablesSuppliersNumbersEntryDate = 'referenceTables.entryDate',
  referenceTablesSuppliersNumbersDischargeDate = 'referenceTables.dischargeDate',
  referenceTablesSuppliersNumbersRemark = 'referenceTables.remark',
  referenceTablesCountryCodes = 'referenceTables.countryCodes',
  referenceTablesCountryCodesIso31661Code = 'referenceTables.countryCodesIso31661Code',
  referenceTablesCountryCodesSubdivisionName = 'referenceTables.countryCodesSubdivisionName',
  referenceTablesCountryCodesIso31662Code = 'referenceTables.countryCodesIso31662Code',
  referenceTablesCountryCodesCountryName = 'referenceTables.countryCodesCountryName',
  referenceTablesStatusDescription = 'referenceTables.statusDescription',
  referenceTablesStatusAlertTitle = 'referenceTables.statusAlertTitle',
  referenceTablesRavReferenceList = 'referenceTables.ravReferenceList',
  referenceTablesRavReferenceListAnimalCategory = 'referenceTables.ravReferenceListAnimalCategory',
  referenceTablesRavReferenceListDescription = 'referenceTables.ravReferenceListDescription',
  referenceTablesRavReferenceListEfem = 'referenceTables.ravReferenceListEfem',
  referenceTablesRavReferenceListHousingType = 'referenceTables.ravReferenceListHousingType',
  referenceTablesAllocationManure = 'referenceTables.allocationManure',
  referenceTablesAllocationManureAnimalCategory = 'referenceTables.allocationManureAnimalCategory',
  referenceTablesAllocationManureManure = 'referenceTables.allocationManureManure',
  referenceTablesAllocationManureGas = 'referenceTables.allocationManureGas',
  referenceTablesAllocationManureElectricity = 'referenceTables.allocationManureElectricity',
  referenceTablesAllocationManureWater = 'referenceTables.allocationManureWater',
  referenceTablesGreenhouseGasEmission = 'referenceTables.greenhouseGasEmission',
  referenceTablesGreenhouseGasEmissionCountry = 'referenceTables.greenhouseGasEmissionCountry',
  referenceTablesGreenhouseGasEmissionLocation = 'referenceTables.greenhouseGasEmissionLocation',
  referenceTablesGreenhouseGasEmissionCategory = 'referenceTables.greenhouseGasEmissionCategory',
  referenceTablesGreenhouseGasEmissionAccount = 'referenceTables.greenhouseGasEmissionAccount',
  referenceTablesGreenhouseGasEmissionName = 'referenceTables.greenhouseGasEmissionName',
  referenceTablesGreenhouseGasEmissionEmissionFactor = 'referenceTables.greenhouseGasEmissionEmissionFactor',
  referenceTablesGreenhouseGasEmissionUnit = 'referenceTables.greenhouseGasEmissionUnit',
  referenceTablesGreenhouseGasEmissionConversionFactor = 'referenceTables.greenhouseGasEmissionConversionFactor',
  referenceTablesGreenhouseGasEmissionFormulaCf = 'referenceTables.greenhouseGasEmissionFormulaCf',
  referenceTablesGreenhouseGasEmissionExtraExternalId = 'referenceTables.greenhouseGasEmissionExtraExternalId',
  referenceTablesGreenhouseGasEmissionInputUnit = 'referenceTables.greenhouseGasEmissionInputUnit',
  referenceTablesGreenhouseGasEmissionConversionFormula = 'referenceTables.greenhouseGasEmissionConversionFormula',
  referenceTablesGreenhouseGasEmissionEmissionFactors = 'referenceTables.greenhouseGasEmissionEmissionFactors',
  referenceTablesGreenhouseGasEmissionCo2Unit = 'referenceTables.greenhouseGasEmissionCo2Unit',

  // entities list
  referenceTablesEntitiesList = 'referenceTables.entitiesList',
  referenceTablesEntitiesListEntityId = 'referenceTables.entitiesListEntityId',
  referenceTablesEntitiesListEntityName = 'referenceTables.entitiesListEntityName',
  referenceTablesEntitiesListCountry = 'referenceTables.entitiesListCountry',
  referenceTablesEntitiesListBusinessUnit = 'referenceTables.entitiesListBusinessUnit',

  // farm management data

  farmManagementDataCompanyId = 'farmManagementData.companyId',
  farmManagementDataTimestamp = 'farmManagementData.timestamp',
  farmManagementDataGroupGroups = 'farmManagementData.groupGroups',
  farmManagementDataGroupsList = 'farmManagementData.groupsList',
  farmManagementDataFarmId = 'farmManagementData.farmId',
  farmManagementDataGroupId = 'farmManagementData.groupId',
  farmManagementDataGroupName = 'farmManagementData.groupName',
  farmManagementDataDateEnd = 'farmManagementData.dateEnd',
  farmManagementDataNotes = 'farmManagementData.notes',
  farmManagementDataBarnId = 'farmManagementData.barnId',
  farmManagementDataRoomId = 'farmManagementData.roomId',
  farmManagementDataPenId = 'farmManagementData.penId',
  farmManagementDataGroupDatetime = 'farmManagementData.groupDatetime',
  farmManagementDataFirstDeliveryGrowthExp = 'farmManagementData.firstDeliveryGrowthExp',
  farmManagementDataLastDeliveryGrowthExp = 'farmManagementData.lastDeliveryGrowthExp',
  farmManagementDataFirstDeliveryPer = 'farmManagementData.firstDeliveryPer',
  farmManagementDataLastDeliveryPerc = 'farmManagementData.lastDeliveryPerc',
  farmManagementDataFirstDeliveryWeight = 'farmManagementData.firstDeliveryWeight',
  farmManagementDataLastDeliveryWeight = 'farmManagementData.lastDeliveryWeight',
  farmManagementDataAbout = 'farmManagementData.about',
  farmManagementDataId = 'farmManagementData.systemId',
  farmManagementDataUploadType = 'farmManagementData.uploadType',
  farmManagementDataFileName = 'farmManagementData.fileName',
  farmManagementDataSource = 'farmManagementData.source',
  farmManagementDataCategory = 'farmManagementData.category',
  farmManagementDataCreatedAt = 'farmManagementData.createdAt',
  farmManagementDataGroupFinancialEvents = 'farmManagementData.groupFinancialEvents',
  farmManagementDataFinEventId = 'farmManagementData.finEventId',
  farmManagementDataEventTypeId = 'farmManagementData.eventTypeId',
  farmManagementDataEventDate = 'farmManagementData.eventDate',
  farmManagementDataContactId = 'farmManagementData.contactId',
  farmManagementDataAmountExVat = 'farmManagementData.amountExVat',
  farmManagementDataAmountVat = 'farmManagementData.amountVat',
  farmManagementDataAmountInVat = 'farmManagementData.amountInVat',
  farmManagementDataInvoiceNumber = 'farmManagementData.invoiceNumber',
  farmManagementDataInvoceDate = 'farmManagementData.invoceDate',
  farmManagementDataFinancialNotes = 'farmManagementData.financialNotes',
  farmManagementDataValve = 'farmManagementData.valve',
  farmManagementDataInputEventId = 'farmManagementData.inputEventId',
  farmManagementDataImportId = 'farmManagementData.importId',
  farmManagementDataInputWarnings = 'farmManagementData.inputWarnings',
  farmManagementDataInputTypeStamp = 'farmManagementData.inputTypeStamp',
  farmManagementDataScannedIds = 'farmManagementData.scannedIds',
  farmManagementDataUserId = 'farmManagementData.userId',
  farmManagementDataBatchNumberProduction = 'farmManagementData.batchNumberProduction',
  farmManagementDataBatchNumberDelivery = 'farmManagementData.batchNumberDelivery',
  farmManagementDataGroupWeights = 'farmManagementData.groupWeights',
  farmManagementDataWeightsList = 'farmManagementData.weightsList',
  farmManagementDataNumber = 'farmManagementData.number',
  farmManagementDataTotalWeight = 'farmManagementData.totalWeight',
  farmManagementDataGroupRemovals = 'farmManagementData.groupRemovals',
  farmManagementDataRemovalList = 'farmManagementData.removalList',
  farmManagementDataAlive = 'farmManagementData.alive',
  farmManagementDataWeightSlaughtered = 'farmManagementData.weightSlaughtered',
  farmManagementDataWeightAlive = 'farmManagementData.weightAlive',
  farmManagementDataFirstReamson = 'farmManagementData.firstReamson',
  farmManagementDataSecondReamson = 'farmManagementData.secondReamson',
  farmManagementDataRemtypeId = 'farmManagementData.remtypeId',
  farmManagementDataAnimalCategoryId = 'farmManagementData.animalCategoryId',
  farmManagementDataWcfId = 'farmManagementData.wcfId',
  farmManagementDataNotClassified = 'farmManagementData.notClassified',
  farmManagementDataClassType = 'farmManagementData.classType',
  farmManagementDataNlMeatPerc = 'farmManagementData.nlMeatPerc',
  farmManagementDataNlAa = 'farmManagementData.nlAa',
  farmManagementDataNlA = 'farmManagementData.nlA',
  farmManagementDataNlB = 'farmManagementData.nlB',
  farmManagementDataNlC = 'farmManagementData.nlC',
  farmManagementDataNlBackFat = 'farmManagementData.nlBackFat',
  farmManagementDataNlLoInDepth = 'farmManagementData.nlLoInDepth',
  farmManagementDataDeLachs = 'farmManagementData.deLachs',
  farmManagementDataDeSchinken = 'farmManagementData.deSchinken',
  farmManagementDataDeSchaulter = 'farmManagementData.deSchaulter',
  farmManagementDataDeIndexPunkte = 'farmManagementData.deIndexPunkte',
  farmManagementDataDeIndexFactor = 'farmManagementData.deIndexFactor',
  farmManagementDataEstimatedDateIn = 'farmManagementData.estimatedDateIn',
  farmManagementDataSexId = 'farmManagementData.sexId',
  farmManagementDataWithoutMale = 'farmManagementData.withoutMale',
  farmManagementDataWithoutFemale = 'farmManagementData.withoutFemale',
  farmManagementDataWithoutMaleCastrated = 'farmManagementData.withoutMaleCastrated',
  farmManagementDataCarPleuritis = 'farmManagementData.carPleuritis',
  farmManagementDataCarInfSkin = 'farmManagementData.carInfSkin',
  farmManagementDataCarInfFeed = 'farmManagementData.CarInfFeed',
  farmManagementDataCarPleuritisInfFeed = 'farmManagementData.carPleuritisInfFeed',
  farmManagementDataCarInfSkinAndFeed = 'farmManagementData.carInfSkinAndFeed',
  farmManagementDataCarPleuritisInfSkin = 'farmManagementData.carPleuritisInfSkin',
  farmManagementDataCarPleuritisInfSkinAndFeed = 'farmManagementData.carPleuritisInfSkinAndFeed',
  farmManagementDataOrganAffLiver = 'farmManagementData.organAffLiver',
  farmManagementDataOrganRejLiver = 'farmManagementData.organRejLiver',
  farmManagementDataOrganAffLungs = 'farmManagementData.organAffLungs',
  farmManagementDataOrganAffLungsAndLiver = 'farmManagementData.organAffLungsAndLiver',
  farmManagementDataOrganAffLungsRejLiver = 'farmManagementData.organAffLungsRejLiver',
  farmManagementDataQualId = 'farmManagementData.qualId',
  farmManagementDataGroupsArrival = 'farmManagementData.groupsArrival',
  farmManagementDataArrivalList = 'farmManagementData.arrivalList',
  farmManagementDataFinancialEventId = 'farmManagementData.financialEventId',
  farmManagementDataGroupArrivalNumber = 'farmManagementData.groupArrivalNumber',
  farmManagementDataOriginId = 'farmManagementData.originId',
  farmManagementDataGroupArrivalDatetime = 'farmManagementData.groupArrivalDatetime',
  farmManagementDataOwnFarm = 'farmManagementData.ownFarm',
  farmManagementDataGroupMoves = 'farmManagementData.groupMoves',
  farmManagementDataGroupFrom = 'farmManagementData.groupFrom',
  farmManagementDataBarnFrom = 'farmManagementData.barnFrom',
  farmManagementDataRoomFrom = 'farmManagementData.roomFrom',
  farmManagementDataPenFrom = 'farmManagementData.penFrom',
  farmManagementDataAnimalCatIdFrom = 'farmManagementData.animalCatIdFrom',
  farmManagementDataWeight = 'farmManagementData.weight',
  farmManagementDataAnimalCatIdTo = 'farmManagementData.groupFrom',
  farmManagementDataAdminDate = 'farmManagementData.groupFrom',
  farmManagementDataRemReason = 'farmManagementData.remReason',
  farmManagementDataCodesAnimalRemarks = 'farmManagementData.codesAnimalRemarks',
  farmManagementDataAnimalRemarksList = 'farmManagementData.animalRemarksList',
  farmManagementDataAnimalRemarkId = 'farmManagementData.animalRemarkId',
  farmManagementDataAbbreviation = 'farmManagementData.abbreviation',
  farmManagementDataDescription = 'farmManagementData.description',
  farmManagementDataNote = 'farmManagementData.note',
  farmManagementDataCode = 'farmManagementData.code',
  farmManagementDataCodesDefects = 'farmManagementData.codesDefects',
  farmManagementDataDefectsList = 'farmManagementData.defectsList',
  farmManagementDataDefectId = 'farmManagementData.defectId',
  farmManagementDataWorkList = 'farmManagementData.workLis',
  farmManagementDataCodesDiagnose = 'farmManagementData.codesDiagnose',
  farmManagementDataDiagnosesList = 'farmManagementData.diagnosesList',
  farmManagementDataDiagnoseId = 'farmManagementData.diagnoseId',
  farmManagementDataCodesAnimalScore = 'farmManagementData.codesAnimalScore',
  farmManagementDataAnimalScoreList = 'farmManagementData.animalScoreList',
  farmManagementDataAnimalScoreId = 'farmManagementData.animalScoreId',
  farmManagementDataCodeGroup = 'farmManagementData.codeGroup',
  farmManagementDataScoreEnd = 'farmManagementData.scoreEnd',
  farmManagementDataSows = 'farmManagementData.sows',
  farmManagementDataBoars = 'farmManagementData.boars',
  farmManagementDataWeaners = 'farmManagementData.weaners',
  farmManagementDataSucklers = 'farmManagementData.sucklers',
  farmManagementDataReplstock = 'farmManagementData.replstock',
  farmManagementDataFinishers = 'farmManagementData.finishers',
  farmManagementDataCodesInsemination = 'farmManagementData.codesInsemination',
  farmManagementDataInseminationList = 'farmManagementData.inseminationList',
  farmManagementDataInseminationId = 'farmManagementData.inseminationId',
  farmManagementDataCodesTreatments = 'farmManagementData.codesTreatments',
  farmManagementDataTreatmentsList = 'farmManagementData.treatmentsList',
  farmManagementDataTreatmentId = 'farmManagementData.treatmentId',
  farmManagementDataRegistrationNumber = 'farmManagementData.registrationNumber',
  farmManagementDataWhPeriod = 'farmManagementData.whPeriod',
  farmManagementDataDestination = 'farmManagementData.destination',
  farmManagementDataTreatmentsPerDay = 'farmManagementData.treatmentPerDay',
  farmManagementDataFirstRepeatTreatment = 'farmManagementData.firstRepeatTreatment',
  farmManagementDataSecondRepeatTreatment = 'farmManagementData.secondRepeatTreatment',
  farmManagementDataThirdRepeatTreatment = 'farmManagementData.thirdRepeatTreatment',
  farmManagementDataFourthRepeatTreatment = 'farmManagementData.fourthRepeatTreatment',
  farmManagementDataFifthRepeatTreatment = 'farmManagementData.fifthRepeatTreatment',
  farmManagementDataGroupPurchasedFood = 'farmManagementData.groupPurchasedFood',
  farmManagementDataPurchasedFoodList = 'farmManagementData.purchasedFoodList',
  farmManagementDataGilts = 'farmManagementData.gilts',
  farmManagementDataEv = 'farmManagementData.eV',
  farmManagementDataDm = 'farmManagementData.dM',
  farmManagementDataAcctId = 'farmManagementData.acctId',
  farmManagementDataGroupUsedFood = 'farmManagementData.groupUsedFood',
  farmManagementDataUsedFoodList = 'farmManagementData.usedFoodList',
  farmManagementDataCodesMatingType = 'farmManagementData.codesMatingType',
  farmManagementDataMatingTypeList = 'farmManagementData.matingTypeList',
  farmManagementDataMatingTypeId = 'farmManagementData.matingTypeId',
  farmManagementDataCodesWeightType = 'farmManagementData.codesWeightType',
  farmManagementDataWeightTypeList = 'farmManagementData.weightTypeList',
  farmManagementDataWeightTypeId = 'farmManagementData.weightTypeId',
  farmManagementDataCodesRemovalType = 'farmManagementData.codesRemovalType',
  farmManagementDataRemovalTypeList = 'farmManagementData.removalTypeList',
  farmManagementDataRemovalTypeId = 'farmManagementData.removalTypeId',
  farmManagementDataCodesAnimalIdentityType = 'farmManagementData.codesAnimalIdentityType',
  farmManagementDataAnimalIdentityTypeList = 'farmManagementData.animalIdentityTypeList',
  farmManagementDataIdForTypeIdentifier = 'farmManagementData.idForTypeIdentifier',
  farmManagementDataUniqueIdentifier = 'farmManagementData.uniqueIdentifier',
  farmManagementDataTransponder = 'farmManagementData.transponder',
  farmManagementDataCodesFarmOption = 'farmManagementData.codesFarmOption',
  farmManagementDataFarmOptionList = 'farmManagementData.farmOptionList',
  farmManagementDataFarmOptionId = 'farmManagementData.farmOptionId',
  farmManagementDataValue = 'farmManagementData.value',
  farmManagementDataCodesBarn = 'farmManagementData.codesBarn',
  farmManagementDataBarnList = 'farmManagementData.barnList',
  farmManagementDataCorrectionFin = 'farmManagementData.correctionFin',
  farmManagementDataIsEarlyWeanUnit = 'farmManagementData.isEarlyWeanUnit',
  farmManagementDataSurface = 'farmManagementData.surface',
  farmManagementDataVvoNumber = 'farmManagementData.vvoNumber',
  farmManagementDataEuApprovalNumber = 'farmManagementData.euApprovalNumber',
  farmManagementDataCorrectionWeaners = 'farmManagementData.correctionWeaners',
  farmManagementDataCorrectionReplGilts = 'farmManagementData.correctionReplGilts',
  farmManagementDataCodesRoom = 'farmManagementData.codesRoom',
  farmManagementDataRoomList = 'farmManagementData.roomList',
  farmManagementDataRoomTypeId = 'farmManagementData.roomTypeId',
  farmManagementDataColor = 'farmManagementData.color',
  farmManagementDataCodesPen = 'farmManagementData.codesPen',
  farmManagementDataPenList = 'farmManagementData.penList',
  farmManagementDataFirstValue = 'farmManagementData.firstValue',
  farmManagementDataSecondValue = 'farmManagementData.secondValue',
  farmManagementDataNumberPlaces = 'farmManagementData.numberPlaces',
  farmManagementDataUbn = 'farmManagementData.ubn',
  farmManagementDataKvk = 'farmManagementData.kvk',
  farmManagementDataKvkName = 'farmManagementData.kvkName',
  farmManagementDataCodesAccount = 'farmManagementData.codesAccount',
  farmManagementDataAccountList = 'farmManagementData.accountList',
  farmManagementDataPercentSows = 'farmManagementData.percentSows',
  farmManagementDataPercentWeaners = 'farmManagementData.percentWeaners',
  farmManagementDataPercentFinishers = 'farmManagementData.percentFinishers',
  farmManagementDataPercentReplGilts = 'farmManagementData.percentReplGilts',
  farmManagementDataTypeId = 'farmManagementData.typeId',
  farmManagementDataActive = 'farmManagementData.active',
  farmManagementDataEvType = 'farmManagementData.evType',
  farmManagementDataVatId = 'farmManagementData.vatId',
  farmManagementDataBarn = 'farmManagementData.barn',
  farmManagementDataMedicine = 'farmManagementData.medicine',
  farmManagementDataInoculation = 'farmManagementData.inoculation',
  farmManagementDataHormone = 'farmManagementData.hormone',
  farmManagementDataMedicineEanCode = 'farmManagementData.medicineEanCode',
  farmManagementDataMedicineCountryCode = 'farmManagementData.medicineCountryCode',
  farmManagementDataMedicineOfficialName = 'farmManagementData.medicineOfficialName',
  farmManagementDataMedicineDateEnd = 'farmManagementData.medicineDateEnd',
  farmManagementDataMedicineUnit = 'farmManagementData.medicineUnit',
  farmManagementDataMedicineWhPeriod = 'farmManagementData.medicineWhPeriod',
  farmManagementDataMedicineDosage = 'farmManagementData.medicineDosage',
  farmManagementDataMedicineDosageFactor = 'farmManagementData.medicineDosageFactor',
  farmManagementDataSupplierId = 'farmManagementData.supplierId',
  farmManagementDataMedicineEmbCode = 'farmManagementData.medicineEmbCode',
  farmManagementDataCodesEventTypes = 'farmManagementData.codesEventTypes',
  farmManagementDataEventTypesList = 'farmManagementData.eventTypesList',
  farmManagementDataShortcut = 'farmManagementData.shortcut',
  farmManagementDataGroupExpense = 'farmManagementData.groupExpense',
  farmManagementDataExpenseList = 'farmManagementData.expenseList',
  farmManagementDataPeriodFrom = 'farmManagementData.periodFrom',
  farmManagementDataPeriodTo = 'farmManagementData.periodTo',

  // table

  tableBodyEmptyDataSourceMessage = 'table.body.emptyDataSourceMessage',
  tableBodyAddTooltip = 'table.body.addTooltip',
  tableBodyDeleteTooltip = 'table.body.deleteTooltip',
  tableBodyEditTooltip = 'table.body.editTooltip',
  tableBodyFilterRowFilterTooltip = 'table.body.filterRow.filterTooltip',
  tableBodyEditRowDeleteText = 'table.body.editRow.deleteText',
  tableBodyEditRowCancelTooltip = 'table.body.editRow.cancelTooltip',
  tableBodyEditRowSaveTooltip = 'table.body.editRow.saveTooltip',
  tableGroupingPlaceholder = 'table.grouping.placeholder',
  tableGroupingGroupedBy = 'table.grouping.groupedBy',
  tableHeaderActions = 'table.header.actions',
  tablePaginationLabelDisplayedRows = 'table.pagination.labelDisplayedRows',
  tablePaginationLabelRowsSelect = 'table.pagination.labelRowsSelect',
  tablePaginationLabelRowsPerPage = 'table.pagination.labelRowsPerPage',
  tablePaginationFirstAriaLabel = 'table.pagination.firstAriaLabel',
  tablePaginationFirstTooltip = 'table.pagination.firstTooltip',
  tablePaginationPreviousAriaLabel = 'table.pagination.previousAriaLabel',
  tablePaginationPreviousTooltip = 'table.pagination.previousTooltip',
  tablePaginationNextAriaLabel = 'table.pagination.nextAriaLabel',
  tablePaginationNextTooltip = 'table.pagination.nextTooltip',
  tablePaginationLastAriaLabel = 'table.pagination.lastAriaLabel',
  tablePaginationLastTooltip = 'table.pagination.lastTooltip',
  tableToolbarAddRemoveColumns = 'table.toolbar.addRemoveColumns',
  tableToolbarNRowsSelected = 'table.toolbar.nRowsSelected',
  tableToolbarShowColumnsTitle = 'table.toolbar.showColumnsTitle',
  tableToolbarShowColumnsAriaLabel = 'table.toolbar.showColumnsAriaLabel',
  tableToolbarExportTitle = 'table.toolbar.exportTitle',
  tableToolbarExportAriaLabel = 'table.toolbar.exportAriaLabel',
  tableToolbarExportName = 'table.toolbar.exportName',
  tableToolbarSearchTooltip = 'table.toolbar.searchTooltip',
  tableToolbarSearchPlaceholder = 'table.toolbar.searchPlaceholder',

  // farm management passports

  farmManagementPassportsAddCompanyId = 'farmManagementPassports.addCompanyId',
  farmManagementPassportsAddFarmId = 'farmManagementPassports.addFarmId',
  farmManagementPassportsRemoveAlert = 'farmManagementPassports.removeAlert',
  farmManagementPassportsRemoved = 'farmManagementPassports.removed',
  farmManagementPassportsSaved = 'farmManagementPassports.saved',

  // aliases

  aliasesDataset = 'aliases.dataset',
  aliasesAlias = 'aliases.alias',
  aliasesOtherSystem = 'aliases.otherSystem',
  aliasesPrimaryDatapoint = 'aliases.primaryDatapoint',
  aliasesPrimaryUbn = 'aliases.primaryUbn',
  aliasesFtpManualUpload = 'aliases.ftpManualUpload',
  aliasesEdiCircle = 'aliases.ediCircle',
  aliasesJoinData = 'aliases.joinData',
  aliasesUserPassportId = 'aliases.userPassportId',
  aliasesPassportAliasId = 'aliases.passportAliasId',
  aliasesToDelete = 'aliases.toDelete',
  aliasesDatasetName = 'aliases.datasetName',
  aliasesUploadSource = 'aliases.uploadSource',
  aliasesNotes = 'aliases.notes',
  aliasRemoveAlert = 'alias.removeAlert',
  aliasRemoved = 'alias.removed',
  aliasSaved = 'alias.saved',

  // labels

  labelsNote = 'labels.note',

  // service

  serviceCreatedAt = 'service.createdAt',
  serviceUpdatedAt = 'service.updatedAt',
  serviceUpdatedBy = 'service.updatedBy',
  serviceUploadedAt = 'service.uploadedAt',
  serviceUploadedBy = 'service.uploadedBy',
  serviceIsEdited = 'service.isEdited',
  serviceLink = 'service.link',
  serviceSource = 'service.source',
  serviceChecksum = 'service.checksum',
  serviceId = 'service.id',
  serviceBlockchainHashKey = 'service.blockchainHashKey',
  serviceBlockchainTimestamp = 'service.blockchainTimestamp',
  serviceBlockchainId = 'service.blockchainId',
  serviceBlockchainMessageId = 'service.blockchainMessageId',
  serviceTableMenu = 'service.tableMenu',
  serviceCheckbox = 'service.checkbox',
  serviceLastUpdateAt = 'service.lastUpdateAt',
  serviceLastUpdateBy = 'service.lastUpdateBy',

  // auth

  authSignInPassword = 'auth.signIn.password',
  authSignInLogIn = 'auth.signIn.logIn',
  authSignInForgotPassword = 'auth.signIn.forgotPassword',
  authSignInClickHere = 'auth.signIn.clickHere',
  // prettier-ignore
  authSignInResetPasswordMessage = 'auth.signIn.resetPasswordMessage',
  authSignInSendLink = 'auth.signIn.sendLink',
  // prettier-ignore
  authSignInLinkSentMessage = 'auth.signIn.linkSentMessage',
  authPasswordEnterNewPassword = 'auth.password.enterNewPassword',
  // prettier-ignore
  authPasswordNewPasswordHelpMessage = 'auth.password.newPasswordHelpMessage',
  authPasswordRestoreExpired = 'auth.password.restoreExpired',
  authPasswordSuccessTitle = 'auth.password.successTitle',
  authPasswordSuccessMessage = 'auth.password.successMessage',
  authPasswordRepeatPassword = 'auth.password.repeatPassword',
  authPasswordUpdatePassword = 'auth.password.updatePassword',
  authPasswordOldPassword = 'auth.password.oldPassword',
  authSessionExpired = 'auth.session.expired',

  serverMaintenance = 'maintenance.serverMaintenance',
  maintenanceDescr = 'maintenance.descr',
  maintenanceTryAgain = 'maintenance.tryAgain',

  // form validation

  validationRequired = 'validation.required',
  validationInvalidEmail = 'validation.invalidEmail',
  validationPasswordLength = 'validation.passwordLength',
  validationPasswordsMatch = 'validation.passwordsMatch',
  validationRequiredField = 'validation.requiredField',
  validationRequiredAtLeastOneField = 'validation.requiredAtLeastOneField',
  validationShouldBePositive = 'validation.shouldBePositive',
  validationShouldBeInRangeBetween = 'validation.shouldBeInRangeBetween',
  validationShouldBeZeroOrPositive = 'validation.shouldBeZeroOrPositive',
  notAvailableAnymore = 'validation.notAvailableAnymore',

  // statuses

  statusActive = 'status.active',
  statusInactive = 'status.inactive',
  statusPending = 'status.pending',
  statusRefused = 'status.refused',
  statusRevoked = 'status.revoked',
  statusGranted = 'status.granted',
  statusExpired = 'status.expired',

  // carbon footprint (co2)
  carbonFootprintCo2Totals = 'carbonFootprint.Co2Totals',
  carbonFootprintUbn = 'carbonFootprint.ubn',
  carbonFootprintKvk = 'carbonFootprint.kvk',
  carbonFootprintKvkName = 'carbonFootprint.kvkName',
  carbonFootprintCompanyType = 'carbonFootprint.companyType',
  carbonFootprintCultivationAndProductionOfFeedCo2Fattener = 'carbonFootprint.cultivationAndProductionOfFeedCo2Fattener',
  carbonFootprintTransportOfFeedCo2Fattener = 'carbonFootprint.transportOfFeedCo2Fattener',
  carbonFootprintManureStorageOfFeedCo2Fattener = 'carbonFootprint.manureStorageOfFeedCo2Fattener',
  carbonFootprintManureTransportOfFeedCo2Fattener = 'carbonFootprint.manureTransportOfFeedCo2Fattener',
  carbonFootprintEntericFermentationCo2Fattener = 'carbonFootprint.entericFermentationCo2Fattener',
  carbonFootprintEnergyUseCo2Fattener = 'carbonFootprint.energyUseCo2Fattener',
  carbonFootprintSowsCo2 = 'carbonFootprint.sowsCo2',
  carbonFootprintSowsValue = 'carbonFootprint.sowsValue',
  carbonFootprintPigletsCo2 = 'carbonFootprint.pigletsCo2',
  carbonFootprintPigletsValue = 'carbonFootprint.pigletsValue',
  carbonFootprintTransportPigletsCo2Fattener = 'carbonFootprint.transportPigletsCo2Fattener',
  carbonFootprintCultivationAndProductionOfFeedCo2Breeder = 'carbonFootprint.cultivationAndProductionOfFeedCo2Breeder',
  carbonFootprintTransportOfFeedCo2Breeder = 'carbonFootprint.transportOfFeedCo2Breeder',
  carbonFootprintManureStorageOfFeedCo2Breeder = 'carbonFootprint.manureStorageOfFeedCo2Breeder',
  carbonFootprintManureTransportOfFeedCo2Breeder = 'carbonFootprint.manureTransportOfFeedCo2Breeder',
  carbonFootprintEntericFermentationCo2Breeder = 'carbonFootprint.entericFermentationCo2Breeder',
  carbonFootprintEnergyUseCo2Breeder = 'carbonFootprint.energyUseCo2Breeder',
  carbonFootprintId = 'carbonFootprint.id',
  carbonFootprintStatus = 'carbonFootprint.status',
  carbonFootprintFileName = 'carbonFootprint.fileName',
  carbonFootprintValidDateFrom = 'carbonFootprint.validDateFrom',
  carbonFootprintValidDateUntil = 'carbonFootprint.validDateUntil',
  carbonFootprintNotes = 'carbonFootprint.notes',
  carbonFootprintsBreeding = 'carbonFootprints.breeding',
  carbonFootprintsFattening = 'carbonFootprints.fattening',
  carbonFootprintsEconomicSowsAndPiglets = 'carbonFootprints.economicSowsAndPiglets',
  carbonFootprintsValue = 'carbonFootprints.value',
  carbonFootprintsEconomicAllocation = 'carbonFootprints.economicAllocation',
  carbonFootprintsCalculation = 'carbonFootprints.calculation',
  carbonFootprintsCo2Eq = 'carbonFootprints.co2Eq',
  carbonFootprintsDistribution = 'carbonFootprints.distribution',
  carbonFootprintsConcept = 'carbonFootprints.concept',
  carbonFootprintsConceptAverageKg = 'carbonFootprints.conceptAverageKg',
  carbonFootprintsTotal = 'carbonFootprints.total',
  carbonFootprintsPerPiglet = 'carbonFootprints.perPiglet',
  carbonFootprintsCultivationAndProductionOfFeedBreeding = 'carbonFootprints.cultivationAndProductionOfFeedBreeding',
  carbonFootprintsTransportOfFeedBreeding = 'carbonFootprints.transportOfFeedBreeding',
  carbonFootprintsManureStorageBreeding = 'carbonFootprints.manureStorageBreeding',
  carbonFootprintsManureTransportBreeding = 'carbonFootprints.manureTransportBreeding',
  carbonFootprintsEntericFermentationBreeding = 'carbonFootprints.entericFermentationBreeding',
  carbonFootprintsEnergyUseBreeding = 'carbonFootprints.energyUseBreeding',
  carbonFootprintsSows = 'carbonFootprints.sows',
  carbonFootprintsPiglets = 'carbonFootprints.piglets',
  carbonFootprintsPigletsFattening = 'carbonFootprints.pigletsFattening',
  carbonFootprintsTransportPigletsFattening = 'carbonFootprintsTransport.pigletsFattening',
  carbonFootprintsCultivationAndProductionOfFeedFattening = 'carbonFootprints.cultivationAndProductionOfFeedFattening',
  carbonFootprintsTransportOfFeedFattening = 'carbonFootprints.transportOfFeedFattening',
  carbonFootprintsManureStorageFattening = 'carbonFootprints.manureStorageFattening',
  carbonFootprintsManureTransportFattening = 'carbonFootprints.manureTransportFattening',
  carbonFootprintsEntericFermentationFattening = 'carbonFootprints.entericFermentationFattening',
  carbonFootprintsEnergyUseFattening = 'carbonFootprints.energyUseFattening',
  carbonFootprintKgSlaughterWeight = 'carbonFootprint.kgSlaughterWeight',
  carbonFootprintsSowsTotal = 'carbonFootprints.sowsTotal',
  carbonFootprintsPigsTotal = 'carbonFootprints.pigsTotal',
  carbonFootprintsTotalCo2Eq = 'carbonFootprints.totalCo2Eq',
  carbonFootprintsSowsCo2Eq = 'carbonFootprints.sowsCo2Eq',
  carbonFootprintsPigsCo2Eq = 'carbonFootprints.pigsCo2Eq',
  carbonFootprintsTransportOfFeedHint = 'carbonFootprints.transportOfFeedHint',
  carbonFootprintsBreedingTotalCo2Eq = 'carbonFootprints.breedingTotalCo2Eq',

  // request log users
  requestLogUsersFullName = 'requestLogUsers.fullName',
  requestLogUsersEmail = 'requestLogUsers.emial',
  requestLogUsersLastLogin = 'requestLogUsers.lastLogin',
  requestLogUsersPrefLang = 'requestLogUsers.prefLang',
  requestLogUsersRequestCount = 'requestLogUsers.requestCount',
  requestLogUsersUserPassports = 'requestLogUsers.userPassports',
  requestLogUsersLabels = 'requestLogUsers.labels',
  requestLogUsersCarbonFootprintCount = 'requestLogUsers.carbonFootprintCount',
  requestLogUsersOrganizationCount = 'requestLogUsers.organizationCount',
  requestLogUsersParticipaionCount = 'requestLogUsers.participationCount',
  requestLogUsersAnimalPassportCount = 'requestLogUsers.animalPassportCount',
  requestLogUsersVriCalculatedCount = 'requestLogUsers.vriCalculatedCount',
  requestLogUsersVriCount = 'requestLogUsers.vriCount',
  requestLogUsersFarmerInputCount = 'requestLogUsers.farmerInputCount',
  requestLogUsersMyFarmCount = 'requestLogUsers.myFarmCount',
  requestLogUsersMyJoindataCount = 'requestLogUsers.myJoindataCount',
  requestLogUsersHfmOutputCount = 'requestLogUsers.hfmOutput',
  requestLogUsersVriComparisonToolCount = 'requestLogUsers.vriComparisonTool',

  // user admin updates log
  userAdminUpdatesLogCreatedBy = 'userAdminUpdatesLog.createdBy',
  userAdminUpdatesLogAction = 'userAdminUpdatesLog.action',
  userAdminUpdatesLogActionType = 'userAdminUpdatesLog.actionType',
  userAdminUpdatesLogLoggableType = 'userAdminUpdatesLog.loggableType',
  // update logs details
  detailsPropertyName = 'details.propertyName',
  detailsPreviousValue = 'details.previousValue',
  detailsNewValue = 'details.newValue',
  detailsShowMore = 'details.showMore',
  detailsFullName = 'details.full_name',
  detailsCompanies = 'details.companies',
  detailsUserPassports = 'details.user_passports',
  detailsPermissionProfile = 'details.permission_profile',
  detailsPrefLang = 'details.pref_lang',
  detailsColorTheme = 'details.color_theme',
  detailsIsContactPerson = 'details.is_contact_person',
  detailsStatus = 'details.status',
  detailsKvk = 'details.kvk',
  detailsKvkCompanyName = 'details.kvk_company_name',
  detailsEmail = 'details.email',
  detailsCustomKvkName = 'details.custom_kvk_name',
  detailsUbn = 'details.ubn',
  detailsGln = 'details.gln',
  detailsVionRelationNumber = 'details.vion_relation_number',
  detailsBreedingProgram = 'details.breeding_program',
  detailsCertificates = 'details.certificates',
  detailsCertificatesOther = 'details.certificates_other',
  detailsClimateSystemOther = 'details.climate_system_other',
  detailsClimateSystems = 'details.climate_systems',
  detailsConcepts = 'details.concepts',
  detailsCustomUbnName = 'details.custom_ubn_name',
  detailsFeedSupplierOther = 'details.feed_supplier_other',
  detailsFeedSuppliers = 'details.feed_suppliers',
  detailsFeedingSystemOther = 'details.feeding_system_other',
  detailsFeedingSystems = 'details.feeding_systems',
  detailsFmsModuleAgrisysts = 'details.fms_module_agrisysts',
  detailsFmsModuleAgroCoops = 'details.fms_module_agro_coops',
  detailsFmsModuleAgrovisions = 'details.fms_module_agrovisions',
  detailsFmsNames = 'details.fms_names',
  detailsGeneticSupplierOther = 'details.genetic_supplier_other',
  detailsGeneticSuppliers = 'details.genetic_suppliers',
  detailsKiStationOther = 'details.ki_station_other',
  detailsKiStations = 'details.ki_stations',
  detailsPhone = 'details.phone',
  detailsPlace = 'details.place',
  detailsPostcode = 'details.postcode',
  detailsSireLineOther = 'details.sire_line_other',
  detailsSireLines = 'details.sire_lines',
  detailsSowLines = 'details.sow_lines',
  detailsSowLineOther = 'details.sow_line_other',
  detailsStreet = 'details.street',
  detailsTypeOfCompany = 'details.type_of_company',
  detailsVatNr = 'details.vat_nr',
  detailsVeterinarian = 'details.veterinarian',
  detailsVeterinaryPractice = 'details.veterinary_practice',
  detailsVeterinaryPracticeOther = 'details.veterinary_practice_other',

  password = 'password',
  confirmPassword = 'confirmPassword',
  acceptInvite = 'acceptInvite',

  // raw materials origin
  rawMaterialsOriginSource = 'rawMaterialsOrigin.source',
  rawMaterialsOriginMessageId = 'rawMaterialsOrigin.messageId',
  rawMaterialsOriginSupplierNumber = 'rawMaterialsOrigin.supplierNumber',
  rawMaterialsOriginSupplierName = 'rawMaterialsOrigin.supplierName',
  rawMaterialsOriginCustomerNumber = 'rawMaterialsOrigin.customerNumber',
  rawMaterialsOriginCustomerName = 'rawMaterialsOrigin.customerName',
  rawMaterialsOriginUbn = 'rawMaterialsOrigin.ubn',
  rawMaterialsOriginBeginningTime = 'rawMaterialsOrigin.beginningTime',
  rawMaterialsOriginEndTime = 'rawMaterialsOrigin.endTime',
  rawMaterialsOriginAnimalCategoryCode = 'rawMaterialsOrigin.animalCategoryCode',
  rawMaterialsOriginSubAnimalCategoryCode = 'rawMaterialsOrigin.subAnimalcategoryCode',
  rawMaterialsOriginArticleNumber = 'rawMaterialsOrigin.articleNumber',
  rawMaterialsOriginArticleTypeNumberCode = 'rawMaterialsOrigin.articleTypeNumberCode',
  rawMaterialsOriginArticleDescription = 'rawMaterialsOrigin.supplierNumber',
  rawMaterialsOriginNetQuantityDeliveredProduct = 'rawMaterialsOrigin.netQuantityDeliveredProduct',
  rawMaterialsOriginQuantityUnit = 'rawMaterialsOrigin.quantityUnit',
  rawMaterialsOriginNutrientDs = 'rawMaterialsOrigin.nutrientDs',
  rawMaterialsOriginNutrientRe = 'rawMaterialsOrigin.nutrientRe',
  rawMaterialsOriginBreed = 'rawMaterialsOrigin.breed',
  rawMaterialsOriginNutrientVcre = 'rawMaterialsOrigin.nutrientVcre',
  rawMaterialsOriginNutrientVcos = 'rawMaterialsOrigin.nutrientVcos',
  rawMaterialsOriginRegionOfOriginCode = 'rawMaterialsOrigin.RegionOfOriginCode',
  rawMaterialsOriginCreatedAt = 'rawMaterialsOrigin.createdAt',

  // hfm output
  hfmOutputId = 'hfmOutput.id',
  hfmOutputTitle = 'hFMOutput.title',
  hfmOutputYear = 'hFMOutput.yearn',
  hfmOutputPeriod = 'hFMOutput.period',
  hfmOutputQuarter = 'hFMOutput.quarter',
  hfmOutputYearToDate = 'hFMOutput.yearToDate',
  hfmOutputEntityExternalId = 'hfmOutput.entityExternalId',
  hfmOutputAccountHfm = 'hfmOutput.accountHfm',
  hfmOutputExtraExternalId = 'hfmOutput.extraExternalId',
  hfmOutputConsumption = 'hfmOutput.consumption',
  hfmOutputCo2Equivalent = 'hfmOutput.co2Equivalent',
  hfmOutputEntities = 'hfmOutput.entities',
  hfmOutputEntitiesCountry = 'hfmOutput.entitiesCountry',
  hfmOutputEntitiesBusinessUnit = 'hfmOutput.businessUnit',
  hfmOutputGreenhouseConversionFactor = 'hfmOutput.greenhouseConversionFactor',
  hfmOutputGreenhouseEmissionFactor = 'hfmOutput.greenhouseEmissionFactor',
  hfmOutputGreenhouseCategory = 'hfmOutput.greenhouseCategory',
  hfmOutputGreenhouseName = 'hfmOutput.greenhouseName',
  //
  hfmOutputReportName = 'hFMOutput.reportName',
  hfmOutputNa = 'hFMOutput.na',
  hfmOutputIcp = 'hFMOutput.icp',
  hfmOutputCustom2 = 'hFMOutput.custom2',
  hfmOutputCustom3 = 'hFMOutput.custom3',
  hfmOutputCustom4 = 'hFMOutput.custom4',

  // errors log
  errorsLogLogDatetime = 'errorsLog.logDatetime',
  errorsLogErrorLogType = 'errorsLog.errorLogType',
  errorsLogErrorBody = 'errorsLog.errorBody',
  errorsLogErrorCode = 'errorsLog.errorCode',

  // sign in messages
  incorrectCredentials = 'Invalid login credentials.',
  incorrectCredentialsExtended = 'Invalid login credentials. Please try again.',
  accountIsSuspended = 'Your account has been suspended, please contact powerchain@impactbuying.com',
  passwordExpired = 'Your password expired! Please change it.',
  sessionExpired = 'Your session expired! Please sign in again.',
  incorrectCredentialsNl = 'Ongeldige logingegevens.',
  incorrectCredentialsNlExtended = 'Ongeldige aanmeldingsgegevens. Probeer het opnieuw.',
  accountIsSuspendedNl = 'Uw account is geblokkeerd, neem contact op met powerchain@impactbuying.com',
  passwordExpiredNl = 'Uw wachtwoord is verlopen! Verander het alstublieft.',
  sessionExpiredNl = 'Uw sessie is verlopen! Meld u opnieuw aan.'
}

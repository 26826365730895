enum AliasesSerialized {
  Aliases = 'Aliases',
  AliasesSubmit = 'AliasesSubmit',
}

enum AliasesUnserialized {
  Aliases = 'Aliases',
  AliasesSubmit = 'AliasesSubmit',
}

export enum AliasesSerializedHeaders {
  id = 'id',
  datasetName = 'datasetName',
  alias = 'alias',
  uploadSource = 'uploadSource',
  notes = 'notes',
  userPassportId = 'userPassportId',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
}

export const AliasesUnserializedHeaders = {
  id: 'id',
  datasetName: 'dataset_name',
  alias: 'value',
  uploadSource: 'upload_source',
  notes: 'notes',
  userPassportId: 'user_passport_id',
  updatedAt: 'updated_at',
  updatedBy: ['uploaded_by', 'full_name'],
} as const;

export enum AliasesSubmitSerializedHeaders {
  id = 'id',
  datasetName = 'datasetName',
  alias = 'alias',
  uploadSource = 'uploadSource',
  notes = 'notes',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
}

export const AliasesSubmitUnserializedHeaders = {
  id: 'id',
  datasetName: 'dataset_name',
  alias: 'value',
  uploadSource: 'upload_source',
  notes: 'notes',
  updatedAt: 'updated_at',
  updatedBy: ['uploaded_by', 'full_name'],
} as const;

export const ALIASES = {
  [AliasesSerialized.Aliases]: {
    serialized: AliasesSerialized.Aliases,
    unserialized: AliasesUnserialized.Aliases,

    columns: {
      [AliasesSerializedHeaders.id]: {
        serialized: AliasesSerializedHeaders.id,
        unserialized: AliasesUnserializedHeaders.id,
      },
      [AliasesSerializedHeaders.datasetName]: {
        serialized: AliasesSerializedHeaders.datasetName,
        unserialized: AliasesUnserializedHeaders.datasetName,
      },
      [AliasesSerializedHeaders.alias]: {
        serialized: AliasesSerializedHeaders.alias,
        unserialized: AliasesUnserializedHeaders.alias,
      },
      [AliasesSerializedHeaders.uploadSource]: {
        serialized: AliasesSerializedHeaders.uploadSource,
        unserialized: AliasesUnserializedHeaders.uploadSource,
      },
      [AliasesSerializedHeaders.notes]: {
        serialized: AliasesSerializedHeaders.notes,
        unserialized: AliasesUnserializedHeaders.notes,
      },
      [AliasesSerializedHeaders.userPassportId]: {
        serialized: AliasesSerializedHeaders.userPassportId,
        unserialized: AliasesUnserializedHeaders.userPassportId,
      },
      [AliasesSerializedHeaders.updatedAt]: {
        serialized: AliasesSerializedHeaders.updatedAt,
        unserialized: AliasesUnserializedHeaders.updatedAt,
      },
      [AliasesSerializedHeaders.updatedBy]: {
        serialized: AliasesSerializedHeaders.updatedBy,
        unserialized: AliasesUnserializedHeaders.updatedBy,
      },
    },
  },
};

export const ALIASES_SUBMIT = {
  [AliasesSerialized.AliasesSubmit]: {
    serialized: AliasesSerialized.AliasesSubmit,
    unserialized: AliasesUnserialized.AliasesSubmit,

    columns: {
      [AliasesSubmitSerializedHeaders.id]: {
        serialized: AliasesSubmitSerializedHeaders.id,
        unserialized: AliasesSubmitUnserializedHeaders.id,
      },
      [AliasesSubmitSerializedHeaders.datasetName]: {
        serialized: AliasesSubmitSerializedHeaders.datasetName,
        unserialized: AliasesSubmitUnserializedHeaders.datasetName,
      },
      [AliasesSubmitSerializedHeaders.alias]: {
        serialized: AliasesSubmitSerializedHeaders.alias,
        unserialized: AliasesSubmitUnserializedHeaders.alias,
      },
      [AliasesSubmitSerializedHeaders.uploadSource]: {
        serialized: AliasesSubmitSerializedHeaders.uploadSource,
        unserialized: AliasesSubmitUnserializedHeaders.uploadSource,
      },
      [AliasesSubmitSerializedHeaders.notes]: {
        serialized: AliasesSubmitSerializedHeaders.notes,
        unserialized: AliasesSubmitUnserializedHeaders.notes,
      },
      [AliasesSubmitSerializedHeaders.updatedAt]: {
        serialized: AliasesSubmitSerializedHeaders.updatedAt,
        unserialized: AliasesSubmitUnserializedHeaders.updatedAt,
      },
      [AliasesSubmitSerializedHeaders.updatedBy]: {
        serialized: AliasesSubmitSerializedHeaders.updatedBy,
        unserialized: AliasesSubmitUnserializedHeaders.updatedBy,
      },
    },
  },
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';

import rowContextMenu from './rowContextMenu';

import { CODES_EVENT_TYPES } from 'store/entities/farmManagementData/codesEventTypes/config';

const { columns, view, serialized } = CODES_EVENT_TYPES.CodesEventTypes;

export const codesEventTypeTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      grouping: true,
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: rowContextMenu,
    headers: [
      new HybridView({
        settings: {
          title: columns.eventTypeIdentifier.view,
          field: columns.eventTypeIdentifier.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.note.view,
          field: columns.note.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.shortcut.view,
          field: columns.shortcut.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.eventTypeDatetime.view,
          field: columns.eventTypeDatetime.serialized,
        },
      }),
    ],
  }),
]);

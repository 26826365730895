import { RAV_CODES_FIELDS } from 'lib/excel/config/referenceTables/ravCodes/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = RAV_CODES_FIELDS.RavCodes;

export const serializeRavCodesData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.animalCategory.original, columns.animalCategory.serialized),
      new CellSerializer(columns.ravCode.original, columns.ravCode.serialized),
      new CellSerializer(columns.animalDescriptionNl.original, columns.animalDescriptionNl.serialized),
      new CellSerializer(columns.animalDescriptionEn.original, columns.animalDescriptionEn.serialized),
      new CellSerializer(columns.animalEnclosureCategory.original, columns.animalEnclosureCategory.serialized),
      new CellSerializer(columns.ammonia.original, columns.ammonia.serialized),
      new CellSerializer(columns.ammoniaUnit.original, columns.ammoniaUnit.serialized),
    ]),
  ]);
};

import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo2DigitsFloating, roundTo5DigitsFloating } from 'lib/helpers/renderHelpers';

import { CVB_TABLE } from 'store/entities/referenceTables/cvbTable/config';

const { columns, view, serialized } = CVB_TABLE.CvbTable;

export const cvbTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.productCode.view,
          field: columns.productCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productSubCode.view,
          field: columns.productSubCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productType.view,
          field: columns.productType.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productNameNL.view,
          field: columns.productNameNL.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.classNameNL.view,
          field: columns.classNameNL.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.subClassNameNL.view,
          field: columns.subClassNameNL.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productNameEN.view,
          field: columns.productNameEN.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.classNameEN.view,
          field: columns.classNameEN.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.subClassNameEN.view,
          field: columns.subClassNameEN.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ds.view,
          field: columns.ds.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.ds.serialized] && roundTo2DigitsFloating(data[columns.ds.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.dsUnit.view,
          field: columns.dsUnit.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.ras.view,
          field: columns.ras.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.ras.serialized] && roundTo2DigitsFloating(data[columns.ras.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.rasUnit.view,
          field: columns.rasUnit.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.rein.view,
          field: columns.rein.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.rein.serialized] && roundTo2DigitsFloating(data[columns.rein.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.reinUnit.view,
          field: columns.reinUnit.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.re.view,
          field: columns.re.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.re.serialized] && roundTo2DigitsFloating(data[columns.re.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.reUnit.view,
          field: columns.reUnit.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.cVcRe.view,
          field: columns.cVcRe.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.cVcRe.serialized] && roundTo5DigitsFloating(data[columns.cVcRe.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.cVcReUnit.view,
          field: columns.cVcReUnit.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.cVcOs.view,
          field: columns.cVcOs.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.cVcOs.serialized] && roundTo5DigitsFloating(data[columns.cVcOs.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.cVcOsUnit.view,
          field: columns.cVcOsUnit.serialized,
        },
      }),
    ],
  }),
]);

import { ResourcesWrapper } from 'lib/http/utils';

import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { MOVEMENT_EVENTS } from 'lib/tables/config/movementEvents/config';

type MovementEventPayload = {
  id: number;
  event_id: string;
  event_type: string;
  event_date_time: string;
  animal_category: string;
  animal_count: string;
  transport_id: string;
  group_data: string;
  kvk: string;
  ubn: string;
  created_at: string;
  updated_at: string;
  destination?: {
    event_location_id: string;
    scheme: string;
    event_id_match: string;
  };
  origin?: {
    event_location_id: string;
    scheme: string;
    event_id_match: string;
  };
};

const { columns, serialized, unserialized } = MOVEMENT_EVENTS.MovementEvents;
const {
  eventId,
  eventType,
  eventDateTime,
  animalCategory,
  animalCount,
  transportId,
  groupDate,
  kvk,
  ubn,
  labels,
  destinationEventLocationId,
  destinationScheme,
  destinationEventIdMatch,
  originEventLocationId,
  originScheme,
  originEventIdMatch,
} = columns;

const getSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(eventId.unserialized, eventId.serialized),
      new CellSerializer(eventType.unserialized, eventType.serialized),
      new CellSerializer(eventDateTime.unserialized, eventDateTime.serialized),
      new CellSerializer(animalCategory.unserialized, animalCategory.serialized),
      new CellSerializer(animalCount.unserialized, animalCount.serialized),
      new CellSerializer(transportId.unserialized, transportId.serialized),
      new CellSerializer(groupDate.unserialized, groupDate.serialized),
      new CellSerializer(kvk.unserialized, kvk.serialized),
      new CellSerializer(ubn.unserialized, ubn.serialized),
      new CellSerializer(labels.unserialized, labels.serialized),
      new CellSerializer(destinationEventLocationId.unserialized, destinationEventLocationId.serialized),
      new CellSerializer(destinationScheme.unserialized, destinationScheme.serialized),
      new CellSerializer(destinationEventIdMatch.unserialized, destinationEventIdMatch.serialized),
      new CellSerializer(originEventLocationId.unserialized, originEventLocationId.serialized),
      new CellSerializer(originScheme.unserialized, originScheme.serialized),
      new CellSerializer(originEventIdMatch.unserialized, originEventIdMatch.serialized),
      new CellSerializer(SERVICE_FIELDS.blockchainHashKey.unserialized, SERVICE_FIELDS.blockchainHashKey.serialized),
      new CellSerializer(
        SERVICE_FIELDS.blockchainTimestamp.unserialized,
        SERVICE_FIELDS.blockchainTimestamp.serialized,
      ),
      new CellSerializer(SERVICE_FIELDS.blockchainId.unserialized, SERVICE_FIELDS.blockchainId.serialized),
    ]),
  ]);
};

export const serializeMovementEvents = (serverData: ResourcesWrapper<MovementEventPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getSerializer(data);

  return serializer.serialize();
};

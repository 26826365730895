import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const slaughterMessages = new Route('get', new RouteParser('/slaughter_messages'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 79,
          message_id: '15477733',
          farmer_id: '0952249',
          message_date: '2020-06-15',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-06-11',
          created_at: '2020-06-17T11:56:19.984Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 77,
          message_id: '15438358',
          farmer_id: '0464417',
          message_date: '2020-06-08',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-06-05',
          created_at: '2020-06-17T11:56:16.777Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 78,
          message_id: '15438361',
          farmer_id: '0952249',
          message_date: '2020-06-08',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-06-05',
          created_at: '2020-06-17T11:56:18.943Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 76,
          message_id: '15401628',
          farmer_id: '0464417',
          message_date: '2020-05-27',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-05-26',
          created_at: '2020-06-17T11:56:15.515Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 76,
          message_id: '15401628',
          farmer_id: '0464417',
          message_date: '2020-05-27',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-05-26',
          created_at: '2020-06-17T11:56:15.515Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 76,
          message_id: '15401628',
          farmer_id: '0464417',
          message_date: '2020-05-27',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-05-26',
          created_at: '2020-06-17T11:56:15.515Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 76,
          message_id: '15401628',
          farmer_id: '0464417',
          message_date: '2020-05-27',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-05-26',
          created_at: '2020-06-17T11:56:15.515Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 76,
          message_id: '15401628',
          farmer_id: '0464417',
          message_date: '2020-05-27',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-05-26',
          created_at: '2020-06-17T11:56:15.515Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 76,
          message_id: '15401628',
          farmer_id: '0464417',
          message_date: '2020-05-27',
          currency: 'EUR',
          slaughterhouse_id: '001',
          slaughter_date: '2020-05-26',
          created_at: '2020-06-17T11:56:15.515Z',
          ...generateBlockchainRecord(),
        },
        {
          id: 76,
          message_id: '15401628',
          farmer_id: '0464417',
          message_date: '2020-05-27',
          currency: 'FIND ME IF YOU CAN',
          slaughterhouse_id: '001',
          slaughter_date: '2020-05-26',
          created_at: '2020-06-17T11:56:15.515Z',
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

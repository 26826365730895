import React from 'react';
import { MTableToolbar } from 'material-table';

import { MaterialTheme } from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const CustomToolbar: React.FC = (props) => {
  const classes = useToolbarStyles();

  return (
    <div className={classes.wrapper} data-testid="table-toolbar">
      <MTableToolbar {...props}/>
    </div>
  );
};

const useToolbarStyles = makeStyles((theme: MaterialTheme) => ({
  //
  wrapper: {
    //
    [theme.breakpoints.down('xs')]: {
      // MTableToolbar
      '& > div': {
        padding: '8px 8px 4px',
        flexDirection: 'column',

        // Title and search bar
        '& > *': {
          width: '100%',
          padding: 0,
          margin: '2px 0',
        },
      },
    },
  },
}));

export default React.memo(CustomToolbar);

import { COUNTRY_CODES_FIELDS } from 'lib/excel/config/referenceTables/countryCodes/structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = COUNTRY_CODES_FIELDS.CountryCodes;

export const getCountryCodesDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.iso31661Code.serialized, extractPropName: columns.iso31661Code.view }),
      new DisplayHeader({
        headerName: columns.subdivisionName.serialized,
        extractPropName: columns.subdivisionName.view,
      }),
      new DisplayHeader({ headerName: columns.iso31662Code.serialized, extractPropName: columns.iso31662Code.view }),
      new DisplayHeader({ headerName: columns.countryName.serialized, extractPropName: columns.countryName.view }),
    ]),
  ]);
};

import { TableColumnDescriptor } from 'lib/tables/TableColumnsGenerator';

interface ToggleTableHeaderDTOI {
  headerSettings: TableColumnDescriptor;
  tableId: string;
  tabId: string;
}

export class ToggleTableHeaderDTO implements ToggleTableHeaderDTOI {
  constructor(
    { headerSettings: _headerSettings, tabId: _tabId, tableId: _tableId }: ToggleTableHeaderDTOI,
    readonly headerSettings: TableColumnDescriptor = _headerSettings,
    readonly tabId: string = _tabId,
    readonly tableId: string = _tableId,
  ) {}
}

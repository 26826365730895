import { Router } from './Router';
import { singIn } from './responses/auth/sign-in';
import { slaughterMessages } from './responses/slaughter/messages';
import { slaughterDetails } from './responses/slaughter/details';
import { slaughterPriceCorrections } from './responses/slaughter/priceCorrections';
import { slaughterChargeOrPremiums } from './responses/slaughter/chargeOrPremiums';
import { fileUpload } from './responses/uploads/uploads';
import { uploadFileAgain } from './responses/uploads/uploadedAlready';
import { weeklyPost } from './responses/uploads/weekly';
import { dailyPost } from './responses/uploads/daily';
import { validateToken } from './responses/auth/validate-token';
import { dataCatalogue, createCategory, deleteCategory, editCategory } from './responses/dataCatalogue/messages';
import { uploadHistory } from './responses/uploads/history';
import { certificatesDaily } from './responses/certificates/kiwa/daily';
import { certificatesCompany } from './responses/certificates/kiwa/weekly/company';
import { recognitionsCompany } from './responses/certificates/kiwa/weekly/recognitions';
import { deviationsCompany } from './responses/certificates/kiwa/weekly/deviations';
import { invoiceMessages } from './responses/invoices/messages';
import { invoiceDetails } from './responses/invoices/details';
import { VRIDatabase } from './responses/vri/database';
import { vriData, vriDataCalculated, vriUbnsAutocomplete } from './responses/vri/messages';
import { certificatesIKB } from './responses/certificates/ikb';
import { certificatesWelfare } from './responses/certificates/welfare';
import { genetics } from './responses/genetics';
import { movementEvents } from './responses/movementEvents';
import { veterinarianData } from './responses/veterinarianData';
import { organizations, createOrganization } from './responses/organizations';
import { AdminUsers } from './responses/admin/users';
import { resetPassword } from './responses/auth/reset-password';
import { animalPassport } from './responses/animalPassport';
import { dashboardTooltip } from './responses/dashboard';
import {
  myJoindata,
  adminJoindata,
  adminJoindataRequestedParticipations,
  joindataLastSync,
  syncJoindata,
} from './responses/myJoindata';
import { userPassports, adminUserPassports } from './responses/userPassports';
import { transports } from './responses/transports';
import { transportLoadings, transportUnloadings } from './responses/transports/loadings';

export const mocksRouter = new Router(
  [
    singIn,
    resetPassword,
    VRIDatabase,
    invoiceDetails,
    certificatesCompany,
    recognitionsCompany,
    deviationsCompany,
    certificatesDaily,
    validateToken,
    uploadFileAgain,
    weeklyPost,
    dailyPost,
    fileUpload,
    slaughterMessages,
    slaughterDetails,
    slaughterPriceCorrections,
    slaughterChargeOrPremiums,
    dataCatalogue,
    createCategory,
    deleteCategory,
    editCategory,
    uploadHistory,
    invoiceMessages,
    vriData,
    vriDataCalculated,
    vriUbnsAutocomplete,
    certificatesIKB,
    certificatesWelfare,
    genetics,
    movementEvents,
    veterinarianData,
    organizations,
    createOrganization,
    AdminUsers,
    animalPassport,
    dashboardTooltip,
    myJoindata,
    adminJoindata,
    adminJoindataRequestedParticipations,
    joindataLastSync,
    syncJoindata,
    userPassports,
    adminUserPassports,
    transports,
    transportLoadings,
    transportUnloadings,
  ],
  [],
);

export default mocksRouter;

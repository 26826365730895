import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_INSEMINATION } from './config';

export type CodesInseminationPayload = {
  id: number;
  company_identifier: string;
  insemination_identifier: string;
  abbreviation: string;
  description: string;
  note: string;
  worklist: string;
  code: string;
  inseminationDatetime: string;
  ubn_list: string[];
  kvk_list: string[];
  registration_name_list: string[];
};

export type CodesInseminationSerialized = {
  id: number;
  companyIdentifier: string;
  inseminationIdentifier: string;
  abbreviation: string;
  description: string;
  note: string;
  workList: string;
  code: string;
  inseminationDatetime: string;
  ubnList: string[];
  kvkList: string[];
  registrationNameList: string[];
};

const { columns, serialized, unserialized } = CODES_INSEMINATION.CodesInsemination;

const getDefaultSerializer = (data: DocumentData<CodesInseminationPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.companyIdentifier.unserialized, columns.companyIdentifier.serialized),
      new CellSerializer(columns.inseminationIdentifier.unserialized, columns.inseminationIdentifier.serialized),
      new CellSerializer(columns.abbreviation.unserialized, columns.abbreviation.serialized),
      new CellSerializer(columns.description.unserialized, columns.description.serialized),
      new CellSerializer(columns.note.unserialized, columns.note.serialized),
      new CellSerializer(columns.workList.unserialized, columns.workList.serialized),
      new CellSerializer(columns.code.unserialized, columns.code.serialized),
      new CellSerializer(columns.inseminationDatetime.unserialized, columns.inseminationDatetime.serialized),
      new CellSerializer(columns.ubnList.unserialized, columns.ubnList.serialized),
      new CellSerializer(columns.kvkList.unserialized, columns.kvkList.serialized),
      new CellSerializer(columns.registrationNameList.unserialized, columns.registrationNameList.serialized),
    ]),
  ]);
};

export const serializeCodesInsemination = (serverData: ResourcesWrapper<CodesInseminationPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';
import { generateBlockchainRecord } from '__mocks__/utils';

export const slaughterDetails = new Route('get', new RouteParser('/slaughters'), (...data) => {
  return [
    200,
    {
      resources: [
        {
          id: 4287,
          slaughter_message_id: 77,
          slaughter_key: '2020060500060909280061080062000092187',
          animal_type: '01',
          carcass_code: '0',
          animal_weight: '0892',
          organ_code: '0',
          muscle_thickness: '598',
          fat_thickness: '147',
          after_correction_pig_amount: '+1432300',
          created_at: '2020-06-17T11:56:16.819Z',
          slaughter_message: {
            id: 77,
            message_id: '15438358',
            farmer_id: '0464417',
            message_date: '2020-06-08',
            currency: 'EUR',
            slaughterhouse_id: '001',
            slaughter_date: '2020-06-05',
            created_at: '2020-06-17T11:56:16.777Z',
          },
          ...generateBlockchainRecord(),
        },
        {
          id: 4286,
          slaughter_message_id: 77,
          slaughter_key: '2020060500060909280061079771000092200',
          animal_type: '01',
          carcass_code: '0',
          animal_weight: '1175',
          organ_code: '0',
          muscle_thickness: '692',
          fat_thickness: '109',
          after_correction_pig_amount: '+1722700',
          created_at: '2020-06-17T11:56:16.818Z',
          slaughter_message: {
            id: 77,
            message_id: '15438358',
            farmer_id: '0464417',
            message_date: '2020-06-08',
            currency: 'EUR',
            slaughterhouse_id: '001',
            slaughter_date: '2020-06-05',
            created_at: '2020-06-17T11:56:16.777Z',
          },
          ...generateBlockchainRecord(),
        },
      ],
    },
  ];
});

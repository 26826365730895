import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundTo2DigitsFloating } from 'lib/helpers/renderHelpers';

import { GWP_100_FACTORS } from 'store/entities/referenceTables/gwp100Factors/config';

const { columns, view, serialized } = GWP_100_FACTORS.Gwp100Factors;

export const gwp100FactorsTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.greenhouseGas.view,
          field: columns.greenhouseGas.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.gwp100Factor.view,
          field: columns.gwp100Factor.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.gwp100Factor.serialized] && roundTo2DigitsFloating(data[columns.gwp100Factor.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.gwp100FactorUnit.view,
          field: columns.gwp100FactorUnit.serialized,
        },
      }),
    ],
  }),
]);

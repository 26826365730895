import qs from 'qs';

import { store } from 'store/store';
import { PERMISSIONS } from 'store/auth/permissions';
import { userHasPermissionSelector } from 'store/auth/reducer';

import { OptionType } from 'components/form/FormAutocomplete/FormAutocompleteComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { FILTER_TYPES } from './filterTypes';
import { ClientStorage } from 'lib/storage/Storage';

export type FilterHelpers = {
  title?: string;
  type: FILTER_TYPES;
  getQuery: (filters: any) => any;
  getValues: (filters: any) => any;
  getLabel: (filters: any) => any;
  getInitialValues: () => any;
  permissions?: PERMISSIONS[];
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  options?: OptionType[];
  customTags?: boolean;
  multiple?: boolean;
};

export type FilterValueType = Record<string, unknown> | string[] | string | number | boolean;
export type ParsedFilters<T extends string = string> = { filter: Partial<Record<T, FilterValueType>> };

const FILTER_SETTINGS_PREFIX = 'filter-settings-';
export const buildFilterStorageKey = (path = window.location.pathname) => FILTER_SETTINGS_PREFIX + path;
export const restoreFilterFromStorage = (currentFilter: any) => {
  const filterStorageKey = buildFilterStorageKey();
  const currentFilterSettings = ClientStorage.getItem(filterStorageKey);

  if (currentFilter) {
    ClientStorage.setItem(filterStorageKey, JSON.stringify(currentFilter));
  } else if (currentFilterSettings) {
    currentFilter = JSON.parse(currentFilterSettings);
  }

  return currentFilter
}

export const removeFilterSettingsFromStorage = () => {
  const allKeys = ClientStorage.getAllKeys();
  const filterSettingsKeys = allKeys.filter(key => key?.startsWith(FILTER_SETTINGS_PREFIX));
  for (const filterSettingsKey of filterSettingsKeys) {
    if (filterSettingsKey) {
      ClientStorage.removeItem(filterSettingsKey);
    }
  }
};

export const removeFilterSettingsFromStorageCurrentFilter = () => {
  const filterStorageKey = buildFilterStorageKey();
  ClientStorage.removeItem(filterStorageKey);
};

export abstract class BaseFilter<FilterKeys extends string> {
  abstract config: Record<FilterKeys, FilterHelpers>;
  abstract serializeFilters: (filters: Partial<Record<FilterKeys, FilterValueType>>) => object;

  private reduceFilters = (
    filters = {} as Partial<Record<FilterKeys, FilterValueType>>,
    method: 'getQuery' | 'getValues' | 'getLabel',
  ) => {
    // const filterEntries = Object.entries(filters) as [FilterKeys, FilterValueType][];
    const filterEntries = (Object.keys(this.config) as FilterKeys[]).map((fKey) => [
      fKey,
      filters[fKey] || this.config[fKey].getInitialValues(),
    ]) as [FilterKeys, FilterValueType][];

    return filterEntries.reduce<Partial<Record<FilterKeys, FilterValueType>>>((acc, [key, value]) => {
      if (this.config[key]) {
        acc[key] = this.config[key][method](value);
      }
      return acc;
    }, {});
  };

  getQuery = (filters = {} as Partial<Record<FilterKeys, FilterValueType>>) => {
    return qs.stringify(
      {
        filter: this.reduceFilters(filters, 'getQuery'),
      },
      { arrayFormat: 'brackets', encodeValuesOnly: true },
    );
  };

  getValues = (filters = {} as Partial<Record<FilterKeys, FilterValueType>>) => {
    return this.reduceFilters(filters, 'getValues');
  };

  getLabels = (filters = {} as Partial<Record<FilterKeys, FilterValueType>>) => {
    return this.reduceFilters(filters, 'getLabel');
  };

  getValuesFromQuery = (query: string): Partial<Record<FilterKeys, FilterValueType>> => {
    const userHasPermission = userHasPermissionSelector(store.getState());
    let { filter: parsedQueryFilter } = qs.parse(query, {
      ignoreQueryPrefix: true,
    }) as ParsedFilters<FilterKeys>;

    parsedQueryFilter = restoreFilterFromStorage(parsedQueryFilter);

    const parsedFilters = this.getValues(parsedQueryFilter);

    const filters: Partial<Record<FilterKeys, FilterValueType>> = {};

    if (typeof parsedFilters === 'object' && !Array.isArray(parsedFilters)) {
      (Object.keys(parsedFilters) as FilterKeys[]).forEach((filter) => {
        if (filter in this.config) {
          const { permissions } = this.config[filter];

          if (!permissions || permissions.every(userHasPermission)) {
            filters[filter as FilterKeys] = parsedFilters[filter];
          }
        }
      });
    }

    return this.getValues(filters);
  };

  getInitialValues = () => {
    const configEntries = Object.entries(this.config) as [FilterKeys, FilterHelpers][];
    const userHasPermission = userHasPermissionSelector(store.getState());

    return configEntries.reduce((acc, [key, { getInitialValues, permissions }]) => {
      if (!permissions || permissions.every(userHasPermission)) {
        acc[key] = getInitialValues();
      }

      return acc;
    }, {} as Record<FilterKeys, FilterValueType>);
  };
}

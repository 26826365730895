import { YES_NO_FIELDS } from 'lib/excel/config/referenceTables/yesNo/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = YES_NO_FIELDS.YesNo;

export const getYesNoDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.yesNoCode.original),
      new CellValidator(columns.yesNoName.original),
    ]),
  ]);
};

import RouteParser from 'route-parser';

import { Route } from '__mocks__/Route';

export const transportLoadings = new Route('get', new RouteParser('/mock/transports/:id/transport_loadings'), () => {
  return [
    200,
    {
      resources: [
        {
          id: 3,
          transport_id: 1,
          planned_arrival_time: '2021-02-11T00:00:00.000Z',
          actual_arrival_time: '2021-02-11T00:00:00.000Z',
          start_loading_time: '2021-02-11T00:00:00.000Z',
          end_loading_time: '2021-02-11T00:00:00.000Z',
          planned_departure_time: '2021-02-12T00:00:00.000Z',
          actual_departure_time: '2021-02-12T00:00:00.000Z',
          number_of_animals: 15,
          location_id_type_code: 'o8uq5a',
          location_id_value: '11111',
          location_name: 'mollitia',
          created_at: '2021-02-14T12:47:55.264Z',
          updated_at: '2021-02-14T12:47:55.264Z',
        },
        {
          id: 2,
          transport_id: 1,
          planned_arrival_time: '2021-02-13T00:00:00.000Z',
          actual_arrival_time: '2021-02-13T00:00:00.000Z',
          start_loading_time: '2021-02-12T00:00:00.000Z',
          end_loading_time: '2021-02-11T00:00:00.000Z',
          planned_departure_time: '2021-02-12T00:00:00.000Z',
          actual_departure_time: '2021-02-13T00:00:00.000Z',
          number_of_animals: 140,
          location_id_type_code: 'j5e86d',
          location_id_value: '11111',
          location_name: 'ad',
          created_at: '2021-02-14T12:47:55.260Z',
          updated_at: '2021-02-14T12:47:55.260Z',
        },
        {
          id: 1,
          transport_id: 1,
          planned_arrival_time: '2021-02-11T00:00:00.000Z',
          actual_arrival_time: '2021-02-11T00:00:00.000Z',
          start_loading_time: '2021-02-12T00:00:00.000Z',
          end_loading_time: '2021-02-13T00:00:00.000Z',
          planned_departure_time: '2021-02-13T00:00:00.000Z',
          actual_departure_time: '2021-02-12T00:00:00.000Z',
          number_of_animals: 36,
          location_id_type_code: 'eiaf2o',
          location_id_value: '11111',
          location_name: 'similique',
          created_at: '2021-02-14T12:47:55.254Z',
          updated_at: '2021-02-14T12:47:55.254Z',
        },
      ],
    },
  ];
});

export const transportUnloadings = new Route(
  'get',
  new RouteParser('/mock/transports/:id/transport_unloadings'),
  () => {
    return [
      200,
      {
        resources: [
          {
            id: 1,
            transport_id: 1,
            planned_arrival_time: '2021-02-12T00:00:00.000Z',
            actual_arrival_time: '2021-02-12T00:00:00.000Z',
            start_loading_time: '2021-02-11T00:00:00.000Z',
            end_loading_time: '2021-02-12T00:00:00.000Z',
            planned_departure_time: '2021-02-13T00:00:00.000Z',
            actual_departure_time: '2021-02-12T00:00:00.000Z',
            number_of_animals: 155,
            location_id_type_code: 'fvowfk',
            location_id_value: '11111',
            location_name: 'ea',
            created_at: '2021-02-14T12:49:28.194Z',
            updated_at: '2021-02-14T12:49:28.194Z',
          },
        ],
      },
    ];
  },
);

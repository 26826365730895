import { SUPPLIERS_NUMBERS_FIELDS } from 'lib/excel/config/referenceTables/suppliersNumbers/structure';
import { DocumentValidator, ExcelSheets } from 'lib/excel/validators/Document';
import { SheetValidator } from 'lib/excel/validators/Sheet';
import { CellValidator } from 'lib/excel/validators/Cell';

const { original, columns, viewName } = SUPPLIERS_NUMBERS_FIELDS.SuppliersNumbers;

export const getSuppliersNumbersDocumentValidator = (data: ExcelSheets) => {
  return new DocumentValidator(data, [
    new SheetValidator(original, viewName, [
      new CellValidator(columns.codeList.original),
      new CellValidator(columns.supplierNumber.original),
      new CellValidator(columns.feedSupplierName.original),
      new CellValidator(columns.entryDate.original),
      new CellValidator(columns.dischargeDate.original),
      new CellValidator(columns.remark.original),
    ]),
  ]);
};

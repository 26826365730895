import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { SERVICE_FIELDS } from 'lib/excel/config/service';
import { ResourcesWrapper } from 'lib/http/utils';

import { ALIASES, ALIASES_SUBMIT } from './config';

export type AliasesPayload = {
  id: number;
  value: string;
  upload_source: string;
  dataset_name: string;
  notes: string;
  user_passport_id: number;
  updated_at: string;
  updated_by: {
    full_name: string;
  };
};

export type AliasesSerialized = {
  id?: number;
  datasetName: string;
  alias: string;
  uploadSource: string;
  notes: string;
  userPassportId: number;
  updatedAt?: string;
  updatedBy?: string;
};

export type AliasesSubmitPayload = Omit<AliasesPayload, 'id' | 'updated_at' | 'updated_by' | 'user_passport_id'>;
export type AliasesSubmitSerialized = Omit<AliasesSerialized, 'userPassportId'>;

const { columns: defaultColumns } = ALIASES.Aliases;
const { columns: submitColumns } = ALIASES_SUBMIT.AliasesSubmit;

const getDefaultSerializer = (data: DocumentData<AliasesPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(ALIASES.Aliases.unserialized, ALIASES.Aliases.serialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.datasetName.unserialized, defaultColumns.datasetName.serialized),
      new CellSerializer(defaultColumns.alias.unserialized, defaultColumns.alias.serialized),
      new CellSerializer(defaultColumns.uploadSource.unserialized, defaultColumns.uploadSource.serialized),
      new CellSerializer(defaultColumns.notes.unserialized, defaultColumns.notes.serialized),
      new CellSerializer(defaultColumns.userPassportId.unserialized, defaultColumns.userPassportId.serialized),
      new CellSerializer(defaultColumns.updatedAt.unserialized, defaultColumns.updatedAt.serialized),
      new CellSerializer(defaultColumns.updatedBy.unserialized, defaultColumns.updatedBy.serialized),
    ]),
  ]);
};

const getSubmitUnserializer = (data: DocumentData<AliasesSubmitSerialized[]>) => {
  return new DocumentSerializer<AliasesSubmitPayload, AliasesSubmitSerialized>(data, [
    new SheetSerializer(ALIASES_SUBMIT.AliasesSubmit.serialized, ALIASES_SUBMIT.AliasesSubmit.unserialized, [
      new CellSerializer(submitColumns.datasetName.unserialized, submitColumns.datasetName.serialized),
      new CellSerializer(submitColumns.alias.unserialized, submitColumns.alias.serialized),
      new CellSerializer(submitColumns.uploadSource.unserialized, submitColumns.uploadSource.serialized),
      new CellSerializer(submitColumns.notes.unserialized, submitColumns.notes.serialized, undefined, undefined, null),
    ]),
  ]);
};

// Serialized into Serialized Submit
const getStoSSSerializer = (data: DocumentData<AliasesSerialized[]>) => {
  return new DocumentSerializer<AliasesSerialized, AliasesSubmitSerialized>(data, [
    new SheetSerializer(ALIASES_SUBMIT.AliasesSubmit.serialized, ALIASES_SUBMIT.AliasesSubmit.unserialized, [
      new CellSerializer(SERVICE_FIELDS.id.unserialized, SERVICE_FIELDS.id.serialized),
      new CellSerializer(defaultColumns.datasetName.serialized, submitColumns.datasetName.serialized),
      new CellSerializer(defaultColumns.alias.serialized, submitColumns.alias.serialized),
      new CellSerializer(defaultColumns.uploadSource.serialized, submitColumns.uploadSource.serialized),
      new CellSerializer(
        defaultColumns.notes.serialized,
        submitColumns.notes.serialized,
        undefined,
        undefined,
        undefined,
      ),
      new CellSerializer(
        defaultColumns.updatedAt.serialized,
        submitColumns.updatedAt.serialized,
        undefined,
        undefined,
        undefined,
      ),
      new CellSerializer(
        defaultColumns.updatedBy.serialized,
        submitColumns.updatedBy.serialized,
        undefined,
        undefined,
        undefined,
      ),
    ]),
  ]);
};

export const serializeAliases = (serverData: ResourcesWrapper<AliasesPayload>) => {
  const { unserialized } = ALIASES.Aliases;
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

export const unserializeAliasForSubmit = (resource: AliasesSubmitSerialized): AliasesSubmitPayload => {
  const { serialized, unserialized } = ALIASES_SUBMIT.AliasesSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getSubmitUnserializer(data);

  return serializer.unserialize()[serialized][0];
};

// Serialized into Serialized Submit
export const serializeAliasStoSS = (resource: AliasesSerialized) => {
  const { serialized, unserialized } = ALIASES_SUBMIT.AliasesSubmit;
  const data = {
    [unserialized]: [resource],
  };

  const serializer = getStoSSSerializer(data);

  return serializer.serialize()[serialized][0];
};

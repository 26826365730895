export enum EnvVars {
  HOST_URL = 'REACT_APP_HOST',
  API_URL = 'REACT_APP_API_URL',
  PUB_API_NODE_1 = 'REACT_APP_PUB_API_NODE_1',
  PUB_API_NODE_2 = 'REACT_APP_PUB_API_NODE_2',
  PUB_API_NODE_3 = 'REACT_APP_PUB_API_NODE_3',
  HIDE_PUB_API_NODE_1 = 'REACT_APP_HIDE_PUB_API_NODE_1',
  HIDE_PUB_API_NODE_2 = 'REACT_APP_HIDE_PUB_API_NODE_2',
  HIDE_PUB_API_NODE_3 = 'REACT_APP_HIDE_PUB_API_NODE_3',
  STORAGE_TYPE = 'REACT_APP_STORAGE_TYPE',
  MODE = 'REACT_APP_MODE',
}

export enum AppMode {
  DEV = 'development',
  TEST = 'test',
  ACCEPTANCE = 'acceptance',
  PROD = 'production',
  UNITTEST = 'unittest'
}

class Env {
  /* eslint-disable */
  private constructor() {}
  /* eslint-enable */

  static getInstance() {
    return new Env();
  }

  getItem(key: EnvVars) {
    return process.env[key];
  }
}

const instance = Env.getInstance();

export { instance as Env };

import { UserRoles } from './User';

export enum PERMISSIONS {
  // LEVEL PERMISSION USAGE: if hierarchy restriction needed, not for specific user role. LEVEL0 - the highest level
  LEVEL0 = 'LEVEL0',
  LEVEL1 = 'LEVEL1',
  LEVEL2 = 'LEVEL2',
  ACCESS_AUTHORIZED_CONTENT = 'ACCESS_AUTHORIZED_CONTENT',
  ACCESS_VRI_DATABASE_UPLOADS = 'ACCESS_VRI_DATABASE_UPLOADS',
  UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
  DATA_CATALOGUE_EDIT = 'DATA_CATALOGUE_EDIT',
  BLOCKCHAIN_PERMISSIONS_FILTER = 'BLOCKCHAIN_PERMISSIONS_FILTER',
  ACCESS_ADMIN_PANEL = 'ACCESS_ADMIN_PANEL',
  SIMPLE_USER = 'SIMPLE_USER',
  MONITORING_ADMIN = 'MONITORING_ADMIN',
}

export type PermissionsEntry = Partial<Record<PERMISSIONS, boolean>>;

export const rolePermissions: Record<UserRoles, PermissionsEntry> = {
  [UserRoles.simpleUser]: {
    [PERMISSIONS.LEVEL2]: true,
    [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: true,
    [PERMISSIONS.SIMPLE_USER]: true,
  },
  [UserRoles.contentAdmin]: {
    [PERMISSIONS.LEVEL0]: true,
    [PERMISSIONS.LEVEL1]: true,
    [PERMISSIONS.LEVEL2]: true,
    [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: true,
    [PERMISSIONS.UPLOAD_DOCUMENTS]: true,
    [PERMISSIONS.DATA_CATALOGUE_EDIT]: true,
    [PERMISSIONS.ACCESS_VRI_DATABASE_UPLOADS]: true,
    [PERMISSIONS.BLOCKCHAIN_PERMISSIONS_FILTER]: true,
  },
  [UserRoles.userAdmin]: {
    [PERMISSIONS.LEVEL1]: true,
    [PERMISSIONS.LEVEL2]: true,
    [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: true,
    [PERMISSIONS.ACCESS_ADMIN_PANEL]: true,
  },
  [UserRoles.monitoringAdmin]: {
    [PERMISSIONS.LEVEL1]: true,
    [PERMISSIONS.LEVEL2]: true,
    [PERMISSIONS.MONITORING_ADMIN]: true,
    [PERMISSIONS.ACCESS_AUTHORIZED_CONTENT]: true,
  },
};

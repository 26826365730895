import { IntlKeys } from 'lib/localization/keys/__keys';

enum SlaughterPriceCorrectionsSerialized {
  SlaughterPriceCorrections = 'SlaughterPriceCorrections',
}

enum SlaughterPriceCorrectionsUnserialized {
  SlaughterPriceCorrections = 'SlaughterPriceCorrections',
}

enum SlaughterPriceCorrectionsSerializedHeaders {
  financialResultId = 'financialResultId',
  financialResultDateTime = 'financialResultDateTime',
  slaughterHouseTypeCode = 'slaughterHouseTypeCode',
  slaughterHouseValue = 'slaughterHouseValue',
  traderTypeCode = 'traderTypeCode',
  traderValue = 'traderValue',
  animalTypeCode = 'animalTypeCode',
  animalValue = 'animalValue',
  numberOfAnimals = 'numberOfAnimals',
  currencyCode = 'currencyCode',
  labels = 'labels',
}

enum SlaughterPriceCorrectionsUnserializedHeaders {
  financialResultId = 'financial_result_id',
  financialResultDateTime = 'financial_result_date_time',
  slaughterHouseTypeCode = 'slaughter_house_type_code',
  slaughterHouseValue = 'slaughter_house_value',
  traderTypeCode = 'trader_type_code',
  traderValue = 'trader_value',
  animalTypeCode = 'animal_type_code',
  animalValue = 'animal_value',
  numberOfAnimals = 'number_of_animals',
  currencyCode = 'currency_code',
  labels = 'labels',
}

export const SLAUGHTER_PRICE_CORRECTIONS = {
  [SlaughterPriceCorrectionsSerialized.SlaughterPriceCorrections]: {
    serialized: SlaughterPriceCorrectionsSerialized.SlaughterPriceCorrections,
    unserialized: SlaughterPriceCorrectionsUnserialized.SlaughterPriceCorrections,
    view: IntlKeys.slaughterPriceCorrections,
    columns: {
      [SlaughterPriceCorrectionsSerializedHeaders.financialResultId]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.financialResultId,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.financialResultId,
        view: IntlKeys.slaughterPCFinancialResultId,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.financialResultDateTime]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.financialResultDateTime,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.financialResultDateTime,
        view: IntlKeys.slaughterPCFinancialResultDateTime,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.slaughterHouseTypeCode]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.slaughterHouseTypeCode,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.slaughterHouseTypeCode,
        view: IntlKeys.slaughterPCSlaughterHouseTypeCode,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.slaughterHouseValue]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.slaughterHouseValue,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.slaughterHouseValue,
        view: IntlKeys.slaughterPCSlaughterHouseValue,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.traderTypeCode]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.traderTypeCode,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.traderTypeCode,
        view: IntlKeys.slaughterPCTraderTypeCode,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.traderValue]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.traderValue,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.traderValue,
        view: IntlKeys.slaughterPCTraderValue,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.animalTypeCode]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.animalTypeCode,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.animalTypeCode,
        view: IntlKeys.slaughterPCAnimalTypeCode,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.animalValue]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.animalValue,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.animalValue,
        view: IntlKeys.slaughterPCAnimalValue,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.numberOfAnimals]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.numberOfAnimals,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.numberOfAnimals,
        view: IntlKeys.slaughterPCNumberOfAnimals,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.currencyCode]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.currencyCode,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.currencyCode,
        view: IntlKeys.slaughterPCCurrencyCode,
      },
      [SlaughterPriceCorrectionsSerializedHeaders.labels]: {
        serialized: SlaughterPriceCorrectionsSerializedHeaders.labels,
        unserialized: SlaughterPriceCorrectionsUnserializedHeaders.labels,
        view: IntlKeys.titleLabels,
      },
    },
  },
};

import { get } from 'lodash';

import { ResourcesWrapper, ResourceWrapper } from 'lib/http/utils';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { CellSerializer, ObjectWithProps, PropertyPath } from 'lib/excel/serilizers/Cell';

import {
  VriCalculatedSerializedHeaders,
  VriCalculatedUnserializedHeaders,
  VriCalculatedSerialized,
  VriCalculatedUnserialized,
  VriCalculatedStatisticsResourceUnserialized,
  VriCalculatedResourcesItemUnserialized,
} from 'lib/tables/config/vriCalculated/config';

const statisticsEntitySerializer = (
  data: DocumentData,
  sheetData: Array<object>,
  excelRow: ObjectWithProps,
  propertyOriginalName: PropertyPath,
) => {
  const proceedData: ObjectWithProps = get(excelRow, propertyOriginalName, '');

  return {
    [VriCalculatedSerializedHeaders.yourScore]: proceedData[VriCalculatedUnserializedHeaders.yourScore] ?? null,
    [VriCalculatedSerializedHeaders.averageTop20Score]:
      proceedData[VriCalculatedUnserializedHeaders.averageTop20Score] ?? null,
    [VriCalculatedSerializedHeaders.difference]: proceedData[VriCalculatedUnserializedHeaders.difference] ?? null,
  };
};

const getVriStatisticsResourceSerializer = (data: DocumentData<VriCalculatedStatisticsResourceUnserialized[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriCalculatedUnserialized.vri, VriCalculatedSerialized.vri, [
      new CellSerializer(
        VriCalculatedUnserializedHeaders.vriTotal,
        VriCalculatedSerializedHeaders.vriTotal,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.lapSpeed,
        VriCalculatedSerializedHeaders.lapSpeed,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.deliveredAnimals,
        VriCalculatedSerializedHeaders.deliveredAnimals,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.delivered,
        VriCalculatedSerializedHeaders.delivered,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.euroPerPig,
        VriCalculatedSerializedHeaders.euroPerPig,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.vriBalanceWithRespectToBase,
        VriCalculatedSerializedHeaders.vriBalanceWithRespectToBase,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.balanceWithRespectToBase,
        VriCalculatedSerializedHeaders.balanceWithRespectToBase,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageWeight,
        VriCalculatedSerializedHeaders.averageWeight,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageWeightCorrection,
        VriCalculatedSerializedHeaders.averageWeightCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageFatThickness,
        VriCalculatedSerializedHeaders.averageFatThickness,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageBeaconCorrection,
        VriCalculatedSerializedHeaders.averageBeaconCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageMuscleThickness,
        VriCalculatedSerializedHeaders.averageMuscleThickness,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageMuscleCorrection,
        VriCalculatedSerializedHeaders.averageMuscleCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageMeat,
        VriCalculatedSerializedHeaders.averageMeat,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageMeatCorrection,
        VriCalculatedSerializedHeaders.averageMeatCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageClassificationCorrection,
        VriCalculatedSerializedHeaders.averageClassificationCorrection,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageSexAllowance,
        VriCalculatedSerializedHeaders.averageSexAllowance,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageConceptSurcharge,
        VriCalculatedSerializedHeaders.averageConceptSurcharge,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.pigsInDraft,
        VriCalculatedSerializedHeaders.pigsInDraft,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.vriHealth,
        VriCalculatedSerializedHeaders.vriHealth,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.euroPerPig,
        VriCalculatedSerializedHeaders.euroPerPig,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.approvedCarcass,
        VriCalculatedSerializedHeaders.approvedCarcass,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.pleurisy,
        VriCalculatedSerializedHeaders.pleurisy,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.inflamedSkin,
        VriCalculatedSerializedHeaders.inflamedSkin,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.inflamedLeg,
        VriCalculatedSerializedHeaders.inflamedLeg,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.approvedOrgan,
        VriCalculatedSerializedHeaders.approvedOrgan,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.leverAfg,
        VriCalculatedSerializedHeaders.leverAfg,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.longAfg,
        VriCalculatedSerializedHeaders.longAfg,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.healthy,
        VriCalculatedSerializedHeaders.healthy,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.epg,
        VriCalculatedSerializedHeaders.epg,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.growth,
        VriCalculatedSerializedHeaders.growth,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.ewConversion,
        VriCalculatedSerializedHeaders.ewConversion,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageEpgWeight,
        VriCalculatedSerializedHeaders.averageEpgWeight,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.loss,
        VriCalculatedSerializedHeaders.loss,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.feedIntakePerDay,
        VriCalculatedSerializedHeaders.feedIntakePerDay,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageBirthWeight,
        VriCalculatedSerializedHeaders.averageBirthWeight,
        statisticsEntitySerializer,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.businessTotal,
        VriCalculatedSerializedHeaders.businessTotal,
        (
          data: DocumentData,
          sheetData: Array<object>,
          excelRow: ObjectWithProps,
          propertyOriginalName: PropertyPath,
        ) => {
          const proceedData: ObjectWithProps = get(excelRow, propertyOriginalName, '');
          return {
            [VriCalculatedSerializedHeaders.vriTotal]: proceedData[VriCalculatedUnserializedHeaders.vriTotal],
            [VriCalculatedSerializedHeaders.epgEuroPerPig]: proceedData[VriCalculatedUnserializedHeaders.epgEuroPerPig],
            [VriCalculatedSerializedHeaders.balanceWithRespectToBase]:
              proceedData[VriCalculatedUnserializedHeaders.balanceWithRespectToBase],
            [VriCalculatedSerializedHeaders.euroPerPig]: proceedData[VriCalculatedUnserializedHeaders.euroPerPig],
          };
        },
      ),
    ]),
  ]);
};

const getVriReportSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriCalculatedUnserialized.vri, VriCalculatedSerialized.vri, [
      new CellSerializer(VriCalculatedUnserializedHeaders.id, VriCalculatedSerializedHeaders.id),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.userPassportId,
        VriCalculatedSerializedHeaders.userPassportId,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.ubn, VriCalculatedSerializedHeaders.ubn),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.vionRelationNumber,
        VriCalculatedSerializedHeaders.vionRelationNumber,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.name, VriCalculatedSerializedHeaders.name),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageMeatCorrection,
        VriCalculatedSerializedHeaders.averageMeatCorrection,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageMuscleCorrection,
        VriCalculatedSerializedHeaders.averageMuscleCorrection,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageBeaconCorrection,
        VriCalculatedSerializedHeaders.averageBeaconCorrection,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageWeightCorrection,
        VriCalculatedSerializedHeaders.averageWeightCorrection,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageSexAllowance,
        VriCalculatedSerializedHeaders.averageSexAllowance,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageConceptSurcharge,
        VriCalculatedSerializedHeaders.averageConceptSurcharge,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.averageMeat, VriCalculatedSerializedHeaders.averageMeat),
      new CellSerializer(VriCalculatedUnserializedHeaders.averageWeight, VriCalculatedSerializedHeaders.averageWeight),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageFatThickness,
        VriCalculatedSerializedHeaders.averageFatThickness,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageMuscleThickness,
        VriCalculatedSerializedHeaders.averageMuscleThickness,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.totalPigsCount,
        VriCalculatedSerializedHeaders.totalPigsCount,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.approvedCarcassCount,
        VriCalculatedSerializedHeaders.approvedCarcassCount,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.calculatedReportId, VriCalculatedSerializedHeaders.calculatedReportId),
      new CellSerializer(VriCalculatedUnserializedHeaders.carcassCode1, VriCalculatedSerializedHeaders.carcassCode1),
      new CellSerializer(VriCalculatedUnserializedHeaders.carcassCode2, VriCalculatedSerializedHeaders.carcassCode2),
      new CellSerializer(VriCalculatedUnserializedHeaders.carcassCode3, VriCalculatedSerializedHeaders.carcassCode3),
      new CellSerializer(VriCalculatedUnserializedHeaders.carcassCode4, VriCalculatedSerializedHeaders.carcassCode4),
      new CellSerializer(VriCalculatedUnserializedHeaders.carcassCode5, VriCalculatedSerializedHeaders.carcassCode5),
      new CellSerializer(VriCalculatedUnserializedHeaders.carcassCode6, VriCalculatedSerializedHeaders.carcassCode6),
      new CellSerializer(VriCalculatedUnserializedHeaders.carcassCode7, VriCalculatedSerializedHeaders.carcassCode7),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.approvedOrganCount,
        VriCalculatedSerializedHeaders.approvedOrganCount,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.organCode1, VriCalculatedSerializedHeaders.organCode1),
      new CellSerializer(VriCalculatedUnserializedHeaders.organCode2, VriCalculatedSerializedHeaders.organCode2),
      new CellSerializer(VriCalculatedUnserializedHeaders.organCode3, VriCalculatedSerializedHeaders.organCode3),
      new CellSerializer(VriCalculatedUnserializedHeaders.organCode4, VriCalculatedSerializedHeaders.organCode4),
      new CellSerializer(VriCalculatedUnserializedHeaders.organCode5, VriCalculatedSerializedHeaders.organCode5),
      new CellSerializer(VriCalculatedUnserializedHeaders.organCode6, VriCalculatedSerializedHeaders.organCode6),
      new CellSerializer(VriCalculatedUnserializedHeaders.healthyCount, VriCalculatedSerializedHeaders.healthyCount),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageClassificationCorrection,
        VriCalculatedSerializedHeaders.averageClassificationCorrection,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.balanceWithRespectToBase,
        VriCalculatedSerializedHeaders.balanceWithRespectToBase,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.pigsInDraft, VriCalculatedSerializedHeaders.pigsInDraft),
      new CellSerializer(VriCalculatedUnserializedHeaders.healthy, VriCalculatedSerializedHeaders.healthy),
      new CellSerializer(VriCalculatedUnserializedHeaders.longAfgCount, VriCalculatedSerializedHeaders.longAfgCount),
      new CellSerializer(VriCalculatedUnserializedHeaders.longAfg, VriCalculatedSerializedHeaders.longAfg),
      new CellSerializer(VriCalculatedUnserializedHeaders.leverAfgCount, VriCalculatedSerializedHeaders.leverAfgCount),
      new CellSerializer(VriCalculatedUnserializedHeaders.leverAfg, VriCalculatedSerializedHeaders.leverAfg),
      new CellSerializer(VriCalculatedUnserializedHeaders.approvedOrgan, VriCalculatedSerializedHeaders.approvedOrgan),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.liverImpairedCount,
        VriCalculatedSerializedHeaders.liverImpairedCount,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.liverImpaired, VriCalculatedSerializedHeaders.liverImpaired),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.inflamedLegCount,
        VriCalculatedSerializedHeaders.inflamedLegCount,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.inflamedLeg, VriCalculatedSerializedHeaders.inflamedLeg),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.inflamedSkinCount,
        VriCalculatedSerializedHeaders.inflamedSkinCount,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.inflamedSkin, VriCalculatedSerializedHeaders.inflamedSkin),
      new CellSerializer(VriCalculatedUnserializedHeaders.pleurisyCount, VriCalculatedSerializedHeaders.pleurisyCount),
      new CellSerializer(VriCalculatedUnserializedHeaders.pleurisy, VriCalculatedSerializedHeaders.pleurisy),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.approvedCarcass,
        VriCalculatedSerializedHeaders.approvedCarcass,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.notJudjeableCount,
        VriCalculatedSerializedHeaders.notJudjeableCount,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.notJudjeable, VriCalculatedSerializedHeaders.notJudjeable),
      new CellSerializer(VriCalculatedUnserializedHeaders.euroPerPig, VriCalculatedSerializedHeaders.euroPerPig),
      new CellSerializer(VriCalculatedUnserializedHeaders.vriHealth, VriCalculatedSerializedHeaders.vriHealth),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.vriClassification,
        VriCalculatedSerializedHeaders.vriClassification,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.total, VriCalculatedSerializedHeaders.total),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.vriSerialNumber,
        VriCalculatedSerializedHeaders.vriSerialNumber,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.epgSerialNumber,
        VriCalculatedSerializedHeaders.epgSerialNumber,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.feedIntakePerDay,
        VriCalculatedSerializedHeaders.feedIntakePerDay,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageNutrientEw,
        VriCalculatedSerializedHeaders.averageNutrientEw,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.removedPigsCount,
        VriCalculatedSerializedHeaders.removedPigsCount,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageEpgWeight,
        VriCalculatedSerializedHeaders.averageEpgWeight,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageBirthWeight,
        VriCalculatedSerializedHeaders.averageBirthWeight,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.averageRoundLengthDays,
        VriCalculatedSerializedHeaders.averageRoundLengthDays,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.loss, VriCalculatedSerializedHeaders.loss),
      new CellSerializer(VriCalculatedUnserializedHeaders.ewConversion, VriCalculatedSerializedHeaders.ewConversion),
      new CellSerializer(VriCalculatedUnserializedHeaders.growth, VriCalculatedSerializedHeaders.growth),
      new CellSerializer(VriCalculatedUnserializedHeaders.epg, VriCalculatedSerializedHeaders.epg),
      new CellSerializer(VriCalculatedUnserializedHeaders.vriEpg, VriCalculatedSerializedHeaders.vriEpg),
    ]),
  ]);
};

const getVriConstantsSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriCalculatedUnserialized.vri, VriCalculatedSerialized.vri, [
      new CellSerializer(VriCalculatedUnserializedHeaders.totalMin, VriCalculatedSerializedHeaders.totalMin),
      new CellSerializer(VriCalculatedUnserializedHeaders.totalMax, VriCalculatedSerializedHeaders.totalMax),
      new CellSerializer(VriCalculatedUnserializedHeaders.epgMin, VriCalculatedSerializedHeaders.epgMin),
      new CellSerializer(VriCalculatedUnserializedHeaders.epgMax, VriCalculatedSerializedHeaders.epgMax),
      new CellSerializer(VriCalculatedUnserializedHeaders.euroPerPigMin, VriCalculatedSerializedHeaders.euroPerPigMin),
      new CellSerializer(VriCalculatedUnserializedHeaders.euroPerPigMax, VriCalculatedSerializedHeaders.euroPerPigMax),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.balanceWithRespectToBaseMin,
        VriCalculatedSerializedHeaders.balanceWithRespectToBaseMin,
      ),
      new CellSerializer(
        VriCalculatedUnserializedHeaders.balanceWithRespectToBaseMax,
        VriCalculatedSerializedHeaders.balanceWithRespectToBaseMax,
      ),
      new CellSerializer(VriCalculatedUnserializedHeaders.totals, VriCalculatedSerializedHeaders.totals),
    ]),
  ]);
};

const getVriResourceSerializer = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(VriCalculatedUnserialized.vri, VriCalculatedSerialized.vri, [
      new CellSerializer(VriCalculatedUnserializedHeaders.id, VriCalculatedSerializedHeaders.id),
      new CellSerializer(VriCalculatedUnserializedHeaders.status, VriCalculatedSerializedHeaders.status),
    ]),
  ]);
};

export const serializeVriReports = (response: ResourcesWrapper<VriCalculatedResourcesItemUnserialized>) => {
  const reportsData = {
    [VriCalculatedSerialized.vri]: response.data.resources[0].calculated_report_items,
  };

  const serializer = getVriReportSerializer(reportsData);

  return serializer.serialize()[VriCalculatedSerialized.vri];
};

export const serializeVriResourcesData = (response: ResourcesWrapper) => {
  const resources = response.data.resources;

  if (resources.length) {
    const vriData = {
      [VriCalculatedSerialized.vri]: resources,
    };
    const constantsData = {
      [VriCalculatedSerialized.vri]: [resources[0].aggregated_report_data],
    };
    const reportsData = {
      [VriCalculatedSerialized.vri]: resources[0].calculated_report_items,
    };

    const resourceDataSerializer = getVriResourceSerializer(vriData);
    const constantsSerializer = getVriConstantsSerializer(constantsData);
    const reportsSerializer = getVriReportSerializer(reportsData);

    const resourceData = resourceDataSerializer.serialize()[VriCalculatedSerialized.vri][0];
    const aggregatedReportData = constantsSerializer.serialize()[VriCalculatedSerialized.vri][0];
    const calculatedReportItems = reportsSerializer.serialize()[VriCalculatedSerialized.vri];

    return {
      resourceData,
      aggregatedReportData,
      calculatedReportItems,
    };
  } else {
    return {
      resourceData: {},
      aggregatedReportData: {},
      calculatedReportItems: [],
    };
  }
};

export const serializeVriResourceData = (response: ResourceWrapper) => {
  const resource = response.data.resource;

  const vriData = {
    [VriCalculatedSerialized.vri]: [resource],
  };
  const constantsData = {
    [VriCalculatedSerialized.vri]: [resource.aggregated_report_data],
  };
  const reportsData = {
    [VriCalculatedSerialized.vri]: resource.calculated_report_items,
  };

  const resourceDataSerializer = getVriResourceSerializer(vriData);
  const constantsSerializer = getVriConstantsSerializer(constantsData);
  const reportsSerializer = getVriReportSerializer(reportsData);

  const resourceData = resourceDataSerializer.serialize()[VriCalculatedSerialized.vri][0];
  const aggregatedReportData = constantsSerializer.serialize()[VriCalculatedSerialized.vri][0];
  const calculatedReportItems = reportsSerializer.serialize()[VriCalculatedSerialized.vri];

  return {
    resourceData,
    aggregatedReportData,
    calculatedReportItems,
  };
};

export const serializeVriStatistics = (response: ResourceWrapper) => {
  const resource = { [VriCalculatedSerialized.vri]: [response.data.resource] };
  const serializer = getVriStatisticsResourceSerializer(resource);

  return serializer.serialize()[VriCalculatedSerialized.vri][0];
};

import { COUNTRY_CODES_FIELDS } from 'lib/excel/config/referenceTables/countryCodes/structure';
import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';

const { columns, serialized, original } = COUNTRY_CODES_FIELDS.CountryCodes;

export const serializeCountryCodesData = (data: DocumentData) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(original, serialized, [
      new CellSerializer(columns.iso31661Code.original, columns.iso31661Code.serialized),
      new CellSerializer(columns.subdivisionName.original, columns.subdivisionName.serialized),
      new CellSerializer(columns.iso31662Code.original, columns.iso31662Code.serialized),
      new CellSerializer(columns.countryName.original, columns.countryName.serialized),
    ]),
  ]);
};

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { SUPPLIERS_NUMBERS } from './config';
import { ManageUploadPayload } from '../manageUploads';

export type SuppliersNumbersPayload = {
  id: number;
  code_list: string | null;
  supplier_code: string | null;
  supplier_name: string | null;
  date_from: string | null;
  date_to: string | null;
  remark: string | null;
  supplier_number_upload: ManageUploadPayload;
};

export type SuppliersNumbersSerialized = {
  id: number;
  referenceUploadID: number;
  codeList: string;
  supplierNumber: string;
  feedSupplierName: string;
  entryDate: string;
  dischargeDate: string | null;
  remark: string | null;
  uploadType: string;
  status: 'active' | 'inactive';
  filename: string;
  validDateFrom: string | null;
  validDateTo: string | null;
  notes: string;
  uploadedAt: string;
  uploadedBy: string;
};

const { columns, serialized, unserialized } = SUPPLIERS_NUMBERS.SuppliersNumbers;

const getDefaultSerializer = (data: DocumentData<SuppliersNumbersPayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.referenceUploadID.unserialized, columns.referenceUploadID.serialized),
      new CellSerializer(columns.codeList.unserialized, columns.codeList.serialized),
      new CellSerializer(columns.supplierNumber.unserialized, columns.supplierNumber.serialized),
      new CellSerializer(columns.feedSupplierName.unserialized, columns.feedSupplierName.serialized),
      new CellSerializer(columns.entryDate.unserialized, columns.entryDate.serialized),
      new CellSerializer(columns.dischargeDate.unserialized, columns.dischargeDate.serialized),
      new CellSerializer(columns.remark.unserialized, columns.remark.serialized),
      new CellSerializer(columns.uploadType.unserialized, columns.uploadType.serialized),
      new CellSerializer(columns.status.unserialized, columns.status.serialized),
      new CellSerializer(columns.filename.unserialized, columns.filename.serialized),
      new CellSerializer(
        columns.validDateFrom.unserialized,
        columns.validDateFrom.serialized,
        undefined,
        undefined,
        null,
      ),
      new CellSerializer(columns.validDateTo.unserialized, columns.validDateTo.serialized, undefined, undefined, null),
      new CellSerializer(columns.notes.unserialized, columns.notes.serialized),
      new CellSerializer(columns.uploadedAt.unserialized, columns.uploadedAt.serialized),
      new CellSerializer(columns.uploadedBy.unserialized, columns.uploadedBy.serialized),
    ]),
  ]);
};

export const serializeSuppliersNumbers = (serverData: ResourcesWrapper<SuppliersNumbersPayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};

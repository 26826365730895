import { CVB_TABLE_FIELDS } from '../structure';
import { DisplayDocument } from 'lib/excel/display/DisplayDocument';
import { DisplayTab } from 'lib/excel/display/DisplayTab';
import { DisplayHeader } from 'lib/excel/display/DisplayHeader';

const { columns, view } = CVB_TABLE_FIELDS.CvbTable;

export const getCvbTabelDisplayModel = () => {
  return new DisplayDocument([
    new DisplayTab(view, [
      new DisplayHeader({ headerName: columns.productCode.serialized, extractPropName: columns.productCode.view }),
      new DisplayHeader({
        headerName: columns.productSubCode.serialized,
        extractPropName: columns.productSubCode.view,
      }),
      new DisplayHeader({ headerName: columns.productType.serialized, extractPropName: columns.productType.view }),
      new DisplayHeader({ headerName: columns.productNameNL.serialized, extractPropName: columns.productNameNL.view }),
      new DisplayHeader({ headerName: columns.classNameNL.serialized, extractPropName: columns.classNameNL.view }),
      new DisplayHeader({
        headerName: columns.subClassNameNL.serialized,
        extractPropName: columns.subClassNameNL.view,
      }),
      new DisplayHeader({ headerName: columns.productNameEN.serialized, extractPropName: columns.productNameEN.view }),
      new DisplayHeader({ headerName: columns.classNameEN.serialized, extractPropName: columns.classNameEN.view }),
      new DisplayHeader({
        headerName: columns.subClassNameEN.serialized,
        extractPropName: columns.subClassNameEN.view,
      }),
      new DisplayHeader({ headerName: columns.ds.serialized, extractPropName: columns.ds.view }),
      new DisplayHeader({ headerName: columns.dsUnit.serialized, extractPropName: columns.dsUnit.view }),
      new DisplayHeader({ headerName: columns.ras.serialized, extractPropName: columns.ras.view }),
      new DisplayHeader({ headerName: columns.rasUnit.serialized, extractPropName: columns.rasUnit.view }),
      new DisplayHeader({ headerName: columns.rein.serialized, extractPropName: columns.rein.view }),
      new DisplayHeader({ headerName: columns.reinUnit.serialized, extractPropName: columns.reinUnit.view }),
      new DisplayHeader({ headerName: columns.re.serialized, extractPropName: columns.re.view }),
      new DisplayHeader({ headerName: columns.reUnit.serialized, extractPropName: columns.reUnit.view }),
      new DisplayHeader({ headerName: columns.cVcRe.serialized, extractPropName: columns.cVcRe.view }),
      new DisplayHeader({ headerName: columns.cVcReUnit.serialized, extractPropName: columns.cVcReUnit.view }),
      new DisplayHeader({ headerName: columns.cVcOs.serialized, extractPropName: columns.cVcOs.view }),
      new DisplayHeader({ headerName: columns.cVcOsUnit.serialized, extractPropName: columns.cVcOsUnit.view }),
    ]),
  ]);
};

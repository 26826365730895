import { TableConfig } from 'lib/tables/TableConfig';
import { TableTab } from 'lib/tables/TableTab';
import { HybridView } from 'lib/tables/HybridView';
import { ObjectWithProps } from 'lib/excel/serilizers/Cell';
import { roundToInteger } from 'lib/helpers/renderHelpers';

import { CFP_GFLI_FEEDPRINT_TABLE } from 'store/entities/referenceTables/cfpGfliFeedprintTable/config';

const { columns, view, serialized } = CFP_GFLI_FEEDPRINT_TABLE.CfpGfliFeedprintTable;

export const cfpGfliFeedprintTableConfig = new TableConfig(serialized, [
  new TableTab({
    tableOptions: {
      sorting: true,
      search: false,
    },
    label: view,
    dataKey: serialized,
    rowMenu: () => null,
    rowContextMenu: undefined,
    headers: [
      new HybridView({
        settings: {
          title: columns.feedPrintCode.view,
          field: columns.feedPrintCode.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.feedPrintCode.serialized] && roundToInteger(data[columns.feedPrintCode.serialized]),
        },
      }),

      new HybridView({
        settings: {
          title: columns.cvbCode.view,
          field: columns.cvbCode.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.source.view,
          field: columns.source.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productNameEn.view,
          field: columns.productNameEn.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productNameNl.view,
          field: columns.productNameNl.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productCodeEu.view,
          field: columns.productCodeEu.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.productNameEu.view,
          field: columns.productNameEu.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.origin.view,
          field: columns.origin.serialized,
        },
      }),

      new HybridView({
        settings: {
          title: columns.climate.view,
          field: columns.climate.serialized,
          render: (data: ObjectWithProps) =>
            data[columns.climate.serialized] && roundToInteger(data[columns.climate.serialized]),
        },
      }),
    ],
  }),
]);

import { DocumentSerializer } from 'lib/excel/serilizers/Document';
import { DocumentData, SheetSerializer } from 'lib/excel/serilizers/Sheet';
import { CellSerializer } from 'lib/excel/serilizers/Cell';
import { ResourcesWrapper } from 'lib/http/utils';

import { CODES_MATING_TYPE } from './config';

export type CodesMatingTypePayload = {
  id: number;
  mating_type_identifier: string;
  note: string;
  mating_type_datetime: string;
};

export type CodesMatingTypeSerialized = {
  id: number;
  matingTypeId: string;
  note: string;
  matingTypeDatetime: string;
};

const { columns, serialized, unserialized } = CODES_MATING_TYPE.CodesMatingType;

const getDefaultSerializer = (data: DocumentData<CodesMatingTypePayload[]>) => {
  return new DocumentSerializer(data, [
    new SheetSerializer(unserialized, serialized, [
      new CellSerializer(columns.id.unserialized, columns.id.serialized),
      new CellSerializer(columns.matingTypeId.unserialized, columns.matingTypeId.serialized),
      new CellSerializer(columns.note.unserialized, columns.note.serialized),
      new CellSerializer(columns.matingTypeDatetime.unserialized, columns.matingTypeDatetime.serialized),
    ]),
  ]);
};

export const serializeCodesMatingType = (serverData: ResourcesWrapper<CodesMatingTypePayload>) => {
  const data = {
    [unserialized]: serverData.data.resources,
  };

  const serializer = getDefaultSerializer(data);

  return serializer.serialize();
};
